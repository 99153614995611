import favourites from '@/api/favourites/favourites';

const state = {
    favouritesEmails: [],
    frequentEmails: [],
    combinedEmails: [],
};

const actions = {
    async updateItemFavourite({ commit }, { emailAddress, isFavourite }) {
        const updateEmails = (list) =>
            list.map((item) => (item.emailAddress === emailAddress ? { ...item, isFavourite } : item));

        commit('SET_FAVOURITES_EMAILS', updateEmails(state.favouritesEmails));
        commit('SET_FREQUENT_EMAILS', updateEmails(state.frequentEmails));
        commit('SET_COMBINED_EMAILS', updateEmails(state.combinedEmails));
    },

    async addFavourite({ dispatch }, { queueId, email }) {
        try {
            await favourites.addFavouriteEmail(queueId, email);
            dispatch('updateItemFavourite', { emailAddress: email, isFavourite: true });
        } catch (error) {
            console.log(error);
        }
    },

    async removeFavourite({ dispatch }, { queueId, email }) {
        try {
            await favourites.removeFavouriteEmail(queueId, email);
            dispatch('updateItemFavourite', { emailAddress: email, isFavourite: false });
        } catch (error) {
            console.log(error);
        }
    },

    async getFavourites({ commit }, { queueId, limit, page }) {
        try {
            const offset = (page - 1) * limit;
            const response = await favourites.getFavouriteEmails(queueId, limit, offset);
            if (page === 1) {
                commit('SET_FAVOURITES_EMAILS', response.data);
            } else {
                commit('SET_FAVOURITES_EMAILS', state.favourites.concat(response.data));
            }
        } catch (error) {
            console.log(error);
        }
    },

    async getFrequentEmails({ commit }, { queueId, limit, page }) {
        try {
            const offset = (page - 1) * limit;
            const response = await favourites.getFrequentEmails(queueId, limit, offset);

            if (page === 1) {
                commit('SET_FREQUENT_EMAILS', response.data);
            } else {
                commit('SET_FREQUENT_EMAILS', state.frequentEmails.concat(response.data));
            }
        } catch (error) {
            console.log(error);
        }
    },

    async getCombinedEmails({ commit }, { queueId, limit, page }) {
        try {
            const offset = (page - 1) * limit;
            const response = await favourites.getCombinedEmails(queueId, limit, offset);

            if (page === 1) {
                commit('SET_COMBINED_EMAILS', response.data);
            } else {
                commit('SET_COMBINED_EMAILS', state.combinedEmails.concat(response.data));
            }
        } catch (error) {
            console.log(error);
        }
    },
};

const mutations = {
    SET_FAVOURITES_EMAILS(state, favouritesEmails) {
        state.favouritesEmails = favouritesEmails;
    },

    SET_FREQUENT_EMAILS(state, frequentEmails) {
        state.frequentEmails = frequentEmails;
    },

    SET_COMBINED_EMAILS(state, combinedEmails) {
        state.combinedEmails = combinedEmails;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
