<template>
    <article class="module-item" :class="item.unreadMessages ? 'active' : 'deactive'">
        <div class="icon-container">
            <i class="mdi" :class="queuesIcon"></i>
        </div>
        <div>
            <span>{{ item.name }}</span>
        </div>
        <div v-if="item.channelType === 'call'">
            <SimpleTooltip bottom :tooltipText="$t('call')">
                <IconButton
                    :size="16"
                    icon="mdi-phone"
                    @click="triggerRedirection($event, item.channelIdentifier, 'phone', redirectFunctionType(modifier))"
                />
            </SimpleTooltip>

            <SimpleTooltip bottom :tooltipText="$t('transfer')">
                <IconButton
                    :size="16"
                    icon="mdi-phone-outgoing"
                    @click="instantiateConnection($event, item.channelIdentifier, 'phone', '#12#')"
                />
            </SimpleTooltip>
        </div>
    </article>
</template>

<script>
    import { mapState } from 'vuex';

    import IconButton from '@/components/Main/Buttons/Icon.vue';
    import SimpleTooltip from '@/components/Global/SimpleTooltip.vue';
    import config from '@/app/config';

    import {
        instantiateConnection,
        redirectFunctionType,
        tooltipTextDisplay,
        isBlindTransferCombination,
    } from '@/utils';

    export default {
        name: 'ModuleQueue',

        components: {
            IconButton,
            SimpleTooltip,
        },

        props: {
            item: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapState({
                modifier: (state) => state.Search.modifier,
            }),

            queuesIcon() {
                switch (this.item.channelType) {
                    case 'email': {
                        return 'mdi-email';
                    }
                    case 'chat': {
                        return 'mdi-message';
                    }
                    case 'call': {
                        return 'mdi-phone';
                    }
                    default: {
                        return 'mdi-account';
                    }
                }
            },
        },

        methods: {
            triggerRedirection(event, number, type) {
                let callNumber = '';
                if (isBlindTransferCombination(this.modifier)) {
                    callNumber = config.customerParams.search.searchParams.blindTransferNumber + number + '#';
                } else {
                    callNumber = number;
                }
                instantiateConnection(event, callNumber, type, redirectFunctionType(this.modifier));
            },

            instantiateConnection,
            redirectFunctionType,
            tooltipTextDisplay,
            isBlindTransferCombination,
        },
    };
</script>

<style scoped>
    .module-item {
        background: white;
        border-bottom: 1px solid #e0e0e0;
        display: grid;
        grid-template-columns: auto 1fr auto;
        min-height: 48px;
        transition: all 0.3s;
        font-size: 12px;
        user-select: none;
        gap: 16px;
        padding: 0px 16px;
    }

    .module-item > * {
        align-items: center;
        display: flex;
        overflow: hidden;
    }

    .module-item > div > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .module-item :last-child {
        justify-content: flex-end;
    }

    .module-item:hover {
        background: #f5f5f5;
    }

    .module-item:active {
        background: #e0e0e0;
    }

    i {
        color: var(--v-primary-base);
    }

    .icon-container {
        padding: 0px;
    }

    .active {
    }

    .deactive {
    }
</style>

<i18n>
{
    "sv": {
        "transfer": "Vidarekoppla",
        "call": "Ring"
    },
    "en": {
        "transfer": "Transfer",
        "call": "Call"
    }
}
</i18n>
