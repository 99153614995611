<template>
    <div>
        <v-card flat>
            <div class="d-flex flex-row">
                <v-col>
                    <div class="mb-0 d-flex flex-row justify-center my-3 pb-5">
                        <v-form ref="callform" v-model="valid" lazy-validation @submit.prevent>
                            <phone-number
                                v-if="selectedNumber"
                                :defaultPhoneNumber="selectedNumber.Number || selectedNumber"
                                @inputAndValidation="handlePhoneInput"
                            />
                        </v-form>
                    </div>
                    <div class="d-flex flex-column align-center px-2 pb-3">
                        <div
                            class="d-flex flex-row flex-wrap justify-space-between align-center"
                            style="max-width: 225px; width: 100%"
                        >
                            <div
                                v-for="button in buttons"
                                :key="button.id"
                                style="flex: 1 0 33%"
                                class="d-flex flex-column align-center"
                            >
                                <div class="pa-1 mb-1">
                                    <v-btn :id="button.id" fab elevation="0" @click="button.action">
                                        <span style="font-size: 24px; font-weight: 400; color: var(--v-gray4-base)">
                                            {{ button.title }}
                                        </span>
                                    </v-btn>
                                </div>
                            </div>
                            <div class="d-flex flex-row justify-center" style="width: 100%">
                                <div class="pa-1 mb-1">
                                    <v-btn
                                        :id="'enter'"
                                        :disabled="!valid"
                                        color="primary"
                                        fab
                                        elevation="0"
                                        @click="clickLetter('call')"
                                    >
                                        <v-icon color="white" size="24">mdi-phone</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-col>
            </div>
        </v-card>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import cnfg from '@/app/config';
    import { channelTypes } from '@/constants/enums/queues.enums';

    export default {
        props: {
            disabled: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                cnfg,
                valid: true,
                phoneNumberToCall: '',
                phoneNumberIsValid: true,
                buttons: [
                    {
                        title: '1',
                        id: 'button-1',
                        action: () => {
                            this.clickLetter('1');
                        },
                    },
                    {
                        title: '2',
                        id: 'button-2',
                        action: () => {
                            this.clickLetter('2');
                        },
                    },
                    {
                        title: '3',
                        id: 'button-3',
                        action: () => {
                            this.clickLetter('3');
                        },
                    },
                    {
                        title: '4',
                        id: 'button-4',
                        action: () => {
                            this.clickLetter('4');
                        },
                    },
                    {
                        title: '5',
                        id: 'button-5',
                        action: () => {
                            this.clickLetter('5');
                        },
                    },
                    {
                        title: '6',
                        id: 'button-6',
                        action: () => {
                            this.clickLetter('6');
                        },
                    },
                    {
                        title: '7',
                        id: 'button-7',
                        action: () => {
                            this.clickLetter('7');
                        },
                    },
                    {
                        title: '8',
                        id: 'button-8',
                        action: () => {
                            this.clickLetter('8');
                        },
                    },
                    {
                        title: '9',
                        id: 'button-9',
                        action: () => {
                            this.clickLetter('9');
                        },
                    },
                    {
                        title: '+',
                        id: 'button-plus',
                        action: () => {
                            this.clickLetter('+');
                        },
                    },
                    {
                        title: '0',
                        id: 'button-0',
                        action: () => {
                            this.clickLetter('0');
                        },
                    },
                    {
                        title: '#',
                        id: 'button-hash',
                        action: () => {
                            this.clickLetter('#');
                        },
                    },
                ],
                latestKeyPressed: '',
                selectedNumber: null,
                phonenumberRules: [
                    (v) => !!v || this.$t('callModule.fillField'),
                    () => this.phoneNumberIsValid || this.$t('callModule.validNumber'),
                ],
            };
        },
        computed: {
            ...mapState({
                loggedInUser: (state) => state.Auth.userObject,
                dialogState: (state) => state.Cases.toggleCommunication,
                data: (state) => state.Cases.CommunicationCreatorData,
                clientOnCard: (state) => state.Cases.clientNowOnCard.data,
                selectedCase: (state) => state.Cases.selectedCase,
                communicationCreatorData: (state) => state.Cases.CommunicationCreatorData,
                outgoingCalls: (state) => state.System.userSettings.search.misc.outgoingCallsNumber.value,
                currentSelectedClient: (state) => state.Client.currentSelectedClient,
            }),
            clientIsUsingMac() {
                return /(mac|iphone|ipod|ipad)/i.test(navigator.userAgent);
            },
            phoneNumbers() {
                return [
                    this.currentSelectedClient.data.primaryTelephone || null,
                    this.currentSelectedClient.data.secondaryTelephone || null,
                ].filter(Boolean);
            },
        },
        watch: {
            phoneNumbers: {
                immediate: true,
                handler(newVal) {
                    if (newVal.length > 0) {
                        // eslint-disable-next-line prefer-destructuring
                        this.selectedNumber = newVal[0];
                    }
                },
            },
        },
        mounted() {
            // eslint-disable-next-line prefer-destructuring
            this.selectedNumber = this.phoneNumbers[0];
            window.addEventListener('keydown', this.handleKeyPress);
        },
        beforeDestroy() {
            window.removeEventListener('keydown', this.handleKeyPress);
        },

        methods: {
            ...mapActions({
                getNumberToCall: 'Call/getNumberToCall',
            }),
            handleKeyPress(e) {
                if (!this.dialogState) return;
                this.latestKeyPressed = e.key;
                this.keyPressed(this.latestKeyPressed);
            },
            handlePhoneInput(phoneObject) {
                const { number, valid } = phoneObject;
                this.phoneNumberToCall = number;
                this.phoneNumberIsValid = valid;
                this.valid = number && valid;
            },
            checkButtonRange(value) {
                return this.buttons.some((el) => el.title === value);
            },
            keyPressed(value) {
                if (value === 'Backspace') {
                    this.clickLetter('backspace');
                    return;
                }
                if (value === 'Enter') {
                    this.clickLetter('call');
                    return;
                }
                if (!this.checkButtonRange(value)) {
                    return;
                }
                let buttonId = '#button-';
                if (value === '+') {
                    buttonId += 'plus';
                } else if (value === '#') {
                    buttonId += 'hash';
                } else {
                    buttonId += value;
                }
                const el = document.querySelector(buttonId);
                if (!el) return;
                el.classList.add('flashing');
                setTimeout(function () {
                    el.classList.remove('flashing');
                }, 200);
                this.clickLetter(value);
            },
            validate() {
                return this.phoneNumberIsValid && this.phoneNumberToCall;
            },
            async clickLetter(value) {
                if (value === 'backspace') {
                    this.phoneNumberToCall = this.phoneNumberToCall.slice(0, -1);
                    return;
                }

                if (value === 'call') {
                    const isValid = this.validate();
                    if (!isValid) return;
                    await this.sendCall();

                    return;
                }

                const el = document.querySelector('#numberBox');
                if (!el) return;
                const focus = document.activeElement === el;
                if (focus) {
                    return;
                }

                if (this.phoneNumberToCall === null) {
                    this.phoneNumberToCall = value;
                } else {
                    this.phoneNumberToCall += value;
                }
            },
            async sendCall() {
                const { number, exception } = await this.getNumberToCall({
                    phoneNumber: this.phoneNumberToCall,
                    caseId: this.$route.params.caseId,
                    userId: this.loggedInUser.userId,
                    type: channelTypes.CALL,
                });
                if (!number) return;

                if (exception) {
                    const confirmed = await this.continueCallAlthoughException(exception);
                    if (!confirmed) return;
                }

                if (!this.clientIsUsingMac) {
                    document.location.href = `tloswyx:${number}`;
                } else {
                    document.location.href = `tel:${this.phoneNumberToCall}`;
                }
                this.closeModule();
            },

            comboboxSelectionDisplay(item) {
                if (item.Number) {
                    return item.Number + ' (' + item.Name + ')';
                }
                return item;
            },

            closeModule() {
                // * do structured clone of the object to avoid reference issues - dont want to lose any keys that could be set for whatever reason.
                const newCreatorData = structuredClone(this.communicationCreatorData);
                newCreatorData.type = 0; // * set it to default type - to kill this component
                this.$store.dispatch('Cases/setCommunicationCreatorData', newCreatorData);
                this.$store.dispatch('Cases/openCommunication', false); // * position matters here as the dispatch above sets it to true
            },
        },
    };
</script>
<style scoped>
    .flashing {
        animation: flash 0.2s ease-in-out;
    }
    @keyframes flash {
        0% {
            background-color: none;
            transform: scale(1);
        }
        50% {
            background-color: #aaa;
            transform: scale(1.2);
        }
        100% {
            background-color: none;
            transform: scale(1);
        }
    }
</style>
<i18n lang="json">
{
    "en": {
        "callModule": {
            "number": "Number: ",
            "selRole": "Select at least one role",
            "hint": "Only numbers and no spaces.",
            "fillField": "This field must be filled in",
            "validNumber": "Please enter a valid phone number",
            "callInit": "Phonecall initiated from ",
            "to": " to: ",
            "alreadyCalling": "Another agent is already trying to call this number. Please try again later."
        }
    },
    "sv": {
        "callModule": {
            "number": "Nummer: ",
            "selRole": "Välj minst en roll",
            "hint": "Endast siffror och inga mellanslag.",
            "fillField": "Detta fält behöver vara ifyllt",
            "validNumber": "Vänligen ange ett giltigt telefonnummer",
            "callInit": "Telefonsamtal initierat från ",
            "to": " till: ",
            "alreadyCalling": "En annan agent försöker redan ringa detta nummer. Vänligen försök igen senare."
        }
    }
}
</i18n>
