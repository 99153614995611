import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-center",staticStyle:{"position":"relative"},style:({
        color: _vm.textColor,
        'font-weight': _vm.fontWeight,
        'font-size': _vm.fontSize,
        height: _vm.fieldHeight,
        width: _vm.fieldWidth,
        'max-width': _vm.fieldWidth,
    })},[(_vm.editingText)?_c('div',{staticStyle:{"position":"relative","height":"100%","width":"100%"}},[_c('div',{staticClass:"rounded-lg ma-0 pa-0",staticStyle:{"position":"absolute","height":"100%","width":"100%","opacity":"0.1","z-index":"0"},style:({ backgroundColor: 'var(--v-gray4-base)' })}),_c(VTextField,{staticClass:"ma-0 pa-0 d-flex align-center",class:_vm.textFieldClass,staticStyle:{"height":"100%"},attrs:{"z-index":"1","autofocus":"","hide-details":"","append-icon":"mdi-arrow-u-left-top","flat":"","solo":"","maxLength":_vm.maxLength,"placeholder":"Namn","background-color":"transparent"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.saveText()},"blur":function($event){return _vm.saveText()},"click:append":_vm.resetText},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1):_vm._e(),(!_vm.editingText)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%","width":"100%"}},[_c(VTooltip,{staticClass:"align-center",staticStyle:{"max-width":"90%"},attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticClass:"text-truncate"},on),[_vm._v(" "+_vm._s(_vm.text)+" ")])]}}],null,false,2041298320)},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_c(VBtn,{staticClass:"ml-3",staticStyle:{"background-color":"transparent"},attrs:{"fab":"","elevation":"0","width":"32px","height":"32px"},on:{"click":function($event){_vm.editingText = true}}},[_c(VIcon,{style:({ color: _vm.iconColor }),attrs:{"size":_vm.iconSize}},[_vm._v("mdi-pencil-outline")])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }