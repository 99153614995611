module.exports = {
    params: {
        integrationLinks: {
            active: true,
            links: [
                { name: 'N/A', color: '#afaf0c', link: 'äga' },
                // { name: 'MRM', color: '#add8e6', link: 'mrm' }
            ],
        },
    },
};
