module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "sv": {
        "noQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen kö"])}
      },
      "en": {
        "noQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No queue"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"sv":{"noQueue":"Ingen kö"},"en":{"noQueue":"No queue"}}')
  delete Component.options._Ctor
  
}
