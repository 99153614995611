module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
        "timeInCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time in case"])},
        "totalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total time"])}
      },
      "sv": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitet"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistik"])},
        "timeInCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tid i ärende"])},
        "totalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total tid"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"activity":"Activity","statistics":"Statistics","timeInCase":"Time in case","totalTime":"Total time"},"sv":{"activity":"Aktivitet","statistics":"Statistik","timeInCase":"Tid i ärende","totalTime":"Total tid"}}')
  delete Component.options._Ctor
  
}
