<template>
    <component :is="integrationComponentByName" />
</template>
<script>
    import MadhatOrders from '@/components/Cases/Integrations/Madhat/OrderList/MadhatOrders.vue';

    export default {
        components: {
            MadhatOrders,
        },
        data() {
            return {
                integrations: [
                    {
                        name: 'madhat',
                        component: 'MadhatOrders',
                    },
                ],
            };
        },
        computed: {
            selectedOrderIntegration() {
                return this.$store.state.Cases.selectedOrderIntegration;
            },
            integrationComponentByName() {
                return this.integrations.find((integration) => integration.name === this.selectedOrderIntegration)
                    ?.component;
            },
        },
    };
</script>
