<template>
    <div class="pagination-container">
        <PopupMenu
            :key="'items' + selectedItems"
            :state="[{ value: selectedItems }]"
            :items="itemsPerPage"
            returnObject
            :heading="$t('listPagination.header')"
            width="auto"
            :numberOfItems="4"
            @input="(v) => setItemsPerPage(v)"
        >
            <template #button>
                <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            fab
                            small
                            color="white"
                            elevation="0"
                            class="option-button"
                            :ripple="false"
                            v-on="on"
                            @mouseleave="(e) => e.target.blur()"
                        >
                            <span class="text-color-pagination">{{ selectedItems }}</span>
                        </v-btn>
                    </template>
                    <span>{{ $t('listPagination.nofCasesPage') }}</span>
                </v-tooltip>
            </template>
            <template #default="{ item }">
                <span class="small-font">{{ item.value }}</span>
            </template>
        </PopupMenu>

        <article class="current-pagination-card">
            <span class="text-caption prevent-select">
                {{ selectedPageFormatted }}
            </span>
        </article>

        <v-tooltip bottom>
            <template #activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    fab
                    small
                    color="white"
                    elevation="0"
                    class="option-button"
                    :disabled="selectedPage === 1 || loading"
                    :loading="loading"
                    :ripple="false"
                    v-on="on"
                    @click="setPage({ value: selectedPage - 1 })"
                    @mouseleave="(e) => e.target.blur()"
                >
                    <v-icon color="gray2" size="18">mdi-chevron-left</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('listPagination.prevPage') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
            <template #activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    fab
                    small
                    color="white"
                    elevation="0"
                    class="option-button"
                    :disabled="selectedPage >= numberOfPages || totalCases <= selectedItems || loading"
                    :loading="loading"
                    :ripple="false"
                    v-on="on"
                    @click="setPage({ value: selectedPage + 1 })"
                    @mouseleave="(e) => e.target.blur()"
                >
                    <v-icon color="gray2" size="18">mdi-chevron-right</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('listPagination.nextPage') }}</span>
        </v-tooltip>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { debouncer } from '@/utils/index';

    const DEBOUNCE_TIME = 300;

    export default {
        components: {
            PopupMenu: () => import('@/components/Cases/Navigation/PopupMenu.vue'),
        },

        data() {
            return {
                loading: false,
                pages: [],
                itemsPerPage: [{ value: 1 }, { value: 3 }, { value: 5 }, { value: 10 }, { value: 20 }, { value: 50 }],
                timeout: null,
                selectedPage: 1,
                debounce: debouncer(this.setSelectedFiltersDebounce, DEBOUNCE_TIME),
            };
        },

        computed: {
            ...mapState({
                selectedCaseId: (state) => state.Cases.selectedCaseId,
                removedCaseIndex: (state) => state.Cases.removedCaseIndex,
            }),
            caseFiltersSelectedItems() {
                return this.$store.state.Cases.caseFiltersState;
            },
            cases() {
                return this.$store.state.Cases.cases;
            },
            selectedItems() {
                return this.caseFiltersSelectedItems.items;
            },
            selectedPageFormatted() {
                let endCount = this.selectedItems * this.selectedPage;
                if (this.totalCases < endCount) {
                    endCount = this.totalCases;
                }
                const NO_CASES = 0;
                if (this.totalCases === NO_CASES) {
                    return this.$t('listPagination.noCases');
                }

                const CASE_INCREMENT = 1;

                const startCount = (this.selectedPage - CASE_INCREMENT) * this.selectedItems + CASE_INCREMENT;
                return `${startCount}-${endCount} ${this.$t('listPagination.outOf')} ${this.totalCases}`;
            },
            totalCases() {
                return this.$store.state.Cases.totalCases;
            },
            numberOfPages() {
                return Math.ceil(this.totalCases / this.selectedItems);
            },

            keyEventPages() {
                const pages = [];
                for (let i = 1; i <= this.numberOfPages; i++) {
                    pages.push({ value: i });
                }
                return pages;
            },

            currentPosition() {
                const FIRST_INDEX = 0;

                if (!this.cases) {
                    return FIRST_INDEX;
                }
                return this.cases.map((e) => e.caseId).indexOf(this.selectedCaseId);
            },
        },

        watch: {
            cases() {
                clearTimeout(this.timeout);
                this.loading = false;
            },
            caseFiltersSelectedItems: {
                deep: true,
                handler() {
                    // Watch the filter items page and update the selected page
                    this.selectedPage = this.caseFiltersSelectedItems.page;
                },
            },
        },

        created() {
            this.setKeyEvents();
        },

        destroyed() {
            window.removeEventListener('keydown', this.keyDown);
        },

        methods: {
            ...mapActions('Cases', ['setSelectedFilters', 'isCaseBusy', 'getCases']),

            setKeyEvents() {
                const DEBOUNCE_TIME = 50;
                this.debouncedNext = debouncer(this.goToNextCase, DEBOUNCE_TIME);
                this.debouncedPrev = debouncer(this.goToPreviousCase, DEBOUNCE_TIME);
                window.addEventListener('keydown', this.keyDown);
            },
            preventClick() {},

            setSelectedFiltersDebounce(filter) {
                const LOAD_TIMEOUT = 100;
                this.timeout = setTimeout(() => {
                    this.loading = true;
                }, LOAD_TIMEOUT); // if the request takes less than 100ms, the loading spinner will not show
                this.setSelectedFilters(filter);
            },

            setPage(item) {
                const filter = structuredClone(this.caseFiltersSelectedItems);
                // Set the components selected page to the new page
                this.selectedPage = item.value;
                filter.page = item.value;
                this.debounce(filter);
            },

            setItemsPerPage(item) {
                const filter = { ...this.caseFiltersSelectedItems };
                filter.items = item.value;
                this.setSelectedFilters(filter);
            },

            fetchPages(page) {
                this.loading = true;
                this.pages = [];

                const maxValue =
                    this.numberOfPages < page * this.caseFiltersSelectedItems.items
                        ? this.numberOfPages
                        : page * this.caseFiltersSelectedItems.items;

                const PAGE_INCREMENT = 1;

                for (
                    let i = (page - PAGE_INCREMENT) * this.caseFiltersSelectedItems.items + PAGE_INCREMENT;
                    i <= maxValue;
                    i++
                ) {
                    this.pages.push({ value: i });
                }

                this.loading = false;
                return {
                    data: this.pages,
                    hasMore: maxValue < this.numberOfPages,
                };
            },

            // Key Pagination

            keyDown(event) {
                const casesLength = this.cases?.length ?? 0;
                if (
                    event.key === 'ArrowRight' &&
                    event.ctrlKey &&
                    event.altKey &&
                    !(this.selectedPage === this.keyEventPages?.length && this.currentPosition === casesLength - 1)
                ) {
                    this.debouncedNext();
                }
                if (
                    event.key === 'ArrowLeft' &&
                    event.ctrlKey &&
                    event.altKey &&
                    !(this.selectedPage === 1 && this.currentPosition === 0)
                ) {
                    this.debouncedPrev();
                }
            },

            async goToNextCase() {
                let newPos = 0;
                // If currentPosition is -1 the case no longer exists in the case list
                if (this.currentPosition === -1) {
                    newPos = this.removedCaseIndex;
                } else {
                    newPos = this.currentPosition + 1;
                }
                if (newPos === this.cases.length && this.selectedPage < this.keyEventPages.length) {
                    await this.setPage({ value: this.selectedPage + 1 });
                    await this.getCases(this.caseFiltersSelectedItems);
                    this.goToCase(this.cases[0]);
                } else if (newPos < this.cases.length) {
                    this.goToCase(this.cases[newPos]);
                } else {
                    this.goToCase(this.cases.at(-1));
                }
            },

            async goToPreviousCase() {
                let newPos = 0;

                // If currentPosition is -1 the case no longer exists in the case list
                if (this.currentPosition === -1) {
                    newPos = this.removedCaseIndex - 1;
                } else {
                    newPos = this.currentPosition - 1;
                }
                if (newPos < 0 && this.selectedPage > 1) {
                    await this.setPage({ value: this.selectedPage === 1 ? 1 : this.selectedPage - 1 });
                    await this.getCases(this.caseFiltersSelectedItems);
                    this.goToCase(this.cases[this.caseFiltersSelectedItems.items - 1]);
                } else if (newPos >= 0) {
                    this.goToCase(this.cases[newPos]);
                } else {
                    this.goToCase(this.cases[0]);
                }
            },

            goToCase(type) {
                try {
                    // if (this.showSelect) return;
                    this.isCaseBusy(type.caseId);
                    const url = '/cases/' + type.caseId;
                    this.$router.push(url);
                } catch (error) {
                    this.$toasted.show(this.$t('clientCard.couldNotOpen'), {
                        icon: 'alert-circle',
                        type: 'warning',
                    });
                }
            },
        },
    };
</script>

<style scoped>
    .text-color-pagination {
        color: var(--v-gray2-base);
    }
    .small-font {
        font-size: 12px;
    }
    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .pagination-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }

    .current-pagination-card {
        height: 40px;
        border-radius: 20px;
        background-color: white;
        display: flex;
        padding: 0px 16px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
    }
    .current-pagination-card span {
        color: var(--v-gray2-base);
        text-transform: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .cursor-pointer {
        cursor: pointer;
    }

    .option-button {
        padding: 0 !important;
        margin: 0 !important;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
        display: flex;
    }
</style>
<i18n lang="json">
{
    "en": {
        "listPagination": {
            "header": "Cases per page",
            "nofCasesPage": "Number of cases per page",
            "prevPage": "Previous page",
            "gotoPage": "Go to page",
            "nextPage": "Next page",
            "noCases": "No cases",
            "outOf": "out of"
        },
        "clientCard": {
            "previousCase": "Previous case (CTRL + ALT + LEFT)",
            "nextCase": "Next case (CTRL + ALT + RIGHT)",
            "couldNotOpen": "Could not open case"
        }
    },
    "sv": {
        "listPagination": {
            "header": "Ärenden per sida",
            "nofCasesPage": "Antal ärenden per sida",
            "prevPage": "Förgående sida",
            "gotoPage": "Gå till sida",
            "nextPage": "Nästa sida",
            "noCases": "Inga ärenden",
            "outOf": "av"
        },
        "clientCard": {
            "previousCase": "Föregående ärende  (CTRL + ALT + VÄNSTER)",
            "nextCase": "Nästa ärende  (CTRL + ALT + HÖGER)",
            "couldNotOpen": "Kunde inte öppna ärende"
        }
    }
}
</i18n>
