import axios from 'axios';

// ** Categories ** //
export function saveNewCategory(category) {
    return axios.post('/faqs/category', category);
}

export function editCategory(category) {
    return axios.put(`/faqs/category/${category.id}`, category);
}

export function getCategoriesByCollectionId(id) {
    return axios.get(`/faqs/category/${id}`);
}

export function deleteCategory(id) {
    return axios.delete(`/faqs/category/${id}`);
}

// ** Collections ** //
export function editCollection(collection) {
    return axios.put(`/faqs/collections/${collection.id}`, collection);
}

export function deleteCollection(id) {
    return axios.delete(`/faqs/collections/${id}`);
}

export function saveNewCollection(collection) {
    return axios.post('/faqs/collections', collection);
}

export function getAllFaqCollections() {
    return axios.get('/faqs/collections');
}

// ** Faqs ** //
export function getAllFaqs(id) {
    return axios.get(`/faqs/${id}`);
}

export function createFaq(newFaq) {
    return axios.post('/faqs', newFaq);
}

export function deleteFaq(id) {
    return axios.delete(`/faqs/${id}`);
}

export function updateFaq(updFaq) {
    return axios.put(`/faqs/${updFaq.id}`, updFaq);
}

// Widgets
export function getWidgetByCollection(collectionId) {
    return axios.get(`/faqs/collections/${collectionId}/widgets`);
}

export function saveWidget(payload) {
    return axios.put(`/faqs/collections/${payload.faqCollection}/widgets/${payload.id}`, payload);
}

export function deleteWidget(payload) {
    return axios.delete(`/faqs/collections/${payload.faqCollection}/widgets/${payload.id}`);
}

export function createWidget(payload) {
    return axios.post(`/faqs/collections/${payload.faqCollection}/widgets`, payload);
}

export default {
    getAllFaqs,
    createFaq,
    deleteFaq,
    updateFaq,

    getAllFaqCollections,
    saveNewCollection,
    deleteCollection,

    getCategoriesByCollectionId,
    saveNewCategory,
    deleteCategory,
    editCategory,
    editCollection,

    getWidgetByCollection,
};
