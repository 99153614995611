<template>
    <section
        :class="{ 'module-card-hover': hovered, 'module-card-hide': !userActionModuleOpen }"
        class="module-card shadow"
        @mouseover="hovered = true"
        @mouseleave="hovered = false"
        @click="openModal"
    >
        <div class="module-linear">
            <div
                v-for="item in items"
                :key="item.id"
                :style="{ width: `${item.capacityPercentage}%` }"
                class="module-bar"
                @mouseover="setHoveredItem(item.id)"
                @mouseleave="removeHoveredItem(item.id)"
            >
                <SimpleTooltip top :tooltipText="getTooltip(item.id)">
                    <div
                        class="module-bar-line"
                        :class="{
                            'module-line-hover': hoveredItems.includes(item.id) || !hovered,
                            'module-bar-unread': hasUnreadMessages(item.id),
                        }"
                    ></div>
                </SimpleTooltip>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    import SimpleTooltip from '@/components/Global/SimpleTooltip.vue';

    export default {
        name: 'ModuleCard',

        components: {
            SimpleTooltip,
        },

        data() {
            return {
                hovered: false,
                hoveredItems: [],
            };
        },

        computed: {
            ...mapState('ActiveCases', ['activeCapacity', 'unreadMessages']),
            ...mapState('Users', ['userActionModuleOpen']),

            totalPercentage() {
                return this.activeCapacity?.totalPercentage;
            },

            items() {
                return this.activeCapacity?.items;
            },

            numberOfItems() {
                return this.activeCapacity?.items?.length;
            },

            moduleStyle() {
                return {
                    width: `${this.totalPercentage}%`,
                };
            },
        },

        sockets: {
            connect() {
                this.getActiveCapacity();
            },
        },

        created() {
            this.getActiveCapacity();
            document.addEventListener('visibilitychange', this.handleVisibilityChange);
        },

        beforeDestroy() {
            document.removeEventListener('visibilitychange', this.handleVisibilityChange);
            this.unsubscribeFromActiveDialogs();
        },

        methods: {
            ...mapActions({
                getActiveCapacity: 'ActiveCases/getActiveCapacity',
                toggleActionModule: 'Users/toggleActionModule',
                unsubscribeFromActiveDialogs: 'ActiveCases/unsubscribeFromActiveDialogs',
            }),

            setHoveredItem(id) {
                this.hoveredItems.push(id);
            },

            removeHoveredItem(id) {
                this.hoveredItems = this.hoveredItems.filter((item) => item !== id);
            },

            openModal() {
                this.toggleActionModule(true);
            },

            handleVisibilityChange() {
                this.isTabActive = !document.hidden;

                if (this.isTabActive) {
                    this.getActiveCapacity();
                }
            },

            hasUnreadMessages(id) {
                const item = this.items.find((data) => data.id === id);

                if (!item) {
                    return false;
                }

                if (item.unreadMessages > 0) {
                    return true;
                }

                return false;
            },

            truncateString(string, length) {
                if (string.length > length) {
                    return `${string.slice(0, length)}...`;
                }

                return string;
            },

            getTooltip(id) {
                const item = this.items.find((data) => data.id === id);

                if (!item) {
                    return '';
                }

                if (item.unreadMessages > 0) {
                    return `${this.truncateString(item.caseId, 20)} (${item.unreadMessages} ${this.$t('newMessages')})`;
                }

                return `${this.truncateString(item.caseId, 20)} (${item.capacityPercentage}%)`;
            },
        },
    };
</script>

<i18n>
    {
        "en": {
            "moduleCard": "Module Card",
            "moduleCardTooltip": "Click to open the module",
            "newMessages": "new messages"
        },
        "sv": {
            "moduleCard": "Modulkort",
            "moduleCardTooltip": "Klicka för att öppna modulen",
            "newMessages": "nya meddelanden"
        }
    }
</i18n>

<style scoped>
    .module-card {
        position: absolute;
        width: 300px;
        height: 32px;
        bottom: 16px;
        left: 136px;
        z-index: 1000;
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 12px;
        transition: all 0.5s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px;
    }

    .module-linear {
        background: #efefef;
        height: 5px;
        width: 100%;
        left: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        border-radius: 4px;
        gap: 2px;
    }

    .module-bar {
        height: 32px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .module-bar-line {
        height: 5px;
        opacity: 0.6;
        transition: all 0.5s ease;
        background: var(--v-primary-base);
        border-radius: 4px;
    }

    .module-line-hover {
        opacity: 1;
    }

    .module-card-hover {
        cursor: pointer;
    }

    .module-bar-unread {
        animation: pulse 3s infinite;
    }

    @keyframes pulse {
        0% {
            background-color: var(--v-color1-base);
        }
        50% {
            background-color: var(--v-primary-base);
        }
        100% {
            background-color: var(--v-color1-base);
        }
    }
</style>
