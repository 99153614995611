import cnfg from '@/app/config';
import clock from '@/assets/DivertIcons/clock.png';
import available from '@/assets/DivertIcons/available.png';
import lunch from '@/assets/DivertIcons/lunch_break.png';
import briefcase from '@/assets/DivertIcons/briefcase.png';
import vacation from '@/assets/DivertIcons/vacation.png';
import sick from '@/assets/DivertIcons/patient.png';
import dnd from '@/assets/DivertIcons/busy.png';
import gfd from '@/assets/DivertIcons/away.png';
import child from '@/assets/DivertIcons/pediatrics.png';
import errand from '@/assets/DivertIcons/horse.png';
import offert from '@/assets/DivertIcons/offert.png';
import paus from '@/assets/DivertIcons/coffee_break.png';
import storage from '@/assets/DivertIcons/at_storage.png';
import colleague from '@/assets/DivertIcons/helping_colleague.png';
import utredning from '@/assets/DivertIcons/utredning.png';
import reclammation from '@/assets/DivertIcons/reclammation.png';
import efterbearbetning from '@/assets/DivertIcons/efterbearbetning.png';
import { addHours } from 'date-fns';
import i18n from '../i18n';
import store from '../store';
import { presence } from '../templates/colors.template';

export const dateRegex = /(\d{4}-\d{2}-\d{2})$/;
export const dateTimeRegex = /\d{4}-\d{2}-\d{2}\s(0?\d|1\d|2[0-3]):\d+/;

export const urlRegex = /(https?:\/\/)?(www\.)?[\w#%+.:=@~-]{2,256}\.[a-z]{2,6}\b([\w#%&+./:=?@~-]*)/gm;

export const tzCorrection = (date) => {
    const tzOffset = new Date().getTimezoneOffset() / 60;
    return addHours(new Date(date), tzOffset);
};

// swedishMobileRegex can not have g tag, because it is used in a loop
export const swedishMobileRegex = /^(\+46|0)\s*(7[0236])\s*(\d{4})\s*(\d{3})$/;
export const mailRegEx =
    /^(?:[\w!#$%&'*+/=?^`{|}~-]+(?:\.[\w!#$%&'*+/=?^`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f!\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[\da-z](?:[\da-z-]*[\da-z])?\.)+[\da-z](?:[\da-z-]*[\da-z])?|\[(?:(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d{1,2}|[\da-z-]*[\da-z]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/i;
export const mailRegExMultiple =
    /(?:[\w!#$%&'*+/=?^`{|}~-]+(?:\.[\w!#$%&'*+/=?^`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f!\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[\da-z](?:[\da-z-]*[\da-z])?\.)+[\da-z](?:[\da-z-]*[\da-z])?|\[(?:(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d{1,2}|[\da-z-]*[\da-z]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/gim;

export const swedishNumberRegex =
    /^(\+46[\s-]?\d{2}[\s-]?\d{3}[\s-]?\d{4})$|^(0\d{2}[\s-]?\d{3}[\s-]?\d{4})$|^(\+46\d[\s-]?\d{3}[\s-]?\d{4})$|^(0\d[\s-]?\d{3}[\s-]?\d{4})$|^(0\d[\s-]?\d{2}[\s-]?\d{4})$|^(\+46\d[\s-]?\d{2}[\s-]?\d{4})$/;

export const internationalNumberRegex = /^\+\d{7,}$/;

export const nonGlobalInternationalNumberRegex =
    /\+(9[679]\d|8[0357-9]\d|6[7-9]\d|5[09]\d|42\d|3[578]\d|2[1-689]\d|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[07]|7|1)\d{1,14}$/;

export const internationalOrInternalNumberRegex = /^\+?\d{3,}$/;

export function toTime(seconds) {
    const date = new Date(null);
    date.setSeconds(seconds);
    return date.toISOString().slice(11, 19);
}

export function getInitials(name) {
    if (name) {
        const regex = /(^[\dA-Za-z])|(\s)([\dA-Za-z])/g;
        const initials = name.match(regex) || [];
        return initials.join('').toUpperCase().replaceAll(' ', '');
    }
    return '';
}

export function focusElementToForceUpdate(context, refName) {
    return new Promise((resolve) => {
        context.$refs[refName].$el.focus();
        setTimeout(() => {
            resolve();
        }, 200);
    });
}

export function removeHtmlTags(text) {
    return text.replaceAll(/<[^>]+>/g, '');
}

export function convertHtmlToPlainText(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Iterate over the children of the body and remove unwanted tags
    for (const child of doc.body.children) {
        if (child.tagName.toLowerCase() !== 'p' && child.tagName.toLowerCase() !== 'br') {
            child.parentNode.replaceChild(document.createTextNode(child.textContent + '\n'), child);
        }
    }

    return doc.body.textContent;
}

// Sec to min:sec
export function secToHourMinSec(secs, format = 'HH:mm:ss') {
    const sec_num = Number.parseInt(secs);
    const hours = format.includes('HH') ? Math.floor(sec_num / 3600) : 0;
    const minutes = format.includes('mm') ? Math.floor(sec_num / 60) % 60 : 0;
    const seconds = format.includes('ss') ? sec_num % 60 : null;
    const time = [hours, minutes, seconds];
    if (format == 'HH:mm:ss') {
        // normal format
        return time
            .map((v) => (v < 10 ? '0' + v : v))
            .filter((v, i) => v !== '00' || i > 0)
            .join(':');
    }
    return time
        .filter((num) => num != null)
        .map((v) => (v < 10 ? '0' + v : v))
        .join(':');
}
export function setGradeNames(score) {
    if (typeof score === 'string') {
        score = Number.parseInt(score);
    }
    switch (score) {
        case -4: {
            return 'Mycket dåligt';
        }

        case -3: {
            return 'Ganska Dåligt';
        }

        case -2: {
            return 'Dåligt';
        }

        case -1: {
            return 'Kan bli bättre';
        }

        case 0: {
            return 'Neutralt';
        }

        case 1: {
            return 'Helt ok';
        }

        case 2: {
            return 'Bra';
        }
        case 3: {
            return 'Ganska bra';
        }
        case 4: {
            return 'Mycket bra';
        }
        case 5: {
            return 'Världsklass';
        }
        default: {
            return 'Oklart';
        }
    }
}

// will use this when we own images
export async function handleProfileImage(dispatch, userId) {
    if (!userId) {
        return require('@/assets/General/no_user.png');
    }
    const result = await dispatch('Users/getUserProfileImageByUserId', { userId });
    if (result == null || result == '') {
        return require('@/assets/General/no_user.png');
    }
    return `data:image/png;base64,${result}`;
}

export function getTapiProfileImage(url) {
    const reg = /^[\w().@-]+\.[^.]+$/i;
    if (!url || url == 'null' || url == '' || !reg.test(url)) {
        return require('@/assets/General/no_user.png');
    }
    return cnfg.mediaUrl + '1touch/img/profile/' + url;
}

export function convertSocialMediaToIcon(socialMedia) {
    switch (socialMedia) {
        case 'facebook': {
            return 'mdi-facebook';
        }

        case 'youtube': {
            return 'mdi-youtube';
        }
        case 'linkedinbusiness': {
            return 'mdi-linkedin';
        }
        case 'instagrambusiness': {
            return 'mdi-instagram';
        }
        case 'twitter': {
            return 'mdi-twitter';
        }
        default: {
            return 'mdi-account';
        }
    }
}

// Loops through object and converts arrays to strings
export function objectStringsToArray(payload) {
    for (const key in payload) {
        if (!Array.isArray(payload[key])) {
            continue;
        }
        let str = '';
        for (const [index, val] of payload[key].entries()) {
            str += val.trim();
            if (payload[key].length - 1 > index) {
                str += ', ';
            }
        }
        payload[key] = str;
    }
    return payload;
}

export function iconChooserLogic(iconText) {
    if (!iconText.includes('.png')) {
        return iconText;
    }

    const text = iconText.slice(0, -4);

    switch (text) {
        case 'available': {
            return available;
        }

        case 'meeting': {
            return clock;
        }

        case 'lunch': {
            return lunch;
        }

        case 'GFD': {
            return gfd;
        }

        case 'business': {
            return briefcase;
        }

        case 'dnd': {
            return dnd;
        }

        case 'vacation': {
            return vacation;
        }

        case 'sick': {
            return sick;
        }

        case 'child': {
            return child;
        }

        case 'errand': {
            return errand;
        }

        case 'offert': {
            return offert;
        }

        case 'coffee_break': {
            return paus;
        }

        case 'at_storage': {
            return storage;
        }

        case 'helping_colleague': {
            return colleague;
        }

        case 'utredning': {
            return utredning;
        }

        case 'reclammation': {
            return reclammation;
        }

        case 'efterbearbetning': {
            return efterbearbetning;
        }

        default: {
            return iconText;
        }
    }
}

export function getPresences() {
    return [
        { id: 0, name: 'Offline' },
        { id: 1, name: 'Tillgänglig' },
        { id: 2, name: 'Upptagen - i samtal' },
        { id: 3, name: 'Visa som offline' },
        { id: 4, name: 'Borta' },
        { id: 5, name: 'Upptagen - stör ej' },
    ];
}

export function debouncer(method, delay) {
    let timerId = null;
    return function (...args) {
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            method.apply(this, args);
        }, delay);
    };
}

export function internalTypeTranslate(type) {
    switch (type) {
        case 'email': {
            return 'Mail';
        }
        case 'chat': {
            return 'Chatt';
        }
        case 'call': {
            return 'Samtal';
        }
        case 'callback': {
            return 'Callback';
        }
        case 'sms': {
            return 'SMS';
        }
        case 'social': {
            return 'Social';
        }
        case 'form': {
            return 'Formulär';
        }
        default: {
            return 'Okänt';
        }
    }
}

export function redirectFunctionType(modifier) {
    const { ctrl, shift, alt } = modifier;
    const configParams = store.state.System.userSettings.search.searchParams;

    switch (true) {
        // case ctrl && shift && alt: {
        //     // CTRL + SHIFT + ALT = Transfer, skip refferal
        //     return '#24#';
        // }
        // case !ctrl && !shift && alt: {
        //     // Alt = Connect to voicemail
        //     return '#45#';
        // }
        // case !ctrl && shift && !alt: {
        //     // Shift = Transfer to queue
        //     return '#13#';
        // }
        case ctrl: {
            // CTRL = Transfer blind or with inquiry depending on config
            return configParams.ctrlEnter.value;
        }
        default: {
            return configParams.enter.value;
        }
    }
}

export function tooltipTextDisplay(modifier) {
    const { ctrl, shift, alt } = modifier;

    switch (true) {
        // case ctrl && shift && alt: {
        //     // CTRL + SHIFT + ALT
        //     return i18n.t('util.skipRef');
        // }
        case ctrl && shift && !alt: {
            // CTRL + SHIFT
            return isBlindTransferCombination(modifier) ? i18n.t('util.transferNoInquiry') : i18n.t('util.inquiry');
        }
        // case !ctrl && shift && !alt: {
        //     // SHIFT
        //     return i18n.t('util.transferToQueue');
        // }
        case ctrl && !shift && !alt: {
            // CTRL
            return isBlindTransferCombination(modifier) ? i18n.t('util.transferNoInquiry') : i18n.t('util.inquiry');
        }
        // case !ctrl && !shift && alt: {
        //     // ALTatch
        //     return i18n.t('util.connectMailbox');
        // }
        default: {
            return i18n.t('util.call');
        }
    }
}

export function isBlindTransferCombination(modifier) {
    const { userSettings } = store.state.System;
    const { blindTransferModifier, blindTransferNumber } = userSettings.search.searchParams;
    const blindTransferNumberValue = blindTransferNumber.value;
    const allowedBlindTransfers = userSettings.conversations.allowBlindTransfer.active;

    // Never allow blind transfer if no number is set or blind transfers are disabled in config
    if (!blindTransferNumberValue.length || !allowedBlindTransfers) {
        return false;
    }

    return !!(
        blindTransferModifier.alt.value == modifier.alt &&
        blindTransferModifier.ctrl.value == modifier.ctrl &&
        blindTransferModifier.shift.value == modifier.shift
    );
}

export function getPresenceColor(presenceid) {
    if (typeof presenceid === 'string') {
        presenceid = Number.parseInt(presenceid);
    }
    switch (presenceid) {
        case 0: {
            // STATE_LOGGED_OFF=0 - grey
            return presence.offline;
        }

        case 1: {
            // STATE_AVAILABLE=1 - green
            return presence.available;
        }

        case 2: {
            // STATE_SPEAKING=2 – red
            return presence.inCall;
        }

        case 3: {
            // STATE_NOT_AVAILABLE= 3 display as logged off
            return presence.offline;
        }

        case 4: {
            // STATE_AWAY=4 - yellow
            return presence.away;
        }

        case 5: {
            // STATE_DND=5 - 5 red
            return presence.dnd;
        }

        default: {
            return presence.offline;
        }
    }
}

export function AvailabilityTypes(id) {
    if (typeof id === 'string') {
        id = Number.parseInt(id);
    }
    let val = '';
    switch (id) {
        case 0: {
            val = ['Utloggad', 'grey'];
            break;
        }
        case 1: {
            val = ['Inloggad', 'green'];
            break;
        }
        case 2: {
            val = ['Inloggad', 'indigo'];
            break;
        }
        case 3: {
            val = ['Utloggad', 'grey'];
            break;
        }
        case 4: {
            val = ['Disabled', 'cyan'];
            break;
        }
        case 5: {
            val = ['Upptagen', 'red'];
            break;
        }
        default: {
            val = ['N/A', 'red'];
            break;
        }
    }
    return val;
}

export function getPresenceIconUrl(presenceid) {
    let returnImageUrl = '';
    const id = Number.parseInt(presenceid);
    switch (id) {
        case 0: {
            // STATE_LOGGED_OFF=0 - grey
            returnImageUrl = 'loggedoff.png';
            break;
        }

        case 1: {
            // STATE_AVAILABLE=1 - green
            returnImageUrl = 'available.png';
            break;
        }

        case 2: {
            // STATE_SPEAKING=2 – red
            returnImageUrl = 'speaking.png';
            break;
        }

        case 3: {
            // STATE_NOT_AVAILABLE= 3 display as logged off
            returnImageUrl = 'loggedoff.png';
            break;
        }

        case 4: {
            // STATE_AWAY=4 - yellow
            returnImageUrl = 'away.png';
            break;
        }

        case 5: {
            // STATE_DND=5 - 5 red
            returnImageUrl = 'dnd.png';
            break;
        }
        default: {
            returnImageUrl = 'loggedoff.png';
            break;
        }
    }

    const images = require.context('../assets/General/StateIcons/', false, /\.png$/);
    return images('./' + returnImageUrl);
}

export function getSpeakingIconUrl(CallNow) {
    let returnImageUrl = '';
    const id = Number.parseInt(CallNow);
    switch (id) {
        case 0: {
            // STATE_LOGGED_OFF=0 - grey
            returnImageUrl = 'not_speaking.png';
            break;
        }
        default: {
            returnImageUrl = 'speaking.png';
            break;
        }
    }

    const images = require.context('../assets/General/StateIcons/', false, /\.png$/);
    return images('./' + returnImageUrl);
}

export function getPresenceStatus(presenceid) {
    if (typeof presenceid === 'string') {
        presenceid = Number.parseInt(presenceid);
    }
    switch (presenceid) {
        case 0: {
            // STATE_LOGGED_OFF=0 - grey
            return { status: 'Offline', color: 'grey' };
        }

        case 1: {
            // STATE_AVAILABLE=1 - green
            return { status: 'Tillgänglig', color: 'green' };
        }

        case 2: {
            // STATE_SPEAKING=2 – red
            return { status: 'Upptagen', color: 'red' };
        }

        case 3: {
            // STATE_NOT_AVAILABLE= 3 display as logged off
            return { status: 'Offline', color: 'grey' };
        }

        case 4: {
            // STATE_AWAY=4 - yellow
            return { status: 'Borta', color: 'yellow' };
        }

        case 5: {
            // STATE_DND=5 - 5 red
            return { status: 'Upptagen', color: 'red' };
        }
        default: {
            return { status: 'Offline', color: 'grey' };
        }
    }
}

export function getCallStateImageUrl(callStateId) {
    let returnCallStateImage = '';
    callStateId = callStateId.toString();

    switch (callStateId) {
        case '-1':
        case '0': {
            // STATE UNKNOWN = -1, 0
            returnCallStateImage = 'img/icons/callstate/unknown.png';
            break;
        }

        case '1': {
            // STATE NOT SPEAKING =1
            returnCallStateImage = 'img/icons/callstate/not-speaking.png';
            break;
        }

        case '2': {
            // STATE SPEAKING=2
            returnCallStateImage = 'img/icons/callstate/speaking.png';
            break;
        }

        case '3': {
            // STATE RINGING= 3
            returnCallStateImage = 'img/icons/callstate/ringing.gif';
            break;
        }

        case '4': {
            // STATE GROUP RINGING=4
            returnCallStateImage = 'img/icons/callstate/ringing.gif';
            break;
        }
        default: {
            returnCallStateImage = 'img/icons/callstate/unknown.png';
            break;
        }
    }

    return returnCallStateImage;
}

export function randomColor() {
    const n = Math.floor(Math.random() * 27);

    switch (n) {
        case 0: {
            return '#093145';
        }

        case 1: {
            return '#1496bb';
        }

        case 2: {
            return '#0C374D';
        }

        case 3: {
            return '#3C6478';
        }

        case 4: {
            return '#93A661';
        }

        case 5: {
            return '#b5c689';
        }
        case 6: {
            return '#d3b53d';
        }
        case 7: {
            return '#efd469';
        }
        case 8: {
            return '#da621e';
        }
        case 9: {
            return '#f26d21';
        }
        case 10: {
            return '#ad2a1a';
        }
        case 11: {
            return '#cd2f1d';
        }
        case 12: {
            return '#60bcd2';
        }
        case 13: {
            return '#929292';
        }
        case 14: {
            return '#feb019';
        }
        case 15: {
            return '#eb649e';
        }
        case 16: {
            return '#efb57c';
        }
        case 17: {
            return '#cc8f7d';
        }
        case 18: {
            return '#5445aa';
        }
        case 19: {
            return '#feb019';
        }
        case 20: {
            return '#ff4460';
        }
        case 21: {
            return '#775ed0';
        }
        case 22: {
            return '#008ffb';
        }
        case 23: {
            return '#00e396';
        }
        case 24: {
            return '#ef628e';
        }
        case 25: {
            return '#61bdd3';
        }
        case 26: {
            return '#2cb5a3';
        }
        case 27: {
            return '#cf73e0';
        }
        default: {
            return '#6897bb';
        }
    }
}
export function getColorByIndex(i) {
    // Special colors for statistik, blue, yellow, red etc
    switch (i) {
        case 0: {
            return '#60bcd2';
        }
        case 1: {
            return '#929292';
        }
        case 2: {
            return '#feb019';
        }
        case 3: {
            return '#eb649e';
        }
        case 4: {
            return '#efb57c';
        }
        case 5: {
            return '#cc8f7d';
        }
        case 6: {
            return '#5445aa';
        }
        case 7: {
            return '#feb019';
        }
        case 8: {
            return '#ff4460';
        }
        case 9: {
            return '#775ed0';
        }
        case 10: {
            return '#008ffb';
        }
        case 11: {
            return '#00e396';
        }
        case 12: {
            return '#ef628e';
        }
        case 13: {
            return '#61bdd3';
        }
        case 14: {
            return '#2cb5a3';
        }
        case 15: {
            return '#cf73e0';
        }
        default: {
            return randomColor();
        }
    }
}

export async function instantiateConnection(event, adress, type, modifier = '') {
    const standardApps = await store.dispatch('System/getStandardApps');
    const clientUsesMac = /(mac|iphone|ipod|ipad)/i.test(navigator.userAgent);

    const appUrlMap = {
        phone: !clientUsesMac ? `tloswyx:${adress + modifier}` : `tel:${adress + modifier}`,
        mail: {
            outlook: `ms-outlook:${adress}`,
            default: `mailto:${adress}`,
        },
        chat: {
            skype: `skype:${adress}?chat`,
            teams: `msteams:${adress}`,
            default: `im:${adress}`,
        },
    };

    const appType = appUrlMap[type];
    if (!appType) return;

    if (typeof appType === 'string') {
        event.preventDefault();
        redirectToUrl(appType);
    } else if (typeof appType === 'object') {
        const app = standardApps.find((app) => {
            return app.type.toLowerCase() === type;
        });
        const appUrl = appType[app?.name.toLowerCase()] || appType.default;
        event.preventDefault();
        redirectToUrl(appUrl);
    }
}

function redirectToUrl(url) {
    const { location } = window;
    try {
        location.href = url;
    } catch (error) {
        console.log('error in instantiateConnection', error);
    }
}

export function jwtParser(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replaceAll('-', '+').replaceAll('_', '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map((c) => '%' + ('00' + c.codePointAt(0).toString(16)).slice(-2))
            .join(''),
    );

    return JSON.parse(jsonPayload);
}

export function uuidv4WithMathR() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replaceAll(/[xy]/g, (c) => {
        const r = Math.trunc(Math.random() * 16);
        const v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function translater(word) {
    switch (word) {
        case 'Total': {
            return 'Totalt';
        }
        case 'Open': {
            return 'Öppen';
        }
        case 'Closed': {
            return 'Stängd';
        }
        case 'Investigation': {
            return 'Undersökning';
        }
        case 'Deleted': {
            return 'Raderad';
        }
        case 'Ongoing': {
            return 'Pågående';
        }
        case 'Cases': {
            return 'Ärenden';
        }
        case 'Chat': {
            return 'Chatt';
        }
        case 'Screen': {
            return 'Skärm';
        }
        default: {
            return word;
        }
    }
}

export function getBaseColor(index) {
    let basecolor = '';
    const x = index;

    switch (x) {
        case 0: {
            // SOFT+
            basecolor = '#d0d0d0';
            break;
        }

        case 1: {
            // GRAY
            basecolor = '#939393';
            break;
        }

        case 2: {
            // WHITE+
            basecolor = '#f7f7f7';
            break;
        }

        case 3: {
            // WHITE-
            basecolor = '#f2f2f2';
            break;
        }

        case 4: {
            // BLACK
            basecolor = '#2b2b2b';
            break;
        }

        case 5: {
            // BLUE-
            basecolor = 'rgb(225, 241, 245)';
            break;
        }

        case 6: {
            basecolor = 'linear-gradient(#efefef, #d0d0d0)';
            break;
        }

        case 7: {
            basecolor = 'linear-gradient(#d0d0d0, #efefef)';
            break;
        }

        case 8: {
            basecolor = 'linear-gradient(#24a796, #1B9384';
            break;
        }

        default: {
            basecolor = '#d0d0d0';
            break;
        }
    }

    return basecolor;
}

// Function for checking Optional Chaining (Viktor B)

export function isAvailable(parent, child) {
    try {
        const childArray = String(child).split('.');
        let evaluted = parent;
        for (const x of childArray) {
            evaluted = evaluted[x];
        }
        return !!evaluted;
    } catch {
        return false;
    }
}

export function dateRangeOverlaps(a_start, a_end, b_start, b_end) {
    if (a_start <= b_start && b_start <= a_end) {
        return true;
    } // b starts in a
    if (a_start <= b_end && b_end <= a_end) {
        return true;
    } // b ends in a
    return !!(b_start < a_start && a_end < b_end);
}

export function dateValidation(dateStart, timeStart, dateEnd, timeEnd) {
    if (dateStart && dateEnd) {
        if (
            !/(\d{4})-(\d{2})-(\d{2})/.test(dateStart) ||
            !/(\d{4})-(\d{2})-(\d{2})/.test(dateEnd) ||
            dateStart.length != 10 ||
            dateEnd.length != 10
        ) {
            return { valid: false, message: this.$t('timeFormattingErrors.wrongTimeFormat') };
        }
    } else {
        return { valid: false, message: this.$t('timeFormattingErrors.unknownErrorDate') };
    }
    if (timeStart && timeEnd) {
        if (
            !/(\d{2}):(\d{2})/.test(timeStart) ||
            !/(\d{2}):(\d{2})/.test(timeEnd) ||
            timeStart.length != 5 ||
            timeEnd.length != 5
        ) {
            return { valid: false, message: this.$t('global.format.') };
        }
    } else {
        return { valid: false, message: this.$t('timeFormattingErrors.unknownErrorTime') };
    }

    const start = new Date(`${dateStart} ${timeStart}`);
    const end = new Date(`${dateEnd} ${timeEnd}`);
    if (start > end) {
        return { valid: false, message: this.$t('timeFormattingErrors.endBeforeStart') };
    }

    return { valid: true, message: '' };
}

export function encodeBase64(data) {
    if (!data) {
        return '';
    }
    return Buffer.from(data).toString('base64');
}
export function decodeBase64(base64Data) {
    if (!base64Data) {
        return '';
    }
    return Buffer.from(base64Data, 'base64').toString('utf8');
}

export function linkify(inputText) {
    if (inputText.length === 0) {
        return '<i>Tomt</i>';
    }
    let replacedText = '';
    let replacePattern1 = '';
    let replacePattern2 = '';
    let replacePattern3 = '';

    // URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[\w!#%&+,./:;=?@|~-]*[\w#%&+/=@|~-])/gim;
    replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^/])(www\.\S+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    // Change email addresses to mailto:: links.
    replacePattern3 = /(([\w.-])+@[_a-z]+?(\.[a-z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText;
}

export function exportCSV(csvText, fileName) {
    const csvBlob = new Blob([csvText], { type: 'text/csv;charset=utf-8;' });
    const csvUrl = URL.createObjectURL(csvBlob);
    const link = document.createElement('a');
    link.setAttribute('href', csvUrl);
    link.setAttribute('download', fileName);
    document.body.append(link);
    link.click();
    link.remove();
}

export function getSortingByObjectKey(keyToSortBy) {
    return (a, b) => {
        if (a[keyToSortBy] > b[keyToSortBy]) {
            return 1;
        }
        if (b[keyToSortBy] > a[keyToSortBy]) {
            return -1;
        }
        return 0;
    };
}
/**
 * Replaces template placeholders in a text with provided values from client, agent, and sender.
 *
 * @param {string} [text=''] - The template text containing placeholders.
 * @param {Object} [params={}] - An object containing client, agent, and sender data.
 * @param {Object<string, string>} [params.client={}] - Client data for placeholder replacement.
 * @param {Object<string, string>} [params.agent={}] - Agent data for placeholder replacement.
 * @param {string} [params.sender] - Sender email for placeholder replacement.
 * @returns {string} The text with placeholders replaced by their corresponding values.
 */
export function replaceTemplateValues(text = '', { client = {}, agent = {}, sender } = {}) {
    if (!text || client === null || agent === null) {
        console.warn("Couldn't replace template values. Missing text or client/agent data. Added the text as is.");
        return text;
    }

    const missing = i18n.t('util.variableMissing');
    let resultText = text;

    // Prepare placeholders and their corresponding values
    const placeholders = {
        '{{AgentName}}': agent.userName || missing,
        '{{AgentFirstName}}': agent.userName ? agent.userName.split(' ')[0] : missing,
        '{{AgentMail}}': agent.Email || missing,
        '{{email}}': agent.email || missing,
        '{{SenderMail}}': sender || missing,
    };

    // Add dynamic client placeholders
    for (const [key, value] of Object.entries(client)) {
        placeholders[`{{${key}}}`] = value || missing;
    }

    // Add dynamic agent placeholders, excluding already added keys
    for (const [key, value] of Object.entries(agent)) {
        if (!['userName', 'email'].includes(key)) {
            placeholders[`{{${key}}}`] = value || missing;
        }
    }

    // Replace placeholders in the text
    for (const [placeholder, value] of Object.entries(placeholders)) {
        resultText = resultText.replaceAll(placeholder, value);
    }

    // Replace any remaining placeholders with the missing value
    resultText = resultText.replace(/{{\w+?}}/g, missing);

    return resultText;
}

export function htmlToText(html) {
    const htmlRegexG = /<(?:"[^"]*"["']*|'[^']*'["']*|[^"'>])+>/g;
    return html.replaceAll(htmlRegexG, '');
}

export function hasHtml(content) {
    const htmlRegex = /<[^>]+>/g;
    return htmlRegex.test(content);
}

export function replaceSwedishLetters(str) {
    return str.toLowerCase().replace('å', 'a').replace('ä', 'a').replace('ö', 'o');
}

export function firstWordCapitilize(string) {
    const words = string.toLowerCase().split(' ');
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].slice(1);
    }
    return words.join(' ');
}

export function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email) && email.match(/@/g).length === 1;
}
// TODO: remove deep copy util and replace all occurances with structured clone
export function deepCopy(o) {
    // eslint-disable-next-line c1/no-empty-declaration
    let output;
    // eslint-disable-next-line c1/no-empty-declaration
    let v;
    // eslint-disable-next-line c1/no-empty-declaration
    let key;
    // eslint-disable-next-line prefer-const
    output = Array.isArray(o) ? [] : {};
    for (const key in o) {
        v = o[key];
        output[key] = typeof v === 'object' ? deepCopy(v) : v;
    }
    return output;
}

export function safeJsonParse(str) {
    try {
        return JSON.parse(str);
    } catch (error) {
        return null;
    }
}

export function encodeToBase64(str) {
    return Buffer.from(str).toString('base64');
}

export function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCodePoint(bytes[i]);
    }
    return window.btoa(binary);
}

export function generateGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replaceAll(/[xy]/g, (c) => {
        const r = Math.trunc(Math.random() * 16);
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function extractValidValuesFromObject(obj, expression) {
    // Extracts valid values from an object based on a given expression
    // I.e: extractValidValuesFromObject({a: 'pelle@pelle.com', b: 'invalid'}, (val) => emailRegex.test(val));
    // Returns array of valid emails
    const newAddresses = [];
    for (const key of Object.keys(obj)) {
        if (expression(obj[key])) {
            newAddresses.push(obj[key]);
        }
    }

    return newAddresses;
}

export function extractValidValuesFromArray(array, expression, property = null) {
    // Extracts valid values from an array based on a given expression
    // Optionally, extracts a specific property from each array element
    // I.e. extractValidValuesFromArray([{Email: 'kalle@kalle.com', Name: 'Kalle'}, {Email: 'invalid', Name: 'Pelle'}], (val) => emailRegex.test(val.Email), 'Email');
    // Would return an array of the emails and not the names
    const newArray = [];
    for (const value of array) {
        if (expression(value)) {
            newArray.push(property ? value[property] : value);
        }
    }
    return newArray;
}

export function dislodgeWindow(href) {
    const { origin } = window.location;
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (!isChrome) {
        window.open(
            `microsoft-edge:${origin}/#/${href.slice(1, href.length)}`,
            '_blank',
            'width=10,height=10,left=-100,top=-100',
        );
    } else {
        window.open(`${origin}/#/${href.slice(1, href.length)}`, '_blank', 'width=1400,height=800,left=100,top=100');
    }
}

export function clamp(num, min, max) {
    return Math.min(Math.max(num, min), max);
}

/**
 * Filters an array of objects based on whether a specific key's value exists in another array.
 *
 * @param {Array} configArray - Array of values to filter against.
 * @param {Array} templateArray - Array of objects to be filtered.
 * @param {String} identifier - The key in the objects to check against the configArray.
 * @returns {Array} - Filtered array of objects.
 */

export function filterObjectsByKeys(configObj, objectsArray, keyProperty) {
    return objectsArray.filter((object) => configObj[object[keyProperty]] === true);
}

export function getActiveFromSettings(object) {
    // takes an object from mongo config, excludes the text object and checks the others if active is true. Returns object like:
    // {call: true, social: false, chat: true}

    const newObj = {};
    for (const [key, value] of Object.entries(object)) {
        if (key !== 'text' && typeof value === 'object' && value.hasOwnProperty('active')) {
            newObj[key] = value.active;
        }
    }
    return newObj;
}

export function keyToPascalCase(key) {
    return key.charAt(0).toUpperCase() + key.slice(1);
}

export function keyToCamelCase(key) {
    return key.charAt(0).toLowerCase() + key.slice(1);
}

export function splitArray(array, maxVisibleItems) {
    if (array.length <= maxVisibleItems) {
        return {
            visibleItems: array,
            extraItems: [],
        };
    }
    return {
        visibleItems: array.slice(0, maxVisibleItems - 1),
        extraItems: array.slice(maxVisibleItems - 1),
    };
}

/**
 * Compares two objects and returns an object that includes the fields that have changed.
 *
 * @param {Object} oldObj - The original object.
 * @param {Object} newObj - The new object.
 * @returns {Object} An object that includes the fields that have changed.
 */
export function getChangedKeysInObject(oldObj, newObj) {
    const changedFields = {};
    for (const key in oldObj) {
        if (JSON.stringify(oldObj[key]) !== JSON.stringify(newObj[key])) {
            changedFields[key] = newObj[key];
        }
    }
    return changedFields;
}

export function containsHtmlElement(htmlContent, selector) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    return doc.querySelector(selector) !== null;
}

export function throttle(func, limit) {
    let inThrottle = false;
    return function (...args) {
        if (!inThrottle) {
            func.apply(this, args);
            inThrottle = true;
            setTimeout(() => {
                inThrottle = false;
            }, limit);
        }
    };
}

export function isArrayEqual(arr1, arr2) {
    // First, check if the arrays have the same length
    if (arr1.length !== arr2.length) {
        return false;
    }

    // Sort both arrays and compare element by element
    const sortedArr1 = [...arr1].sort((a, b) => a - b);
    const sortedArr2 = [...arr2].sort((a, b) => a - b);

    return sortedArr1.every((value, index) => value === sortedArr2[index]);
}

export const allIcons = [
    'account',
    'account-alert',
    'account-box',
    'account-box-outline',
    'account-check',
    'account-circle',
    'account-key',
    'tooltip-account',
    'account-minus',
    'account-multiple',
    'account-multiple-outline',
    'account-multiple-plus',
    'account-network',
    'account-outline',
    'account-plus',
    'account-remove',
    'account-search',
    'account-star',
    'account-switch',
    'airballoon',
    'airplane',
    'airplane-off',
    'alarm',
    'alarm-check',
    'alarm-multiple',
    'alarm-off',
    'alarm-plus',
    'album',
    'alert',
    'alert-box',
    'alert-circle',
    'alert-octagon',
    'alpha',
    'alphabetical',
    'google-cloud',
    'ambulance',
    'android',
    'android-studio',
    'apple',
    'apple-finder',
    'apple-ios',
    'apple-safari',
    'apps',
    'archive',
    'arrange-bring-forward',
    'arrange-bring-to-front',
    'arrange-send-backward',
    'arrange-send-to-back',
    'arrow-all',
    'arrow-bottom-left',
    'arrow-bottom-right',
    'arrow-collapse',
    'arrow-down',
    'arrow-down-bold',
    'arrow-down-bold-circle',
    'arrow-down-bold-circle-outline',
    'arrow-down-bold-hexagon-outline',
    'arrow-expand',
    'arrow-left',
    'arrow-left-bold',
    'arrow-left-bold-circle',
    'arrow-left-bold-circle-outline',
    'arrow-left-bold-hexagon-outline',
    'arrow-right',
    'arrow-right-bold',
    'arrow-right-bold-circle',
    'arrow-right-bold-circle-outline',
    'arrow-right-bold-hexagon-outline',
    'arrow-top-left',
    'arrow-top-right',
    'arrow-up',
    'arrow-up-bold',
    'arrow-up-bold-circle',
    'arrow-up-bold-circle-outline',
    'arrow-up-bold-hexagon-outline',
    'at',
    'attachment',
    'auto-fix',
    'auto-upload',
    'baby',
    'backburger',
    'backup-restore',
    'bank',
    'barcode',
    'barley',
    'barrel',
    'basket',
    'basket-fill',
    'basket-unfill',
    'battery',
    'battery-10',
    'battery-20',
    'battery-30',
    'battery-40',
    'battery-50',
    'battery-60',
    'battery-70',
    'battery-80',
    'battery-90',
    'battery-alert',
    'battery-charging-100',
    'battery-charging-20',
    'battery-charging-30',
    'battery-charging-40',
    'battery-charging-60',
    'battery-charging-80',
    'battery-charging-90',
    'battery-minus',
    'battery-negative',
    'battery-outline',
    'battery-plus',
    'battery-positive',
    'battery-unknown',
    'beach',
    'beaker',
    'beaker-outline',
    'beer',
    'bell',
    'bell-off',
    'bell-outline',
    'bell-ring',
    'bell-ring-outline',
    'bell-sleep',
    'beta',
    'bike',
    'binoculars',
    'bio',
    'biohazard',
    'bitbucket',
    'black-mesa',
    'blinds',
    'block-helper',
    'bluetooth',
    'bluetooth-audio',
    'bluetooth-connect',
    'bluetooth-settings',
    'bluetooth-transfer',
    'blur',
    'blur-linear',
    'blur-off',
    'blur-radial',
    'bone',
    'book',
    'book-multiple',
    'book-open',
    'book-variant',
    'bookmark',
    'bookmark-check',
    'bookmark-music',
    'bookmark-outline',
    'bookmark-plus',
    'bookmark-remove',
    'border-all',
    'border-bottom',
    'border-color',
    'border-horizontal',
    'border-inside',
    'border-left',
    'border-none',
    'border-outside',
    'border-right',
    'border-top',
    'border-vertical',
    'bowling',
    'box',
    'briefcase',
    'briefcase-check',
    'briefcase-download',
    'briefcase-upload',
    'brightness-1',
    'brightness-2',
    'brightness-3',
    'brightness-4',
    'brightness-5',
    'brightness-6',
    'brightness-7',
    'brightness-auto',
    'broom',
    'brush',
    'bug',
    'bulletin-board',
    'bullhorn',
    'bus',
    'cake',
    'cake-variant',
    'calculator',
    'calendar',
    'calendar-blank',
    'calendar-check',
    'calendar-clock',
    'calendar-multiple',
    'calendar-multiple-check',
    'calendar-plus',
    'calendar-remove',
    'calendar-text',
    'calendar-today',
    'camcorder',
    'camcorder-off',
    'camera',
    'camera-front',
    'camera-front-variant',
    'camera-iris',
    'camera-party-mode',
    'camera-rear',
    'camera-rear-variant',
    'camera-switch',
    'camera-timer',
    'candycane',
    'car',
    'car-wash',
    'carrot',
    'cart',
    'cart-outline',
    'cash',
    'cash-100',
    'cash-multiple',
    'cast',
    'cast-connected',
    'castle',
    'cat',
    'cellphone',
    'cellphone-dock',
    'cellphone-link',
    'cellphone-link-off',
    'cellphone-settings',
    'chair-school',
    'chart-arc',
    'chart-areaspline',
    'chart-bar',
    'chart-histogram',
    'chart-line',
    'chart-pie',
    'check',
    'check-all',
    'checkbox-blank',
    'checkbox-blank-circle',
    'checkbox-blank-circle-outline',
    'checkbox-blank-outline',
    'checkbox-marked',
    'checkbox-marked-circle',
    'checkbox-marked-circle-outline',
    'checkbox-marked-outline',
    'checkbox-multiple-blank',
    'checkbox-multiple-blank-outline',
    'checkbox-multiple-marked',
    'checkbox-multiple-marked-outline',
    'checkerboard',
    'chevron-double-down',
    'chevron-double-left',
    'chevron-double-right',
    'chevron-double-up',
    'chevron-down',
    'chevron-left',
    'chevron-right',
    'chevron-up',
    'church',
    'city',
    'clipboard',
    'clipboard-account',
    'clipboard-alert',
    'clipboard-arrow-down',
    'clipboard-arrow-left',
    'clipboard-check',
    'clipboard-outline',
    'clipboard-text',
    'clippy',
    'clock',
    'clock-fast',
    'close',
    'close-box',
    'close-box-outline',
    'close-circle',
    'close-circle-outline',
    'close-network',
    'closed-caption',
    'cloud',
    'apple-icloud',
    'cloud-check',
    'cloud-circle',
    'cloud-download',
    'cloud-outline',
    'cloud-off-outline',
    'cloud-upload',
    'cloud-refresh',
    'cloud-lock',
    'cloud-lock-outline',
    'cloud-question',
    'cloud-tags',
    'cloud-print',
    'cloud-print-outline',
    'cloud-search',
    'cloud-search-outline',
    'code-array',
    'code-braces',
    'code-equal',
    'code-greater-than',
    'code-less-than',
    'code-less-than-or-equal',
    'code-not-equal',
    'code-not-equal-variant',
    'code-string',
    'code-tags',
    'codepen',
    'coffee',
    'coffee-to-go',
    'color-helper',
    'comment',
    'comment-account',
    'comment-account-outline',
    'comment-alert',
    'comment-alert-outline',
    'comment-check',
    'comment-check-outline',
    'comment-multiple-outline',
    'comment-outline',
    'comment-plus-outline',
    'comment-processing',
    'comment-processing-outline',
    'comment-remove-outline',
    'comment-text',
    'comment-text-outline',
    'compare',
    'compass',
    'compass-outline',
    'console',
    'content-copy',
    'content-cut',
    'content-duplicate',
    'content-paste',
    'content-save',
    'content-save-all',
    'contrast',
    'contrast-box',
    'contrast-circle',
    'cow',
    'credit-card',
    'credit-card-multiple',
    'crop',
    'crop-free',
    'crop-landscape',
    'crop-portrait',
    'crop-square',
    'crosshairs',
    'crosshairs-gps',
    'crown',
    'cube',
    'cube-outline',
    'cube-unfolded',
    'cup',
    'cup-water',
    'currency-btc',
    'currency-eur',
    'currency-gbp',
    'currency-inr',
    'currency-rub',
    'currency-try',
    'currency-usd',
    'cursor-default',
    'cursor-default-outline',
    'cursor-move',
    'cursor-pointer',
    'database',
    'database-minus',
    'database-outline',
    'database-plus',
    'debug-step-into',
    'debug-step-out',
    'debug-step-over',
    'decimal-decrease',
    'decimal-increase',
    'delete',
    'delete-variant',
    'deskphone',
    'desktop-tower',
    'details',
    'deviantart',
    'diamond',
    'dice-1',
    'dice-2',
    'dice-3',
    'dice-4',
    'dice-5',
    'dice-6',
    'directions',
    'disqus',
    'division',
    'division-box',
    'dns',
    'domain',
    'dots-horizontal',
    'dots-vertical',
    'download',
    'drag',
    'drag-horizontal',
    'drag-vertical',
    'drawing',
    'drawing-box',
    'drone',
    'dropbox',
    'drupal',
    'duck',
    'dumbbell',
    'earth',
    'earth-off',
    'eject',
    'elevation-decline',
    'elevation-rise',
    'elevator',
    'email',
    'email-open',
    'email-outline',
    'emoticon',
    'emoticon-cool',
    'emoticon-devil',
    'emoticon-happy',
    'emoticon-neutral',
    'emoticon-poop',
    'emoticon-sad',
    'emoticon-tongue',
    'engine',
    'engine-outline',
    'equal',
    'equal-box',
    'eraser',
    'escalator',
    'evernote',
    'exclamation',
    'exit-to-app',
    'export',
    'eye',
    'eye-off',
    'eyedropper',
    'eyedropper-variant',
    'facebook',
    'facebook-messenger',
    'factory',
    'fan',
    'fast-forward',
    'ferry',
    'file',
    'file-cloud',
    'file-delimited',
    'file-document',
    'file-excel-box',
    'file-find',
    'file-image',
    'file-multiple',
    'file-music',
    'file-outline',
    'file-pdf-box',
    'file-powerpoint',
    'file-powerpoint-box',
    'file-presentation-box',
    'file-video',
    'file-word',
    'file-word-box',
    'film',
    'filmstrip',
    'filmstrip-off',
    'filter',
    'filter-outline',
    'filter-remove-outline',
    'filter-variant',
    'fire',
    'firefox',
    'fish',
    'flag',
    'flag-checkered',
    'flag-outline',
    'flag-triangle',
    'flag-variant',
    'flash',
    'flash-auto',
    'flash-off',
    'flashlight',
    'flashlight-off',
    'flip-to-back',
    'flip-to-front',
    'floppy',
    'flower',
    'folder',
    'folder-account',
    'folder-download',
    'folder-google-drive',
    'folder-image',
    'folder-lock',
    'folder-lock-open',
    'folder-move',
    'folder-multiple',
    'folder-multiple-image',
    'folder-multiple-outline',
    'folder-outline',
    'folder-plus',
    'folder-remove',
    'folder-upload',
    'food',
    'food-apple',
    'food-variant',
    'football',
    'football-helmet',
    'format-align-center',
    'format-align-justify',
    'format-align-left',
    'format-align-right',
    'format-bold',
    'format-clear',
    'format-color-fill',
    'format-float-center',
    'format-float-left',
    'format-float-none',
    'format-float-right',
    'format-header-1',
    'format-header-2',
    'format-header-3',
    'format-header-4',
    'format-header-5',
    'format-header-6',
    'format-header-decrease',
    'format-header-equal',
    'format-header-increase',
    'format-header-pound',
    'format-indent-decrease',
    'format-indent-increase',
    'format-italic',
    'format-line-spacing',
    'format-list-bulleted',

    'format-paint',
    'format-paragraph',

    'format-size',
    'format-strikethrough',
    'format-subscript',
    'format-superscript',
    'format-text',
    'format-underline',
    'format-wrap-inline',
    'format-wrap-square',
    'format-wrap-tight',
    'format-wrap-top-bottom',
    'forum',
    'forward',
    'fridge',
    'fullscreen',
    'fullscreen-exit',
    'function',
    'gamepad',
    'gamepad-variant',
    'gas-station',
    'gavel',
    'gender-female',
    'gender-male',
    'gender-male-female',
    'gender-transgender',
    'gift',
    'git',
    'github',
    'glass-flute',
    'glass-mug',
    'glass-stange',
    'glass-tulip',
    'glasses',
    'gmail',
    'google',
    'google-chrome',
    'google-circles',
    'google-circles-communities',
    'google-circles-extended',
    'google-circles-group',
    'google-drive',
    'google-earth',
    'google-glass',
    'google-maps',

    'google-play',
    'google-plus',
    'google-hangouts',
    'grid',
    'grid-off',
    'group',

    'guitar-pick',
    'guitar-pick-outline',
    'hand-pointing-right',
    'hanger',

    'harddisk',
    'headphones',
    'headphones-box',
    'headphones-settings',
];
