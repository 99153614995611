import billing from '@/api/billing/billing';
import i18n from '../../i18n';

// State object
const state = {
    invoices: [],
    articles: [],
    periods: [],
    customers: [],
    loaders: {
        customers: false,
    },
    selectedItem: null,
    currentContext: null,
    billableCustomersList: [],
};
// Getter functions
const getters = {};
// Actions
const actions = {
    authorizeIntegration() {
        return new Promise((resolve, reject) => {
            billing
                .authorizeIntegration()
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    checkIfIntegrationIsActive() {
        return new Promise((resolve, reject) => {
            billing
                .checkIfIntegrationIsActive()
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    syncExternals() {
        return new Promise((resolve, reject) => {
            billing
                .syncExternals()
                .then((res) => {
                    if (res.status !== 200) {
                        this._vm.$toasted.show(i18n.t('billingStore.dataSynchingFailed'), {
                            icon: 'mdi-alert-circle',
                            type: 'error',
                        });
                    } else {
                        this._vm.$toasted.show(i18n.t('billingStore.dataSynching'), {
                            icon: 'mdi-check-circle',
                            type: 'success',
                        });
                    }
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(`${i18n.t('billingStore.dataSynchingFailed')} - ${error.response.data}`, {
                        icon: 'mdi-alert-circle',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    getCustomers({ commit, dispatch }) {
        dispatch('setLoadersKey', { customers: true });

        return new Promise((resolve, reject) => {
            billing
                .getCustomers()
                .then((res) => {
                    commit('SET_CUSTOMERS', res.data);
                    dispatch('setLoadersKey', { customers: false });
                    resolve(res.data);
                })
                .catch((error) => {
                    dispatch('setLoadersKey', { customers: false });
                    reject(error);
                });
        });
    },
    getCustomer(_, customerId) {
        return new Promise((resolve, reject) => {
            billing
                .getCustomer(customerId)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    updateCustomer({ commit }, payload) {
        return new Promise((resolve, reject) => {
            billing
                .updateCustomer(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t(res.data.translationKey || res.data.message || res.data), {
                        icon: 'mdi-check',
                        type: 'success',
                    });
                    resolve(res.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    deleteCustomer(_, id) {
        return new Promise((resolve, reject) => {
            billing
                .deleteCustomer(id)
                .then((res) => {
                    if (res.status !== 200) {
                        this._vm.$toasted.show(res.data, {
                            icon: 'mdi-cancel',
                            type: 'error',
                        });
                        resolve(res);
                    } else {
                        this._vm.$toasted.show(i18n.t('billingStore.customerDeleted'), {
                            icon: 'mdi-check-circle',
                            type: 'success',
                        });
                        resolve(res);
                    }
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    addArticlesToCustomer({ commit }, payload) {
        return new Promise((resolve, reject) => {
            billing
                .addArticlesToCustomer(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    deleteArticleFromCustomer({ commit }, payload) {
        return new Promise((resolve, reject) => {
            billing
                .deleteArticleFromCustomer(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getBillableCustomers({ commit }, payload) {
        return new Promise((resolve, reject) => {
            billing
                .getBillableCustomers(payload)
                .then((res) => {
                    commit('SET_CURRENT_CONTEXT', res.data.context);

                    const result = res.data.customers.map((el) => {
                        el.selected = 1; // default selected, used for checkboxes
                        return el;
                    });

                    commit('SET_BILLABLE_CUSTOMERS_LIST', result);
                    resolve(res.data.customers);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getBillableCustomer({ commit }, payload) {
        return new Promise((resolve, reject) => {
            billing
                .getBillableCustomer(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getInvoices({ commit }, payload) {
        return new Promise((resolve, reject) => {
            billing
                .getInvoices(payload)
                .then((res) => {
                    commit('SET_INVOICES', res.data);

                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    createInvoice(_, payload) {
        return new Promise((resolve, reject) => {
            billing
                .createInvoice(payload)
                .then((res) => {
                    if (res.data.failed.length) {
                        this._vm.$toasted.show(i18n.t('billingStore.invoiceCreatedButSomeFailed'), {
                            icon: 'mdi-cancel',
                            type: 'error',
                        });
                        resolve(res);
                    } else {
                        this._vm.$toasted.show(i18n.t('billingStore.invoiceCreated'), {
                            icon: 'mdi-check-circle',
                            type: 'success',
                        });
                        resolve(res);
                    }
                })
                .catch((error) => {
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    getArticlesByCustomer(_, id) {
        return new Promise((resolve, reject) => {
            billing
                .getArticlesByCustomer(id)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getArticles({ commit }, payload) {
        return new Promise((resolve, reject) => {
            billing
                .getArticles(payload)
                .then((res) => {
                    commit('SET_ARTICLES', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    createArticle(_, payload) {
        return new Promise((resolve, reject) => {
            billing
                .createArticle(payload)
                .then((res) => {
                    if (res.status < 210) {
                        this._vm.$toasted.show(i18n.t('billingStore.articleCreated'), {
                            icon: 'mdi-check-circle',
                            type: 'success',
                        });
                    } else {
                        this._vm.$toasted.show(i18n.t('billingStore.articleCreatedFailed'), {
                            icon: 'mdi-cancel',
                            type: 'error',
                        });
                    }
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    deleteArticle(_, articleId) {
        return new Promise((resolve, reject) => {
            billing
                .deleteArticle(articleId)
                .then((res) => {
                    if (res.status === 200) {
                        this._vm.$toasted.show(i18n.t('billingStore.articleDeleted'), {
                            icon: 'mdi-check-circle',
                            type: 'success',
                        });
                        resolve(res.data);
                    } else {
                        this._vm.$toasted.show(res.data, {
                            icon: 'mdi-cancel',
                            type: 'error',
                        });
                        reject(res.data);
                    }
                })
                .catch((error) => {
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    updateArticle(_, article) {
        return new Promise((resolve, reject) => {
            billing
                .updateArticle(article)
                .then((res) => {
                    if (res.status < 210) {
                        this._vm.$toasted.show(i18n.t('billingStore.articleUpdated'), {
                            icon: 'mdi-check-circle',
                            type: 'success',
                        });
                    } else {
                        this._vm.$toasted.show(i18n.t('billingStore.articleUpdatedFailed'), {
                            icon: 'mdi-cancel',
                            type: 'error',
                        });
                    }
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    getBillingPeriods({ commit }) {
        return new Promise((resolve, reject) => {
            billing
                .getBillingPeriods()
                .then((res) => {
                    commit('SET_BILLING_PERIODS', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    getPeriodTypes({ commit }) {
        return new Promise((resolve, reject) => {
            billing
                .getPeriodTypes()
                .then((res) => {
                    // commit('SET_PERIOD_TYPES', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    createPeriod({ commit }, payload) {
        return new Promise((resolve, reject) => {
            billing
                .createPeriod(payload)
                .then((res) => {
                    if (res.status === 200) {
                        this._vm.$toasted.show(i18n.t('billingStore.periodCreated'), {
                            icon: 'mdi-check-circle',
                            type: 'success',
                        });
                        const [insertedPeriod] = res.data;
                        commit('ADD_BILLING_PERIOD', insertedPeriod);
                        resolve(res.data);
                    } else {
                        this._vm.$toasted.show(res.data, {
                            icon: 'mdi-cancel',
                            type: 'error',
                        });
                        reject(res);
                    }
                })
                .catch((error) => {
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    deletePeriod({ commit }, periodId) {
        return new Promise((resolve, reject) => {
            billing
                .deletePeriod(periodId)
                .then((res) => {
                    if (res.status < 210) {
                        this._vm.$toasted.show(i18n.t('billingStore.periodDeleted'), {
                            icon: 'mdi-check-circle',
                            type: 'success',
                        });
                    } else {
                        this._vm.$toasted.show(i18n.t('billingStore.periodDeletedFailed'), {
                            icon: 'mdi-cancel',
                            type: 'error',
                        });
                    }
                    commit('REMOVE_BILLING_PERIOD', periodId);
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    updatePeriod(_, payload) {
        return new Promise((resolve, reject) => {
            billing
                .updatePeriod(payload)
                .then((res) => {
                    if (res.status < 210) {
                        this._vm.$toasted.show(i18n.t('billingStore.periodUpdated'), {
                            icon: 'mdi-check-circle',
                            type: 'success',
                        });
                    } else {
                        this._vm.$toasted.show(i18n.t('billingStore.periodUpdatedFailed'), {
                            icon: 'mdi-cancel',
                            type: 'error',
                        });
                    }
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    addPeriodToCustomer(_, payload) {
        return new Promise((resolve, reject) => {
            billing
                .addPeriodToCustomer(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('billingStore.periodAddedToCustomer'), {
                        icon: 'mdi-check-circle',
                        type: 'success',
                    });
                    resolve(res);
                })
                .catch((error) => {
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    deleteCustomerPeriod(_, payload) {
        return new Promise((resolve, reject) => {
            billing
                .deleteCustomerPeriod(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('billingStore.periodRemovedFromCustomer'), {
                        icon: 'mdi-check-circle',
                        type: 'success',
                    });
                    resolve(res);
                })
                .catch((error) => {
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    generateInvoiceBase(_, payload) {
        return new Promise((resolve, reject) => {
            billing
                .generateInvoiceBase(payload)
                .then((res) => {
                    if (res.status === 500) {
                        this._vm.$toasted.show(i18n.t('billingStore.invoiceGenerationFailed'), {
                            icon: 'mdi-cancel',
                            type: 'error',
                        });
                    }
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getInvoiceSummary(_, payload) {
        return new Promise((resolve, reject) => {
            billing
                .getInvoiceSummary(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    setLoadersKey({ commit }, payload) {
        const [key, value] = Object.entries(payload)[0];
        commit('SET_LOADERS_KEY', { key, value });
    },
};
const mutations = {
    SET_INVOICES(state, invoices) {
        state.invoices = invoices;
    },
    SET_ARTICLES(state, payload) {
        state.articles = payload;
    },
    SET_BILLING_PERIODS(state, payload) {
        state.periods = payload;
    },
    ADD_BILLING_PERIOD(state, payload) {
        state.periods.push(payload);
    },
    REMOVE_BILLING_PERIOD(state, payload) {
        state.periods = state.periods.filter((period) => period.id !== payload);
    },
    SET_CUSTOMERS(state, payload) {
        state.customers = payload;
    },

    SET_SELECTED_ITEM(state, payload) {
        state.selectedItem = payload;
    },
    SET_CURRENT_CONTEXT(state, payload) {
        state.currentContext = null;
        state.currentContext = payload;
    },
    SET_BILLABLE_CUSTOMERS_LIST(state, payload) {
        state.billableCustomersList = payload;
    },
    SET_LOADERS_KEY(state, data) {
        const { key, value } = data;
        state.loaders[key] = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
