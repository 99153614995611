module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "comment": {
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To:"])},
          "internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal"])}
        }
      },
      "sv": {
        "comment": {
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Till:"])},
          "internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intern"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"comment":{"to":"To:","internal":"Internal"}},"sv":{"comment":{"to":"Till:","internal":"Intern"}}}')
  delete Component.options._Ctor
  
}
