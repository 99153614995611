<template>
    <main class="action-buttons-row">
        <section class="action-button" @click="fireCallback">
            <v-icon size="80" color="primary">mdi-phone-incoming</v-icon>
            <div class="texts">
                <span>{{ $t('callCallbackModule.orderedCallback') }}</span>
                <span class="extra-text">{{ $t('callCallbackModule.callbackExtraText') }}</span>
            </div>
        </section>
        <section class="action-button" @click="openCallModule">
            <v-icon size="80" color="primary">mdi-phone</v-icon>
            <div class="texts">
                <span>{{ $t('callCallbackModule.outgoingCall') }}</span>
                <span class="extra-text">
                    {{ $t('callCallbackModule.callExtraText') }}
                </span>
            </div>
        </section>
    </main>
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    import helper from '@/helpers/calls.helper';
    import { channelTypes } from '@/constants/enums/queues.enums';
    import { caseStatuses } from '@/enums/cases.enums';

    export default {
        name: 'CallCallbackModule',
        data() {
            return {
                dialog: false,
                callbackNumber: null,
            };
        },
        computed: {
            ...mapState({
                caseData: (state) => state.Cases.caseData,
                selectedCaseId: (state) => state.Cases.selectedCaseId,
                loggedInUser: (state) => state.Auth.userObject,
                selectedCase: (state) => state.Cases.selectedCase,
                dialogState: (state) => state.Cases.toggleCommunication,
            }),
            clientIsUsingMac() {
                return /(mac|iphone|ipod|ipad)/i.test(navigator.userAgent);
            },
        },
        watch: {
            dialogState: {
                handler(value) {
                    if (!value) return;
                    this.init();
                },
            },
        },
        async created() {
            await this.init();
        },

        methods: {
            ...mapActions({
                setCommunicationCreatorData: 'Cases/setCommunicationCreatorData',
                getCallbackNumbersFromCase: 'Cases/getCallbackNumbersFromCase',
                getNumberToCall: 'Call/getNumberToCall',
                setCommunicationModal: 'Cases/openCommunication',
            }),
            async init() {
                // If no case or case not in queue, open call module
                if (!this.selectedCaseId || this.selectedCase.case.status !== caseStatuses.IN_QUEUE) {
                    this.openCallModule();
                    return;
                }

                // If callback number exists, give option to agent
                this.callbackNumber = await this.getCallbackNumbersFromCase(this.selectedCaseId);
                if (this.callbackNumber) return;

                await this.openCallModule();
            },
            async openCallModule() {
                const CALL_MODULE_INDEX = 7;
                await this.setCommunicationCreatorData({ type: CALL_MODULE_INDEX });
            },
            async fireCallback() {
                const result = await this.getNumberToCall({
                    phoneNumber: this.callbackNumber,
                    caseId: this.selectedCaseId,
                    userId: this.loggedInUser.userId,
                    type: channelTypes.CALLBACK,
                });

                const { number, exception } = result;

                if (!number) return;

                if (exception) {
                    const { confirmed } = await this.continueCallAlthoughException(exception);
                    if (!confirmed) return;
                }

                if (!this.clientIsUsingMac) {
                    document.location.href = `tloswyx:${number}`;
                } else {
                    document.location.href = `tel:${this.phoneNumberToCall}`;
                }

                this.setCommunicationModal(false);
            },
            async continueCallAlthoughException(exception) {
                const exceptionObject = helper.getCallExceptionObject(exception);
                if (!exceptionObject) {
                    return true;
                }

                const { titleKey, messageKey } = exceptionObject;

                const { confirmed } = await this.$global.dialogs.showConfirmationDialog({
                    title: this.$t(titleKey),
                    message: this.$t(messageKey),
                    confirmText: this.$t('global.yes'),
                    declineText: this.$t('global.no'),
                });

                return confirmed;
            },
        },
    };
</script>

<style scoped>
    .action-buttons-row {
        display: flex;
        width: 500px;
        height: 300px;
        gap: 24px;
        align-items: center;
        justify-content: center;
    }

    .action-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        text-align: center;
        cursor: pointer;

        padding: 8px;
        width: 220px;
        height: 220px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease-in-out;
    }

    .action-button:hover {
        background-color: #f0f0f0;
    }

    .texts {
        display: flex;
        flex-direction: column;
    }

    .extra-text {
        font-size: 10px;
        color: var(--v-base-gray2);
    }
</style>

<i18n lang="json">
{
    "en": {
        "callCallbackModule": {
            "orderedCallback": "Ordered callback",
            "outgoingCall": "Outgoing call",
            "callbackExtraText": "(Callback)",
            "callExtraText": "Optional number (Not callback)"
        }
    },
    "sv": {
        "callCallbackModule": {
            "orderedCallback": "Beställd återuppringning",
            "outgoingCall": "Utgående samtal",
            "callbackExtraText": "(Återuppringning)",
            "callExtraText": "Valfritt nummer (Ej återuppringning)"
        }
    }
}
</i18n>
