import { showConfirmationDialog as internalShowConfirmationDialog } from '../dialogs/dialog.service';
import { showDialog as internalShowDialog } from '../dialogs/dialog.service';

/**
 * Namespace for global services.
 * Provides access to various services that can be used throughout the application.
 *
 * @namespace global
 *
 * @property {object} dialogs - Provides access to the dialogs service.
 * @property {function} dialogs.showConfirmationDialog - Shows a confirmation dialog with the provided options.
 */
export const global = {
    dialogs: {
        /**
         * Shows a confirmation dialog with the provided options.
         *
         * @param {object} options - Configuration for the dialog.
         * @param {string} options.title - The title of the dialog.
         * @param {string} options.message - The message of the dialog.
         * @param {string} options.confirmText - The text of the confirm button.
         * @param {string} options.declineText - The text of the decline button.
         * @param {object[]} options.extraButtons - An array of objects representing extra buttons.
         * @param {string} options.extraButtons.text - The text of the extra button.
         * @param {string} options.extraButtons.class - The class of the extra button.
         * @param {string} options.extraButtons.action - The action to be executed when the extra button is clicked.
         * @returns {Promise<{ confirmed: boolean, declined: boolean, canceled: boolean, confirmedExtra: boolean }>} - Resolves to an object with the confirmation result.
         *
         * @example
         * async function showDialog() {
         *   try {
         *     const result = await this.$global.dialogs.showConfirmationDialog({
         *       title: 'Confirm Action',
         *       message: 'Are you sure you want to proceed?',
         *       confirmText: 'Yes',
         *       declineText: 'No',
         *       extraButtons: [
         *         {
         *           text: 'Maybe',
         *           class: 'maybe-button',
         *           action: () => console.log('Maybe clicked')
         *         }
         *       ]
         *     });
         *
         *     if (result) {
         *       const { confirmed, declined, canceled, confirmedExtra } = result;
         *       if (confirmed) {
         *         console.log('User confirmed the action.');
         *       } else if (declined) {
         *         console.log('User declined the action.');
         *       } else if (canceled) {
         *         console.log('User canceled the action.');
         *       } else if (confirmedExtra) {
         *         console.log('User clicked an extra button.');
         *       }
         *     } else {
         *       console.log('Dialog result is undefined.');
         *     }
         *   } catch (error) {
         *     console.error('An error occurred:', error);
         *   }
         * }
         *
         * showDialog();
         */
        showConfirmationDialog(options) {
            return internalShowConfirmationDialog(options);
        },

        showDialog(options) {
            return internalShowDialog(options);
        },
    },
};
