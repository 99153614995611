import validations from '@/helpers/c1settings/c1settings.validation';
import c1settings from '@/api/c1Settings/c1Settings';
import i18n from '../../i18n';

// State object (variables in the store that we want to save here to access global and change them)
const state = {
    settings: {},
};

// Actions (functions that do something)
const actions = {
    async updateField({ commit }, payload) {
        try {
            const response = await c1settings.updateSingleValue(payload);
            const { data } = response;
            commit('SET_SETTINGS', data);
            this._vm.$toasted.show(i18n.t('global.changedSaved'), {
                icon: 'mdi-check',
                type: 'success',
            });
        } catch (error) {
            this._vm.$toasted.show(i18n.t('dynamicStatistics.errorUpdate'), {
                icon: 'mdi-cancel',
                type: 'error',
            });
        }
    },
    // 1. Load settings into the store
    async loadSettings({ commit, dispatch }) {
        try {
            const settings = await dispatch('System/getSettings', ['admin_config'], { root: true });

            // 2. Save in the store
            commit('SET_SETTINGS', settings);
        } catch (error) {
            console.error('Could not load settings', new Date());
        }
    },

    getValidation(_, validationArray) {
        if (!validationArray) {
            return [];
        }

        const newArray = [];

        for (const item of validationArray) {
            newArray.push(validations[item]);
        }

        return newArray;
    },
};

// Mutations (sets the data)
const mutations = {
    SET_SETTINGS(state, payload) {
        state.settings = payload;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
