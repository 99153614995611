import api from '@/api/brands/brands';
import i18n from '../../i18n';
import { localStoragePlugin } from '../plugins';
import { se } from 'date-fns/locale';

// State object
const state = {
    active: false,
    userAvailableBrands: [],
    userSelectedBrands: [],
    selectedBrand: null,
    brandsModalOpen: false,
    brands: [],
};
// Getter functions
const getters = {};
// Actions
const actions = {
    async getBrandsActive({ commit, dispatch }) {
        try {
            const brandsCount = await dispatch('getBrandsCount');

            const brandsActive = Boolean(brandsCount);
            commit('SET_BRANDS_ACTIVE', brandsActive);

            return brandsActive;
        } catch (error) {
            console.error('Could not get user brands', error);
            throw error;
        }
    },
    async getAvailableBrandsByUser({ commit, dispatch, rootState }) {
        try {
            const data = await dispatch('getBrands', { users: [rootState.Auth.userObject.userId] });
            const mappedData = data.map((brand) => {
                return {
                    ...brand,
                    value: brand.id,
                };
            });
            commit('SET_USER_AVAILABLE_BRANDS', mappedData);

            return mappedData;
        } catch (error) {
            console.error('Could not get user brands', error);
            throw error;
        }
    },

    // The limit and offset are not implemented in the frontend yet
    async getBrands({ commit }, data) {
        try {
            const params = {
                search: data?.search || '',
                offset: data?.offset || 0,
                limit: data?.limit || 100,
                users: data?.users || null,
            };

            const brands = await api.getBrands(params);

            commit(
                'SET_BRANDS',
                brands.data.map((brand) => ({
                    ...brand,
                    value: brand.id,
                    text: brand.name,
                    icon: 'mdi-domain',
                })),
            );

            return brands.data;
        } catch (error) {
            console.error('Could not get brands', error);
            throw error;
        }
    },
    async getBrand({ _ }, brandId) {
        try {
            const brands = await api.getBrand(brandId);
            return brands.data;
        } catch (error) {
            console.error('Could not get brands', error);
            throw error;
        }
    },
    async getBrandsCount() {
        try {
            const brands = await api.getBrandsCount();
            return brands.data.total;
        } catch (error) {
            console.error('Could not get brands count', error);
            throw error;
        }
    },

    async getBrandLogo({ _ }, { brandId, resolution = '128' }) {
        try {
            const res = await api.getBrandLogo(brandId, { resolution });
            return `data:image/png;base64,${res.data}`;
        } catch (error) {
            console.error('Could not get brand logo', error);
            return null;
        }
    },

    async createBrand({ _ }, payload) {
        try {
            const result = await api.createBrand(payload);
            return result.data;
        } catch (error) {
            console.error('Could not save brand', error);
            throw error;
        }
    },

    async updateBrand({ _ }, { brandId, fields }) {
        try {
            const result = await api.updateBrand(brandId, fields);
            return result.data;
        } catch (error) {
            console.error('Could not save brand', error);
            throw error;
        }
    },

    async deleteBrand({ dispatch }, { id, toBrandId }) {
        try {
            await api.deleteBrand(id, toBrandId);
            await dispatch('getBrands');
        } catch (error) {
            console.error('Could not delete brand', error);
            throw error;
        }
    },

    async getUserSelectedBrands({ commit, dispatch, rootState, state }) {
        try {
            if (!state.active) {
                return;
            }
            const { userId } = rootState.Auth.userObject;

            if (!userId) {
                return;
            }

            const { data: brands } = await api.getUserSelectedBrands(userId);
            commit('SET_USER_SELECTED_BRANDS', brands);

            const brandsActive = state.active;
            // If the user has no brands selected, show the modal to select brands
            if (!brands.length && brandsActive && rootState.Auth.isLoggedIn) {
                await dispatch('showUserSelectBrandsModal', brands);
            }

            return brands;
        } catch (error) {
            console.error('Could not get user brands', error);
            throw error;
        }
    },

    async setUserSelectedBrands({ commit, state, rootState, dispatch }, brandIds) {
        try {
            const { userId } = rootState.Auth.userObject;
            const newSelectedBrands = await api.updateUserSelectedBrands(userId, brandIds);
            const oldSelectedBrands = state.userSelectedBrands;

            await dispatch(
                'Brands/userSelectedBrandsSideEffects',
                { oldBrands: oldSelectedBrands, newBrands: newSelectedBrands.data },
                { root: true },
            );

            commit('SET_USER_SELECTED_BRANDS', newSelectedBrands.data);
            return brandIds;
        } catch (error) {
            console.error('Could not get user brands', error);
            throw error;
        }
    },

    async userSelectedBrandsSideEffects({ state, rootState, dispatch }, { newBrands, oldBrands }) {
        const brandIdsAdded = newBrands.filter((brand) => !oldBrands?.includes(brand));

        if (!brandIdsAdded.length) {
            return;
        }

        const caseFiltersStateBrands = rootState.Cases.caseFiltersState.brands;
        const userAvailableBrands = state.userAvailableBrands;

        const brandsAdded = userAvailableBrands.filter((brand) => brandIdsAdded.includes(brand.id));

        const newSelectedBrands = [...caseFiltersStateBrands, ...brandsAdded];

        await dispatch('Cases/updateSelectedFilterObject', { brands: newSelectedBrands }, { root: true });
    },

    async showUserSelectBrandsModal({ commit }) {
        if (state.brandsModalOpen) return;

        commit('SET_BRANDS_MODAL_OPEN', true);
        await this._vm.$global.dialogs.showDialog({
            title: i18n.t('brands.selectBrands'),
            closeble: false,
            component: () => import('@/components/Brands/UserSelectBrandsModal.vue'),
        });
        commit('SET_BRANDS_MODAL_OPEN', false);
    },

    async fillBrandWithQueues({ _ }, brandId) {
        try {
            const result = await api.fillBrandWithQueues(brandId);
            return result.data;
        } catch (error) {
            console.error('Could not fill brands with queues', error);
            throw error;
        }
    },

    setSelectedBrand({ commit }, payload) {
        commit('SET_SELECTED_BRAND', payload);
    },
};
// Mutations
const mutations = {
    SET_BRANDS(state, payload) {
        state.brands = payload;
    },

    SET_BRANDS_ACTIVE(state, payload) {
        state.active = payload;
    },

    SET_USER_AVAILABLE_BRANDS(state, payload) {
        state.userAvailableBrands = payload;
    },

    SET_USER_SELECTED_BRANDS(state, payload) {
        state.userSelectedBrands = payload;
    },

    SET_SELECTED_BRAND(state, payload) {
        state.selectedBrand = payload;
    },
    SET_BRANDS_MODAL_OPEN(state, payload) {
        state.brandsModalOpen = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
