<template>
    <div class="module-queue-item">
        <i class="mdi mdi-account-multiple"></i>
        <div class="truncate-container">
            <span>{{ item.queueName ? item.queueName : $t('noQueue') }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'QueueChip',

        props: {
            item: {
                type: Object,
                required: true,
            },
        },
    };
</script>

<style scoped>
    i {
        color: var(--v-primary-base);
    }

    .module-queue-item {
        background: transparent;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        color: var(--v-gray3-text);
        font-size: 10px;
        padding: 2px 4px;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
    }

    .module-queue-item i {
        color: #666;
    }

    .truncate-container {
        max-width: 60px;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>

<i18n>
    {
        "sv": {
            "noQueue": "Ingen kö"
        },
        "en": {
            "noQueue": "No queue"
        }
    }
    </i18n>
