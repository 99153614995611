module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "callModule": {
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number: "])},
          "selRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least one role"])},
          "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only numbers and no spaces."])},
          "fillField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field must be filled in"])},
          "validNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid phone number"])},
          "callInit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phonecall initiated from "])},
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" to: "])},
          "alreadyCalling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another agent is already trying to call this number. Please try again later."])}
        }
      },
      "sv": {
        "callModule": {
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer: "])},
          "selRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj minst en roll"])},
          "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endast siffror och inga mellanslag."])},
          "fillField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta fält behöver vara ifyllt"])},
          "validNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vänligen ange ett giltigt telefonnummer"])},
          "callInit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonsamtal initierat från "])},
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" till: "])},
          "alreadyCalling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En annan agent försöker redan ringa detta nummer. Vänligen försök igen senare."])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"callModule":{"number":"Number: ","selRole":"Select at least one role","hint":"Only numbers and no spaces.","fillField":"This field must be filled in","validNumber":"Please enter a valid phone number","callInit":"Phonecall initiated from ","to":" to: ","alreadyCalling":"Another agent is already trying to call this number. Please try again later."}},"sv":{"callModule":{"number":"Nummer: ","selRole":"Välj minst en roll","hint":"Endast siffror och inga mellanslag.","fillField":"Detta fält behöver vara ifyllt","validNumber":"Vänligen ange ett giltigt telefonnummer","callInit":"Telefonsamtal initierat från ","to":" till: ","alreadyCalling":"En annan agent försöker redan ringa detta nummer. Vänligen försök igen senare."}}}')
  delete Component.options._Ctor
  
}
