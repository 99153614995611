export const states = Object.freeze({
    INIT: 'init',
    LOADING: 'loading',
    LOADED: 'loaded',
    ERROR: 'error',
    NOT_FOUND: 'not_found',
    NOT_ALLOWED: 'not_allowed',
});

export const caseStatuses = Object.freeze({
    OPEN: 'Open',
    ONGOING: 'Ongoing',
    CLOSED: 'Closed',
    IN_QUEUE: 'InQueue',
    DELETED: 'Deleted',
    SNOOZED: 'Snoozed',
});

export const events = Object.freeze({
    NEW_CASE: 'NEW_CASE',
    UPDATE_CASE: 'UPDATE_CASE',
    NEW_COMMENT: 'NEW_COMMENT',
    UPDATE_COMMENT: 'UPDATE_COMMENT',
    OTHER_USER_UPDATED_CASE: 'OTHER_USER_UPDATED_CASE',
    DELETE_COMMENT: 'DELETE_COMMENT',
});

export const caseKeys = Object.freeze({
    CATEGORIES: 'categories',
    STATUS: 'status',
    QUEUE: 'queueId',
    USER: 'userId',
    SLA: 'sla',
    ORDER: 'textField',
    SNOOZE: 'snooze',
    TEXT: 'textField',
});

export const positionTypes = Object.freeze({
    LEFT: 'left',
    RIGHT: 'right',
});

export const categories = Object.freeze({
    NO_CATEGORY: 0,
});

export const callbackTypes = Object.freeze({
    CALLBACK_MANUAL: 'callback_manual',
    CALLBACK_AUTO: 'callback_auto',
});

export const caseTypes = Object.freeze({
    CALL: 'call',
    CHAT: 'chat',
    EMAIL: 'email',
    SMS: 'sms',
    SOCIAL: 'social',
    CALLBACK: 'callback',
});

export const icons = Object.freeze({
    CALL: {
        totalCalls: 'mdi-phone-outline',
        missedCalls: 'mdi-phone-hangup-outline',
        answeredCalls: 'mdi-phone-check-outline',
        transferedCalls: 'mdi-phone-outgoing',
        offeredCallbacks: 'mdi-phone-outgoing',
        numberInQueue: 'mdi-account-outline',
        longestWaiting: 'mdi-clock-outline',
        averageTimeInQueue: 'mdi-clock-outline',
        answeredCallsPercentage: 'mdi-phone-outline',
    },
    CHAT: {
        totalCalls: 'mdi-chat-outline',
        missedCalls: 'mdi-chat-minus-outline',
        answeredCalls: 'mdi-chat-plus-outline',
        numberInQueue: 'mdi-account-outline',
        longestWaiting: 'mdi-clock-outline',
        averageTimeInQueue: 'mdi-clock-outline',
        answeredCallsPercentage: 'mdi-chat-outline',
    },
    EMAIL: {
        totalCalls: 'mdi-email-outline',
        missedCalls: 'mdi-email-minus-outline',
        answeredCalls: 'mdi-email-plus-outline',
        numberInQueue: 'mdi-account-outline',
        longestWaiting: 'mdi-clock-outline',
        averageTimeInQueue: 'mdi-clock-outline',
        answeredCallsPercentage: 'mdi-email-outline',
    },
    SMS: {
        totalCalls: 'mdi-message-text-outline',
        missedCalls: 'mdi-message-outline',
        answeredCalls: 'mdi-forum-outline',
        numberInQueue: 'mdi-account-outline',
        longestWaiting: 'mdi-clock-outline',
        averageTimeInQueue: 'mdi-clock-outline',
        answeredCallsPercentage: 'mdi-message-text-outline',
    },
    CALLBACK: {
        totalCalls: 'mdi-phone-outline',
        missedCalls: 'mdi-phone-hangup-outline',
        answeredCalls: 'mdi-phone-check-outline',
        offeredCallbacks: 'mdi-phone-outgoing',
        numberInQueue: 'mdi-account-outline',
        longestWaiting: 'mdi-clock-outline',
        averageTimeInQueue: 'mdi-clock-outline',
        answeredCallsPercentage: 'mdi-phone-outline',
    },
    SOCIAL: {
        totalCalls: 'mdi-chat-outline',
        missedCalls: 'mdi-chat-minus-outline',
        answeredCalls: 'mdi-chat-plus-outline',
        numberInQueue: 'mdi-account-outline',
        longestWaiting: 'mdi-clock-outline',
        averageTimeInQueue: 'mdi-clock-outline',
        answeredCallsPercentage: 'mdi-chat-outline',
    },
});

export const communicationTypes = Object.freeze({
    EMAIL: 'email',
    SMS: 'sms',
    CALL: 'call',
    VIDEO: 'video',
});

export const usersSpecialCases = Object.freeze({
    ALLUSERS: -1,
    UNASSIGNED: -2,
});

export const deletionDialogResponses = Object.freeze({
    CANCEL: 'CANCEL',
    DELETE_LATER: 'DELETE_LATER',
    DELETE_NOW: 'DELETE_NOW',
});

export const changeMultipleCasesOptions = Object.freeze({
    USER: 'user',
    STATUS: 'status',
    CATEGORY: 'category',
    QUEUE: 'queue',
});

export const numberOfDeletions = Object.freeze({
    MIN: 1,
    MAX: 250,
});
