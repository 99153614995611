<template>
    <div>
        <v-card>
            <v-row class="ma-0 pa-0">
                <v-col />
                <v-col class="d-flex justify-center pb-5">
                    <v-card-title class="mt-0 pt-1">
                        {{ $t('getMemberButton.ourPages') }}
                    </v-card-title>
                </v-col>
                <v-col class="d-flex justify-end">
                    <v-tooltip top>
                        <template #activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click="$emit('close')">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        {{ $t('getMemberButton.fetch') }}
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-card-text class="d-flex flex-column align-center">
                <div v-if="!loading" class="d-flex">
                    <v-text-field
                        v-model="pnr"
                        :disabled="loading"
                        :label="$t('getMemberButton.ssn')"
                        placeholder="YYYYMMDDXXXX"
                        dense
                    >
                        <template #append>
                            <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" icon v-on="on" @click="fetchMember(pnr)">
                                        <v-icon>mdi-account-check-outline</v-icon>
                                    </v-btn>
                                </template>
                                {{ $t('getMemberButton.fetch') }}
                            </v-tooltip>
                        </template>
                    </v-text-field>
                </div>
                <div v-else>
                    <v-progress-linear indeterminate />
                    <div class="text-caption">{{ $t('getMemberButton.fetchingData') }}</div>
                </div>

                <div v-if="!loading">{{ result }}</div>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';

    export default {
        components: {},
        props: {
            ssn: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                pnr: '',
                loading: false,
                result: null,
            };
        },
        watch: {
            ssn: {
                immediate: true,
                handler(val) {
                    this.pnr = val;
                },
            },
        },
        methods: {
            ...mapActions({
                getMember: 'Integrations/sverigesAkassorGetMember',
            }),
            async fetchMember() {
                this.loading = true;
                this.result = await this.getMember(this.pnr);
                this.loading = false;
            },
        },
    };
</script>
<style scoped lang="scss"></style>
<i18n>
    {
        "en": {
            "getMemberButton": {
                "ourPages": "Our pages",
                "ssn": "Social security number",
                "fetch": "Fetch",
                "fetchingData": "Fetching data..."
            }
        },
        "sv": {
            "getMemberButton": {
                "ourPages": "Våra sidor",
                "ssn": "Personnummer",
                "fetch": "Hämta",
                "fetchingData": "Hämtar data..."
            }
        }
    }
</i18n>
