// getExternalData
import axios from 'axios';

function getUserNotices() {
    return axios.get('/system/user/notifications');
}

function closeUserNotice(payload) {
    return axios.put('/system/user/notifications', payload);
}

function getSettings(configKeys) {
    return axios.get(`/system/configurations/?configKeys=${configKeys}`);
}

function updateDoc(payload) {
    return axios.put(`/system/settings`, payload);
}

function getServerTZOffset() {
    return axios.get('/system/timezone');
}

function getStandardApps() {
    return axios.get('/system/standard-apps');
}

export default {
    getUserNotices,
    closeUserNotice,
    getSettings,
    updateDoc,
    getServerTZOffset,
    getStandardApps,
};
