<template>
    <article
        class="module-item"
        :class="item.unreadMessages ? 'active' : 'deactive'"
        @click="navigateCase(item.caseId)"
    >
        <div v-if="selectedCase?.case?.caseId === item.caseId" class="activity-line"></div>

        <div class="icon-container">
            <i :style="iconStyle" class="mdi" :class="caseTypeIcon"></i>
        </div>
        <div>
            <span>{{ item.topic }}</span>
        </div>
        <div>
            <QueueChip :item="item" />
        </div>
    </article>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import QueueChip from '@/components/Global/UserActionModule/Modules/Items/QueueChip.vue';
    import { filterObjectsByKeys, getActiveFromSettings } from '@/utils';
    import { channelTypes } from '@/templates/cases.template';

    export default {
        name: 'ModuleCase',

        components: {
            QueueChip,
        },

        props: {
            item: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapState('Cases', ['selectedCase']),
            ...mapState('Social', ['social']),
            ...mapState('System', ['userSettings']),

            iconStyle() {
                return {
                    color: this.item.unreadMessages ? 'var(--v-color1-base)' : 'var(--v-primary-base)',
                };
            },

            filteredChannelTypes() {
                if (!this.userSettings.system.channels) {
                    return [];
                }
                const channelBooleans = getActiveFromSettings(this.userSettings.system.channels);
                return filterObjectsByKeys(channelBooleans, channelTypes, 'value');
            },

            badgeIcon() {
                if (
                    this.item.internalSubType === 'message' &&
                    this.item.internalType === 'social' &&
                    this.item.source === 'facebook'
                ) {
                    return this.social.facebookmessenger?.icon;
                }
                return this.social[this.item.source]?.icon;
            },

            caseTypeIcon() {
                switch (this.item.internalType) {
                    case 'social': {
                        return this.badgeIcon;
                    }
                    case 'email': {
                        return 'mdi-email';
                    }
                    default: {
                        return (
                            this.filteredChannelTypes.find((t) => t.value === this.item.internalType)?.icon ||
                            'mdi-help-circle'
                        );
                    }
                }
            },
        },

        methods: {
            ...mapActions('Cases', ['goToCase']),
            ...mapActions('Users', ['toggleActionModule']),

            navigateCase(caseId) {
                this.goToCase({ caseId });
                this.toggleActionModule(false);
            },
        },
    };
</script>

<style scoped>
    .activity-line {
        background: var(--v-primary-base);
        height: 100%;
        width: 4px;
        position: absolute;
        left: 0;
    }

    .module-item {
        background: white;
        border-bottom: 1px solid #e0e0e0;
        display: grid;
        grid-template-columns: auto 1fr auto;
        min-height: 48px;
        transition: all 0.3s;
        font-size: 12px;
        user-select: none;
        gap: 16px;
        padding: 0px 16px;
        position: relative;
        cursor: pointer;
    }

    .module-item > * {
        align-items: center;
        display: flex;
        overflow: hidden;
    }

    .module-item > div > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .module-item :last-child {
        justify-content: flex-end;
    }

    .module-item:hover {
        background: #f5f5f5;
    }

    .module-item:active {
        background: #e0e0e0;
    }

    .icon-container {
        padding: 0px;
    }

    .active {
        opacity: 1;
    }

    .deactive {
        opacity: 0.6;
    }
</style>
