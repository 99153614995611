import axios from 'axios';

function addOverflow(payload) {
    return axios.post(`/queues/${payload.queueId}/overflow`, payload);
}
function deleteAllOverflows(queueId) {
    return axios.delete(`/queues/${queueId}/overflows`);
}
function editOverflow(payload) {
    return axios.put(`/queues/${payload.queueId}/overflow`, payload);
}

function getOverflows(queueId) {
    return axios.get(`/queues/${queueId}/overflows`);
}
function deleteOverflow(overflowId) {
    return axios.delete(`/queues/${overflowId}/overflow`);
}
function updateOverflowActive(payload) {
    return axios.put(`/queues/${payload.queueId}/overflowActive`, payload);
}

function updateKeepQueuePoints(payload) {
    return axios.put(`/queues/${payload.queueId}/keepQueuePoints`, payload);
}

export default {
    updateOverflowActive,
    addOverflow,
    deleteAllOverflows,
    editOverflow,
    deleteOverflow,
    getOverflows,
    updateKeepQueuePoints,
};
