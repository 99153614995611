import axios from 'axios';

function getCalendarData(payload) {
    return axios.get(
        `/calendar/?user=` +
            payload.user +
            `&date=` +
            new Date().toISOString().slice(0, 10) +
            `&isExternal=${payload.isExternal || ''}`,
    );
}

function getUserCreatedEvents(payload) {
    return axios.get(`/calendar/user/${payload.user}?page=${payload.page}&limit=${payload.limit}`);
}
function addCalendarEvent(payload) {
    return axios.post(`/calendar/event`, payload);
}
function updateCalendarEvent(payload) {
    return axios.put(`/calendar/event`, payload);
}
function deleteCalendarEvent(payload) {
    return axios.delete(`/calendar/event/${payload.event}`);
}
export default {
    getCalendarData,
    addCalendarEvent,
    updateCalendarEvent,
    deleteCalendarEvent,
    getUserCreatedEvents,
};
