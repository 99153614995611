import axios from 'axios';

function sendSMS(payload) {
    return axios.post('/video/send-sms', payload);
}

function sendEmail(payload) {
    return axios.post('/video/send-email', payload);
}

export default {
    sendSMS,
    sendEmail,
};
