import axios from 'axios';

function getBlacklistedEmails(payload) {
    return axios.get(`/emails/blacklisted/`, { params: payload });
}
function getBlacklistedEmailsPage(payload) {
    const { id, ...parameters } = payload;
    return axios.get(`/emails/blacklisted/${id}/page`, { params: parameters });
}

function addBlacklistedEmail(payload) {
    return axios.post(`/emails/blacklisted/`, payload);
}

function updateBlacklistedEmail(payload) {
    const { id, ...body } = payload;
    return axios.put(`/emails/blacklisted/${id}`, body);
}

function deleteBlacklistedEmail(payload) {
    return axios.delete(`/emails/blacklisted/${payload.id}`);
}

export default {
    getBlacklistedEmails,
    getBlacklistedEmailsPage,
    addBlacklistedEmail,
    updateBlacklistedEmail,
    deleteBlacklistedEmail,
};
