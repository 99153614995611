module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "callCallbackModule": {
          "orderedCallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordered callback"])},
          "outgoingCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing call"])},
          "callbackExtraText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Callback)"])},
          "callExtraText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional number (Not callback)"])}
        }
      },
      "sv": {
        "callCallbackModule": {
          "orderedCallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beställd återuppringning"])},
          "outgoingCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utgående samtal"])},
          "callbackExtraText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Återuppringning)"])},
          "callExtraText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valfritt nummer (Ej återuppringning)"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"callCallbackModule":{"orderedCallback":"Ordered callback","outgoingCall":"Outgoing call","callbackExtraText":"(Callback)","callExtraText":"Optional number (Not callback)"}},"sv":{"callCallbackModule":{"orderedCallback":"Beställd återuppringning","outgoingCall":"Utgående samtal","callbackExtraText":"(Återuppringning)","callExtraText":"Valfritt nummer (Ej återuppringning)"}}}')
  delete Component.options._Ctor
  
}
