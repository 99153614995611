module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "integrations": {
          "noIntegrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No integrations available"])}
        }
      },
      "sv": {
        "integrations": {
          "noIntegrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga integrationer tillgängliga"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"integrations":{"noIntegrations":"No integrations available"}},"sv":{"integrations":{"noIntegrations":"Inga integrationer tillgängliga"}}}')
  delete Component.options._Ctor
  
}
