<template>
    <button class="icon-button" :style="iconButton" @click="(event) => $emit('click', event)">
        <i class="mdi" :class="icon" :style="iconSize"></i>
    </button>
</template>

<script>
    export default {
        name: 'IconButton',

        props: {
            icon: {
                type: String,
                required: true,
            },

            size: {
                type: Number,
                default: 18,
            },
        },

        computed: {
            iconSize() {
                return `font-size: ${this.size}px;`;
            },

            iconButton() {
                return {
                    height: `${this.size + 10}px`,
                    width: `${this.size + 10}px`,
                };
            },
        },
    };
</script>

<style scoped>
    .icon-button {
        background: none;
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
        transition: background-color 0.2s;
        border-radius: 50%;
        height: 32px;
        width: 32px;
    }

    .icon-button i {
        font-size: 18px;
        color: #6c6c6c;
    }

    .icon-button:hover i {
        color: black;
    }

    .icon-button:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .icon-button:active {
        background-color: rgba(0, 0, 0, 0.2);
    }
</style>
