<template>
    <section v-if="template">
        <section class="template-item" @click="selectTemplate(template)">
            <div class="truncate-container">
                <span class="truncate-template">
                    {{ template.name }}
                </span>
            </div>
        </section>
        <section v-if="template.id === templates.selectedTemplate.id" class="template-slider">
            <TemplateContent :key="template.id" :templateId="template.id" />
        </section>
    </section>
</template>
<script>
    import { mapActions, mapState } from 'vuex';
    import TemplateContent from './TemplateContent.vue';

    export default {
        components: {
            TemplateContent,
        },
        props: {
            template: {
                type: Object,
                Required: true,
                default: null,
            },
        },
        computed: {
            ...mapState('Templates', ['templates']),
        },
        methods: {
            ...mapActions('Templates', ['selectTemplate']),
        },
    };
</script>
<style lang="scss" scoped>
    .template-item {
        display: flex;
        padding: 16px 32px;
        transition: background-color 0.3s;
        min-height: 32px;
        user-select: none;
        border: 1px dotted #ddd;
        border-radius: 6px;
        cursor: pointer;
        margin: 5px;
        justify-content: stretch;
        align-items: center;
    }

    .template-item:hover {
        background-color: #f9f9f9;
    }

    .template-item:active {
        background-color: #f0f0f0;
    }

    .template-slider {
        padding: 16px 32px;
        border: 1px solid #ddd;
        border-radius: 6px;
    }

    .truncate-container {
        display: flex;
        align-items: center;
        flex: 1;
        width: 0px;
    }

    .truncate-template {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
