<template>
    <div v-if="recordingsExists">
        <div class="pa-4 mx-4 container">
            <p class="ma-2">{{ callDirection }}</p>
            <v-row>
                <v-col class="ma-2">{{ $t('calls.actions') }}</v-col>
                <v-col class="ma-2">{{ $t('calls.call') }}</v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn depressed class="ma-2" color="primary" @click="play()">{{ $t('calls.play') }}</v-btn>
                    <v-btn disabled depressed class="ma-2" color="primary">{{ $t('calls.summary') }}</v-btn>
                    <v-btn disabled depressed class="ma-2" color="primary">{{ $t('calls.dialog') }}</v-btn>
                </v-col>
                <v-col>
                    <audio ref="audio" controls="controls" autobuffer="autobuffer">
                        <source src="" type="audio/wav" />
                        {{ $t('calls.noSupport') }}
                    </audio>
                </v-col>
            </v-row>
        </div>
        <div class="pa-4">
            <div v-if="result.transcript">
                {{ result.transcript }}
            </div>
        </div>
    </div>
</template>

<script>
    import callDirection from '@/enums/calls.enums';
    import { httpStatusCode } from '@/enums/response.enums';

    const { INBOUND } = callDirection;

    export default {
        props: {
            caseInfo: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                key: 'value',
                result: {},
                loaded: false,
                recordingsExists: false,
            };
        },
        computed: {
            callDirection() {
                if (!this.loaded) {
                    return '';
                }
                return this.result.direction === INBOUND ? this.$t('calls.inbound') : this.$t('calls.outbound');
            },
        },
        async created() {
            this.loaded = false;
            await this.getRecordingMetaData();
            this.loaded = true;
        },
        methods: {
            async play() {
                this.$refs.audio.src = await this.$store.dispatch('Comments/getRecording', this.$props.caseInfo.MsgID);
                this.$refs.audio.play();
            },
            async getRecordingMetaData() {
                try {
                    const result = await this.$store.dispatch(
                        'Comments/getRecordingsMetaData',
                        this.$props.caseInfo.MsgID
                    );
                    const { data, status } = result;
                    if (status === httpStatusCode.OK) {
                        this.recordingsExists = true;
                        this.result = data;
                    }
                } catch (error) {
                    this.recordingsExists = false;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .container {
        border-radius: 5px;
        background-color: var(--v-gray3-base);
        color: var(--v-gray4-base);
    }
</style>

<i18n lang="json">
{
    "en": {
        "calls": {
            "actions": "Actions",
            "call": "Call",
            "play": "Play",
            "summary": "Summary",
            "dialog": "Dialog",
            "noSupport": "Your browser does not support the audio element.",
            "recordingStartedAt": "Recording started at: {created}",
            "inbound": "Inbound call",
            "outbound": "Outbound call"
        }
    },
    "sv": {
        "calls": {
            "actions": "Funktioner",
            "call": "Telefonsamtal",
            "play": "Spela upp",
            "summary": "Summering",
            "dialog": "Dialog",
            "noSupport": "Din webbläsare stödjer inte ljudfiler.",
            "recordingStartedAt": "Inspelning startade: {created}",
            "inbound": "Inkommande samtal",
            "outbound": "Utgående samtal"
        }
    }
}
</i18n>
