export const exceptionTypes = Object.freeze({
    CLOSED: 'CLOSED',
    OPEN: 'OPEN',
    MESSAGE: 'MESSAGE',
    TRANSFER: 'TRANSFER',
});

export const calendarDisplayTypes = Object.freeze({
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
});

export const openhoursQueueTypes = Object.freeze({
    C1: 'C1',
    OQ: 'OQ',
    IVR: 'IVR',
});

export default {
    exceptionTypes,
    calendarDisplayTypes,
    openhoursQueueTypes,
};
