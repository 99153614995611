<template>
    <section class="comments system-container incoming">
        <div class="system-heading">
            <!-- Avatar tooltip -->
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <div class="common-avatar" v-on="on">
                        <CommonAvatar v-if="item.userId" :userId="item.userId" :size="24" />
                        <SystemAvatar v-else :size="32" />
                    </div>
                </template>

                <span>{{ item.userName }}</span>
            </v-tooltip>

            <!-- User / Comment -->
            <span class="user-comment">{{ item.userId ? item.userName : $t('comment.system') }}</span>
            <!-- Dot icon tooltip -->
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <div class="system-dot" v-on="on"></div>
                </template>
                <span>{{ formatFrom(item.systemFrom) }}</span>
                <!-- Display system chip text in tooltip -->
            </v-tooltip>

            <!-- Display system chip text -->
            <span class="system-chip-text" :style="chipStyle(item.systemFrom)">
                {{ formatFrom(item.systemFrom) }}
            </span>

            <!-- Arrow icon -->
            <v-icon size="14" class="arrow-icon" color="var(--v-gray1-base)">mdi-arrow-right</v-icon>

            <!-- System chip -->
            <span class="system-chip2" :style="chipStyle(item.systemTo)">{{ formatTo(item.systemTo) }}</span>

            <!-- Comment date -->
            <div class="comment-date">{{ formatDate(item.dateTime) }}</div>
        </div>
    </section>
</template>

<script>
    import { format } from 'date-fns';
    import { mapState, mapActions } from 'vuex';
    import { getDay } from '@/utils/DateFormatter';

    import SystemAvatar from '@/components/Global/SystemAvatar.vue';
    import CommonAvatar from '@/components/Global/CommonAvatar.vue';

    export default {
        components: {
            CommonAvatar,
            SystemAvatar,
        },
        props: {
            item: {
                type: Object,
                default: () => {},
            },
        },

        data() {
            return {
                isSmallScreen: false,
            };
        },

        computed: {
            ...mapState({
                userSettings: (state) => state.System.userSettings,
            }),
        },

        mounted() {
            const showSystem = this.userSettings?.cases?.attachConversationSettings?.attachSystemMessages?.active;

            if (this.userSettings.cases.attachConversationSettings?.attachConversation?.active && showSystem) {
                this.setAttachChecked({ commentId: this.item.id, value: true });
            }
        },

        methods: {
            ...mapActions({
                setAttachChecked: 'Comments/setAttachChecked',
            }),

            chipStyle(value) {
                if (value === '') {
                    return {
                        backgroundColor: 'var(--v-gray5-base)',
                        color: 'var(--v-gray2-base)',
                    };
                }

                return '';
            },
            formatDate(_dateTime) {
                if (!_dateTime) {
                    return this.$t('comment.invalid');
                }
                try {
                    const dateTime = new Date(_dateTime);
                    if (!(dateTime instanceof Date) || Number.isNaN(dateTime)) {
                        return this.$t('comment.invalid');
                    }

                    if (!this.userSettings.cases.showDate.active) {
                        return getDay(dateTime);
                    }

                    return getDay(dateTime) + ' (' + format(new Date(dateTime), 'yyyy-MM-dd') + ')';
                } catch (error) {
                    console.error('Could not format date', error);
                    return this.$t('comment.invalid');
                }
            },

            formatFrom(from) {
                if (this.item.systemType === 'status') {
                    return this.$t('caseStatus.' + from);
                }

                if (from === '!DELETED_USER' && this.item.systemType === 'userId') {
                    return this.$t('comment.deletedUser');
                }

                if ((from === '' || !from) && this.item.systemType === 'userId') {
                    return this.$t('comment.notAssigned');
                }

                if ((from === '' || !from) && this.item.systemType === 'queueId') {
                    return this.$t('comment.empty');
                }

                if ((from === '' || !from) && this.item.systemType === 'sla') {
                    return this.$t('comment.empty');
                }

                if (this.item.systemType === 'categories') {
                    // Split the categories using a comma
                    const categories = from.split(',');

                    // Check if there are more than 2 categories
                    if (categories.length > 2) {
                        // Add +2 to the last category
                        return categories.at(0) + ' + ' + (categories.length - 1);
                    }

                    // Join the categories back with and if 2 and single if 1
                    return categories.length === 2
                        ? categories.join(' ' + this.$t('comment.and') + ' ')
                        : categories.join('');
                }

                if (this.item.systemType === 'sla') {
                    return this.formatDate(from);
                }

                if (from === '' && this.item.systemType === 'text') {
                    return this.$t('comment.empty');
                }

                return from;
            },

            formatTo(to) {
                if (this.item.systemType === 'socialSecurityNumber') {
                    return this.$t('comment.verified');
                }

                if (this.item.systemType === 'status') {
                    return this.$t('caseStatus.' + to);
                }

                if (to === '!DELETED_USER' && this.item.systemType === 'userId') {
                    return this.$t('comment.deletedUser');
                }

                if ((to === '' || !to) && this.item.systemType === 'userId') {
                    return this.$t('comment.notAssigned');
                }

                if ((to === '' || !to) && this.item.systemType === 'queueId') {
                    return this.$t('comment.empty');
                }

                if (this.item.systemType === 'categories') {
                    // Split the categories using a comma
                    const categories = to.split(',');

                    // Check if there are more than 2 categories
                    if (categories.length > 2) {
                        // Add +2 to the last category
                        return categories.at(0) + ' + ' + (categories.length - 1);
                    }

                    // Join the categories back with and if 2 and single if 1
                    return categories.length === 2
                        ? categories.join(' ' + this.$t('comment.and') + ' ')
                        : categories.join('');
                }

                if (this.item.systemType === 'sla') {
                    return this.formatDate(to);
                }

                if (to === '' && this.item.systemType === 'text') {
                    return this.$t('comment.empty');
                }

                return to;
            },

            translateType(type) {
                // switch

                switch (type) {
                    case 'categories': {
                        return this.$t('types.categories');
                    }

                    case 'status': {
                        return this.$t('types.status');
                    }

                    case 'userId': {
                        return this.$t('types.userId');
                    }

                    case 'queueId': {
                        return this.$t('types.queueId');
                    }

                    case 'sla': {
                        return this.$t('types.sla');
                    }

                    case 'textField': {
                        return this.$t('types.textField');
                    }

                    case 'socialSecurityNumber': {
                        return this.$t('types.socialSecurityNumber');
                    }

                    default: {
                        return type;
                    }
                }
            },

            getDay,
            format,
        },
    };
</script>
<style scoped lang="scss">
    .system-dot {
        display: none;
    }
    .system-heading {
        display: grid;
        grid-template-areas: 'avatar system-text arrow-icon system-chip2 comment-date';
    }

    .common-avatar {
        grid-area: avatar;
        margin-right: 10px;
    }

    .mdi-dot-icon {
        display: none;
    }

    .system-text {
        grid-area: system-text;
        color: var(--v-gray2-base);
        padding-right: 8px;
        margin-right: 4px;
    }

    .arrow-icon {
        grid-area: arrow-icon;
        margin-right: 6px;
    }

    .user-comment {
        margin-right: 10px;
    }

    .system-chip-text {
        font-size: 0.8rem;
        border: 1px solid #ccc;
        padding: 4px 6px;
        margin: 0px;
        border-radius: 4px;
        color: var(--v-gray4-base);
        margin-right: 8px;
    }

    .system-chip2 {
        grid-area: system-chip2;
        font-size: 0.8rem;
        border: 1px solid #ccc;
        padding: 4px 6px;
        margin: 0px;
        border-radius: 4px;
        color: var(--v-gray4-base);
        margin-right: 15px;
    }

    .comment-date {
        grid-area: comment-date;
        display: flex;
        align-items: center;
        justify-self: end;
        color: var(--v-gray2-base);
        white-space: nowrap;
    }

    .system-heading {
        display: flex;
        align-items: center;

        color: var(--v-gray2-base);
    }

    .comments {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .system-container {
        display: flex;
        padding: 16px;
        border-radius: 12px;
        gap: 8px;
        caret-color: white !important;
        font-size: 0.8rem;
        justify-self: flex-end;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 1380px) {
        .system-dot {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--v-gray1-base);
            border-radius: 50%;
            width: 6px;
            height: 6px;
            margin: 0 8px 0 16px;
        }

        .system-heading {
            display: grid;
            grid-template-areas: 'avatar dot-icon arrow-icon system-chip2';
        }

        .common-avatar {
            grid-area: avatar;
            display: flex;
            align-items: center;
            margin-right: 0px;
        }

        .system-text {
            grid-area: blue1;
            color: var(--v-gray2-base);
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
            margin-left: 6px;
            display: none;
        }

        .mdi-dot-icon {
            grid-area: dot-icon;
            display: flex;
            justify-self: flex-end;
            font-size: 1.2rem;
            color: var(--v-gray1-base);
            margin-right: 6px;
            width: 45%;
        }

        .arrow-icon {
            grid-area: arrow-icon;
            display: flex;
        }

        .system-chip2 {
            grid-area: system-chip2;
            font-size: 0.8rem;
            border: 1px solid #ccc;
            padding: 4px 6px;
            margin: 0px 4px;
            border-radius: 4px;
            color: var(--v-gray4-base);
            display: flex;
            justify-self: flex-start;
        }

        .comment-date {
            grid-area: yellow1;
            display: flex;
            align-items: center;
            justify-self: end;
            color: var(--v-gray2-base);
            white-space: nowrap;
            margin-bottom: 10px;
            display: none;
        }

        .user-comment {
            display: none;
        }

        .system-chip-text {
            display: none;
        }
    }
</style>
<i18n lang="json">
{
    "en": {
        "comment": {
            "changed": "Changed ",
            "to": " to ",
            "from": " from ",
            "system": "System",
            "empty": "None",
            "notAssigned": "Not assigned",
            "and": "and",
            "verified": "Verified",
            "more": "more",
            "deletedUser": "Deleted user",
            "invalid": "Invalid date"
        },
        "types": {
            "categories": "Categories",
            "status": "Status",
            "userId": "Assigned",
            "queueId": "Queue",
            "sla": "SLA",
            "textField": "Order number",
            "socialSecurityNumber": "BankId"
        }
    },
    "sv": {
        "comment": {
            "changed": "Ändrade ",
            "to": " till ",
            "from": " från ",
            "system": "System",
            "empty": "Ingen",
            "notAssigned": "Ej tilldelad",
            "and": "och",
            "verified": "Verifierad",
            "more": "fler",
            "deletedUser": "Raderad användare",
            "invalid": "Ogiltigt datum"
        },
        "types": {
            "categories": "Kategorier",
            "status": "Status",
            "userId": "Tilldelad",
            "queueId": "Kö",
            "sla": "SLA",
            "textField": "Ordernummer",
            "socialSecurityNumber": "BankId"
        }
    }
}
</i18n>
