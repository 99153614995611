import companyGroups from '../../api/companyGroups/companyGroups';
import i18n from '../../i18n';

const state = {
    groups: [],
    allGroups: [],
    currentGroupSettings: {},
};
const getters = {};

const actions = {
    async createCompanyGroup({ dispatch }, payload) {
        try {
            const res = await companyGroups.createCompanyGroup(payload);
            dispatch('getAllCompanyGroups');
            this._vm.$toasted.show(i18n.t('companyGroupsStore.groupCreated'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('companyGroupsStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async getGroupWithUsers(_, id) {
        try {
            const res = await companyGroups.getGroupWithUsers(id);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('companyGroupsStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    async getUserCompanyGroups({ commit }, payload) {
        try {
            const res = await companyGroups.getUserCompanyGroups(payload || this.state.Auth.userObject.userId);
            commit('SET_USER_COMPANY_GROUPS', res.data);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('companyGroupsStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async getAllCompanyGroups({ commit }) {
        try {
            const res = await companyGroups.getAllCompanyGroups();
            const data = res.data.map((group) => {
                return {
                    ...group,
                    value: group.ID,
                };
            });

            commit('SET_ALL_COMPANY_GROUPS', data);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('companyGroupsStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async getGroupTooltipInfo(_, payload) {
        try {
            const res = await companyGroups.getGroupTooltipInfo(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('companyGroupsStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    async addMembersToCompanyGroup({ dispatch }, payload) {
        try {
            const res = await companyGroups.addMembersToCompanyGroup(payload);
            this._vm.$toasted.show(i18n.t('companyGroupsStore.addMemberSuccess'), {
                icon: 'check',
                type: 'success',
            });
            dispatch('getAllCompanyGroups');
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('companyGroupsStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async saveGroupDescription(_, payload) {
        try {
            const res = await companyGroups.saveGroupDescription(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('companyGroupsStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    async deleteUserFromGroup(_, payload) {
        try {
            const res = await companyGroups.deleteUserFromGroup(payload);
            this._vm.$toasted.show(i18n.t('companyGroupsStore.personDeleted'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('companyGroupsStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async deleteAllUsersFromGroup(_, payload) {
        try {
            const res = await companyGroups.deleteAllUsersFromGroup(payload);
            this._vm.$toasted.show(i18n.t('companyGroupsStore.personDeleted'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('companyGroupsStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async getAvailableUsers(_, payload) {
        try {
            const res = await companyGroups.getAvailableUsers(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('companyGroupsStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async addMembersToGroup(_, payload) {
        try {
            const res = await companyGroups.addMembersToGroup(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('companyGroupsStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async deleteCompanyGroup({ dispatch }, payload) {
        try {
            const res = await companyGroups.deleteCompanyGroup(payload);
            dispatch('getAllCompanyGroups');
            this._vm.$toasted.show(i18n.t('companyGroupsStore.groupDeleted'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('companyGroupsStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async editGroupName(_, payload) {
        try {
            const res = await companyGroups.editGroupName(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('companyGroupsStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async getGroupsBasics(_, payload) {
        try {
            const res = await companyGroups.getGroupsBasics(payload);

            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('companyGroupsStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getGroupSettings({ commit }, payload) {
        try {
            const { data } = await companyGroups.getGroupSettings(payload);

            if (state.currentGroupSettings.id === data.id) {
                commit('SET_CURRENT_GROUP_SETTINGS', {});
                return data;
            }

            commit('SET_CURRENT_GROUP_SETTINGS', data);
            return data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('companyGroupsStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getUsersInGroup(_, payload) {
        try {
            const res = await companyGroups.getUsersInGroup(payload);

            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('companyGroupsStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });

            throw error;
        }
    },
};

const mutations = {
    SET_USER_COMPANY_GROUPS(state, data) {
        state.groups = data;
    },
    SET_ALL_COMPANY_GROUPS(state, data) {
        state.allGroups = data;
    },
    SET_CURRENT_GROUP_SETTINGS(state, data) {
        state.currentGroupSettings = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
