import overflow from '@/api/queues/overflow';
import i18n from '../../i18n';

// Actions
const state = {
    overflowActive: 0,
    keepQueuePoints: 0,
    showForm: false,
    events: [],
    tempEvent: null,
    deleteAllDialog: false,
    days: [
        { value: 1, text: i18n.t('global.weekDays.monday'), short: i18n.t('global.shortWDays.mon') },
        { value: 2, text: i18n.t('global.weekDays.tuesday'), short: i18n.t('global.shortWDays.tue') },
        { value: 3, text: i18n.t('global.weekDays.wednesday'), short: i18n.t('global.shortWDays.wed') },
        { value: 4, text: i18n.t('global.weekDays.thursday'), short: i18n.t('global.shortWDays.thu') },
        { value: 5, text: i18n.t('global.weekDays.friday'), short: i18n.t('global.shortWDays.fri') },
        { value: 6, text: i18n.t('global.weekDays.saturday'), short: i18n.t('global.shortWDays.sat') },
        { value: 0, text: i18n.t('global.weekDays.sunday'), short: i18n.t('global.shortWDays.sun') },
    ],
    weekday: [1, 2, 3, 4, 5, 6, 0],
};

const getters = {
    queueId: (state, getters, rootState) => {
        return rootState.QueueManager.selectedQueue.id;
    },
    getWeekdayName: (state) => (weekday) => {
        return state.days[weekday === 0 ? 6 : weekday - 1].short; // adjust weekday number and return weekday name from days array
    },
};

const actions = {
    addOverflow({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            overflow
                .addOverflow(payload)
                .then((res) => {
                    dispatch('getOverflows');
                    dispatch('closeForm');
                    resolve(res);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('overflowStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    deleteAllOverflows({ commit, dispatch, getters }) {
        return new Promise((resolve, reject) => {
            overflow
                .deleteAllOverflows(getters.queueId)
                .then((res) => {
                    dispatch('getOverflows');
                    commit('TOGGLE_DELETE_ALL');
                    resolve(res);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('overflowStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    editOverflow({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            overflow
                .editOverflow(payload)
                .then((res) => {
                    dispatch('getOverflows');
                    dispatch('closeForm');
                    resolve(res);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('overflowStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    deleteOverflow({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            overflow
                .deleteOverflow(id)
                .then((res) => {
                    commit('DELETE_EVENT', id);
                    dispatch('closeForm');
                    resolve(res);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('overflowStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    getOverflows({ commit, getters }) {
        return new Promise((resolve, reject) => {
            overflow
                .getOverflows(getters.queueId)
                .then((res) => {
                    commit('SET_EVENTS', res.data);
                    resolve(res);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('overflowStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    openForm({ commit }, newEvent) {
        if (!state.tempEvent) {
            commit('UPDATE_TEMP_EVENT', newEvent);
        }
        commit('OPEN_FORM');
    },

    closeForm({ commit, dispatch, getters }) {
        dispatch('getOverflows', getters.queueId);
        commit('CLOSE_FORM');
        commit('RESET_TEMP');
        commit('REMOVE_TEMP_EVENTS');
    },

    addEvent({ commit }, payload) {
        commit('ADD_EVENT', payload);
    },

    errorToast() {
        this._vm.$toasted.show(i18n.t('overflowStore.eventOverlap'), {
            icon: 'alert',
            type: 'error',
            duration: 4000,
        });
    },
    updateTempEvent({ commit }, payload) {
        commit('UPDATE_TEMP_EVENT', payload);
    },

    toggleDeleteAll({ commit }) {
        commit('TOGGLE_DELETE_ALL');
    },

    updateOverflowActive({ commit, getters }, value) {
        try {
            const payload = { queueId: getters.queueId, value };
            const res = overflow.updateOverflowActive(payload);
            commit('TOGGLE_OVERFLOW_ACTIVE', payload.value);
            this._vm.$toasted.show(i18n.t('queueManagerStore.saved'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res;
        } catch (error) {
            throw new Error(error);
        }
    },
    updateKeepQueuePoints({ commit, getters }, value) {
        try {
            const payload = { queueId: getters.queueId, value };
            const res = overflow.updateKeepQueuePoints(payload);
            commit('TOGGLE_OVERFLOW_ACTIVE', payload.value);
            this._vm.$toasted.show(i18n.t('queueManagerStore.saved'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res;
        } catch (error) {
            throw new Error(error);
        }
    },

    setOverflowActive({ commit }, value) {
        commit('TOGGLE_OVERFLOW_ACTIVE', value);
    },
};
const mutations = {
    TOGGLE_OVERFLOW_ACTIVE(state, value) {
        state.overflowActive = value;
    },
    TOGGLE_KEEP_QUEUE_POINTS(state, value) {
        state.keepQueuePoints = value;
    },
    TOGGLE_DELETE_ALL(state) {
        state.deleteAllDialog = !state.deleteAllDialog;
    },
    OPEN_FORM(state) {
        state.showForm = true;
    },
    CLOSE_FORM(state) {
        state.showForm = false;
    },
    ADD_EVENT(state, payload) {
        state.events.push(payload);
    },
    SET_EVENTS(state, data) {
        state.events = data;
    },
    UPDATE_TEMP_EVENT(state, payload) {
        state.tempEvent = payload;
    },
    RESET_TEMP(state) {
        state.tempEvent = null;
    },
    DELETE_EVENT(state, id) {
        state.events = state.events.filter((x) => x.id != id);
    },
    REMOVE_TEMP_EVENTS(state) {
        state.events = state.events.filter((event) => event.id !== undefined);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
