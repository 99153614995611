<template>
    <div>
        <IntegrationButton
            :active="selectedClientSSN != null"
            :loading="loading"
            :integrationName="integration.name"
            @click="handleOpenMember()"
        />
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import IntegrationButton from './IntegrationButton.vue';

    export default {
        components: {
            IntegrationButton,
        },
        props: {
            integration: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                open: false,
                loading: false,
            };
        },
        computed: {
            ...mapGetters({
                selectedClientSSN: 'Client/selectedClientSSN',
            }),
        },
        methods: {
            ...mapActions({
                openMember: 'Integrations/sverigesAkassorOpenMember',
            }),
            async handleOpenMember() {
                this.loading = true;
                await this.openMember(this.selectedClientSSN);
                this.loading = false;
            },
        },
    };
</script>
