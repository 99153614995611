<template>
    <main ref="container" class="settings-menu-container">
        <!-- Next previous case-->

        <CaseNavigation v-if="arrowsActive" />

        <!-- heading="Välj layout" -->
        <PopupMenu
            ref="layoutMenu"
            :items="layoutOptions"
            :state="[listLayout]"
            :heading="$t('listSettingsMenu.selLayout')"
            class="px-2"
            returnObject
            width="auto"
            :numberOfItems="2"
            :menuAttach="$refs.container"
            @input="(v) => setLayout(v)"
        >
            <template #button>
                <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            fab
                            small
                            color="white"
                            elevation="0"
                            class="option-button"
                            style="cursor: pointer"
                            v-on="on"
                        >
                            <v-icon size="20" :style="{ color: 'var(--v-gray2-base' }">
                                {{ listLayout.icon }}
                            </v-icon>
                        </v-btn>
                    </template>
                    <!-- <span>{{ listLayout.nameTranslate[$i18n.locale] }}</span> -->
                    <span>{{ listLayout.name }}</span>
                </v-tooltip>
            </template>

            <template #default="{ item }">
                <v-icon size="20" class="mr-2" color="primary">{{ item.icon }}</v-icon>
                <span style="font-size: 12px">{{ item.name }}</span>
            </template>
        </PopupMenu>

        <AssignMultiple />
    </main>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        components: {
            PopupMenu: () => import('@/components/Cases/Navigation/PopupMenu.vue'),
            AssignMultiple: () => import('@/components/Cases/List/AssignMultiple.vue'),
            CaseNavigation: () => import('@/components/Cases/Navigation/CaseNavigation.vue'),
        },

        data() {
            return {
                layoutOptions: [
                    { name: this.$t('listSettingsMenu.big') + ' (80 px)', value: 'big', icon: 'mdi-plus' },
                    { name: this.$t('listSettingsMenu.small') + ' (50 px)', value: 'small', icon: 'mdi-minus' },
                ],
            };
        },
        computed: {
            ...mapState({
                userSettings: (state) => state.System.userSettings,
            }),

            arrowsActive() {
                return this.userSettings.cases.nextArrow.active;
            },

            listLayout() {
                return this.$store.state.Cases.listLayout;
            },
        },
        created() {
            this.updateLayout();
        },

        methods: {
            setLayout(value) {
                this.$store.dispatch('Cases/setListLayout', value);
                this.$refs.layoutMenu?.close();
            },
            updateLayout() {
                if (this.listLayout.value === 'big') {
                    this.setLayout(this.layoutOptions[0]);
                }
                if (this.listLayout.value === 'small') {
                    this.setLayout(this.layoutOptions[1]);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .settings-menu-container {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
    }

    .option-button {
        padding: 0 !important;
        margin: 0 !important;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
    }
</style>
<i18n lang="json">
{
    "en": {
        "listSettingsMenu": {
            "selLayout": "Select layout",
            "big": "Big",
            "small": "Small"
        }
    },
    "sv": {
        "listSettingsMenu": {
            "selLayout": "Välj layout",
            "big": "Stor",
            "small": "Liten"
        }
    }
}
</i18n>
