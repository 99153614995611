export function getGreetingKey() {
    const currentDay = new Date().getDay();
    const currentHour = new Date().getHours();

    switch (currentDay) {
        case 1: {
            // Monday
            if (currentHour >= 5 && currentHour < 12) {
                return 'login.mondayMorning';
            }
            break;
        }
        case 5: {
            // Friday
            if (currentHour >= 12 && currentHour < 17) {
                return 'login.fridayAfternoon';
            }
            break;
        }
        default: {
            if (currentHour >= 5 && currentHour < 12) {
                return 'login.morning';
            }
            if (currentHour >= 12 && currentHour < 17) {
                return 'login.afternoon';
            }
            return 'login.evening';
        }
    }
}
