module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "content": {
          "confirmationDialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take over the case?"])},
          "confirmationDialogMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You are assigned to this case. Do you want to take away the write access from ", _interpolate(_list(0)), ", who is currently working in the case? ", _interpolate(_list(0)), " will be demoted to read-only."])},
          "notAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not allowed to view this case."])}
        }
      },
      "sv": {
        "content": {
          "confirmationDialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta över ärendet?"])},
          "confirmationDialogMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Du är tilldelad detta ärende. Vill du ta över skrivbehörigheten från ", _interpolate(_list(0)), ", som för närvarande arbetar i fallet? ", _interpolate(_list(0)), " kommer att bli degraderad till läsbehörighet."])},
          "notAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har inte behörighet att visa detta ärende."])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"content":{"confirmationDialogTitle":"Take over the case?","confirmationDialogMessage":"You are assigned to this case. Do you want to take away the write access from {0}, who is currently working in the case? {0} will be demoted to read-only.","notAllowed":"You are not allowed to view this case."}},"sv":{"content":{"confirmationDialogTitle":"Ta över ärendet?","confirmationDialogMessage":"Du är tilldelad detta ärende. Vill du ta över skrivbehörigheten från {0}, som för närvarande arbetar i fallet? {0} kommer att bli degraderad till läsbehörighet.","notAllowed":"Du har inte behörighet att visa detta ärende."}}}')
  delete Component.options._Ctor
  
}
