<template>
    <div id="flag" class="text-truncate d-flex" :class="getPositionClass(position)">
        <v-img
            v-if="item.Flag"
            rounded
            style="border-radius: 0px"
            :style="{ maxWidth: listLayout.value == 'big' ? '24px' : '16px' }"
            :src="item.flag"
        />
    </div>
</template>

<script>
    import { positionTypes } from '@/enums/cases.enums';
    import { getPositionClass } from '@/helpers/cases/cases.helper';

    export default {
        props: {
            item: {},
            position: {
                type: String,
                default: positionTypes.LEFT,
                validator(value) {
                    // Shorthand for validator
                    return Object.values(positionTypes).includes(value);
                },
            },
        },
        computed: {
            listLayout() {
                return this.$store.state.Cases.listLayout;
            },

            flag() {
                return 'PublicFiles/Images/flags/4x3/' + this.item.language + '.svg';
            },
        },
        methods: {
            getPositionClass,
        },
    };
</script>

<style scoped>
    .flag-width {
        width: 200px;
    }
    .no-border-radius {
        border-radius: 0px;
    }
</style>
