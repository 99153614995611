module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "moduleCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module Card"])},
        "moduleCardTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to open the module"])},
        "newMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new messages"])}
      },
      "sv": {
        "moduleCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modulkort"])},
        "moduleCardTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicka för att öppna modulen"])},
        "newMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nya meddelanden"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"moduleCard":"Module Card","moduleCardTooltip":"Click to open the module","newMessages":"new messages"},"sv":{"moduleCard":"Modulkort","moduleCardTooltip":"Klicka för att öppna modulen","newMessages":"nya meddelanden"}}')
  delete Component.options._Ctor
  
}
