export const dataTypes = Object.freeze({
    DATE: 'DATE',
    NUMBER: 'NUMBER',
    STRING: 'STRING',
    TIME: 'TIME',
});

export const directionTypes = Object.freeze({
    INBOUND: 'INBOUND',
    OUTBOUND: 'OUTBOUND',
});

export const layoutTypes = Object.freeze({
    MIX: 'MIX',
    PODIUM: 'PODIUM',
    LIST: 'LIST',
});

export const sourceTypes = Object.freeze({
    C1: 'C1',
    S2: 'S2',
    SWITCH: 'SWITCH',
});

export const trendTypes = Object.freeze({
    NEGATIVE: 'NEGATIVE',
    NEUTRAL: 'NEUTRAL',
    POSITIVE: 'POSITIVE',
});

export const stateValues = Object.freeze({
    DEFAULT: 'DEFAULT',
    INITIATED: 'INITIATED',
    FETCHING: 'FETCHING',
    LOADED: 'LOADED',
    ERROR: 'ERROR',
});

export const sortingTypes = Object.freeze({
    ASC: 'ASC',
    DESC: 'DESC',
});

export const timePeriodTypes = Object.freeze({
    TODAY: 1,
    YESTERDAY: -1,
    THIS_WEEK: 7,
    LAST_WEEK: -7,
    THIS_MONTH: 30,
    LAST_MONTH: -30,
});
