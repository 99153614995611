const state = {
    meetingModuleStates: {
        SETUP: 'SETUP',
        INITIATED: 'INITIATED',
        IN_MEETING: 'IN_MEETING',
        MEETING_COMPLETED: 'MEETING_COMPLETED',
        MEETING_ERROR: 'MEETING_ERROR',
    },
    currentMeetingState: 'SETUP',
};
const getters = {};
const mutations = {
    SET_CURRENT_MEETING_STATE(state, newState) {
        state.currentMeetingState = newState;
    },
};
const actions = {
    setVideoMeetingState({ commit }, newState) {
        commit('SET_CURRENT_MEETING_STATE', newState);
    },
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
