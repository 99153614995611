module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "shortcut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shortcut"])},
        "systemLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System language"])},
        "wrapupTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrap-Up Time"])}
      },
      "sv": {
        "shortcut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genväg"])},
        "systemLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systemspråk"])},
        "wrapupTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbetningstid"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"shortcut":"Shortcut","systemLanguage":"System language","wrapupTime":"Wrap-Up Time"},"sv":{"shortcut":"Genväg","systemLanguage":"Systemspråk","wrapupTime":"Bearbetningstid"}}')
  delete Component.options._Ctor
  
}
