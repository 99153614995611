import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/';
import jwt from 'jsonwebtoken';
import Start from '../views/Start/Start.vue';
import title from './router.title';
import AuthHandler from '../auth/auth.handler';
import AuthHelper from '../auth/auth.helper';
import restrictedRoutes from './router.helper';
import Login from '../views/Login/Login.vue';
import Cases from '../views/Cases/Cases.vue'; // This should not be lazy loaded because of performance.

Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Start,
    },
    {
        path: '/Wallboard',
        name: 'Wallboard',
        component: () => import(/* webpackChunkName: "wallboard" */ '../views/Wallboard/Wallboard.vue'),
        meta: {
            requiresLogin: true,
            requiredRole: 1,
            name: 'router.wallboard',
            icon: 'mdi-view-agenda',
        },
    },
    {
        path: '/Board',
        name: 'Board',
        component: () => import(/* webpackChunkName: "board" */ '../views/Board/Board.vue'),
        meta: { requiresLogin: true, requiredRole: 1 },
    },
    {
        path: '/Favorites',
        name: 'Favoriter',
        component: () => import(/* webpackChunkName: "queues" */ '../views/Contacts/Contacts.vue'),
        meta: {
            requiresLogin: true,
            requiredRole: 1,
            name: 'Favoriter',
            icon: 'mdi-star-outline',
        },
    },

    // {
    //   path: '/Dialer',
    //   name: 'Dialer',
    //   component: () => import(/* webpackChunkName: "queues" */ '../views/Dialer/Dialer.vue'),
    //   meta: { requiresLogin: true, requiredRole: 1 }
    // },
    {
        path: '/Groups',
        name: 'Groups',
        component: () => import(/* webpackChunkName: "queues" */ '../views/GroupsQueues/GroupsGridLayout.vue'),
        meta: { requiresLogin: true, requiredRole: 1 },
    },
    {
        path: '/QueuesOverview',
        name: 'QueuesOverview',
        component: () => import(/* webpackChunkName: "queues" */ '../views/Statistics/QueuesOverview.vue'),
        meta: {
            requiresLogin: true,
            requiredRole: 1,
            name: 'router.queuesOverview',
            icon: 'mdi-account-multiple',
        },
    },
    {
        path: '/Cases/:caseId',
        name: 'Case',
        component: () => import(/* webpackChunkName: "cases" */ '../views/Cases/Cases.vue'),
        meta: {
            requiresLogin: true,
            requiredRole: 1,
            name: 'router.cases',
            icon: 'mdi-briefcase',
        },
    },
    {
        path: '/Cases',
        name: 'Cases',
        component: Cases,
        meta: {
            requiresLogin: true,
            requiredRole: 1,
            name: 'router.cases',
            icon: 'mdi-briefcase',
        },
    },
    {
        path: '/Search',
        name: 'Search',
        component: () => import(/* webpackChunkName: "search" */ '../views/Search/Search.vue'),
        meta: {
            requiresLogin: true,
            requiredRole: 1,
            name: 'router.search',
            icon: 'mdi-magnify',
        },
    },
    {
        path: '/Search/Settings',
        name: 'Search Settings',
        component: () => import('../views/Search/Settings.vue'),
        meta: {
            requiresLogin: true,
            requiredRole: 4,
            name: 'router.searchSettings',
            icon: 'mdi-cog',
        },
    },
    {
        path: '/Statistics/Cases',
        name: 'StatisticsCases',
        component: () => import('../views/Statistics/Cases.vue'),
        meta: {
            requiresLogin: true,
            requiredRole: 1,
            name: 'router.caseStatistics',
        },
    },
    {
        name: 'Groupcalls',
        path: '/Statistics/Groupcalls',
        component: () => import('../views/Statistics/Groupcalls.vue'),
        meta: { requiresLogin: true, requiredRole: 1, name: 'router.groupCalls' },
    },
    {
        path: '/C1QueuesInfo',
        name: 'C1QueuesInfo',
        component: () => import('../views/GroupsQueues/C1QueuesInfo.vue'),
        meta: {
            requiresLogin: true,
            requiredRole: 1,
            name: 'router.queueWallboard',
        },
    },

    {
        path: '/Leaderboard',
        component: () => import('../views/Leaderboard/Leaderboard.vue'),
        meta: {
            requiresLogin: true,
            requiredRole: 1,
            name: 'router.leaderboard',
            icon: 'mdi-trophy',
        },
        children: [
            {
                path: '',
                name: 'GlobalLeaderboard',
                component: () => import('../components/Leaderboard/LeaderboardContent.vue'),
                meta: {
                    requiresLogin: true,
                    requiredRole: 1,
                    name: 'router.leaderboard',
                    icon: 'mdi-trophy',
                },
            },
            {
                path: ':userId',
                name: 'IndividualLeaderboard',
                component: () => import('../components/Leaderboard/IndividualLeaderboard.vue'),
                meta: {
                    requiresLogin: true,
                    requiredRole: 1,
                    name: 'router.leaderboard',
                    icon: 'mdi-trophy',
                },
            },
        ],
    },
    {
        path: '/GroupsInfo',
        name: 'GroupsInfo',
        component: () => import('../views/GroupsQueues/GroupsInfo.vue'),
        meta: { requiresLogin: true, requiredRole: 1, name: 'router.groupsInfo' },
    },
    {
        path: '/Admin',
        name: 'Admin',
        component: () => import('../views/Admin/Admin.vue'),
        redirect: '/Admin/GetStarted',
        meta: { requiresLogin: true, requiredRole: 4 },
        children: [
            {
                path: 'Faq',
                component: () => import('../components/Admin/Faq/FaqCollections.vue'),
                meta: { requiresLogin: true, requiredRole: 4, name: 'router.faq' },
                children: [
                    {
                        path: 'Collections',
                        component: () => import('../components/Admin/Faq/Faq.vue'),
                        meta: { requiresLogin: true, requiredRole: 4 },
                    },
                    {
                        path: 'Categories',
                        component: () => import('../components/Admin/Faq/FaqCategory.vue'),
                        meta: { requiresLogin: true, requiredRole: 4 },
                    },
                ],
            },
            {
                path: 'GetStarted',
                component: () => import('../components/Admin/GetStarted/GetStarted.vue'),
                meta: { requiresLogin: true, requiredRole: 1 },
            },
            {
                path: 'AISettings',
                component: () => import('../components/Admin/AISettings/AISettings.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                path: 'Templates',
                component: () => import('../components/Admin/Templates/Templates.vue'),
                meta: { requiresLogin: true, requiredRole: 3 },
            },
            {
                path: 'SystemTemplates',
                component: () => import('../components/Admin/SystemTemplates/SystemTemplates.vue'),
                meta: { requiresLogin: true, requiredRole: 3 },
            },
            {
                path: 'QueueManager',
                component: () => import('../components/Admin/QueueManager/QueueManager.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                path: 'queues/divert-settings',
                component: () => import('../components/Admin/QueueManager/DivertSettings/DivertSettings.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                path: 'Users',
                component: () => import('../components/Admin/UserAdmin/UserAdmin.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                path: '/Admin/Brands',
                name: 'Brands',
                component: () => import('../components/Admin/Brands.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                path: '/Admin/Cases/Categorization/Categories',
                component: () => import('../components/Admin/Cases/CaseCategories.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                path: 'Rules',
                component: () => import('../components/Admin/RuleManagement/RuleManagement.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                path: 'Categories',
                component: () => import('../components/Admin/Cases/CaseCategories.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                path: 'Groups',
                component: () => import('../components/Admin/CompanyGroups/CompanyGroups.vue'),
                meta: { requiresLogin: true, requiredRole: 1 },
            },
            {
                path: 'Groups/Roles',
                component: () => import('../components/Admin/CompanyGroups/Roles/Roles.vue'),
                meta: { requiresLogin: true, requiredRole: 1 },
            },
            {
                path: 'CallbackAdmin',
                component: () => import('../components/Admin/CallbackAdmin/CallbackAdmin.vue'),
                meta: { requiresLogin: true, requiredRole: 1 },
            },
            {
                path: 'CallbackAdmin/CallbackTemplates',
                component: () => import('../components/Admin/CallbackAdmin/CallbackTemplates.vue'),
                meta: { requiresLogin: true, requiredRole: 1 },
            },
            {
                path: 'OpenHours/Access',
                name: 'OpenHours Access',
                component: () => import('../components/Admin/OpenHours/AccessAdministration/AccessAdministration.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                path: 'Integration',
                name: 'IntegrationSettings',
                component: () => import('../components/Admin/Settings/Settings.vue'),
                meta: { requiresLogin: true, requiredRole: 2 },
            },
            {
                path: 'SystemIntegrations',
                name: 'SystemIntegrations',
                component: () => import('../components/Admin/Integration/SystemIntegrations/SystemIntegrations.vue'),
                meta: { requiresLogin: true, requiredRole: 99 },
            },
            {
                path: 'UserIntegrations',
                name: 'UserIntegrations',
                component: () => import('../components/Admin/Integration/UserIntegrations.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                path: 'Integration',
                name: 'Integration',
                component: () => import('../components/Admin/Integration/Integration.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                path: 'Integration/SynkExternal',
                name: 'SynkExternal',
                component: () => import('../components/Admin/Integration/SynkExternal.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                path: 'StandardApps',
                name: 'StandardApps',
                component: () => import('../components/Admin/Integration/StandardApps.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                path: 'Marketing/SmsManager',
                name: 'SmsManager',
                component: () => import('../components/Admin/Marketing/SmsManager.vue'),
                meta: { requiresLogin: true, requiredRole: 3 },
            },

            {
                path: 'Marketing/SmsManagerBeta',
                name: 'SmsManagerBeta',
                component: () => import('../components/Admin/Marketing/SmsManagerBeta.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },

            {
                path: 'Marketing/AudienceManager',
                name: 'AudienceManager',
                component: () => import('../components/Admin/Marketing/AudienceManager.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },

            {
                path: 'CustomerManagement',
                name: 'CustomerManagement',
                component: () => import('../components/Admin/CustomerManagement/CustomerList.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                name: 'TelephonySettings',
                path: '/Admin/Telephony/Numbers',
                component: () => import('../components/Admin/Telephony/NumberTelephonySettings.vue'),
                meta: { requiresLogin: true, requiredRole: 99 },
            },
            {
                name: 'AdminStatistics',
                path: 'Statistics',
                component: () => import('../views/Statistics/GroupStatistics.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },

            {
                name: 'ActivityStatistics',
                path: '/Admin/Statistics/Activity',
                component: () => import('../views/Statistics/ActivityStatistics.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },

            {
                name: 'ActiveCalls',
                path: 'Statistics/ActiveCalls',
                component: () => import('../views/Statistics/ActiveCalls.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                name: 'CallPerformance',
                path: 'Statistics/CallPerformance',
                component: () => import('../views/Statistics/CallPerformanceStatistics.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                name: 'QueueCalls',
                path: 'Statistics/Queue/Calls',
                component: () => import('../views/Statistics/QueueCalls.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                path: 'Statistics/Callback',
                component: () => import('../views/Statistics/CmotStatistics.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                name: 'C1QueueOverview',
                path: 'Statistics/C1QueueOverview',
                component: () => import('../views/Statistics/C1QueueStatisticsOverview.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                name: 'UserGroupCompare',
                path: 'Statistics/UserGroupCompare',
                component: () => import('../views/Statistics/UserGroupCompare.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                name: '',
                path: 'Statistics',
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                name: 'StatisticsActivityStatistics',
                path: 'Statistics/ActivityStatistics',
                component: () => import('../views/Statistics/ActivityStatistics.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                name: 'CallPerformanceStatistics',
                path: 'Statistics/CallPerformanceStatistics',
                component: () => import('../views/Statistics/CallPerformanceStatistics.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                name: 'C1SettingsRoute',
                path: '/Admin/C1Settings/:routeId',
                component: () => import('../components/Admin/C1Settings/C1Settings.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                name: 'C1Settings',
                path: 'C1Settings',
                component: () => import('../components/Admin/C1Settings/C1Settings.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                name: 'SearchSettings',
                path: 'SearchSettings',
                component: () => import('../components/Admin/SearchSettings/SearchSettings.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                name: 'UserDataSettings',
                path: 'UserDataSettings',
                component: () => import('../components/Admin/UserDataSettings/UserDataSettings.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                name: 'InvoiceDocuments',
                path: 'InvoiceDocuments',
                component: () => import('../views/Admin/Billing/InvoiceDocuments/InvoiceDocuments.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                name: 'Billing',
                path: 'Billing',
                component: () => import('../views/Admin/Billing/Billing.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                name: 'StatisticsQueueCDR',
                path: 'Statistics/C1QueueService',
                component: () => import('../views/Statistics/C1QueueService.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                name: 'StatisticsExport',
                path: 'Statistics/Export',
                component: () => import('../views/Statistics/Export.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                name: 'WorkingTimeStatistics',
                path: '/Admin/Statistics/WorkingTimeStatistics',
                component: () => import('../views/Statistics/WorkingTimeStatistics.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                name: 'WallboardSettings',
                path: 'Statistics/wallboard',
                component: () => import('../views/Statistics/WallboardSettings.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
            {
                name: 'BlacklistedEmails',
                path: 'Emails/Blacklisted',
                component: () => import('../views/Admin/Emails/BlacklistedEmails/BlacklistedEmails.vue'),
                meta: { requiresLogin: true, requiredRole: 4 },
            },
        ],
    },
    {
        name: 'Surveys',
        path: '/Admin/Surveys',
        component: () => import(/* webpackChunkName: "surveys" */ '../components/Admin/Surveys/Surveys.vue'),
        meta: {
            requiresLogin: true,
            requiredRole: 3,
            name: 'router.surveys',
            icon: 'mdi-clipboard-text',
        },
    },
    {
        name: 'SurveyQuestions',
        path: '/Admin/Surveys/Questions',
        component: () => import(/* webpackChunkName: "surveys" */ '../components/Admin/Surveys/SurveyQuestions.vue'),
        meta: {
            requiresLogin: true,
            requiredRole: 3,
            name: 'router.questions',
            icon: 'mdi-clipboard-text',
        },
    },
    {
        name: 'SurveyAnswers',
        path: '/Admin/Surveys/Answers',
        component: () => import(/* webpackChunkName: "surveys" */ '../components/Admin/Surveys/SurveyAnswers.vue'),
        meta: {
            requiresLogin: true,
            requiredRole: 3,
            name: 'router.answers',
            icon: 'mdi-clipboard-text',
        },
    },
    {
        name: 'SurveyTriggers',
        path: '/Admin/Surveys/Triggers',
        component: () => import(/* webpackChunkName: "surveys" */ '../components/Admin/Surveys/SurveyTriggers.vue'),
        meta: {
            requiresLogin: true,
            requiredRole: 3,
            name: 'router.triggers',
            icon: 'mdi-clipboard-text',
        },
    },
    {
        path: '/Admin/OpenHours',
        name: 'OpenHours',
        component: () => import('../views/Admin/Openhours/Openhours.vue'),
        meta: { requiresLogin: true, requiredRole: 3 },
    },

    {
        path: '/Admin/Forms',
        name: 'FormsBuilder',
        component: () => import('../views/Admin/Openhours/Openhours.vue'),
        meta: { requiresLogin: true, requiredRole: 3, name: 'router.formsBuilder', icon: 'mdi-format-list-bulleted' },
    },

    {
        name: 'WidgetOverview',
        path: '/widgets',
        component: () => import('../views/Widgets/WidgetOverview.vue'),
        meta: {
            requiresLogin: true,
            requiredRole: 4,
            name: 'router.widgets',
            icon: 'mdi-widgets',
        },
    },
    {
        name: 'WidgetSettings',
        path: '/widgets/:id/settings',
        component: () => import('../views/Widgets/WidgetSettings.vue'),
        meta: {
            requiresLogin: true,
            requiredRole: 4,
            name: 'router.widgetSettings',
            icon: 'mdi-widgets',
        },
    },

    {
        path: '/Admin/OpenHours/Calls/NoMenu',
        name: 'OpenHoursNoMenu',
        component: () => import('../components/Admin/OpenHours/OpenHoursNoMenu.vue'),
        meta: { requiresLogin: true, requiredRole: 4 },
    },
    {
        path: '/login',
        name: 'Login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash]) for this route
        // which is lazy-loaded when the route is visited.
        component: Login,
        meta: { requiresLogin: false },
    },
    {
        path: '/NotFound',
        name: 'Not Found',
        component: () => import(/* webpackChunkName: "about" */ '../views/Error/NotFound.vue'),
        meta: { requiresLogin: true },
    },
    {
        path: '*',
        name: 'Not Found Page',
        component: () => import(/* webpackChunkName: "about" */ '../views/Error/NotFound.vue'),
        meta: { requiresLogin: true },
    },
];

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, _, next) => {
    if (to.name === 'FormsBuilder') {
        // Open the forms builder in a new tab
        window.open('/editor/forms', '_blank');
        return;
    }
    title(to, next);
    // if we have a accesstoken and it is expired, we refresh
    if (store.state.Auth.accessToken) {
        const r = jwt.decode(store.state.Auth.accessToken);
        if (Date.now() >= r.exp * 1000) {
            const tokens = await AuthHandler.refreshTokens();
            AuthHelper.saveTokens(tokens);
        }
    } else if (to.path !== '/Login') {
        const accessedPath = { path: to.path, query: to.query };
        store.dispatch('Auth/setRedirectTo', accessedPath);
        next('/Login');
        return;
    }

    // Check if user has required role
    if (to.meta.requiredRole) {
        const requiredRoles = store.getters['Auth/getRoles'].filter((item) => {
            return to.meta.requiredRole <= item;
        });

        if (!requiredRoles.length && to.path !== '/Board') {
            next('/404');
            return;
        }
    }

    const restricted = restrictedRoutes.exists(to.path.toLowerCase());
    if (restricted) {
        next('/404');
        return;
    }
    next();
});

export default router;
