<template>
    <transition name="fade">
        <div
            v-if="loading || caseLoading"
            class="softShadow d-flex flex-column justify-center align-center"
            style="
                position: fixed;
                top: calc(50vh - 80px / 2);
                left: calc(50vw - 80px / 2);
                z-index: 9998;
                background-color: white;
                padding: 10px;
                border-radius: 6px;
                width: 80px;
                height: 80px;
            "
        >
            <v-progress-circular indeterminate size="32" color="primary" style="z-index: 9999; display: block" />
        </div>
    </transition>
</template>
<script>
    export default {
        props: { loading: Boolean, default: false },

        computed: {
            caseLoading() {
                return this.$store.state.Cases.loading;
            },
        },
    };
</script>
<style scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease-out;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
