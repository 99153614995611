/**
 * Format milliseconds to readable time string with units
 * @param {number} milliseconds
 * @returns {string} formatted time string - X h Y m Z s
 */
export function formatTime(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours} h ${minutes} m ${seconds} s`;
}
