module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "recursiveListItem": {
          "popOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pop out"])}
        }
      },
      "sv": {
        "recursiveListItem": {
          "popOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poppa ut"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"recursiveListItem":{"popOut":"Pop out"}},"sv":{"recursiveListItem":{"popOut":"Poppa ut"}}}')
  delete Component.options._Ctor
  
}
