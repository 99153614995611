module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "button": {
          "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])}
        },
        "link": {
          "checkBrowser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to open URL in a new window. Please check your browser settings."])}
        },
        "social": {
          "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
          "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
          "mentioned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentioned"])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" from "])},
          "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" on "])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])},
          "reacted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>reacted</b>"])}
        },
        "sentiment": {
          "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative"])},
          "neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutral"])}
        },
        "bottom": {
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syncing..."])},
          "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synced"])}
        },
        "action": {
          "isHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment is hidden"])}
        }
      },
      "sv": {
        "button": {
          "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svara"])}
        },
        "link": {
          "checkBrowser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misslyckades med att öppna URL i ett nytt fönster. Vänligen kontrollera dina webbläsarinställningar."])}
        },
        "social": {
          "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
          "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inlägg"])},
          "mentioned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nämndes"])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" från "])},
          "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" på "])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okänd"])},
          "reacted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>reagerade</b>"])}
        },
        "sentiment": {
          "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positiv"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativ"])},
          "neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutral"])}
        },
        "bottom": {
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkar..."])},
          "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkad"])}
        },
        "action": {
          "isHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentaren är dold"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"button":{"answer":"Answer"},"link":{"checkBrowser":"Failed to open URL in a new window. Please check your browser settings."},"social":{"comment":"Comment","post":"Post","mentioned":"Mentioned","from":" from ","on":" on ","unknown":"Unknown","reacted":"<b>reacted</b>"},"sentiment":{"positive":"Positive","negative":"Negative","neutral":"Neutral"},"bottom":{"pending":"Syncing...","sent":"Synced"},"action":{"isHidden":"Comment is hidden"}},"sv":{"button":{"answer":"Svara"},"link":{"checkBrowser":"Misslyckades med att öppna URL i ett nytt fönster. Vänligen kontrollera dina webbläsarinställningar."},"social":{"comment":"Kommentar","post":"Inlägg","mentioned":"Nämndes","from":" från ","on":" på ","unknown":"Okänd","reacted":"<b>reagerade</b>"},"sentiment":{"positive":"Positiv","negative":"Negativ","neutral":"Neutral"},"bottom":{"pending":"Synkar...","sent":"Synkad"},"action":{"isHidden":"Kommentaren är dold"}}}')
  delete Component.options._Ctor
  
}
