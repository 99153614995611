module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "empty": {
          "noCases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cases is hidden in filtermenu"])}
        }
      },
      "sv": {
        "empty": {
          "noCases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ärenden är dolda i filtermenyn"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"empty":{"noCases":"Cases is hidden in filtermenu"}},"sv":{"empty":{"noCases":"Ärenden är dolda i filtermenyn"}}}')
  delete Component.options._Ctor
  
}
