import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSparkline } from 'vuetify/lib/components/VSparkline';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"none"}},[_c(VContainer,[_c(VRow,[_c(VCol,[_c(VCard,{staticClass:"pa-3",attrs:{"elevation":"1"}},[_vm._v("Content")])],1),_c(VCol,[_c(VCard,{staticClass:"pa-3",attrs:{"elevation":"1"}},[_c(VCardText,[_c(VSheet,[_c(VSparkline,{attrs:{"value":_vm.value,"color":"primary","height":"100","padding":"24","stroke-linecap":"round","smooth":""},scopedSlots:_vm._u([{key:"label",fn:function(item){return [_vm._v(" "+_vm._s(item.value)+" ")]}}])})],1)],1),_c(VCardText,[_c('div',{staticClass:"text-h4 font-weight-thin"},[_vm._v(" "+_vm._s(_vm.$t('start.cases'))+" ")])])],1)],1)],1),_c(VRow,[_c(VCol,[_c(VCard,{staticClass:"pa-3",attrs:{"min-height":"260px","color":"primary","elevation":"1"}},[_c(VCardText,{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.$t('start.welcome'))+" "+_vm._s(_vm.loggedInUser.userName)+" ")])],1)],1),_c(VCol,[_c(VCard,{staticClass:"pa-3",attrs:{"min-height":"260px","elevation":"1"}},[_vm._v("Content")])],1),_c(VCol,[_c(VCard,{staticClass:"pa-3",attrs:{"min-height":"260px","elevation":"1"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }