import i18n from '../../i18n';

import { validateEmail } from '../../utils/emails.util';

export default {
    // Validate hex code
    hex(hexCode) {
        const hexRegex = /^#?[\dA-Fa-f]{6}$/;
        const hexRegexTest = hexRegex.test(hexCode);
        if (hexRegexTest) {
            return true;
        }
        return i18n.t('validation.hexValidation');
    },

    position({ position, active }) {
        // Check if position is a number
        if (typeof position !== 'number' && Number.isNaN(Number(position))) {
            return i18n.t('validation.positionValidation');
        }

        // Check if active is a boolean
        if (typeof active !== 'boolean') {
            return i18n.t('validation.positionValidation');
        }

        // If both checks pass, the values are valid
        return true;
    },

    // Validate checkbox & switch
    boolean(value) {
        if (typeof value === 'boolean') {
            return true;
        }
        return i18n.t('validation.booleanValidation');
    },

    // Validate time of day
    // HH:mm format (24-hour format)
    timeOfDay(timeOfDay) {
        const validateTimeOfDayRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
        const validateTimeOfDayTest = validateTimeOfDayRegex.test(timeOfDay);
        if (validateTimeOfDayTest) {
            return true;
        }
        return i18n.t('validation.timeOfDayValidation');
    },

    // Validate time in seconds
    timeInSeconds(seconds) {
        const timeInSecondsRegex = /^\d+$/;
        const timeInSecondsTest = timeInSecondsRegex.test(seconds);
        if (timeInSecondsTest) {
            return true;
        }
        return i18n.t('validation.timeInSecondsValidation');
    },

    // Validate time in minutes
    timeInMinutes(minutes) {
        const timeInMinutesRegex = /^\d+$/;
        const timeInMinutesTest = timeInMinutesRegex.test(minutes);
        if (timeInMinutesTest) {
            return true;
        }
        return i18n.t('validation.timeInMinutesValidation');
    },

    // Validate number
    number(number) {
        if (number === '' || number === null || number === undefined) {
            return i18n.t('validation.numberValidation');
        }
        const numberRegex = /^\d+$/;
        const numberRegexTest = numberRegex.test(number);
        if (numberRegexTest) {
            return true;
        }
        return i18n.t('validation.numberValidation');
    },

    // Validate at least 3 letters or digits input
    atLeastThreeCharacters(value) {
        if (value.length >= 3) {
            return true;
        }

        return i18n.t('validation.atLeastThreeCharactersValidation');
    },

    // Validate email array
    email(emails) {
        let newEmails = emails;
        if (typeof emails === 'string') {
            newEmails = emails.split(',');
        }

        if (newEmails.length === 0) {
            return '';
        }

        // Email
        for (const email of emails) {
            const emailRegexTest = validateEmail(email);
            if (!emailRegexTest) {
                return i18n.t('validation.eMailValidation');
            }
        }

        return true;
    },
};
