module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "getMemberButton": {
          "ourPages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our pages"])},
          "ssn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social security number"])},
          "fetch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fetch"])},
          "fetchingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fetching data..."])}
        }
      },
      "sv": {
        "getMemberButton": {
          "ourPages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Våra sidor"])},
          "ssn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnummer"])},
          "fetch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hämta"])},
          "fetchingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hämtar data..."])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"getMemberButton":{"ourPages":"Our pages","ssn":"Social security number","fetch":"Fetch","fetchingData":"Fetching data..."}},"sv":{"getMemberButton":{"ourPages":"Våra sidor","ssn":"Personnummer","fetch":"Hämta","fetchingData":"Hämtar data..."}}}')
  delete Component.options._Ctor
  
}
