import { Registerer, UserAgent } from 'sip.js';
import pbx from '../../api/pbx/pbx';
import { registerStates, callStates, callDirections } from '../../constants/enums/sip.enums';
import i18n from '../../i18n';
import config from '../../app/config';
import { httpStatusCode } from '../../enums/response.enums';

const maxRetries = 5;

const state = {
    userAgent: null,
    sipData: {},
    sipState: registerStates.DISABLED,
    reconnectRetries: 0,
    enableSipDebug: false,
    refreshTimer: null,
};

const actions = {
    // #region Socket events
    async SOCKET_connect({ dispatch }) {
        if (state.sipState === registerStates.DISABLED || state.sipState >= registerStates.READY) return;
        const isActive = await dispatch('isSIPActive');
        if (isActive) {
            dispatch('checkIfPhoneAlreadyActive');
        } else {
            dispatch('setState', registerStates.DISABLED);
        }
    },

    async SOCKET_setAsMainSip({ dispatch }) {
        const isActive = await dispatch('isSIPActive');
        if (!isActive) return;
        dispatch('becomeMainUser', true);
    },

    SOCKET_removeAsMainSip({ dispatch }) {
        dispatch('removeAsMainSip');
    },

    async SOCKET_disconnect({ dispatch }) {
        const isActive = await dispatch('isSIPActive');
        if (!isActive) return;
        const data = {
            activeClientId: '',
            isActive: false,
            status: 'offline',
            replace: false,
        };
        if (state.sipState > registerStates.READY) {
            dispatch('setStatus', data);
        }
        dispatch('setState', registerStates.DISCONNECTED);
    },
    // SOCKET_users({ rootState, commit }, data) {
    //     const loggedInUser = rootState.Auth.userObject;
    // },
    // #endregion

    async init({ dispatch }) {
        const res = await dispatch('Integrations/getActiveStates', null, { root: true });
        if (!res) {
            return false;
        }
        if (res.FREESWITCH) {
            dispatch('setState', registerStates.INIT);
            return true;
        }
        return false;
    },

    async login({ dispatch, rootState, commit }) {
        const res = await dispatch('getCredentials');
        if (res.status !== httpStatusCode.OK) {
            dispatch('setState', registerStates.ERROR);
            return;
        }
        const newData = { ...res.data, password: res.data.destinationAddr };
        delete newData.destinationAddr;
        commit('SET_SIP_DATA', newData);
        if (state.sipData.error) {
            console.error(state.sipData.error);
            if (state.sipData.error === 'No assigned Numbers') {
                dispatch('setState', registerStates.NO_NUMBER);
                return;
            }
            dispatch('setState', registerStates.ERROR);
            return;
        }

        const userAgentOptions = {
            uri: UserAgent.makeURI(`sip:${state.sipData.username}@default`),
            transportOptions: {
                server: config.freeSWITCHUrl + '?token=' + rootState.Auth.accessToken,
            },
            authorizationPassword: state.sipData.password,
            authorizationUsername: state.sipData.username,
            logBuiltinEnabled: state.enableSipDebug,
        };
        const userAgent = new UserAgent(userAgentOptions);
        const registered = userAgent.isConnected();
        if (registered) {
            dispatch('setState', registerStates.READY);
            return;
        }
        const registererOptions = {};
        const registerer = new Registerer(userAgent, registererOptions);
        const registererRegisterOptions = {
            requestDelegate: {
                onAccept() {
                    setTimeout(() => {
                        dispatch('setState', registerStates.READY);
                    }, 200);
                },
                onReject() {
                    const data = structuredClone(state.sipData);
                    data.error = 'Unable to register';
                    commit('SET_SIP_DATA', data);
                    // This is to prevent the loading screen from flashing
                    setTimeout(() => {
                        dispatch('setState', registerStates.NOT_REGED);
                    }, 1000);
                },
            },
        };
        userAgent.delegate = {
            onDisconnect() {
                if (rootState.Auth.isLoggedIn) {
                    dispatch('setState', registerStates.DISCONNECTED);
                    return;
                }
                dispatch('setState', registerStates.DISABLED);
            },
            onInvite(invitation) {
                const incomingSession = invitation;
                const { maxConcurrentCalls, activeSessions } = rootState.Sip;
                if (activeSessions.length >= maxConcurrentCalls) {
                    incomingSession.reject();
                    return;
                }
                dispatch('Sip/addSession', incomingSession, { root: true });
                const requestMessage = incomingSession.incomingInviteRequest.message.from;
                const obj = {
                    isMuted: false,
                    isHold: false,
                    direction: callDirections.INCOMING,
                    startTime: Date.now(),
                    endTime: undefined,
                    duration: undefined,
                    displayName: requestMessage._displayName || i18n.t('global.unknown'),
                    callerNumber:
                        requestMessage.uri.user.replace('-webb', '').replace('-phone', '') || i18n.t('global.unknown'),
                    callState: callStates.RINGING,
                    hangupReason: undefined,
                };
                dispatch('Sip/setCallsInfo', { data: obj, id: incomingSession.id }, { root: true });
                const notification = new Notification(requestMessage._displayName + ' is calling you', {
                    body: 'You have a call from: ' + requestMessage._displayName,
                });
                notification.addEventListener('click', () => {
                    window.focus();
                    notification.close();
                });
            },
        };
        try {
            await userAgent.start();
            await registerer.register(registererRegisterOptions);
            commit('SET_USERAGENT', userAgent);
        } catch (error) {
            dispatch('setState', registerStates.ERROR);
        }
    },
    async becomeMainUser({ dispatch, commit }, replace = false) {
        const isActive = await dispatch('isSIPActive');
        if (!isActive) return;
        if (!this._vm.$socket.id) {
            console.warn('sip.auth.store.js:174 ~ becomeMainUser ~ : No socket id. Waiting for socket to connect');
            // This is to prevent the loading screen from flashing
            setTimeout(() => {
                dispatch('setState', registerStates.ERROR);
            }, 1000);
            return;
        }
        const data = {
            activeClientId: this._vm.$socket.id,
            isActive: true,
            status: 'online',
            replace,
        };
        const res = await dispatch('setStatus', data);
        if (res.status === 200) {
            commit('SET_RECONNECT_RETRIES', 0);
            dispatch('setState', registerStates.REGISTERING);
            return;
        }
        dispatch('setState', registerStates.ERROR);
    },
    async handleRefresh({ dispatch, commit, state }) {
        commit('CLEAR_TIMEOUT');
        const res = await dispatch('getCredentials');
        if (res.status !== httpStatusCode.OK) {
            if (res.status !== httpStatusCode.FORBIDDEN) {
                dispatch('setState', registerStates.ERROR);
                return;
            }
            // This is to prevent it from just disappearing
            setTimeout(() => {
                dispatch('setState', registerStates.DISABLED);
            }, 1000);
            return;
        }
        const newData = { ...res.data, password: res.data.destinationAddr };
        delete newData.destinationAddr;
        commit('SET_SIP_DATA', newData);
        if (state.sipData.error) {
            console.error(state.sipData.error);
            if (state.sipData.error === 'No assigned Numbers') {
                dispatch('setState', registerStates.NO_NUMBER);
                return;
            }
            dispatch('setState', registerStates.ERROR);
            return;
        }
        state.userAgent.options.authorizationPassword = newData.password;
        try {
            await state.userAgent.reconnect();
            console.log('refreshSIP -> success');
            dispatch('setState', registerStates.READY);
        } catch (error) {
            console.log('refreshSIP -> error');
            console.error(error);
            dispatch('setState', registerStates.ERROR);
        }
    },
    setState({ commit, dispatch }, newState) {
        const oldState = state.sipState;
        if (oldState === newState && newState !== registerStates.REGISTERING) {
            return;
        }
        commit('SET_SIP_STATE', newState);
        switch (newState) {
            case registerStates.DISABLED: {
                break;
            }
            case registerStates.INIT: {
                dispatch('checkIfPhoneAlreadyActive');
                break;
            }
            case registerStates.NOT_REGED: {
                break;
            }
            case registerStates.ERROR: {
                if (state.reconnectRetries < maxRetries) {
                    commit('SET_RECONNECT_RETRIES', state.reconnectRetries + 1);
                    dispatch('setState', registerStates.REGISTERING);
                }
                break;
            }
            case registerStates.NO_NUMBER: {
                break;
            }
            case registerStates.DISCONNECTED: {
                break;
            }
            case registerStates.REGISTERING: {
                if (state.reconnectRetries > maxRetries) {
                    dispatch('setState', registerStates.ERROR);
                } else if (state.reconnectRetries !== 0) {
                    setTimeout(() => {
                        dispatch('login');
                    }, 5000);
                } else {
                    dispatch('login');
                }
                break;
            }
            case registerStates.ALREADY_ACTIVE: {
                break;
            }
            case registerStates.READY: {
                commit('SET_RECONNECT_RETRIES', 0);
                dispatch('setTimerTimeout');
                break;
            }
            case registerStates.RE_REG: {
                dispatch('handleRefresh');
                break;
            }
            case registerStates.BECOME_MAIN: {
                dispatch('becomeMainUser');
                break;
            }
            case registerStates.REPLACE_MAIN: {
                dispatch('becomeMainUser', true);
                break;
            }

            default: {
                break;
            }
        }
    },
    setTimerTimeout({ commit, dispatch }) {
        commit('CLEAR_TIMEOUT');
        const expiresIn = Math.max(state.sipData.expires - Date.now(), 0) || 1000 * 10;
        const cb = () => {
            dispatch('setState', registerStates.RE_REG);
        };
        commit('SET_TIMEOUT', { cb, expiresIn });
    },
    removeAsMainSip({ dispatch }) {
        dispatch('setState', registerStates.ALREADY_ACTIVE);
    },
    async checkIfPhoneAlreadyActive({ dispatch }) {
        const isActive = await dispatch('isSIPActive');
        if (!isActive) return;
        const res = await dispatch('getStatus');
        if (res.status !== httpStatusCode.OK) {
            dispatch('setState', registerStates.ERROR);
            return;
        }
        const { data } = res;
        if (data.isActive && data.activeClientId !== this._vm.$socket.id) {
            dispatch('setState', registerStates.ALREADY_ACTIVE);
            return;
        }
        dispatch('setState', registerStates.BECOME_MAIN);
    },
    refreshSIP({ dispatch }) {
        dispatch('setState', registerStates.RE_REG);
    },
    async logout({ dispatch }) {
        const data = {
            activeClientId: '',
            isActive: false,
            status: 'offline',
        };
        await dispatch('setStatus', data);
    },
    async isSIPActive({ dispatch }) {
        const res = await dispatch('Integrations/getActiveStates', null, { root: true });
        if (!res) {
            return false;
        }
        if (!res.FREESWITCH) {
            dispatch('setState', registerStates.DISABLED);
        }
        return res.FREESWITCH;
    },

    // #region Api calls
    async setStatus(_, payload) {
        try {
            return await pbx.setStatus(payload);
        } catch ({ response }) {
            return response;
        }
    },
    async getCredentials() {
        try {
            const { data, status } = await pbx.getCredentials();
            return { data, status };
        } catch ({ response }) {
            return { data: response.data || null, status: response.status };
        }
    },
    async getStatus() {
        try {
            const { data, status } = await pbx.getStatus();
            return { data, status };
        } catch ({ response }) {
            return { data: response.data || null, status: response.status };
        }
    },
    // #endregion

    // #region Setters
    setSipDebug({ commit }, data) {
        commit('SET_SIP_DEBUG', data);
    },
    handleClientLogout({ dispatch }) {
        dispatch('setState', registerStates.DISABLED);
        dispatch('removeUserAgent');
    },
    removeUserAgent({ commit }) {
        if (state.userAgent) {
            state.userAgent.stop();
        }
        commit('SET_USERAGENT', null);
    },
    // #endregion
};
const getters = {
    getMaxRetries: () => maxRetries,
};

const mutations = {
    SET_USERAGENT(state, data) {
        state.userAgent = data;
    },
    SET_SIP_STATE(state, data) {
        state.sipState = data;
    },
    SET_SIP_DATA(state, data) {
        state.sipData = data;
    },
    SET_SIP_DEBUG(state, data) {
        state.enableSipDebug = data;
    },
    SET_RECONNECT_RETRIES(state, data) {
        state.reconnectRetries = data;
    },
    CLEAR_TIMEOUT(state) {
        clearTimeout(state.refreshTimer);
    },
    SET_TIMEOUT(state, { cb, expiresIn }) {
        state.refreshTimer = setTimeout(cb, expiresIn);
    },
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
