import axios from 'axios';

function createSMSCampaign(data) {
    return axios.post('/campaigns/sms', data);
}

function getSMSCampaigns(data) {
    return axios.get('/campaigns/sms', { params: data });
}

function getCampignResults({ page, limit, campaignId }) {
    return axios.get(`/campaigns/${campaignId}/results`, { params: { page, limit } });
}

function getCampaignJobItems({ campaignId, status, page, limit }) {
    return axios.get(`/campaigns/${campaignId}/job-items`, { params: { status, page, limit } });
}

export default {
    createSMSCampaign,
    getSMSCampaigns,
    getCampignResults,
    getCampaignJobItems,
};
