import AuthHandler from '../auth/auth.handler';
import AuthHelper from '../auth/auth.helper';
import store from '../store';
import { httpStatusCode } from '../enums/response.enums';

let authTokenRequest = null;

export async function authInterceptorErrorHandler(error, axios) {
    const { response } = error;
    const { config } = error;

    if (
        response &&
        response.status === httpStatusCode.UNAUTHORIZED &&
        response.config.url !== 'auth' &&
        response.config.url !== 'auth/refresh' &&
        config &&
        !config.__isRetryRequest
    ) {
        try {
            const res = await getAuthToken();
            if (res?.private && res?.public) {
                const accessToken = res.private.AccessToken;
                config.headers.Authorization = accessToken;
                if (store.state.Auth.accessToken !== accessToken) {
                    await AuthHelper.saveTokens(res);
                }
                config.__isRetryRequest = true;
                return axios(config);
            }
            await store.dispatch('Auth/logoutClient', 'Du har blivit utloggad pga inaktivitet.');
        } catch (error_) {
            await store.dispatch('Auth/logoutClient', 'Du har blivit utloggad pga inaktivitet.');
            throw error_;
        }
    }
    return response;
}
function resetAuthTokenRequest() {
    authTokenRequest = null;
}
function getAuthToken() {
    if (!authTokenRequest) {
        authTokenRequest = AuthHandler.refreshTokens();
        authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
    }
    return authTokenRequest;
}
