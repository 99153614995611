module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "signature": {
          "showSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Signature"])},
          "hideSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Signature"])}
        }
      },
      "sv": {
        "signature": {
          "showSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa signatur"])},
          "hideSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dölj signatur"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"signature":{"showSignature":"Show Signature","hideSignature":"Hide Signature"}},"sv":{"signature":{"showSignature":"Visa signatur","hideSignature":"Dölj signatur"}}}')
  delete Component.options._Ctor
  
}
