import { format, sub, startOfToday, startOfWeek, startOfMonth, endOfWeek, endOfMonth, subMonths } from 'date-fns';
import i18n from '../../i18n';

import { trendTypes, dataTypes, timePeriodTypes } from '../../enums/leaderboard.enums';

/// //////////////////////////////////////////////////
// -------------- EXPORTED FUNCTIONS ---------------//
/// //////////////////////////////////////////////////

/**
 * Returns the corresponding key for the left header title based on the given value.
 * @param {string} value - The value representing the time period type.
 * @returns {string} - The key for the left header title.
 */
export function getLeftHeaderTitleKey(value) {
    switch (value) {
        case timePeriodTypes.TODAY: {
            return 'today';
        }
        case timePeriodTypes.YESTERDAY: {
            return 'yesterday';
        }
        case timePeriodTypes.THIS_WEEK: {
            return 'thisWeek';
        }
        case timePeriodTypes.LAST_WEEK: {
            return 'lastWeek';
        }
        case timePeriodTypes.THIS_MONTH: {
            return 'thisMonth';
        }
        case timePeriodTypes.LAST_MONTH: {
            return 'lastMonth';
        }

        default: {
            console.log('No matching value');
            return '';
        }
    }
}
/**
 * Returns the corresponding key for the left header title based on the given value.
 * @param {string} value - The value representing the time period type.
 * @returns {string} - The key for the left header title.
 */
export function getDateByDay(value, startOrEnd) {
    switch (value) {
        case timePeriodTypes.TODAY: {
            return format(startOfToday(), 'yyyy-MM-dd');
        }
        case timePeriodTypes.YESTERDAY: {
            return format(sub(startOfToday(), { days: 1 }), 'yyyy-MM-dd');
        }
        case timePeriodTypes.THIS_WEEK: {
            if (startOrEnd === 'from') {
                return format(startOfWeek(startOfToday(), { weekStartsOn: 1 }), 'yyyy-MM-dd');
            }
            return format(startOfToday(), 'yyyy-MM-dd');
        }
        case timePeriodTypes.LAST_WEEK: {
            const startOfLastWeek = sub(startOfWeek(startOfToday(), { weekStartsOn: 1 }), { weeks: 1 });
            const endOfLastWeek = sub(endOfWeek(startOfToday(), { weekStartsOn: 1 }), { weeks: 1 });

            if (startOrEnd === 'from') {
                return format(startOfLastWeek, 'yyyy-MM-dd');
            }
            return format(endOfLastWeek, 'yyyy-MM-dd');
        }
        case timePeriodTypes.THIS_MONTH: {
            if (startOrEnd === 'from') {
                return format(startOfMonth(startOfToday()), 'yyyy-MM-dd');
            }
            return format(startOfToday(), 'yyyy-MM-dd');
        }
        case timePeriodTypes.LAST_MONTH: {
            const startOfLastMonth = sub(startOfMonth(startOfToday()), { months: 1 });

            if (startOrEnd === 'from') {
                return format(startOfLastMonth, 'yyyy-MM-dd');
            }
            const endOfLastMonth = endOfMonth(subMonths(new Date(), 1));

            return format(endOfLastMonth, 'yyyy-MM-dd');
        }

        default: {
            console.log('No matching value');
            return '';
        }
    }
}

/**
 * Returns the first state change between two state objects.
 * @param {Object} newStates - The new state object.
 * @param {Object} oldStates - The old state object.
 * @returns {Object|null} - The first state change as an object with 'key' and 'value' properties, or null if no state change is found.
 */
export function getStateChange(newStates, oldStates) {
    if (!newStates || !oldStates) return null;
    const keys = Object.keys(newStates);
    for (const key of keys) {
        if (newStates[key] === oldStates[key]) continue;
        return { key, value: newStates[key] };
    }
    return null;
}

/**
 * Returns the trend type based on the given percent value.
 * @param {number} percent - The percent value.
 * @returns {string} The trend type (NEGATIVE, NEUTRAL, or POSITIVE).
 */
export function getPercentState(percent) {
    const RELATIVE_PERCENT_NUMBER = 0;
    switch (true) {
        case percent < RELATIVE_PERCENT_NUMBER: {
            return trendTypes.NEGATIVE;
        }
        case percent === RELATIVE_PERCENT_NUMBER: {
            return trendTypes.NEUTRAL;
        }
        case percent > RELATIVE_PERCENT_NUMBER: {
            return trendTypes.POSITIVE;
        }
        default: {
            console.log('Invalid percent');
            return trendTypes.NEUTRAL;
        }
    }
}

/**
 * Returns the style object for a given trend state.
 * @param {string} state - The trend state.
 * @returns {Object} The style object containing backgroundColor, icon, and color properties.
 */
export function getTrendStyle(state) {
    switch (state) {
        case trendTypes.NEGATIVE: {
            return {
                backgroundColor: 'rgba(255, 0, 0, 0.2)',
                icon: 'mdi-trending-down',
                color: 'rgba(176, 96, 108,1)',
            };
        }
        case trendTypes.NEUTRAL: {
            return {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                icon: 'mdi-trending-neutral',
                color: 'rgba(0, 0, 0, 0.4)',
            };
        }
        case trendTypes.POSITIVE: {
            return {
                backgroundColor: 'rgba(5, 189, 5, 0.2)',
                icon: 'mdi-trending-up',
                color: 'rgba(92, 180, 150, 1)',
            };
        }
        default: {
            console.log('Invalid percent state');
            return {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                icon: 'mdi-trending-neutral',
                color: 'rgba(0, 0, 0, 0.4)',
            };
        }
    }
}

/**
 * Retrieves the podium items from the given items array.
 * If sorting is provided, it rearranges the podium items based on the sorting value.
 *
 * @param {Array} items - The array of items.
 * @param {Object} sorting - The sorting object.
 * @returns {Array} - The podium items.
 */
export function getLeaderboardPodiumItems(inputItems, sorting) {
    const items = structuredClone(inputItems);

    const podiumItems = [];

    if (!items) {
        return podiumItems;
    }

    // Change the order of the items to match the podium order
    if (items[1]) {
        podiumItems.push(items[1]);
    }
    if (items[0]) {
        podiumItems.push(items[0]);
    }
    if (items[2]) {
        podiumItems.push(items[2]);
    }

    if (!sorting || !sorting.value) {
        return podiumItems;
    }
    return podiumItems.map((item) => {
        const { dataPoints } = item;
        const sortingIndex = dataPoints.findIndex((dataPoint) => dataPoint.value === sorting.value);
        const sortingDataPoint = dataPoints.splice(sortingIndex, 1);

        dataPoints.unshift(sortingDataPoint[0]);
        return item;
    });
}

/**
 * Calculates the layout of cards within a grid based on the specified number of columns.
 *
 * @param {Object} options - The options for calculating the card layout.
 * @param {number} options.columns - The number of columns in the grid.
 * @param {Array} options.cards - The array of cards to be placed within the grid.
 * @returns {Array} The array of reworked cards with their calculated placement within the grid.
 */
export function calculateCardLayout({ columns, cards }) {
    // Initialize an empty grid row with the specified number of columns
    const emptyGridRow = Array.from({ length: columns }, () => 0);

    // Initialize an empty array to store the reworked cards
    const reworkedCards = [];

    // Initialize the grid with an initial empty row
    let grid = [emptyGridRow];

    // Iterate through each card to calculate its placement within the grid
    for (const card of cards) {
        // Calculate the card placement and obtain the result and updated grid
        const { result, grid: newGrid } = calculateCardPlacement({ card, columns, grid, emptyGridRow });

        // Add the result to the reworked cards array
        reworkedCards.push(result);

        // Update the grid for the next iteration
        grid = newGrid;
    }

    // Return the array of reworked cards
    return reworkedCards;
}

/**
 * Formats the leaderboard value based on the given data type.
 *
 * @param {any} value - The value to be formatted.
 * @param {string} type - The data type of the value.
 * @returns {any} - The formatted value.
 */
export function formatLeaderboardValueByType(value, type) {
    switch (type) {
        case dataTypes.DATE: {
            if (!value) {
                return i18n.t('leaderboard.noData');
            }
            return formatLeaderboardDate(value);
        }
        case dataTypes.NUMBER: {
            const DEFAULT_VALUE = 0;
            return value || DEFAULT_VALUE;
        }
        case dataTypes.STRING: {
            return value || i18n.t('leaderboard.noData');
        }
        case dataTypes.TIME: {
            const DEFAULT_VALUE = '00:00:00';
            if (!value) {
                return DEFAULT_VALUE;
            }
            return formatLeaderboardTime(value);
        }

        default: {
            console.log('Unknown data type');
            return value;
        }
    }
}

/// //////////////////////////////////////////////////
// ------------ NON EXPORTED FUNCTIONS -------------//
/// //////////////////////////////////////////////////

/**
 * Calculates the placement of a card within a grid.
 *
 * @param {Object} options - The options for card placement.
 * @param {Object} options.card - The card to be placed.
 * @param {number} options.columns - The number of columns in the grid.
 * @param {Array} options.grid - The current grid.
 * @param {Object} options.emptyGridRow - The empty grid row template.
 * @returns {Object} - The result of the card placement and the updated grid.
 */
function calculateCardPlacement({ card, columns, grid, emptyGridRow }) {
    let row = 0;
    let col = 0;

    // Determine the effective width of the card within the grid
    const width = card.width <= columns ? card.width : columns;

    // Extract the height of the card
    const { height } = card;

    // Extend the grid to accommodate the height of the card
    grid.push(...getNewGridRows(height, emptyGridRow));

    // Add empty rows to the grid based on the height of the card
    for (let i = 0; i < height; i++) {
        grid.push(structuredClone(emptyGridRow));
    }

    // Find an empty spot for the current item in the grid
    while (!isEmptySpot(grid, row, col, width, height, columns)) {
        // If no spot is found, move to the next column in the same row
        col++;

        // If we reach the end of the row, move to the next row
        if (col + card.width > columns) {
            col = 0;
            row++;
        }

        // If we reach the end of the grid, add a new row
        if (row === grid.length) {
            grid.push(structuredClone(emptyGridRow));
        }
    }

    // Place the item in the grid
    grid = placeItem({ grid, row, col, width, height, columns });

    // Save the result of the card placement
    const result = getCardResult(card, row, col, width, height);

    // Return the result and the updated grid
    return { result, grid };
}

/**
 * Generates an array of new grid rows.
 *
 * @param {number} height - The number of rows to generate.
 * @param {object} emptyGridRow - The empty grid row object to clone.
 * @returns {Array} - The array of new grid rows.
 */
function getNewGridRows(height, emptyGridRow) {
    const result = [];

    for (let i = 0; i < height; i++) {
        result.push(structuredClone(emptyGridRow));
    }

    return result;
}

/**
 * Checks if a spot in the grid is empty.
 * @param {Array<Array<number|null>>} grid - The grid representing the game board.
 * @param {number} row - The starting row index of the spot.
 * @param {number} col - The starting column index of the spot.
 * @param {number} width - The width of the spot.
 * @param {number} height - The height of the spot.
 * @param {number} columns - The total number of columns in the grid.
 * @returns {boolean} - True if the spot is empty, false otherwise.
 */
function isEmptySpot(grid, row, col, width, height, columns) {
    for (let i = row; i < row + height; i++) {
        for (let j = col; j < col + width; j++) {
            // Check if the grid position is out of bounds or already occupied
            if (!grid[i] || j >= columns || (grid[i][j] && grid[i][j] !== 0)) {
                return false;
            }
        }
    }

    // Return true if the spot is empty
    return true;
}

/**
 * Places an item on the grid by marking the grid positions occupied by the item.
 * @param {Object} options - The options for placing the item.
 * @param {Array<Array<number>>} options.grid - The grid to place the item on.
 * @param {number} options.row - The starting row index of the item.
 * @param {number} options.col - The starting column index of the item.
 * @param {number} options.width - The width of the item.
 * @param {number} options.height - The height of the item.
 * @returns {Array<Array<number>>} The updated grid with the item placed.
 */
function placeItem({ grid: gridInput, row, col, width, height }) {
    // Clone the grid to prevent mutating the original
    const grid = structuredClone(gridInput);

    // Mark the grid positions occupied by the card
    for (let i = row; i < row + height; i++) {
        for (let j = col; j < col + width; j++) {
            grid[i][j] = 1;
        }
    }

    // Return the updated grid
    return grid;
}

/**
 * Returns the result of a card with formatted row and column positions.
 * @param {Object} card - The card object.
 * @param {number} row - The row position.
 * @param {number} col - The column position.
 * @param {number} width - The width of the card.
 * @param {number} height - The height of the card.
 * @returns {Object} - The card object with formatted row and column positions.
 */
function getCardResult(card, row, col, width, height) {
    return {
        ...card,
        // Format the row and column positions for the result
        row: `${row + 1}/${row + 1 + height}`,
        column: `${col + 1}/${col + 1 + width}`,
    };
}

/**
 * Formats the given value as a leaderboard time in the format HH:MM:SS.
 * @param {number} value - The value to be formatted as a leaderboard time.
 * @returns {string} The formatted leaderboard time.
 */
function formatLeaderboardTime(value) {
    const time = Math.round(value);
    const SECONDS_PER_MINUTE = 60;
    const MINUTES_PER_HOUR = 60;
    const DOUBLE_DIGIT_THRESHOLD = 10;

    const seconds = time % SECONDS_PER_MINUTE;
    const minutes = Math.floor(time / SECONDS_PER_MINUTE);
    const hours = Math.floor(minutes / MINUTES_PER_HOUR);

    const secondsString = seconds < DOUBLE_DIGIT_THRESHOLD ? `0${seconds}` : seconds;
    const minutesString =
        minutes % MINUTES_PER_HOUR < DOUBLE_DIGIT_THRESHOLD
            ? `0${minutes % MINUTES_PER_HOUR}`
            : minutes % MINUTES_PER_HOUR;
    const hoursString = hours < DOUBLE_DIGIT_THRESHOLD ? `0${hours}` : hours;

    return `${hoursString}:${minutesString}:${secondsString}`;
}

/**
 * Formats the leaderboard date value.
 *
 * @param {string} value - The date value to be formatted.
 * @returns {string} The formatted date string.
 */
function formatLeaderboardDate(value) {
    if (!value) {
        return '';
    }
    const date = format(new Date(value), 'yyyy-MM-dd HH:mm');
    // if date is today, return 'Today, HH:mm'
    if (date.split(' ')[0] === format(new Date(), 'yyyy-MM-dd')) {
        return `${i18n.t('leaderboard.today')}, ${date.split(' ')[1]}`;
    }
    // if date is yesterday, return 'Yesterday, HH:mm'
    if (date.split(' ')[0] === format(new Date(Date.now() - 86400000), 'yyyy-MM-dd')) {
        return `${i18n.t('leaderboard.yesterday')}, ${date.split(' ')[1]}`;
    }
    // if date is older than yesterday but less then a week, return 'Weekday, HH:mm'
    if (new Date(value) > new Date(Date.now() - 86400000 * 7)) {
        return `${i18n.t(`global.weekDays.${format(new Date(value), 'EEEE').toLowerCase()}`)}, ${date.split(' ')[1]}`;
    }

    return date;
}
