<template>
    <v-tooltip right>
        <template #activator="{ on, attrs }">
            <v-btn
                class="mx-2 my-1"
                fab
                elevation="0"
                :color="buttonColor"
                v-bind="attrs"
                height="50"
                width="50"
                @mouseleave="(e) => mouseLeave(e)"
                @mouseover="hover = true"
                v-on="on"
                @click="setActive()"
            >
                <v-icon v-if="item.active" :color="'white'">{{ item.iconSelected }}</v-icon>
                <v-icon v-else color="gray2">{{ item.icon }}</v-icon>
            </v-btn>
        </template>
        <span>{{ item.name }}</span>
    </v-tooltip>
</template>

<script>
    export default {
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                hover: false,
            };
        },
        computed: {
            buttonColor() {
                return this.item.active ? 'primary' : 'transparent';
            },
        },
        methods: {
            setActive() {
                this.$store.dispatch('BuilderMenu/setActive', this.item.id);
            },
            mouseLeave(e) {
                this.hover = false;
                e.target.blur();
            },
        },
    };
</script>
