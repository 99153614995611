module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "comment": {
          "survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey"])},
          "sendSurvey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
        }
      },
      "sv": {
        "comment": {
          "survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkät"])},
          "sendSurvey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"comment":{"survey":"Survey","sendSurvey":"Send"}},"sv":{"comment":{"survey":"Enkät","sendSurvey":"Skicka"}}}')
  delete Component.options._Ctor
  
}
