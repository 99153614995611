<template>
    <section>
        <v-btn
            v-if="menuOptions.length > 0"
            ref="menuButton"
            fab
            color="white"
            elevation="0"
            height="30"
            width="30"
            @mouseover="(e) => mouseOver(true, e)"
            @mouseout="(e) => mouseOver(false, e)"
            @click="toggleMenu"
        >
            <v-icon size="18" :color="menuIconColor">mdi-dots-horizontal</v-icon>
        </v-btn>

        <v-menu
            v-if="menu"
            v-model="menu"
            :close-on-content-click="false"
            :activator="$refs.menuButton"
            offset-y
            elevation="0"
        >
            <v-list>
                <div
                    v-for="(option, index) of menuOptions"
                    :key="index"
                    class="item"
                    @click="clickButton(option.value)"
                >
                    <v-icon size="16" class="mr-2">{{ option.icon }}</v-icon>
                    <v-list-item-title class="medium-heading">{{ option.name }}</v-list-item-title>
                </div>
            </v-list>
        </v-menu>
    </section>
</template>
<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'ActionMenu',

        props: {
            item: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                menu: false,
                hover: false,
            };
        },

        methods: {
            ...mapActions('Social', ['hideComment', 'showComment']),

            toggleMenu() {
                this.menu = !this.menu;
            },

            async clickButton(option) {
                if (option === 'Show' && this.item.meta.IsHidden && this.item.meta.CanHide) {
                    await this.showComment({ itemId: this.item.meta.ItemID, data: { isHidden: false } });
                } else if (option === 'Hide' && !this.item.meta.IsHidden && this.item.meta.CanHide) {
                    this.loading = true;
                    this.hideComment({ itemId: this.item.meta.ItemID, data: { isHidden: true } });
                }

                this.menu = false;
            },

            mouseOver(value, event) {
                this.hover = value;
            },
        },

        computed: {
            menuIconColor() {
                return this.hover ? 'var(--v-gray4-base)' : 'var(--v-gray1-base)';
            },

            menuOptions() {
                if (!this.item.meta.CanHide) {
                    return [];
                }

                if (this.item.meta.IsHidden) {
                    return [
                        {
                            icon: 'mdi-eye',
                            name: this.$t('actionMenu.show'),
                            value: 'Show',
                        },
                    ];
                }
                return [
                    {
                        icon: 'mdi-eye-off',
                        name: this.$t('actionMenu.hide'),
                        value: 'Hide',
                    },
                ];
            },
        },
    };
</script>
<style lang="scss" scoped>
    .medium-heading {
        font-size: 12px;
        font-weight: 400;
        color: var(--v-gray4-base);
    }

    .item {
        display: flex;
        align-items: center;
        padding: 0 12px;
        padding-right: 18px;
        cursor: pointer;
        height: 42px;
        transition: background-color 0.1s ease-in-out;
    }

    .item:hover {
        background-color: var(--v-gray3-base);
    }
</style>

<i18n lang="json">
{
    "en": {
        "actionMenu": {
            "hide": "Hide",
            "show": "Show"
        }
    },
    "sv": {
        "actionMenu": {
            "hide": "Dölj",
            "show": "Visa"
        }
    }
}
</i18n>
