<template>
    <section>
        <ProgressLoader :loading="loading" />

        <section class="app-container">
            <!-- List of cases-->
            <ListView v-if="isCaseListVisible === true" />
            <NoContentListView v-if="isCaseListVisible === false" />
            <!-- Case content-->
            <!-- THIS NEEDS TO BE A NATIVE HTML ELEMENT TO ALLOW SCROLL TO FUNCTIONALITY -->
            <div ref="caseContent">
                <Content />
            </div>
        </section>
    </section>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import ListView from '@/components/Cases/ListView.vue';
    import Content from '@/components/Cases/Content.vue';
    import ProgressLoader from '@/components/Cases/ProgressLoader.vue';
    import NoContentListView from '@/components/Cases/NoContentListView.vue';

    const SCROLL_CUTOFF = 20;

    export default {
        components: {
            Content,
            ListView,
            ProgressLoader,
            NoContentListView,
        },

        data() {
            return {
                loading: false,
                connected: false,
                firstSocketConnect: true,
            };
        },

        sockets: {
            connect_error() {
                this.connected = false;
            },
            disconnect() {
                this.connected = false; // this disconnect event is very important, without it connected isn't set to false properly causing the app not to refetch cases - Tim Aribssib 2023-03-07
            },
            connect() {
                this.connected = true;
            },
        },

        computed: {
            ...mapState({
                selectedCase: (state) => state.Cases.selectedCase,
                caseFiltersSelectedItems: (state) => state.Cases.caseFiltersState,
                showFilters: (state) => state.Cases.showFilters,
                isCaseListVisible: (state) => state.Filter.settingsStates.isCaseListVisible?.value,
            }),
            ...mapGetters({
                goToContent: 'Cases/getGoToContent',
            }),
            selectedItems() {
                return this.caseFiltersSelectedItems.items;
            },
            showFilterButtons() {
                return this.showFilters === false;
            },
        },
        watch: {
            connected: {
                async handler() {
                    if (this.firstSocketConnect) {
                        this.firstSocketConnect = false;
                        return;
                    }

                    await this.getCaseFilters(this.isCaseListVisible);
                },
                immediate: false,
            },
            goToContent: {
                immediate: false,
                handler(newVal) {
                    if (newVal) {
                        // this timeout is needed to make sure the content is rendered before we scroll to it (otherwise it wont work, not even with $nextTick)
                        const RENDER_TIMEOUT = 100;
                        setTimeout(() => {
                            this.scrollToBottom();
                        }, RENDER_TIMEOUT);
                        this.$store.dispatch('Cases/setGoToContent', false);
                    }
                },
            },
            isCaseListVisible: {
                async handler(value) {
                    if (value) {
                        await this.getCaseFilters(value);
                    }
                },
            },
            async $route() {
                await this.$store.dispatch('Cases/handleCaseRouting', this.$route.params.caseId);

                this.$nextTick(() => {
                    if (this.storeSelectedCase?.caseStart?.InternalType !== 'callback') {
                        if (this.selectedItems >= SCROLL_CUTOFF) {
                            this.scrollToBottom();
                        } else if (this.goToContent) {
                            this.scrollToBottom();
                            this.$store.dispatch('Cases/setGoToContent', false);
                        }
                    }
                });
            },
        },
        async created() {
            await this.init();
        },
        beforeDestroy() {
            this.$store.commit('Cases/SET_BUSY_CASE_LIST', []);
            this.$store.dispatch('Cases/unSubscribeToListenToCaseList', this.caseFiltersSelectedItems);
            this.$store.dispatch('Cases/unSubscribeToActiveCases');
        },

        methods: {
            ...mapActions('Cases', {
                getCaseFilters: 'getCaseFilters',
            }),

            scrollToBottom() {
                const scrollContainer = document.querySelector('#scroll-container');
                scrollContainer.scrollTop = scrollContainer.scrollHeight;
            },

            async init() {
                await this.getCaseFilters(this.isCaseListVisible);

                if (this.$route.params.caseId === undefined) return;

                await this.$store.dispatch('Cases/handleCaseRouting', this.$route.params.caseId);

                if (this.selectedItems >= SCROLL_CUTOFF) {
                    this.scrollToBottom();
                }

                if (!this.goToContent) return;

                this.$store.dispatch('Cases/setGoToContent', false);
            },
        },
    };
</script>

<style scoped>
    .app-container {
        @media (min-width: 3200px) {
        }
    }
</style>
