<template>
    <SimpleTooltip :enabled="Boolean(templateTooltipText)" :tooltipText="templateTooltipText" bottom white>
        <div v-if="brandId" :style="containerStyle" @click="click">
            <img :src="logo" alt="Brand Logo" :style="circularStyle" />
        </div>
        <div v-else :style="noLogoStyle" @click="click">No logo</div>
    </SimpleTooltip>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'BrandLogoRound',
        components: {
            SimpleTooltip: () => import('@/components/Global/SimpleTooltip.vue'),
        },

        props: {
            size: {
                type: String,
                default: '50px', // Default size if no size prop is passed
            },
            brandId: {
                type: Number,
                default: null,
            },
            color: {
                type: String,
                default: 'var(--v-gray3-base)',
            },
            borderWidth: {
                type: String,
                default: '2px',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            tooltip: {
                type: Boolean,
                default: false,
            },
            tooltipText: {
                type: String,
                default: '',
            },
            backgroundColor: {
                type: String,
                default: 'transparent',
            },
            resolution: {
                type: String,
                default: '128',
                validator(value) {
                    const resolutions = ['128', '256', '512'];
                    return resolutions.includes(value);
                },
            },
        },
        data() {
            return {
                logo: null,
                brand: null,
            };
        },
        computed: {
            circularStyle() {
                return {
                    width: this.size,
                    height: this.size,
                    borderRadius: '50%',
                    objectFit: 'cover',
                    border: `${this.borderWidth} solid ${this.color}`, // 2px border around the image
                    backgroundColor: this.backgroundColor,
                    transition: 'all 0.1s', // Smooth transition
                    opacity: this.logo ? 1 : 0, // Hide the image if it's not loaded yet
                    padding: '10%',
                };
            },
            containerStyle() {
                return {
                    opacity: this.disabled ? 0.4 : 1, // Lower opacity when disabled
                    transition: 'all 0.1s', // Smooth transition
                    display: 'flex',
                    alignItems: 'center',
                    width: this.size,
                    height: this.size,
                };
            },
            noLogoStyle() {
                return {
                    width: this.size,
                    height: this.size,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    backgroundColor: 'var(--v-gray3-base)',
                    color: 'var(--v-gray7-base)',
                    fontSize: '60%',
                    border: `${this.borderWidth} solid ${this.color}`, // 2px border around the image
                };
            },
            templateTooltipText() {
                return this.tooltipText || this.brand?.name || '';
            },
        },
        created() {
            this.init();
        },
        methods: {
            ...mapActions({
                getBrandLogo: 'Brands/getBrandLogo',
                getBrand: 'Brands/getBrand',
            }),
            async init() {
                if (!this.brandId) return;
                this.logo = await this.getBrandLogo({ brandId: this.brandId, resolution: this.resolution });
                if (this.tooltip && !this.tooltipText) {
                    this.brand = await this.getBrand(this.brandId);
                }
            },
            click(event) {
                this.$emit('click', event);
            },
        },
    };
</script>
