<template>
    <section v-if="selectedCase" class="content-container" :style="contentContainerStyle" fluid>
        <section :style="clientOuterContainerStyle">
            <ClientCard :caseId="selectedCaseId" />
        </section>

        <section class="content-outer-container" :style="caseContentGridRows">
            <section v-if="userSettings && userSettings.cases.caseTabs.active" class="case-comments-tabs-container">
                <CaseCommentsTabs v-show="userSettings && userSettings.cases.caseTabs.active" />
            </section>
            <CaseComments :caseId="selectedCaseId" />
        </section>
    </section>
    <section v-else-if="caseState === casesStatesEnums.NOT_ALLOWED" class="content-container">
        <article class="not-allowed">
            <v-icon>mdi-alert-circle</v-icon>
            <span class="case-card-error-message">{{ $t('content.notAllowed') }}</span>
        </article>
    </section>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    import CaseComments from '@/components/Cases/CaseComments/CaseComments.vue';
    import ClientCard from '@/components/Cases/ClientCard.vue';
    import CaseCommentsTabs from '@/components/Cases/CaseComments/CaseCommentsTabs.vue';

    import { states } from '@/enums/client.enums';
    import { states as casesStatesEnums } from '@/enums/cases.enums';

    export default {
        components: {
            CaseComments,
            ClientCard,
            CaseCommentsTabs,
        },
        data() {
            return {
                firstMount: false,
                selectedCaseBusyBy: '',
                states,
                casesStatesEnums,
            };
        },
        computed: {
            ...mapState({
                readContent: (state) => state.Layout.readContent,
                readStateContent: (state) => state.Layout.readStateContent,
                userSettings: (state) => state.System.userSettings,
                selectedCase: (state) => state.Cases.selectedCase,
                clientCardState: (state) => state.Client.clientCardState,
                busyCaseList: (state) => state.Cases.busyCaseList,
                selectedCaseId: (state) => state.Cases.selectedCaseId,
                caseState: (state) => state.Cases.caseState,
            }),

            busyCaseList() {
                return this.$store.state.Cases.busyCaseList;
            },

            loggedInUser() {
                return this.$store.state.Auth.userObject;
            },

            caseInQueue() {
                // find case in queue and return it
                const caseFound = this.$store.state.Cases.casesInQueue.find((c) => c === this.selectedCaseId);
                return !!caseFound;
            },

            caseContentGridRows() {
                if (this.userSettings && this.userSettings.cases.caseTabs.active) {
                    return 'grid-template-rows: 60px 1fr;';
                }
                return 'grid-template-rows: 1fr;';
            },
            clientOuterContainerStyle() {
                if (this.userSettings && this.userSettings.cases.caseTabs.active) {
                    return 'margin-top: 60px;';
                }
                return '';
            },
            selectedCaseReadOnly: {
                get() {
                    return this.$store.state.Cases.selectedCaseReadOnly;
                },
                set(value) {
                    this.$store.dispatch('Layout/setReadSelectedCase', value);
                },
            },

            selectedCaseReadOnlyBusyBy: {
                get() {
                    return this.$store.state.Cases.selectedCaseReadOnlyBusyBy;
                },
                set(value) {
                    this.$store.commit('Cases/setSelectedCaseReadOnlyBusyBy', value);
                },
            },

            contentContainerStyle() {
                if (this.clientCardState === states.CLOSED) {
                    return {
                        'grid-template-columns': '48px 1fr',
                        gap: '0px',
                    };
                }
                return {
                    'grid-template-columns': 'minmax(400px, 15vw) 1fr',
                    gap: '16px',
                };
            },
        },

        watch: {
            caseInQueue(newVal) {
                if (newVal) {
                    this.$store.dispatch('Layout/setReadContent', 'READ_ONLY');
                } else {
                    this.$store.dispatch('Layout/setReadContent', 'READ_WRITE');
                }
            },
            busyCaseList(newVal, oldVal) {
                this.handleBusyCaseListUpdate(newVal, oldVal);
            },
        },
        created() {
            this.$store.dispatch('Cases/setSelectedCaseId', null);
        },

        methods: {
            ...mapActions({
                toggleClientCard: 'Client/toggleClientCard',
                giveWriteAccessToCaseOwner: 'Cases/giveWriteAccessToCaseOwner',
            }),
            async handleBusyCaseListUpdate(newVal, oldVal) {
                // If I enter a case in which an agent is working and I am the assigned agent,
                // I get asked if I want to take over the write access from the other agent
                const currentCase = newVal.find((c) => c.userId === this.loggedInUser.userId);
                if (!currentCase || !currentCase.isAssignedToCase) return;

                const isSameUser = (c) => c.userId === this.loggedInUser.userId;
                const isSameCase = (c) => c.id === currentCase.id;

                const isCaseChanged = !oldVal.some((c) => isSameUser(c) && isSameCase(c));
                const isAnotherUserInCase = newVal.some((c) => !isSameUser(c) && isSameCase(c));

                if (!isCaseChanged || !isAnotherUserInCase) return;

                const activeUser = newVal.find((c) => !isSameUser(c) && isSameCase(c));
                const { confirmed } = await this.$global.dialogs.showConfirmationDialog({
                    title: this.$t('content.confirmationDialogTitle'),
                    message: this.$t('content.confirmationDialogMessage', [activeUser.userName]),
                    confirmText: this.$t('global.btn.confirm'),
                    declineText: this.$t('global.btn.cancel'),
                });

                if (confirmed) this.giveWriteAccessToCaseOwner(currentCase.id);
            },
        },
    };
</script>
<style lang="scss" scoped>
    .content-container {
        display: grid;
        grid-template-rows: minmax(1000px, calc(100vh - 96px));

        @media screen and (max-width: 1000px) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 80vh 80vh;
        }
    }

    .content-outer-container {
        display: grid;
        height: 100%;
    }

    .case-comments-tabs-container {
        display: flex;
        align-items: center;
        flex: 1;
    }

    .not-allowed {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        gap: 8px;
    }
</style>
<i18n lang="json">
{
    "en": {
        "content": {
            "confirmationDialogTitle": "Take over the case?",
            "confirmationDialogMessage": "You are assigned to this case. Do you want to take away the write access from {0}, who is currently working in the case? {0} will be demoted to read-only.",
            "notAllowed": "You are not allowed to view this case."
        }
    },
    "sv": {
        "content": {
            "confirmationDialogTitle": "Ta över ärendet?",
            "confirmationDialogMessage": "Du är tilldelad detta ärende. Vill du ta över skrivbehörigheten från {0}, som för närvarande arbetar i fallet? {0} kommer att bli degraderad till läsbehörighet.",
            "notAllowed": "Du har inte behörighet att visa detta ärende."
        }
    }
}
</i18n>
