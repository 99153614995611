<template>
    <section>
        <OrderList v-if="orders.length" id="order-list-component" :orders="orders" />
        <LoadingState v-else-if="loading" />
        <NoData v-else @fetchOrders="fetchOrders" />
    </section>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        components: {
            OrderList: () => import('./OrderList.vue'),
            LoadingState: () => import('./LoadingState.vue'),
            NoData: () => import('./NoData.vue'),
        },
        data() {
            return {
                orders: [],
                loading: true,
            };
        },
        computed: {
            ...mapState({
                selectedCaseId: (state) => state.Cases.selectedCase.case.caseId,
            }),
        },
        watch: {
            selectedCaseId() {
                this.init();
            },
        },
        created() {
            this.init();
        },
        methods: {
            ...mapActions('Integrations', ['getOrders']),
            async init() {
                this.setLoading(true);
                await this.fetchOrders();
                this.setLoading(false);
            },
            async fetchOrders() {
                this.orders = await this.getOrders({
                    type: 'madhat',
                    caseId: this.$store.state.Cases.selectedCaseId,
                });
            },
            setLoading(state) {
                this.loading = state;
            },
        },
    };
</script>

<style lang="scss" scoped>
    section {
        width: 100%;
        height: 100%;
        display: flex;
        background: white;
        & > * {
            flex: 1;
            &:not(:first-child) {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
</style>
