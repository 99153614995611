<template>
    <div>
        <IntegrationButton :integrationName="integration.name" @click="toggle" />
        <v-dialog v-model="open" max-width="1000px">
            <PinDelivery @close="open = false" />
        </v-dialog>
    </div>
</template>
<script>
    import IntegrationButton from './IntegrationButton.vue';

    export default {
        components: {
            IntegrationButton,
            PinDelivery: () => import('./PinDelivery/PinDelivery.vue'),
        },
        props: {
            integration: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                open: false,
            };
        },
        methods: {
            toggle() {
                this.open = true;
            },
        },
    };
</script>
