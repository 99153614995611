<template>
    <component :is="iconMode ? components.ICONS : components.CHIPS" :item="item" :identifier="item.caseId" />
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        components: {
            CategoryChips: () => import('@/components/Cases/List/Category/CategoryChips/CategoryChips.vue'),
            CategoryIcons: () => import('@/components/Cases/List/Category/CategoryIcons/CategoryIcons.vue'),
        },
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                components: Object.freeze({
                    ICONS: 'CategoryIcons',
                    CHIPS: 'CategoryChips',
                }),
            };
        },
        computed: {
            ...mapState({
                iconMode: (state) => state.System.userSettings.appearance.misc.categoryIconsInCaseList.active,
                layout: (state) => state.Cases.listLayout,
            }),
        },
    };
</script>
