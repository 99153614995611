<template>
    <BrandLogoRound v-if="item.brandId" :brandId="item.brandId" :size="size" tooltip />
</template>

<script>
    import BrandLogoRound from '@/components/Brands/BrandLogoRound.vue';

    export default {
        components: {
            BrandLogoRound,
        },
        props: {
            item: {
                type: Object,
                default: () => ({}),
            },
        },
        computed: {
            isBig() {
                if (this.small) {
                    return false;
                }
                return this.listLayout.value === 'big';
            },

            listLayout() {
                return this.$store.state.Cases.listLayout;
            },
            size() {
                return this.isBig ? '40px' : '32px';
            },
        },
    };
</script>

<style scoped></style>
