module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "containsHtml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hover to show more information. This contains HTML"])},
        "noOutcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No outcome"])}
      },
      "sv": {
        "containsHtml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Håll musen över för att visa mer information. Denna innehåller HTML"])},
        "noOutcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget utfall"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"containsHtml":"Hover to show more information. This contains HTML","noOutcome":"No outcome"},"sv":{"containsHtml":"Håll musen över för att visa mer information. Denna innehåller HTML","noOutcome":"Inget utfall"}}')
  delete Component.options._Ctor
  
}
