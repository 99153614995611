import queues from '@/api/queues/queues';
import router from '@/router';
import i18n from '../../i18n';

// State object
const state = {
    userQueues: [],
    allQueues: [],
    allQueuesExtended: [],
    getStatistics: {},
    divertSettingsSelectedUser: {},
};

// Getter functions
const getters = {
    getQueues(state) {
        return state.getQueues;
    },
    getStatistics(state) {
        return state.getStatistics;
    },
};
// Actions
const actions = {
    getAllQueues({ commit }) {
        return new Promise((resolve, reject) => {
            queues
                .getAllQueues()
                .then((res) => {
                    commit('SET_QUEUES_LIST', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getStatistics({ commit }, payload) {
        return new Promise((resolve, reject) => {
            queues
                .getStatistics(payload.id)
                .then((res) => {
                    commit('SET_MEMBERS_LIST', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    loginLogoutToQueue({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            queues
                .loginLogoutToQueue(payload)
                .then((res) => {
                    // if we are in queueloginadmin, we wanna update allQueues so the big list updates
                    if (router.currentRoute.name === 'QueueLoginAdmin') {
                        dispatch('getAllQueues');
                    }
                    // get whether its a login or logout and apply message
                    const message = getMessage(JSON.parse(res.config.data));
                    this._vm.$toasted.show(message + i18n.t('queuesStore.teleQueue'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });

                    resolve(res);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queuesStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    getUserDivertSettings(_, userId) {
        return new Promise((resolve, reject) => {
            queues
                .getUserDivertSettings(userId)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    saveUserDivertSettings(_, payload) {
        return new Promise((resolve, reject) => {
            queues
                .saveUserDivertSettings(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getQueueCallbackSlotTime(_, queueId) {
        return new Promise((resolve, reject) => {
            queues
                .getQueueCallbackSlotTime(queueId)
                .then((response) => {
                    resolve(response.data[0].StandardSlotTime);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getQueueChannelSurveys(_, payload) {
        return new Promise((resolve, reject) => {
            queues
                .getQueueChannelSurveys(payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    saveQueueChannelSurveys(_, payload) {
        return new Promise((resolve, reject) => {
            queues
                .saveQueueChannelSurveys(payload)
                .then((response) => {
                    this._vm.$toasted.show(i18n.t('global.changedSaved'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async answerCall(_, payload) {
        try {
            return await queues.answerCall(payload);
        } catch (error) {
            return error;
        }
    },
    /**
     * @param {number[]} whichQueues
     */
    async getNextCase({ dispatch }) {
        try {
            const res = await queues.getNextCase();
            if (res.data.caseId) {
                router.push({
                    name: 'Case',
                    params: {
                        caseId: res.data.caseId,
                    },
                });
                await dispatch('Cases/setGoToContent', true, { root: true });
                return;
            }
            this._vm.$toasted.show(i18n.t('queuesStore.noMoreCases'), {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
        } catch (error) {
            console.error('Error getting next email', error);
            this._vm.$toasted.show(`${i18n.t('global.requestResponses.somethingWrong')}: ${error}`, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
        }
    },
};
// Mutations
const mutations = {
    SET_QUEUES_EXTENDED_LIST(state, data) {
        state.allQueuesExtended = data;
    },
    SET_QUEUES_LIST(state, data) {
        state.allQueues = data;
    },
    SET_USER_QUEUES_LIST(state, data) {
        state.userQueues = data;
    },
    SET_MEMBERS_LIST(state, data) {
        state.getStatistics = data;
    },
    SET_DIVERT_SETTINGS_SELECTED_USER(state, data) {
        state.divertSettingsSelectedUser = data;
    },
};

function getMessage(data) {
    if (data.groups.length === 1) {
        if (data.groups[0].isactive) {
            // return 'Inloggad'
            return i18n.t('queuesStore.loggedIn');
        }
        // return 'Utloggad'
        return i18n.t('queuesStore.loggedOut');
    }
    return '';
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
