import { SessionState, Inviter, UserAgent, Web } from 'sip.js';
import pbx from '../../api/pbx/pbx';
import { callStates, callDirections } from '../../constants/enums/sip.enums';
import i18n from '../../i18n';
import { findMediaObject, parseNumber, errorToMessage } from '../../helpers/pbx.helper';
import { internalAndExternalPhoneNumberRegex } from '../../utils/pbx.utils';

const state = {
    activeSessions: [],
    callsInfo: {},
    inputDevice: null,
    outputDevice: null,
    numberToShow: '',
    ringtone: null,
    selectedSessionId: null,
    maxConcurrentCalls: 2,
    isMinimized: true,
};

const actions = {
    async callNumber({ state, commit, dispatch, rootState }, numberInput) {
        for (const session of state.activeSessions) {
            if (session.state === SessionState.Initial) {
                return 'alreadyReceiving';
            }
            if (session.state === SessionState.Establishing) {
                return 'alreadyCalling';
            }
        }
        const destinationNumber = parseNumber(numberInput);
        if (!hasValidInputs(destinationNumber)) {
            return 'invalidNumber';
        }
        const { sipData, userAgent } = rootState['Sip.auth'];
        if (!sipData || !sipData.username || !userAgent) {
            return 'error';
        }
        for (const number of sipData.userNumbers) {
            if (number.internalNumber === destinationNumber || number.publicNumber === destinationNumber) {
                // This returns a string wich is used in i18n to display a toasted
                return 'callYourself';
            }
        }

        const destination = 'sip:' + destinationNumber + '@default';
        const target = UserAgent.makeURI(destination);

        const inviterInviteOptions = {
            requestDelegate: {
                onAccept(response) {
                    // Runs when the call is outgoing and the other party accepts the call
                    // Needs to be sliced because the id has a lot of extra info
                    const id = response.session.id.slice(0, 30);
                    const data = structuredClone(state.callsInfo[id]);

                    data.callState = callStates.ONGOING;
                    data.startTime = Date.now();
                    commit('UPDATE_CALLS_INFO', { data, id });
                },
                onReject(response) {
                    // Runs when the call is outgoing you cancel the call or the other party rejects the call
                    dispatch('handleRejectedCall', response);
                },
            },
            // extraHeaders: ['sip_h_X-TestHead: THis is my message '],
        };
        const id = state.inputDevice?.deviceId || 'default';
        const inviter = new Inviter(userAgent, target, {
            sessionDescriptionHandlerOptions: {
                constraints: { audio: { deviceId: id }, video: false },
                iceGatheringTimeout: 100,
            },
            extraHeaders: ['X-NumberToShow:' + state.numberToShow],
        });
        try {
            await inviter.invite(inviterInviteOptions);
            commit('ADD_ACTIVE_SESSION', inviter);
            const data = {
                isMuted: false,
                isHold: false,
                direction: callDirections.OUTGOING,
                startTime: Date.now(),
                endTime: undefined,
                duration: undefined,
                displayName: destinationNumber,
                callerNumber: destinationNumber,
                callState: callStates.AWAITING,
                hangupReason: undefined,
            };
            commit('UPDATE_CALLS_INFO', { id: inviter.id, data });
        } catch (error) {
            console.error('error when trying to invite', error);
            return 'error when trying to invite';
        }
        return 'OK';
    },
    removeInactive({ commit }, id) {
        const sessions = [...state.activeSessions];
        const index = sessions.findIndex((session) => session.id === id);
        if (index === -1) {
            return;
        }

        sessions.splice(index, 1);
        commit('SET_ACTIVE_SESSIONS', sessions);
        commit('DELETE_CALLS_INFO', { id });
    },
    doTransferById({ dispatch }, { id, data: userData, warm }) {
        if (!userData || !userData.InternalNumber) {
            throw 'No internal number';
        }
        if (warm) {
            return dispatch('warmTransfer', { id, number: userData.InternalNumber });
        }
        return dispatch('transfer', { id, number: userData.InternalNumber });
    },
    transfer(_, { number, id }) {
        const session = state.activeSessions.find((session) => session.id === id);
        if (!number || !session) {
            throw 'No session';
        }
        if (!hasValidInputs(number)) {
            throw 'Not only numbers';
        }
        if (session.state !== SessionState.Established) {
            throw 'Invalid session state';
        }
        if (number[0] === '+') {
            number = number.slice(1);
        }
        const target = UserAgent.makeURI('sip:' + number + '@default');
        session.refer(target);
        return 'OK';
    },
    warmTransfer({ rootState, dispatch }, { id, number }) {
        const session = state.activeSessions.find((session) => session.id === id);
        return new Promise((resolve, reject) => {
            if (!session) {
                console.error('No session');
                return reject(i18n.t('transfer.error'));
            }
            if (!hasValidInputs(number)) {
                console.error('Not only numbers');
                return reject(i18n.t('transfer.error'));
            }
            if (session.state !== SessionState.Established) {
                console.error('Invalid session state');
                return reject(i18n.t('transfer.error'));
            }
            if (number[0] === '+') {
                number = number.slice(1);
            }
            const event = new CustomEvent('C1:SIP:Toasted', {
                detail: {
                    type: 'info',
                    message: i18n.t('transfer.trying'),
                    duration: 5000,
                    icon: 'mdi-information-variant',
                },
            });
            document.dispatchEvent(event);
            const destination = 'sip:' + number + '@default';
            const target = UserAgent.makeURI(destination);
            const inviterInviteOptions = {
                requestDelegate: {
                    onAccept() {
                        session.refer(inviter);
                        dispatch('denyOrHangUp', { id });
                        return resolve('OK');
                    },
                    onReject() {
                        return reject(i18n.t('transfer.rejected'));
                    },
                },
            };
            const { userAgent } = rootState['Sip.auth'];
            const inviter = new Inviter(userAgent, target, {
                sessionDescriptionHandlerOptions: {
                    constraints: { audio: { deviceId: id }, video: false },
                    iceGatheringTimeout: 100,
                },
                extraHeaders: ['sip_h_X-TestHead: TRANSFER-TEST'],
            });
            return inviter.invite(inviterInviteOptions);
        });
    },
    endAllCalls({ state, commit }) {
        const options = {
            requestOptions: {
                extraHeaders: ['X-Redial: true'],
            },
        };
        for (const session of state.activeSessions) {
            commit('END_CALL', { id: session.id, options });
        }
    },
    async loadSettings({ commit, dispatch }) {
        const res = await pbx.getSettings();
        if (res.status === 200 && res.data) {
            const { inputDevice, outputDevice, numberToShow, ringtone, maxConcurrentCalls } = res.data;
            dispatch('setInputDevice', inputDevice);
            dispatch('setOutputDevice', outputDevice);
            commit('SET_NUMBER_TO_SHOW', numberToShow);
            commit('SET_RINGTONE', ringtone);
            commit('SET_MAX_CONCURRENT_CALLS', maxConcurrentCalls);
            return;
        }
        dispatch('setInputDevice', 'Default');
        dispatch('setOutputDevice', 'Default');
        commit('SET_RINGTONE', 'Skogsfors');
        commit('SET_MAX_CONCURRENT_CALLS', 2);
    },
    // #region Api calls
    async setSettings(_, payload) {
        try {
            const { status } = await pbx.setSettings(payload);
            return status;
        } catch (error) {
            return error;
        }
    },
    async getTransferUserData(_, payload) {
        try {
            const { data, status } = await pbx.getTransferUserData(payload);
            return { data, status };
        } catch ({ response }) {
            return { data: response.data, status: response.status };
        }
    },
    async getUserNumber(_, payload) {
        try {
            const { data, status } = await pbx.findUserNumber(payload);
            return { data, status };
        } catch ({ response }) {
            return { data: response.data, status: response.status };
        }
    },
    async getCallHistory(_, payload) {
        try {
            const { data, status } = await pbx.getCallHistory(payload);
            return { data, status };
        } catch ({ response }) {
            return { data: response.data, status: response.status };
        }
    },
    async addGateway(_, payload) {
        try {
            const { data } = await pbx.getGateway(payload);
            return data;
        } catch (error) {
            return error;
        }
    },
    async addNumberRange(_, payload) {
        try {
            const { data } = await pbx.addNumberRange(payload);
            return data;
        } catch (error) {
            return error;
        }
    },
    async setInternalNumberRange(_, payload) {
        try {
            const { data } = await pbx.setInternalNumberRange(payload);
            return data;
        } catch (error) {
            return error;
        }
    },
    async updateAvailableNumbers() {
        try {
            const { data } = await pbx.updateAvailableNumbers();
            return data;
        } catch (error) {
            return error;
        }
    },
    // #endregion

    handleRejectedCall({ state, commit }, response) {
        const message = errorToMessage(response.message.statusCode);
        const event = new CustomEvent('C1:SIP:Toasted', {
            detail: {
                type: 'warning',
                message,
                duration: 15000,
                icon: 'mdi-alert',
            },
        });
        document.dispatchEvent(event);

        const id = response.message.callId + response.message.fromTag;
        const data = { ...state.callsInfo[id] };
        // check if the call exists or if the call has been deleted
        if (!data) {
            return;
        }
        data.callState = callStates.REJECTED;
        data.endTime = Date.now();
        data.hangupReason = response.message.statusCode;
        commit('UPDATE_CALLS_INFO', { data, id });
    },

    // #region  CALL CONTROLS
    answerCall({ state }, { id }) {
        const session = state.activeSessions.find((session) => session.id === id);
        if (!session) return;
        if (session.state === SessionState.Terminated || session.state === SessionState.Established) return;
        const deviceId = state.inputDevice?.deviceId || 'default';
        const constrainsDefault = {
            audio: { deviceId },
            video: false,
        };
        const options = {
            sessionDescriptionHandlerOptions: {
                constraints: constrainsDefault,
                iceGatheringTimeout: 100,
            },

            extraHeaders: ['sip_h_X-TestHead: ThisIsMyTest On a invte accept'],
        };
        try {
            const session = state.activeSessions.find((session) => session.id === id);
            session.accept(options);
        } catch (error) {
            console.error(error);
        }
    },
    denyOrHangUp({ state, commit }, { id }) {
        const session = state.activeSessions.find((session) => session.id === id);
        if (!session) return;
        const data = structuredClone(state.callsInfo[id]);
        if (data.callState === callStates.REJECTED) {
            data.endTime = Date.now();
            data.callState = callStates.ENDED;
            commit('UPDATE_CALLS_INFO', { id, data });
            return;
        }
        const options = {
            requestOptions: {
                extraHeaders: ['X-Redial: false'],
            },
        };
        commit('END_CALL', { id, options });

        data.endTime = Date.now();
        data.callState = callStates.ENDED;
        commit('UPDATE_CALLS_INFO', { id, data });
    },
    muteCall({ state, commit }, { id, newState }) {
        const session = state.activeSessions.find((session) => session.id === id);
        const data = structuredClone(state.callsInfo[id]);
        if (!session || session.state === SessionState.Terminated) return;

        const pc = session.sessionDescriptionHandler.peerConnection;
        for (const stream of pc.getSenders()) {
            // Dont invert the value here, because the enabled needs to be set to the opposite of the current value
            stream.track.enabled = data.isMuted;
        }
        data.isMuted = newState || !data.isMuted;
        switch (true) {
            case data.isHold: {
                data.callState = callStates.HOLD;
                break;
            }
            case data.isMuted: {
                data.callState = callStates.MUTED;
                break;
            }
            case session.state === SessionState.Established: {
                data.callState = callStates.ONGOING;
                break;
            }
            default: {
                data.callState = data.direction === callDirections.OUTGOING ? callStates.AWAITING : callStates.RINGING;
                break;
            }
        }

        commit('UPDATE_CALLS_INFO', { id, data });
    },
    holdCall({ state, commit }, { id, newState }) {
        const session = state.activeSessions.find((session) => session.id === id);
        if (!session || session.state !== SessionState.Established || session.pendingReinvite) return;
        const data = structuredClone(state.callsInfo[id]);
        if (data.isHold === newState) {
            return;
        }
        data.isHold = newState || !data.isHold;

        if (data.isHold) {
            const options = {
                sessionDescriptionHandlerModifiers: [Web.holdModifier],
            };
            data.isHold = true;
            data.callState = callStates.HOLD;
            commit('UPDATE_CALLS_INFO', { id, data });

            session.invite(options);
            return;
        }
        const options = {
            sessionDescriptionHandlerModifiers: [],
        };
        data.isHold = false;
        if (data.isMuted) {
            data.callState = callStates.MUTED;
        } else {
            data.callState = callStates.ONGOING;
        }
        session.invite(options);
        commit('UPDATE_CALLS_INFO', { id, data });
    },
    sendDTMF({ state }, { id, digit }) {
        const session = state.activeSessions.find((session) => session.id === id);
        if (!session || !digit) {
            return;
        }
        if (digit === '*') {
            digit = 'star';
        }
        if (digit === '#') {
            digit = 'pound';
        }
        const audio = new Audio(require(`@/assets/Sounds/Dtmf/Dtmf-${digit}.mp3`));
        const soundId = state.outputDevice?.deviceId || 'default';
        audio.setSinkId(soundId).catch((error) => {
            console.error(error);
        });
        audio.play();
        session.sessionDescriptionHandler.sendDtmf(digit);
    },
    // #endregion

    // #region SETTERS
    setCallsInfo({ commit }, data) {
        commit('UPDATE_CALLS_INFO', data);
    },
    async setInputDevice({ commit }, data) {
        const res = await findMediaObject(data, 'audioinput');
        commit('SET_INPUT_DEVICE', res);
    },
    async setOutputDevice({ commit }, data) {
        const res = await findMediaObject(data, 'audiooutput');
        commit('SET_OUTPUT_DEVICE', res);
    },
    setNumberToShow({ commit }, data) {
        commit('SET_NUMBER_TO_SHOW', data);
    },
    setMaxConcurrentCalls({ commit }, data) {
        commit('SET_MAX_CONCURRENT_CALLS', data);
    },
    setRingtone({ commit }, data) {
        commit('SET_RINGTONE', data);
    },
    addSession({ state, commit }, data) {
        const sessions = [...state.activeSessions, data];
        commit('SET_ACTIVE_SESSIONS', sessions);
    },
    setSectedSessionId({ commit }, data) {
        commit('SET_SELECTED_SESSION_ID', data);
    },
    setIsMinimized({ commit }, data) {
        commit('SET_IS_MINIMIZED', data);
    },
    // #endregion
};
function hasValidInputs(str) {
    return internalAndExternalPhoneNumberRegex.test(str);
}

const getters = {};

const mutations = {
    SET_ACTIVE_SESSIONS(state, data) {
        state.activeSessions = data;
    },
    SET_INPUT_DEVICE(state, data) {
        data = data || { label: 'default' };
        state.inputDevice = data;
    },
    SET_OUTPUT_DEVICE(state, data) {
        data = data || { label: 'default' };
        state.outputDevice = data;
    },
    SET_NUMBER_TO_SHOW(state, data) {
        state.numberToShow = data;
    },
    SET_RINGTONE(state, data) {
        state.ringtone = data;
    },
    SET_SELECTED_SESSION_ID(state, data) {
        state.selectedSessionId = data;
    },
    SET_MAX_CONCURRENT_CALLS(state, data) {
        state.maxConcurrentCalls = data;
    },
    ADD_ACTIVE_SESSION(state, data) {
        state.activeSessions.push(data);
    },
    DELETE_CALLS_INFO(state, id) {
        const newCallsInfo = structuredClone(state.callsInfo);
        delete newCallsInfo[id];
        state.callsInfo = newCallsInfo;
    },
    UPDATE_CALLS_INFO(state, { id, data }) {
        const newCallsInfo = { ...state.callsInfo };
        newCallsInfo[id] = { ...newCallsInfo[id], ...data };
        state.callsInfo = newCallsInfo;
    },
    SET_IS_MINIMIZED(state, data) {
        state.isMinimized = data;
    },
    // Dont know if this is the best way to do this but it removes some strict mode errors
    END_CALL(state, { id, options }) {
        const session = state.activeSessions.find((session) => session.id === id);
        switch (session.state) {
            case SessionState.Initial:
            case SessionState.Establishing: {
                // An unestablished incoming session
                try {
                    session.reject(options);
                } catch {
                    session.cancel(options);
                }
                break;
            }
            case SessionState.Established: {
                // An established session
                session.bye(options);
                break;
            }
            default: {
                break;
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
