<template>
    <div
        v-if="show"
        id="checkmark"
        class="d-flex flex-column mr-0 justify-center"
        :class="margin ? 'ml-4' : 'ml-0'"
        style="width: 32px; max-width: 32px"
    >
        <div v-if="!hide" class="checkbox-form">
            <label class="item">
                <input
                    :disabled="disabled"
                    type="checkbox"
                    :checked="checked"
                    @input="(e) => updateShowSelect(e.target.checked)"
                />
                <span class="checkmark"></span>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            margin: {
                type: Boolean,
                default: true,
            },
            show: {
                type: Boolean,
                default: true,
            },
            checked: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            hide: {
                type: Boolean,
                default: false,
            },
        },

        methods: {
            updateShowSelect(isChecked) {
                this.$emit('input', isChecked);
            },
        },
    };
</script>

<style scoped>
    .checkbox-form .item {
        display: block;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        font-size: 1em;
        height: 20px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: flex;
        align-items: center;
    }
    .checkbox-form .item input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        border-radius: 12px;
    }
    .checkbox-form .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: transparent;
        border: 1px solid #ccc;
        border-radius: 12px;
        transition: background-color 0.1s ease;
    }

    .checkbox-form .item:hover input ~ .checkmark {
        background-color: #ccc;
    }
    .checkbox-form .item input:checked ~ .checkmark {
        background-color: var(--v-primary-base) !important;
        border: 0px solid #ccc;
        border-radius: 12px;
    }
    .checkbox-form .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }
    .checkbox-form .item input:checked ~ .checkmark:after {
        display: block;
    }
    .checkbox-form .item .checkmark:after {
        left: 8px;
        top: 4px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
</style>
