<template>
    <div :key="currentQueueId" class="container mb-5 py-5 px-3" flat>
        <v-text-field
            v-model="search"
            style="border-radius: 6px"
            full-width
            hide-details
            outlined
            dense
            :label="$t('templateView.search')"
        />

        <InfiniteList
            :headers="headers"
            :fetchItems="getTemplates"
            :items="templates.casesTemplates"
            :limit="10"
            :search="search"
            :emptyHeader="$t('templateView.noTemplates')"
            :emptyMessage="$t('templateView.addGotoTempl')"
            emptyIcon="mdi-account-group"
        >
            <template #item="{ item }">
                <TemplateItem :template="item" />
            </template>
        </InfiniteList>
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    import { templateTypes } from '@/enums/templates.enums';
    import TemplateItem from '@/components/Cases/Sidebars/TemplateView/TemplateItem.vue';

    export default {
        components: {
            TemplateItem,
        },
        data() {
            return {
                search: '',
                headers: [{ text: '', value: '', width: '' }],
            };
        },
        computed: {
            ...mapState({
                selectedCase: (state) => state.Cases.selectedCase,
                allQueues: (state) => state.QueueManager.allQueues,
                templates: (state) => state.Templates.templates,
                currentQueueId: (state) => state.Cases.selectedCase?.case?.queueId,
            }),
        },

        mounted() {
            this.getAllQueues();
        },
        methods: {
            ...mapActions('QueueManager', ['getAllQueues']),
            ...mapActions('Templates', ['templateByCategory']),
            async getTemplates(pagination) {
                if (!pagination) return;

                // Find the language by queueId in allQueues (Not best solution but works for now will refactor)
                const selectedQueue = this.allQueues.find((queue) => queue.ID === this.currentQueueId);

                await this.templateByCategory({
                    page: pagination.page,
                    limit: pagination.limit,
                    categoryId: templateTypes.EMAIL,
                    search: pagination.search,
                    queueIds: [this.currentQueueId],
                    language: selectedQueue?.Language,
                });
            },
        },
    };
</script>
<style lang="scss" scoped>
    .container {
        display: flex;
        justify-content: stretch;
        max-height: 55vh;
        flex-direction: column;
        overflow: hidden;
    }
</style>
<i18n lang="json">
{
    "en": {
        "templateView": {
            "noTemplates": "No templates found in the language this queue has",
            "addGotoTempl": "Add by going to the template manager.",
            "search": "Search"
        }
    },
    "sv": {
        "templateView": {
            "noTemplates": "Inga mallar hittades i det språk denna kö har",
            "addGotoTempl": "Lägg till genom att gå till mall-hanteringen.",
            "search": "Sök"
        }
    }
}
</i18n>
