import board from '@/api/board/board';

// Actions
const state = {
    selectedGroupId: null,
};
const actions = {
    getTags({}, payload) {
        return new Promise((resolve, reject) => {
            board
                .getTags(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getNotes({}, payload) {
        return new Promise((resolve, reject) => {
            board
                .getNotes(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    addNewTag({}, payload) {
        return new Promise((resolve, reject) => {
            board
                .addNewTag(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    addNewNote({}, payload) {
        return new Promise((resolve, reject) => {
            board
                .addNewNote(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateNote({}, payload) {
        return new Promise((resolve, reject) => {
            board
                .updateNote(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateOrdinalOnNotes({}, payload) {
        return new Promise((resolve, reject) => {
            board
                .updateOrdinalOnNotes(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    deleteNote({}, payload) {
        return new Promise((resolve, reject) => {
            board
                .deleteNote(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    deleteTag({}, payload) {
        return new Promise((resolve, reject) => {
            board
                .deleteTag(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    deleteTagOnNote({}, payload) {
        return new Promise((resolve, reject) => {
            board
                .deleteTagOnNote(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

const mutations = {
    SET_SELECTED_GROUP_ID(state, payload) {
        state.selectedGroupId = payload;
    },
};

export default {
    namespaced: true,
    actions,
    state,
    mutations,
};
