import prompts from '../../api/telephony/prompts';

const state = {
    availableVoices: [],
};

const actions = {
    async updateAvailableVoices({ commit }) {
        try {
            const res = await prompts.getAvailableVoices();
            const availableVoices = res.data;
            commit('SET_AVAILABLE_VOICES', availableVoices);
        } catch (error) {
            console.error('Error fetching available voices', error);
        }
    },
    async generateTestPrompt(_, { voiceName, text }) {
        try {
            const res = await prompts.generateTestPrompt(voiceName, text);
            return res.data;
        } catch (error) {
            console.error('Error generating test prompt', error);
            return null;
        }
    },
};

const getters = {
    availableVoices: (state) => {
        if (Array.isArray(state.availableVoices)) {
            return state.availableVoices;
        }
        return [];
    },
    availableLocations: (state) => {
        if (Array.isArray(state.availableVoices)) {
            return [...new Set(state.availableVoices.map((voice) => voice.LocaleName))].sort();
        }
        return [];
    },
    getVoicesByCountry: (state) => (country, gender) => {
        if (!Array.isArray(state.availableVoices)) {
            return [];
        }
        return state.availableVoices.filter((voice) => {
            if (gender !== 'All') {
                return voice.LocaleName === country && voice.Gender === gender;
            }
            return voice.LocaleName === country;
        });
    },
};

const mutations = {
    SET_AVAILABLE_VOICES: (state, availableVoices) => {
        state.availableVoices = availableVoices;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
