<template>
    <node-view-wrapper as="span" class="image-container">
        <img v-bind="node.attrs" ref="resizableImg" :draggable="isDraggable" :data-drag-handle="isDraggable" />
        <v-icon ref="icon" class="ml-n3 resize-icon hidden" @mousedown="onMouseDown">
            mdi-arrow-top-left-bottom-right-bold
        </v-icon>
    </node-view-wrapper>
</template>

<script>
    import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2';

    export default {
        components: {
            NodeViewWrapper,
        },
        props: nodeViewProps,
        data() {
            return {
                isResizing: false,
                lastMovement: {},
                count: 0,
            };
        },
        computed: {
            isDraggable() {
                return this.node?.attrs?.isDraggable;
            },
        },
        methods: {
            onMouseDown(e) {
                e.preventDefault();

                this.isResizing = true;

                window.addEventListener('mousemove', this.throttle(this.onMouseMove));
                window.addEventListener('mouseup', this.onMouseUp);
            },
            throttle(fn, wait = 60, leading = true) {
                let prev;
                let timeout;
                let lastArgs;

                return (...args) => {
                    lastArgs = args;
                    if (timeout) return;
                    timeout = setTimeout(
                        () => {
                            timeout = null;
                            prev = Date.now();
                            fn.apply(this, lastArgs.splice(0, lastArgs.length));
                        },

                        leading ? (prev && Math.max(0, wait - (Date.now() - prev))) || 0 : wait
                    );
                };
            },
            onMouseUp(e) {
                // e.preventDefault();
                this.isResizing = false;
                this.lastMovement = {};
                window.removeEventListener('mousemove', this.throttle(this.onMouseMove));
                window.removeEventListener('mouseup', this.onMouseUp);
            },
            onMouseMove(e) {
                e.preventDefault();

                if (!this.isResizing) {
                    return;
                }

                if (!Object.keys(this.lastMovement).length) {
                    this.lastMovement = { x: e.x, y: e.y };
                    return;
                }

                if (e.x === this.lastMovement.x && e.y === this.lastMovement.y) {
                    return;
                }

                const nextX = e.x - this.lastMovement.x;
                const nextY = e.y - this.lastMovement.y;
                const width = this.$refs.resizableImg.width + nextX;
                const height = this.$refs.resizableImg.height + nextY;

                this.lastMovement = { x: e.x, y: e.y };
                this.updateAttributes({ width, height });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .image-container:hover {
        .hidden {
            visibility: visible !important;
        }
    }

    .image-container {
        overflow: hidden;
        position: relative;
    }

    .resize-icon {
        position: absolute;
        bottom: 0;
    }

    ::v-deep.resize-icon {
        cursor: se-resize !important;
    }
</style>
