import axios from 'axios';
import DedupeRequest from '../../entity/DedupeRequest';

function getUserCompanyGroups(userId) {
    return axios.get(`groups/user/${userId}`);
}
function getGroupWithUsers(groupId) {
    return axios.get(`/groups/${groupId}/extended-users`);
}

const getAllCompanyGroupsDedupe = new DedupeRequest(() => {
    return axios.get('/groups');
}, 1000);

function getAllCompanyGroups() {
    return getAllCompanyGroupsDedupe.send();
}

function getGroupTooltipInfo(groupId) {
    return axios.get(`/groups/${groupId}/tooltip`);
}

function createCompanyGroup(payload) {
    return axios.post(`groups`, payload);
}
// async function updateCompanyGroup(groupId) {
//     return axios({
//         method: 'put',
//         url: `groups/${groupId}`
//     })
// }

function addMembersToCompanyGroup(payload) {
    return axios.put(`groups/${payload.groupId}`, payload);
}
function saveGroupDescription(payload) {
    return axios.put(`groups/${payload.groupId}/description`, payload);
}

function deleteCompanyGroup(groupId) {
    return axios.delete(`groups/${groupId}`);
}
function deleteUserFromGroup({ groupId, userId }) {
    return axios.delete(`/groups/${groupId}/users/${userId}`);
}
function deleteAllUsersFromGroup(groupId) {
    return axios.delete(`/groups/all/${groupId}/users`);
}
function editGroupName(payload) {
    return axios.put(`groups/${payload.groupId}/name`, payload);
}
function getGroupsBasics() {
    return axios.get(`/groups/basics`);
}
function getGroupSettings(groupId) {
    return axios.get(`/groups/${groupId}/settings`);
}
function getUsersInGroup(payload) {
    return axios.get(`/groups/${payload.groupId}/users?page=${payload.page}&search=${payload.search}`);
}

function getAvailableUsers(payload) {
    return axios.get(`/groups/available/users/${payload.groupId}?page=${payload.page}&search=${payload.search}`);
}
function addMembersToGroup(payload) {
    return axios.put(`/groups/members/${payload.groupId}`, payload);
}

export default {
    getAllCompanyGroups,
    getUserCompanyGroups,
    getGroupWithUsers,

    getGroupTooltipInfo,
    createCompanyGroup,
    saveGroupDescription,
    // updateCompanyGroup,
    deleteCompanyGroup,
    deleteUserFromGroup,
    deleteAllUsersFromGroup,
    addMembersToCompanyGroup,
    editGroupName,
    getGroupsBasics,
    getGroupSettings,
    getUsersInGroup,
    getAvailableUsers,
    addMembersToGroup,
};
