<template>
    <v-avatar
        v-bind="bind"
        right
        :size="size"
        class="d-flex justify-center align-center avatar"
        :color="color"
        :style="avatarStyle"
    >
        <img v-if="src" :src="src" :class="[presenceClass, 'image']" @load="onImageLoad" />
    </v-avatar>
</template>
<script>
    import { randomColor, getColorByIndex } from '@/utils';

    export default {
        props: {
            presence: { type: Number, default: 99 },
            name: {
                type: String,
                default: '',
            },
            img: {
                type: String,
                default: '',
            },
            index: {
                type: Number,
                default: 100,
            },
            size: {
                type: [Number, String],
                default: 40,
            },
            // for eventual image usage
            userId: {
                type: [Number, String],
                default: 0,
            },

            color: {
                type: String,
                default: 'gray3',
            },
            base64img: {
                type: String,
                default: '',
            },
            x1: {
                type: Boolean,
                default: false,
            },
            x2: {
                type: Boolean,
                default: false,
            },
            x4: {
                type: Boolean,
                default: false,
            },
            border: {
                type: String,
                default: '0 solid var(--v-accent2-base)',
            },
            borderRadius: {
                type: String,
                default: '50%',
            },
            bind: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                src: null,
                presenceClassMap: {
                    0: 'presence-offline',
                    1: 'presence-online',
                    2: 'presence-online',
                    3: 'presence-offline',
                    4: 'presence-disabled',
                    5: 'presence-busy',
                },
                isImageLoaded: false,
            };
        },
        computed: {
            presenceClass() {
                return this.presenceClassMap[this.presence] || '';
            },
            avatarStyle() {
                return {
                    borderRadius: this.borderRadius,
                    height: `${this.size}px`,
                    width: `${this.size}px`,
                };
            },
        },
        watch: {
            base64img(newVal) {
                if (newVal) {
                    this.src = newVal;
                }
            },
        },

        async created() {
            if (this.base64img) {
                this.src = this.base64img;
                return;
            }

            let resolution = '128';

            switch (true) {
                case this.x4: {
                    resolution = '512';
                    break;
                }
                case this.x2: {
                    resolution = '256';
                    break;
                }
                case this.x1: {
                    resolution = '128';
                    break;
                }
                default: {
                    resolution = '128';
                    break;
                }
            }

            const params = {
                userId: this.userId,
                resolution,
            };

            this.src = await this.$store.dispatch('Users/getUserProfileImage', params); // use when we own images - Teo 2022-08-31
        },
        methods: {
            randomColor,
            getColorByIndex,
            onImageLoad() {
                this.isImageLoaded = true;
            },
        },
    };
</script>
<style scoped>
    .image {
        object-fit: cover;
    }

    .avatar-container {
        border-radius: 50%;
    }

    .avatar {
        transition: opacity 0.2s;
    }

    .presence-online {
        border: 2px solid rgba(39, 205, 58, 0.48);
    }
    .presence-busy {
        border: 2px solid rgba(200, 62, 38, 0.829);
    }
    .presence-idle {
        border: 2px solid rgba(192, 198, 28, 0.808);
    }
    .presence-offline {
        border: 2px solid rgba(221, 221, 204, 0.848);
    }
    .presence-disabled {
        border: 2px solid rgba(221, 221, 204, 0.848);
    }
</style>
