module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "sla": {
          "noSla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No SLA"])},
          "slaTime": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Time: ", _interpolate(_named("time"))])},
          "slaLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Length: ", _interpolate(_named("time"))])},
          "inQueue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last incoming: ", _interpolate(_named("time"))])},
          "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Snoozed until: ", _interpolate(_named("time"))])},
          "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case closed"])}
        }
      },
      "sv": {
        "sla": {
          "noSla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen SLA"])},
          "slaTime": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tid: ", _interpolate(_named("time"))])},
          "slaLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Längd: ", _interpolate(_named("time"))])},
          "inQueue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Senast mottaget: ", _interpolate(_named("time"))])},
          "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bevakad till: ", _interpolate(_named("time"))])},
          "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ärendet stängt"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"sla":{"noSla":"No SLA","slaTime":"Time: {time}","slaLength":"Length: {time}","inQueue":"Last incoming: {time}","snoozed":"Snoozed until: {time}","closed":"Case closed"}},"sv":{"sla":{"noSla":"Ingen SLA","slaTime":"Tid: {time}","slaLength":"Längd: {time}","inQueue":"Senast mottaget: {time}","snoozed":"Bevakad till: {time}","closed":"Ärendet stängt"}}}')
  delete Component.options._Ctor
  
}
