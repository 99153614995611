import axios from 'axios';

function getConnectedClientData(userId, type) {
    return axios.get(`search/${type}/${userId}/client-data`);
}

function search(payload) {
    return axios.get(
        `search/${payload.type}/?q=${encodeURIComponent(payload.q)}&filterQuestion=${encodeURIComponent(
            payload.filterQuestion,
        )}&page=${payload.page}&listViewItems=${payload.listViewItems}`,
    );
}

function updateSearchObject(payload) {
    return axios.put(`/search/object`, payload);
}

function getUserData({ userId, type }) {
    const urlSearch = `search/getUser/?q=${encodeURIComponent(userId)}&type=${type}`;
    return axios.get(`${urlSearch}`);
}

function getUniqueSearch(searchWord) {
    return axios.get('search/?q=' + searchWord);
}

function getSearchHandlerUsers({ search, page }) {
    return axios.get(`search/settings/users?search=${search}&page=${page}`);
}
function getSearchHandlerQueues({ search, page }) {
    return axios.get(`search/settings/queues?search=${search}&page=${page}`);
}
function updateUserShowInSearch(payload) {
    return axios.put(`search/settings/users/${payload.id}`, payload);
}

export default {
    getConnectedClientData,
    search,
    updateSearchObject,
    getUniqueSearch,
    getUserData,
    getSearchHandlerUsers,
    getSearchHandlerQueues,
    updateUserShowInSearch,
};
