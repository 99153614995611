<template>
    <v-tooltip v-bind="tooltipProps">
        <template #activator="{ on }">
            <div class="button-container" v-on="on">
                <slot name="button"></slot>
            </div>
        </template>
        <v-card :max-width="maxWidth" class="tooltip-content" elevation="0">
            <v-card-title v-if="heading" class="heading">
                {{ heading }}
            </v-card-title>
            <v-card-text :style="{ padding: tooltipPadding }">
                <slot name="content"></slot>
            </v-card-text>
        </v-card>
    </v-tooltip>
</template>
<script>
    export default {
        props: {
            heading: {
                type: String,
                default: '',
            },
            maxWidth: {
                type: String,
                default: '600px',
            },
            location: {
                // Can be set to 'right', 'left', 'top', bottom or 'none' to hide tooltip
                type: String,
                default: 'right',
            },
            openDelay: {
                type: String,
                default: '0',
            },
            tooltipPadding: {
                type: String,
                default: '4px',
            },
            growButtonArea: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            tooltipProps() {
                if (this.$props.location === 'none') {
                    return { contentClass: 'custom-tooltip', openDelay: this.openDelay };
                }
                return { [this.$props.location]: true, contentClass: 'custom-tooltip', openDelay: this.openDelay };
            },
        },
    };
</script>
<style scoped>
    .button-container {
        align-items: center;
        justify-content: center;
        align-content: center;
        display: flex;
        flex: 0;
        max-width: 100%;
    }
    .custom-tooltip {
        opacity: 1 !important;
        background-color: transparent !important;
    }
    .tooltip-content {
        border: 1px solid #eee !important;
    }
    .heading {
        display: flex;
        justify-content: flex-start;
        align-content: center;
        font-size: 12px;
        border-bottom: 1px solid #eee !important;
        height: 20px;
        padding: auto;
    }
</style>
