const { safeJsonParse } = require('../utils/index');

// Plugin to save the state of the store in the local storage and get it on initialization
export function localStoragePlugin(objects) {
    return (store) => {
        for (const { key, stateProperty, mutationName } of objects) {
            // Load the initial state from local storage
            const localStorageItem = localStorage.getItem(key);
            if (localStorageItem !== 'undefined') {
                const savedState = safeJsonParse(localStorage.getItem(key));
                if (savedState !== null) {
                    store.commit(mutationName, savedState);
                }
            }
            // Subscribe to store changes and update local storage
            store.subscribe((mutation, state) => {
                if (mutation.type === mutationName) {
                    const storeName = mutationName.split('/')[0];
                    const stateValue = state[storeName][stateProperty];
                    if (stateValue === undefined) return;
                    localStorage.setItem(key, JSON.stringify(stateValue));
                }
            });
        }
    };
}

export default [localStoragePlugin];
