module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "comment": {
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To:"])},
          "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing"])},
          "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming"])},
          "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending"])},
          "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
          "callback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
          "try": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try"])}
        }
      },
      "sv": {
        "comment": {
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Till:"])},
          "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utgående"])},
          "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkommande"])},
          "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skickar"])},
          "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapad"])},
          "callback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återkoppla"])},
          "try": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försök"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"comment":{"to":"To:","outgoing":"Outgoing","incoming":"Incoming","sending":"Sending","created":"Created","callback":"Callback","try":"Try"}},"sv":{"comment":{"to":"Till:","outgoing":"Utgående","incoming":"Inkommande","sending":"Skickar","created":"Skapad","callback":"Återkoppla","try":"Försök"}}}')
  delete Component.options._Ctor
  
}
