import axios from 'axios';

function divertOptions() {
    return axios.get('tapi/presence/hvd');
}

function setPresence(data) {
    return axios.put(`tapi/users/${data.userId}/hvd`, data);
}

function getPresence(userId) {
    return axios.get(`tapi/users/${userId}/presence`);
}

export default {
    divertOptions,
    setPresence,
    getPresence,
};
