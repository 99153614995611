module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "link": {
          "checkBrowser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to open URL in a new window. Please check your browser settings."])}
        },
        "social": {
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])}
        }
      },
      "sv": {
        "link": {
          "checkBrowser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misslyckades med att öppna URL i ett nytt fönster. Vänligen kontrollera dina webbläsarinställningar."])}
        },
        "social": {
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okänd"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"link":{"checkBrowser":"Failed to open URL in a new window. Please check your browser settings."},"social":{"unknown":"Unknown"}},"sv":{"link":{"checkBrowser":"Misslyckades med att öppna URL i ett nytt fönster. Vänligen kontrollera dina webbläsarinställningar."},"social":{"unknown":"Okänd"}}}')
  delete Component.options._Ctor
  
}
