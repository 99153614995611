import wallboard from '@/api/wallboard/wallboard';
import helper from '@/helpers/wallboard/wallboard.helper';
import { localStoragePlugin } from '../plugins';
import i18n from '../../i18n';

const state = {
    globalWallboardState: false,
    statistics: [],
    wallboardUsers: [],
    allQueues: [],
    allUsers: [],
    visualUsers: [],
    page: 0,
    isPaused: true,
    currentTime: 0,
    clockInterval: null,
    clockCounter: null,
    delay: { name: '10 s', value: 10000, icon: 'mdi-timer-sand' },
    delayMenuOptions: [],
    usersAmount: { name: '16st', value: 16, icon: 'mdi-account-multiple' },
    globalTimer: null,
    tickingLongestWaitings: [],
    confetti: { active: false, amount: '', text: '', color: '' },
    celebrations: [],
};

const getters = {
    getAllQueues: (state) => state.allQueues,
};

const actions = {
    /// ////////////////////////////////////////////////
    // -----------------------------------------------//
    // ---------------------SOCKETS-------------------//
    // -----------------------------------------------//
    /// ////////////////////////////////////////////////

    async SOCKET_wallboardStatistics({ dispatch }, payload) {
        try {
            await dispatch('handleConfetti', payload);
            await dispatch('setUpdatedQueues', payload);
        } catch (error) {
            this._vm.$toasted.show(i18n.t('casesStore.noUpdateList') + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
        }
    },

    SOCKET_userUpdatesToWallboard({ commit }, data) {
        const formattedUsers = helper.formatUsersData(data);

        commit('SET_WALLBOARD_USERS', formattedUsers);
    },

    SOCKET_userActivityWallboard({ state, commit }, changes) {
        const updatedUsers = helper.processChangedActivities(changes, state.wallboardUsers);
        commit('SET_WALLBOARD_USERS', updatedUsers);
    },

    async setSocketConnection({ dispatch }) {
        await Promise.all([
            dispatch('statisticsSubscribe'),
            dispatch('usersSubscribe'),
            dispatch('usersActivitySubscribe'),
        ]);
    },

    async setSubscriptionData({ state, commit }) {
        const allQueues = helper.getAndSortAllQueues(state.statistics);
        await commit('SET_ALL_QUEUES', allQueues);

        const allUserIds = helper.getAndSortAllUserIds(state.wallboardUsers);
        await commit('SET_ALL_USERS', allUserIds);

        const visualUserIds = helper.getAndSortVisualUsers(state.wallboardUsers);
        await commit('SET_VISUAL_USERS', visualUserIds);
    },

    statisticsSubscribe({ state }) {
        this._vm.$socket.emit('wallboardStatisticsSubscribe', state.allQueues);
    },
    statisticsUnSubscribe({ state }) {
        this._vm.$socket.emit('wallboardStatisticsUnSubscribe', state.allQueues);
    },
    usersSubscribe({ state }) {
        const object = { ids: state.allUsers, limit: state.usersAmount.value };
        this._vm.$socket.emit('wallboardUsersSubscribe', object);
    },
    usersUnSubscribe({ state }) {
        const object = { ids: state.allUsers, limit: state.usersAmount.value };
        this._vm.$socket.emit('wallboardUsersUnSubscribe', object);
    },
    usersActivitySubscribe({ state }) {
        this._vm.$socket.emit('wallboardUsersActivitySubscribe', state.visualUsers);
    },
    usersActivityUnSubscribe({ state }) {
        this._vm.$socket.emit('wallboardUsersActivityUnSubscribe', state.visualUsers);
    },

    /// ////////////////////////////////////////////////
    // -----------------------------------------------//
    // -----------------CALCULATIONS------------------//
    // -----------------------------------------------//
    /// ////////////////////////////////////////////////

    async setInitializations({ state, commit, dispatch }) {
        await Promise.all([dispatch('getWallboardStatistics'), dispatch('getWallboardUsers', state.usersAmount.value)]);
        await Promise.all([dispatch('setSubscriptionData'), dispatch('setCelebrations')]);
        await dispatch('setSocketConnection');

        commit('SET_DELAY_MENU_OPTIONS', helper.prepareDelayMenuOptions(state.delayMenuOptions));
        dispatch('setClockTime');
        dispatch('setTimerArrays');
        dispatch('startTimer');
    },

    setCelebrations({ commit, state }) {
        const celebrations = [];
        for (const stat of state.statistics) {
            if (!stat.celebrations) continue;
            celebrations.push(stat.celebrations);
        }
        commit('SET_CELEBRATIONS', celebrations);
    },

    setGlobalWallboardState({ commit }, payload) {
        commit('SET_GLOBAL_WALLBOARD_STATE', payload);
    },

    setUpdatedQueues({ dispatch, commit, state }, payload) {
        const newStatistics = helper.processUpdatedQueues(state.statistics, payload);

        commit('SET_STATISTICS', newStatistics);

        dispatch('setTimerArrays');
    },

    startTimer({ commit, dispatch, state }) {
        if (!state.globalTimer) {
            const timer = setInterval(() => {
                dispatch('setUpdatedLongestWaiting');
            }, 1000);
            commit('SET_GLOBAL_TIMER', timer);
        }
    },

    stopTimer({ commit, state }) {
        if (state.globalTimer) {
            commit('CLEAR_GLOBAL_TIMER');
        }
    },

    setTimerArrays({ commit, state }) {
        const tickingLongestWaitings = helper.getTickingLongestWaitings(state.statistics);
        commit('SET_TICKING_LONGEST_WAITINGS', tickingLongestWaitings);
    },

    setUpdatedLongestWaiting({ commit, state }) {
        for (const object of state.tickingLongestWaitings) {
            if (object.type == 'queue') {
                commit('INCREMENT_LONGEST_WAITING_QUEUE', object);
            } else if (object.type == 'card') {
                commit('INCREMENT_LONGEST_WAITING_CARD', object);
            }
        }
    },

    setPageStep({ state, commit, dispatch }, payload) {
        commit('SET_CURRENT_TIME', 0);
        dispatch('setClockTime');
        const nextPage = helper.calculateNextPage(state.page, payload, state.statistics.length);
        commit('SET_PAGE', nextPage);
    },

    setSpecificPage({ state, commit, dispatch }, { id, length }) {
        const page = helper.calculatePageToSet(state.statistics, id, length);

        if (page === null) return;

        commit('SET_PAGE', page);
        dispatch('setPause', true);
    },

    setClockTime({ state, commit, dispatch }) {
        commit('CLEAR_CLOCK_INTERVAL');
        commit('CLEAR_CLOCK_COUNTER');
        commit('SET_CURRENT_TIME', 0);

        const counter = setInterval(() => {
            if (state.isPaused) return;

            let newTime = state.currentTime + 1;
            if (newTime >= 4) {
                newTime = 0;
            }

            commit('SET_CURRENT_TIME', newTime);
        }, state.delay.value / 4);

        commit('SET_CLOCK_COUNTER', counter);

        const interval = setInterval(() => {
            if (state.isPaused) return;
            dispatch('setPageStep', 1);
        }, state.delay.value);

        commit('SET_CLOCK_INTERVAL', interval);
    },

    setPause({ dispatch, commit }, payload) {
        commit('SET_CURRENT_TIME', 0);
        commit('SET_IS_PAUSED', payload);
        if (payload) return;
        dispatch('setClockTime');
    },

    setDelay({ dispatch, commit }, payload) {
        commit('SET_DELAY', payload);
        dispatch('setClockTime');
    },

    async setUsersAmount({ dispatch, commit }, payload) {
        const oldLimit = state.usersAmount.value;
        const newLimit = payload.value;

        if (oldLimit == newLimit) return;

        await dispatch('usersUnSubscribe');

        await commit('SET_USERS_AMOUNT', payload);

        await dispatch('usersSubscribe');

        if (oldLimit > newLimit) {
            const users = structuredClone(state.wallboardUsers);
            const newUsers = users.map((card) => {
                card.users = card.users.slice(0, newLimit);
                return card;
            });
            commit('SET_WALLBOARD_USERS', newUsers);
        } else {
            await dispatch('getWallboardUsers', newLimit);
        }
    },

    beforeDestroy({ state, dispatch, commit }) {
        clearInterval(state.interval);
        commit('CLEAR_CLOCK_INTERVAL');

        dispatch('stopTimer');
        dispatch('statisticsUnSubscribe');
        dispatch('usersUnSubscribe');

        dispatch('setGlobalWallboardState', false);
        dispatch('stopConfetti');
    },

    handleConfetti({ state, commit, dispatch }, cards) {
        for (const card of cards) {
            const oldCard = state.statistics.find((c) => c.id === card.id);

            if (oldCard.data.answeredCalls === card.data.answeredCalls) continue;

            const celebration = state.celebrations.find((c) => c.cardId === card.id);

            if (!celebration.active) continue;

            const { amount, particle, color } = celebration;

            if (card.data.answeredCalls % amount !== 0) continue;

            this._vm.$confetti.start({
                particles: [
                    {
                        type: particle,
                    },
                ],
                defaultColors: [color],
            });

            const CONFETTI_START_DELAY = 1000;
            const CONFETTI_DURATION = 15000;
            setTimeout(() => {
                commit('SET_CONFETTI', {
                    active: true,
                    amount: card.data.answeredCalls,
                    text: i18n.t('wallboardStore.answeredCalls'),
                    color,
                });
            }, CONFETTI_START_DELAY);

            setTimeout(() => {
                dispatch('stopConfetti');
            }, CONFETTI_DURATION + CONFETTI_START_DELAY);
        }
    },

    stopConfetti({ commit }) {
        this._vm.$confetti.stop();
        commit('SET_CONFETTI', {
            active: false,
            amount: '',
            text: '',
            color: '',
        });
    },

    /// ////////////////////////////////////////////////
    // -----------------------------------------------//
    // ---------------FETCHING FROM API---------------//
    // -----------------------------------------------//
    /// ////////////////////////////////////////////////

    getWallboardStatistics({ commit }) {
        return new Promise((resolve, reject) => {
            wallboard
                .getWallboardStatistics()
                .then(async (res) => {
                    await commit('SET_STATISTICS', res.data);

                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    storeWallboardCard({}, payload) {
        return new Promise((resolve, reject) => {
            wallboard
                .storeWallboardCard(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.saved'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    getWallboardUsers({ commit }, payload) {
        return new Promise((resolve, reject) => {
            wallboard
                .getWallboardUsers(payload)
                .then(async (res) => {
                    const formattedUsers = helper.formatUsersData(res.data);
                    await commit('SET_WALLBOARD_USERS', formattedUsers);
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    getWallboardSettings({}) {
        return new Promise((resolve, reject) => {
            wallboard
                .getWallboardSettings()
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    updateWallboardCard({}, payload) {
        return new Promise((resolve, reject) => {
            wallboard
                .updateWallboardCard(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.updated'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    updateWallboardOrder({}, payload) {
        return new Promise((resolve, reject) => {
            wallboard
                .updateWallboardOrder(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.updated'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    deleteWallboardCard({}, id) {
        return new Promise((resolve, reject) => {
            wallboard
                .deleteWallboardCard(id)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    addQueueToWallboardCard({}, payload) {
        return new Promise((resolve, reject) => {
            wallboard
                .addQueueToWallboardCard(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.updated'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    removeQueueFromWallboardCard({}, payload) {
        return new Promise((resolve, reject) => {
            wallboard
                .removeQueueFromWallboardCard(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.updated'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    updateCelebration({}, payload) {
        return new Promise((resolve, reject) => {
            wallboard
                .updateCelebration(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.updated'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('queueManagerStore.wrong2'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
};

const mutations = {
    SET_STATISTICS(state, data) {
        state.statistics = data;
    },
    SET_WALLBOARD_USERS(state, data) {
        state.wallboardUsers = data;
    },
    SET_ALL_QUEUES(state, data) {
        state.allQueues = data;
    },
    SET_ALL_USERS(state, data) {
        state.allUsers = data;
    },
    SET_VISUAL_USERS(state, data) {
        state.visualUsers = data;
    },
    SET_SPECIFIC_USER(state, { queueIndex, userIndex, newUser }) {
        state.wallboardUsers[queueIndex].users[userIndex] = newUser;
    },
    SET_GLOBAL_WALLBOARD_STATE(state, data) {
        state.globalWallboardState = data;
    },
    SET_USERS_AMOUNT(state, data) {
        state.usersAmount = data;
    },
    SET_CONFETTI(state, data) {
        state.confetti = data;
    },
    SET_CELEBRATIONS(state, data) {
        state.celebrations = data;
    },

    /// /////////////////////////////
    // ------CLOCK/PAGINATION------//
    /// /////////////////////////////

    SET_CLOCK_COUNTER(state, data) {
        state.clockCounter = data;
    },
    SET_CLOCK_INTERVAL(state, data) {
        state.clockInterval = data;
    },
    CLEAR_CLOCK_INTERVAL(state) {
        clearInterval(state.clockInterval);
        state.clockInterval = null;
    },
    CLEAR_CLOCK_COUNTER(state) {
        clearInterval(state.clockCounter);
    },
    SET_DELAY_MENU_OPTIONS(state, data) {
        state.delayMenuOptions = data;
    },
    SET_DELAY(state, data) {
        state.delay = data;
    },
    SET_CURRENT_TIME(state, data) {
        state.currentTime = data;
    },
    SET_IS_PAUSED(state, data) {
        state.isPaused = data;
    },
    SET_PAGE(state, data) {
        state.page = data;
    },

    /// /////////////////////////////
    // ----QUEUE DISPLAY TIMER-----//
    /// /////////////////////////////

    INCREMENT_LONGEST_WAITING_QUEUE(state, { i, j }) {
        state.statistics[i].ownStatistics[j].longestWaiting++;
    },
    INCREMENT_LONGEST_WAITING_CARD(state, { i }) {
        state.statistics[i].data.longestWaiting++;
    },
    SET_GLOBAL_TIMER(state, data) {
        state.globalTimer = data;
    },
    CLEAR_GLOBAL_TIMER(state) {
        clearInterval(state.globalTimer);
        state.globalTimer = null;
    },
    SET_TICKING_LONGEST_WAITINGS(state, data) {
        state.tickingLongestWaitings = data;
    },
};

const plugins = [
    localStoragePlugin([
        {
            key: 'wallboardUsersAmount',
            stateProperty: 'usersAmount',
            mutationName: 'Wallboard/SET_USERS_AMOUNT',
        },
        { key: 'wallboardDelay', stateProperty: 'delay', mutationName: 'Wallboard/SET_DELAY' },
    ]),
];

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    plugins,
};
