module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "caseComments": {
          "caseNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case number"])},
          "numericalCaseId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numerical case Id"])},
          "clickToCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to copy"])},
          "copiedToClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied to clipboard"])},
          "failedToCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to copy"])},
          "copyNotSupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy is not supported on this device"])}
        }
      },
      "sv": {
        "caseComments": {
          "caseNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ärendenummer"])},
          "numericalCaseId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numeriskt ärendenummer"])},
          "clickToCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicka för att kopiera"])},
          "copiedToClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopierat till urklipp"])},
          "failedToCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misslyckades med att kopiera"])},
          "copyNotSupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiering stöds inte på denna enhet"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"caseComments":{"caseNumber":"Case number","numericalCaseId":"Numerical case Id","clickToCopy":"Click to copy","copiedToClipboard":"Copied to clipboard","failedToCopy":"Failed to copy","copyNotSupported":"Copy is not supported on this device"}},"sv":{"caseComments":{"caseNumber":"Ärendenummer","numericalCaseId":"Numeriskt ärendenummer","clickToCopy":"Klicka för att kopiera","copiedToClipboard":"Kopierat till urklipp","failedToCopy":"Misslyckades med att kopiera","copyNotSupported":"Kopiering stöds inte på denna enhet"}}}')
  delete Component.options._Ctor
  
}
