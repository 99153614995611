export async function performActions(configs, commit, dispatch) {
    for (const config of configs) {
        switch (config.name) {
            case 'admin_config': {
                commit('SET_CUSTOMER_SETTINGS', config);
                localStorage.setItem('__configDownloaded', new Date());
                break;
            }

            case 'userdata_config': {
                dispatch('Search/setSearchKeys', config, { root: true });
                break;
            }

            case 'search_config': {
                dispatch('Search/setSearchSettings', config, { root: true });
                break;
            }
        }
    }
    return true;
}
