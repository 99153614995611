import axios from 'axios';

function deploySmsBatch(payload) {
    return axios.post('marketing/sms/batch', payload);
}
function getHistory() {
    return axios.get('marketing/sms/history');
}

export default {
    deploySmsBatch,
    getHistory,
};
