<template>
    <div ref="status" class="d-flex align-center" :class="getPositionClass(position)">
        <StatusBar :state="statusState" :resize="itemState" />
    </div>
</template>

<script>
    import { useEventListener } from '@vueuse/core';
    import StatusBar from '@/components/Cases/StatusBar.vue';
    import { getSlaPercentage } from '@/utils/Design';
    import { caseStatuses, positionTypes } from '@/enums/cases.enums';
    import { getPositionClass } from '@/helpers/cases/cases.helper';

    export default {
        components: {
            StatusBar,
        },
        props: {
            item: {
                type: Object,
                default: () => ({}),
            },
            returnDateNow: {
                type: Date,
                default: () => new Date(),
            },
            position: {
                type: String,
                default: positionTypes.LEFT,
                validator(value) {
                    // Shorthand for validator
                    return Object.values(positionTypes).includes(value);
                },
            },
        },

        data() {
            return {
                height: 0,
                width: 0,
                itemState: true,
            };
        },
        computed: {
            statusState() {
                if (this.item.justSnoozed && this.item.status === caseStatuses.OPEN) {
                    return { value: 'JustSnoozed' };
                }
                if (
                    this.item.unreadMessages != 0 &&
                    this.item.responded == 0 &&
                    this.item.status === caseStatuses.OPEN
                ) {
                    return { value: 'New' };
                }
                return {
                    value: this.item.status,
                };
            },
        },

        created() {
            useEventListener(window, 'resize', this.resizeHandler);
        },

        mounted() {
            this.height = window.innerHeight;
            this.width = window.innerWidth;
        },

        methods: {
            getSlaPercentage,
            getPositionClass,

            resizeHandler() {
                this.height = window.innerHeight;
                this.width = window.innerWidth;
            },
        },
    };
</script>
