<template>
    <div v-if="item.enabled">
        <v-list-item
            v-if="!hasChildren"
            class="d-flex flex-row align-center"
            :style="style"
            @click="handleClick(item)"
            @mouseenter="mouseOver(item)"
            @mouseleave="mouseOut(true)"
        >
            <v-list-item-action class="m-auto">
                <v-icon :cy-data="item.href" :color="selectedItem === listId ? 'white' : null">
                    {{ item.icon }}
                </v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title :class="`text-body-3 font-weight-regular `">
                    {{ item.title[$i18n.locale] }}
                </v-list-item-title>
            </v-list-item-content>
            <div v-if="hasPopOuts && hovered === item.title[$i18n.locale]" style="z-index: 105">
                <v-tooltip top>
                    <template #activator="{ on, attrs }">
                        <v-btn small icon v-bind="attrs" @click.stop="dislodgeWindow(item.href)" v-on="on">
                            <v-icon :color="selectedItem === listId ? 'white' : null" small>mdi-launch</v-icon>
                        </v-btn>
                    </template>
                    {{ $t('recursiveListItem.popOut') }}
                </v-tooltip>
            </div>
        </v-list-item>
        <v-list-group
            v-else
            :value="item.active"
            :prepend-icon="currLevel == 1 ? item.icon : null"
            :sub-group="level > 0 === true"
            :style="style2"
            @click="pushRouter(item, 0)"
            @input="handleItemActive(item)"
            @mouseenter="mouseOver(item, true)"
            @mouseleave="mouseOut(true)"
        >
            <template #activator>
                <v-list-item-content :cy-text="item.title['en']">
                    <v-list-item-title>
                        {{ item.title[$i18n.locale] }}
                    </v-list-item-title>
                </v-list-item-content>
            </template>
            <RecursiveListItem
                v-for="(item, i) in item.children"
                :id="i"
                :key="i"
                :level="currLevel"
                :hasPopOuts="hasPopOuts"
                :selectedItem="selectedItem"
                :item="item"
                :isSidebarPinned="isSidebarPinned"
                :class="isSidebarPinned ? 'pl-0' : ''"
                @selected="(v) => $emit('selected', v)"
            />
        </v-list-group>
    </div>
</template>
<script>
    import { dislodgeWindow } from '@/utils/index';

    export default {
        name: 'RecursiveListItem',
        props: {
            item: {
                type: Object,
                default: null,
            },
            selectedItem: { type: String, default: '' },
            hasPopOuts: {
                type: Boolean,
                default: true,
            },
            level: {
                type: Number,
                default: 0,
            },
            id: {
                type: Number,
                default: 0,
            },
            isSidebarPinned: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                rememberActive: [],
                listClickDisabled: false,
                currLevel: 0,
                hovered: '',
                manualSelectColor: '--v-primary-lighten1',
                style: '',
                style2: '',
            };
        },
        computed: {
            hasChildren() {
                const { children } = this.item;
                return children && children.length > 0;
            },
            listPadding() {
                return this.currLevel;
            },
            listId() {
                return this.item.href;
            },
        },
        watch: {
            level: {
                immediate: true,
                handler(newVal) {
                    this.currLevel = newVal + 1;
                },
            },
            selectedItem: {
                immediate: true,
                handler(newVal) {
                    this.style = `background-color:${newVal === this.listId ? `var(${this.manualSelectColor})` : 'transparent'};
                    color:${newVal === this.listId ? `white` : 'black'};
                    margin: auto;
                    justify-content: none;
                    ${this.currLevel > 1 ? `padding-left: ${(this.listPadding * 3 + 2) * 4}px` : ''};`;

                    this.style2 = `background-color:${newVal === this.listId ? `var(${this.manualSelectColor})` : 'transparent'};
                    color:${newVal === this.listId ? `white` : 'black'};
                    margin: auto;
                    justify-content: none;
                    ${this.currLevel > 0 ? `padding-left: ${(this.listPadding - 1) * 4}px` : ''};`;
                },
            },
        },

        methods: {
            dislodgeWindow,
            handleClick(item) {
                if (this.listClickDisabled) return;
                this.pushRouter(item);
            },
            mouseOver(item, disable) {
                this.hovered = item.title[this.$i18n.locale];
                this.listClickDisabled = !!disable;
            },
            mouseOut(shouldClearHovered) {
                if (shouldClearHovered) {
                    this.hovered = null;
                }
                this.listClickDisabled = false;
            },
            pushRouter(item) {
                if (item.href) {
                    this.$router.push(item.href);
                }
            },
            handleItemActive(item) {
                item.active = !item.active;
            },
        },
    };
</script>
<style scoped>
    :deep(.v-list-item) {
        justify-content: normal !important;
    }
</style>
<i18n lang="json">
{
    "en": {
        "recursiveListItem": {
            "popOut": "Pop out"
        }
    },
    "sv": {
        "recursiveListItem": {
            "popOut": "Poppa ut"
        }
    }
}
</i18n>
