import axios from 'axios';

function getWidgetsList(q) {
    const params = {
        page: q.page,
    };

    return axios.get('/widgets-builder', {
        params,
    });
}

function getSpecificWidget(id, preview) {
    const params = {
        ID: id,
        preview,
    };

    return axios.get('/widgets-builder/widget', {
        params,
    });
}

function getWidgetQueues() {
    return axios.get('/widgets-builder/queues');
}

function createWidget(q) {
    return axios.post('/widgets-builder', {
        name: q.name,
        url: q.url,
        deployUrl: q.deployUrl,
        type: q.id,
    });
}

function deleteWidget(id) {
    return axios.delete('/widgets-builder', {
        params: {
            id,
        },
    });
}

function duplicateWidget(id) {
    return axios.post('/widgets-builder/duplicate', {
        id,
    });
}

function getTemplates() {
    return axios.get('/widgets-builder/templates');
}

function updateWidget(q) {
    return axios.put('/widgets-builder', {
        id: q.id,
        name: q.name,
        url: q.url,
        type: q.type,
        deployUrl: q.deployUrl,
    });
}

function updateWidgetObject(q) {
    return axios.put('/widgets-builder/object', {
        id: q.id,
        object: q.object,
        preview: q.preview,
    });
}

function resetWidgetObject(q) {
    return axios.put('/widgets-builder/reset', {
        id: q.id,
    });
}

function updateWidgetState(data) {
    return axios.put('/widgets-builder/state', {
        uniqueId: data.uniqueId,
        state: data.state,
    });
}

function getBookingBbox(data) {
    return axios.get('/widgets-builder/booking/bbox', {
        params: {
            target: data.target,
            lang: data.lang,
        },
    });
}

function getBookingIcon(icon) {
    const endpoint = `/static/public/widgets/chat/build/assets/images/icons/booking`;
    return axios.get(`${endpoint}/${icon}.svg`);
}

function setConnectedFaqs(data) {
    return axios.put('/widgets-builder/faq', {
        id: data.uniqueId,
        faqs: data.faqs,
    });
}

function loadSelectedFaqs(data) {
    return axios.get('/widgets-builder/faq', {
        params: {
            id: data.uniqueId,
        },
    });
}

function updateEmailSettings(data) {
    return axios.put('/widgets-builder/settings/email', data);
}

export default {
    getWidgetsList,
    getSpecificWidget,
    createWidget,
    getTemplates,
    deleteWidget,
    duplicateWidget,
    updateWidget,
    updateWidgetObject,
    resetWidgetObject,
    updateWidgetState,
    getWidgetQueues,
    getBookingBbox,
    getBookingIcon,
    setConnectedFaqs,
    loadSelectedFaqs,
    updateEmailSettings,
};
