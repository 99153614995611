// State object
const state = {
    text: '',
    icon: '',
    color: '',
};
// Getter functions
const getters = {};
// Actions
const actions = {
    setTopData({ dispatch, commit }, data) {
        commit('SET_TOP_TEXT', data.text);
        commit('SET_TOP_ICON', data.icon);
        commit('SET_TOP_COLOR', data.color);
    },

    setTopText({ commit }, text) {
        commit('SET_TOP_TEXT', text);
    },

    setTopIcon({ commit }, icon) {
        commit('SET_TOP_ICON', icon);
    },
    setTopColor({ commit }, color) {
        commit('SET_TOP_COLOR', color);
    },
};
// Mutations
const mutations = {
    SET_TOP_ICON(state, icon) {
        state.icon = icon;
    },
    SET_TOP_TEXT(state, text) {
        state.text = text;
    },
    SET_TOP_COLOR(state, color) {
        state.color = color;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
