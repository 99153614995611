module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "sv": {
        "toolbar": {
          "Undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ångra"])},
          "Redo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gör om"])},
          "Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länk"])},
          "Remove link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort länk"])},
          "Strike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genomstruken"])},
          "Underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understruken"])},
          "Italic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursiv"])},
          "Bullet List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punktlista"])},
          "Ordered List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numrerad lista"])},
          "Paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paragraf"])},
          "Heading 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubrik 1"])},
          "Heading 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubrik 2"])},
          "Heading 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubrik 3"])},
          "Bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fetstil"])},
          "Code Block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodblock"])},
          "Horizontal Rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horisontell linje"])},
          "Subscript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedsänkt"])},
          "Superscript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upphöjd"])},
          "Image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild"])},
          "Size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storlek"])},
          "FontFamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typsnitt"])},
          "Color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Färg"])},
          "Attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bifoga fil"])},
          "Table options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabell"])},
          "Insert table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infoga tabell"])},
          "Add row after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till rad efter"])},
          "Add row before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till rad före"])},
          "Remove row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort rad"])},
          "Add column after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till kolumn efter"])},
          "Add column before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till kolumn före"])},
          "Remove column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort kolumn"])},
          "Delete table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort tabell"])},
          "Merge or split cells": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammanfoga eller dela celler"])}
        }
      },
      "en": {
        "toolbar": {
          "Undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undo"])},
          "Redo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redo"])},
          "Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
          "Remove link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove link"])},
          "Strike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strike"])},
          "Underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underline"])},
          "Italic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italic"])},
          "Bullet List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bullet List"])},
          "Ordered List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordered List"])},
          "Paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paragraph"])},
          "Heading 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heading 1"])},
          "Heading 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heading 2"])},
          "Heading 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heading 3"])},
          "Bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bold"])},
          "Code Block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code Block"])},
          "Horizontal Rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal Rule"])},
          "Subscript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscript"])},
          "Superscript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superscript"])},
          "Image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
          "Size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
          "FontFamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FontFamily"])},
          "Color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
          "Attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment"])},
          "Table options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table options"])},
          "Insert table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert table"])},
          "Add row after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add row after"])},
          "Add row before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add row before"])},
          "Remove row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove row"])},
          "Add column after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add column after"])},
          "Add column before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add column before"])},
          "Remove column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove column"])},
          "Delete table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete table"])},
          "Merge or split cells": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merge or split cells"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"sv":{"toolbar":{"Undo":"Ångra","Redo":"Gör om","Link":"Länk","Remove link":"Ta bort länk","Strike":"Genomstruken","Underline":"Understruken","Italic":"Kursiv","Bullet List":"Punktlista","Ordered List":"Numrerad lista","Paragraph":"Paragraf","Heading 1":"Rubrik 1","Heading 2":"Rubrik 2","Heading 3":"Rubrik 3","Bold":"Fetstil","Code Block":"Kodblock","Horizontal Rule":"Horisontell linje","Subscript":"Nedsänkt","Superscript":"Upphöjd","Image":"Bild","Size":"Storlek","FontFamily":"Typsnitt","Color":"Färg","Attachment":"Bifoga fil","Table options":"Tabell","Insert table":"Infoga tabell","Add row after":"Lägg till rad efter","Add row before":"Lägg till rad före","Remove row":"Ta bort rad","Add column after":"Lägg till kolumn efter","Add column before":"Lägg till kolumn före","Remove column":"Ta bort kolumn","Delete table":"Ta bort tabell","Merge or split cells":"Sammanfoga eller dela celler"}},"en":{"toolbar":{"Undo":"Undo","Redo":"Redo","Link":"Link","Remove link":"Remove link","Strike":"Strike","Underline":"Underline","Italic":"Italic","Bullet List":"Bullet List","Ordered List":"Ordered List","Paragraph":"Paragraph","Heading 1":"Heading 1","Heading 2":"Heading 2","Heading 3":"Heading 3","Bold":"Bold","Code Block":"Code Block","Horizontal Rule":"Horizontal Rule","Subscript":"Subscript","Superscript":"Superscript","Image":"Image","Size":"Size","FontFamily":"FontFamily","Color":"Color","Attachment":"Attachment","Table options":"Table options","Insert table":"Insert table","Add row after":"Add row after","Add row before":"Add row before","Remove row":"Remove row","Add column after":"Add column after","Add column before":"Add column before","Remove column":"Remove column","Delete table":"Delete table","Merge or split cells":"Merge or split cells"}}}')
  delete Component.options._Ctor
  
}
