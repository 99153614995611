module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "start": {
          "cases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cases 24h"])},
          "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])}
        }
      },
      "sv": {
        "start": {
          "cases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ärenden 24h"])},
          "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välkommen"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"start":{"cases":"Cases 24h","welcome":"Welcome"}},"sv":{"start":{"cases":"Ärenden 24h","welcome":"Välkommen"}}}')
  delete Component.options._Ctor
  
}
