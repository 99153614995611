import activity from '@/api/activity/activity';

// State object
const state = {
    caseActivity: {},
    caseActivityStatistics: {},
};

// Actions
const actions = {
    setCaseActivityStatistics({ commit }, caseActivityStatistics) {
        commit('SET_CASE_ACTIVITY_STATISTICS', caseActivityStatistics);
    },

    async getCaseActivity({ commit }, { caseId, limit, offset }) {
        try {
            const response = await activity.getCaseActivity(caseId, limit, offset);
            if (offset > 0) {
                commit('SET_CASE_ACTIVITY', {
                    ...state.caseActivity,
                    ...response.data,
                    activities: state.caseActivity.activities.concat(response.data.activities),
                });
            } else {
                commit('SET_CASE_ACTIVITY', response.data);
            }
        } catch (error) {
            console.error(error);
        }
    },

    async getCaseActivityStatistics({ commit }, { caseId }) {
        try {
            const response = await activity.getCaseActivityStatistics(caseId);
            commit('SET_CASE_ACTIVITY_STATISTICS', response.data);
        } catch (error) {
            console.error(error);
        }
    },

    setCaseActivity({ commit }, caseActivity) {
        commit('SET_CASE_ACTIVITY', caseActivity);
    },
};
// Mutations
const mutations = {
    SET_CASE_ACTIVITY(state, caseActivity) {
        state.caseActivity = caseActivity;
    },

    SET_CASE_ACTIVITY_STATISTICS(state, caseActivityStatistics) {
        state.caseActivityStatistics = caseActivityStatistics;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
