module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "caseCommentsTabs": {
          "closeCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do You want to close the case?"])}
        }
      },
      "sv": {
        "caseCommentsTabs": {
          "closeCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vill du stänga ärendet?"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"caseCommentsTabs":{"closeCase":"Do You want to close the case?"}},"sv":{"caseCommentsTabs":{"closeCase":"Vill du stänga ärendet?"}}}')
  delete Component.options._Ctor
  
}
