function getCallExceptionObject(exception) {
    switch (exception) {
        case 'callbackUnavailable': {
            return {
                titleKey: 'callsHelper.callbackUnavailableTitle',
                messageKey: 'callsHelper.callbackUnavailableMessage',
            };
        }
        default: {
            console.log('getCallExceptionObject - Unknown exception', exception);
            return null;
        }
    }
}

export default {
    getCallExceptionObject,
};
