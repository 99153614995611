import axios from 'axios';

function getLeaderboard({ from, to, types, search, groups, source, fields, sorting }) {
    return axios.get(`/leaderboard`, {
        params: {
            source,
            from,
            to,
            types,
            search,
            sorting,
            groups: groups.join(','),
            fields: fields.join(','),
        },
    });
}

function getIndividualLeaderboard({ userId, source, from, to, search, groups }) {
    return axios.get(`/leaderboard/${userId}`, {
        params: {
            source,
            from,
            to,
            search,
            groups: groups.join(','),
        },
    });
}

function getLeaderboardSources() {
    return axios.get(`/leaderboard/sources`);
}

function getLeaderboardInterval({ source }) {
    return axios.get(`/leaderboard/interval`, {
        params: {
            source,
        },
    });
}

export default {
    getLeaderboard,
    getIndividualLeaderboard,
    getLeaderboardSources,
    getLeaderboardInterval,
};
