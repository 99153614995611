import axios from 'axios';

function getNumberToCall({ caseId, phoneNumber, userId, commentId, type }) {
    return axios.get(`/queues/call/${phoneNumber}`, { params: { caseId, userId, commentId, type } });
}

function getNumberToTransfer({ phoneNumber, userId }) {
    return axios.get(`/queues/transfer/${phoneNumber}`, { params: { userId } });
}

export default {
    getNumberToCall,
    getNumberToTransfer,
};
