<template>
    <main class="nav-wrapper">
        <CommunicationCreator :key="selectedCaseId" />
        <ClientContactBarButton v-for="item in communicationTypes" :key="item.dialogId" :item="item" />
    </main>
</template>

<script>
    import { mapState } from 'vuex';
    import CommunicationCreator from '@/components/Cases/ClientCommunicationCreator/Container.vue';
    import ClientContactBarButton from './ClientContactBarButton.vue';
    import { communicationTypes } from '../../templates/cases.template';

    export default {
        name: 'ClientContactBar',
        components: {
            ClientContactBarButton,
            CommunicationCreator,
        },
        computed: {
            ...mapState({
                communicationTypesConfig: (state) => state.System.userSettings.clientCard.communicationTypes,
                selectedCaseId: (state) => state.Cases.selectedCaseId,
            }),

            communicationTypes() {
                return communicationTypes.map((type) => {
                    return { ...type, active: this.communicationTypesConfig[type.value].active };
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .nav-wrapper {
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        > * {
            flex-grow: 1;
        }
    }
</style>
