export const channelTypes = Object.freeze({
    EMAIL: 'email',
    CALL: 'call',
    CHAT: 'chat',
    SMS: 'sms',
    SOCIAL: 'social',
    CALLBACK: 'callback',
    FORM: 'form',
});

export default {
    channelTypes,
};
