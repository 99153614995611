import axios from 'axios';

function createRole(payload) {
    return axios.post('v2/userroles', payload);
}
function deleteRole(payload) {
    return axios.delete(`userroles/${payload}`);
}
function addMembersToRole(payload) {
    return axios.put(`userroles/members/${payload.roleId}`, payload);
}
function saveRoleDescription(payload) {
    return axios.put(`userroles/${payload.roleId}/description`, payload);
}
function deleteUserFromRole(payload) {
    return axios.delete(`userroles/member/${payload.user.RoleMembershipId}`, { data: payload });
}
function deleteAllUsersFromRole(roleId) {
    return axios.delete(`userroles/all/members/${roleId}`);
}
function addRoleOwnership(payload) {
    return axios.post('userroles/ownership', payload);
}
function deleteRoleOwnership(payload) {
    return axios.delete(`userroles/ownership/${payload.roleId}`, { data: payload });
}
function getAllRoles() {
    return axios.get('userroles');
}

function getRolesByUserId(userId) {
    return axios.get('/v2/userroles/users/' + userId);
}

/**
 * @param {number} userId
 * @param {number} roleId
 */
function loginToRole(userId, roleId) {
    return axios.put(`/userroles/${roleId}/login/${userId}`);
}

/**
 * @param {number} userId
 * @param {number} roleId
 */
function logoutOfRole(userId, roleId) {
    return axios.put(`/userroles/${roleId}/logout/${userId}`);
}

function roleOpeningHours(payload) {
    return axios.put('userroles/openhours', payload);
}

function getRoleMembers(payload) {
    return axios.get(`/userroles/members/${payload.roleId}?page=${payload.page}&search=${payload.search}`);
}

function getAvailableUsers(payload) {
    return axios.get(`/userroles/available/users/${payload.roleId}?page=${payload.page}&search=${payload.search}`);
}

export default {
    createRole,
    getAllRoles,
    addMembersToRole,
    saveRoleDescription,
    deleteUserFromRole,
    deleteAllUsersFromRole,
    addRoleOwnership,
    deleteRoleOwnership,
    deleteRole,
    getRolesByUserId,
    roleOpeningHours,
    getRoleMembers,
    getAvailableUsers,
    loginToRole,
    logoutOfRole,
};
