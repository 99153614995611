const emailRegEx = /^[^ @]+@[^ @]+\.[^ @]+$/; // (no space or @)@(no spcae or @).(no space or @)
const domainRegEx = /^[^ @]+\.[^ @]+$/; // (no space or @).(no space or @)

function validateEmail(email) {
    return emailRegEx.test(email);
}

function validateDomain(domain) {
    return domainRegEx.test(domain);
}

module.exports = {
    emailRegEx,
    domainRegEx,
    validateEmail,
    validateDomain,
};
