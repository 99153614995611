import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('OpenExternal',{attrs:{"href":_vm.item.href},scopedSlots:_vm._u([{key:"default",fn:function({ onClick }){return [_c(VListItem,{staticClass:"item",on:{"click":onClick}},[_c(VListItemAction,{staticClass:"m-auto"},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.item.icon)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,{class:`text-body-3 font-weight-regular `},[_vm._v(" "+_vm._s(_vm.item.title[_vm.$i18n.locale])+" ")])],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }