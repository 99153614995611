module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "timeLine": {
          "dateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date / Time"])},
          "emptyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No cases"])},
          "emptyListHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No cases"])}
        }
      },
      "sv": {
        "timeLine": {
          "dateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum / Tid"])},
          "emptyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga ärenden"])},
          "emptyListHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga ärenden"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"timeLine":{"dateTime":"Date / Time","emptyList":"No cases","emptyListHeader":"No cases"}},"sv":{"timeLine":{"dateTime":"Datum / Tid","emptyList":"Inga ärenden","emptyListHeader":"Inga ärenden"}}}')
  delete Component.options._Ctor
  
}
