<template>
    <v-tooltip bottom>
        <template #activator="{ on, attrs }">
            <div v-if="!callback" class="container" :class="{ center: alignCenter }" v-bind="attrs" v-on="on">
                <SLAIndicator :item="item" :size="listLayout == 'big' ? 20 : 16" />
                <SLALabel :item="item" :size="listLayout == 'big' ? 12 : 10" />
            </div>
            <div v-else class="container" :class="{ center: alignCenter }" v-bind="attrs" v-on="on">
                <span>
                    {{ slaTime }}
                </span>
            </div>
        </template>
        <span v-if="noSLA">
            {{ $t('sla.noSla') }}
        </span>
        <span v-else-if="caseIsInQueue">
            {{ $t('sla.inQueue', { time: lastIncoming }) }}
        </span>
        <span v-else-if="caseIsSnoozed">
            {{ $t('sla.snoozed', { time: slaTime }) }}
        </span>
        <span v-else-if="caseIsClosed">
            {{ $t('sla.closed') }}
        </span>
        <span v-else>
            {{ $t('sla.slaTime', { time: slaTime }) }}
            <br />
            {{ $t('sla.slaLength', { time: slaLength }) }}
        </span>
    </v-tooltip>
</template>

<script>
    import { mapState } from 'vuex';
    import { format } from 'date-fns';
    import { getDay } from '@/utils/DateFormatter';
    import { getServiceTime } from '@/utils/Design';
    import { caseStatuses } from '@/enums/cases.enums';
    import SLAIndicator from '@/components/Cases/List/SLA/SLAIndicator.vue';
    import SLALabel from '@/components/Cases/List/SLA/SLALabel.vue';

    export default {
        components: {
            SLAIndicator,
            SLALabel,
        },

        props: {
            item: {
                type: Object,
                default: () => ({}),
            },
            alignCenter: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapState({
                callback: (state) => state.System.userSettings.cases.callbackView.active,
                listLayout: (state) => state.Cases.listLayout.value,
                dateNowRealTime: (state) => state.Cases.dateNow,
            }),
            caseIsSnoozed() {
                return this.item.status === caseStatuses.SNOOZED;
            },
            caseIsInQueue() {
                return this.item.status === caseStatuses.IN_QUEUE;
            },
            caseIsClosed() {
                return this.item.status === caseStatuses.CLOSED;
            },
            serviceTime() {
                return this.getServiceTime(this.item.sla, this.item.lastIncoming, 'text', this.dateNowRealTime);
            },
            slaTime() {
                const formattedSla = format(new Date(this.item.sla), 'yyyy-MM-dd HH:mm:ss');
                return getDay(formattedSla);
            },
            slaLength() {
                return this.getServiceTime(
                    this.item.sla,
                    this.item.lastIncoming,
                    'text',
                    new Date(this.item.lastIncoming).getTime()
                );
            },
            noSLA() {
                return this.item.sla <= this.item.lastIncoming || this.item.status === caseStatuses.DELETED;
            },
            lastIncoming() {
                return format(new Date(this.item.lastIncoming), 'yyyy-MM-dd HH:mm:ss');
            },
        },

        methods: {
            getServiceTime,
            getDay,
        },
    };
</script>

<style lang="scss" scoped>
    .container {
        display: flex;
        flex-direction: row;
    }
    .container.center {
        justify-content: center;
    }
</style>
<i18n lang="json">
{
    "en": {
        "sla": {
            "noSla": "No SLA",
            "slaTime": "Time: {time}",
            "slaLength": "Length: {time}",
            "inQueue": "Last incoming: {time}",
            "snoozed": "Snoozed until: {time}",
            "closed": "Case closed"
        }
    },
    "sv": {
        "sla": {
            "noSla": "Ingen SLA",
            "slaTime": "Tid: {time}",
            "slaLength": "Längd: {time}",
            "inQueue": "Senast mottaget: {time}",
            "snoozed": "Bevakad till: {time}",
            "closed": "Ärendet stängt"
        }
    }
}
</i18n>
