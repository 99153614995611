module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "comment": {
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To:"])},
          "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing"])},
          "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming"])},
          "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending"])}
        },
        "calls": {
          "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
          "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call"])},
          "recordingStartedAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Recording started at: ", _interpolate(_named("created"))])},
          "inbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbound call"])},
          "outbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outbound call"])}
        }
      },
      "sv": {
        "comment": {
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Till:"])},
          "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utgående"])},
          "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkommande"])},
          "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skickar"])}
        },
        "calls": {
          "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktioner"])},
          "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonsamtal"])},
          "recordingStartedAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Inspelning startade: ", _interpolate(_named("created"))])},
          "inbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkommande samtal"])},
          "outbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utgående samtal"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"comment":{"to":"To:","outgoing":"Outgoing","incoming":"Incoming","sending":"Sending"},"calls":{"actions":"Actions","call":"Call","recordingStartedAt":"Recording started at: {created}","inbound":"Inbound call","outbound":"Outbound call"}},"sv":{"comment":{"to":"Till:","outgoing":"Utgående","incoming":"Inkommande","sending":"Skickar"},"calls":{"actions":"Funktioner","call":"Telefonsamtal","recordingStartedAt":"Inspelning startade: {created}","inbound":"Inkommande samtal","outbound":"Utgående samtal"}}}')
  delete Component.options._Ctor
  
}
