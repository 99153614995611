/**
 * Automatically imports all the modules and exports as a single module object
 */

const requireModule = require.context('.', true, /\.store\.js$/);
const modules = {};

function createModulesFromContext(context) {
    for (const filename of context.keys()) {
        // Ignore root index file
        if (filename === './index.js') continue;

        // create the module path from filename
        const modulePath = filename.replaceAll(/(\.\/|\.store\.js)/g, '');

        // Recursively get the namespace of the module, considering any subdirectories
        const moduleNamePath = modulePath.split('/').map((name) => name.replace(/^\w/, (c) => c.toUpperCase()));

        // Get module name and module configuration
        const moduleName = moduleNamePath.pop().replace(/^\w/, (c) => c.toUpperCase());
        const moduleConfig = context(filename).default || context(filename);

        // Apply module configuration to the correct namespace, creating namespaces as necessary
        let parentModule = modules;
        for (const name of moduleNamePath) {
            if (!parentModule[name]) {
                parentModule[name] = { namespaced: true, modules: {} };
            }
            parentModule = parentModule[name].modules;
        }
        parentModule[moduleName] = moduleConfig;
    }

    return modules;
}
export default createModulesFromContext(requireModule);
