module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "listPagination": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cases per page"])},
          "nofCasesPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of cases per page"])},
          "prevPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous page"])},
          "gotoPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to page"])},
          "nextPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next page"])},
          "noCases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No cases"])},
          "outOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["out of"])}
        },
        "clientCard": {
          "previousCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous case (CTRL + ALT + LEFT)"])},
          "nextCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next case (CTRL + ALT + RIGHT)"])},
          "couldNotOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not open case"])}
        }
      },
      "sv": {
        "listPagination": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ärenden per sida"])},
          "nofCasesPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal ärenden per sida"])},
          "prevPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förgående sida"])},
          "gotoPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till sida"])},
          "nextPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nästa sida"])},
          "noCases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga ärenden"])},
          "outOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["av"])}
        },
        "clientCard": {
          "previousCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Föregående ärende  (CTRL + ALT + VÄNSTER)"])},
          "nextCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nästa ärende  (CTRL + ALT + HÖGER)"])},
          "couldNotOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde inte öppna ärende"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"listPagination":{"header":"Cases per page","nofCasesPage":"Number of cases per page","prevPage":"Previous page","gotoPage":"Go to page","nextPage":"Next page","noCases":"No cases","outOf":"out of"},"clientCard":{"previousCase":"Previous case (CTRL + ALT + LEFT)","nextCase":"Next case (CTRL + ALT + RIGHT)","couldNotOpen":"Could not open case"}},"sv":{"listPagination":{"header":"Ärenden per sida","nofCasesPage":"Antal ärenden per sida","prevPage":"Förgående sida","gotoPage":"Gå till sida","nextPage":"Nästa sida","noCases":"Inga ärenden","outOf":"av"},"clientCard":{"previousCase":"Föregående ärende  (CTRL + ALT + VÄNSTER)","nextCase":"Nästa ärende  (CTRL + ALT + HÖGER)","couldNotOpen":"Kunde inte öppna ärende"}}}')
  delete Component.options._Ctor
  
}
