module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "comment": {
          "changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changed "])},
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" to "])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" from "])},
          "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System"])},
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
          "notAssigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not assigned"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
          "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified"])},
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more"])},
          "deletedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted user"])},
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid date"])}
        },
        "types": {
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
          "userId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned"])},
          "queueId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queue"])},
          "sla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
          "textField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order number"])},
          "socialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BankId"])}
        }
      },
      "sv": {
        "comment": {
          "changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändrade "])},
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" till "])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" från "])},
          "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System"])},
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen"])},
          "notAssigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ej tilldelad"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["och"])},
          "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifierad"])},
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fler"])},
          "deletedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raderad användare"])},
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogiltigt datum"])}
        },
        "types": {
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
          "userId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilldelad"])},
          "queueId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kö"])},
          "sla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
          "textField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordernummer"])},
          "socialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BankId"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"comment":{"changed":"Changed ","to":" to ","from":" from ","system":"System","empty":"None","notAssigned":"Not assigned","and":"and","verified":"Verified","more":"more","deletedUser":"Deleted user","invalid":"Invalid date"},"types":{"categories":"Categories","status":"Status","userId":"Assigned","queueId":"Queue","sla":"SLA","textField":"Order number","socialSecurityNumber":"BankId"}},"sv":{"comment":{"changed":"Ändrade ","to":" till ","from":" från ","system":"System","empty":"Ingen","notAssigned":"Ej tilldelad","and":"och","verified":"Verifierad","more":"fler","deletedUser":"Raderad användare","invalid":"Ogiltigt datum"},"types":{"categories":"Kategorier","status":"Status","userId":"Tilldelad","queueId":"Kö","sla":"SLA","textField":"Ordernummer","socialSecurityNumber":"BankId"}}}')
  delete Component.options._Ctor
  
}
