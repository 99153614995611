<template>
    <div>
        <v-tooltip bottom>
            <template #activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                    <action-button color="white" :hoverEffect="true" @click="getNextCase">
                        <div>{{ $t('button.nextCase') }}</div>
                    </action-button>
                </div>
            </template>

            <span>{{ $t('button.nextCaseTooltip') }}</span>
        </v-tooltip>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';

    export default {
        methods: {
            ...mapActions({
                getNextCase: 'Queues/getNextCase',
            }),
        },
    };
</script>
<style scoped lang="scss"></style>
<i18n lang="json">
{
    "en": {
        "button": {
            "nextCase": "Take next case",
            "nextCaseTooltip": "Take the next case in your logged in queues that use cherry picking"
        }
    },
    "sv": {
        "button": {
            "nextCase": "Ta nästa ärende",
            "nextCaseTooltip": "Ta nästa ärende i dina inloggade köer som använder cherry picking"
        }
    }
}
</i18n>
