export function getAndSortAllQueues(statistics) {
    const allQueues = [];
    for (const item of statistics) {
        if (!item.queueNames) continue;
        for (const queue of item.queueNames) {
            const object = { queueId: queue.id, channelType: item.type };
            if (allQueues.find((q) => q.queueId === object.queueId && q.channelType === object.channelType)) continue;
            allQueues.push(object);
        }
    }
    allQueues.sort((a, b) => a.queueId - b.queueId);
    return allQueues;
}

export function processUpdatedQueues(currentStatistics, payload) {
    const statistics = structuredClone(currentStatistics);
    for (const card of payload) {
        const index = statistics.findIndex((c) => c.id == card.id);
        statistics[index].data = card.data;
        statistics[index].ownStatistics = card.ownStatistics;
    }
    return statistics;
}

export function getTickingLongestWaitings(statistics) {
    const timers = [];
    for (const [i, card] of statistics.entries()) {
        for (let j = 0; j < card.ownStatistics.length; j++) {
            const queue = card.ownStatistics[j];
            if (queue.numberInQueue == 0) continue;
            timers.push({ type: 'queue', i, j });
        }
        if (!card.data || card.data.numberInQueue == 0) continue;
        timers.push({ type: 'card', i });
    }
    return timers;
}

export function calculateNextPage(currentPage, step, totalPages) {
    let nextPage = currentPage + step;
    if (nextPage < 0) {
        nextPage = totalPages;
    } else if (nextPage > totalPages) {
        nextPage = 0;
    }
    return nextPage;
}

export function calculatePageToSet(statistics, id, length) {
    if (length < 2) return null;
    for (const [i, statistic] of statistics.entries()) {
        if (statistic.id == id) {
            return i + 1;
        }
    }
    return null;
}

export function prepareDelayMenuOptions(options) {
    const menuOptions = structuredClone(options);
    for (let i = 1; i < 5; i++) {
        menuOptions.push({ name: i * 2 + ' s', value: i * 2000, icon: 'mdi-timer-sand' });
    }
    for (let i = 1; i < 10; i++) {
        menuOptions.push({ name: i * 10 + ' s', value: i * 10000, icon: 'mdi-timer-sand' });
    }
    return menuOptions;
}

export function formatUsersData(data) {
    for (const card of data) {
        card.users = card.users.map((user) => {
            return {
                alerting: user.Alerting,
                name: user.Name,
                presenceId: user.PresenceId,
                quarantine: user.Quarantine,
                userId: user.UserId,
                loggedIn: user.LoggedIn,
                channelActive: user.ChannelActive,
                caseActivityStatus: user.CaseActivityStatus || '',
                activeCaseId: user.ActiveCaseId || '',
                statusText: user.StatusText,
                currentComms: user.currentComms,
                teamsAvailability: user.TeamsAvailability,
            };
        });
    }
    return data;
}

export function getAndSortVisualUsers(users) {
    const visualUserIds = [];
    for (const queue of users) {
        for (const user of queue.users) {
            // check if already exists in allUsersIds and push if not
            if (!visualUserIds.includes(user.userId)) {
                visualUserIds.push(user.userId);
            }
        }
    }
    visualUserIds.sort((a, b) => a - b);
    return visualUserIds;
}

export function getAndSortAllUserIds(users) {
    let allUserIds = [];
    for (const queue of users) {
        allUserIds.push(queue.userIds);
    }
    allUserIds = allUserIds.flat().filter((v, i, a) => a.indexOf(v) === i);
    allUserIds.sort((a, b) => a - b);
    return allUserIds;
}

export function processChangedActivities(changes, wallboardUsers) {
    const users = structuredClone(wallboardUsers);
    for (const queue of users) {
        for (const user of queue.users) {
            for (const change of changes) {
                if (user.userId !== change.UserId) continue;

                user.caseActivityStatus = change.CaseType || '';
                user.activeCaseId = change.CaseId || '';
            }
        }
    }
    return users;
}

export default {
    getAndSortAllQueues,
    processUpdatedQueues,
    getTickingLongestWaitings,
    calculateNextPage,
    calculatePageToSet,
    prepareDelayMenuOptions,
    formatUsersData,
    getAndSortVisualUsers,
    getAndSortAllUserIds,
    processChangedActivities,
};
