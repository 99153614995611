<template>
    <div
        v-if="currentState !== 'error' && currentState !== 'loading'"
        class="d-flex flex-row justify-center align-center headerContainer"
    >
        <div class="d-flex flex-row align-center justify-center item-container">
            <div class="mr-3">
                <v-tooltip v-if="showEditButton && hasEditRights" bottom>
                    <template #activator="{ on, attrs }">
                        <v-btn
                            small
                            fab
                            elevation="0"
                            color="transparent"
                            v-bind="attrs"
                            v-on="on"
                            @click="activateEditMode"
                            @mouseleave="(e) => e.target.blur()"
                        >
                            <v-icon size="20" color="white">mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ `${$t('dynamicStatistics.edit')} ${currentMode}` }}</span>
                </v-tooltip>
            </div>
            <div class="dynamic-container" :style="containerStyle">
                <Transition name="slide-fade" mode="out-in">
                    <div v-if="subState != 'edit'" :key="1" class="d-flex flex-row stat-item">
                        <div
                            v-if="isEmpty"
                            class="empty-msg d-flex justify-center align-center text-body-1"
                            :class="{ 'no-pointer': global && !hasEditRights }"
                            @click="activateEditMode"
                        >
                            {{ hasEditRights ? $t('dynamicStatistics.clickToAdd') : $t('dynamicStatistics.noItems') }}
                        </div>
                        <DynamicStatisticsItem
                            v-for="(item, index) in nonEmptyItems"
                            v-else
                            :key="item.id"
                            :item="item"
                            :indexVal="index"
                        />
                    </div>
                    <div v-else :key="2" class="d-flex flex-column align-center justify-center">
                        <Container
                            class="d-flex flex-row stat-item"
                            drag-class="opacity-ghost"
                            drop-class="opacity-ghost-drop"
                            orientation="horizontal"
                            @drop="onDrop"
                        >
                            <Draggable v-for="(item, index) in data" :key="item.id">
                                <DynamicStatisticsItem class="draggable-item" :item="item" :indexVal="index" />
                            </Draggable>
                        </Container>
                    </div>
                </Transition>
            </div>

            <div class="ml-3">
                <v-tooltip v-if="isFiltersOpen || subState === 'edit'" bottom>
                    <template #activator="{ on, attrs }">
                        <v-btn
                            small
                            fab
                            elevation="0"
                            color="transparent"
                            v-bind="attrs"
                            v-on="on"
                            @click="toggleGlobal"
                            @mouseleave="(e) => e.target.blur()"
                        >
                            <v-icon size="20" color="white">{{ currentStatsIcon }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ `${$t('dynamicStatistics.switchTo')} ${otherMode}` }}</span>
                </v-tooltip>
            </div>
        </div>
    </div>

    <div v-else-if="currentState == 'loading'" class="d-flex flex-row justify-center align-center py-2 headerContainer">
        <v-progress-circular indeterminate color="primary" />
    </div>
</template>

<script>
    import { mapActions, mapState, mapGetters } from 'vuex';
    import { Container, Draggable } from 'vue-smooth-dnd';
    import { applyDrag } from '@/utils/DragNDrop';
    import DynamicStatisticsItem from '@/components/DynamicStatistics/DynamicStatisticsItem.vue';
    import { states } from '@/enums/states.enums';
    import { userRoles } from '@/enums/roles.enums';

    export default {
        components: {
            Container,
            Draggable,
            DynamicStatisticsItem,
        },
        data() {
            return {
                debounceTimer: null,
                caseFiltersStateLoaded: false,
            };
        },
        computed: {
            hasEditRights() {
                return this.isAdmin || !this.global;
            },
            showEditButton() {
                if (this.global) {
                    // Only admin is allowed to edit which global statistics to show since it affects all users
                    return this.isAdmin && (this.isFiltersOpen || this.subState === 'edit');
                }
                return this.isFiltersOpen || this.subState === 'edit';
            },
            containerStyle() {
                if (!this.data) {
                    return {};
                }
                const isEdit = this.subState === 'edit';
                const itemLength = isEdit ? this.data.length : this.nonEmptyItems.length;
                const gap = 8;
                const itemWidth = 100;
                const totalWidth = itemLength * itemWidth + (itemLength - 1) * gap;
                const totalWidthPx = `${totalWidth}px`;
                return { minWidth: totalWidthPx, maxWidth: totalWidthPx };
            },
            items: {
                set(items) {
                    this.$store.dispatch('DynamicStatistics/updateData', items);
                },
                get() {
                    return this.$store.state.DynamicStatistics.data;
                },
            },
            ...mapState({
                data: (state) => state.DynamicStatistics.data,
                currentState: (state) => state.DynamicStatistics.currentState,
                subState: (state) => state.DynamicStatistics.subState.value,
                caseFiltersState: (state) => state.Cases.caseFiltersState,
                filtersLoaded: (state) => state.Cases.filtersLoaded,
                global: (state) => state.DynamicStatistics.global,
                roles: (state) => state.Auth.roles,
                filter: (state) => state.Header.filter,
            }),
            ...mapGetters({
                isEmpty: 'DynamicStatistics/dataItemsEmpty',
            }),

            isFiltersOpen() {
                return this.filter === states.OPEN;
            },
            isAdmin() {
                return this.roles.includes(userRoles.SUPER_ADMIN);
            },
            currentMode() {
                return this.global
                    ? this.$t('dynamicStatistics.globalStats')
                    : this.$t('dynamicStatistics.personalStats');
            },
            otherMode() {
                return this.global
                    ? this.$t('dynamicStatistics.personalStats')
                    : this.$t('dynamicStatistics.globalStats');
            },

            currentStatsIcon() {
                return this.global ? 'mdi-earth' : 'mdi-account';
            },
            nonEmptyItems() {
                if (this.data) {
                    return this.data.filter((item) => item.status === 'fulfilled');
                }
                return null;
            },
            editBtnIconColor() {
                return this.subState === 'edit' ? 'white' : 'var(--v-gray2-base) !important';
            },
        },
        watch: {
            isFiltersOpen(val) {
                if (!val) {
                    this.activateEdit({ isAdmin: this.isAdmin, state: false });
                }
            },
            caseFiltersState: {
                handler() {
                    if (this.filtersLoaded) {
                        this.$store.dispatch('DynamicStatistics/getDynamicStatistics');
                    }
                },
                deep: true,
            },
        },

        beforeDestroy() {
            this.$store.dispatch('DynamicStatistics/unSubscribeToWallboard');
        },
        methods: {
            ...mapActions({
                activateEdit: 'DynamicStatistics/activateEdit',
            }),

            onDrop(dropResult) {
                this.items = applyDrag(this.items, dropResult);
                this.$store.dispatch('DynamicStatistics/updateOrder', this.items);
            },
            async toggleGlobal() {
                await this.$store.dispatch('DynamicStatistics/setGlobal', !this.global);
            },
            activateEditMode() {
                this.$store.dispatch('DynamicStatistics/activateEdit', {
                    isAdmin: this.isAdmin,
                    state: this.subState !== 'edit',
                });
            },
        },
    };
</script>

<style scoped>
    .headerContainer {
        gap: 0.5em;
        flex: 1;
        min-width: 605px;
    }
    .stat-item {
        gap: 0.5em;
    }

    .empty-msg {
        width: 500px;
        background: #8080800d;
        border-radius: 6px;
        color: #00000066;
        height: 45px;
        cursor: pointer;
    }

    .no-pointer {
        cursor: default;
    }

    .item-container {
        flex: 1;
    }

    .dynamic-container {
        flex: 1;
    }
</style>

<i18n lang="json">
{
    "en": {
        "dynamicStatistics": {
            "edit": "Edit",
            "switchTo": "Switch to",
            "personalStats": "Personal Statistics",
            "globalStats": "Global Statistics",
            "clickToAdd": "Click to add items",
            "noItems": "No items to show"
        }
    },
    "sv": {
        "dynamicStatistics": {
            "edit": "Redigera",
            "switchTo": "Växla till",
            "personalStats": "personlig statistik",
            "globalStats": "global statistik",
            "clickToAdd": "Klicka för att lägga till objekt",
            "noItems": "Inga objekt att visa"
        }
    }
}
</i18n>
