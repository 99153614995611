import { internalNumberRegex } from '../utils/pbx.utils';
import i18n from '../i18n';

export const pbxIntegrationId = 16;

const regex = /^(07|08)\d{7,8}$/;
// Makes a 07 and 08 number into a 467 number
export function parseNumber(number) {
    if (!number) {
        return '';
    }
    if (internalNumberRegex.test(number)) {
        return number;
    }
    if (regex.test(number)) {
        number = '46' + number.slice(1);
        return number;
    }
    if (number[0] === '+') {
        number = number.slice(1);
    }
    return number;
}
/**
 * Finds a media object with the specified label or, if not found, a default media object of the specified type.
 * @param {string} label - The label of the media device to find.
 * @param {string} type - The type of the media device to find (e.g. 'audioinput', 'audiooutput', 'videoinput').
 * @returns {Promise<MediaDeviceInfo>} - The media device object that matches the specified label or type, or default if not found.
 */
export async function findMediaObject(label, type) {
    const raw = await navigator.mediaDevices.enumerateDevices();
    const device = raw.find((device) => device.label === label);
    if (!device) {
        return raw.find((device) => device.deviceId === 'default' && device.kind === type);
    }
    return device;
}

export function errorToMessage(statusCode) {
    if (i18n.te('callStates.' + statusCode)) {
        return i18n.t('callStates.' + statusCode);
    }
    return i18n.t('callStates.error', { statusCode });
}
