import widgets from '@/api/widgets/widgets';
import router from '@/router';
import i18n from '../../i18n';

const state = {
    widgetQueues: [],

    widgetBuilderState: false,

    specificLoading: false,
    widgetActiveByOtherUser: false,
    widgetsActivity: [],
    templates: [],
    specificWidget: {},

    widgetsList: [],
    numberOfWidgets: 0,
    page: 1,

    widget: 1,
    widgetState: Object.freeze({
        INITIATED: 0,
        LOADING: 1,
        LOADED: 2,
    }),

    loadingValue: 0,

    zoom: JSON.parse(localStorage.getItem('__widgetZoom')) || 0.75,
    zoomState: Object.freeze({
        25: 0.25,
        50: 0.5,
        75: 0.75,
        100: 1,
        125: 1.25,
        150: 1.5,
        175: 1.75,
        200: 2,
    }),

    device: JSON.parse(localStorage.getItem('__deviceState')) || 0,
    deviceState: Object.freeze({
        DESKTOP: 0,
        TABLET: 1,
        MOBILE: 2,
        CUSTOM: 3,
    }),

    deviceCustomData: JSON.parse(localStorage.getItem('__deviceCustomData')) || {},

    surfacePosition: JSON.parse(localStorage.getItem('__widgetSurfacePosition')) || {
        x: 0,
        y: 0,
    },

    reloadIFrame: 0,

    displays: Object.freeze({
        DESKTOP: {
            width: 1920,
            height: 1080,
            name: 'Desktop',
            icon: 'mdi-monitor',
            value: 'DESKTOP',
        },
        TABLET: {
            width: 1024,
            height: 768,
            name: 'Tablet',
            icon: 'mdi-tablet',
            value: 'TABLET',
        },
        MOBILE: {
            width: 360,
            height: 640,
            name: 'Mobile',
            icon: 'mdi-cellphone',
            value: 'MOBILE',
        },
    }),

    units: [
        {
            width: 320,
            height: 568,
            name: 'iPhone SE',
            icon: 'mdi-cellphone',
            value: 0,
            type: 'Mobile',
        },
        {
            width: 414,
            height: 896,
            name: 'iPhone XR',
            icon: 'mdi-cellphone',
            value: 1,
            type: 'Mobile',
        },
        {
            width: 390,
            height: 844,
            name: 'iPhone 12 Pro',
            icon: 'mdi-cellphone',
            value: 2,
            type: 'Mobile',
        },
        {
            width: 411,
            height: 731,
            name: 'Pixel 5',
            icon: 'mdi-cellphone',
            value: 3,
            type: 'Mobile',
        },
        {
            width: 360,
            height: 740,
            name: 'Samsung Galaxy S8+',
            icon: 'mdi-cellphone',
            value: 4,
            type: 'Mobile',
        },
        {
            width: 412,
            height: 915,
            name: 'Samsung Galaxy S20 Ultra',
            icon: 'mdi-cellphone',
            value: 5,
            type: 'Mobile',
        },
        {
            height: 834,
            width: 1112,
            name: 'iPad Air',
            icon: 'mdi-tablet',
            value: 6,
            type: 'Tablet',
        },
        {
            height: 768,
            width: 1024,
            name: 'iPad Mini',
            icon: 'mdi-tablet',
            value: 7,
            type: 'Tablet',
        },
        {
            height: 1024,
            width: 1366,
            name: 'Surface Pro 7',
            icon: 'mdi-tablet',
            value: 8,
            type: 'Tablet',
        },
        {
            height: 540,
            width: 720,
            name: 'Surface Duo',
            icon: 'mdi-tablet',
            value: 9,
            type: 'Tablet',
        },
        {
            height: 280,
            width: 653,
            name: 'Galaxy Fold',
            icon: 'mdi-tablet',
            value: 10,
            type: 'Tablet',
        },
        {
            height: 412,
            width: 914,
            name: 'Samsung Galaxy A51/71',
            icon: 'mdi-tablet',
            value: 11,
            type: 'Tablet',
        },
        {
            width: 1024,
            height: 600,
            name: 'Nest Hub',
            icon: 'mdi-tablet',
            value: 12,
            type: 'Tablet',
        },
        {
            width: 1280,
            height: 800,
            name: 'Nest Hub Max',
            icon: 'mdi-tablet',
            value: 13,
            type: 'Tablet',
        },
    ],

    faqLoadError: false,
};

const actions = {
    SOCKET_widgetsActivity({ dispatch }, payload) {
        try {
            dispatch('setWidgetActivity', payload);
        } catch (error) {
            console.log('WidgetsActivity error:', error);
        }
    },

    async setConnectedFaqs(_, payload) {
        try {
            return await widgets.setConnectedFaqs(payload);
        } catch (error) {
            console.log('WidgetsActivity error:', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong'), {
                icon: 'cancel',
                type: 'error',
            });
            return [];
        }
    },

    async loadSelectedFaqs(_, payload) {
        try {
            this.faqLoadError = false;
            const result = await widgets.loadSelectedFaqs(payload);
            return result.data;
        } catch (error) {
            this.faqLoadError = true;
            console.log('WidgetsActivity error:', error);
            return [];
        }
    },

    async reloadIFrame({ commit }) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        commit('RELOAD_IFRAME', state.reloadIFrame + 1);
    },

    widgetActiveByOtherUser({ commit }, payload) {
        commit('SET_WIDGET_ACTIVE_BY_OTHER_USER', payload);
    },

    setWidgetBuilderState({ commit }, payload) {
        commit('SET_WIDGET_BUILDER_STATE', payload);
    },

    openSettings({}, data) {
        if (data.active) {
            this._vm.$toasted.show(data.active.Name + ' is working on this widget.', {
                icon: 'mdi-account-lock',
                type: 'warning',
            });
            return;
        }

        router.push('/widgets/' + data.id + '/settings');
    },

    setWidgetActivity({ commit }, payload) {
        commit('SET_WIDGETSLIST_ACTIVITY', payload);
    },

    openWidgetsOverview({}) {
        this._vm.$socket.emit('openWidgetsOverview');
    },

    closeWidgetsOverview({}) {
        this._vm.$socket.emit('closeWidgetsOverview');
    },

    openWidget({}, id) {
        this._vm.$socket.emit('openWidget', id);
    },

    closeWidget({}, id) {
        this._vm.$socket.emit('closeWidget', id);
    },

    getWidgetQueues({ commit }) {
        return new Promise((resolve) => {
            widgets

                .getWidgetQueues()
                .then(async (res) => {
                    if (res.status !== 200) {
                        this._vm.$toasted.show('Something went wrong, please try again.', {
                            icon: 'cancel',
                            type: 'error',
                        });
                        throw new Error(res);
                    }
                    if (res.status === 200) {
                        commit('SET_WIDGET_QUEUES', res.data);
                    }
                    resolve(res.status);
                })
                .catch((error) => {
                    console.error('Widgets Error -> Get Widget Queues -> Error:', error);
                    resolve(error);
                });
        });
    },

    updateWidget({ dispatch }, widget) {
        return new Promise((resolve) => {
            widgets
                .updateWidget(widget)
                .then(async (res) => {
                    await new Promise((resolve) => setTimeout(resolve, 500));
                    if (res.status !== 200) {
                        this._vm.$toasted.show('Something went wrong, please try again.', {
                            icon: 'cancel',
                            type: 'error',
                        });
                        throw new Error(res);
                    }
                    if (res.status === 200) {
                        this._vm.$toasted.show('Widget updated successfully.', {
                            icon: 'mdi-check-circle',
                            type: 'success',
                        });
                        await dispatch('getWidgetsList');
                    }
                    resolve(res.status);
                })
                .catch((error) => {
                    console.error('Widgets Error -> Update Widget -> Error:', error);
                    resolve(error);
                });
        });
    },

    updateWidgetObject({ dispatch }, widget) {
        return new Promise((resolve) => {
            widgets
                .updateWidgetObject(widget)
                .then(async (res) => {
                    resolve(res.status);
                })
                .catch((error) => {
                    console.error('Widgets Error -> Update Widget -> Error:', error);
                    resolve(error);
                });
        });
    },

    resetWidgetObject({ dispatch }, widget) {
        return new Promise((resolve) => {
            widgets
                .resetWidgetObject(widget)
                .then(async (res) => {
                    resolve(res.status);
                })
                .catch((error) => {
                    console.error('Widgets Error -> Update Widget -> Error:', error);
                    resolve(error);
                });
        });
    },

    duplicateWidget({ dispatch }, id) {
        return new Promise((resolve) => {
            widgets
                .duplicateWidget(id)
                .then(async (res) => {
                    if (res.status !== 200) {
                        this._vm.$toasted.show('Something went wrong, please try again.', {
                            icon: 'cancel',
                            type: 'error',
                        });
                        throw new Error(res);
                    }
                    if (res.status === 200) {
                        this._vm.$toasted.show('Widget duplicated successfully.', {
                            icon: 'mdi-check-circle',
                            type: 'success',
                        });
                        await dispatch('getWidgetsList');
                    }
                    resolve(res.status);
                })
                .catch(async (error) => {
                    console.error('Widgets Error -> Duplicate Widget -> Error:', error);
                    resolve(error);
                });
        });
    },

    deleteWidget({ dispatch }, id) {
        return new Promise((resolve) => {
            widgets
                .deleteWidget(id)
                .then(async (res) => {
                    if (res.status !== 200) {
                        this._vm.$toasted.show('Something went wrong, please try again.', {
                            icon: 'cancel',
                            type: 'error',
                        });
                        throw new Error(res);
                    }
                    if (res.status === 200) {
                        this._vm.$toasted.show('Widget deleted successfully.', {
                            icon: 'mdi-check-circle',
                            type: 'success',
                        });
                        await dispatch('getWidgetsList');
                    }
                    resolve(res.status);
                })
                .catch(async (error) => {
                    console.error('Widgets Error -> Delete Widget -> Error:', error);
                    resolve(error);
                });
        });
    },

    getTemplates({ commit }) {
        return new Promise((resolve) => {
            widgets
                .getTemplates()
                .then(async (res) => {
                    if (res.status !== 200) {
                        throw new Error(res);
                    }
                    if (res.status === 200) {
                        commit('SET_TEMPLATES', res.data);
                    }
                    resolve(res.status);
                })
                .catch(async (error) => {
                    console.error('Widgets Error -> Get Templates -> Error:', error);
                    resolve(error);
                });
        });
    },

    createWidget({ dispatch }, data) {
        return new Promise((resolve) => {
            widgets
                .createWidget(data)
                .then(async (res) => {
                    await new Promise((resolve) => setTimeout(resolve, 500));
                    if (res.status !== 200) {
                        this._vm.$toasted.show('Something went wrong, please try again.', {
                            icon: 'cancel',
                            type: 'error',
                        });
                        throw new Error(res);
                    }
                    if (res.status === 200) {
                        this._vm.$toasted.show('Widget created successfully.', {
                            icon: 'mdi-check-circle',
                            type: 'success',
                        });
                        dispatch('getWidgetsList');
                    }
                    resolve(res.status);
                })
                .catch(async (error) => {
                    await new Promise((resolve) => setTimeout(resolve, 500));
                    console.error('Widgets Error -> Create Widget -> Error:', error);
                    resolve(error);
                });
        });
    },
    getWidgetsList({ commit }) {
        const payload = {
            page: state.page,
        };
        return new Promise((resolve) => {
            widgets
                .getWidgetsList(payload)
                .then((res) => {
                    const widgets = res.data;
                    commit('SET_WIDGETS_LIST', widgets.data);
                    commit('SET_NUMBERS_OF_WIDGETS', widgets.count);
                    resolve(res.status);
                })
                .catch((error) => {
                    console.error('Widgets Error -> Get Widgets List -> Error:', error);
                });
        });
    },

    async getSpecificWidget({ commit, state }, id) {
        commit('SET_SPECIFIC_LOADING', true);
        const preview = 1;

        const promise = new Promise((resolve) => {
            widgets
                .getSpecificWidget(id, preview)
                .then((res) => {
                    const widget = res.data;
                    commit('SET_WIDGET', widget);
                    resolve(res.data);
                })
                .catch((error) => {
                    console.error('Widgets Error -> Get Specific Widget -> Error:', error);
                });
        });

        const res = await Promise.all([promise, new Promise((resolve) => setTimeout(() => resolve(200), 1000))]);
        commit('SET_SPECIFIC_LOADING', false);
        return res[0];
    },

    setLoadingValue({ commit }, data) {
        commit('SET_LOADING_VALUE', data);
    },

    setSurfacePosition({ commit }, data) {
        commit('SET_SURFACE_POSITION', data);
    },

    setCustomDevice({ commit, state }, data) {
        commit('SET_DEVICE_STATE', state.deviceState.CUSTOM);
        commit('SET_DEVICE_CUSTOM_DATA', data);
    },

    setDeviceState({ commit, state }, type) {
        commit('SET_DEVICE_STATE', state.deviceState[type]);
        commit('SET_DEVICE_CUSTOM_DATA', {});
    },

    setWidgetState({ commit, state }, type) {
        commit('SET_WIDGET_STATE', state.widgetState[type]);
    },

    setWidgetZoom({ commit, state }, type) {
        if (type === 'IN' && state.zoom < 2) {
            commit('SET_WIDGET_ZOOM', state.zoom + 0.25);
        }
        if (type === 'OUT' && state.zoom > 0.25) {
            commit('SET_WIDGET_ZOOM', state.zoom - 0.25);
        }
    },

    previousPage({ commit, state }) {
        if (state.page > 1) {
            commit('SET_PAGE', state.page - 1);
        }
    },

    nextPage({ commit, state }) {
        if (state.page < state.numberOfWidgets) {
            commit('SET_PAGE', state.page + 1);
        }
    },

    updateWidgetState({ dispatch }, data) {
        return new Promise((resolve) => {
            widgets
                .updateWidgetState(data)
                .then(async (res) => {
                    if (res.status !== 200) {
                        this._vm.$toasted.show('Something went wrong, please try again.', {
                            icon: 'cancel',
                            type: 'error',
                        });
                        throw new Error(res);
                    }
                    if (res.status === 200) {
                        this._vm.$toasted.show('Widget ' + data.state.toLowerCase() + ' successfully.', {
                            icon: 'mdi-check-circle',
                            type: 'success',
                        });
                        await dispatch('getWidgetsList');
                    }
                    resolve(res.status);
                })
                .catch(async (error) => {
                    console.error('Widgets Error -> Update Widget State -> Error:', error);
                    resolve(error);
                });
        });
    },

    linkWidget({ dispatch }, data) {
        if (data.relationId === null) {
        } else {
            try {
                dispatch('BuilderMenu/setRelationId', data.relationId, { root: true });
            } catch (error) {
                this._vm.$toasted.show('Something went wrong, please try again.', {
                    icon: 'cancel',
                    type: 'error',
                });
                console.error('Widgets Error -> Update Widget Set Relation Id -> Error:', error);
            } finally {
                this._vm.$toasted.show('Widget linked successfully.', {
                    icon: 'mdi-check-circle',
                    type: 'success',
                });
            }
        }
    },
};

const mutations = {
    SET_TEMPLATES(state, data) {
        state.templates = data;
    },
    SET_NUMBERS_OF_WIDGETS(state, data) {
        state.numberOfWidgets = data;
    },
    SET_WIDGETS_LIST(state, data) {
        state.widgetsList = data;
    },
    SET_LOADING_VALUE(state, data) {
        state.loadingValue = data;
    },
    SET_SURFACE_POSITION(state, data) {
        state.surfacePosition = data;
        localStorage.setItem('__widgetSurfacePosition', JSON.stringify(data));
    },
    SET_DEVICE_CUSTOM_DATA(state, data) {
        state.deviceCustomData = data;
        localStorage.setItem('__deviceCustomData', JSON.stringify(data));
    },
    SET_DEVICE_STATE(state, data) {
        state.device = data;
        localStorage.setItem('__deviceState', JSON.stringify(data));
    },
    SET_WIDGET_STATE(state, data) {
        state.widget = data;
    },
    SET_WIDGET_ZOOM(state, data) {
        state.zoom = data;
        localStorage.setItem('__widgetZoom', JSON.stringify(data));
    },
    SET_WIDGET(state, data) {
        state.specificWidget = data;
    },
    SET_PAGE(state, data) {
        state.page = data;
    },
    SET_WIDGETSLIST_ACTIVITY(state, data) {
        state.widgetsActivity = data;
    },
    SET_WIDGET_ACTIVE_BY_OTHER_USER(state, data) {
        state.widgetActiveByOtherUser = data;
    },
    SET_SPECIFIC_LOADING(state, data) {
        state.specificLoading = data;
    },
    RELOAD_IFRAME(state, value) {
        state.reloadIFrame = value;
    },
    SET_WIDGET_BUILDER_STATE(state, data) {
        state.widgetBuilderState = data;
    },
    SET_WIDGET_QUEUES(state, data) {
        state.widgetQueues = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
