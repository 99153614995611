<template>
    <main class="client-contact-card-container">
        <section class="client-contact-container">
            <secondary-action-button
                :color="selectedClientId === clientAssignedToId ? 'primary' : 'var(--v-gray2-base)'"
                :icon="clientAssignedToIcon"
                text
                :outlined="false"
                :dense="true"
                @click="setAssignedTo"
            >
                <span>{{ clientAssignedTo }}</span>
            </secondary-action-button>

            <secondary-action-button
                v-if="clientParent"
                class="client-parent"
                icon="mdi-link"
                :outlined="false"
                text
                :color="selectedClientId === parentId ? 'primary' : 'var(--v-gray2-base)'"
                :class="clientParent ? 'client-parent-hover' : ''"
                @mouseout="clientParentHover = false"
                @mouseover="clientParentHover = true"
                @click="setParentTo"
            >
                <span>{{ clientParentText }}</span>
            </secondary-action-button>

            <section v-if="isShadow" class="multiple-client-match-container">
                <button class="multiple-client-match" @click="mergeClient">
                    {{ $t('clientContactInfo.clientMatch') }}
                </button>
            </section>
        </section>

        <section class="action-container">
            <div class="button-container">
                <standard-select
                    :key="clientAssignedTo + '_client'"
                    color="primary"
                    text
                    :items="mappedClientContacts"
                    :value="clientSelectedMapped"
                    :dense="true"
                    :disabled="isReadOnly"
                    :single="true"
                    @change="handleClientAction"
                />
            </div>

            <div class="button-container">
                <standard-select
                    :dense="true"
                    single
                    :items="actionButtons"
                    :disabled="isReadOnly"
                    :resetOnClick="true"
                    @change="(item) => handleAction(item[0])"
                >
                    <template #item-slot="{ item }">
                        <section class="action-item">
                            <div>
                                <i class="icon mdi" :class="[item.icon]" :style="{ color: item.color }"></i>
                            </div>
                            <div class="item-text">
                                <span>{{ $t(item.textKey) }}</span>
                            </div>

                            <div>
                                <v-tooltip right>
                                    <template #activator="{ on, attrs }">
                                        <i class="icon mdi mdi-help info-icon" v-bind="attrs" v-on="on"></i>
                                    </template>
                                    <span>{{ $t(item.textKey + 'Tooltip') }}</span>
                                </v-tooltip>
                            </div>
                        </section>
                    </template>
                </standard-select>
            </div>
            <div>
                <BankIdButton
                    :key="clientSelected.data.clientId + '_bankId'"
                    :caseId="caseToLoad"
                    :clientId="clientSelected.data.clientId"
                    :ssn="clientSelected.data.socialSecurityNumber"
                    :disabled="isReadOnly"
                />
            </div>
        </section>
    </main>
</template>
<script>
    import { mapActions, mapState, mapGetters } from 'vuex';
    import BankIdButton from './BankIdButton.vue';

    export default {
        components: {
            BankIdButton,
        },

        data() {
            return {
                // View
                isExpanded: false,

                // Modals
                clientAssignModal: false,
                caseAssignModal: false,

                // New variables
                clientSelected: null,

                clientParentHover: false,

                selectedContact: null,
            };
        },

        computed: {
            ...mapState({
                caseToLoad: (state) => state.Cases.caseToLoad,
                clientContactInfo: (state) => state.Cases.clientContactInfo,
                clientNowOnCard: (state) => state.Cases.clientNowOnCard,
                parentId: (state) => state.Cases.clientNowOnCard.data.parent,
                selectedCase: (state) => state.Cases.selectedCase,
                actionButtonsClient: (state) => state.Client.actionButtonsClient,
                actionButtonsShadow: (state) => state.Client.actionButtonsShadow,
                actionButtonsContact: (state) => state.Client.actionButtonsContact,
                modal: (state) => state.Client.modal,
                currentSelectedClient: (state) => state.Client.currentSelectedClient,
                readClientCard: (state) => state.Layout.readClientCard,
                readStateClientCard: (state) => state.Layout.readStateClientCard,
            }),

            ...mapGetters({
                isReadOnly: 'Cases/isReadOnly',
                selectedClientId: 'Client/selectedClientId',
            }),

            // New variables

            clientParentTextStyle() {
                return {
                    color: this.hoverColor,
                    display: this.clientParent ? 'inline' : 'none',
                };
            },

            hoverColor() {
                return this.clientParentHover && this.clientParent ? 'var(--v-gray4-base)' : 'var(--v-gray2-base)';
            },

            clientParent() {
                const parent = this.mappedClientContacts.find((client) => client.value === this.parentId);
                if (parent) {
                    return parent.text;
                }
                return null;
            },

            clientParentText() {
                const parent = this.mappedClientContacts.find((client) => client.value === this.parentId);
                if (parent) {
                    return parent.text;
                }
                return '';
            },

            isShadow() {
                return this.clientSelectedMapped.type === 'shadow';
            },

            actionButtons() {
                if (this.isShadow) {
                    return this.actionButtonsShadow;
                }

                if (this.clientSelectedMapped.type === 'contact') {
                    return this.actionButtonsContact;
                }

                return this.actionButtonsClient;
            },

            clientAssignedToIcon() {
                return this.clientIcon(this.clientNowOnCard.assignedToType);
            },

            clientAssignedTo() {
                return this.clientNowOnCard.assignedTo;
            },
            clientAssignedToId() {
                return this.clientNowOnCard.data.clientId;
            },

            clientAssignedToClientId() {
                return this.clientNowOnCard.assignedToClientId;
            },

            clientFields() {
                return this.clientNowOnCard.data;
            },

            clientContacts() {
                return this.clientNowOnCard.contacts;
            },

            clientType() {
                return this.clientSelected;
            },

            clientSelectedMapped() {
                return {
                    text: this.clientSelected.data.name,
                    value: this.clientSelected.data.clientId,
                    icon: this.clientIcon(this.clientSelected.data.type),
                    type: this.clientSelected.data.type,
                };
            },

            selectedContactMapped() {
                return {
                    text: this.clientSelected.data.name,
                    value: this.clientSelected.data.clientId,
                    icon: this.clientIcon(this.clientSelected.data.type),
                    color: 'var(--v-gray2-base)',
                    type: this.clientSelected.data.type,
                };
            },

            mappedClientContacts() {
                return [...this.clientContacts].map((contact) => ({
                    text: contact.data.name,
                    value: contact.data.clientId,
                    icon: this.clientIcon(contact.data.type),
                    type: contact.data.type,
                }));
            },
        },
        watch: {
            currentSelectedClient: {
                immediate: true,
                deep: true,
                handler(newVal) {
                    this.clientSelected = newVal;
                },
            },

            clientNowOnCard: {
                immediate: true,
                deep: true,
                handler(newVal, oldVal) {
                    // Unsubscribe to the client
                    if (oldVal) {
                        this.unsubscribeToClient({ clientId: oldVal.assignedToClientId });
                    }

                    if (newVal) {
                        this.subscribeToClient({ clientId: newVal.assignedToClientId });
                    }

                    // Subscribe to the new client
                    if (newVal.assignedToClientId !== oldVal?.assignedToClientId) {
                        const selectedContact = this.clientContacts.find(
                            (contact) => contact.data.clientId === newVal.assignedToClientId
                        );

                        this.selectedContact = selectedContact;
                    }

                    if (this.selectedContact) {
                        this.clientSelected = this.selectedContact;
                        this.handleClientAction([this.selectedContactMapped]);
                        return;
                    }

                    if (this.clientSelected) {
                        const selectedContact = this.clientContacts.find(
                            (contact) => contact.data.clientId === this.clientSelected.data.clientId
                        );

                        this.selectedContact = selectedContact;
                        this.clientSelected = selectedContact;
                        this.handleClientAction([this.selectedContactMapped]);
                        return;
                    }

                    this.clientSelected = newVal;
                    this.handleClientAction([this.clientSelectedMapped]);
                },
            },
        },

        beforeDestroy() {
            this.unsubscribeToClient(this.currentSelectedClient);
        },

        methods: {
            ...mapActions('Cases', ['setClientContactInfo', 'setClientOnCard']),
            ...mapActions('Client', [
                'handleAction',
                'updateClient',
                'setClientLoading',
                'setCurrentSelectedClient',
                'subscribeToClient',
                'unsubscribeToClient',
            ]),
            ...mapActions({
                getCaseTimeline: 'Cases/timeline',
            }),

            clientIcon(type) {
                switch (type) {
                    case 'client': {
                        return 'mdi-link';
                    }
                    case 'contact': {
                        return 'mdi-account';
                    }
                    case 'shadow': {
                        return 'mdi-account-question';
                    }
                    default: {
                        return 'mdi-link';
                    }
                }
            },

            mergeClient() {
                this.handleAction({ value: 'mergeClient' });
            },

            setAssignedTo() {
                this.clientSelected = this.clientNowOnCard;
                this.handleClientAction([this.clientSelectedMapped]);
            },

            setParentTo() {
                const parent = this.clientContacts.find(
                    (contact) => contact.data.clientId === this.clientNowOnCard.data.parent
                );
                if (!parent) return;
                this.clientSelected = parent;
                this.handleClientAction([this.clientSelectedMapped]);
            },

            handleClientAction(item) {
                const contact = this.clientNowOnCard.contacts.find(
                    (contact) => contact.data.clientId === item[0].value
                );

                if (!contact) return;

                this.clientSelected = contact;
                this.selectedContact = contact;
                this.setCurrentSelectedClient({ ...this.clientSelected, type: item[0].type });

                this.getCaseTimeline({ id: this.clientSelected.data.clientId, load: false });
            },

            expand() {
                this.isExpanded = !this.isExpanded;
            },

            fillContactInfo() {
                if (this.contactPerson && this.showContactPerson) {
                    this.displayContactPerson();
                } else {
                    this.createContactInfoData();
                }
            },
        },
    };
</script>
<style lang="scss" scoped>
    .item-text {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
    }

    .client-parent {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        font-size: 0.9rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border: none !important;
    }

    .client-parent-hover {
        cursor: pointer;
    }

    .client-contact-card-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .action-container {
        display: grid;
        grid-template-columns: 1fr 1fr auto;
        grid-gap: 16px;
        padding: 16px;
    }

    .action-container > * {
        justify-self: end;
    }

    .client-contact-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 8px;
        padding: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .client-contact-container > * {
        align-self: center;
    }

    .multiple-client-match {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        color: var(--v-error-base);
        font-size: 12px;
        padding: 16px;
        border: 1px solid var(--v-error-lighten5);
        border-radius: 6px;
        transition: background-color 0.1s ease-in-out;
        flex: 1;
    }

    .multiple-client-match:hover {
        background-color: var(--v-gray5-base);
        border: 1px solid rgb(0, 0, 0, 0.15);
        color: var(--v-gray4-base);
    }

    .multiple-client-match-container {
        display: flex;
        align-items: center;
        justify-content: stretch;
    }

    .button-container {
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        flex: 1;
        justify-self: stretch;
    }

    .action-item {
        display: grid;
        grid-template-columns: 48px 1fr 24px;
        width: 100%;
    }

    .action-item > :first-child {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .action-item > * {
        align-self: center;
    }

    .action-item > :last-child {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .info-icon {
        color: var(--v-gray2-base);
        font-size: 12px;
    }
</style>

<i18n lang="json">
{
    "en": {
        "clientContactInfo": {
            "clientMatch": "Client matched against multiple clients in the system"
        }
    },
    "sv": {
        "clientContactInfo": {
            "clientMatch": "Klient matchad mot flera klienter i systemet"
        }
    }
}
</i18n>
