<template>
    <div
        class="d-flex flex-row travelItem softShadow"
        style="position: relative"
        :style="{ backgroundColor: getBackgroundColor(), cursor: expandable() ? 'pointer' : 'default' }"
        @mouseover="hoverItem(true)"
        @mouseleave="hoverItem(false)"
        @click="itemExpandToggle()"
    >
        <div class="itemTimer">
            <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                    <v-chip
                        v-if="event.talkTimeInSec != null || event.queueTime != null"
                        small
                        :color="'white'"
                        class="softShadow"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon>mdi-time</v-icon>
                        <span style="font-size: 10px">{{ getMMSS(event.talkTimeInSec || event.queueTime) }}</span>
                    </v-chip>
                </template>
                <span>{{ event.talkTimeInSec ? $t('caseTravel.talkTime') : $t('caseTravel.queueTime') }}</span>
            </v-tooltip>
        </div>

        <div v-if="expandable()" style="position: absolute; right: 0; top: 0" class="pa-3">
            <v-icon size="16" :color="expand ? 'var(--v-gray4-base)' : '#ccc'">
                {{ expand ? 'mdi-minus' : 'mdi-plus' }}
            </v-icon>
        </div>

        <div class="d-flex flex-column text px-2" style="width: 100%">
            <span v-if="isInitiated && isArray" class="itemTitle">
                <div class="d-flex flex-column">
                    <span>{{ event[0].text[$i18n.locale] }}</span>
                    <span v-if="selectedCase?.caseStart?.AgentName || event[0].userName">
                        {{ event[0].status == 'initiated' ? selectedCase.caseStart.AgentName : event[0].queueName }}
                    </span>
                </div>
            </span>
            <span v-else-if="isInitiated" class="itemTitle">
                <div class="d-flex flex-column">
                    <span>{{ event.text[$i18n.locale] }}</span>
                    <span v-if="selectedCase?.caseStart?.AgentName || event.userName">
                        {{ event.status == 'initiated' ? selectedCase.caseStart.AgentName : event.userName }}
                    </span>
                </div>
                <span v-if="!event.userName">{{ event.queueName }}</span>
            </span>
            <span v-else class="itemTitle">
                {{ event.text[$i18n.locale] }}
            </span>
            <div class="itemData d-flex flex-column">
                <span v-if="isArray" class="itemTime">
                    {{ getDay(event[0].time) }}
                </span>
                <span v-else class="itemTime">
                    {{ getDay(event.time) }}
                </span>
            </div>

            <v-divider v-if="expand" class="mt-3 mb-3" />

            <div
                v-if="expand && !isArray"
                class="moreInfo d-flex flex-column"
                style="font-size: 12px; line-height: 1.4em"
            >
                <span v-if="event.caseId" class="itemCase">
                    <span style="font-weight: 500">{{ $t('caseTravel.caseId') }}</span>
                    {{ event.caseId }}
                </span>
                <span v-if="event.queueId" class="itemCase">
                    <span style="font-weight: 500">{{ $t('caseTravel.queue') }}</span>
                    {{ event.queueId }}
                </span>
                <span v-if="event.queueName" class="itemCase">
                    <span style="font-weight: 500">{{ $t('caseTravel.queueName') }}</span>
                    {{ event.queueName }}
                </span>

                <v-divider v-if="event.caseId" class="mt-3 mb-3" />

                <div v-if="event.caseId">
                    <v-btn color="primary" elevation="0" x-small @click="goToCase(event.caseId)">
                        <span style="font-size: 10px">{{ $t('caseTravel.goToCase') }}</span>
                    </v-btn>
                </div>
            </div>
            <div v-else-if="expand" class="moreInfo d-flex flex-column" style="font-size: 12px; line-height: 1.4em">
                <span class="mb-2">{{ $t('caseTravel.calledPeople') }}</span>
                <div v-for="(user, index) in event" :key="index" class="d-flex align-center">
                    <CommonAvatar :key="user.userId" :size="32" :userId="user.userId" border="4px solid white" />
                    <div class="d-flex flex-column">
                        <span class="itemTitle2 ml-2">{{ user.userName }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { customerParams } from '@/app/config';
    import { getMMSS, getDay } from '@/utils/caseTravel';
    import CommonAvatar from '@/components/Global/CommonAvatar.vue';

    export default {
        components: { CommonAvatar },
        props: {
            event: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                colors: this.$vuetify.theme.themes.light,
                hover: false,
                expand: false,
            };
        },

        computed: {
            selectedCase() {
                return this.$store.state.Cases.selectedCase;
            },
            caseTravelMinimized() {
                return this.$store.state.Cases.caseTravelMinimized;
            },
            isArray() {
                return Array.isArray(this.event);
            },
        },

        watch: {
            caseTravelMinimized: {
                handler(newVal) {
                    if (!newVal) {
                        this.expand = false;
                    }
                },
                deep: true,
            },
        },
        methods: {
            getDay,
            getMMSS,

            getBackgroundColor() {
                return this.hover ? this.colors.gray5 : 'white';
            },
            isAvatar(event) {
                return (event.profilePicture != null && event.status !== 'callHungUp') || event.status === 'initiated';
            },
            isInitiated(event) {
                return event.userName || event.status === 'initiated';
            },
            hoverItem(value) {
                if (!this.expandable()) {
                    return;
                }
                this.hover = value;
            },
            expandable() {
                if (this.isArray) {
                    return this.event[0].caseId || this.event[0].queueId || this.event[0].queueName;
                }
                return this.event.caseId || this.event.queueId || this.event.queueName;
            },
            itemExpandToggle() {
                if (!this.expandable()) {
                    return;
                }
                this.expand = !this.expand;

                if (this.expand) {
                    this.$store.commit('Cases/SET_CASE_TRAVEL_MINIMIZED', true);
                }
            },
            goToCase(caseId) {
                this.$router.push('/cases/' + caseId);
            },
        },
    };
</script>

<style scoped lang="scss">
    .travelItem {
        padding: 12px;
        margin-bottom: 12px;
        border-radius: 8px;
        width: 100%;
        flex: 1;
        flex-basis: 100%;
        transition: all 0.3s ease;
    }

    .itemTimer {
        position: absolute;
        right: -12px;
        top: 0;
        transform: translateY(-50%);
    }

    .itemTitle {
        font-weight: 500 !important;
        color: var(--v-gray4-base);
        font-size: 12px;
        line-height: 1.4em;
    }
    .itemTitle2 {
        font-weight: 500 !important;
        color: var(--v-gray4-base);
        font-size: 12px;
        padding-top: 1px;
    }

    .itemTime {
        font-weight: 400 !important;
        color: var(--v-gray2-base);
        font-size: 12px;
    }

    .itemCase {
        font-weight: 400 !important;
        max-width: 210px;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .itemData {
    }

    .box {
        min-width: 48px;
    }

    .text {
    }

    .item {
        height: 64px;
    }
</style>

<i18n lang="json">
{
    "en": {
        "caseTravel": {
            "time": "Event time",
            "goToCase": "Go to case",
            "caseId": "Case ID:",
            "queue": "Queue:",
            "queueName": "Queue name:",
            "talkTime": "Call time",
            "queueTime": "Queue time",
            "calledPeople": "Called people"
        }
    },
    "sv": {
        "caseTravel": {
            "time": "Händelsetid",
            "goToCase": "Gå till ärende",
            "caseId": "Ärendenummer:",
            "queue": "Kö:",
            "queueName": "Könamn:",
            "talkTime": "Samtalstid",
            "queueTime": "Kötid",
            "calledPeople": "Uppringda personer"
        }
    }
}
</i18n>
