<template>
    <IntegrationButton :active="active" :integrationName="integration.name" @click="buttonClicked" />
</template>
<script>
    import IntegrationButton from './IntegrationButton.vue';

    export default {
        components: { IntegrationButton },
        props: {
            integration: {
                type: Object,
                required: true,
            },
        },

        computed: {
            active() {
                return this.integration.active;
            },
        },
        methods: {
            buttonClicked() {
                alert('button clicked');
            },
        },
    };
</script>
<style scoped lang="scss"></style>
<i18n lang="json">
{
    "en": {},
    "sv": {}
}
</i18n>
