import axios from 'axios';

function getQueueCategoryRelations(payload) {
    return axios.get('/categories/queues/relations', {
        params: {
            queueId: payload.queueId,
            categoryId: payload.categoryId,
            parent: payload.parent,
        },
    });
}

function updateQueueCategoryRelations(payload) {
    return axios.put('/categories/queues/relations', payload);
}

function getCategories(payload) {
    return axios.get('/categories', { params: payload });
}

export default {
    getQueueCategoryRelations,
    updateQueueCategoryRelations,
    getCategories,
};
