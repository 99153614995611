import recordings from '@/api/recordings/recordings';
// Actions
const actions = {
    async getRecordings(_, payload) {
        const { data } = await recordings.getRecordings(payload);
        return data;
    },

    async getSpecificRecording(_, payload) {
        const { data } = await recordings.getSpecificRecording(payload);
        return data;
    },
};

export default {
    namespaced: true,
    actions,
};
