<template>
    <SimpleTooltip bottom :tooltipText="userName">
        <div>
            <CommonAvatar :key="item.userId" :userId="item.userId" :size="24" />
        </div>
    </SimpleTooltip>
</template>
<script>
    import CommonAvatar from '@/components/Global/CommonAvatar.vue';

    export default {
        components: {
            SimpleTooltip: () => import('@/components/Global/SimpleTooltip.vue'),
            CommonAvatar,
        },
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        computed: {
            userName() {
                return this.item.userName || this.$t('timelineItemAvatar.noAgent');
            },
        },
    };
</script>
<i18n lang="json">
{
    "en": {
        "timelineItemAvatar": {
            "noAgent": "No agent"
        }
    },
    "sv": {
        "timelineItemAvatar": {
            "noAgent": "Ingen agent"
        }
    }
}
</i18n>
