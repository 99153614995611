module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "timeLine": {
          "dateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date / Time"])},
          "noAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No agent"])},
          "noContactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No contact person"])},
          "noTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No topic"])},
          "workingCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is currently working on this case"])}
        }
      },
      "sv": {
        "timeLine": {
          "dateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum / Tid"])},
          "noAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen agent"])},
          "noContactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen kontaktperson"])},
          "noTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget ämne"])},
          "workingCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jobbar i detta ärende just nu"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"timeLine":{"dateTime":"Date / Time","noAgent":"No agent","noContactPerson":"No contact person","noTopic":"No topic","workingCase":"is currently working on this case"}},"sv":{"timeLine":{"dateTime":"Datum / Tid","noAgent":"Ingen agent","noContactPerson":"Ingen kontaktperson","noTopic":"Inget ämne","workingCase":"jobbar i detta ärende just nu"}}}')
  delete Component.options._Ctor
  
}
