import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{attrs:{"flat":""}},[_c('div',{staticClass:"d-flex flex-row"},[_c(VCol,[_c('div',{staticClass:"mb-0 d-flex flex-row justify-center my-3 pb-5"},[_c(VForm,{ref:"callform",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.selectedNumber)?_c('phone-number',{attrs:{"defaultPhoneNumber":_vm.selectedNumber.Number || _vm.selectedNumber},on:{"inputAndValidation":_vm.handlePhoneInput}}):_vm._e()],1)],1),_c('div',{staticClass:"d-flex flex-column align-center px-2 pb-3"},[_c('div',{staticClass:"d-flex flex-row flex-wrap justify-space-between align-center",staticStyle:{"max-width":"225px","width":"100%"}},[_vm._l((_vm.buttons),function(button){return _c('div',{key:button.id,staticClass:"d-flex flex-column align-center",staticStyle:{"flex":"1 0 33%"}},[_c('div',{staticClass:"pa-1 mb-1"},[_c(VBtn,{attrs:{"id":button.id,"fab":"","elevation":"0"},on:{"click":button.action}},[_c('span',{staticStyle:{"font-size":"24px","font-weight":"400","color":"var(--v-gray4-base)"}},[_vm._v(" "+_vm._s(button.title)+" ")])])],1)])}),_c('div',{staticClass:"d-flex flex-row justify-center",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"pa-1 mb-1"},[_c(VBtn,{attrs:{"id":'enter',"disabled":!_vm.valid,"color":"primary","fab":"","elevation":"0"},on:{"click":function($event){return _vm.clickLetter('call')}}},[_c(VIcon,{attrs:{"color":"white","size":"24"}},[_vm._v("mdi-phone")])],1)],1)])],2)])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }