import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { themeColors, presence, standard } from '../templates/colors.template';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                ...themeColors,
                presence,
                standard,
            },
        },
    },
});
