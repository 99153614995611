module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "timeLine": {
          "noTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No topic"])}
        }
      },
      "sv": {
        "timeLine": {
          "noTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget ämne"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"timeLine":{"noTopic":"No topic"}},"sv":{"timeLine":{"noTopic":"Inget ämne"}}}')
  delete Component.options._Ctor
  
}
