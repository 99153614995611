import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"activity-line",style:(_vm.borderStyle)},[_c(VTooltip,{attrs:{"right":"","disabled":!_vm.isBusy},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"border pa-0 mx-0",class:{
                    selectedRowBorder: _vm.item.caseId === _vm.selectedCaseId,
                    selectedRowBorderBusy: _vm.isBusy && _vm.item.caseId !== _vm.selectedCaseId,
                },style:({
                    maxWidth: _vm.small ? '4px' : '6px',
                })},'div',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.busyText))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }