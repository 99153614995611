import { format, subDays, parseISO } from 'date-fns';
import i18n from '../i18n';

export function getMMSS(seconds) {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().slice(14, 19);
}

export function getDay(v) {
    const value = format(parseISO(v), 'yyyy-MM-dd');
    const time = format(parseISO(v), 'HH:mm:ss');

    const today = format(new Date(), 'yyyy-MM-dd');
    const yesterDay = format(subDays(new Date(), 1), 'yyyy-MM-dd');

    switch (value) {
        case today: {
            // return `Idag ${time}`;
            return i18n.t('global.today') + ` ${time}`;
        }

        case yesterDay: {
            // return `Igår ${time}`;
            return i18n.t('global.yesterday') + ` ${time}`;
        }
        default: {
            return `${value} ${time}`;
        }
    }
}
