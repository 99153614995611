import axios from 'axios';

export default {
    checkInternalNumberValidation(params) {
        return axios.get('/pbx/validate?type=internal', {
            params,
        });
    },
    checkPublicNumberValidation(params) {
        return axios.get('/pbx/validate?type=external', {
            params,
        });
    },
    getNextInternalNumber() {
        return axios.get('/pbx/next?type=internal');
    },
    getPublicNumberSuggestions() {
        return axios.get('/pbx/next?type=external');
    },
    getCredentials() {
        return axios.get(`/pbx/credentials/`, {
            params: {
                device: 'Browser',
            },
        });
    },
    getStatus() {
        return axios.get(`/pbx/status`);
    },
    setStatus(payload) {
        return axios.post(`/pbx/status`, payload);
    },
    getTransferUserData({ page = 1, search = '' }) {
        return axios.get(`/pbx/transfer`, {
            params: { page, search },
        });
    },
    findUserNumber({ name = '', userId = '' }) {
        return axios.get(`/pbx/userdata`, {
            params: { name, userId },
        });
    },
    getCallHistory({ page = 1, userId }) {
        return axios.get(`/pbx/cdr/${userId}`, { params: { page } });
    },
    setInternalNumberRange(payload) {
        return axios.post(`pbx/range`, payload, {
            params: {
                type: 'internal',
            },
        });
    },
    addNumberRange(payload) {
        return axios.post(`pbx/range`, payload, {
            params: {
                type: 'external',
            },
        });
    },
    updateAvailableNumbers() {
        return axios.post(`/pbx/update-available`);
    },
    getSettings() {
        return axios.get(`/pbx/settings`, {
            params: {
                device: 'Browser',
            },
        });
    },
    setSettings(payload) {
        return axios.post(`/pbx/settings`, { device: 'Browser', ...payload });
    },
    addGateway(payload) {
        return axios.post(`/pbx/gateway`, payload);
    },
};
