module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "externalTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External tasks"])},
        "externalTasksModal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External tasks"])},
        "createTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create task"])},
        "externalTasksModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External tasks"])},
        "externalTasksModalClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
      },
      "sv": {
        "externalTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externa ärenden"])},
        "externalTasksModal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externa ärenden"])},
        "createTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa ärende"])},
        "externalTasksModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externa ärenden"])},
        "externalTasksModalClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stäng"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"externalTasks":"External tasks","externalTasksModal":"External tasks","createTask":"Create task","externalTasksModalTitle":"External tasks","externalTasksModalClose":"Close"},"sv":{"externalTasks":"Externa ärenden","externalTasksModal":"Externa ärenden","createTask":"Skapa ärende","externalTasksModalTitle":"Externa ärenden","externalTasksModalClose":"Stäng"}}')
  delete Component.options._Ctor
  
}
