import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-truncate profile-picture-container",class:_vm.getPositionClass(_vm.position),attrs:{"cy-caseAgent":_vm.item.userId == 0 ? 'none' : _vm.item.userName}},[(_vm.item.userId !== 0)?_c('div',{key:_vm.item.userId},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(!_vm.userSettings.cases.showInitialInsteadOfProfilePic?.active)?_c('CommonAvatar',{key:_vm.item.userId,attrs:{"userId":_vm.item.userId,"color":_vm.item.caseId === _vm.selectedCaseId ? 'var(--v-gray3-base)' : 'var(--v-gray3-base)',"size":_vm.listLayout.value == 'big' ? 32 : 26}}):_c(VAvatar,{staticClass:"d-flex justify-center align-center profile-picture-no-border",style:({
                            color: _vm.getTextColorByBackground(_vm.generateColor(_vm.item.userName || _vm.item.userId)),
                            minWidth: _vm.smallerOrBigger,
                            maxWidth: _vm.smallerOrBigger,
                            maxHeight: _vm.smallerOrBigger,
                        }),attrs:{"right":"","color":_vm.generateColor(_vm.item.userName || _vm.item.userId),"height":_vm.smallerOrBigger,"width":_vm.smallerOrBigger}},[_c('span',{staticClass:"initial-text"},[_vm._v(" "+_vm._s(_vm.getInitials(_vm.item.userName))+" ")])])],1)]}}],null,false,3422141152)},[_c('span',[_vm._v(_vm._s(_vm.item.userName || _vm.item.userId))])])],1):_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VBtn,{attrs:{"fab":"","color":"gray3","height":_vm.listLayout.value == 'big' ? 32 : 26,"width":_vm.listLayout.value == 'big' ? 32 : 26,"elevation":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('addCaseToYou')}}},[_c(VIcon,{attrs:{"size":_vm.listLayout.value == 'big' ? 16 : 16,"color":"gray2"}},[_vm._v("mdi-plus")])],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('profilePicture.assign')))])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }