module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "sv": {
        "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidarekoppla"])},
        "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ring"])}
      },
      "en": {
        "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
        "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"sv":{"transfer":"Vidarekoppla","call":"Ring"},"en":{"transfer":"Transfer","call":"Call"}}')
  delete Component.options._Ctor
  
}
