<template>
    <section class="bar">
        <Content />
    </section>
</template>
<script>
    import { mapState } from 'vuex';
    import Content from '@/components/Main/Header/HeaderContent.vue';

    export default {
        components: {
            Content,
        },

        computed: {
            ...mapState({
                state: (state) => state.Layout.topBar,
                states: (state) => state.Layout.topBarState,
                text: (state) => state.TopInformationBar.text,
                icon: (state) => state.TopInformationBar.icon,
                color: (state) => state.TopInformationBar.color,
            }),
        },
    };
</script>
<style scoped>
    .bar {
        height: 64px;
        z-index: 99;
        background-color: var(--v-primary-base);
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        position: sticky;
        top: 0;
        border-bottom: 1px solid var(--v-gray3-base);
    }
    .bar-open {
        background-color: white;
        height: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--v-primary-base);
        font-size: 14px;
    }

    .slide-fade-enter-active {
        transition: all 0.35s ease-in-out;
    }

    .slide-fade-leave-active {
        transition: all 0.35s ease-in-out;
    }

    .slide-fade-enter {
        transform: translateY(-64px);
        opacity: 0;
    }

    .slide-fade-leave-to {
        transform: translateY(-64px);
        opacity: 0;
    }

    .fade-enter-active {
        transform: scale(1);
        transition: all 0.3s ease-in-out;
    }

    .fade-leave-active {
        transform: scale(1);
        transition: all 0.3s ease-in-out;
    }

    .fade-enter {
        transform: scale(0.8);
        opacity: 0;
    }

    .fade-leave-to {
        opacity: 0;
    }
</style>
