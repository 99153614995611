<template>
    <dialog id="confirmation-dialog-container" ref="dialog" class="confirmation-dialog">
        <section class="confirmation-dialog-container">
            <div class="confirmation-dialog-inner-container">
                <div class="dialog-header">
                    <span class="confirmation-title">{{ title }}</span>
                </div>
                <div>
                    <div class="confirmation-message">
                        <div v-if="message" class="confirmation-dialog-body">
                            {{ message }}
                        </div>
                        <slot name="body"></slot>
                    </div>
                </div>
            </div>
            <div class="confirmation-button-outer-container">
                <div class="confirmation-button-container">
                    <secondary-action-button @click="decline" @keydown.enter="decline">
                        {{ declineText }}
                    </secondary-action-button>
                    <secondary-action-button
                        v-for="button in extraButtons"
                        :key="button.text"
                        :depressed="true"
                        :class="button.class"
                        @click="() => extraButtonAction(button.action)"
                    >
                        {{ button.text }}
                    </secondary-action-button>
                    <primary-action-button
                        cy-data="confirm"
                        :depressed="true"
                        autofocus
                        @click="confirm"
                        @keydown.enter="confirm"
                    >
                        {{ confirmText }}
                    </primary-action-button>
                </div>
            </div>
        </section>
    </dialog>
</template>

<script>
    /**
     * A confirmation dialog component.
     * This component is used to show a dialog with a message and two buttons: confirm and decline.
     * The dialog can also have extra buttons with custom actions.
     */
    export default {
        name: 'ConfirmationDialog',

        data() {
            return {
                dialog: null,
                title: '',
                message: '',
                confirmText: '',
                declineText: '',
                extraButtons: [],
            };
        },

        /**
         * Set the dialog reference.
         */
        mounted() {
            this.dialog = this.$refs.dialog;
        },

        /**
         * Remove event listeners when the component is destroyed.
         */
        beforeDestroy() {
            this.removeListeners();
        },

        methods: {
            /**
             * Show the dialog.
             * @param {object} options The dialog options.
             * @param {string} options.title The dialog title.
             * @param {string} options.message The dialog message.
             * @param {string} options.confirmText The text for the confirm button.
             * @param {string} options.declineText The text for the decline button.
             * @param {object[]} options.extraButtons The extra buttons.
             * @param {string} options.extraButtons[].text The text for the extra button.
             * @param {string} options.extraButtons[].class The class for the extra button.
             * @param {function} options.extraButtons[].action The action for the extra button.
             * @returns {Promise<{confirmed: boolean, declined: boolean, canceled: boolean, confirmedExtra: boolean}>} A promise that resolves with the result of the dialog.
             */
            show({ title, message, confirmText, declineText, extraButtons = [] }) {
                this.populateDialog(title, message, confirmText, declineText, extraButtons);
                this.dialog.showModal();
                this.addListeners();

                return new Promise((resolve) => {
                    const cleanupAndResolve = (result) => {
                        this.removeListeners();
                        this.dialog.close();
                        resolve(result);
                    };

                    this.$once('confirmed', () => {
                        const result = { confirmed: true, declined: false, canceled: false };
                        cleanupAndResolve(result);
                    });

                    this.$once('declined', () => {
                        const result = { confirmed: false, declined: true, canceled: false };
                        cleanupAndResolve(result);
                    });

                    this.$once('canceled', () => {
                        const result = { confirmed: false, declined: false, canceled: true };
                        cleanupAndResolve(result);
                    });

                    this.$once('confirmedExtra', () => {
                        const result = {
                            confirmed: true,
                            declined: false,
                            canceled: false,
                            confirmedExtra: true,
                        };
                        cleanupAndResolve(result);
                    });
                });
            },

            /**
             * Populate the dialog with the given options.
             * @param {string} title The dialog title.
             * @param {string} message The dialog message.
             * @param {string} confirmText The text for the confirm button.
             * @param {string} declineText The text for the decline button.
             * @param {object[]} extraButtons The extra buttons.
             * @param {string} extraButtons[].text The text for the extra button.
             * @param {string} extraButtons[].class The class for the extra button.
             * @param {function} extraButtons[].action The action for the extra button.
             */
            populateDialog(title, message, confirmText, declineText, extraButtons) {
                this.title = title;
                this.message = message;
                this.confirmText = confirmText;
                this.declineText = declineText;
                this.extraButtons = extraButtons;
            },

            /**
             * Emit the confirmed event.
             */
            confirm() {
                this.$emit('confirmed');
            },

            /**
             * Emit the canceled event.
             */
            cancel() {
                this.$emit('canceled');
            },

            /**
             * Emit the declined event.
             */
            decline() {
                this.$emit('declined');
            },

            /**
             * Close the dialog when clicking outside of it.
             */
            closeOnOutsideClick(event) {
                if (event.target === this.dialog) {
                    this.cancel();
                }
            },

            /**
             * Close the dialog when pressing the escape key.
             * @param {KeyboardEvent} event The keyboard event.
             */
            handleEscape(event) {
                if (event.key === 'Escape' || event.keyCode === 27) {
                    this.cancel();
                }
            },

            /**
             * Add event listeners to the document.
             * The listeners are used to close the dialog when clicking outside of it or pressing the escape key.
             */
            addListeners() {
                document.addEventListener('mouseup', this.closeOnOutsideClick);
                document.addEventListener('keydown', this.handleEscape);
            },

            /**
             * Remove event listeners from the document.
             */
            removeListeners() {
                document.removeEventListener('mouseup', this.closeOnOutsideClick);
                document.removeEventListener('keydown', this.handleEscape);
            },

            /**
             * Emit the confirmedExtra event and execute the action of the extra button.
             * @param {function} action The action of the extra button.
             */
            extraButtonAction(action) {
                this.$emit('confirmedExtra');
                action();
            },
        },
    };
</script>

<style scoped>
    .confirmation-title {
        font-weight: 400;
        font-size: 20px;
    }

    .confirmation-message {
        color: var(--v-gray2-base);
        margin-top: 12px;
    }

    .confirmation-dialog-body {
        word-break: break-word;
    }

    .confirmation-dialog {
        width: 80vw;
        max-width: 560px;
        border: none;
        transform: translate(-50%, -50%);
        border-radius: 16px;
        top: 50%;
        left: 50%;
        box-shadow:
            0 4px 8px rgba(0, 0, 0, 0.1),
            0 2px 4px rgba(0, 0, 0, 0.06);

        border: 1px solid rgba(0, 0, 0, 0.2);
    }

    .confirmation-dialog[open] {
        animation: show 0.2s ease-in-out normal;
    }

    .confirmation-button-container {
        display: flex;
        flex-direction: row;
        gap: 8px;
    }

    .confirmation-dialog-container {
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .confirmation-dialog-inner-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 24px;
    }

    .confirmation-button-outer-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .dialog-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 12px;
    }

    @keyframes show {
        from {
            transform: translate(-50%, -50%) scale(0.8);
            opacity: 0;
        }
        to {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
    }
</style>
