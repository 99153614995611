module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "sla": {
          "preSlaOpen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " left"])},
          "postSlaOpen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " ago"])},
          "inQueue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " queue time"])},
          "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " (snoozed)"])}
        }
      },
      "sv": {
        "sla": {
          "preSlaOpen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " kvar"])},
          "postSlaOpen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " sedan"])},
          "inQueue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " kötid"])},
          "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " (bevakad)"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"sla":{"preSlaOpen":"{time} left","postSlaOpen":"{time} ago","inQueue":"{time} queue time","snoozed":"{time} (snoozed)"}},"sv":{"sla":{"preSlaOpen":"{time} kvar","postSlaOpen":"{time} sedan","inQueue":"{time} kötid","snoozed":"{time} (bevakad)"}}}')
  delete Component.options._Ctor
  
}
