<template>
    <div class="d-flex" :class="getPositionClass(position)">
        <v-btn
            v-if="item.attachment"
            fab
            small
            :color="item.unreadMessages > 0 ? 'gray3' : 'gray5'"
            elevation="0"
            class="pa-0 mx-0 nohover"
            :style="{
                height: listLayout.value == 'big' ? '36px' : '20px',
                width: listLayout.value == 'big' ? '36px' : '20px',
            }"
        >
            <v-icon :size="listLayout.value == 'big' ? 16 : 16" color="gray2" class="ma-0">mdi-attachment</v-icon>
        </v-btn>
    </div>
</template>

<script>
    import { positionTypes } from '@/enums/cases.enums';
    import { getPositionClass } from '@/helpers/cases/cases.helper';

    export default {
        props: {
            item: {},
            selectedCaseId: '',
            position: {
                type: String,
                default: positionTypes.LEFT,
                validator(value) {
                    // Shorthand for validator
                    return Object.values(positionTypes).includes(value);
                },
            },
        },

        computed: {
            listLayout() {
                return this.$store.state.Cases.listLayout;
            },
        },
        methods: {
            getPositionClass,
        },
    };
</script>

<style scoped>
    .nohover::before {
        display: none;
    }
</style>
