import axios from 'axios';

function getIntegrations(page) {
    return axios.get(`/integrations/custom?page=${page}`);
}

function addIntegration(payload) {
    return axios.post('/integrations/custom', payload);
}

function getIntegrationFromQueueId(payload) {
    return axios.get(`/queues/${payload.queueId}/identifiers/${payload.internalType}/integration`);
}

function getIntegrationVariables(payload) {
    return axios.post(`/integration/variables`, payload);
}

function getIntegrationVariablesByIntegrationId(payload) {
    return axios.get(`/integration/variables/${payload}`);
}

function saveIntegrationVariables(payload) {
    return axios.post(`/integration/variables/save`, payload);
}

function getStandardAppsSettings() {
    return axios.get('/integration/standard-apps/settings');
}

function setStandardApp(payload) {
    return axios.post('/integration/standard-apps', payload);
}

function getExternalData() {
    return axios.get('integration/synchronization/1337');
}
function deploySync(payload) {
    return axios.post('integration/synchronization/1337', payload);
}
function getHistory() {
    return axios.get('integration/history/1337');
}
/**
 * BANKID
 */
function bankidAuthenticate(data) {
    return axios.post('integrations/bankid/authenticate', data);
}

function isBankIdActive() {
    return axios.get('integrations/bankid/active');
}

function getActiveStates() {
    return axios.get('system/integrations/active-state');
}

/**
 * DEDU
 */

function getDeduTasks(payload) {
    return axios.get('integrations/dedu/tasks', { params: payload });
}

function getDeduTask(id) {
    return axios.get(`integrations/dedu/tasks/${id}`);
}

function createDeduTask(data) {
    return axios.post('integrations/dedu/tasks', data);
}

function updateDeduTask(data) {
    return axios.put(`integrations/dedu/tasks/${data.taskId}`, data);
}

function getDeduBuildingEntityItems(payload) {
    return axios.get(`integrations/dedu/building-entity-items`, {
        params: {
            ...payload,
            type: payload.type.toString(), // toString or else its an array *shrug*
        },
    });
}

function getDeduProfessions({ structureId }) {
    return axios.get(`integrations/dedu/professions`, {
        params: {
            structureId,
        },
    });
}

function getDeduTaskTypes() {
    return axios.get('integrations/dedu/task-types');
}

function getDeduTaskCategories() {
    return axios.get('integrations/dedu/task-categories');
}

function getDeduTaskSubCategories() {
    return axios.get('integrations/dedu/task-subcategories');
}
function getDeduTaskPriorities() {
    return axios.get('integrations/dedu/task-priorities');
}
function getDeduTaskSources() {
    return axios.get('integrations/dedu/task-sources');
}

// MADHAT
function getOrders({ type, caseId }) {
    return axios.get(`integrations/${type}/orders`, { params: { caseId } });
}
function getOrder(payload) {
    const { type, ...args } = payload;
    return axios.get(`integrations/${type}/order`, { params: { ...args } });
}

function getPortals(type) {
    return axios.get(`integrations/${type}/portals`);
}
function addPortal(payload) {
    return axios.post(`integrations/madhat/portals`, payload);
}

function updatePortal(payload) {
    return axios.put(`integrations/madhat/portals/${payload.id}`, payload);
}

function deletePortal(id) {
    return axios.delete(`integrations/madhat/portals/${id}`);
}
function getOrderIntegrations() {
    return axios.get(`integrations/orders`);
}

/* PINDELIVERY */
function getPindeliveryReports(orderId) {
    return axios.get(`integrations/pindelivery/reports?orderId=${orderId}`);
}

/**
 * SYSTEM INTEGRATIOMS
 */

function getSystemIntegrations() {
    return axios.get('system/integrations');
}
function getCaseButtonIntegrations() {
    return axios.get('system/integrations/case-buttons');
}

function getSystemIntegrationKeys() {
    return axios.get(`system/integrations/keys`);
}
function addKeyToIntegration(payload) {
    return axios.put(`system/integrations/${payload.integrationId}/key`, payload);
}

function getSystemIntegrationData(id) {
    return axios.get(`system/integrations/${id}`);
}

function updateSystemIntegration(payload) {
    const { integrationId, ...rest } = payload;
    return axios.put(`system/integrations/${integrationId}`, rest);
}

function updateSystemIntegrationInstance(payload) {
    const { instanceId, ...rest } = payload;
    return axios.put(`system/integrations/instances/${instanceId}`, rest);
}

function saveSystemIntegrationInstanceSettings(payload) {
    return axios.put(`system/integrations/instances/${payload.instanceId}/settings`, { settings: payload.values });
}

function createInstance(payload) {
    return axios.post(`system/integrations/${payload.integrationId}/instances`, payload);
}

function deleteInstance(instanceId) {
    return axios.delete(`system/integrations/instances/${instanceId}`);
}

function changeSystemIntegration(payload) {
    return axios.put(`system/integrations/${payload.id}`, payload);
}

/* Sveriges A-kassor */
function sverigesAkassorGetMember(id) {
    return axios.get(`integrations/sveriges-akassor/member?ssn=${id}`);
}
function sverigesAkassorOpenMember(id) {
    return axios.get(`integrations/sveriges-akassor/open/member?ssn=${id}`);
}

export default {
    getIntegrations,
    addIntegration,
    getIntegrationFromQueueId,
    getIntegrationVariables,
    getIntegrationVariablesByIntegrationId,
    saveIntegrationVariables,
    getStandardAppsSettings,
    setStandardApp,
    getExternalData,
    deploySync,
    getHistory,
    bankidAuthenticate,
    isBankIdActive,
    getSystemIntegrations,
    getCaseButtonIntegrations,
    getSystemIntegrationData,
    updateSystemIntegration,
    updateSystemIntegrationInstance,
    changeSystemIntegration,
    getSystemIntegrationKeys,
    addKeyToIntegration,
    getDeduTasks,
    getDeduTask,
    createDeduTask,
    updateDeduTask,
    getDeduBuildingEntityItems,
    getDeduProfessions,
    getDeduTaskTypes,
    getDeduTaskCategories,
    getDeduTaskSubCategories,
    getDeduTaskPriorities,
    getDeduTaskSources,
    getOrders,
    getOrder,
    getPortals,
    addPortal,
    updatePortal,
    deletePortal,
    getOrderIntegrations,
    getActiveStates,
    saveSystemIntegrationInstanceSettings,
    createInstance,
    deleteInstance,
    getPindeliveryReports,
    sverigesAkassorGetMember,
    sverigesAkassorOpenMember,
};
