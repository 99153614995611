/**
 * Apply styles from pasted excel table <style> tag to inline styles in table instead.
 * @param {string} htmlContent - The HTML content to convert.
 * @returns {string} The converted HTML content.
 */

export function convertHtmlStylesToInline(htmlContent) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    const styleMap = parseStylesFromHtml(doc.querySelectorAll('style'));
    applyStylesToElements(doc, styleMap);

    return doc.body.innerHTML;
}

/**
 * Parses styles from Excel HTML content.
 * @param {NodeListOf<Element>} styleElements - The style elements to parse.
 * @returns {Object} The map of class names to styles.
 */
export function parseStylesFromHtml(styleElements) {
    const styleMap = {};
    for (const styleElement of styleElements) {
        for (const styleRule of styleElement.textContent.split('}')) {
            const result = splitStyleRule(styleRule);
            if (result) {
                const [selectors, styles] = result;
                addStylesToMap(styleMap, selectors, styles);
            }
        }
    }
    return styleMap;
}

/**
 * Adds a style to the style map if the selector is a class.
 * @param {Object} styleMap - The map of class names to styles.
 * @param {string[]} selectors - CSS selectors.
 * @param {string} styles - CSS styles as a string.
 */
function addStylesToMap(styleMap, selectors, styles) {
    for (const selector of selectors) {
        const className = extractClassName(selector);
        if (className) {
            styleMap[className] = styles;
        }
    }
}

/**
 * Splits style rules into selectors and styles.
 * @param {string} styleRule - A single CSS style rule.
 * @returns {[string[], string] | undefined} An array containing selectors and style string, or undefined if not valid.
 */
function splitStyleRule(styleRule) {
    const [selectors, styles] = styleRule.split('{');
    if (selectors && styles) {
        return [selectors.split(','), styles.trim()];
    }
    return null;
}

/**
 * Extracts and trims the class name from a CSS selector.
 * @param {string} selector - The CSS selector.
 * @returns {string | undefined} The extracted class name, or undefined if not found.
 */
function extractClassName(selector) {
    return selector.trim().split('.')[1]; // Returns undefined if no class name is found
}

/**
 * Applies styles to elements in the document.
 * @param {Document} doc - The document to apply styles to.
 * @param {Object} styleMap - The map of class names to styles.
 */
export function applyStylesToElements(doc, styleMap) {
    for (const el of doc.querySelectorAll('[class]')) {
        const classNames = el.className.split(/\s+/);
        for (const className of classNames) {
            const inlineStyles = styleMap[className];
            if (inlineStyles) {
                el.style.cssText += inlineStyles;
            }
        }
        removeClassAttributes(el);
    }
}

/**
 * Removes class attributes from an element.
 * @param {Element} el - The element to remove class attributes from.
 */
export function removeClassAttributes(el) {
    el.removeAttribute('class');
}

/**
 * Checks if the given HTML content is from an Excel paste.
 * @param {string} htmlContent - The HTML content to check.
 * @returns {boolean} True if the content is from an Excel paste, false otherwise.
 */
export function isExcelPastedContent(htmlContent) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    return doc.querySelector('html').getAttribute('xmlns:x') === 'urn:schemas-microsoft-com:office:excel';
}

export function isImageBase64PastedContent(htmlContent) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const imgElements = doc.querySelectorAll('img');

    for (const imgElement of imgElements) {
        const src = imgElement.getAttribute('src');
        if (src && src.startsWith('data:image')) {
            return true; // Image is base64 encoded
        }
    }

    return false; // No base64 encoded images found
}

/**
 *
 * Identify if the pasted content text contains an image with http at the very start of the fragment
 * This is a workaround for the issue where you right-lick copy an inline image in eg. outlook client.
 *
 * @param {string} htmlContent
 * @returns
 */
export function imageContentIsFragmentImgHttp(htmlContent) {
    return htmlContent.includes('<!--StartFragment--><img src="http');
}
