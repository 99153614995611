<template>
    <section ref="sidemenu" class="menu-container" :style="menuStyle">
        <header class="menu-header">
            <h2 class="h2-title">{{ $t('header.filter') }}</h2>
        </header>

        <transition name="fade" mode="out-in">
            <component
                :is="sideMenuComponent.component"
                v-if="sideMenuLoaded"
                :key="sideMenuComponent.component + componentKey"
                class="sidemenu-component"
            />
        </transition>
    </section>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';

    export default {
        name: 'SideMenu',

        components: {
            CasesFilterMenu: () => import('@/components/Main/FilterMenu/Cases.vue'),
            LeaderboardFilterMenu: () => import('@/components/Leaderboard/LeaderboardFilterMenu.vue'),
        },

        data() {
            return {
                expandedWidth: 300,
                collapsedWidth: 0,
                openTimer: 0,
                openInterval: null,
                componentKey: 0,
            };
        },
        computed: {
            ...mapState({
                components: (state) => state.Header.components,
                filtersLoaded: (state) => state.Cases.filtersLoaded,
            }),
            ...mapGetters('Header', {
                sideMenuOpen: 'sideMenuOpen',
            }),
            route() {
                return this.$route.name;
            },
            sideMenuComponent() {
                return this.components.find((component) => component.routes.includes(this.route));
            },
            sideMenuLoaded() {
                switch (this.route) {
                    case 'Cases': {
                        return this.filtersLoaded;
                    }

                    default: {
                        return true;
                    }
                }
            },
            containerStyle() {
                return {
                    minWidth: this.expandedWidth + 'px',
                };
            },

            menuStyle() {
                return {
                    minWidth: this.sideMenuOpen ? this.expandedWidth + 'px' : this.collapsedWidth + 'px',
                };
            },
        },
        watch: {
            sideMenuOpen: {
                immediate: true,
                handler(val) {
                    this.handleAutoClose({ open: val });
                },
            },
            openTimer() {
                const { active, seconds } = this.sideMenuComponent.closing.autoClose;

                if (!this.openTimer || this.openTimer < seconds || !active) return;
                this.componentKey++;
                this.toggleFilters();
            },
        },

        created() {
            window.addEventListener('click', this.handleClick);
        },

        beforeDestroy() {
            window.removeEventListener('click', this.handleClick);
        },
        methods: {
            ...mapActions('Header', {
                toggleFilters: 'toggleFilters',
            }),
            handleClick(e) {
                const menu = this.$refs.sidemenu;

                if (!menu || menu.contains(e.target)) {
                    this.openTimer = 0;
                    return;
                }

                const { clickOutside } = this.sideMenuComponent.closing;

                if (!this.sideMenuOpen || !clickOutside.active) return;

                // Get the bounding rectangle of the sidemenu
                const menuRect = menu.getBoundingClientRect();

                // Get the mouse coordinates
                const mouseX = e.clientX;
                const mouseY = e.clientY;

                // Check if the mouse is inside the bounding rectangle of the sidemenu
                if (
                    mouseX >= menuRect.left &&
                    mouseX <= menuRect.right &&
                    mouseY >= menuRect.top &&
                    mouseY <= menuRect.bottom
                ) {
                    // Mouse is inside the sidemenu, return or perform any specific actions
                    return;
                }

                // Mouse is outside the sidemenu, perform additional actions (e.g., toggle filters)
                this.toggleFilters();
            },
            handleAutoClose({ open }) {
                const { active, seconds } = this.sideMenuComponent.closing.autoClose;

                if (open && active && seconds) {
                    // Start the timer

                    const TIMER_INTERVAL = 1000;
                    this.openInterval = setInterval(() => {
                        this.openTimer++;
                    }, TIMER_INTERVAL);
                } else {
                    // Reset the timer
                    clearInterval(this.openInterval);
                    this.openTimer = 0;
                }
            },
        },
    };
</script>

<style scoped>
    .filter-actions {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
    }
    .filter-list {
        display: grid;
        grid-template-rows: repeat(auto, 1fr);
        gap: 16px;
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid var(--v-gray3-base);
    }

    .menu-container {
        min-height: 100vh;
        background-color: white;
        border-right: 1px solid var(--v-gray3-base);
        overflow: hidden;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
        transition: min-width 0.2s ease-out;
        z-index: 3;
    }
    .menu-container .sidemenu-component {
        flex: 1;
        width: 300px;
        background-color: rgba(255, 255, 255, 0.9);
    }

    .menu-header {
        height: 64px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
        border-bottom: 1px solid #e0e0e0;
        width: 300px;
    }
    .h2-title {
        font-size: 1rem !important;
        font-weight: 400 !important;
        color: var(--v-gray2-base) !important;
    }
</style>

<i18n lang="json">
{
    "en": {
        "header": {
            "filter": "Filters"
        },
        "footer": {
            "reset": "Reset",
            "apply": "Apply",
            "applyAll": "Choose all"
        }
    },
    "sv": {
        "header": {
            "filter": "Filters"
        },
        "footer": {
            "reset": "Nollställ",
            "apply": "Applicera",
            "applyAll": "Välj alla"
        }
    }
}
</i18n>
