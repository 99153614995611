<template>
    <Container :dialog="dialog" width="auto" :heading="heading">
        <CallCallbackModule v-if="data.type === 5" />
        <VideoModule v-else-if="data.type === 6" :key="dialog" :start="dialog" />
        <CallModule v-else-if="data.type === 7" />
    </Container>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import CallModule from './CallModule.vue';
    import VideoModule from './VideoModule.vue';
    import CallCallbackModule from './CallCallbackModule.vue';

    export default {
        components: {
            Container: () => import('@/components/Global/Display/Popup/BasicPopupContainer.vue'),
            CallModule,
            VideoModule,
            CallCallbackModule,
        },
        data() {
            return {
                dialog: false,
            };
        },
        computed: {
            ...mapState({
                dialogState: (state) => state.Cases.toggleCommunication,
                data: (state) => state.Cases.CommunicationCreatorData,
            }),

            heading() {
                switch (this.data.type) {
                    case 1: {
                        return this.$t('container.sendEmail');
                    }
                    case 3: {
                        return this.$t('container.sendSms');
                    }
                    case 5: {
                        return this.$t('container.chooseCallType');
                    }
                    case 6: {
                        return this.$t('container.meeting');
                    }
                    case 7: {
                        return this.$t('container.call');
                    }
                    default: {
                        return this.$t('container.dialog');
                    }
                }
            },
        },

        watch: {
            dialog() {
                if (this.dialog === this.dialogState) return;
                this.openCommunication(this.dialog);
            },
            dialogState() {
                if (this.dialog === this.dialogState) return;
                this.dialog = this.dialogState;
            },
        },
        methods: {
            ...mapActions({
                openCommunication: 'Cases/openCommunication',
            }),
        },
    };
</script>
<style lang="scss">
    .v-input {
        max-width: 100% !important;
    }
    .tiptap-vuetify-editor__toolbar {
        display: none;
    }

    #tiptap_clientcard-form {
        .tiptap-vuetify-editor__content {
            min-height: 300px;
        }
    }
</style>
<i18n lang="json">
{
    "en": {
        "container": {
            "sendEmail": "Send email",
            "sendSms": "Send SMS",
            "call": "Call",
            "dialog": "Dialog",
            "meeting": "Video meeting",
            "chooseCallType": "Choose call type"
        }
    },
    "sv": {
        "container": {
            "sendEmail": "Skicka email",
            "sendSms": "Skicka SMS",
            "call": "Ring",
            "dialog": "Dialog",
            "meeting": "Videomöte",
            "chooseCallType": "Välj samtalstyp"
        }
    }
}
</i18n>
