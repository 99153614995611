import { format, startOfDay, isToday, isYesterday, isWithinInterval, subDays, subYears, isBefore } from 'date-fns';
import { sv } from 'date-fns/locale';
import i18n from '../i18n';

export function getDateFormat(date, type) {
    const formatDate = new Date(date);

    const months = [
        i18n.t('global.shortMonths.jan'),
        i18n.t('global.shortMonths.feb'),
        i18n.t('global.shortMonths.mar'),
        i18n.t('global.shortMonths.apr'),
        i18n.t('global.shortMonths.may'),
        i18n.t('global.shortMonths.jun'),
        i18n.t('global.shortMonths.jul'),
        i18n.t('global.shortMonths.aug'),
        i18n.t('global.shortMonths.sep'),
        i18n.t('global.shortMonths.oct'),
        i18n.t('global.shortMonths.nov'),
        i18n.t('global.shortMonths.dec'),
    ];

    const monthIndex = formatDate.getMonth();
    const monthName = months[monthIndex];

    let newDate = monthName + ' ' + formatDate.getDate();
    if (type === 'date' && formatDate.getFullYear() !== new Date().getFullYear()) {
        newDate = newDate + ', ' + formatDate.getFullYear();
    }
    const mins = ('0' + formatDate.getMinutes()).slice(-2);
    const hours = ('0' + formatDate.getHours()).slice(-2);
    const newTime = hours + ':' + mins;
    const month = monthName;

    if (type === 'date') {
        return newDate;
    }

    if (type === 'time') {
        return newTime;
    }

    if (type === 'month') {
        return month;
    }
}

// Calculating the time over SLA based on percentage between Slatime and DateTime
export function getServiceTime(SlaTime, DateTime, Type, DateNow) {
    if (SlaTime <= DateTime) {
        return '';
    }

    const startDate = new Date(DateTime).getTime();
    const endDate = new Date(SlaTime).getTime();
    const date = DateNow;
    const range = endDate - startDate;
    const diff = Math.abs(Math.max(endDate - date));
    let seconds = Math.floor((diff / 1000) % 60);
    let minutes = Math.floor((diff / (1000 * 60)) % 60);
    let hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    let days = Math.floor(diff / 86400000);

    const ratioColor = (ratio, color1, color2) => {
        const hex = (x) => {
            x = x.toString(16);
            return x.length == 1 ? '0' + x : x;
        };
        const r = Math.ceil(
            Number.parseInt(color1.slice(0, 2), 16) * ratio + Number.parseInt(color2.slice(0, 2), 16) * (1 - ratio),
        );
        const g = Math.ceil(
            Number.parseInt(color1.slice(2, 4), 16) * ratio + Number.parseInt(color2.slice(2, 4), 16) * (1 - ratio),
        );
        const b = Math.ceil(
            Number.parseInt(color1.slice(4, 6), 16) * ratio + Number.parseInt(color2.slice(4, 6), 16) * (1 - ratio),
        );
        return '#' + hex(r) + hex(g) + hex(b);
    };

    if (Type == 'text') {
        if (days > 0) {
            let returnValue = days + ' ';

            returnValue += i18n.t('global.shortDays');

            returnValue += ' ' + hours + ' ';

            returnValue += i18n.t('global.shortHours');

            return returnValue;
        }

        if (hours > 0) {
            let returnValue = hours + ' ';

            returnValue += i18n.t('global.shortHours');

            returnValue += ' ' + minutes + ' m.';

            return returnValue;
        }

        if (minutes > 0) {
            return minutes + ' m. ' + seconds + ' s.';
        }

        if (seconds >= 0) {
            if (seconds == 0) {
                return '0 s.';
            }
            if (seconds < 61) {
                return seconds + ' s.';
            }
            seconds -= seconds % 10;
            return seconds + ' s.';
        }
    } else if (Type == 'color') {
        const color1 = '939393'; // grey
        const color2 = '2cb5a3'; // blue

        if (days > 0) {
            return ratioColor(0, color1, color2);
        }

        if (hours > 0) {
            return ratioColor(0.25, color1, color2);
        }

        if (minutes > 0) {
            return ratioColor(0.5, color1, color2);
        }

        if (seconds >= 0) {
            return ratioColor(1, color1, color2);
        }

        if (days > 0) {
            return '#ef628e'; // red
        }
    } else if (Type == 'time') {
        seconds = Math.floor((range / 1000) % 60);
        minutes = Math.floor((range / (1000 * 60)) % 60);
        hours = Math.floor((range / (1000 * 60 * 60)) % 24);
        days = Math.floor(range / (24 * 60 * 60 * 1000));

        if (days > 0) {
            if (days == 1) {
                return days + ' ' + i18n.t('global.day');
            }
            return days + ' ' + i18n.t('global.days');
        }

        if (hours > 0) {
            if (hours == 1) {
                return hours + ' ' + i18n.t('global.hour');
            }
            return hours + ' ' + i18n.t('global.hours');
        }

        if (minutes > 0) {
            if (minutes == 1) {
                return minutes + ' min';
            }
            return minutes + ' min';
        }

        if (seconds >= 0) {
            if (seconds == 0) {
                return '';
            }

            if (seconds == 1) {
                return seconds + ' s';
            }
            return seconds + ' s';
        }
    }
}

export function getSliderColor(SlaTime, DateTime, DateNow) {
    const color1 = '939393'; // grey
    const color2 = '2cb5a3'; // blue

    const ratioColor = (ratio, color1, color2) => {
        const hex = (x) => {
            x = x.toString(16);
            return x.length == 1 ? '0' + x : x;
        };
        const r = Math.ceil(
            Number.parseInt(color1.slice(0, 2), 16) * ratio + Number.parseInt(color2.slice(0, 2), 16) * (1 - ratio),
        );
        const g = Math.ceil(
            Number.parseInt(color1.slice(2, 4), 16) * ratio + Number.parseInt(color2.slice(2, 4), 16) * (1 - ratio),
        );
        const b = Math.ceil(
            Number.parseInt(color1.slice(4, 6), 16) * ratio + Number.parseInt(color2.slice(4, 6), 16) * (1 - ratio),
        );
        return '#' + hex(r) + hex(g) + hex(b);
    };

    const getSliderPercentage = (SlaTime, DateTime) => {
        const startDate = new Date(DateTime).getTime();
        const endDate = new Date(SlaTime).getTime();
        if (endDate <= startDate) {
            return -1;
        }
        const date = DateNow;
        const range = endDate - startDate;
        const diff = Math.max(endDate - date);
        const percentage = (1 - diff / range) * 100;

        if (diff >= 0) {
            return percentage;
        }
        return 100;
    };

    let ratio = getSliderPercentage(SlaTime, DateTime) / 100;

    if (ratio < 0) {
        ratio = 1;
    }

    return ratioColor(ratio, color1, color2);
}

export function checkSLATime(SlaTime, DateTime, DateNow) {
    const startDate = new Date(DateTime).getTime();
    const endDate = new Date(SlaTime).getTime();

    if (endDate <= startDate) {
        return -1;
    }

    const date = DateNow;
    const range = endDate - startDate;
    const diff = Math.max(endDate - date);
    const percentage = (1 - diff / range) * 100;

    if (diff > 0 && diff <= 5000) {
        return true;
    }
    return false;
}

export function getSliderPercentage(SlaTime, DateTime, DateNow) {
    const startDate = new Date(DateTime).getTime();
    const endDate = new Date(SlaTime).getTime();

    if (endDate <= startDate) {
        return -1;
    }

    const date = DateNow;
    const range = endDate - startDate;
    const diff = Math.max(endDate - date);
    const percentage = (1 - diff / range) * 100;

    if (diff >= 0) {
        return percentage;
    }
    return 100;
}

export function getSpinnerPercentage(end, start, now, ciel) {
    if (end <= start) {
        return -1;
    }

    const range = start - end;
    const timePassed = now - start;

    if (ciel) {
        return Math.min(Math.ceil((-100 * timePassed) / (25 * range)) * 24.5, 100);
    }
    return Math.min(Math.round((-100 * timePassed) / (25 * range)) * 24.5, 100);
}

export function getSlaPercentage(SlaTime, DateTime, DateNow) {
    const startDate = new Date(DateTime).getTime();
    const endDate = new Date(SlaTime).getTime();

    if (endDate <= startDate) {
        return -1;
    }

    const date = DateNow;
    const range = endDate - startDate;
    const diff = Math.max(endDate - date);
    const percentage = (1 - diff / range) * 100;

    if (percentage >= 200) {
        return 200;
    }
    return percentage;
}

export function phoneDateTimeFormatter(timeInput) {
    const time = new Date(timeInput);
    const compareDate = startOfDay(new Date(timeInput));
    const today = startOfDay(new Date());
    const yesterday = subDays(today, 1);
    const weekAgo = subDays(today, 7);
    const yearAgo = subYears(today, 1);

    if (isToday(compareDate)) {
        return format(time, 'HH:mm', { locale: sv });
    }
    if (isYesterday(compareDate)) {
        return i18n.t('global.yesterday').toLowerCase();
    }
    if (isWithinInterval(compareDate, { start: weekAgo, end: yesterday })) {
        return format(time, 'iiii', { locale: sv });
    }
    if (isBefore(compareDate, yearAgo)) {
        return format(time, 'dd MMM', { locale: sv });
    }
    return format(time, 'P', { locale: sv });
}
