<template>
    <v-tooltip v-if="menuDisabled" top>
        <template #activator="{ on }">
            <div
                class="button"
                :class="totallyDisabled ? 'button-disabled' : 'button-enabled'"
                v-on="on"
                @click="directClick"
            >
                <v-icon size="20" :color="totallyDisabled ? 'var(--v-gray3-base)' : 'var(--v-gray2-base)'">
                    mdi-timer-outline
                </v-icon>
                <span class="timer-text">
                    {{ formatTimeFromSeconds(timerSeconds) }}
                </span>
            </div>
        </template>
        {{ totallyDisabled ? $t('wrapupTime.noExtendedWrapup') : $t('wrapupTime.startExtendedWrapup') }}
    </v-tooltip>
    <v-menu
        v-else
        ref="menuRef"
        v-model="openList"
        elevation="0"
        :close-on-content-click="true"
        :nudge-top="150"
        :nudge-left="15"
        transition="scroll-y-reverse-transition"
        offset-y
        max-width="200px"
        min-width="200px"
        :disabled="menuDisabled"
    >
        <template #activator="{ on, attrs }">
            <div class="button button-enabled" v-bind="attrs" v-on="on">
                <v-icon size="20" :color="wrapupTimer > 0 ? 'var(--v-primary-base)' : 'var(--v-gray2-base)'">
                    mdi-timer-outline
                </v-icon>
                <span class="timer-text">
                    {{ formatTimeFromSeconds(timerSeconds) }}
                </span>
            </div>
        </template>

        <v-card elevation="0">
            <v-list>
                <v-list-item
                    v-for="(option, index) of menuOptions"
                    :key="index"
                    :disabled="isOptionDisabled(option.value)"
                    @click="clickListButton(option.value)"
                >
                    <v-icon size="18" class="mr-2">{{ option.icon }}</v-icon>
                    <v-list-item-title>{{ option.text }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import { formatTimeFromSeconds } from '@/utils/DateFormatter';
    import { wrapupActions } from '@/enums/users.enums';

    export default {
        data() {
            return {
                wrapupTimer: null,
                interval: null,
                menuOptions: [
                    {
                        text: this.$t('wrapupTime.extendWrapup'),
                        icon: 'mdi-timer-plus-outline',
                        value: wrapupActions.EXTEND,
                    },
                    {
                        text: this.$t('wrapupTime.resetWrapup'),
                        icon: 'mdi-timer-off-outline',
                        value: wrapupActions.RESET,
                    },
                ],
                openList: false,
            };
        },
        computed: {
            ...mapState({
                userSettings: (state) => state.System.userSettings,
                loggedInUser: (state) => state.Auth.userObject,
            }),
            userLastHangup() {
                return new Date(this.loggedInUser.LastHangup);
            },
            userCurrentWrapupAmount() {
                return this.loggedInUser.ExtendedWrapup;
            },
            extendedWrapupTime() {
                return Number(this.userSettings.queue.extendedWrapup.extendedWrapupTime.value);
            },
            extendedWrapupAmount() {
                return Number(this.userSettings.queue.extendedWrapup.extendedWrapupAmount.value);
            },
            extendedWrapupAmountValid() {
                return this.userCurrentWrapupAmount < this.extendedWrapupAmount;
            },
            menuDisabled() {
                return !this.wrapupTimer;
            },
            totallyDisabled() {
                return this.menuDisabled && !this.extendedWrapupAmountValid;
            },
            timerSeconds() {
                return this.wrapupTimer > 0 ? this.wrapupTimer : this.extendedWrapupTime;
            },
        },
        watch: {
            userLastHangup: {
                handler() {
                    this.setTimer();
                },
                deep: true,
            },
            openList: {
                handler(val) {
                    this.$emit('menuRef', val ? this.$refs.menuRef : null);
                },
                deep: true,
            },
        },
        created() {
            this.init();
        },
        methods: {
            formatTimeFromSeconds,
            ...mapActions({
                storeExtendWrapupTime: 'Users/extendWrapupTime',
                storeResetLastHangup: 'Users/resetLastHangup',
            }),
            init() {
                this.setTimer();
            },

            setTimer() {
                if (new Date() > this.userLastHangup) {
                    this.wrapupTimer = 0;
                    return;
                }
                clearInterval(this.interval);

                this.wrapupTimer = (this.userLastHangup.getTime() - Date.now()) / 1000;
                this.interval = setInterval(() => {
                    if (this.wrapupTimer > 0) {
                        this.wrapupTimer--;
                    } else {
                        this.wrapupTimer = 0;
                    }
                }, 1000);
            },
            directClick() {
                if (this.totallyDisabled || !this.menuDisabled) return;
                this.clickListButton(wrapupActions.EXTEND);
            },
            clickListButton(value) {
                switch (value) {
                    case wrapupActions.EXTEND: {
                        this.storeExtendWrapupTime({
                            lastHangup: this.userLastHangup,
                            extendedWrapup: this.extendedWrapupTime,
                            userId: this.loggedInUser.userId,
                        });
                        break;
                    }
                    case wrapupActions.RESET: {
                        this.storeResetLastHangup(this.loggedInUser.userId);
                        break;
                    }
                    default: {
                        console.log('Value not found');
                        break;
                    }
                }
            },
            isOptionDisabled(type) {
                return type === wrapupActions.EXTEND && !this.extendedWrapupAmountValid;
            },
        },
    };
</script>
<style scoped lang="scss">
    .timer-text {
        line-height: 1rem;
    }
    .button {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 6px 10px;
        gap: 4px;
        border-radius: 8px;
        flex: 0;
        justify-content: flex-start;
    }

    .button-disabled {
        background-color: rgba(255, 255, 255, 0.6);
        color: var(--v-gray3-base);
        cursor: default;
    }

    .button-enabled {
        color: var(--v-gray2-base);
        background-color: white;
        cursor: pointer;
    }
</style>
<i18n lang="json">
{
    "en": {
        "wrapupTime": {
            "wrapupTime": "Wrapup time",
            "resetWrapup": "Reset Wrapup",
            "extendWrapup": "Extend Wrapup",
            "noExtendedWrapup": "No wrapup time available",
            "startExtendedWrapup": "Start extended wrapup time"
        }
    },
    "sv": {
        "wrapupTime": {
            "wrapupTime": "Bearbetningstid",
            "resetWrapup": "Avsluta bearbetning",
            "extendWrapup": "Förläng bearbetningstid",
            "noExtendedWrapup": "Ingen bearbetningstid tillgänglig",
            "startExtendedWrapup": "Starta extra bearbetningstid"
        }
    }
}
</i18n>
