export const presence = {
    offline: '#aaaaaa',
    available: '#2cb5a3',
    inCall: '#804c89',
    away: '#c1a73a',
    dnd: '#b5405e',
};

export const standard = {
    color1: '#939393',
    color2: '#F2F2F2',
    color3: '#D0D0D0',
    color4: '#2CB5A3',
    color5: '#61BDD3',
    color6: '#CF73E0',
};

export const themeColors = {
    primary: '#00c4ad',
    secondary: '#00c4ad',
    accent: '#C0E6E2',
    accent2: '#F0C1CD',
    accent3: '#A0CBEF',
    accent4: '#008ffb',
    accent5: '#ff4560',
    accent6: '#00e396',
    accent7: '#feb019',
    accent8: '#775dd0',
    color1: '#ef628e',
    color2: '#3299b3',
    color3: '#00c4ad',
    color4: '#cf73e0',
    color5: '#EFAE62',
    gray0: '#e6e6e6',
    gray1: '#A2A2A2',
    gray2: '#717171',
    gray3: '#f2f2f2',
    gray4: '#2b2b2b',
    gray5: '#F5F5F5',
    gray6: '#EEEEEE',
    error: '#fd3636',
    bg: '#f9f9f9',
    appleSilver1: '#f5f5f7',
    silverBlue: '#f4f6f9',

    // Button styles
    buttonSelected: '#fafafa',
    buttonSelectedHover: '#ffffff',
    buttonNotSelected: '#f2f2f2',
    buttonNotSelectedHover: '#e3e3e3',
};
