import axios from 'axios';

//= ===================================================//
//                     Notes                          //
//= ===================================================//

function getNotes(id) {
    return axios.get(`/board/group/${id}/notes`);
}

function addNewNote(q) {
    return axios.post(`/board/notes`, q);
}

function updateNote(q) {
    return axios.put(`/board/notes/${q.ID}`, q);
}

function updateOrdinalOnNotes(q) {
    return axios.put(`/board/notes/`, q);
}

function deleteNote(q) {
    return axios.delete(`/board/notes/${q}`);
}

//= ===================================================//
//                      Tags                          //
//= ===================================================//

function addNewTag(q) {
    return axios.post(`/board/tags`, q);
}

function getTags() {
    return axios.get(`/board/tags`);
}

function deleteTag(q) {
    return axios.delete(`/board/tags/${q}`);
}

function deleteTagOnNote(payload) {
    return axios.delete(`/board/notes/${payload.noteID}/tags/${payload.tagID}`);
}

export default {
    getNotes,
    addNewNote,
    deleteNote,
    addNewTag,
    updateNote,
    updateOrdinalOnNotes,
    getTags,
    deleteTag,
    deleteTagOnNote,
};
