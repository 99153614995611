<template>
    <v-card flat class="v-card mx-auto px-3">
        <v-list v-if="activeTemplates.length > 0">
            <v-tooltip right>
                <template #activator="{ on, attrs }">
                    <v-btn v-bind="attrs" icon class="noSignature" v-on="on" @click="setSelectSignature({})">
                        <v-icon>mdi-minus-circle-outline</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('SignatureView.noSignature') }}</span>
            </v-tooltip>

            <v-tooltip
                v-for="(item, i) in activeTemplates"
                :key="item.ID"
                right
                openDelay="200"
                color="white"
                style="z-index: 20"
            >
                <template #activator="{ on, attrs }">
                    <v-list-item
                        class="listItem"
                        :class="selectedSignature.ID == item.ID ? 'activeListItem' : ''"
                        v-bind="attrs"
                        v-on="on"
                        @click="setSelectSignature(item)"
                        @mouseover="setSignatureImages(i)"
                    >
                        <v-list-item-title>{{ item.Name }}</v-list-item-title>
                    </v-list-item>
                </template>
                <span v-dompurify-html="item.Content" class="signatureTooltip"></span>
            </v-tooltip>
        </v-list>
        <div v-else class="ma-5">
            <!-- Det finns tyvärr inga signaturer. -->
            {{ $t('SignatureView.noSignatures') }}
            <br />
            <!-- Lägg till genom att gå till mall hanteringen. -->
            {{ $t('SignatureView.addGotoTempl') }}
            <br />
            <!-- Endast aktiverade mallar syns -->
            {{ $t('SignatureView.onlyActivated') }}
            <br />
            <br />
            <v-divider />
            <br />
            <!-- Mall-hanteringen-->
            <v-btn dense color="primary" elevation="0" @click="$router.replace('/Admin/Templates')">
                {{ $t('SignatureView.templateManager') }}
            </v-btn>
        </div>
    </v-card>
</template>

<script>
    import { mapState } from 'vuex';
    import { getImagesInContent } from '../../../directives/shadowDom';

    export default {
        data() {
            return {
                activeTemplates: [],
            };
        },

        computed: {
            ...mapState({
                signatureTemplates: (state) => state.Admin.signatureTemplates,
                selectedSignature: (state) => state.Cases.selectedCaseSignature,
            }),
        },
        mounted() {
            this.activeTemplates = structuredClone(this.getActiveTemplates());
        },
        methods: {
            getImagesInContent,
            setSelectSignature(signatureObj) {
                this.$store.commit('Cases/SET_SELECTED_SIGNATURE', signatureObj);
            },
            getActiveTemplates() {
                return this.signatureTemplates.filter((item) => item.IsActive == '1');
            },
            async setSignatureImages(itemIndex) {
                for (let i = 0; i < this.activeTemplates.length; i++) {
                    if (i !== itemIndex) continue;
                    const content = this.activeTemplates[i].Content;
                    this.activeTemplates[i].Content = await this.getImagesInContent(content);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .listItem {
        color: var(--v-gray2-base);
        background-color: var(--v-gray5-base) !important;
        border-radius: 6px;
        overflow: hidden;
        text-align: center;
        height: 20px;
        margin: 8px;
    }
    .activeListItem {
        color: var(--v-primary-base) !important;
        border: 1px solid var(--v-primary-base);
    }
    .noSignature {
        color: var(--v-gray4-base) !important;
        text-align: center !important;
    }
    .v-card {
        width: 100%;
        height: 565px;
        overflow-y: scroll;
        text-align: center;
    }
    .signatureTooltip {
        color: black;
    }
</style>
<i18n lang="json">
{
    "en": {
        "SignatureView": {
            "noSignature": "No signature",
            "noSignatures": "There are no signatures",
            "addGotoTempl": "Add by going to the template manager.",
            "templateManager": "Template manager",
            "onlyActivated": "Only activated templates are visible"
        }
    },
    "sv": {
        "SignatureView": {
            "noSignature": "Ingen signatur",
            "noSignatures": "Det finns tyvärr inga signaturer.",
            "addGotoTempl": "Lägg till genom att gå till mall-hanteringen.",
            "templateManager": "Mall-hanteringen",
            "onlyActivated": "Endast aktiverade mallar syns"
        }
    }
}
</i18n>
