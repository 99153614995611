<template>
    <v-avatar
        v-bind="bind"
        right
        :size="size"
        class="d-flex justify-center align-center avatar"
        :color="color"
        :style="avatarStyle"
    >
        <img src="@/assets/General/C1_icon.svg" height="12" width="12" class="img-scale" />
    </v-avatar>
</template>
<script>
    export default {
        props: {
            name: {
                type: String,
                default: '',
            },

            index: {
                type: Number,
                default: 100,
            },
            size: {
                type: [Number, String],
                default: 40,
            },

            color: {
                type: String,
                default: 'gray3',
            },

            x1: {
                type: Boolean,
                default: false,
            },
            x2: {
                type: Boolean,
                default: false,
            },
            x4: {
                type: Boolean,
                default: false,
            },
            border: {
                type: String,
                default: '0 solid var(--v-accent2-base)',
            },
            borderRadius: {
                type: String,
                default: '50%',
            },
            bind: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {};
        },
        computed: {
            avatarStyle() {
                return {
                    borderRadius: this.borderRadius,
                    height: `${this.size}px`,
                    width: `${this.size}px`,
                };
            },
        },
    };
</script>
<style scoped>
    .avatar-container {
        border-radius: 50%;
    }

    .avatar {
        transition: opacity 0.2s;
    }

    .img-scale {
        transform: scale(0.65);
    }
</style>
