import queues from '@/api/queues/queues';
import api from '@/api/templates/systemtemplates';
import i18n from '@/i18n';
import router from '@/router';
import { format, parseISO } from 'date-fns';

const state = {
    templatesItems: [],
    selectedTemplate: {
        template: {
            content: '',
            attachments: [],
        },
    },
    selectedCategory: {
        id: 0,
        richTextEditor: 0,
        IsAttachmentActivated: 1,
    },
    emptyTemplate: {
        template: {
            id: 0,
            categoryId: 0,
            content: '',
            attachments: [],
            subject: '',
            name: '',
            isActive: 1,
            language: 'SWE',
            createdAt: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            queues: [],
        },
    },
    queuesFilter: {
        queueIds: [],
    },
    editMode: true,
};
const actions = {
    applyMergeTag({ commit }, mergeTag) {
        commit('APPLY_MERGE_TAG', mergeTag);
    },
    toggleOpen({ dispatch, state, commit }, templateId) {
        if (!state.editMode) {
            return;
        }

        if (templateId === state.selectedTemplate.template.id) {
            commit('SET_SELECTED_TEMPLATE', {});
            return;
        }

        dispatch('getTemplateById', templateId);
    },

    createTemplate({ commit }) {
        commit('CREATE_NEW_TEMPLATE');
        commit('SET_SELECTED_TEMPLATE', state.emptyTemplate.template);
    },

    /**
     * Updates the template.
     *
     * @param {Object} commit - The commit object.
     * @param {Object} payload - The payload object containing the updated template.
     */
    updateTemplate({ commit }, payload) {
        commit('CHANGE_TEMPLATE', payload);
    },

    async saveTemplate({ state, dispatch }) {
        if (state.editMode) {
            await dispatch('update');
        } else {
            await dispatch('create');
        }
    },
    async create({ state, commit, dispatch }) {
        try {
            const res = await api.createTemplate(state.selectedTemplate.template);

            if (res.status === 201) {
                this._vm.$toasted.show(i18n.t('global.requestResponses.success'), {
                    icon: 'check',
                    type: 'success',
                });

                commit('SET_SELECTED_TEMPLATE', {});
                commit('TOGGLE_OPEN');
                commit('RESET');
                dispatch('templateByCategory', { page: 1, limit: 10 });
                return;
            }

            throw new Error(res?.response?.data || 'Unexpected error occurred');
        } catch (error) {
            this._vm.$toasted.show(error, {
                icon: 'cancel',
                type: 'error',
            });
        }
    },
    async update({ state }) {
        try {
            const res = await api.updateTemplate(state.selectedTemplate.template);
            if (res.status === 200) {
                this._vm.$toasted.show(i18n.t('global.requestResponses.success'), {
                    icon: 'check',
                    type: 'success',
                });

                return;
            }

            throw new Error(res?.response?.data || 'Unexpected error occurred');
        } catch (error) {
            this._vm.$toasted.show(error.response.data, {
                icon: 'cancel',
                type: 'error',
            });
        }
    },

    /**
     * Removes a template from the system.
     *
     * @async
     * @function removeTemplate
     * @param {Object} options - The options object.
     * @param {Object} options.state - The state object.
     * @param {Object} options.commit - The commit object.
     * @returns {Promise<void>} - A promise that resolves when the template is successfully removed.
     * @throws {Error} - If an error occurs during the removal process.
     */
    async removeTemplate({ state, commit }) {
        if (!state.editMode) {
            commit('REMOVE_TEMPLATE', state.selectedTemplate.template.id);
            return;
        }

        try {
            const res = await api.removeTemplate(state.selectedTemplate.template.id);

            if (res.status === 200) {
                this._vm.$toasted.show(i18n.t('global.requestResponses.success'), {
                    icon: 'check',
                    type: 'success',
                });

                commit('REMOVE_TEMPLATE', state.selectedTemplate.template.id);
                return;
            }
            throw new Error(res?.response?.data || 'Unexpected error occurred');
        } catch (error) {
            this._vm.$toasted.show(error, {
                icon: 'cancel',
                type: 'error',
            });
        }
    },
    /**
     * Selects a category and updates the route path with the selected category ID.
     * @param {Object} commit - The commit function from Vuex store.
     * @param {number} categoryId - The ID of the category to be selected.
     */
    selectCategory({ commit }, category) {
        commit('SET_SELECTED_CATEGORY', category);
    },
    /**
     * Retrieves the categories from the API.
     *
     * @returns {Array} An array of categories.
     * @throws {Error} If an unexpected error occurs or if the response status is not 200.
     */
    async categories() {
        try {
            const res = await api.categories();
            if (res.status === 200 && Array.isArray(res.data)) return res.data;

            throw new Error(res.data?.message || 'Unexpected error occurred');
        } catch (error) {
            this._vm.$toasted.show(error, {
                icon: 'cancel',
                type: 'error',
            });
            return [];
        }
    },
    /**
     * Retrieves templates by category.
     *
     * @param {Object} state - The state object.
     * @param {string} categoryId - The category ID.
     * @returns {Array} - An array of templates.
     */
    async templateByCategory({ state, commit }, { page, limit, queueIds }) {
        const offset = (page - 1) * limit;
        try {
            const res = await api.templateByCategory(state.selectedCategory.id, {
                offset,
                limit,
                search: '',
                queueIds: queueIds?.length ? queueIds : state.queuesFilter.queueIds,
            });
            if (res.status === 200 && Array.isArray(res.data)) {
                if (page === 1) {
                    commit('SET_TEMPLATES', res.data);
                } else {
                    commit('SET_TEMPLATES', state.templatesItems.concat(res.data));
                }
                return;
            }

            throw new Error(res.data?.message || 'Unexpected error occurred');
        } catch (error) {
            this._vm.$toasted.show(error, {
                icon: 'cancel',
                type: 'error',
            });
            return [];
        }
    },

    /**
     * Retrieves a template by its ID.
     *
     * @param {Object} _ - The Vuex context object.
     * @param {string} templateId - The ID of the template to retrieve.
     * @returns {Promise<Object|null>} - A promise that resolves to the retrieved template object, or null if an error occurred.
     */
    async getTemplateById({ commit }, templateId) {
        try {
            const res = await api.getTemplateById(templateId);
            if (res.status === 200 && res.data) {
                const template = { ...res.data.result, attachments: res.data.attachments };
                commit('SET_SELECTED_TEMPLATE', template);
                return template;
            }

            throw new Error(res.data?.message || 'Unexpected error occurred');
        } catch (error) {
            this._vm.$toasted.show(error, {
                icon: 'cancel',
                type: 'error',
            });
            return [];
        }
    },

    /**
     * Sets the queues filter in the state.
     *
     * @param {Object} commit - The commit function from Vuex store.
     * @param {number[]} queues - The queues to be set as filter
     */
    setQueuesFilter({ dispatch, commit }, queues) {
        const queueIds = queues.map((queue) => queue.ID);
        commit('SET_QUEUES_FILTER', queueIds);
        dispatch('templateByCategory', { page: 1, limit: 20, queueIds });
    },
};

const mutations = {
    SET_QUEUES_FILTER(state, queues) {
        state.queuesFilter.queueIds = queues;
    },
    RESET(state) {
        state.selectedTemplate = {
            template: {
                content: '',
                attachments: [],
            },
        };
        state.emptyTemplate = {
            template: {
                id: 0,
                categoryId: 0,
                content: '',
                attachments: [],
                subject: '',
                name: '',
                isActive: 1,
                language: 'SWE',
            },
        };
    },

    APPLY_MERGE_TAG(state, mergeTag) {
        state.selectedTemplate.template.content += '{{' + mergeTag + '}}';
    },

    TOGGLE_OPEN(state) {
        state.editMode = !state.editMode;
    },

    /**
     * Creates a new template in.
     */
    CREATE_NEW_TEMPLATE(state) {
        state.editMode = false;
        state.emptyTemplate.template.categoryId = state.selectedCategory.id;
        state.templatesItems.unshift(state.emptyTemplate.template);
    },
    /**
     * Updates the template with the specified key-value pair.
     *
     * @param {Object} state - The current state of the store.
     * @param {Object} payload - The payload containing the key and value to update the template.
     * @param {string} payload.key - The key of the template property to update.
     * @param {*} payload.value - The new value to assign to the template property.
     */
    CHANGE_TEMPLATE(state, { key, value }) {
        state.selectedTemplate.template[key] = value;
    },

    /**
     * Removes a template from the state.
     *
     * @param {Object} state - The current state object.
     * @param {string} templateId - The ID of the template to be removed.
     */
    REMOVE_TEMPLATE(state, templateId) {
        state.templatesItems = state.templatesItems.filter((template) => template.id !== templateId);
        state.editMode = true;
    },
    /**
     * Sets the selected template in the state.
     *
     * @param {Object} state - The Vuex state object.
     * @param {Object} template - The template object to set as the selected template.
     */
    SET_SELECTED_TEMPLATE(state, template) {
        state.selectedTemplate.template = template;
        if (template && Object.keys(template).length === 0 && template.constructor === Object) {
            return;
        }

        // Make the attachment array to fit into Rich Text Editor
        state.selectedTemplate.template.attachments =
            template.attachments.map((attachment) => ({
                type: 'file',
                file: attachment,
            })) || [];
    },
    /**
     * Sets the templates in the state.
     *
     * @param {Object} state - The Vuex state object.
     * @param {Array} templates - The templates to be set in the state.
     */
    SET_TEMPLATES(state, templates) {
        state.templatesItems = templates;
    },
    /**
     * Sets the selected category ID in the state.
     *
     * @param {Object} state - The Vuex state object.
     * @param {number} categoryId - The ID of the selected category.
     */
    SET_SELECTED_CATEGORY(state, category) {
        state.selectedCategory = category;
        state.editMode = true;
    },
};
const getters = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
