<template>
    <div class="d-flex flex-column py-2 justify-center align-center button-wrapper">
        <BuilderButton
            :key="-2"
            :item="{ id: -2, name: 'Back', icon: 'mdi-chevron-left', iconSelected: 'mdi-chevron-left', active: false }"
        />
        <div class="main-buttons">
            <BuilderButton v-for="(item, index) of selectedMenuItems" :key="index" :item="item" />
        </div>

        <BuilderButton
            :key="-4"
            :item="{
                id: -4,
                name: 'Settings',
                icon: 'mdi-cog',
                iconSelected: 'mdi-cog',
                active: widgetSettingsDialog,
            }"
        />

        <BuilderButton
            :key="-3"
            :item="{
                id: -3,
                name: 'Unlink relation',
                icon: relationIdIcon,
                iconSelected: relationIdIcon,
                active: isRelationActive,
            }"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import BuilderButton from '@/components/Global/BuilderMenu/BuilderButton.vue';

    export default {
        components: {
            BuilderButton,
        },

        computed: {
            ...mapState({
                objectToEdit: (state) => state.BuilderMenu.objectToEdit,
                menuOptions: (state) => state.BuilderMenu.menuOptions,
                options: (state) => state.BuilderMenu.options,
                widgetSettingsDialog: (state) => state.BuilderMenu.widgetSettingsDialog,
            }),
            selectedMenuItems() {
                const keys = Object.keys(this.objectToEdit);
                const filteredItems = [];

                for (const key of keys) {
                    if (this.options[key] != null) {
                        const matchingMenu = this.menuOptions.find((menu) => {
                            return menu.id === this.options[key];
                        });

                        if (matchingMenu) {
                            filteredItems.push(matchingMenu);
                        }
                    }
                }

                return filteredItems;
            },
            relationIdIcon() {
                const { relationId } = this.objectToEdit.properties.main;
                if (!relationId || !relationId.length) {
                    return 'mdi-link-variant';
                }

                return 'mdi-link-variant-off';
            },
            isRelationActive() {
                const { relationId } = this.objectToEdit.properties.main;
                return relationId && relationId.length;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .button-wrapper {
        height: 100%;
    }

    .main-buttons {
        flex: 1;
    }
</style>
<i18n lang="json">
{}
</i18n>
