<template>
    <main class="d-flex" :class="getPositionClass(position)">
        <v-tooltip bottom>
            <template #activator="{ on, attrs }">
                <div class="text-box" v-bind="attrs" v-on="on">
                    <Transition name="fade">
                        <div
                            :style="{
                                color: item.UnreadMessages > 0 ? 'var(--v-gray4-base)' : 'var(--v-gray2-base)',
                                fontWeight: item.UnreadMessages > 0 ? '500' : '400',
                            }"
                            class="d-flex flex-column"
                            :class="getPositionClass(position, 'column')"
                        >
                            <div
                                class="text-truncate d-flex flex-row align-center"
                                :style="
                                    background && value !== $t('noOutcome') && value !== ' '
                                        ? {
                                              backgroundColor: backgroundClr,
                                              color: 'white',
                                              padding: '4px',
                                              paddingLeft: '12px',
                                              height: '32px',
                                              fontSize: listLayout == 'big' ? '14px' : '12px',
                                              borderRadius: '4px',
                                          }
                                        : ''
                                "
                            >
                                <span class="text-truncate">
                                    <v-icon v-if="compactValue">mdi-application-brackets-outline</v-icon>
                                    {{ compactValue ? $t('containsHtml') : formatedValue }}
                                </span>
                            </div>
                            <div
                                v-if="listLayout.value == 'big'"
                                class="text-truncate d-flex"
                                style="font-weight: 400 !important; color: var(--v-gray1-base)"
                            ></div>
                        </div>
                    </Transition>
                </div>
            </template>
            <span v-if="listLayout.value == 'big'">
                {{ tooltipValue }}
            </span>
            <span v-else>{{ headerValue }}: {{ tooltipValue }}</span>
        </v-tooltip>
    </main>
</template>

<script>
    import { positionTypes } from '@/enums/cases.enums';
    import { getPositionClass } from '@/helpers/cases/cases.helper';

    export default {
        props: {
            value: {
                type: String,
                default: '',
            },

            header: {
                type: String,
                default: '',
            },

            item: {
                type: Object,
                default: () => {},
            },

            backgroundClr: {
                type: String,
                default: '',
            },

            position: {
                type: String,
                default: positionTypes.LEFT,
                validator(value) {
                    // Shorthand for validator
                    return Object.values(positionTypes).includes(value);
                },
            },
        },
        data() {
            return {
                background: false,
                divStyle: {},
            };
        },
        computed: {
            listLayout() {
                return this.$store.state.Cases.listLayout;
            },
            tooltipValue() {
                return this.value || this.item.clientId;
            },
            formatedValue() {
                return this.value || this.item.clientid;
            },
            headerValue() {
                return this.header;
            },
            compactValue() {
                const htmlRegex = /<\/?[a-z][\S\s]*>/;
                return htmlRegex.test(this.value);
            },
        },
        mounted() {
            if (this.backgroundClr) {
                this.background = true;
                this.divStyle.backgroundColor = this.backgroundClr;
            }
        },
        methods: {
            getPositionClass,
        },
    };
</script>

<style scoped>
    .text-font-size {
        font-size: 12px;
    }
    .text-box {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .text-container {
        font-weight: 400 !important;
        color: var(--v-gray1-base) !important;
    }
</style>
<i18n lang="json">
{
    "en": {
        "containsHtml": "Hover to show more information. This contains HTML",
        "noOutcome": "No outcome"
    },
    "sv": {
        "containsHtml": "Håll musen över för att visa mer information. Denna innehåller HTML",
        "noOutcome": "Inget utfall"
    }
}
</i18n>
