module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "sv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
        "chooseLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select language"])}
      },
      "sv": {
        "sv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svenska"])},
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engelska"])},
        "chooseLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj språk"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"sv":"Swedish","en":"English","chooseLanguage":"Select language"},"sv":{"sv":"Svenska","en":"Engelska","chooseLanguage":"Välj språk"}}')
  delete Component.options._Ctor
  
}
