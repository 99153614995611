module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "sv": {
        "filePreview": {
          "previewIframe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förhandsgranska (ram)"])},
          "previewNewTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förhandsgranska (ny flik)"])},
          "lockEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lås redigering"])},
          "unlockEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lås upp redigering"])},
          "downloadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda ner fil"])},
          "couldNotPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde inte förhandsgranska filen. Testa att ladda ned den istället."])},
          "filetypeNotSupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtypen stöds inte av förhandsgranskningen."])}
        }
      },
      "en": {
        "filePreview": {
          "previewIframe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview (iframe)"])},
          "previewNewTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview (new tab)"])},
          "lockEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock edit"])},
          "unlockEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock edit"])},
          "downloadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download file"])},
          "couldNotPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not preview file. Try to download it instead."])},
          "filetypeNotSupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file type is not supported by the preview."])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"sv":{"filePreview":{"previewIframe":"Förhandsgranska (ram)","previewNewTab":"Förhandsgranska (ny flik)","lockEdit":"Lås redigering","unlockEdit":"Lås upp redigering","downloadFile":"Ladda ner fil","couldNotPreview":"Kunde inte förhandsgranska filen. Testa att ladda ned den istället.","filetypeNotSupported":"Filtypen stöds inte av förhandsgranskningen."}},"en":{"filePreview":{"previewIframe":"Preview (iframe)","previewNewTab":"Preview (new tab)","lockEdit":"Lock edit","unlockEdit":"Unlock edit","downloadFile":"Download file","couldNotPreview":"Could not preview file. Try to download it instead.","filetypeNotSupported":"The file type is not supported by the preview."}}}')
  delete Component.options._Ctor
  
}
