import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.menuDisabled)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"button",class:_vm.totallyDisabled ? 'button-disabled' : 'button-enabled',on:{"click":_vm.directClick}},on),[_c(VIcon,{attrs:{"size":"20","color":_vm.totallyDisabled ? 'var(--v-gray3-base)' : 'var(--v-gray2-base)'}},[_vm._v(" mdi-timer-outline ")]),_c('span',{staticClass:"timer-text"},[_vm._v(" "+_vm._s(_vm.formatTimeFromSeconds(_vm.timerSeconds))+" ")])],1)]}}],null,false,2276252653)},[_vm._v(" "+_vm._s(_vm.totallyDisabled ? _vm.$t('wrapupTime.noExtendedWrapup') : _vm.$t('wrapupTime.startExtendedWrapup'))+" ")]):_c(VMenu,{ref:"menuRef",attrs:{"elevation":"0","close-on-content-click":true,"nudge-top":150,"nudge-left":15,"transition":"scroll-y-reverse-transition","offset-y":"","max-width":"200px","min-width":"200px","disabled":_vm.menuDisabled},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"button button-enabled"},'div',attrs,false),on),[_c(VIcon,{attrs:{"size":"20","color":_vm.wrapupTimer > 0 ? 'var(--v-primary-base)' : 'var(--v-gray2-base)'}},[_vm._v(" mdi-timer-outline ")]),_c('span',{staticClass:"timer-text"},[_vm._v(" "+_vm._s(_vm.formatTimeFromSeconds(_vm.timerSeconds))+" ")])],1)]}}]),model:{value:(_vm.openList),callback:function ($$v) {_vm.openList=$$v},expression:"openList"}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VList,_vm._l((_vm.menuOptions),function(option,index){return _c(VListItem,{key:index,attrs:{"disabled":_vm.isOptionDisabled(option.value)},on:{"click":function($event){return _vm.clickListButton(option.value)}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"18"}},[_vm._v(_vm._s(option.icon))]),_c(VListItemTitle,[_vm._v(_vm._s(option.text))])],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }