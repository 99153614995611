import axios from 'axios';

function getCreatorFormOptions() {
    return axios.get(`/form/options/`);
}

function getFormByOption(id, caseId) {
    let url = `/form/${id}`;

    if (caseId !== null) {
        url += `/${caseId}`;
    }

    return axios.get(url);
}
function postForm(data) {
    return axios.post('/form', data);
}

export default {
    getCreatorFormOptions,
    getFormByOption,
    postForm,
};
