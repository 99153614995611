import axios from 'axios';

function getSurveys() {
    return axios.get('/surveys');
}

function getSurveyById(id) {
    return axios.get(`/surveys/${id}`);
}

function createSurvey(newSurvey) {
    return axios.post('/surveys', newSurvey);
}

function deleteSurvey(id) {
    return axios.delete(`/surveys/${id}`);
}

function getTriggersBySurveyId(surveyId) {
    return axios.get(`/surveys/${surveyId}/triggers`);
}

function updateTrigger(trigger) {
    return axios.put(`/surveys/${trigger.surveyRef}/triggers/${trigger.id}`, trigger);
}

function createTrigger(surveyId, trigger) {
    return axios.post(`/surveys/${surveyId}/triggers`, trigger);
}

function deleteTrigger(surveyId, triggerId) {
    return axios.delete(`/surveys/${surveyId}/triggers/${triggerId}`);
}

function getQuestionsBySurvey(id) {
    return axios.get(`/surveys/${id}/questions`);
}

function getAnswersCsv(surveyId) {
    return axios.get(`/surveys/${surveyId}/answers`, {
        params: {
            type: 'csv',
        },
    });
}

function putSurveyAndQuestions(survey, questions) {
    return axios.put(`/surveys/${survey.id}`, {
        ...survey,
        questions,
    });
}

function setSurveyActive(surveyId, active) {
    return axios.put(`/surveys/${surveyId}/active`, {
        active,
    });
}

function getAnswersBySurvey(surveyId) {
    return axios.get(`/surveys/${surveyId}/answers`, {
        params: {
            type: 'summary',
        },
    });
}

function sendPendingManualSurvey(commentId) {
    return axios.post(`/pending-surveys/${commentId}/send`);
}

export default {
    getSurveys,
    getSurveyById,
    createSurvey,
    deleteSurvey,
    getQuestionsBySurvey,
    getAnswersBySurvey,
    putSurveyAndQuestions,
    setSurveyActive,
    getTriggersBySurveyId,
    updateTrigger,
    createTrigger,
    deleteTrigger,
    getAnswersCsv,
    sendPendingManualSurvey,
};
