import axios from 'axios';

function getActiveCases(limit, offset) {
    return axios.get('/users/cases/active', {
        params: {
            limit,
            offset,
        },
    });
}

function getActiveDialogs(limit, offset) {
    return axios.get('/users/dialogs/active', {
        params: {
            limit,
            offset,
        },
    });
}

function getActiveQueues(limit, offset) {
    return axios.get('/users/queues/active', {
        params: {
            limit,
            offset,
        },
    });
}

function getActiveUnreadMessages() {
    return axios.get('/users/unreadmessages');
}

function getActiveCapacity() {
    return axios.get('/users/capacity');
}

function getActiveCapacityLeft(channelType) {
    return axios.get('/users/capacity/amount', {
        params: {
            channelType,
        },
    });
}

export default {
    getActiveCases,
    getActiveDialogs,
    getActiveQueues,
    getActiveUnreadMessages,
    getActiveCapacity,
    getActiveCapacityLeft,
};
