import i18n from '../i18n';

export function icon(source) {
    switch (source) {
        case 'facebook': {
            return require('@/assets/SocialMedia/facebook.svg');
        }
        case 'instagrambusiness': {
            return require('@/assets/SocialMedia/instagram.svg');
        }
        case 'twitter': {
            return require('@/assets/SocialMedia/twitter.svg');
        }
        case 'linkedinbusiness': {
            return require('@/assets/SocialMedia/linkedin.svg');
        }
        case 'youtube': {
            return require('@/assets/SocialMedia/youtube.svg');
        }
        default: {
            return null;
        }
    }
}

export function type(type, source) {
    let header = '';
    switch (type) {
        case 'comment': {
            header += i18n.t('global.socialComment');
            break;
        }
        case 'mentioned_media': {
            header += i18n.t('global.socialMentioned');
            break;
        }
        case 'message': {
            header += i18n.t('global.socialMessage');
            break;
        }
        default: {
            header += i18n.t('global.socialPost');
            break;
        }
    }
    header += i18n.t('global.socialOn');
    switch (source) {
        case 'facebook': {
            header += 'Facebook';
            break;
        }
        case 'instagrambusiness': {
            header += 'Instagram';
            break;
        }
        case 'twitter': {
            header += 'Twitter';
            break;
        }
        case 'linkedinbusiness': {
            header += 'LinkedIn';
            break;
        }
        case 'youtube': {
            header += 'YouTube';
            break;
        }
        default: {
            header += i18n.t('global.socialUnknown');
            break;
        }
    }
    return header;
}

export default {
    type,
};
