module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "workingCase": {
          "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is currently working on this case."])}
        }
      },
      "sv": {
        "workingCase": {
          "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jobbar i detta ärende just nu."])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"workingCase":{"recipient":"is currently working on this case."}},"sv":{"workingCase":{"recipient":"jobbar i detta ärende just nu."}}}')
  delete Component.options._Ctor
  
}
