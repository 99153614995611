module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "header": {
          "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])}
        },
        "footer": {
          "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
          "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
          "applyAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose all"])}
        }
      },
      "sv": {
        "header": {
          "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])}
        },
        "footer": {
          "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nollställ"])},
          "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicera"])},
          "applyAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj alla"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"header":{"filter":"Filters"},"footer":{"reset":"Reset","apply":"Apply","applyAll":"Choose all"}},"sv":{"header":{"filter":"Filters"},"footer":{"reset":"Nollställ","apply":"Applicera","applyAll":"Välj alla"}}}')
  delete Component.options._Ctor
  
}
