export const templateTypes = Object.freeze({
    PHRASE: 1,
    SIGNATURE: 3,
    EMAIL: 4,
    SMS: 5,
    CHAT: 6,
    COMMENT: 7,
    SEARCH: 8,
    CONFIRMATION_EMAIL: 9,
});
