<template>
    <div class="read-more-container">
        <template v-if="items.length > maxLength && limited">
            <div v-for="i in 3" :key="i">
                <slot :item="items[i]" :index="i"></slot>
            </div>
        </template>
        <template v-else>
            <div v-for="(item, i) in items" :key="i">
                <slot :item="item" :index="i"></slot>
            </div>
        </template>
        <v-btn
            v-if="shouldShowToggle"
            class="read-more-button"
            text
            @click="() => (limited = !limited)"
            @mouseover="hover = true"
            @mouseout="hover = false"
        >
            <!-- {{ limited ? 'Fler..' : 'Färre..' }} -->
            <div class="read-more-button-container">
                <v-icon v-if="limited" size="18" :color="hoverColor">mdi-paperclip</v-icon>
                <span v-if="limited" class="read-more-button-number" :style="{ color: hoverColor }">
                    {{ numberOfExtraItems }}
                </span>
                <v-icon v-if="limited" size="16" :color="hoverColor">mdi-plus</v-icon>
                <v-icon v-if="!limited" size="16" :color="hoverColor">mdi-minus</v-icon>
            </div>
        </v-btn>
    </div>
</template>
<script>
    export default {
        props: {
            items: {
                type: Array,
                required: true,
            },
            maxLength: {
                type: Number,
                default: 3,
            },
        },
        data() {
            return { limited: true, hover: false };
        },
        computed: {
            shouldShowToggle() {
                return this.items.length > this.maxLength;
            },
            numberOfExtraItems() {
                return this.items.length - this.maxLength;
            },
            hoverColor() {
                return this.hover ? 'var(--v-gray-4-base)' : 'var(--v-gray2-base)';
            },
        },
    };
</script>
<style scoped>
    .read-more-button-number {
        color: var(--v-gray2-base);
        font-size: 14px;
    }
    .read-more-button-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
    }
    .read-more-button {
        height: 40px !important;
        border: 1px solid rgba(0, 0, 0, 0.15);
    }
    .read-more-container {
        display: flex;
        gap: 4px;
        flex-wrap: wrap;
    }
</style>
<i18n lang="json">
{
    "en": {
        "readMore": {
            "more": "More..",
            "less": "Less.."
        }
    },
    "sv": {
        "readMore": {
            "more": "Fler..",
            "less": "Färre.."
        }
    }
}
</i18n>
