import {
    updateBooking,
    insertBooking,
    getPeriod,
    getAllUsersInGroup,
    getAllGroups,
    getClickedBooking,
    getTimeGenereateType,
    deleteRecord,
    deleteAllRecords,
    deleteAllRecordsWithTemplate,
    addGeneratedSlotTimes,
    deleteMarkedSlots,
    deleteSlot,
    addTemplate,
    updateTemplate,
    deleteTemplate,
    getTemplateById,
    getTemplateByQueueIdentifier,
    getTemplateFrequencies,
    addAgent,
    updateAgent,
    deleteAgent,
    getAgentsByQueueId,
    setPeriod,
    postponeBooking,
    getPhonenumber,
    generateNewSlots,
} from '@/api/callbackAdmin/callbackAdmin';
import i18n from '../../i18n';

// State object
const state = {
    templateList: [],
};
// Getter functions
const getters = {};
// Actions
const actions = {
    async updateBooking({}, { data, selectedBooking }) {
        return new Promise((resolve, reject) => {
            updateBooking(data, selectedBooking)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async insertBooking({}, data) {
        return new Promise((resolve, reject) => {
            insertBooking(data)
                .then((res) => {
                    resolve(res);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.bookingAdded'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async getPeriod({}, selectedBooking) {
        return new Promise((resolve, reject) => {
            getPeriod(selectedBooking)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async setPeriod({}, payload) {
        return new Promise((resolve, reject) => {
            setPeriod(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async getAllUsersInGroup({}, id) {
        return new Promise((resolve, reject) => {
            getAllUsersInGroup(id)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async getAllGroups({}, userId) {
        return new Promise((resolve, reject) => {
            getAllGroups(userId)
                .then((res) => {
                    // if (res.status === 500) throw new Error('Något fel har skett, försök igen.');
                    if (res.status === 500) {
                        throw new Error(i18n.t('callbackAdminStore.wrong'));
                    }
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async getClickedBooking({}, id) {
        return new Promise((resolve, reject) => {
            getClickedBooking(id)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async getTimeGenereateType({}) {
        return new Promise((resolve, reject) => {
            getTimeGenereateType()
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.dataErr'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async deleteRecord({}, selectedBooking) {
        return new Promise((resolve, reject) => {
            deleteRecord(selectedBooking)
                .then((res) => {
                    if (res.data.affectedRows > 0) {
                        this._vm.$toasted.show(i18n.t('callbackAdminStore.bookingDeleted'), {
                            icon: 'mdi-content-save',
                            type: 'success',
                        });
                        resolve(res);
                    } else {
                        this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                            icon: 'cancel',
                            type: 'error',
                        });
                    }
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async deleteAllRecords({}, selectedBooking) {
        return new Promise((resolve, reject) => {
            deleteAllRecords(selectedBooking)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.bookingsDeleted'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async deleteAllRecordsWithTemplate({}, selectedBooking) {
        return new Promise((resolve, reject) => {
            deleteAllRecordsWithTemplate(selectedBooking)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.bookingsDeleted'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async addGeneratedSlotTimes({}, slotTimes) {
        return new Promise((resolve, reject) => {
            addGeneratedSlotTimes(slotTimes)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async deleteMarkedSlots({}, selectedSlotId) {
        return new Promise((resolve, reject) => {
            deleteMarkedSlots(selectedSlotId)
                .then((res) => {
                    if (res.data.affectedRows > 0) {
                        resolve(res);
                        this._vm.$toasted.show(i18n.t('callbackAdminStore.slotDeleted'), {
                            icon: 'mdi-content-save',
                            type: 'success',
                        });
                    } else {
                        this._vm.$toasted.show(i18n.t('callbackAdminStore.slotDeletedLocked'), {
                            icon: 'cancel',
                            type: 'error',
                        });
                        resolve(res);
                    }
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async deleteSlot({}, item) {
        return new Promise((resolve, reject) => {
            deleteSlot(item)
                .then((res) => {
                    if (res.data.affectedRows > 0) {
                        resolve(res);
                        this._vm.$toasted.show('Slot borttagen', {
                            icon: 'mdi-content-save',
                            type: 'success',
                        });
                    } else {
                        this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                            icon: 'cancel',
                            type: 'error',
                        });
                    }
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    //= ===================================================//
    //       CALLBACKBOOKING TEMPLATES                    //
    //= ===================================================//
    async addTemplate({}, payload) {
        return new Promise((resolve, reject) => {
            addTemplate(payload)
                .then((res) => {
                    resolve(res);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.templateAdded'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },

    async updateTemplate({}, payload) {
        return new Promise((resolve, reject) => {
            updateTemplate(payload)
                .then((res) => {
                    resolve(res);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.templateUpdated'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async deleteTemplate({}, id) {
        return new Promise((resolve, reject) => {
            deleteTemplate(id)
                .then((res) => {
                    resolve(res);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.templateDeleted'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async getTemplateById({}, id) {
        return new Promise((resolve, reject) => {
            getTemplateById(id)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async getTemplateByQueueIdentifier({}, id) {
        return new Promise((resolve, reject) => {
            getTemplateByQueueIdentifier(id)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async getTemplateFrequencies({}) {
        return new Promise((resolve, reject) => {
            getTemplateFrequencies()
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async editBooking({}, payload) {
        return new Promise((resolve, reject) => {
            this.editBooking(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.bookingUpdated'), {
                        icon: 'success',
                        type: 'error',
                    });
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async postponeBooking({}, payload) {
        return new Promise((resolve, reject) => {
            postponeBooking(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.bookingUpdated'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    //= =======================================================//
    //                   CALLBACK AGENTS                      //
    //= =======================================================//

    async getAgentsByQueueId({}, id) {
        return new Promise((resolve, reject) => {
            getAgentsByQueueId(id)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async addAgent({}, payload) {
        return new Promise((resolve, reject) => {
            addAgent(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async updateAgent({}, { payload, id }) {
        return new Promise((resolve, reject) => {
            updateAgent(payload, id)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.slotUpdated'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async deleteAgent({}, id) {
        return new Promise((resolve, reject) => {
            deleteAgent(id)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    async getPhonenumber({}, callbackId) {
        return new Promise((resolve, reject) => {
            getPhonenumber(callbackId)
                .then((response) => {
                    resolve(response.data[0]);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async generateNewSlots({}, selectedBooking) {
        return new Promise((resolve, reject) => {
            generateNewSlots(selectedBooking)
                .then((response) => {
                    if (response.status !== 200) {
                        throw { message: i18n.t('callbackAdminStore.generatingSlotsErr') };
                    }
                    resolve(response.data);
                    this._vm.$toasted.show(i18n.t('callbackAdminStore.newSlotsGenerated'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                        duration: 1500,
                    });
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(error.message || i18n.t('callbackAdminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
};
// Mutations
const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
