/**
 * An array of objects representing automatic sort items.
 * Each object contains a text, value, icon, and color property.
 * @typedef {Object} AutomaticSortItem
 * @property {string} text - The text to display for the item.
 * @property {boolean} value - The value of the item.
 * @property {string} icon - The icon to display for the item.
 * @property {string} color - The color to use for the item.
 */

/**
 * An array of objects representing automatic sort items.
 * @type {AutomaticSortItem[]}
 */
export const automaticSortItems = [
    { text: 'true', value: true, icon: 'mdi-check', color: 'success' },
    { text: 'false', value: false, icon: 'mdi-close', color: 'error' },
];

/**
 * An array of objects representing options for closing outside click items.
 * Each object contains a text, value, icon, and color property.
 * @typedef {Object} CloseOutsideClickItem
 * @property {string} text - The text to display for the option.
 * @property {boolean} value - The value of the option.
 * @property {string} icon - The icon to display for the option.
 * @property {string} color - The color to use for the option.
 */

/**
 * An array of options for closing outside click items.
 * @type {CloseOutsideClickItem[]}
 */
export const closeOutsideClickItems = [
    { text: 'true', value: true, icon: 'mdi-check', color: 'success' },
    { text: 'false', value: false, icon: 'mdi-close', color: 'error' },
];

/**
 * An array of objects representing options for closing outside click items.
 * Each object contains a text, value, icon, and color property.
 * @typedef {Object} CaseListVisibleOptions
 * @property {string} text - The text to display for the option.
 * @property {boolean} value - The value of the option.
 * @property {string} icon - The icon to display for the option.
 * @property {string} color - The color to use for the option.
 */

/**
 * An array of options for closing outside click items.
 * @type {CaseListVisibleOptions[]}
 */
export const caseListVisibleOptions = [
    { text: 'true', value: true, icon: 'mdi-check', color: 'success' },
    { text: 'false', value: false, icon: 'mdi-close', color: 'error' },
];
