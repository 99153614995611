import store from '../store';
import i18n from '../i18n';

export default class AuthHandler {
    static async refreshTokens() {
        try {
            const res = await store.dispatch('Auth/refreshToken');
            if (res.status == 200) {
                return res.data;
            }
            store.dispatch('Auth/logoutClient', i18n.t('global.auth.sessionExpired'));
        } catch (error) {
            console.error(error);
            store.dispatch('Auth/logoutClient', i18n.t('global.auth.sessionExpired'));
        }
    }
}
