import axios from 'axios';

function publish(q) {
    return axios.post('/social/publish/', {
        text: q.text,
        caseId: q.caseId,
    });
}

function updateComment(itemId, data) {
    return axios.put(`/social/comment/${itemId}`, data);
}

export default {
    publish,
    updateComment,
};
