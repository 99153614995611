<template>
    <div class="comments" :style="commentStyle" :class="commentClass">
        <header class="comment-section">
            <div class="comment-avatar">
                <InternalType :absolute="false" :item="{ internalType: channelTypes.CALLBACK }" />
                <v-tooltip bottom>
                    <template #activator="{ on }">
                        <div v-on="on">
                            <CommonAvatar v-if="item.userId" :userId="item.userId" :size="32" />
                            <SystemAvatar v-else :size="32" />
                        </div>
                    </template>
                    <span>{{ item.userId ? item.userName : $t('comment.system') }}</span>
                </v-tooltip>
            </div>
            <div class="date-from">
                <div class="comment-from-explainer text-truncate">{{ $t('comment.orderedNumber') }}</div>
                <div class="comment-from text-truncate">{{ item.from }}</div>
            </div>
            <primary-action-button
                class="primary-action-button"
                :disabled="!clickableCallback"
                @click="executeCallback"
            >
                {{ callbackButtonText }}
            </primary-action-button>

            <div class="top-field">
                <div class="comment-top-field end comment-date text-truncate">
                    <v-progress-circular
                        v-if="!item.sent"
                        color="var(-v-gray2-base)"
                        indeterminate
                        size="10"
                        width="2"
                    />
                    {{ $t('comment.created') }}
                </div>

                <div class="comment-top-field end comment-date text-truncate">
                    {{ formatDate(item.dateTime) }}
                </div>
            </div>
        </header>

        <CallRecording :item="item" />
    </div>
</template>
<script>
    import { format } from 'date-fns';
    import { mapState, mapActions } from 'vuex';
    import { getDay } from '@/utils/DateFormatter';

    import helper from '@/helpers/calls.helper';

    import config from '@/app/config';

    import InternalType from '@/components/Cases/List/InternalType.vue';
    import SystemAvatar from '@/components/Global/SystemAvatar.vue';

    import { channelTypes } from '@/constants/enums/queues.enums';
    import { caseStatuses } from '@/enums/cases.enums';
    import CallRecording from './CallRecording.vue';

    export default {
        components: {
            InternalType,
            SystemAvatar,
            CallRecording,
        },

        props: {
            item: {
                type: Object,
                default: () => {},
            },
        },

        data() {
            return {
                config,
                channelTypes,
            };
        },
        computed: {
            ...mapState({
                userSettings: (state) => state.System.userSettings,
                loggedInUser: (state) => state.Auth.userObject,
                selectedCase: (state) => state.Cases.selectedCase,
            }),

            clientIsUsingMac() {
                return /(mac|iphone|ipod|ipad)/i.test(navigator.userAgent);
            },

            commentStyle() {
                return {
                    justifySelf: this.item.outgoing ? 'end' : 'start',
                };
            },

            commentClass() {
                return {
                    outgoing: this.item.outgoing,
                    incoming: !this.item.outgoing,
                };
            },

            commentDirection() {
                // handle sent
                if (!this.item.sent) {
                    return this.$t('comment.sending');
                }
                return this.item.outgoing ? this.$t('comment.outgoing') : this.$t('comment.incoming');
            },
            clickableCallback() {
                return !this.closedCallback && !this.reopenedCallback;
            },
            closedCallback() {
                const caseInQueue = this.selectedCase.case.status === caseStatuses.IN_QUEUE;

                return this.item.tries === null || !caseInQueue;
            },
            reopenedCallback() {
                const firstCallbackComment = this.selectedCase.comments.find(
                    (comment) => comment.typeOfMessage === channelTypes.CALLBACK
                );
                const isFirstCallbackComment = firstCallbackComment && firstCallbackComment.id === this.item.id;
                return !isFirstCallbackComment;
            },
            callbackButtonText() {
                switch (true) {
                    case this.reopenedCallback: {
                        return this.$t('comment.reopened');
                    }
                    case this.closedCallback: {
                        return this.$t('comment.closed');
                    }
                    default: {
                        return this.$t('comment.callback') + this.item.tries;
                    }
                }
            },
        },

        methods: {
            ...mapActions({
                getNumberToCall: 'Call/getNumberToCall',
                setCommunicationModal: 'Cases/openCommunication',
            }),

            async executeCallback() {
                const { number, exception } = await this.getNumberToCall({
                    phoneNumber: this.item.from,
                    caseId: this.item.caseId,
                    userId: this.loggedInUser.userId,
                    commentId: this.item.id,
                    type: channelTypes.CALLBACK,
                });
                if (!number) return;

                if (exception) {
                    const confirmed = await this.continueCallAlthoughException(exception);
                    if (!confirmed) return;
                }

                if (!this.clientIsUsingMac) {
                    document.location.href = `tloswyx:${number}`;
                } else {
                    document.location.href = `tel:${this.phoneNumberToCall}`;
                }
            },

            async continueCallAlthoughException(exception) {
                const exceptionObject = helper.getCallExceptionObject(exception);

                if (!exceptionObject) {
                    return true;
                }

                const { titleKey, messageKey } = exceptionObject;

                const { confirmed } = await this.$global.dialogs.showConfirmationDialog({
                    title: this.$t(titleKey),
                    message: this.$t(messageKey),
                    confirmText: this.$t('global.yes'),
                    declineText: this.$t('global.no'),
                });

                return confirmed;
            },

            formatDate(dateTime) {
                if (!this.userSettings.cases.showDate.active) {
                    return getDay(dateTime);
                }

                return getDay(dateTime) + ' (' + format(new Date(dateTime), 'yyyy-MM-dd') + ')';
            },

            getDay,
            format,
        },
    };
</script>
<style scoped lang="scss">
    .end {
        justify-content: end;
    }

    .comment-section {
        display: grid;
        grid-template-areas:
            'comment-avatar date-from top-field'
            'comment-avatar primary-action-button top-field';
        grid-template-columns: 1fr 4fr 1fr;
    }

    .date-from {
        grid-area: date-from;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .primary-action-button {
        grid-area: primary-action-button;
        max-width: 200px;
    }

    .top-field {
        grid-template-areas: top-field;
    }

    .comment-top-field {
        font-size: 12px;
        gap: 4px;
        display: flex;
        align-items: center;
        grid-area: comment-date;
        align-self: flex-end;
        color: var(--v-gray2-base);
    }

    .callback-button {
        display: flex;
        align-items: center;
        gap: 8px;
        grid-area: primary-action-button;
        align-self: flex-end;
    }

    .comment-avatar {
        color: white;
        border: 0px solid var(--v-accent2-base);
        display: flex;
        align-self: flex-start;
        align-items: center;
        max-height: 40px;
        gap: 8px;
        grid-area: comment-avatar;
    }

    .comment-action-buttons {
        padding-top: 16px;
    }

    .comment-attachment {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .comments {
        padding: 32px;
        padding-bottom: 16px;
        border-radius: 12px;
        width: 100%;
        max-width: 800px;
        gap: 4px;
        background-color: rgba(245, 245, 247, 0.53);
        font-size: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    }

    .comments-grid {
        display: grid;
        grid-template-columns: 1fr 1.4fr;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        padding: 8px 0px;
    }

    .comments-grid-header {
        text-align: left;
        font-size: 12px;
        color: var(--v-gray2-base);
    }

    .comments-grid-text {
        font-size: 12px;
        color: var(--v-gray4-base);
    }

    .medium-heading {
        font-size: 14px;
        font-weight: 500;
        color: var(--v-gray4-base);
        text-align: center;
        margin-bottom: 16px;
    }

    .avatar-image {
        background-position: center;
        object-fit: cover;
    }

    .comment-info {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        font-size: 0.8rem;
        color: var(--v-gray2-base);
    }

    .comment-date {
        justify-self: end;
    }

    .comment-from-explainer {
        color: var(--v-gray2-base);
        font-size: 0.8rem;
    }

    .comment-from {
        color: var(--v-gray4-base);
        font-size: 1rem;
    }

    .comment-content {
        padding-top: 16px;
        padding-bottom: 16px;
        font-size: 1rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .callback-content {
        padding-top: 16px;
        padding-bottom: 16px;
        font-size: 1rem;
    }

    .comment-title {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 1.3rem;
        font-weight: 300;
    }

    @media (max-width: 1349px) {
        .comment-section {
            grid-template-areas:
                'comment-avatar comment-top-field'
                'date-from date-from'
                'primary-action-button .';
            grid-template-columns: 1fr 1fr;
            gap: 8px;
        }

        .date-from {
            grid-area: date-from;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            text-align: right;
            align-items: center;
            padding-top: 4%;
        }

        .comment-date-wrapper {
            grid-area: comment-date-wrapper;
            display: flex;
            justify-content: center;
            align-items: left;
            flex-direction: column;
            font-size: 12px;
            gap: 4px;
        }

        .comment-from {
            margin-top: 6px;
        }

        .comment-top-field {
            grid-area: comment-top-field;
            min-width: 100px;
            display: flex;
        }

        .comment-avatar {
            color: white;
            border: 0px solid var(--v-accent2-base);
            max-height: 40px;
            grid-area: comment-avatar;
            width: 30%;
        }

        .primary-action-button {
            grid-column: 1 / span 2;
            display: flex;
            justify-self: center;
        }
    }
    @media (min-width: 1350px) and (max-width: 1550px) {
        .comment-section {
            grid-template-areas:
                'avatar date'
                '. from'
                '. callback-button';
            display: grid;
            gap: 8px;
            grid-template-columns: 1fr 3fr;
        }

        .comment-top-field {
            grid-area: date;
        }

        .comment-avatar {
            color: white;
            border: 0px solid var(--v-accent2-base);
            margin-right: 8px;
            max-height: 40px;
            gap: 8px;
            grid-area: avatar;
        }

        .date-from {
            grid-area: from;
            display: flex;
            justify-content: flex-end;
            text-align: end;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: flex-end;
        }
        .primary-action-button {
            grid-area: callback-button;
            display: flex;
            justify-self: flex-end;
        }
    }
</style>

<i18n lang="json">
{
    "en": {
        "comment": {
            "to": "To:",
            "outgoing": "Outgoing",
            "incoming": "Incoming",
            "sending": "Sending",
            "created": "Created",
            "callback": "Callback attempt #",
            "closed": "Callback Closed",
            "orderedNumber": "Ordered callback number",
            "reopened": "Reopened call"
        }
    },
    "sv": {
        "comment": {
            "to": "Till:",
            "outgoing": "Utgående",
            "incoming": "Inkommande",
            "sending": "Skickar",
            "created": "Skapad",
            "callback": "Callback försök #",
            "closed": "Callback Stängd",
            "orderedNumber": "Bokat uppringningsnummer",
            "reopened": "Återöppnat samtal"
        }
    }
}
</i18n>
