/**
 * Represents a tree node.
 */
class TreeNode {
    /**
     * Create a TreeNode.
     * @param {string} name - The name of the node.
     */
    constructor(name) {
        this.name = name;
        this.children = [];
    }

    /**
     * Adds a child node to the current node.
     * @param {TreeNode|TreeNode[]|string | number | boolean} data - The child node or an array of child nodes.
     * @param {string} [name=''] - The name of the child node.
     */
    addChild(potentialNodes, name = '') {
        if (potentialNodes instanceof TreeNode) {
            this.children.push(potentialNodes);
            return;
        }

        if (Array.isArray(potentialNodes) && name) {
            const node = new TreeNode(name);
            for (const datum of potentialNodes) {
                node.addChild(datum);
            }
            this.children.push(node);
            return;
        }

        this.children.push(potentialNodes);
    }

    /**
     * Remove a child node by name.
     * @param {string} name - The name of the child node to remove.
     */
    delete(name) {
        this.children = this.children.reduce((acc, child) => {
            if (child instanceof TreeNode) {
                child.delete(name);
                if (child.name !== name) {
                    acc.push(child);
                }
            } else if (child !== name) {
                acc.push(child);
            }
            return acc;
        }, []);
    }

    /**
     * Check if the node or its descendants contain a string.
     * @param {string} searchString - The string to search for.
     * @returns {boolean} - True if the string is found, otherwise false.
     */
    exists(searchString) {
        const searchSet = new Set(this.children);
        if (searchSet.has(searchString)) {
            return true;
        }
        return this.children.some((child) => child instanceof TreeNode && child.exists(searchString));
    }
}

const root = new TreeNode('root');

export default root;
