<template>
    <div v-if="template">
        <div class="attachment">
            <div v-for="attachment in template.attachments" :key="attachment.id" class="attachment-chip truncate">
                <v-icon size="13">mdi-attachment</v-icon>
                {{ attachment.name }}
            </div>
        </div>

        <div
            v-dompurify-html="template.content"
            class="content-area"
            @click="appendTemplateToTextField({ content: template.content, attachments: template.attachments })"
        ></div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import { getImagesInContent } from '../../../../directives/shadowDom';

    export default {
        props: {
            templateId: {
                type: Number,
                Required: true,
                default: 0,
            },
        },
        data() {
            return {
                template: null,
            };
        },
        computed: {
            ...mapState('Cases', ['selectedCase']),
            ...mapState('QueueManager', ['allQueues']),
            ...mapState('Templates', ['templates']),
        },
        async mounted() {
            this.template = await this.getTemplateById(this.templateId);
            this.template.content = await this.getImagesInContent(this.template.content);
        },
        methods: {
            ...mapActions('Templates', ['getTemplateById']),
            ...mapActions('Cases', ['appendTemplateToTextField']),
            getImagesInContent,
        },
    };
</script>

<style scoped>
    .content-area {
        padding: 1rem;
        overflow-y: auto;
        height: 100%;
        max-height: 100%;
        background-color: #f5f5f5;
        cursor: pointer;
    }
    .content-area:hover {
        background-color: #f0f0f0;
        border: 2px solid var(--v-primary-base);
        border-radius: 6px;
        margin: 3px;
    }
    .attachment-chip {
        margin: 0.5rem;
    }
    .truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
        display: inline-block;
    }
</style>
