// * This file is used to get the source context for chatgpt
export function getSourceContext(context, rootState, commentId, textInput = null) {
    const regexForStripHTML = /<[^>]+>|<style[^>]*>[\S\s]*?<\/style>/gi;
    switch (context) {
        case 'chat': {
            // Not implemented yet
            return textInput;
        }
        case 'sms': {
            // Not implemented yet
            return null;
        }
        case 'comment': {
            const comment = rootState.Cases.selectedCase.comments.find((comment) => comment.id === commentId);
            return comment?.comment?.replaceAll(regexForStripHTML, '');
        }
        case 'cases': {
            return rootState.Comments.content;
        }
        default: {
            // Not implemented yet
            return textInput;
        }
    }
}
