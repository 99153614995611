import axios from 'axios';

function getAllIvrs() {
    return axios.get(`/ivrs/user`);
}
//= ===================================================//
//                  OPENHOURS CALLS                   //
//= ===================================================//

function getOpenHours({ queueType, queueId, channelType }) {
    return axios.get(`/openinghours?queueType=${queueType}&queueId=${queueId}&channelType=${channelType}`);
}
function setOpeningHours(payload) {
    return axios.post(`/openinghours`, payload);
}

function getOpeningHoursExceptions({ queueType, queueId, channelType, dateRange }) {
    return axios.get(`/openinghours/exceptions`, {
        params: {
            queueType,
            queueId,
            channelType,
            start: dateRange.startDate,
            end: dateRange.endDate,
        },
    });
}

function setOpeningHoursException(payload) {
    return axios.put(`/openinghours/exception`, payload);
}
function deleteOpeningHoursException(id) {
    return axios.delete(`/openinghours/exception/${id}`);
}
function getExceptionTemplates() {
    return axios.get(`/openinghours/exception/templates`);
}
function createExceptionTemplate(payload) {
    return axios.post(`/openinghours/exception/templates`, payload);
}
function deleteExceptionTemplate(id) {
    return axios.delete(`/openinghours/exception/template/${id}`);
}

function uploadFile({ formData }) {
    return axios.post(`/openinghours/files`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}
function updateFileOpeningHoursFileMetaData(payload) {
    return axios.put('/openinghours/files', payload);
}

function getFilesLibraryByLoggedInUser(id) {
    return axios.get(`/files/user/${id}`);
}

function getAllFiles() {
    return axios.get(`/files`);
}

function getFile(name) {
    return axios.get(`/file/name/${name}`);
}
function removeFile(ohFileId) {
    return axios.delete(`openinghours/files/${ohFileId}`);
}

function getFileUsageInQueues(id) {
    return axios.get(`/file/${id}/queues`);
}
function getExceptionSettings() {
    return axios.get(`/openinghours/settings`);
}
function setExceptionSettings(payload) {
    return axios.put(`/openinghours/settings`, payload);
}
function getQueueOpeninghours(queueId) {
    return axios.get(`/openinghours/${queueId}`);
}

export default {
    getOpenHours,
    setOpeningHours,
    getOpeningHoursExceptions,
    setOpeningHoursException,
    deleteOpeningHoursException,
    getExceptionTemplates,
    createExceptionTemplate,
    deleteExceptionTemplate,
    uploadFile,
    updateFileOpeningHoursFileMetaData,
    getFileUsageInQueues,
    getFilesLibraryByLoggedInUser,
    getFile,
    getAllFiles,
    removeFile,
    getQueueOpeninghours,
    getAllIvrs,
    getExceptionSettings,
    setExceptionSettings,
};
