module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "readMore": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More.."])},
          "less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less.."])}
        }
      },
      "sv": {
        "readMore": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fler.."])},
          "less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Färre.."])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"readMore":{"more":"More..","less":"Less.."}},"sv":{"readMore":{"more":"Fler..","less":"Färre.."}}}')
  delete Component.options._Ctor
  
}
