<template>
    <div
        id="actionModule"
        ref="target"
        class="justify-end py-4 action-bar d-flex flex-column align-end"
        :class="opened ? 'pr-4' : ''"
        :style="activeBar"
        @mouseover="() => (hovered = true)"
        @mouseleave="() => (hovered = false)"
    >
        <div class="justify-end bar-content softShadowLeft d-flex flex-column">
            <div class="show-outside" @click="() => (opened = !opened)"></div>
            <!--
            <v-tooltip bottom open-delay="250" close-delay="250">
                <template #activator="{ on, attrs }">
                    <button
                        v-bind="attrs"
                        class="action-button show-outside softShadow"
                        :style="activeButton"
                        v-on="on"
                        @click="() => (opened = !opened)"
                    >
                        <v-badge
                            bordered
                            color="color1"
                            overlap
                            :content="unreadMessages"
                            offset-x="20"
                            offset-y="20"
                            :value="unreadMessages"
                        >
                            <v-icon
                                :class="unreadMessages > 0 ? 'jump-animation' : ''"
                                class="pa-3"
                                size="42"
                                style="background: white; border-radius: 99999px"
                            >
                                mdi-account-details
                            </v-icon>
                        </v-badge>
                    </button>
                </template>
                <span>{{ $t('shortcut') }}: {{ buttonsForShortcuts }}</span>
            </v-tooltip>

        -->

            <div class="pt-2 px-2 pb-1 module-content">
                <!--v-if="userSettings.userModal.showQueues.active"-->
                <Module type="queues" />
                <Module type="cases" />
                <Module type="dialogs" />
            </div>
            <div class="bar-footer">
                <div class="bar-footer-item">
                    <span class="mb-1">{{ $t('systemLanguage') }}</span>
                    <Language />
                </div>
                <div v-if="wrapupTimeActive" ref="wrapupTime" class="bar-footer-item">
                    <span class="mb-1">{{ $t('wrapupTime') }}</span>
                    <WrapupTime @menuRef="(v) => setWrapupMenuRef(v)" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions, mapState } from 'vuex';

    import Module from '@/components/Global/UserActionModule/Modules/Module.vue';
    import Language from '@/components/Global/Language.vue';
    import WrapupTime from '@/components/Global/WrapupTime.vue';

    export default {
        components: {
            Module,
            Language,
            WrapupTime,
        },
        data() {
            return {
                hovered: false,
                targetRef: null,
                wrapupMenuRef: null,
            };
        },
        computed: {
            ...mapState({
                userSettings: (state) => state.System.userSettings,
                unreadMessages: (state) => state.ActiveCases.unreadMessages,
            }),
            opened: {
                get() {
                    return this.$store.state.Users.userActionModuleOpen;
                },
                set(value) {
                    this.$store.commit('Users/SET_USER_ACTION_MODULE_OPEN', value);
                },
            },
            wrapupTimeActive() {
                return this.userSettings.queue.extendedWrapup.showWrapup.active;
            },

            activeUserCases() {
                return this.$store.state.Cases?.activeUserCases;
            },

            clientIsUsingMac() {
                return /(mac|iphone|ipod|ipad)/i.test(navigator.userAgent);
            },

            buttonsForShortcuts() {
                return `(${this.clientIsUsingMac ? 'cmd' : 'ctrl'} + k)`;
            },

            activeBar() {
                if (this.opened) {
                    return {
                        width: '400px',
                    };
                }
                return {
                    width: '0px',
                };
            },
            activeButton() {
                if ((this.opened || this.unreadMessages === 0) && !this.hovered) {
                    return {
                        width: '6px',
                        paddingLeft: '12px',
                    };
                }
                return {};
            },
        },

        sockets: {
            connection() {
                this.subscribeToUnreadMessages();
            },
        },

        created() {
            window.addEventListener('keydown', this.keyDown);
        },
        mounted() {
            this.targetRef = this.$refs.target;
            document.addEventListener('mousedown', this.handleClickOutside);
            this.subscribeToUnreadMessages();
        },
        beforeDestroy() {
            document.removeEventListener('mousedown', this.handleClickOutside);
            this.unsubscribeFromUnreadMessages();
        },

        destroyed() {
            window.removeEventListener('keydown', this.keyDown);
        },
        methods: {
            ...mapActions('ActiveCases', ['subscribeToUnreadMessages', 'unsubscribeFromUnreadMessages']),

            handleClickOutside(event) {
                if (!this.targetRef || this.targetRef.contains(event.target)) return;

                if (this.wrapupMenuRef && !this.wrapupMenuRef.contains) return;
                this.handleModal();
            },
            keyDown(event) {
                const eventKey = this.clientIsUsingMac ? event.metaKey : event.ctrlKey;
                if (event.code === 'KeyK' && eventKey) {
                    this.opened = !this.opened;
                }
            },
            handleModal() {
                if (!this.opened) return;
                this.opened = false;
            },
            setWrapupMenuRef(ref) {
                this.wrapupMenuRef = ref;
            },
        },
    };
</script>
<style scoped lang="scss">
    .module-content {
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;
        min-height: 300px;
        max-height: 100%;
        gap: 8px;
        display: flex;
        flex-direction: column;
    }

    .action-bar {
        position: fixed;
        right: 0;
        z-index: 1000;
        bottom: 0;
        min-height: 300px;
        max-height: 100%;
        transition: all 0.5s ease;
    }

    .show-outside {
        transform: translateX(-100%);
        position: absolute;
        left: 0;
    }

    .bar-content {
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;
        min-height: 300px;
        max-height: 100%;
        border-radius: 6px 6px 0px 0px;
        background: white;
    }

    .bar-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2%;
        padding: 8px;

        height: 100px;
    }

    .bar-footer-item {
        background-color: var(--v-gray3-base);
        color: var(--v-gray4-base);

        border-radius: 10px;
        padding-top: 12px;
        height: 100%;
        flex: 1;

        display: flex;
        flex-direction: column;
        align-items: center;
    }
</style>
<i18n lang="json">
{
    "en": {
        "shortcut": "Shortcut",
        "systemLanguage": "System language",
        "wrapupTime": "Wrap-Up Time"
    },
    "sv": {
        "shortcut": "Genväg",
        "systemLanguage": "Systemspråk",
        "wrapupTime": "Bearbetningstid"
    }
}
</i18n>
