<template>
    <div
        class="text-truncate profile-picture-container"
        :class="getPositionClass(position)"
        :cy-caseAgent="item.userId == 0 ? 'none' : item.userName"
    >
        <div v-if="item.userId !== 0" :key="item.userId">
            <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                        <CommonAvatar
                            v-if="!userSettings.cases.showInitialInsteadOfProfilePic?.active"
                            :key="item.userId"
                            :userId="item.userId"
                            :color="item.caseId === selectedCaseId ? 'var(--v-gray3-base)' : 'var(--v-gray3-base)'"
                            :size="listLayout.value == 'big' ? 32 : 26"
                        />
                        <v-avatar
                            v-else
                            right
                            :style="{
                                color: getTextColorByBackground(generateColor(item.userName || item.userId)),
                                minWidth: smallerOrBigger,
                                maxWidth: smallerOrBigger,
                                maxHeight: smallerOrBigger,
                            }"
                            class="d-flex justify-center align-center profile-picture-no-border"
                            :color="generateColor(item.userName || item.userId)"
                            :height="smallerOrBigger"
                            :width="smallerOrBigger"
                        >
                            <span class="initial-text">
                                {{ getInitials(item.userName) }}
                            </span>
                        </v-avatar>
                    </div>
                </template>
                <span>{{ item.userName || item.userId }}</span>
            </v-tooltip>
        </div>
        <div v-else>
            <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                        <v-btn
                            fab
                            color="gray3"
                            :height="listLayout.value == 'big' ? 32 : 26"
                            :width="listLayout.value == 'big' ? 32 : 26"
                            elevation="0"
                            @click.stop="$emit('addCaseToYou')"
                        >
                            <v-icon :size="listLayout.value == 'big' ? 16 : 16" color="gray2">mdi-plus</v-icon>
                        </v-btn>
                    </div>
                </template>
                <span>{{ $t('profilePicture.assign') }}</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
    import { getSlaPercentage } from '@/utils/Design';
    import { getInitials } from '@/utils';
    import { generateColor, getTextColorByBackground } from '@/utils/Colors';
    import { positionTypes } from '@/enums/cases.enums';
    import { getPositionClass } from '@/helpers/cases/cases.helper';

    export default {
        components: {
            CommonAvatar: () => import('@/components/Global/CommonAvatar.vue'),
        },

        props: {
            item: {
                type: Object,
                default: () => {},
            },

            selectedCaseId: {
                type: String,
                default: '',
            },

            position: {
                type: String,
                default: positionTypes.LEFT,
                validator(value) {
                    // Shorthand for validator
                    return Object.values(positionTypes).includes(value);
                },
            },
        },
        computed: {
            userSettings() {
                return this.$store.state.System.userSettings;
            },
            listLayout() {
                return this.$store.state.Cases.listLayout;
            },
            smallerOrBigger() {
                return this.listLayout.value === 'big' ? '32px' : '26px';
            },
        },

        watch: {
            item: {
                handler() {
                    this.$forceUpdate();
                },
                deep: true,
            },
        },

        methods: {
            getSlaPercentage,
            getTextColorByBackground,
            generateColor,
            getInitials,
            getPositionClass,
        },
    };
</script>

<style scoped>
    .profile-picture {
        font-weight: 400;
        color: #777;
    }

    .profile-picture-no-border {
        border: none !important;
    }

    .initial-text {
        font-size: 12px;
        font-weight: 500;
    }
    .profile-picture-container {
        font-weight: 400;
        color: #777;
        display: flex;
    }
</style>
<i18n lang="json">
{
    "en": {
        "profilePicture": {
            "assign": "Assign the case to Yourself"
        }
    },
    "sv": {
        "profilePicture": {
            "assign": "Tilldela ärendet till dig själv"
        }
    }
}
</i18n>
