module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "videoModule": {
          "startMeet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start a video meeting"])},
          "joinMeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join the meeting"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong when starting the meeting, try again. If the problem persists, contact support."])},
          "meetingFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The meeting is finished"])},
          "theirLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Their link"])},
          "commenceConference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commence an immediate video conference."])},
          "shareLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The link will be sent by SMS or and email after you start the meeting."])},
          "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
          "endMeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End meeting"])},
          "sendSms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send SMS"])},
          "sendMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send mail"])},
          "noNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No number found"])},
          "noMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No mail found"])},
          "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
          "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied to clipboard"])},
          "sendMeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send meeting"])},
          "videoMeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video meeting"])},
          "integrationNotActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The integration is not active"])}
        }
      },
      "sv": {
        "videoModule": {
          "startMeet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starta ett videomöte"])},
          "joinMeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå med i mötet"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Något gick fel vid start av mötet, testa igen. Om problemet kvarstår kontakta support."])},
          "meetingFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mötet är avslutat"])},
          "theirLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deras länk"])},
          "commenceConference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inled en omedelbar videokonferens."])},
          "shareLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länken skickas via SMS eller E-post efter att du startat mötet."])},
          "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Börja"])},
          "endMeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avsluta möte"])},
          "sendSms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka SMS"])},
          "sendMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka E-post"])},
          "noNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget nummer hittades"])},
          "noMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen E-post hittades"])},
          "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiera länk"])},
          "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopierat till urklipp"])},
          "sendMeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka möte"])},
          "videoMeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videomöte"])},
          "integrationNotActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrationen är inte aktiv"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"videoModule":{"startMeet":"Start a video meeting","joinMeeting":"Join the meeting","error":"Something went wrong when starting the meeting, try again. If the problem persists, contact support.","meetingFinished":"The meeting is finished","theirLink":"Their link","commenceConference":"Commence an immediate video conference.","shareLink":"The link will be sent by SMS or and email after you start the meeting.","start":"Start","endMeeting":"End meeting","sendSms":"Send SMS","sendMail":"Send mail","noNumber":"No number found","noMail":"No mail found","copyLink":"Copy link","copied":"Copied to clipboard","sendMeeting":"Send meeting","videoMeeting":"Video meeting","integrationNotActive":"The integration is not active"}},"sv":{"videoModule":{"startMeet":"Starta ett videomöte","joinMeeting":"Gå med i mötet","error":"Något gick fel vid start av mötet, testa igen. Om problemet kvarstår kontakta support.","meetingFinished":"Mötet är avslutat","theirLink":"Deras länk","commenceConference":"Inled en omedelbar videokonferens.","shareLink":"Länken skickas via SMS eller E-post efter att du startat mötet.","start":"Börja","endMeeting":"Avsluta möte","sendSms":"Skicka SMS","sendMail":"Skicka E-post","noNumber":"Inget nummer hittades","noMail":"Ingen E-post hittades","copyLink":"Kopiera länk","copied":"Kopierat till urklipp","sendMeeting":"Skicka möte","videoMeeting":"Videomöte","integrationNotActive":"Integrationen är inte aktiv"}}}')
  delete Component.options._Ctor
  
}
