module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "subcases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subcases"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other related cases"])}
      },
      "sv": {
        "subcases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underärenden"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Övriga"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andra relaterade ärenden"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"subcases":"Subcases","other":"Other","related":"Other related cases"},"sv":{"subcases":"Underärenden","other":"Övriga","related":"Andra relaterade ärenden"}}')
  delete Component.options._Ctor
  
}
