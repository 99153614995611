module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "comment": {
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To:"])},
          "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing"])},
          "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming"])},
          "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending"])},
          "tries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tries: "])}
        },
        "label": {
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "confirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm email"])},
          "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Headline"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
          "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender"])},
          "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
          "damage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damage"])},
          "sub-damage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-damage"])},
          "total-damage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total damage"])},
          "number-damage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number damage"])},
          "weight-damage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight damage"])},
          "cargo-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargo content"])},
          "insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance"])},
          "insurance-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance company"])},
          "compensation-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compensation amount"])}
        }
      },
      "sv": {
        "comment": {
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Till:"])},
          "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utgående"])},
          "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkommande"])},
          "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skickar"])},
          "tries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försök: "])}
        },
        "label": {
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post"])},
          "confirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekräfta e-post"])},
          "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubrik"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meddelande"])},
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
          "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avsändare"])},
          "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottagare"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivning"])},
          "damage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skada"])},
          "sub-damage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delskada"])},
          "total-damage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalskada"])},
          "number-damage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal skada"])},
          "weight-damage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viktskada"])},
          "cargo-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraktinnehåll"])},
          "insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försäkring"])},
          "insurance-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försäkringsbolag"])},
          "compensation-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersättningsbelopp"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"comment":{"to":"To:","outgoing":"Outgoing","incoming":"Incoming","sending":"Sending","tries":"Tries: "},"label":{"email":"Email","confirmEmail":"Confirm email","headline":"Headline","message":"Message","number":"Number","date":"Date","sender":"Sender","receiver":"Receiver","description":"Description","damage":"Damage","sub-damage":"Sub-damage","total-damage":"Total damage","number-damage":"Number damage","weight-damage":"Weight damage","cargo-content":"Cargo content","insurance":"Insurance","insurance-company":"Insurance company","compensation-amount":"Compensation amount"}},"sv":{"comment":{"to":"Till:","outgoing":"Utgående","incoming":"Inkommande","sending":"Skickar","tries":"Försök: "},"label":{"email":"E-post","confirmEmail":"Bekräfta e-post","headline":"Rubrik","message":"Meddelande","number":"Nummer","date":"Datum","sender":"Avsändare","receiver":"Mottagare","description":"Beskrivning","damage":"Skada","sub-damage":"Delskada","total-damage":"Totalskada","number-damage":"Antal skada","weight-damage":"Viktskada","cargo-content":"Fraktinnehåll","insurance":"Försäkring","insurance-company":"Försäkringsbolag","compensation-amount":"Ersättningsbelopp"}}}')
  delete Component.options._Ctor
  
}
