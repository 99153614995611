import marketing from '@/api/marketing/marketing';
// Actions
const actions = {
    async deploySmsBatch(_, payload) {
        const { data } = await marketing.deploySmsBatch(payload);
        return data;
    },
    async getHistory(_, payload) {
        const { data } = await marketing.getHistory(payload);
        return data;
    },
};

export default {
    namespaced: true,
    actions,
};
