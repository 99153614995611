/*
    CASE MODEL FROM DB:

    ID
    CaseID
    ClientID
    Status
    Topic
    Description
    UserId
    AgentName
    SLA
    Category
    CategoryIds
    Answered
    Responded
    InternalCreated
    MsgID
    CaseRespondant
    AssignedTo
    HasAttachment
    InternalType
    Language
    GroupId
    Group
    Result
    Tries
    Text
    UnreadMessages
    DateTime
    Created
    LastUpdated
    LastUpdatedBy
    Resolved
    ResolvedAt
    ResolvedBy
    SurveySent
    ToEmail
    CopyToEmail
    HiddenCopyToEmail
    GdprCleaned
    JustSnoozed
    AgentCommentTime

*/

import axios from 'axios';

function getDynamicStatistics(q) {
    const params = {
        Statuses: q.filter.statuses.join(','),
        Limit: q.filter.items,
        Page: q.filter.page,
        UserIds: q.filter.userIds.join(','),
        GroupId: q.filter.queues.join(','),
        InternalType: q.filter.channels.join(','),
        CategoryIds: q.filter.categories.join(','),
        SortBy: q.filter.sortBy,
        Direction: q.filter.direction,
        Query: encodeURIComponent(q.filter.query),
        SearchType: q.filter.searchType,
        ID: q.userId,
    };

    if (q.filter.showUnansweredOnly) {
        params.Answered = 0;
    }

    return axios.get('/dynamic-statistics', {
        params,
    });
}

function getDynamicQueues(q) {
    const params = {
        Statuses: q.filter.statuses.join(','),
        Limit: q.filter.items,
        Page: q.filter.page,
        UserId: q.filter.userId,
        GroupId: q.filter.queues.join(','),
        InternalType: q.filter.channels.join(','),
        CategoryIds: q.filter.categories.join(','),
        SortBy: q.filter.sortBy,
        Direction: q.filter.direction,
        Query: encodeURIComponent(q.filter.query),
        SearchType: q.filter.searchType,
        ID: q.userId,
        DataPoint: q.dataPoint,
    };

    if (q.filter.showUnansweredOnly) {
        params.Answered = 0;
    }

    return axios.get('/dynamic-statistics/queues', {
        params,
    });
}

function getDynamicChoices(id) {
    return axios.get('/dynamic-statistics/choices', {
        params: {
            id,
        },
    });
}

function deleteDynamicStatistics(q) {
    return axios.delete('/dynamic-statistics', {
        params: {
            id: q.id,
            userId: q.userId,
        },
    });
}

function updateDynamicStatistics(q) {
    return axios.put('/dynamic-statistics', {
        userId: q.userId,
        dataPoint: q.dataPoint,
        listOrder: q.listOrder,
    });
}

function addDynamicStatistics(q) {
    return axios.post('/dynamic-statistics', {
        userId: q.userId,
        dataPoint: q.dataPoint,
        listOrder: q.listOrder,
    });
}

function getDynamicStatisticsItem(q) {
    return axios.get('/dynamic-statistics/item', {
        params: {
            id: q,
        },
    });
}

function updateDynamicOrder(q, userId) {
    return axios.post(`/dynamic-statistics/order?&userId=${userId}`, q);
}

function getCurveStatistics(q) {
    const params = {
        Statuses: q.filter.statuses.join(','),
        Limit: q.filter.items,
        Page: q.filter.page,
        UserIds: q.filter.userIds,
        GroupId: q.filter.queues.join(','),
        InternalType: q.filter.channels.join(','),
        CategoryIds: q.filter.categories.join(','),
        SortBy: q.filter.sortBy,
        Direction: q.filter.direction,
        Query: encodeURIComponent(q.filter.query),
        SearchType: q.filter.searchType,
        DataPoint: q.dataPoint,
    };

    if (q.filter.showUnansweredOnly) {
        params.Answered = 0;
    }

    return axios.get('/dynamic-statistics/curve', {
        params,
    });
}

export default {
    getDynamicStatistics,
    getDynamicChoices,
    deleteDynamicStatistics,
    updateDynamicStatistics,
    addDynamicStatistics,
    getDynamicStatisticsItem,
    updateDynamicOrder,
    getCurveStatistics,
    getDynamicQueues,
};
