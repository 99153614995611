import axios from 'axios';

function getSettings(configKeys) {
    return axios.get(`/system/configurations/?configKeys=${configKeys}`);
}
function updateDoc(payload) {
    return axios.put(`/system/settings`, payload);
}
function updateSingleValue(payload) {
    const { keysToChange, route } = payload;
    return axios.put(`/system/settings/${route}`, { keysToChange });
}

export default {
    getSettings,
    updateDoc,
    updateSingleValue,
};
