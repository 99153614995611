import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"d-flex",class:_vm.getPositionClass(_vm.position)},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"text-box"},'div',attrs,false),on),[_c('Transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"d-flex flex-column",class:_vm.getPositionClass(_vm.position, 'column'),style:({
                            color: _vm.item.UnreadMessages > 0 ? 'var(--v-gray4-base)' : 'var(--v-gray2-base)',
                            fontWeight: _vm.item.UnreadMessages > 0 ? '500' : '400',
                        })},[_c('div',{staticClass:"text-truncate d-flex flex-row align-center",style:(_vm.background && _vm.value !== _vm.$t('noOutcome') && _vm.value !== ' '
                                    ? {
                                          backgroundColor: _vm.backgroundClr,
                                          color: 'white',
                                          padding: '4px',
                                          paddingLeft: '12px',
                                          height: '32px',
                                          fontSize: _vm.listLayout == 'big' ? '14px' : '12px',
                                          borderRadius: '4px',
                                      }
                                    : '')},[_c('span',{staticClass:"text-truncate"},[(_vm.compactValue)?_c(VIcon,[_vm._v("mdi-application-brackets-outline")]):_vm._e(),_vm._v(" "+_vm._s(_vm.compactValue ? _vm.$t('containsHtml') : _vm.formatedValue)+" ")],1)]),(_vm.listLayout.value == 'big')?_c('div',{staticClass:"text-truncate d-flex",staticStyle:{"font-weight":"400 !important","color":"var(--v-gray1-base)"}}):_vm._e()])])],1)]}}])},[(_vm.listLayout.value == 'big')?_c('span',[_vm._v(" "+_vm._s(_vm.tooltipValue)+" ")]):_c('span',[_vm._v(_vm._s(_vm.headerValue)+": "+_vm._s(_vm.tooltipValue))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }