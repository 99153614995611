export const wrapupActions = {
    EXTEND: 'extend',
    RESET: 'reset',
};

export const teamsAvailability = Object.freeze({
    AVAILABLE: 'Available',
    BUSY: 'Busy',
    DO_NOT_DISTURB: 'DoNotDisturb',
    BE_RIGHT_BACK: 'BeRightBack',
    AWAY: 'Away',
    OFF_WORK: 'OffWork',
    OFFLINE: 'Offline',
    IN_A_MEETING: 'InAMeeting',
    IN_A_CALL: 'InACall',
    PRESENTING: 'Presenting',
    INACTIVE: 'Inactive',
    IN_A_CONFERENCE_CALL: 'InAConferenceCall',
});

export default {
    wrapupActions: Object.freeze(wrapupActions),
};
