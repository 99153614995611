<template>
    <div v-if="prerender" :key="render" class="module-container">
        <section class="module-header">
            <h2 class="module-header">{{ header }}</h2>
        </section>
        <section class="module-list-container">
            <infinite-list
                :fetchItems="fetchFunction"
                :items="items"
                :limit="30"
                :emptyHeader="headerNoItems"
                :emptyMessage="message"
                emptyIcon="mdi-google-downasaur"
                size="small"
            >
                <template #item="{ item }">
                    <component :is="moduleComponent" :item="item" />
                </template>
            </infinite-list>
        </section>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    import ModuleCase from './Items/ModuleCase.vue';
    import ModuleDialog from './Items/ModuleDialog.vue';
    import ModuleQueue from './Items/ModuleQueue.vue';

    export default {
        name: 'Module',

        components: {
            ModuleCase,
            ModuleDialog,
            ModuleQueue,
        },

        props: {
            type: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                polling: null,
                render: 0,
                prerender: false,
                isTabActive: true,
            };
        },

        computed: {
            ...mapState('ActiveCases', ['activeCases', 'activeDialogs', 'activeQueues']),
            ...mapState('Users', ['userActionModuleOpen']),

            moduleComponent() {
                switch (this.type) {
                    case 'cases': {
                        return ModuleCase;
                    }
                    case 'dialogs': {
                        return ModuleDialog;
                    }
                    case 'queues': {
                        return ModuleQueue;
                    }
                    default: {
                        return ModuleCase;
                    }
                }
            },

            icon() {
                switch (this.type) {
                    case 'cases': {
                        return 'mdi-briefcase';
                    }
                    case 'dialogs': {
                        return 'mdi-chat';
                    }
                    case 'queues': {
                        return 'mdi-account-group';
                    }
                    default: {
                        return 'mdi-briefcase';
                    }
                }
            },

            headerNoItems() {
                switch (this.type) {
                    case 'cases': {
                        return this.$t('activeCases.activeCasesNoItems');
                    }
                    case 'dialogs': {
                        return this.$t('activeCases.activeDialogsNoItems');
                    }
                    case 'queues': {
                        return this.$t('activeCases.activeQueuesNoItems');
                    }
                    default: {
                        return this.$t('activeCases.activeCasesNoItems');
                    }
                }
            },

            header() {
                switch (this.type) {
                    case 'cases': {
                        return this.$t('activeCases.activeCases');
                    }
                    case 'dialogs': {
                        return this.$t('activeCases.activeDialogs');
                    }
                    case 'queues': {
                        return this.$t('activeCases.activeQueues');
                    }
                    default: {
                        return this.$t('activeCases.activeCases');
                    }
                }
            },

            message() {
                switch (this.type) {
                    case 'cases': {
                        return this.$t('activeCases.activeCasesMessage');
                    }
                    case 'dialogs': {
                        return this.$t('activeCases.activeDialogsMessage');
                    }
                    case 'queues': {
                        return this.$t('activeCases.activeQueuesMessage');
                    }
                    default: {
                        return this.$t('activeCases.activeCasesMessage');
                    }
                }
            },

            fetchFunction() {
                switch (this.type) {
                    case 'cases': {
                        return this.getActiveCases;
                    }
                    case 'dialogs': {
                        return this.getActiveDialogs;
                    }
                    case 'queues': {
                        return this.getActiveQueues;
                    }
                    default: {
                        return this.getActiveCases;
                    }
                }
            },

            items() {
                switch (this.type) {
                    case 'cases': {
                        return this.activeCases;
                    }
                    case 'dialogs': {
                        return this.activeDialogs;
                    }
                    case 'queues': {
                        return this.activeQueues;
                    }
                    default: {
                        return this.activeCases;
                    }
                }
            },
        },

        watch: {
            userActionModuleOpen() {
                if (this.userActionModuleOpen) {
                    this.prerender = true;
                    this.render++;
                }
            },
        },

        created() {
            this.polling = setInterval(() => {
                if (!this.userActionModuleOpen) return;
                if (!this.isTabActive) return;
                this.fetchFunction({ limit: this.items.length, page: 1 });
            }, 60000);

            document.addEventListener('visibilitychange', this.handleVisibilityChange);
        },

        beforeDestroy() {
            document.removeEventListener('visibilitychange', this.handleVisibilityChange);
            clearInterval(this.polling);
        },

        methods: {
            ...mapActions('ActiveCases', ['getActiveCases', 'getActiveDialogs', 'getActiveQueues']),

            handleVisibilityChange() {
                this.isTabActive = !document.hidden;

                if (this.isTabActive && this.userActionModuleOpen) {
                    this.fetchFunction({ limit: this.items.length, page: 1 });
                }
            },
        },
    };
</script>

<style scoped>
    .module-container {
        background: var(--v-gray3-base);
        border-radius: 8px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        min-height: 235px;
        max-height: 235px;
    }

    .module-list-container {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        padding: 0px 8px;
        max-height: 192px;
    }

    .module-header {
        padding: 4px;
    }

    h2 {
        font-weight: 400;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #6f6f6f;
    }
</style>

<i18n lang="json">
{
    "en": {
        "activeCases": {
            "activeCases": "Active Cases",
            "activeDialogs": "Active Dialogs",
            "activeQueues": "Active Queues",
            "activeCasesMessage": "You don't have any active cases.",
            "activeDialogsMessage": "You don't have any active dialogs.",
            "activeQueuesMessage": "You don't have any active queues.",
            "activeCasesNoItems": "No active cases",
            "activeDialogsNoItems": "No active dialogs",
            "activeQueuesNoItems": "No active queues"
        }
    },
    "sv": {
        "activeCases": {
            "activeCases": "Aktiva ärenden",
            "activeDialogs": "Aktiva dialoger",
            "activeQueues": "Aktiva köer",
            "activeCasesMessage": "Du har inga aktiva ärenden.",
            "activeDialogsMessage": "Du har inga aktiva dialoger.",
            "activeQueuesMessage": "Du har inga aktiva köer.",
            "activeCasesNoItems": "Inga aktiva ärenden",
            "activeDialogsNoItems": "Inga aktiva dialoger",
            "activeQueuesNoItems": "Inga aktiva köer"
        }
    }
}
</i18n>
