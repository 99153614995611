module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "SignatureView": {
          "noSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No signature"])},
          "noSignatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no signatures"])},
          "addGotoTempl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add by going to the template manager."])},
          "templateManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template manager"])},
          "onlyActivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only activated templates are visible"])}
        }
      },
      "sv": {
        "SignatureView": {
          "noSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen signatur"])},
          "noSignatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det finns tyvärr inga signaturer."])},
          "addGotoTempl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till genom att gå till mall-hanteringen."])},
          "templateManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mall-hanteringen"])},
          "onlyActivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endast aktiverade mallar syns"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"SignatureView":{"noSignature":"No signature","noSignatures":"There are no signatures","addGotoTempl":"Add by going to the template manager.","templateManager":"Template manager","onlyActivated":"Only activated templates are visible"}},"sv":{"SignatureView":{"noSignature":"Ingen signatur","noSignatures":"Det finns tyvärr inga signaturer.","addGotoTempl":"Lägg till genom att gå till mall-hanteringen.","templateManager":"Mall-hanteringen","onlyActivated":"Endast aktiverade mallar syns"}}}')
  delete Component.options._Ctor
  
}
