import i18n from '../../i18n';

const state = {
    headersMetadata: {
        categoriesMetadata: null,
        queuesMetadata: null,
    },
};

const actions = {
    async getHeadersMetadata({ commit, dispatch, state }) {
        const [categoriesMetadata, queuesMetadata] = await Promise.all([
            dispatch('Cases/getCaseCategories', null, { root: true }),
            dispatch('getQueuesMetadata'),
        ]);
        const data = {
            ...state.headersMetadata,
            categoriesMetadata,
            queuesMetadata,
        };
        commit('SET_HEADERS_METADATA', data);
    },

    async getQueuesMetadata({ dispatch }) {
        const data = await dispatch('QueueManager/getQueues', {}, { root: true });
        const queues = data.map((queue) => {
            return { Name: queue.Name, ID: queue.ID };
        });
        queues.unshift({
            Name: !data ? i18n.t('casesStore.wrong') : i18n.t('casesStore.noQueue'),
            ID: 0,
        });
        return queues;
    },
};
const mutations = {
    SET_HEADERS_METADATA(state, headersMetadata) {
        state.headersMetadata = headersMetadata;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
