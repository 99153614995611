module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "dynamicStatistics": {
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
          "switchTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to"])},
          "personalStats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Statistics"])},
          "globalStats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Statistics"])},
          "clickToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to add items"])},
          "noItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No items to show"])}
        }
      },
      "sv": {
        "dynamicStatistics": {
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera"])},
          "switchTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Växla till"])},
          "personalStats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personlig statistik"])},
          "globalStats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["global statistik"])},
          "clickToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicka för att lägga till objekt"])},
          "noItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga objekt att visa"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"dynamicStatistics":{"edit":"Edit","switchTo":"Switch to","personalStats":"Personal Statistics","globalStats":"Global Statistics","clickToAdd":"Click to add items","noItems":"No items to show"}},"sv":{"dynamicStatistics":{"edit":"Redigera","switchTo":"Växla till","personalStats":"personlig statistik","globalStats":"global statistik","clickToAdd":"Klicka för att lägga till objekt","noItems":"Inga objekt att visa"}}}')
  delete Component.options._Ctor
  
}
