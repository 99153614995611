import { jwtParser } from '@/utils';
import axios from 'axios';
import store from '../store';
import i18n from '../i18n';

export default {
    /**
     *
     * @param {Object} headers - Request headers containing private tokens
     * @param {Object} data  - Request header containing public user data
     * @description Apply data from tokens etc
     */
    saveTokens(data) {
        try {
            const tokens = data.private;
            const userData = data.public;
            if (!tokens) {
                console.log('saveTokens - No tokens');
                return null;
            }

            const { AccessToken } = tokens;

            // access data in token, use its' data and create userObj
            const parsedAccessToken = jwtParser(AccessToken);

            if (userData) {
                const userObj = {
                    ...userData,
                    userId: parsedAccessToken.UserId,
                    userName: parsedAccessToken.UserName,
                    credentialName: parsedAccessToken.CredentialName,
                    email: parsedAccessToken.Email,
                    profileImageUrl: userData.ProfilePicture,
                };

                store.dispatch('Auth/setUser', userObj);
                localStorage.setItem('__userObject', JSON.stringify(userObj));
            }
            // store tokens
            localStorage.setItem('loggedIn', 'true');
            store.dispatch('Auth/setAccessToken', AccessToken);
            // set new access token as default auth header
            axios.defaults.headers.common.Authorization = AccessToken || '';

            store.dispatch('Auth/setLoginState', true);
            store.dispatch('Auth/setRoles', parsedAccessToken.Roles);
        } catch (error) {
            console.log('saveTokens - Error', error);
            store.dispatch('Auth/logoutClient', i18n.t('global.auth.sessionExpired'));
        }
    },
    removeToken() {
        delete axios.defaults.headers.common.Authorization;
    },
};
