import comments from '@/api/comments/comments';
import { httpStatusCode } from '@/enums/response.enums';
import { tiptapStates } from '@/enums/comments.enums';
import { events, caseTypes } from '../../enums/cases.enums';
import i18n from '../../i18n';
import helper from '../../helpers/comments/comments.helper';

const state = {
    /* -------Tiptap fields-----------*/

    isForwarding: false,
    subject: '',
    emailFrom: '',
    emailToRecipients: [],
    emailCcRecipients: [],
    emailBccRecipients: [],
    repliedComment: null,

    content: '',
    files: [],
    contentAI: '',
    showCcs: false,
    currentExternalState: true,

    /* -------Tiptap visual state-----------*/

    tiptapState: localStorage.getItem('__tiptapState_') || tiptapStates.CLOSED, // 'open', 'closed', 'full'
    tiptapHeight: localStorage.getItem('__tiptapHeight_') || 500,
    caseCommentsWidth: 1000,
    caseCommentsHeight: 1000,

    /* ------- Comments -----------*/

    commentSearch: '', // search value to search among comments
    attachChecked: [], // List of all checked comment ids
    selectedCaseCommentIds: [], // List of all comment ids in the selected case
    reversedCommentsOrder: false, // true if newest comment is at the bottom
};

const getters = {
    getEmailToRecipients(state) {
        return state.emailToRecipients;
    },

    getLatestEmailComment(state, getters, rootState) {
        if (!rootState.Cases.selectedCase) return null;

        const isReversed = rootState.System.userSettings?.cases.reverseCommentsOrder.active;
        const comments = [...rootState.Cases.selectedCase.comments];
        if (isReversed) {
            return comments.reverse().find((comment) => comment.typeOfMessage === caseTypes.EMAIL);
        }
        return comments.find((comment) => comment.typeOfMessage === caseTypes.EMAIL);
    },

    getAllCommentsChecked(state) {
        return state.selectedCaseCommentIds.every((comment) => state.attachChecked.includes(comment));
    },
    getShowCheckboxes(state, getters, rootState) {
        return state.tiptapState === tiptapStates.CLOSED ? false : rootState.Forwarder.showAttachCheckBoxes;
    },
    emailsValid(state) {
        const allEmails = [...state.emailToRecipients, ...state.emailCcRecipients, ...state.emailBccRecipients];

        for (const email of allEmails) {
            if (!helper.validateEmail(email)) {
                return false;
            }
        }

        return state.emailToRecipients.length;
    },
};

const actions = {
    // Will fix this complexity and refactor this later (should have a better way to handle these events than a switch case)
    // eslint-disable-next-line sonarjs/cognitive-complexity
    SOCKET_changeInCurrentCase({ rootState, commit }, response) {
        try {
            const { data, type } = response;
            const currentCase = structuredClone(rootState.Cases.selectedCase);

            switch (type) {
                case events.NEW_CASE: {
                    // This should never happen cause the user dont subscribe to a case that doesnt exist
                    // We should in the future be able to open up the case when its created by subscribing to future cases as well
                    break;
                }

                case events.OTHER_USER_UPDATED_CASE: {
                    // If another user changed the case we need to update the case data in the store and toast the user that the case has been updated

                    if (currentCase.case.caseId !== data.case.caseId) return;

                    currentCase.case = data.case;
                    currentCase.event = events.OTHER_USER_UPDATED_CASE;
                    commit('Cases/SET_SELECTED_CASE', currentCase, { root: true });

                    this._vm.$toasted.show(i18n.t('cases.detailsUpdated'), {
                        theme: 'toasted-information',
                        position: 'bottom-center',
                        duration: 30_000,
                        icon: 'mdi-reload',
                        action: {
                            text: i18n.t('comment.close'),
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                            },
                        },
                    });

                    break;
                }

                case events.UPDATE_CASE: {
                    if (currentCase.case.caseId !== data.case.caseId) return;

                    currentCase.case = data.case;
                    currentCase.subCases = data.subCases;
                    currentCase.event = events.UPDATE_CASE;
                    commit('Cases/SET_SELECTED_CASE', currentCase, { root: true });
                    break;
                }

                case events.NEW_COMMENT: {
                    const newComment = data;
                    if (newComment.caseId !== currentCase.case.caseId) return;

                    if (state.reversedCommentsOrder) {
                        currentCase.comments.push(newComment);
                        // Scroll to bottom
                        const targetDiv = document.querySelector('.comment-content');
                        const scrollTo = targetDiv.scrollHeight;

                        if (targetDiv) {
                            targetDiv.scrollTo({
                                top: scrollTo,
                                behavior: 'smooth',
                            });
                        }
                    } else {
                        currentCase.comments.unshift(newComment);
                    }

                    // Notify the user that a new comment has been added if its not a system comment
                    // Update the case data if the case is updated

                    const creatorIsMe = data.userId === rootState.Auth.userObject.userId;

                    if (newComment.typeOfMessage !== 'system' && !creatorIsMe) {
                        this._vm.$toasted.show(i18n.t('cases.activity'), {
                            theme: 'toasted-information',
                            position: 'bottom-center',
                            duration: 30_000,
                            icon: 'mdi-plus',
                            action: {
                                text: i18n.t('comment.scrollToTop'),
                                onClick: (e, toastObject) => {
                                    toastObject.goAway(0);
                                    const targetDiv = document.querySelector('.comment-content');
                                    const scrollTo = state.reversedCommentsOrder ? targetDiv.scrollHeight : 0;

                                    if (targetDiv) {
                                        targetDiv.scrollTo({
                                            top: scrollTo,
                                            behavior: 'smooth',
                                        });
                                    }
                                },
                            },
                        });
                    }

                    currentCase.event = events.NEW_COMMENT;
                    commit('Cases/SET_SELECTED_CASE', currentCase, { root: true });
                    break;
                }

                case events.UPDATE_COMMENT: {
                    const newComment = data;

                    // Causes error if not in a case
                    if (!currentCase) {
                        return;
                    }

                    // If comments is updated that is currently in the comments array we need to update them
                    const currentComment = currentCase.comments.find((comment) => comment.id === newComment.id);

                    if (currentComment) {
                        currentCase.comments = currentCase.comments.map((comment) => {
                            if (comment.id === newComment.id) {
                                return newComment;
                            }
                            return comment;
                        });
                    }

                    // Don't send a toast if sent been updated (we handle this with update)
                    const isCommentSent = currentComment && currentComment.sent === 1 && newComment.sent === 1;
                    const messageIdChanged = currentComment && currentComment.msgId !== newComment.msgId;
                    const conversationIdChanged =
                        currentComment && currentComment.conversationId !== newComment.conversationId;

                    if (isCommentSent && !messageIdChanged && !conversationIdChanged) {
                        this._vm.$toasted.show(i18n.t('cases.itemsUpdated'), {
                            theme: 'toasted-information',
                            position: 'bottom-center',
                            duration: 30_000,
                            icon: 'mdi-reload',
                            action: {
                                text: i18n.t('comment.close'),
                                onClick: (e, toastObject) => {
                                    toastObject.goAway(0);
                                },
                            },
                        });
                    }

                    currentCase.event = events.UPDATE_COMMENT;
                    commit('Cases/SET_SELECTED_CASE', currentCase, { root: true });
                    break;
                }

                case events.DELETE_COMMENT: {
                    const deletedComment = data;

                    if (!currentCase) {
                        break;
                    }

                    const index = currentCase.comments.findIndex((comment) => comment.id === deletedComment.id);
                    if (index !== -1) {
                        currentCase.comments.splice(index, 1);
                    }

                    currentCase.event = events.DELETE_COMMENT;
                    commit('Cases/SET_SELECTED_CASE', currentCase, { root: true });
                    break;
                }

                default: {
                    // Default we do nothing
                    break;
                }
            }
        } catch (error) {
            console.error('Could not update case', error);
        }
    },

    setReversedCommentsOrder({ commit }, payload) {
        commit('SET_REVERSED_COMMENTS_ORDER', payload);
    },

    setIsForwarding({ commit }, payload) {
        commit('SET_IS_FORWARDING', payload);
    },

    setCommentContent({ state, commit }, { content, caseId, isForwarding, external }) {
        if (!caseId) return;

        if (isForwarding) {
            commit('SET_IS_FORWARDING', true);
        }

        if (external) {
            commit('SET_CURRENT_EXTERNAL_STATE', external);
        }

        const forwarding = state.isForwarding;

        commit('SET_COMMENT_CONTENT', content);

        const sanitizedContentElement = document.createElement('div');
        sanitizedContentElement.innerHTML = content;
        // * set all src to empty string to avoid loading images
        for (const img of sanitizedContentElement.querySelectorAll('img')) {
            img.src = '';
        }

        const contentObj = { content: sanitizedContentElement.innerHTML, isForwarding: forwarding || false };
        const contentJsonString = JSON.stringify(contentObj);

        const CONTENT_START_LENGTH = 0;
        const CONTENT_MIN_LENGTH = 7;

        const caseStringLocalStorage = forwarding ? '__caseContentForward_' : '__caseContent_';

        // ! We dont save forwarding content due to problems with inline images, for now. We have a incoming fix for this. - Teodor 2024-04-08
        if (
            !forwarding &&
            content &&
            (content.length > CONTENT_MIN_LENGTH || content.length === CONTENT_START_LENGTH)
        ) {
            localStorage.setItem(caseStringLocalStorage + caseId, contentJsonString);
        } else {
            localStorage.removeItem(caseStringLocalStorage + caseId);
        }
    },
    setCommentFiles({ commit }, { caseId, files }) {
        if (!caseId) return;
        commit('SET_COMMENT_FILES', files);
    },
    clearCommentFiles({ commit }) {
        commit('SET_COMMENT_FILES', []);
    },

    setAttachChecked({ state, commit }, { commentId, value }) {
        if (value) {
            // 1. Add attach
            // if it already exists, do nothing,
            if (state.attachChecked.includes(commentId)) return;
            commit('SET_ATTACH_CHECKED', [...state.attachChecked, commentId]);
            return;
        }

        // 2. Remove attach
        commit(
            'SET_ATTACH_CHECKED',
            state.attachChecked.filter((id) => id !== commentId),
        );
    },

    setEmailFrom({ commit }, payload) {
        commit('SET_EMAIL_FROM', payload);
    },

    async checkAllComments({ commit, rootState, dispatch }) {
        const commentIds = await dispatch('getCommentIds', rootState.Cases.selectedCase.case.caseId);
        commit('SET_ATTACH_CHECKED', commentIds);
    },

    clearAttachChecked({ commit }) {
        commit('SET_ATTACH_CHECKED', []);
    },

    setSubject({ commit }, payload) {
        commit('SET_SUBJECT', payload);
    },

    setTiptapHeight({ commit }, payload) {
        commit('SET_TIPTAP_HEIGHT', payload);
    },

    setCommentSearch({ commit }, payload) {
        commit('SET_COMMENT_SEARCH', payload);
    },

    openTiptap({ commit }) {
        commit('SET_TIPTAP_STATE', tiptapStates.OPEN);
    },

    closeTiptap({ commit, dispatch, rootState }) {
        commit('SET_TIPTAP_STATE', tiptapStates.CLOSED);

        if (state.isForwarding) {
            commit('SET_IS_FORWARDING', false);
        }

        dispatch('clearRepliedComment');
    },

    fullTiptap({ commit }) {
        commit('SET_TIPTAP_STATE', tiptapStates.FULL);
    },

    setShowCCs({ commit }, payload) {
        commit('SET_SHOW_CCS', payload);
    },

    // Recordings
    getRecordingsMetaData(_, payload) {
        return comments.getRecordingsMetaData(payload);
    },
    async getRecording(_, payload) {
        try {
            const result = await comments.getRecording(payload);
            return result.data;
        } catch (error) {
            if (error.response.status === httpStatusCode.NOT_FOUND) {
                this._vm.$toasted.show(i18n.t('commentStore.recordingNotSynced'), {
                    icon: 'cancel',
                    type: 'error',
                });
                throw error;
            }
            this._vm.$toasted.show(i18n.t('commentStore.couldntPlayRecording'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    // Tiptap
    setContentOfTipTapEditor({ commit }, payload) {
        if (payload.choices) {
            commit('SET_COMMENT_CONTENT', payload.choices[0].delta.content);
            return;
        }
        commit('RESET_CONTENT_EDITOR');
        commit('SET_COMMENT_CONTENT_AI', payload);
    },

    setContentOfTipTapEditorPartial({ commit }, payload) {
        if (payload) {
            commit('SET_COMMENT_CONTENT_AI', payload.content.delta.content);
        } else {
            commit('RESET_CONTENT_EDITOR');
        }
    },

    // Buttons

    setCurrentExternalState({ commit }, payload) {
        commit('SET_CURRENT_EXTERNAL_STATE', payload);
    },

    // Get comment id
    getLatestCommentId({ rootState }) {
        let latestCommentId = null;
        if (rootState.Cases.selectedCase) {
            const { selectedCase } = rootState.Cases;
            const comments = [...selectedCase.comments];
            if (selectedCase.comments.length > 0) {
                for (const comment of comments.reverse()) {
                    if (comment.Type == 1) {
                        latestCommentId = comment.ID;
                        break;
                    }
                }
            } else {
                latestCommentId = selectedCase.caseStart.ID;
            }
        }
        return latestCommentId;
    },

    // Email start
    setEmailToRecipients({ commit }, payload) {
        commit('SET_EMAIL_TO_RECIPIENTS', payload);
    },

    setEmailCcRecipients({ commit }, payload) {
        commit('SET_EMAIL_CC_RECIPIENTS', payload);
    },

    setEmailBccRecipients({ commit }, payload) {
        commit('SET_EMAIL_BCC_RECIPIENTS', payload);
    },

    async getCommentIds({ commit }, caseId) {
        const { data: commentIds } = await comments.getCommentIds(caseId);
        commit('SET_SELECTED_CASE_COMMENT_IDS', commentIds);
        return commentIds;
    },

    setRepliedComment({ commit }, payload) {
        commit('SET_REPLIED_COMMENT', payload);
    },

    clearRepliedComment({ dispatch }) {
        dispatch('setRepliedComment', null);
    },

    async deleteComment(_, commentId) {
        try {
            await comments.deleteComment(commentId);
        } catch (error) {
            console.error('Error deleting comment', error);

            this._vm.$toasted.show(i18n.t('commentStore.deleteFailed'), error, {
                icon: 'cancel',
                type: 'error',
            });
        }
    },
};

const mutations = {
    SET_IS_FORWARDING(state, payload) {
        state.isForwarding = payload;
    },
    SET_REVERSED_COMMENTS_ORDER(state, data) {
        state.reversedCommentsOrder = data;
    },
    SET_COMMENT_CONTENT(state, data) {
        state.content = data;
    },
    SET_COMMENT_FILES(state, data) {
        state.files = data;
    },
    SET_EMAIL_FROM(state, data) {
        state.emailFrom = data;
    },
    SET_SUBJECT(state, data) {
        state.subject = data;
    },
    SET_ATTACH_CHECKED(state, data) {
        state.attachChecked = data;
    },
    SET_TIPTAP_STATE(state, data) {
        state.tiptapState = data;
        localStorage.setItem('__tiptapState_', data);
    },
    RESET_CONTENT_EDITOR(state) {
        state.content = '';
    },
    SET_CASE_COMMENTS_WIDTH(state, data) {
        state.caseCommentsWidth = data;
    },
    SET_CASE_COMMENTS_HEIGHT(state, data) {
        state.caseCommentsHeight = data;
    },

    SET_EMAIL_TO_RECIPIENTS(state, data) {
        state.emailToRecipients = data;
    },
    SET_EMAIL_CC_RECIPIENTS(state, data) {
        state.emailCcRecipients = data;
    },
    SET_EMAIL_BCC_RECIPIENTS(state, data) {
        state.emailBccRecipients = data;
    },
    SET_SHOW_CCS(state, data) {
        state.showCcs = data;
    },

    SET_CURRENT_EXTERNAL_STATE(state, data) {
        state.currentExternalState = data;
    },
    SET_COMMENT_SEARCH(state, data) {
        state.commentSearch = data;
    },

    SET_TIPTAP_HEIGHT(state, data) {
        state.tiptapHeight = data;
        localStorage.setItem('__tiptapHeight_', data);
    },
    SET_COMMENT_CONTENT_AI(state, data) {
        if (!data) return;
        // Replace new lines with <br> to show them in the editor because the command call just returns non html text
        data = data.replaceAll('\n', '<br>');
        state.contentAI = data;
    },
    SET_SELECTED_CASE_COMMENT_IDS(state, data) {
        state.selectedCaseCommentIds = data;
    },
    SET_REPLIED_COMMENT(state, data) {
        state.repliedComment = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
