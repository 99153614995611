import axios from 'axios';

function createTemplate(template) {
    return axios.post('/v2/templates', template);
}

function updateTemplate(template) {
    return axios.put(`/v2/templates/${template.id}`, template);
}

function removeTemplate(templateId) {
    return axios.delete(`/v2/templates/${templateId}`);
}

/**
 * Retrieves the categories of system templates.
 *
 * @returns {Promise} A promise that resolves with the categories.
 */
function categories() {
    return axios.get('/v2/templates/categories');
}

/**
 * Retrieves templates by category.
 *
 * @param {number} categoryId - The ID of the category.
 * @param {Object} pagination - The pagination object.
 * @param {number} pagination.offset - The offset value for pagination.
 * @param {number} pagination.limit - The limit value for pagination.
 * @param {string} pagination.search - The search query.
 * @param {string} pagination.language - The language code.
 * @returns {Promise} - A promise that resolves to the response from the server.
 */
function templateByCategory(categoryId, pagination) {
    const { offset, limit, search, language, queueIds } = pagination;

    return axios.get('/v2/templates', {
        params: {
            categoryId,
            offset,
            limit,
            query: search,
            language,
            queues: queueIds,
        },
    });
}

/**
 * Retrieves a template by its ID.
 *
 * @param {string} templateId - The ID of the template to retrieve.
 * @returns {Promise} A promise that resolves with the template data.
 */
function getTemplateById(templateId) {
    return axios.get(`/v2/templates/${templateId}`);
}

export default {
    categories,
    templateByCategory,
    getTemplateById,

    removeTemplate,
    updateTemplate,
    createTemplate,
};
