import callDivert from '@/api/callDivert/callDivert';
import i18n from '../../i18n';

// State object
const state = {
    divertOptions: [],
    c1DivertsOptions: [],

    c1Presence: {},
    presence: null,
};
// Getter functions
const getters = {
    getDivertOptions(state) {
        return state.divertOptions;
    },
    getPresence(state) {
        return state.presence;
    },
};
// Actions
const actions = {
    divertOptions({ commit }) {
        return new Promise((resolve, reject) => {
            callDivert
                .divertOptions()
                .then((res) => {
                    commit('SET_DIVERT_OPTIONS', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    setPresence({ commit, rootState }, payload) {
        return new Promise((resolve, reject) => {
            const { userObject } = rootState.Auth;
            if (!payload.userId) {
                payload.userId = userObject.userId;
            }

            callDivert
                .setPresence(payload)
                .then((res) => {
                    let toastText = '';

                    if (userObject.userId === payload.userId) {
                        commit('SET_PRESENCE', res.data);
                        toastText = i18n.t('callDivertStore.youreDiverted');
                    } else {
                        toastText = i18n.t('callDivertStore.diverted');
                    }

                    this._vm.$toasted.show(toastText, {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });

                    resolve(res);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('callDivertStore.wrong'), {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    getPresence({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            const { userObject } = rootState.Auth;
            callDivert
                .getPresence(userObject.userId)
                .then((res) => {
                    commit('SET_PRESENCE', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

// Mutations
const mutations = {
    SET_DIVERT_OPTIONS(state, data) {
        state.divertOptions = data;
    },
    SET_PRESENCE(state, data) {
        state.presence = data;
    },
    SET_C1_PRESENCE(state, data) {
        state.c1Presence = data;
    },
    SET_C1_OPTIONS(state, data) {
        state.c1DivertsOptions = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
