module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "comment": {
          "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
          "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
          "dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialog"])},
          "noSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your browser does not support the audio element."])}
        }
      },
      "sv": {
        "comment": {
          "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spela upp"])},
          "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summering"])},
          "dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialog"])},
          "noSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din webbläsare stödjer inte ljudfiler."])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"comment":{"play":"Play","summary":"Summary","dialog":"Dialog","noSupport":"Your browser does not support the audio element."}},"sv":{"comment":{"play":"Spela upp","summary":"Summering","dialog":"Dialog","noSupport":"Din webbläsare stödjer inte ljudfiler."}}}')
  delete Component.options._Ctor
  
}
