import axios from 'axios';
import cfg from '@/app/config';
import DedupeRequest from '../../entity/DedupeRequest';

function loginClient(auth) {
    return axios.get('auth', {
        headers: {
            Authorization: 'Basic ' + Buffer.from(`${auth.username}:${auth.password}`, 'utf8').toString('base64'),
        },
    });
}

function federatedLoginClient(path) {
    return axios.get('auth' + path);
}

const dedupeRefreshToken = new DedupeRequest(() => {
    return axios.get('auth/refresh');
}, 1000);

function refreshToken() {
    return dedupeRefreshToken.send();
}

function changePassword(data) {
    return axios.put('/credentials', data);
}

function swyxGeneratePassword(email) {
    return axios.post('swyx/generate/password', email);
}

function resetPassword(username) {
    return axios.post('/auth/reset-password', username);
}

function logoutClient(authenticationMethod) {
    window.location.href = `${cfg.customerFirstUrl}auth/${authenticationMethod.toLowerCase()}/logout`;
}

function logout() {
    return axios.get('/logout');
}

export default {
    loginClient,
    federatedLoginClient,
    refreshToken,
    changePassword,
    swyxGeneratePassword,
    logoutClient,
    resetPassword,
    logout,
};
