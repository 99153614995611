<template>
    <div v-if="orderIntegrations.length">
        <ClientCardActionButton
            v-for="(integration, i) of orderIntegrations"
            :key="i"
            state="orders"
            :tooltip-label="formatName(integration.name) + ' Orders'"
            :action="() => select(integration)"
        />
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import ClientCardActionButton from '@/components/Cases/ClientCard/ClientCardActionButton.vue';

    export default {
        components: {
            ClientCardActionButton,
        },
        data() {
            return {
                orderIntegrations: [],
            };
        },
        mounted() {
            this.init();
        },
        methods: {
            ...mapActions({
                sideBarView: 'Cases/sideBarView',
                selectOrderIntegration: 'Cases/selectOrderIntegration',
                getOrderIntegrations: 'Integrations/getOrderIntegrations',
            }),
            async init() {
                this.orderIntegrations = await this.getOrderIntegrations();
            },
            select(integration) {
                this.selectOrderIntegration(integration.name.toLowerCase());
                this.sideBarView('orders');
            },
            formatName(name) {
                return name.charAt(0).toUpperCase() + name.toLowerCase().slice(1);
            },
        },
    };
</script>
