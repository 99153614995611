export const campaignStrategyType = Object.freeze({
    MASS_EMAIL: 'MASS_EMAIL',
    MASS_SMS: 'MASS_SMS',
    COMPOSITE: 'COMPOSITE',
});

export const audienceType = Object.freeze({
    ADMINS: 'ADMINS',
    VIP: 'VIP',
    USERS: 'USERS',
    CLIENTS: 'CLIENTS',
    ACTIVE_CLIENTS: 'ACTIVE_CLIENTS',
    NEW_CLIENTS: 'NEW_CLIENTS',
    COMPOSITE: 'COMPOSITE',
});
