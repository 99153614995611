import customerManagement from '@/api/customerManagement/customerManagement';
import i18n from '../../i18n';

import { httpStatusCode } from '../../enums/response.enums';

const actions = {
    async searchClients(_, payload) {
        try {
            const res = await customerManagement.searchClients(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('customerManagement.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async getAllClientsData(_, payload) {
        try {
            const res = await customerManagement.getAllClientsData(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('customerManagement.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async getAllContactsData(_, payload) {
        try {
            const res = await customerManagement.getAllContactsData(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('customerManagement.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async addNewClient(_, payload) {
        try {
            const res = await customerManagement.addNewClient(payload);
            const { status } = res.data;

            let message = '';
            let icon = '';
            let type = '';

            switch (status) {
                case httpStatusCode.NO_CONTENT: {
                    message = i18n.t('customerManagement.clientAlreadyExists');
                    icon = 'mdi-content-save';
                    type = 'information';
                    break;
                }
                case httpStatusCode.CREATED:
                case httpStatusCode.OK: {
                    message = i18n.t('customerManagement.savedCustomer');
                    icon = 'mdi-content-save';
                    type = 'success';
                    break;
                }
                default: {
                    message = i18n.t('customerManagement.wrong');
                    icon = 'cancel';
                    type = 'error';
                }
            }

            this._vm.$toasted.show(message, {
                icon,
                type,
            });

            return res.data;
        } catch (error) {
            if (error.response?.data?.paths) {
                const { paths } = error.response.data;

                const errorMessage = paths.map((path) => i18n.t(`label.${path}`)).join(', ');

                this._vm.$toasted.show(i18n.t('customerManagement.validation') + errorMessage, {
                    icon: 'cancel',
                    type: 'error',
                });
                throw null;
            }
            const errorMessage = error.response?.data || '';
            this._vm.$toasted.show(i18n.t('customerManagement.wrong') + errorMessage, {
                icon: 'cancel',
                type: 'error',
            });
            throw null;
        }
    },

    async addNewContact(_, payload) {
        /* Makes SQL-call dynamic */
        try {
            const res = await customerManagement.addNewContact(payload);
            this._vm.$toasted.show(i18n.t('customerManagement.savedContact'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('customerManagement.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async deleteClient(_, payload) {
        try {
            const res = await customerManagement.deleteClient(payload);
            this._vm.$toasted.show('Kunden raderades', {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('customerManagement.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async getClientExtraData(_, payload) {
        try {
            const res = await customerManagement.getClientExtraData(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('customerManagement.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async getContactPersonExtraData(_, payload) {
        try {
            const res = await customerManagement.getContactPersonExtraData(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('customerManagement.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async getClientTemplate() {
        try {
            const res = await customerManagement.getClientTemplate();
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('customerManagement.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async getContactTemplate() {
        try {
            const res = await customerManagement.getContactTemplate();
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('customerManagement.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async updateContactInfo(_, payload) {
        try {
            const res = await customerManagement.updateContactInfo(payload);
            this._vm.$toasted.show(i18n.t('customerManagement.updateContactInfo'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('customerManagement.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async setDefaultCreateClient(_, payload) {
        try {
            const res = await customerManagement.setDefaultCreateClient(payload);
            this._vm.$toasted.show(i18n.t('customerManagement.dataSaved'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('customerManagement.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async getDefaultCreateClient() {
        try {
            const res = await customerManagement.getDefaultCreateClient();
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('customerManagement.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async generateClientId() {
        try {
            const res = await customerManagement.generateClientId();
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('customerManagement.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async getAvailableUsers(_, payload) {
        try {
            const res = await customerManagement.getAvailableUsers(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('customerManagement.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async addUsersToClient(_, payload) {
        try {
            const res = await customerManagement.addUsersToClient(payload);
            this._vm.$toasted.show(i18n.t('customerManagement.changesSaved'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('customerManagement.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async getMembers(_, payload) {
        try {
            const res = await customerManagement.getMembers(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('customerManagement.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async removeMember(_, payload) {
        try {
            const res = await customerManagement.removeMember(payload);
            this._vm.$toasted.show(i18n.t('customerManagement.changesSaved'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('customerManagement.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async removeAllMembers(_, payload) {
        try {
            const res = await customerManagement.removeAllMembers(payload);
            this._vm.$toasted.show(i18n.t('customerManagement.changesSaved'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('customerManagement.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    async updateClient(_, payload) {
        try {
            const res = await customerManagement.updateClient(payload);

            const { status } = res.data;

            let message = '';
            let icon = '';
            let type = '';

            switch (status) {
                case httpStatusCode.NO_CONTENT: {
                    message = i18n.t('customerManagement.clientAlreadyExists');
                    icon = 'mdi-content-save';
                    type = 'information';
                    break;
                }
                case httpStatusCode.CREATED:
                case httpStatusCode.OK: {
                    message = i18n.t('customerManagement.savedCustomer');
                    icon = 'mdi-content-save';
                    type = 'success';
                    break;
                }

                default: {
                    message = i18n.t('customerManagement.wrong');
                    icon = 'cancel';
                    type = 'error';
                }
            }

            this._vm.$toasted.show(message, {
                icon,
                type,
            });

            return res.data;
        } catch (error) {
            if (error.response?.data?.paths) {
                const { paths } = error.response.data;

                const errorMessage = paths.map((path) => i18n.t(`label.${path}`)).join(', ');

                this._vm.$toasted.show(i18n.t('customerManagement.validation') + errorMessage, {
                    icon: 'cancel',
                    type: 'error',
                });
                throw null;
            }
            const errorMessage = error.response?.data.message || '';
            this._vm.$toasted.show(i18n.t('customerManagement.wrong') + errorMessage, {
                icon: 'cancel',
                type: 'error',
            });
            throw null;
        }
    },

    async checkClient(_, payload) {
        try {
            const res = await customerManagement.checkClient(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('customerManagement.wrong'), {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
};

export default {
    namespaced: true,
    actions,
};
