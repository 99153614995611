<template>
    <section class="active-users-container">
        <CaseActivity />

        <v-tooltip v-if="usersInCurrentCase.activeUser && loaded" bottom>
            <template #activator="{ on }">
                <div class="active-users-container-border" v-on="on">
                    <CommonAvatar
                        :key="usersInCurrentCase.activeUser.userId"
                        :userId="usersInCurrentCase.activeUser.userId"
                        :size="32"
                    />
                </div>
            </template>
            <span>{{ activeUserDisplayName }} {{ $t('activeUsers.working') }}</span>
        </v-tooltip>

        <div class="extra-users">
            <v-tooltip v-for="user in usersInCurrentCase.otherUsers" :key="user.userId" bottom>
                <template #activator="{ on }">
                    <div class="active-users-container-border-white" v-on="on">
                        <CommonAvatar :key="user.userId" :userId="user.userId" :size="32" />
                    </div>
                </template>
                <span>{{ user.userName }} {{ $t('activeUsers.watching') }}</span>
            </v-tooltip>
        </div>

        <div class="extra-users">
            <v-tooltip v-if="showExtraUsers" bottom>
                <template #activator="{ on }">
                    <div class="active-users-container-border-white" v-on="on">
                        <v-avatar :size="32" color="var(--v-gray3-base)">
                            <span class="active-users-avatar-text">
                                +{{ usersInCurrentCase.otherUsers.length - 2 }}
                            </span>
                        </v-avatar>
                    </div>
                </template>
                <span>{{ usersInCurrentCase.otherUsers.length - 2 }} {{ $t('activeUsers.extra') }}</span>
            </v-tooltip>
        </div>
    </section>
</template>
<script>
    import { mapState, mapGetters } from 'vuex';
    import CaseActivity from './CaseActivity.vue';

    export default {
        components: {
            CommonAvatar: () => import('@/components/Global/CommonAvatar.vue'),
            CaseActivity,
        },

        data() {
            return {
                loaded: false,
            };
        },
        sockets: {
            wholeList() {
                this.loaded = true;
            },
        },

        computed: {
            ...mapState({
                busyCaseList: (state) => state.Cases.busyCaseList,
                caseToLoad: (state) => state.Cases.caseToLoad,
                selectedCase: (state) => state.Cases.selectedCase,
                userObject: (state) => state.Auth.userObject,
            }),
            ...mapGetters('Cases', ['usersInCurrentCase']),

            activeUserDisplayName() {
                if (this.usersInCurrentCase.activeUser.userId === this.userObject.userId) {
                    return this.$t('activeUsers.you');
                }

                return this.usersInCurrentCase.activeUser ? this.usersInCurrentCase.activeUser.userName : '';
            },

            showExtraUsers() {
                return this.usersInCurrentCase.otherUsers.length > 2;
            },
        },
    };
</script>
<style scoped>
    .active-users-container-border {
        border: 2px solid var(--v-primary-base);
        border-radius: 50%;
        padding: 2px;
        margin-right: 2px;
        margin-left: 2px;
    }

    .active-users-avatar-text {
        user-select: none;
    }
    .active-users-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    .extra-users {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 4px;
    }
</style>
<i18n lang="json">
{
    "en": {
        "activeUsers": {
            "watching": " is watching",
            "extra": " more",
            "working": " is working in the case",
            "you": "You"
        }
    },
    "sv": {
        "activeUsers": {
            "watching": " tittar på",
            "extra": " fler",
            "working": " arbetar i ärendet",
            "you": "Du"
        }
    }
}
</i18n>
