import axios from 'axios';

function getPromptsByContext(context) {
    return axios.get('/integrations/chatgpt/prompts', {
        params: {
            context,
        },
    });
}

/**
 * Fetches conversations from the smart search API.
 *
 * @param {number} offset - The starting point for fetching conversations.
 * @param {number} limit - The maximum number of conversations to fetch.
 * @param {string | undefined} search - The search query to filter conversations.
 * @returns {Promise} - A promise that resolves to the response of the API call.
 */
function getConversations(offset, limit, _search) {
    return axios.get('/integrations/chatgpt/smart-search/conversations', {
        params: {
            offset,
            limit,
        },
    });
}
/**
 * Creates a new conversation by sending a POST request to the specified endpoint.
 *
 * @param {Object} data - The data to be sent in the request body.
 * @returns {Promise} - A promise that resolves to the response of the POST request.
 */
function createConversation(data) {
    return axios.post('/integrations/chatgpt/smart-search/conversation', data);
}

/**
 * Fetches a conversation by its ID from the smart search integration.
 *
 * @param {string} id - The unique identifier of the conversation.
 * @returns {Promise} - A promise that resolves to the response of the GET request.
 */
function getConversation(id) {
    return axios.get(`/integrations/chatgpt/smart-search/conversation/${id}`);
}

/**
 * Asynchronously saves a conversation item by sending a POST request to the specified endpoint.
 * The function yields chunks of the response stream as they are received.
 *
 * @async
 * @generator
 * @function saveConversationItem
 * @param {string} id - The ID of the conversation.
 * @param {string} text - The text content to be saved in the conversation.
 * @yields {Uint8Array} - Chunks of the response stream.
 * @throws {Error} - Throws an error if the request fails.
 */
async function* saveConversationItem(id, text, promptId) {
    try {
        const { data: stream } = await axios.post(
            `/integrations/chatgpt/smart-search/conversation/${id}`,
            { text, promptId },
            {
                responseType: 'stream',
                adapter: 'fetch',
            },
        );

        const reader = stream.pipeThrough(new TextDecoderStream()).getReader();
        // biome-ignore lint/suspicious/noAssignInExpressions: <Subjective: I prefer the code to be short>
        for (let chunk; !(chunk = await reader.read()).done; ) {
            yield chunk.value;
        }
    } catch (error) {
        console.error('Error during request:', error);
        return error;
    }
}

export default {
    getConversations,
    createConversation,

    getConversation,
    saveConversationItem,

    getPromptsByContext,
};
