<template>
    <div style="display: none">
        <v-container>
            <v-row>
                <v-col>
                    <v-card class="pa-3" elevation="1">Content</v-card>
                </v-col>
                <v-col>
                    <v-card class="pa-3" elevation="1">
                        <v-card-text>
                            <v-sheet>
                                <v-sparkline
                                    :value="value"
                                    color="primary"
                                    height="100"
                                    padding="24"
                                    stroke-linecap="round"
                                    smooth
                                >
                                    <template #label="item">
                                        {{ item.value }}
                                    </template>
                                </v-sparkline>
                            </v-sheet>
                        </v-card-text>
                        <v-card-text>
                            <div class="text-h4 font-weight-thin">
                                <!-- Ärenden 24h -->
                                {{ $t('start.cases') }}
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card min-height="260px" color="primary" class="pa-3" elevation="1">
                        <v-card-text color="white">
                            <!-- Välkommen {{ loggedInUser.userName }} -->
                            {{ $t('start.welcome') }} {{ loggedInUser.userName }}
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card min-height="260px" class="pa-3" elevation="1">Content</v-card>
                </v-col>
                <v-col>
                    <v-card min-height="260px" class="pa-3" elevation="1" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        name: 'Start',
        data: () => ({
            value: [423, 446, 675, 510, 590, 610, 760],
        }),
        computed: {
            ...mapState({
                loggedInUser: (state) => state.Auth.userObject,
            }),
        },
        created() {
            this.$router.push('/cases');
        },
    };
</script>
<i18n lang="json">
{
    "en": {
        "start": {
            "cases": "Cases 24h",
            "welcome": "Welcome"
        }
    },
    "sv": {
        "start": {
            "cases": "Ärenden 24h",
            "welcome": "Välkommen"
        }
    }
}
</i18n>
