import axios from 'axios';

function getAttachmentFile(id) {
    try {
        return axios.get(`/attachment/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
    } catch (error) {
        console.error(error);
        return error;
    }
}

function getAttachmentFileByContentId(contentId) {
    try {
        return axios.get(`/attachment/content/${contentId}`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
    } catch (error) {
        console.error(error);
        return error;
    }
}

function uploadAttachments(attachments) {
    return axios.post('/attachments', attachments, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });
}

export default {
    getAttachmentFile,
    getAttachmentFileByContentId,
    uploadAttachments,
};
