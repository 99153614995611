module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "calls": {
          "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
          "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call"])},
          "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
          "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
          "dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialog"])},
          "noSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your browser does not support the audio element."])},
          "recordingStartedAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Recording started at: ", _interpolate(_named("created"))])},
          "inbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbound call"])},
          "outbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outbound call"])}
        }
      },
      "sv": {
        "calls": {
          "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktioner"])},
          "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonsamtal"])},
          "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spela upp"])},
          "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summering"])},
          "dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialog"])},
          "noSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din webbläsare stödjer inte ljudfiler."])},
          "recordingStartedAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Inspelning startade: ", _interpolate(_named("created"))])},
          "inbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkommande samtal"])},
          "outbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utgående samtal"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"calls":{"actions":"Actions","call":"Call","play":"Play","summary":"Summary","dialog":"Dialog","noSupport":"Your browser does not support the audio element.","recordingStartedAt":"Recording started at: {created}","inbound":"Inbound call","outbound":"Outbound call"}},"sv":{"calls":{"actions":"Funktioner","call":"Telefonsamtal","play":"Spela upp","summary":"Summering","dialog":"Dialog","noSupport":"Din webbläsare stödjer inte ljudfiler.","recordingStartedAt":"Inspelning startade: {created}","inbound":"Inkommande samtal","outbound":"Utgående samtal"}}}')
  delete Component.options._Ctor
  
}
