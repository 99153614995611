<template>
    <div ref="content-ref">
        <slot></slot>
    </div>
</template>
<script>
    // A Vue component.
    export default {
        props: {
            boundryContainer: {
                type: String,
            },

            elementsToObserve: {
                type: Array,
                default: () => [{ domIdentifierMethod: 'getElementsByTagName', identifier: 'img' }],
            },
            onIntersect: {
                type: Function,
                default: null,
            },
        },
        data() {
            return {
                observers: [],
            };
        },
        mounted() {
            for (const elementToObserve of this.elementsToObserve) {
                this.addObservers(elementToObserve);
            }
        },
        beforeDestroy() {
            if (!this.observers.length) return;

            for (const observer of this.observers) {
                observer.disconnect();
            }
        },
        methods: {
            addObservers(elementToObserve) {
                // if we dont supply a contentContainerId, we use the element as the root
                // this enables complete wrapping of the content
                const contentContainer = this.$refs['content-ref'];
                const boundryContainer = this.boundryContainer
                    ? document.getElementById(this.boundryContainer)
                    : contentContainer;

                // get all elements
                const elements = contentContainer[elementToObserve.domIdentifierMethod](elementToObserve.identifier);

                const options = {
                    root: boundryContainer,
                    rootMargin: '0px',
                    threshold: 0.1,
                };

                const observer = this.createObserver(options, elementToObserve.identifier);

                // loop through each image
                for (const el of elements) {
                    if (elementToObserve.identifier === 'img') {
                        this.imgFadeSetup(el);

                        // this is very important
                        // ensures that the images have a min height and width, so they dont overlap
                        // this caused a bug where all images would load at the same time
                        el.style.minHeight = '10px';
                        el.style.minWidth = '10px';

                        // onload, fade in the image
                        el.addEventListener('load', () => {
                            el.style.opacity = 1;
                        });
                    }

                    // add intersector observer
                    observer.observe(el);
                }

                this.observers.push(observer);
            },
            createObserver(options, elementIdentifier) {
                return new IntersectionObserver(async (entries, observer) => {
                    for (const entry of entries) {
                        if (!entry.isIntersecting) continue;

                        const { target } = entry;

                        // if we have a custom function, use that
                        if (this.onIntersect) {
                            this.onIntersect(entry, observer);
                        } else {
                            this.defaultFunctionByIdentifier(target, elementIdentifier);
                        }
                        // unobserve
                        observer.unobserve(target);
                    }
                }, options);
            },
            async defaultFunctionByIdentifier(target, elementIdentifier) {
                switch (elementIdentifier) {
                    case 'img': {
                        // * get the image data-source, this is data-src so it doesnt trigger the image loading
                        const imgData = await this.getImageDataFromServer(target.dataset.src);

                        // * if we didnt get any data, return
                        if (!imgData) return;

                        // * set the src to the image data
                        target.setAttribute('src', this.getSrcUrl(imgData));

                        break;
                    }
                    default: {
                        break;
                    }
                }
            },
            async getImageDataFromServer(src) {
                if (!src || !src.includes('cid:')) {
                    return null;
                }

                const contentId = src.split('cid:').pop();

                // get the image data from the server
                const result = await this.$store.dispatch('Files/getAttachmentFileByContentId', contentId);

                return result || null;
            },
            getSrcUrl(imgData) {
                let srcUrl = require('@/assets/General/file_not_found.png');

                if (imgData) {
                    // get the image data and content type
                    const { data, contentType } = imgData;
                    // create the image url
                    srcUrl = `data:${contentType};base64,${data}`;
                }

                return srcUrl;
            },
            imgFadeSetup(el) {
                // set opacity to 0
                el.style.opacity = 0;
                el.style.transform = 'translate-y(-20px)';
                el.style.transition = 'all 0.5s ease-in-out';
            },
        },
    };
</script>
<style scoped lang="scss"></style>
