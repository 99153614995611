import admin from '@/api/admin/admin';
import i18n from '../../i18n';

// State object
const state = {
    adminList: null,
    sidebarContent: null,
    sidebarContentDefault: [],
    templates: [],
    signatureTemplates: [],
    selectedSignatureTemplate: {},
    globalSignature: null,
    templateCategories: [],
    snoozeOptions: [],
    chatTemplates: [],
};
// Getter functions
const getters = {
    getTemplateList(state) {
        return state.templates;
    },
};
// Actions
const actions = {
    // CONFIG

    getIntegrationConfig() {
        return new Promise((resolve, reject) => {
            admin
                .getIntegrationConfig()
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    saveIntegrationConfig(_, payload) {
        return new Promise((resolve, reject) => {
            admin
                .saveIntegrationConfig(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    getSpecificIntegrationTopic(_, payload) {
        return new Promise((resolve, reject) => {
            admin
                .getSpecificIntegrationTopic(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    getAllTimeAlternatives() {
        return new Promise((resolve, reject) => {
            admin
                .getAllTimeAlternatives()
                .then((res) => {
                    resolve(res.data);
                    if (res.status === 200) {
                        resolve(res.data);
                    } else {
                        resolve([]);
                    }
                })
                .catch((error) => {
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    getSnoozeOptions({ commit }, payload) {
        return new Promise((resolve, reject) => {
            admin
                .getSnoozeOptions(payload)
                .then((res) => {
                    if (res.status === 200) {
                        commit('SET_SNOOZE_OPTIONS', res.data);
                        resolve(res.data);
                    } else {
                        resolve([]);
                    }
                })
                .catch((error) => {
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    getSettingsSnoozes(_, payload) {
        return new Promise((resolve, reject) => {
            admin
                .getSettingsSnoozes(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    addSLASnooze(_, payload) {
        return new Promise((resolve, reject) => {
            admin
                .addSLASnooze(payload)
                .then((res) => {
                    resolve(res.data);
                    this._vm.$toasted.show(i18n.t('adminStore.snoozeAdded'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('adminStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    deleteSLASnooze(_, payload) {
        return new Promise((resolve, reject) => {
            admin
                .deleteSLASnooze(payload)
                .then((res) => {
                    resolve(res.data);
                    this._vm.$toasted.show(i18n.t('adminStore.snoozeDeleted'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('adminStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },

    getGlobalSignature({ commit, state }) {
        if (state.globalSignature) {
            return state.globalSignature;
        }
        return new Promise((resolve, reject) => {
            admin
                .getGlobalSignature()
                .then((res) => {
                    commit('SET_GLOBAL_SIGNATURE', res.data || null);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('adminStore.getGlobalSigantureErr'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    setGlobalSignature({ commit }, id) {
        return new Promise((resolve, reject) => {
            admin
                .setGlobalSignature(id)
                .then((res) => {
                    commit('SET_GLOBAL_SIGNATURE', res.data || null);
                    resolve(res.data);
                    this._vm.$toasted.show(i18n.t('adminStore.newGlobalSignatureSet'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('adminStore.newGlobalSignatureErr'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    removeGlobalSignature({ commit }) {
        return new Promise((resolve, reject) => {
            admin
                .removeGlobalSignature()
                .then((res) => {
                    if (res.status !== 200) {
                        throw { message: i18n.t('adminStore.wrong') };
                    }
                    commit('SET_GLOBAL_SIGNATURE', null);
                    resolve(res.data);
                    this._vm.$toasted.show(i18n.t('adminStore.globalSignatureRemoved'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('adminStore.globalSignatureRemoveErr'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    getAdminList({ commit }) {
        return new Promise((resolve, reject) => {
            admin
                .getAdminList()
                .then((res) => {
                    if (res.status == 200) {
                        commit('SET_ADMIN_LIST', res.data);
                        resolve(res.data);
                    } else {
                        reject();
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getSidebarContent({ commit }) {
        return new Promise((resolve, reject) => {
            admin
                .getSidebarContent()
                .then((res) => {
                    if (res.status == 200) {
                        commit('SET_SIDEBAR_CONTENT', res.data);
                        commit('SET_SIDEBAR_CONTENT_DEFAULT', res.data);
                        resolve(res.data);
                    } else {
                        reject();
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    //= ===================================================//
    //                  TEMPLATE ACTIONS                  //
    //= ===================================================//

    getTemplates({ commit }, id) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await admin.getTemplates(id);
                if (id === 3) {
                    commit('SET_SIGNATURE_TEMPLATES', res.data);
                    resolve(res.data);
                } else {
                    commit('SET_TEMPLATES', res.data);
                    resolve(res.data);
                }
            } catch (error) {
                reject(error);
            }
        });
    },

    getTemplatesByIdentifier(_, query) {
        return new Promise((resolve, reject) => {
            admin
                .getTemplatesByIdentifier(query)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    addOrChangeTemplate({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            admin
                .addOrChangeTemplate(payload)
                .then(async (res) => {
                    await dispatch('getTemplates');
                    resolve(res.data);
                    this._vm.$toasted.show('Sparat', {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },
    saveChatMessage(_, payload) {
        return new Promise((resolve, reject) => {
            admin
                .saveChatMessage(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getChatMessages(_, payload) {
        return new Promise((resolve, reject) => {
            admin
                .getChatMessages(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    deleteTemplate({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            admin
                .deleteTemplate(payload.ID)
                .then((res) => {
                    dispatch('getTemplates');
                    resolve(res.data);
                    this._vm.$toasted.show(i18n.t('adminStore.templateDeleted'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                })
                .catch((error) => {
                    reject(error);
                    this._vm.$toasted.show(i18n.t('adminStore.ErrTemplateDeleted'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                });
        });
    },

    async getChatTemplates({ commit }, filters) {
        try {
            const res = await admin.getChatTemplates(filters);
            commit('SET_CHAT_TEMPLATES', res.data);
            return res.data;
        } catch (error) {
            console.error('error', error);
            return null;
        }
    },

    //= ===================================================//
    //                            ROLES                   //
    //= ===================================================//

    setAdminList({ commit }, payload) {
        commit('SET_ADMIN_LIST', payload);
    },
    setSidebarContent({ commit }, payload) {
        commit('SET_SIDEBAR_CONTENT', payload);
    },
    toggleActiveAdminList({ commit }, index) {
        commit('TOGGLE_ACTIVE_ADMIN_LIST', index);
    },
};

// Mutations
const mutations = {
    SET_TEMPLATES(state, data) {
        state.templates = data;
        state.signatureTemplates = data.filter((template) => template.CategoryId === 3);
    },
    SET_GLOBAL_SIGNATURE(state, data) {
        state.globalSignature = data;
    },
    SET_SIGNATURE_TEMPLATES(state, data) {
        state.signatureTemplates = data;
    },
    SET_SIDEBAR_CONTENT(state, data) {
        state.sidebarContent = data;
    },
    SET_SIDEBAR_CONTENT_DEFAULT(state, data) {
        state.sidebarContentDefault = data;
    },
    SET_ADMIN_LIST(state, data) {
        state.adminList = data;
    },

    TOGGLE_ACTIVE_ADMIN_LIST(state, index) {
        state.adminList[index].active = !state.adminList[index].active;
    },
    SET_SNOOZE_OPTIONS(state, data) {
        state.snoozeOptions = data;
    },
    SET_CHAT_TEMPLATES(state, templates) {
        state.chatTemplates = templates;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
