<template>
    <main ref="group" class="d-flex" :class="getPositionClass(position)" :cy-caseQueue="item.queue || 'none'">
        <div v-if="item.queue">
            <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                    <v-chip
                        v-bind="attrs"
                        class="rounded pl-3 mr-1 nohover"
                        :color="item.UnreadMessages > 0 ? 'gray3' : 'gray5'"
                        text-color="gray2"
                        dense
                        outlined
                        :style="{
                            width: itemState == false ? '40px' : 'auto',
                            fontSize: isBig ? '12px' : '12px',
                        }"
                        v-on="on"
                    >
                        <v-icon :size="isBig ? 15 : 12" class="mr-2">
                            {{ item.queueIcon || 'mdi-account-multiple' }}
                        </v-icon>
                        <span class="text-truncate">{{ item.queue }}</span>
                    </v-chip>
                </template>
                <span>{{ item.queue }}</span>
            </v-tooltip>
        </div>
        <div v-else ref="group">
            <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                    <v-chip
                        v-bind="attrs"
                        class="rounded pl-3 mr-1 nohover"
                        dense
                        :color="item.UnreadMessages > 0 ? 'gray3' : 'gray5'"
                        text-color="white"
                        outlined
                        :style="{
                            width: itemState == false ? '40px' : 'auto',
                            fontSize: isBig ? '12px' : '12px',
                        }"
                        v-on="on"
                    >
                        <v-icon :size="isBig ? 15 : 12" class="mr-2" :style="{ color: 'var(--v-gray2-base' }">
                            {{ item.queueIcon || 'mdi-account-multiple' }}
                        </v-icon>
                        <span class="text-truncate" :style="{ color: 'var(--v-gray2-base' }">
                            {{ $t('queueChip.noQueue') }}
                        </span>
                    </v-chip>
                </template>
                <!-- <span>Ingen kö</span> -->
                <span>{{ $t('queueChip.noQueue') }}</span>
            </v-tooltip>
        </div>
    </main>
</template>

<script>
    import { useEventListener } from '@vueuse/core';
    import { positionTypes } from '@/enums/cases.enums';
    import { getPositionClass } from '@/helpers/cases/cases.helper';

    export default {
        props: {
            item: {
                type: Object,
                default: () => ({}),
            },
            selectedCaseId: {
                type: String,
                default: '',
            },
            small: {
                type: Boolean,
                default: false,
            },
            position: {
                type: String,
                default: positionTypes.LEFT,
                validator(value) {
                    // Shorthand for validator
                    return Object.values(positionTypes).includes(value);
                },
            },
        },
        data() {
            return {
                height: 0,
                width: 0,
                itemState: true,
            };
        },

        computed: {
            isBig() {
                if (this.small) {
                    return false;
                }
                return this.listLayout.value == 'big';
            },

            listLayout() {
                return this.$store.state.Cases.listLayout;
            },
        },

        created() {
            useEventListener(window, 'resize', this.resizeHandler);
        },

        mounted() {
            this.height = window.innerHeight;
            this.width = window.innerWidth;
        },

        methods: {
            getPositionClass,
            resizeHandler(e) {
                this.height = window.innerHeight;
                this.width = window.innerWidth;
            },
        },
    };
</script>

<style scoped>
    .nohover::before {
        display: none;
    }
</style>
<i18n lang="json">
{
    "en": {
        "queueChip": {
            "noQueue": "No queue"
        }
    },
    "sv": {
        "queueChip": {
            "noQueue": "Ingen kö"
        }
    }
}
</i18n>
