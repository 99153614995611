module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "chat": {
          "unreadMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unread messages"])},
          "chatClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The chat is closed"])},
          "chatClosedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The chat is closed by"])},
          "sureClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to close the chat?"])},
          "caseInQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The case is in the queue waiting to be picked up by the automatic assignment"])},
          "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["client"])},
          "continueTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will continue to: "])},
          "leaveSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to leave the site?"])}
        }
      },
      "sv": {
        "chat": {
          "unreadMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nya meddelanden"])},
          "chatClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chatten är nu stängd"])},
          "chatClosedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chatten är avslutad av"])},
          "sureClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill stänga chatten?"])},
          "caseInQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ärendet är i kön och väntar på att plockas upp av automatisk tilldelning"])},
          "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klient"])},
          "continueTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kommer att fortsätta till: "])},
          "leaveSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vill du lämna sidan?"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"chat":{"unreadMessages":"Unread messages","chatClosed":"The chat is closed","chatClosedBy":"The chat is closed by","sureClose":"Are you sure you want to close the chat?","caseInQueue":"The case is in the queue waiting to be picked up by the automatic assignment","client":"client","continueTo":"You will continue to: ","leaveSite":"Do you want to leave the site?"}},"sv":{"chat":{"unreadMessages":"Nya meddelanden","chatClosed":"Chatten är nu stängd","chatClosedBy":"Chatten är avslutad av","sureClose":"Är du säker på att du vill stänga chatten?","caseInQueue":"Ärendet är i kön och väntar på att plockas upp av automatisk tilldelning","client":"klient","continueTo":"Du kommer att fortsätta till: ","leaveSite":"Vill du lämna sidan?"}}}')
  delete Component.options._Ctor
  
}
