module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "goToCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to case"])},
        "subCaseCreatedFromCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case created from case"])},
        "createdNewSubCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created new subcase"])},
        "integrationTopicParentComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subcase created from case topic"])},
        "integrationTopicChildComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created from case topic"])}
      },
      "sv": {
        "goToCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till ärende"])},
        "subCaseCreatedFromCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapat från ärende"])},
        "createdNewSubCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidarebefordrat som nytt underärende"])},
        "integrationTopicParentComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underärende skapat från ämnesärende"])},
        "integrationTopicChildComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapat från ärendes ämnesrubrik"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"goToCase":"Go to case","subCaseCreatedFromCase":"Case created from case","createdNewSubCase":"Created new subcase","integrationTopicParentComment":"Subcase created from case topic","integrationTopicChildComment":"Created from case topic"},"sv":{"goToCase":"Gå till ärende","subCaseCreatedFromCase":"Skapat från ärende","createdNewSubCase":"Vidarebefordrat som nytt underärende","integrationTopicParentComment":"Underärende skapat från ämnesärende","integrationTopicChildComment":"Skapat från ärendes ämnesrubrik"}}')
  delete Component.options._Ctor
  
}
