<template>
    <section class="bank-id-container">
        <v-tooltip top>
            <template #activator="{ on, attrs }">
                <div>
                    <secondary-action-button
                        :disabled="!isActive"
                        :dense="true"
                        v-bind="attrs"
                        v-on="on"
                        @click="isActive ? (dialog = true) : ''"
                    >
                        <div class="bank-id-button-inner-container">
                            <img
                                :width="btnSize * 0.9"
                                :src="require('@/assets/General/BankID_logo.svg')"
                                :style="bankIdStyle"
                            />
                            <v-icon v-if="isAuthenticated" size="24" color="var(--v-success-base)">
                                mdi-account-check-outline
                            </v-icon>
                        </div>
                    </secondary-action-button>
                </div>
            </template>
            {{ $t('bankIdButton.bankID') }}
        </v-tooltip>
        <v-dialog v-model="dialog" width="50%" max-width="500px">
            <v-card>
                <v-card-text v-if="!data" class="d-flex flex-column align-center">
                    <div>
                        <v-img width="300" src="@/assets/General/BankID_logo.svg" />
                    </div>
                    <div v-if="!loading" class="d-flex">
                        <v-text-field
                            v-model="pnr"
                            :disabled="loading"
                            :rules="ssnRules"
                            :label="$t('bankIdButton.ssn')"
                            placeholder="YYYYMMDDXXXX"
                            dense
                            @keyup.enter="() => isValidSwedishSSN(pnr) && bankIdAuthenticate()"
                        >
                            <template #append>
                                <v-tooltip top>
                                    <template #activator="{ on, attrs }">
                                        <v-btn
                                            :disabled="!isValidSwedishSSN(pnr)"
                                            :color="bankIdColor"
                                            v-bind="attrs"
                                            icon
                                            v-on="on"
                                            @click="bankIdAuthenticate"
                                        >
                                            <v-icon>mdi-account-check-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    {{ $t('bankIdButton.verify') }}
                                </v-tooltip>
                            </template>
                        </v-text-field>
                    </div>
                    <div v-else>
                        <v-progress-linear indeterminate :color="bankIdColor" />
                        <div class="text-caption">{{ $t('bankIdButton.verCustomer') }}</div>
                    </div>
                </v-card-text>
                <v-card-text v-else class="text-center">
                    <v-icon size="200" :color="bankIdColor">mdi-check-circle-outline</v-icon>
                    <v-sheet class="text-h5">{{ $t('bankIdButton.verSuccess') }}</v-sheet>
                    <div>{{ $t('global.name') }}: {{ data.user.name }}</div>
                    <div class="text-caption">{{ $t('bankIdButton.ssn') }}: {{ data.user.personalNumber }}</div>
                    <div class="text-caption">{{ $t('global.time') }}: {{ data.dateTime }}</div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </section>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { isValidSwedishSSN } from '@/utils/SocialSecurityValidationSwedish';

    export default {
        props: {
            ssn: {
                type: String,
                default: '',
            },
            caseId: {
                type: String,
                default: '',
            },
            clientId: {
                type: String,
                default: '',
            },
            btnSize: {
                type: String,
                default: '50',
            },
        },
        data() {
            return {
                pnr: '',
                bankIdColor: '#283d4d',
                dialog: false,
                loading: false,
                data: null,
                isActive: true,
                ssnRules: [(v) => isValidSwedishSSN(v) || this.$t('bankIdButton.ssnNotValid')],
                isAuthenticated: false,
            };
        },

        computed: {
            bankIdStyle() {
                return {
                    filter: this.isActive ? 'none' : 'grayscale(100%)',
                    opacity: this.isActive ? '1' : '0.5',
                };
            },
        },

        watch: {
            ssn: {
                immediate: true,
                handler(val) {
                    this.pnr = val;
                },
            },
        },

        created() {
            this.checkActiveState();
        },

        methods: {
            ...mapActions('Integrations', {
                storeIsBankIdActive: 'isBankIdActive',
                storeBankIdAuthenticate: 'bankIdAuthenticate',
            }),

            isValidSwedishSSN,

            async checkActiveState() {
                this.isActive = await this.storeIsBankIdActive();
            },

            async bankIdAuthenticate() {
                if (!isValidSwedishSSN(this.pnr)) {
                    this.$toasted.show(this.$t('bankIdButton.ssnNotValid'), { type: 'error' });
                    return;
                }

                this.loading = true;

                try {
                    const result = await this.storeBankIdAuthenticate({
                        ssn: this.pnr,
                        caseId: this.caseId,
                        clientId: this.clientId,
                    });
                    this.data = result;

                    this.isAuthenticated = true;
                } catch (error) {
                    // more vague error message, cant show the error message from bankid due to axiosClient middleware currently modifies the error message
                    this.$toasted.show(this.$t('bankIdButton.somethingWentWrong'), {
                        type: 'error',
                    });
                } finally {
                    this.loading = false;
                }
            },
        },
    };
</script>

<style scoped>
    .bank-id-container {
        display: flex;
        flex: 1;
        align-items: stretch;
    }

    .bank-id-button-inner-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 4px;
    }
</style>
<i18n lang="json">
{
    "en": {
        "bankIdButton": {
            "bankID": "BankID",
            "verCustomer": "Waiting for confirmation...",
            "verSuccess": "Customer authentication succeeded!",
            "ssn": "Social security number",
            "ssnNotValid": "Social security number not valid",
            "errMess": "Something went wrong. BankID response",
            "somethingWentWrong": "Something went wrong, try again in a few minutes!",
            "verify": "Verify"
        }
    },
    "sv": {
        "bankIdButton": {
            "bankID": "BankID",
            "verCustomer": "Väntar på bekräftelse...",
            "verSuccess": "Kundautentisering lyckades!",
            "ssn": "Personnummer",
            "ssnNotValid": "Personnumret är inte giltigt",
            "errMess": "Något gick fel, BankID svar",
            "somethingWentWrong": "Något gick fel, testa igen om några minuter!",
            "verify": "Verifiera"
        }
    }
}
</i18n>
