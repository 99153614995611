import axios from 'axios';

function getRecordings(clientId) {
    return axios.get('recordinglist/' + clientId);
}
function getSpecificRecording(sessionId) {
    return axios.get('recordings/' + sessionId);
}
export default {
    getRecordings,
    getSpecificRecording,
};
