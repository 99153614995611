var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column py-2 justify-center align-center button-wrapper"},[_c('BuilderButton',{key:-2,attrs:{"item":{ id: -2, name: 'Back', icon: 'mdi-chevron-left', iconSelected: 'mdi-chevron-left', active: false }}}),_c('div',{staticClass:"main-buttons"},_vm._l((_vm.selectedMenuItems),function(item,index){return _c('BuilderButton',{key:index,attrs:{"item":item}})}),1),_c('BuilderButton',{key:-4,attrs:{"item":{
            id: -4,
            name: 'Settings',
            icon: 'mdi-cog',
            iconSelected: 'mdi-cog',
            active: _vm.widgetSettingsDialog,
        }}}),_c('BuilderButton',{key:-3,attrs:{"item":{
            id: -3,
            name: 'Unlink relation',
            icon: _vm.relationIdIcon,
            iconSelected: _vm.relationIdIcon,
            active: _vm.isRelationActive,
        }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }