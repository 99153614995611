module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "container": {
          "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send email"])},
          "sendSms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send SMS"])},
          "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call"])},
          "dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialog"])},
          "meeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video meeting"])},
          "chooseCallType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose call type"])}
        }
      },
      "sv": {
        "container": {
          "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka email"])},
          "sendSms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka SMS"])},
          "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ring"])},
          "dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialog"])},
          "meeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videomöte"])},
          "chooseCallType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj samtalstyp"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"container":{"sendEmail":"Send email","sendSms":"Send SMS","call":"Call","dialog":"Dialog","meeting":"Video meeting","chooseCallType":"Choose call type"}},"sv":{"container":{"sendEmail":"Skicka email","sendSms":"Skicka SMS","call":"Ring","dialog":"Dialog","meeting":"Videomöte","chooseCallType":"Välj samtalstyp"}}}')
  delete Component.options._Ctor
  
}
