import surveysApi from '@/api/surveys/surveys';
import i18n from '../../i18n';

const state = {
    surveys: [],
    questions: [],
    answeredQuestions: [],
    unmodified: true,

    idCounter: 0,

    modes: Object.freeze({
        CREATE: 'create',
        EDIT: 'edit',
        CLOSED: 'closed',
    }),
    mode: null,
    selectedSurvey: {},
    types: Object.freeze([
        { value: 'RADIO', textI18nKey: 'radio' },
        { value: 'MULTISELECT', textI18nKey: 'multiselect' },
        { value: 'RATING', textI18nKey: 'rating' },
        { value: 'INPUT', textI18nKey: 'input' },
        { value: 'TEXTAREA', textI18nKey: 'textarea' },
    ]),
    openTabs: [],

    totalQuestions: null,

    triggers: [],
};

const getters = {};

const actions = {
    openTab({ commit }, tab) {
        commit('OPEN_TAB', tab);
    },
    closeTab({ commit }, tab) {
        commit('CLOSE_TAB', tab);
    },
    openModalAndSetMode({ dispatch }, payload) {
        dispatch('setSelectedSurvey', payload.item);
        dispatch('openCreateModal', payload.mode);
    },
    setSelectedSurvey({ commit }, survey) {
        commit('SET_SELECTED_SURVEY', survey);
    },
    openCreateModal({ commit }, mode) {
        commit('SET_MODE', mode);
    },

    async getAnswersCsv(_, surveyId) {
        try {
            const response = await surveysApi.getAnswersCsv(surveyId);
            return response.data;
        } catch (error) {
            console.error('Error getting answers csv', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong') + ': ' + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
            return null;
        }
    },

    //* *****************/ Triggers *************************

    async getTriggers({ commit }, surveyId) {
        try {
            const response = await surveysApi.getTriggersBySurveyId(surveyId);
            commit('SET_TRIGGERS', response.data);
        } catch (error) {
            console.error('Error getting triggers', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong') + ': ' + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
        }
    },

    async updateTrigger({ commit }, trigger) {
        try {
            await surveysApi.updateTrigger(trigger);
            commit('UPDATE_TRIGGER', trigger);
            this._vm.$toasted.show(i18n.t('surveyStore.triggerUpdated'), {
                icon: 'mdi-alert-circle',
                type: 'success',
            });
            return true;
        } catch (error) {
            console.error('Error updating trigger', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong') + ': ' + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
            return null;
        }
    },

    async createTrigger({ dispatch }, { surveyId, trigger }) {
        try {
            await surveysApi.createTrigger(surveyId, trigger);
            await dispatch('getTriggers', surveyId);
            this._vm.$toasted.show(i18n.t('surveyStore.triggerCreated'), {
                icon: 'mdi-alert-circle',
                type: 'success',
            });
            return true;
        } catch (error) {
            console.error('Error creating trigger', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong') + ': ' + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
            return null;
        }
    },

    async deleteTrigger({ dispatch }, { surveyId, triggerId }) {
        try {
            await surveysApi.deleteTrigger(surveyId, triggerId);
            await dispatch('getTriggers', surveyId);
            this._vm.$toasted.show(i18n.t('surveyStore.triggerDeleted'), {
                icon: 'mdi-alert-circle',
                type: 'success',
            });
            return true;
        } catch (error) {
            console.error('Error deleting trigger', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong') + ': ' + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
            return null;
        }
    },

    //* *****************/ Surveys *************************

    async getSurvey({ commit }, id) {
        try {
            const response = await surveysApi.getSurveyById(id);
            commit('SET_SELECTED_SURVEY', response.data);
            commit('SET_UNMODIFED');
        } catch (error) {
            console.error('Error getting survey', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong') + ': ' + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
        }
    },

    async setSurveyActive({ commit }, { id, active }) {
        try {
            await surveysApi.setSurveyActive(id, active);
            commit('SET_SURVEY_ACTIVE', { id, active });
            this._vm.$toasted.show(i18n.t(active ? 'surveyStore.surveyActivated' : 'surveyStore.surveyInactivated'), {
                icon: 'mdi-alert-circle',
                type: 'success',
            });
        } catch (error) {
            console.error('Error setting survey active', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong') + ': ' + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
        }
    },

    async getSurveys({ commit }) {
        try {
            const response = await surveysApi.getSurveys();
            commit('SET_SURVEY', response.data);
            return response.data;
        } catch (error) {
            console.error('Error getting surveys', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong') + ': ' + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
            return error;
        }
    },
    async createSurvey({ dispatch }, newSurvey) {
        try {
            const data = await surveysApi.createSurvey(newSurvey);
            await dispatch('getSurveys', newSurvey);
            this._vm.$toasted.show(i18n.t('surveyStore.surveyCreated'), {
                icon: 'mdi-alert-circle',
                type: 'success',
            });
            return data;
        } catch (error) {
            console.error('Error creating survey', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong') + ': ' + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
            return null;
        }
    },
    async updateSurvey({ commit }, payload) {
        try {
            const result = await surveysApi.updateSurvey(payload);

            const updatedSurvey = result.data;

            commit('UPDATE_SURVEY', updatedSurvey);
            this._vm.$toasted.show(i18n.t('surveyStore.surveyUpdated'), {
                icon: 'mdi-alert-circle',
                type: 'success',
            });
        } catch (error) {
            console.error('Error updating survey', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong') + ': ' + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
        }
    },
    async deleteSurvey({ commit }, id) {
        try {
            await surveysApi.deleteSurvey(id);
            commit('DELETE_SURVEY', id);
            this._vm.$toasted.show(i18n.t('surveyStore.surveyDeleted'), {
                icon: 'mdi-alert-circle',
                type: 'success',
            });
        } catch (error) {
            console.error('Error deleting survey', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong') + ': ' + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
        }
    },

    //* *****************/ Questions *************************

    async putSurveyAndQuestions({ commit }, { surveyInfo, questions }) {
        try {
            await surveysApi.putSurveyAndQuestions(surveyInfo, questions);
            commit('SET_UNMODIFED');
            this._vm.$toasted.show(i18n.t('surveyStore.surveyUpdated'), {
                icon: 'mdi-alert-circle',
                type: 'success',
            });
        } catch (error) {
            console.error('Error putting survey and questions', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong') + ': ' + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
        }
    },

    async getAllQuestionBySurvey({ commit, state }, surveyId) {
        try {
            const response = await surveysApi.getQuestionsBySurvey(surveyId);
            const normalized = response.data.questions.map((q) => {
                commit('SET_ID_COUNTER', state.idCounter + 1);
                return {
                    ...q,
                    id: state.idCounter,
                    alternatives: q.alternatives.map((a) => a.text),
                };
            });
            commit('SET_SURVEY_QUESTIONS', normalized);
            commit('SET_UNMODIFED');
        } catch (error) {
            console.error('Error getting all questions for survey', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong') + ': ' + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
        }
    },

    createQuestion({ commit, state }, payload) {
        try {
            commit('SET_ID_COUNTER', state.idCounter + 1);
            commit('CREATE_QUESTION', { ...payload, id: state.idCounter });
        } catch (error) {
            console.error('Error creating question', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong') + ': ' + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
        }
    },
    deleteQuestion({ commit }, payload) {
        try {
            commit('DELETE_QUESTION', payload.id);
        } catch (error) {
            console.error('Error deleting question', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong') + ': ' + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
        }
    },
    updateQuestion({ commit }, payload) {
        try {
            commit('UPDATE_QUESTION', payload);
        } catch (error) {
            console.error('Error updating question', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong') + ': ' + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
        }
    },
    setSurveyQuestions({ commit }, questions) {
        try {
            commit('SET_SURVEY_QUESTIONS', questions);
        } catch (error) {
            console.error('Error setting survey questions', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong') + ':' + error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    toggleQuestionRequired({ commit }, id) {
        try {
            commit('TOGGLE_QUESTION_REQUIRED', id);
        } catch (error) {
            console.error('Error toggling question required', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong') + ':' + error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    //* *****************/ Alternatives *************************

    updateQuestionAlternatives({ commit }, { questionId, alternatives }) {
        commit('UPDATE_QUESTION_ALTERNATIVES', { questionId, alternatives });
    },

    // answers
    async getAnswersBySurvey({ commit }, surveyId) {
        try {
            const response = await surveysApi.getAnswersBySurvey(surveyId);
            commit('SET_SURVEY_ANSWERS', response.data);
        } catch (error) {
            console.error('Error getting answers for survey', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong') + ': ' + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
        }
    },

    //* *****************/ Send manual surveys *************************

    async sendPendingManualSurvey(_, commentId) {
        try {
            await surveysApi.sendPendingManualSurvey(commentId);
            this._vm.$toasted.show(i18n.t('surveyStore.surveySent'), {
                icon: 'mdi-alert-circle',
                type: 'success',
            });
        } catch (error) {
            console.error('Error trying to send pending manual survey', error);
            this._vm.$toasted.show(i18n.t('global.requestResponses.somethingWrong') + ': ' + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
        }
    },
};

const mutations = {
    OPEN_TAB(state, tab) {
        state.openTabs.push(tab);
    },
    CLOSE_TAB(state, tab) {
        state.openTabs = state.openTabs.filter((t) => t !== tab);
    },

    SET_TRIGGERS(state, triggers) {
        state.triggers = triggers;
    },
    UPDATE_TRIGGER(state, trigger) {
        state.triggers = state.triggers.map((t) => {
            if (t.id === trigger.id) {
                return trigger;
            }
            return t;
        });
    },

    //* *****************/ Surveys *************************

    SET_SELECTED_SURVEY(state, survey) {
        state.selectedSurvey = survey;
        state.unmodified = false;
    },
    SET_MODE(state, mode) {
        state.mode = mode;
    },
    SET_SURVEY(state, surveys) {
        state.surveys = surveys;
    },
    UPDATE_SURVEY(state, updSurvey) {
        state.surveys = state.surveys.map((survey) => {
            if (survey.id === updSurvey.id) {
                return updSurvey;
            }
            return survey;
        });
    },
    DELETE_SURVEY(state, id) {
        state.surveys = state.surveys.filter((survey) => survey.id !== id);
    },
    SET_SURVEY_ACTIVE(state, { id, active }) {
        state.surveys = state.surveys.map((survey) => {
            if (survey.id === id) {
                return { ...survey, active };
            }
            return survey;
        });

        if (id === state.selectedSurvey?.id) {
            state.selectedSurvey.active = active;
        }
    },

    //* *****************/ Questions *************************

    SET_UNMODIFED(state) {
        state.unmodified = true;
    },

    SET_SURVEY_QUESTIONS(state, questions) {
        state.questions = questions;
        state.unmodified = false;
    },
    CREATE_QUESTION(state, question) {
        state.questions = [...state.questions, question];
        state.unmodified = false;
    },
    DELETE_QUESTION(state, id) {
        state.questions = state.questions.filter((question) => question.id !== id);
        state.unmodified = false;
    },
    UPDATE_QUESTION(state, updQuestion) {
        state.questions = state.questions.map((question) => {
            if (question.id === updQuestion.id) {
                return updQuestion;
            }
            return question;
        });
        state.unmodified = false;
    },

    UPDATE_QUESTION_ALTERNATIVES(state, { questionId, alternatives }) {
        state.questions = state.questions.map((question) => {
            if (question.id === questionId) {
                return { ...question, alternatives };
            }
            return question;
        });
        state.unmodified = false;
    },

    TOGGLE_QUESTION_REQUIRED(state, id) {
        state.questions = state.questions.map((question) => {
            if (question.id === id) {
                return { ...question, required: !question.required };
            }
            return question;
        });
        state.unmodified = false;
    },

    //* *****************/ Answers *************************

    SET_SURVEY_ANSWERS(state, data) {
        state.answeredQuestions = data;
    },

    SET_ID_COUNTER(state, data) {
        state.idCounter = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
