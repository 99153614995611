module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "listSettingsMenu": {
          "selLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select layout"])},
          "big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Big"])},
          "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small"])}
        }
      },
      "sv": {
        "listSettingsMenu": {
          "selLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj layout"])},
          "big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stor"])},
          "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liten"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"listSettingsMenu":{"selLayout":"Select layout","big":"Big","small":"Small"}},"sv":{"listSettingsMenu":{"selLayout":"Välj layout","big":"Stor","small":"Liten"}}}')
  delete Component.options._Ctor
  
}
