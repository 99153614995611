import {
    changeOrder,
    deleteAllCollections,
    deleteSelectedCollections,
    editCollectionName,
    getAllCollectionItems,
    getCollectionItems,
    getCollectionsWithItems,
    getAllCollectionsWithItems,
    getSearchIds,
    searchCollections,
    searchInDB,
    searchItems,
    deleteCollectionItem,
    addCollectionItem,
    createCollection,
} from '@/api/favorites/favorites';

// State object
const state = {
    shouldUpdate: false,
    pinActive: false,
    contactCollections: {},
    searchResult: [],
    sortItems: localStorage.getItem('__sortItems') || 'asc',
};
// Getter functions
const getters = {};
// Actions
const actions = {
    searchInDB({ commit }, payload) {
        return new Promise((resolve, reject) => {
            searchInDB(payload)
                .then((res) => {
                    commit('SET_SEARCH_RESULT', res.data);
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    changeOrder({}, i) {
        return new Promise((resolve, reject) => {
            changeOrder(i)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    console.error('ERRR', error);
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    editCollectionName({ commit }, payload) {
        return new Promise((resolve, reject) => {
            editCollectionName(payload)
                .then((res) => {
                    commit('SET_COLLECTION_NAME', payload);
                    resolve(res);
                })
                .catch((error) => {
                    console.error('ERRR', error);
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    deleteCollectionItem({}, payload) {
        return new Promise((resolve, reject) => {
            deleteCollectionItem(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    console.error('ERRR', error);
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    addCollectionItem({}, payload) {
        return new Promise((resolve, reject) => {
            addCollectionItem(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    console.error('ERRR', error);
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    getCollectionItems({ commit }, payload) {
        return new Promise((resolve, reject) => {
            getCollectionItems(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    console.error('ERRR', error);
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    getAllCollectionItems({ commit }, payload) {
        return new Promise((resolve, reject) => {
            getAllCollectionItems(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    console.error('ERRR', error);
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    getSearchIds({}, payload) {
        return new Promise((resolve, reject) => {
            getSearchIds(payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    console.error('ERRR', error);
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    getCollectionsWithItems({ commit }, payload) {
        return new Promise((resolve, reject) => {
            getCollectionsWithItems(payload)
                .then((res) => {
                    if (res.status === 200) {
                        commit('ADD_NEW_COLLECTIONS', res.data);
                        resolve(res);
                    }
                })
                .catch((error) => {
                    console.error('ERRR', error);
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    getAllCollectionsWithItems({ commit }, payload) {
        return new Promise((resolve, reject) => {
            getAllCollectionsWithItems(payload)
                .then((res) => {
                    if (res.status === 200) {
                        commit('ADD_NEW_COLLECTIONS', res.data);
                        resolve(res);
                    }
                })
                .catch((error) => {
                    console.error('ERRR', error);
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    initialFetch({ commit }, payload) {
        return new Promise((resolve, reject) => {
            getCollectionsWithItems(payload)
                .then((res) => {
                    if (res.status === 200) {
                        commit('SET_CONTACT_COLLECTIONS', res.data);
                        resolve(res);
                    }
                })
                .catch((error) => {
                    console.error('ERRR', error);
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    searchCollections({ commit }, payload) {
        return new Promise((resolve, reject) => {
            searchCollections(payload)
                .then((res) => {
                    if (res.status === 200) {
                        commit('SET_COLLECTIONS_ARRAY', res.data);
                        resolve(res);
                    }
                })
                .catch((error) => {
                    console.error('ERRR', error);
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    searchItems({ commit }, payload) {
        return new Promise((resolve, reject) => {
            searchItems(payload)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    }
                })
                .catch((error) => {
                    console.error('ERRR', error);
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    deleteSelectedCollections({ commit }, selectedCollections) {
        return new Promise((resolve, reject) => {
            deleteSelectedCollections(selectedCollections)
                .then((res) => {
                    commit('DELETE_SELECTED_COLLECTIONS', selectedCollections);
                    resolve(res);
                })
                .catch((error) => {
                    console.error('ERRR', error);
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    deleteAllCollections({ commit }, id) {
        return new Promise((resolve, reject) => {
            deleteAllCollections(id)
                .then((res) => {
                    commit('DELETE_ALL_COLLECTIONS');
                    resolve(res);
                })
                .catch((error) => {
                    console.error('ERRR', error);
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    createCollection({ commit }, payload) {
        return new Promise((resolve, reject) => {
            createCollection(payload)
                .then((res) => {
                    commit('ADD_NEW_COLLECTION', res.data);
                    resolve(res);
                })
                .catch((error) => {
                    console.error('ERRR', error);
                    this._vm.$toasted.show(error.message, {
                        icon: 'mdi-cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
};

const mutations = {
    SET_ITEMS_ARRAY(state, { data, collectionId }) {
        if (data.data) {
            const collection = state.contactCollections.collections.find(
                (collection) => collection.ID === collectionId,
            );
            if (collection) {
                collection.items = data;
            }
        }
    },

    SET_COLLECTIONS_ARRAY(state, data) {
        state.contactCollections.collections = data;
    },
    SET_CONTACT_COLLECTIONS(state, data) {
        state.contactCollections = data;
    },
    ADD_NEW_COLLECTIONS(state, data) {
        const existingIds = new Set(state.contactCollections.collections.map((collection) => collection.ID));
        const filteredCollections = data.collections.filter((collection) => !existingIds.has(collection.ID));
        state.contactCollections.collections = state.contactCollections.collections.concat(filteredCollections);
        state.contactCollections.hasMore = data.hasMore;
        state.contactCollections.total = data.total;
    },

    ADD_NEW_COLLECTION(state, data) {
        state.contactCollections.collections.unshift(...data);
        state.contactCollections.total++;
        state.contactCollections.hasMore = state.contactCollections.total > state.contactCollections.collections.length;
    },

    DELETE_SELECTED_COLLECTIONS(state, data) {
        const numDeleted = data.length;
        state.contactCollections.collections = state.contactCollections.collections.filter(
            (collection) => !data.includes(collection.ID),
        );
        state.contactCollections.total -= numDeleted;
        state.contactCollections.hasMore = state.contactCollections.total > state.contactCollections.collections.length;
    },

    DELETE_ALL_COLLECTIONS(state) {
        state.contactCollections = { collections: [], hasMore: false, total: 0 };
    },

    SET_COLLECTION_NAME(state, data) {
        state.contactCollections.collections = state.contactCollections.collections.map((collection) =>
            collection.id === data.ID ? { ...collection, name: data.Name } : collection,
        );
    },
    SET_SEARCH_RESULT(state, data) {
        state.searchResult = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
