module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "integrationIframe": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, please try again later."])},
          "integrationNotActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration is not active."])},
          "integrationNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration not found."])},
          "clientNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client not found."])},
          "iframeNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iframe not found."])}
        }
      },
      "sv": {
        "integrationIframe": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Något gick fel, vänligen försök igen senare."])},
          "integrationNotActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrationen är inte aktiv."])},
          "integrationNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrationen hittades inte."])},
          "clientNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klienten hittades inte."])},
          "iframeNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iframe hittades inte."])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"integrationIframe":{"error":"Something went wrong, please try again later.","integrationNotActive":"Integration is not active.","integrationNotFound":"Integration not found.","clientNotFound":"Client not found.","iframeNotFound":"Iframe not found."}},"sv":{"integrationIframe":{"error":"Något gick fel, vänligen försök igen senare.","integrationNotActive":"Integrationen är inte aktiv.","integrationNotFound":"Integrationen hittades inte.","clientNotFound":"Klienten hittades inte.","iframeNotFound":"Iframe hittades inte."}}}')
  delete Component.options._Ctor
  
}
