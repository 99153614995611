import { nonGlobalInternationalNumberRegex, mailRegEx } from '../../utils/index';

/**
 * Checks if a given key value matches a regular expression.
 * @param {string} keyValue - The key value to check.
 * @param {RegExp} regex - The regular expression to match against.
 * @returns {boolean} - True if the key value matches the regular expression, false otherwise.
 */
export function handleKey(keyValue, regex) {
    const valueArray = keyValue.split(';');

    // Loop through each value in the string
    for (const value of valueArray) {
        const valueIsArray = value.includes(',');

        // If the value is not an array and matches the regex, return true
        if (!valueIsArray && regex.test(value)) {
            return true;
        }

        // If the value is not an array, continue to the next value
        if (!valueIsArray) continue;

        // Loop through each value in the array
        for (const n of value.split(',')) {
            // If the value matches the regex, return true
            if (regex.test(n)) {
                return true;
            }
        }
    }

    // If no values match the regex, return false
    return false;
}

export function isRingable(value) {
    const number = nonGlobalInternationalNumberRegex.test(value);
    if (number) {
        return number;
    }

    if (/^\d+$/.test(value) && value.length > 3) {
        return true;
    }

    return false;
}

export function isEmail(value) {
    return mailRegEx.test(value);
}

export function formatPhoneNumberList(phoneNumberList) {
    const numbersToChooseFrom = phoneNumberList
        .filter(Boolean)
        .flatMap((number) => number.split(',').map((n) => n.trim()))
        .filter(Boolean);

    return [...new Set(numbersToChooseFrom)];
}

export function formatExchangeNumber(number) {
    const firstChar = number[0];

    return firstChar === '0' ? '0' + number : number;
}

export default {
    handleKey,
    isRingable,
    isEmail,
};
