module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "sidebar": {
          "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
          "externalLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External links"])},
          "pinSidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin sidebar"])},
          "unpinSidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpin sidebar"])}
        }
      },
      "sv": {
        "sidebar": {
          "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga ut"])},
          "externalLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externa länkar"])},
          "pinSidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fäst sidomenyn"])},
          "unpinSidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lossa sidomenyn"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"sidebar":{"signOut":"Sign out","externalLinks":"External links","pinSidebar":"Pin sidebar","unpinSidebar":"Unpin sidebar"}},"sv":{"sidebar":{"signOut":"Logga ut","externalLinks":"Externa länkar","pinSidebar":"Fäst sidomenyn","unpinSidebar":"Lossa sidomenyn"}}}')
  delete Component.options._Ctor
  
}
