import { getDateByDay } from '@/helpers/leaderboard/leaderboard.helper';
import { layoutTypes, directionTypes, sourceTypes, sortingTypes, timePeriodTypes } from '../enums/leaderboard.enums';

export function getTimePeriods() {
    return [
        {
            textKey: 'leaderboard.today',
            value: { from: getDateByDay(timePeriodTypes.TODAY, 'from'), to: getDateByDay(timePeriodTypes.TODAY, 'to') },
            icon: 'mdi-calendar-today',
        },
        {
            textKey: 'leaderboard.yesterday',
            value: {
                from: getDateByDay(timePeriodTypes.YESTERDAY, 'from'),
                to: getDateByDay(timePeriodTypes.YESTERDAY, 'to'),
            },
            icon: 'mdi-calendar-today',
        },
        {
            textKey: 'leaderboard.thisWeek',
            value: {
                from: getDateByDay(timePeriodTypes.THIS_WEEK, 'from'),
                to: getDateByDay(timePeriodTypes.THIS_WEEK, 'to'),
            },
            icon: 'mdi-calendar-week',
        },
        {
            textKey: 'leaderboard.lastWeek',
            value: {
                from: getDateByDay(timePeriodTypes.LAST_WEEK, 'from'),
                to: getDateByDay(timePeriodTypes.LAST_WEEK, 'to'),
            },
            icon: 'mdi-calendar-week',
        },
        {
            textKey: 'leaderboard.thisMonth',
            value: {
                from: getDateByDay(timePeriodTypes.THIS_MONTH, 'from'),
                to: getDateByDay(timePeriodTypes.THIS_MONTH, 'to'),
            },
            icon: 'mdi-calendar-month',
        },
        {
            textKey: 'leaderboard.lastMonth',
            value: {
                from: getDateByDay(timePeriodTypes.LAST_MONTH, 'from'),
                to: getDateByDay(timePeriodTypes.LAST_MONTH, 'to'),
            },
            icon: 'mdi-calendar-month',
        },
        { textKey: 'leaderboard.chooseDate', value: 'chooseDate', icon: 'mdi-clipboard-text-clock' },
    ];
}

export const timePeriods = getTimePeriods();

export const layouts = [
    { textKey: 'leaderboard.standard', value: layoutTypes.STANDARD, icon: 'mdi-creation-outline' },
    { textKey: 'leaderboard.podium', value: layoutTypes.PODIUM, icon: 'mdi-podium-gold' },
    { textKey: 'leaderboard.list', value: layoutTypes.LIST, icon: 'mdi-playlist-star' },
];
export const directions = [
    { textKey: 'leaderboard.outboundCalls', value: directionTypes.OUTBOUND, icon: 'mdi-phone-outgoing' },
    { textKey: 'leaderboard.inboundCalls', value: directionTypes.INBOUND, icon: 'mdi-phone-incoming' },
];

export const sources = [
    { textKey: 'C1', value: sourceTypes.C1, icon: 'mdi-home-account' },
    { textKey: 'leaderboard.switch', value: sourceTypes.SWITCH, icon: 'mdi-phone-dial-outline' },
    { textKey: 'S2', value: sourceTypes.S2, icon: 'mdi-phone-refresh-outline' },
];

export const showTotalOptions = [
    { textKey: 'true', value: true, icon: 'mdi-check', color: 'success' },
    { textKey: 'false', value: false, icon: 'mdi-close', color: 'error' },
];

export const showProfileOptions = [
    { textKey: 'true', value: true, icon: 'mdi-check', color: 'success' },
    { textKey: 'false', value: false, icon: 'mdi-close', color: 'error' },
];

export const fieldOptions = [
    { textKey: 'leaderboard.orderValue', value: 'orderValue', icon: 'mdi-star-four-points-circle-outline' },
    { textKey: 'leaderboard.totalCalls', value: 'totalCalls', icon: 'mdi-phone' },
    { textKey: 'leaderboard.totalTalkTime', value: 'totalTalkTime', icon: 'mdi-timer' },
    { textKey: 'leaderboard.avgTalkTime', value: 'avgTalkTime', icon: 'mdi-timer' },
    { textKey: 'leaderboard.lastCall', value: 'lastCall', icon: 'mdi-phone' },
    { textKey: 'leaderboard.orders', value: 'ordersAmount', icon: 'mdi-package-variant' },
    { textKey: 'leaderboard.products', value: 'productsAmount', icon: 'mdi-archive-outline' },
    {
        textKey: 'leaderboard.orderValuePerDay',
        value: 'orderValuePerDay',
        icon: 'mdi-star-four-points-circle-outline',
    },
    { textKey: 'leaderboard.ordersPerDay', value: 'ordersPerDay', icon: 'mdi-package-variant' },
    { textKey: 'leaderboard.productsPerDay', value: 'productsPerDay', icon: 'mdi-archive-outline' },
];

export const sortingDirections = [
    { textKey: 'leaderboard.descending', value: sortingTypes.DESC, icon: 'mdi-sort-descending' },
    { textKey: 'leaderboard.ascending', value: sortingTypes.ASC, icon: 'mdi-sort-ascending' },
];

export const headerLayoutTemplate = {
    show: true,
    clarify: false,
};
