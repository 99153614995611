module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "clientCard": {
          "noEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No email found"])},
          "noTelephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No telephone found"])},
          "noContentHintPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client needs to have phone number filled in."])},
          "noContentHintEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client needs to have email filled in."])},
          "noContentHintVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client needs to have email or phone number filled in."])},
          "noContentHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing client info."])},
          "noContentHintNoCalling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calling integration not active."])},
          "noContentHintNoSms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sms integration not active."])}
        }
      },
      "sv": {
        "clientCard": {
          "noEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen e-post hittades"])},
          "noTelephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget telefonnummer hittades"])},
          "noContentHintPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klienten behöver ha telefonnummer ifyllt."])},
          "noContentHintEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klienten behöver ha e-post ifyllt."])},
          "noContentHintVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klienten behöver ha e-post eller telefonnummer ifyllt."])},
          "noContentHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saknad klientinfo."])},
          "noContentHintNoCalling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtals integration är inte aktiv."])},
          "noContentHintNoSms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sms integration är inte aktiv."])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"clientCard":{"noEmail":"No email found","noTelephone":"No telephone found","noContentHintPhone":"Client needs to have phone number filled in.","noContentHintEmail":"Client needs to have email filled in.","noContentHintVideo":"Client needs to have email or phone number filled in.","noContentHint":"Missing client info.","noContentHintNoCalling":"Calling integration not active.","noContentHintNoSms":"Sms integration not active."}},"sv":{"clientCard":{"noEmail":"Ingen e-post hittades","noTelephone":"Inget telefonnummer hittades","noContentHintPhone":"Klienten behöver ha telefonnummer ifyllt.","noContentHintEmail":"Klienten behöver ha e-post ifyllt.","noContentHintVideo":"Klienten behöver ha e-post eller telefonnummer ifyllt.","noContentHint":"Saknad klientinfo.","noContentHintNoCalling":"Samtals integration är inte aktiv.","noContentHintNoSms":"Sms integration är inte aktiv."}}}')
  delete Component.options._Ctor
  
}
