<template>
    <div>
        <div ref="casesFamily" class="subcase-list-container">
            <SimpleTooltip :tooltipText="$t('related')" top>
                <standard-select
                    useSlot
                    :dense="true"
                    single
                    left
                    :menuAttach="$refs.casesFamily"
                    :items="selectItems"
                    @change="([item]) => clicked(item.value)"
                >
                    <template #item-slot="{ item }">
                        <div class="group-item-container">
                            <div class="internal-type-and-date-container">
                                <InternalType
                                    v-if="selectedCase?.case"
                                    :item="selectedCase?.case"
                                    :small="true"
                                    :isInactive="isInactive(item)"
                                />
                                <div class="d-flex flex-column">
                                    <div class="item-class" :class="getStyleClass(item)">
                                        <span class="truncate-text">{{ item.name }}</span>
                                    </div>
                                    <div class="item-date">{{ formatDate(item.created) }}</div>
                                </div>
                            </div>
                            <v-icon size="16" :class="getIconColor(item)">{{ getIconClass(item) }}</v-icon>
                        </div>
                    </template>

                    <v-chip v-if="isOriginParent" class="family-case-select" fab elevation="0" color="primary">
                        <v-icon size="15" class="icon-branch-style">mdi-family-tree</v-icon>
                        {{ $t('other') }}
                    </v-chip>

                    <div v-if="!isOriginParent" class="family-case-select-subcase">
                        <v-icon size="15" class="icon-branch-style" color="white">mdi-family-tree</v-icon>
                        {{ $t('other') }}
                    </div>
                </standard-select>
            </SimpleTooltip>
        </div>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import { formatDateToText, getMonthByNumber } from '@/utils/DateFormatter';
    import InternalType from '@/components/Cases/List/InternalType.vue';

    export default {
        components: {
            SimpleTooltip: () => import('@/components/Global/SimpleTooltip.vue'),
            InternalType,
        },
        props: {
            selectedCase: {
                type: Object,
                required: true,
            },
        },
        computed: {
            selectItems() {
                const items = [];

                if (this.selectedCase.case.parentCase) {
                    const parentItem = {
                        name: this.selectedCase.case.parentCase.topic,
                        value: this.selectedCase.case.parentCase.caseId,
                        text: this.selectedCase.case.parentCase.topic,
                        created: this.selectedCase.case.parentCase.created,
                    };
                    items.push(parentItem);
                }
                const currentCaseItem = {
                    name: this.selectedCase.case.topic,
                    value: this.selectedCase.case.caseId,
                    text: this.selectedCase.case.topic,
                    created: this.selectedCase.case.created,
                };
                items.push(currentCaseItem);

                const subCasesItems = this.selectedCase.subCases.map((subCase) => {
                    const { caseId, topic, created } = subCase;
                    return {
                        name: topic,
                        value: caseId,
                        text: topic,
                        created,
                    };
                });

                items.push(...subCasesItems);

                return items;
            },
            isOriginParent() {
                return this.selectedCase.case.originParent.caseId === this.selectedCase.case.caseId;
            },

            getStyleClass() {
                return (item) => {
                    if (item.value === this.selectedCase.case.caseId) {
                        return 'current-item-class';
                    }
                    return 'item-class';
                };
            },
            getIconClass() {
                return (item) => {
                    if (item.value === this.selectedCase.case.parentCase?.caseId) {
                        return 'mdi-arrow-up';
                    }
                    if (item.value === this.selectedCase.case.caseId) {
                        return 'mdi-arrow-left';
                    }
                    return 'mdi-subdirectory-arrow-left';
                };
            },
            getIconColor() {
                return (item) => {
                    if (item.value === this.selectedCase.case.caseId) {
                        return 'current-icon-color';
                    }
                    return 'subcase-icon-color';
                };
            },
        },

        methods: {
            ...mapActions('Cases', ['goToCase']),
            getMonthByNumber,
            formatDateToText,
            clicked(caseId) {
                this.goToCase({ caseId });
            },
            navigateToParentCase() {
                if (this.selectedCase.case.parent) {
                    this.goToCase({ caseId: this.selectedCase.case.parent });
                } else {
                    console.log('No parent case available.');
                }
            },
            isInactive(item) {
                return item.value !== this.selectedCase.case.caseId;
            },
            formatDate(date) {
                if (!date) {
                    return '';
                }
                return this.formatDateToText(new Date(date));
            },
        },
    };
</script>
<style scoped lang="scss">
    .subcase-list-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 12px;
    }
    .family-case-select {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 12px;
        padding: 0 12px;
        border-radius: 16px;
        font-size: 12px;
        height: 32px;
    }

    .family-case-select-subcase {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        padding: 14px;
        background-color: var(--v-primary-base);
        height: 35px;
        font-size: 11px;
        border-left: 0.9px white solid;
        border-right: 0.9px white solid;
        color: white;
    }

    .group-item-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        width: 100%;
        padding: 0 8px 0 6px;
    }
    .internal-type-and-date-container {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
    }
    .type-circle {
        background-color: var(--v-gray2-lighten1);
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon-branch-style {
        color: var(--v-primary-base);
        margin-right: 10px;
    }
    .item-class {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        border-radius: 16px;
        font-size: 12px;
    }
    .truncate-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        max-width: 130px;
    }
    .item-date {
        font-size: 10px;
        color: var(--v-gray1-base);
    }
    .current-item-class {
        color: var(--v-primary-base);
    }
    .current-icon-color {
        color: var(--v-primary-base);
    }
    .subcase-icon-color {
        color: var(--v-gray2-lighten1);
    }
</style>
<i18n lang="json">
{
    "en": {
        "subcases": "Subcases",
        "other": "Other",
        "related": "Other related cases"
    },
    "sv": {
        "subcases": "Underärenden",
        "other": "Övriga",
        "related": "Andra relaterade ärenden"
    }
}
</i18n>
