import faqApi from '@/api/faq/faq';

const state = {
    faqs: [],
    createMode: false,
    collections: [],
    categories: [],
    categoryId: null,
    createCategoryMode: false,
    createCollectionMode: false,
    categoryObject: {
        mode: 'create',
        name: '',
        icon: 'mdi-help',
        collectionId: 0,
    },
    collectionObject: {
        mode: 'create',
        name: '',
        id: 0,
        isActive: true,
    },
};

const getters = {
    allFaqs: (state) => state.faqs,
};

const actions = {
    // ** Categories ** //
    setCategoryToEdit({ commit }, id) {
        commit('SET_CATEGORY_TO_EDIT', id);
    },
    saveNewCategory({ dispatch }, category) {
        if (category.mode === 'create') {
            dispatch('createCategory', category);
        }
        if (category.mode === 'edit') {
            dispatch('editCategory', category);
        }
    },
    editCategory({ commit, dispatch }, category) {
        faqApi.editCategory(category).then((response) => {
            if (response.status === 200) {
                this._vm.$toasted.show('Kategori uppdaterad', {
                    icon: 'mdi-check-circle',
                    type: 'success',
                });
                dispatch('fetchCategoriesByCollectionId', category.collectionId);
                commit('SET_CREATE_CATEGORY_MODE', false);
            } else {
                this._vm.$toasted.show(response.data.message, {
                    icon: 'mdi-alert-circle',
                    type: 'error',
                });
            }
        });
    },
    createCategory({ commit, dispatch }, category) {
        faqApi.saveNewCategory(category).then((response) => {
            if (response.status === 201) {
                dispatch('fetchCategoriesByCollectionId', category.collectionId);
                commit('SET_CREATE_CATEGORY_MODE', false);
                this._vm.$toasted.show('Kategori skapad', {
                    icon: 'mdi-check-circle',
                    type: 'success',
                });
            } else {
                this._vm.$toasted.show(response.data.message, {
                    icon: 'mdi-alert-circle',
                    type: 'error',
                });
            }
        });
    },
    deleteCategory({ commit }, id) {
        faqApi.deleteCategory(id).then((response) => {
            if (response.status === 200) {
                this._vm.$toasted.show('Kategori borttagen', {
                    icon: 'mdi-check-circle',
                    type: 'success',
                });
                commit('UPDATE_CATEGORY_ID', id);
            } else {
                this._vm.$toasted.show(response.data.message, {
                    icon: 'mdi-alert-circle',
                    type: 'error',
                });
            }
        });
    },
    setCreateCategoryMode({ commit }, mode) {
        commit('RESET_CATEGORY_TO_EDIT');
        commit('SET_CREATE_CATEGORY_MODE', mode);
    },
    async fetchCategoriesByCollectionId({ commit }, id) {
        const response = await faqApi.getCategoriesByCollectionId(id);
        commit('SET_CATEGORIES', response.data);
    },
    // **************************************************************************************

    // ** Collections ** //
    getAllFaqCollections({ commit }) {
        faqApi
            .getAllFaqCollections()
            .then((response) => {
                if (response.status !== 200) {
                    this._vm.$toasted.show(response.data.message, {
                        icon: 'mdi-alert-circle',
                        type: 'error',
                    });
                    return;
                }
                commit('setCollections', response.data);
            })
            .catch((error) => {
                if (error.status !== 200) {
                    this._vm.$toasted.show(error, {
                        icon: 'mdi-alert-circle',
                        type: 'error',
                    });
                }
            });
    },
    setCreateCollectionMode({ commit }, mode) {
        commit('SET_CREATE_COLLECTION_MODE', mode);
        commit('RESET_COLLECTION_TO_EDIT');
    },

    setCollectionToEdit({ commit }, id) {
        commit('SET_COLLECTION_TO_EDIT', id);
    },

    deleteCollection({ dispatch }, id) {
        faqApi.deleteCollection(id).then((response) => {
            if (response.status === 200) {
                this._vm.$toasted.show('FAQ borttagen', {
                    icon: 'mdi-check-circle',
                    type: 'success',
                });
                dispatch('getAllFaqCollections');
            } else {
                this._vm.$toasted.show(response.data.message, {
                    icon: 'mdi-alert-circle',
                    type: 'error',
                });
            }
        });
    },

    createCollection({ commit, dispatch }, collection) {
        faqApi.saveNewCollection(collection).then((response) => {
            if (response.status === 201) {
                dispatch('getAllFaqCollections');
                commit('SET_CREATE_COLLECTION_MODE', false);
                this._vm.$toasted.show('FAQ skapad', {
                    icon: 'mdi-check-circle',
                    type: 'success',
                });
            } else {
                this._vm.$toasted.show(response.data.message, {
                    icon: 'mdi-alert-circle',
                    type: 'error',
                });
            }
        });
    },

    editCollection({ commit, dispatch }, collection) {
        faqApi.editCollection(collection).then((response) => {
            if (response.status === 200) {
                this._vm.$toasted.show('FAQ uppdaterad', {
                    icon: 'mdi-check-circle',
                    type: 'success',
                });
                dispatch('getAllFaqCollections');
                commit('SET_CREATE_COLLECTION_MODE', false);
            } else {
                this._vm.$toasted.show(response.data.message, {
                    icon: 'mdi-alert-circle',
                    type: 'error',
                });
            }
        });
    },

    saveNewCollection({ dispatch, commit }, collection) {
        if (collection.mode === 'create') {
            dispatch('createCollection', collection);
        }
        if (collection.mode === 'edit') {
            dispatch('editCollection', collection);
        }
        commit('RESET_COLLECTION_TO_EDIT');
    },
    // **************************************************************************************

    // ** Faqs ** //
    setCreateMode({ commit }, mode) {
        commit('setCreateMode', mode);
    },
    async fetchFaqs({ commit }, id) {
        const response = await faqApi.getAllFaqs(id);
        commit('setFaqs', response.data);
        commit('SET_SELECTED_CATEGORY_ID', id);
    },
    async createFaq({ commit, dispatch, state }, faq) {
        faq.categoryId = state.categoryId;
        const response = await faqApi.createFaq(faq);
        if (response.status === 201) {
            dispatch('fetchFaqs', faq.categoryId);
            commit('setCreateMode', false);
            this._vm.$toasted.show('FAQ skapad'),
                {
                    icon: 'mdi-check-circle',
                    type: 'success',
                };
        } else {
            this._vm.$toasted.show(response.data.message, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
        }
    },
    async deleteFaq({ commit }, id) {
        const response = await faqApi.deleteFaq(id);
        if (response.status === 200) {
            this._vm.$toasted.show('FAQ borttagen', {
                icon: 'mdi-check-circle',
                type: 'success',
            });
            commit('removeFaq', id);
        } else {
            this._vm.$toasted.show(response.data.message, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
        }
    },
    async updateFaq({ commit }, faq) {
        const response = await faqApi.updateFaq(faq);
        if (response.status === 200) {
            this._vm.$toasted.show('FAQ uppdaterad', {
                icon: 'mdi-check-circle',
                type: 'success',
            });
            commit('editFaq', faq);
        } else {
            this._vm.$toasted.show(response.data.message, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
        }
    },
    // **************************************************************************************
};

const mutations = {
    RESET_CATEGORY_TO_EDIT: (state) => {
        state.categoryObject = {
            mode: 'create',
            heading: '',
            name: '',
            icon: 'mdi-help',
            collectionId: 0,
        };
    },
    SET_CATEGORY_TO_EDIT: (state, id) => {
        state.categoryObject = state.categories.find((category) => category.id === id);
        state.categoryObject.mode = 'edit';
    },
    SET_CATEGORIES: (state, categories) => (state.categories = categories),
    SET_SELECTED_CATEGORY_ID: (state, id) => (state.categoryId = id),
    SET_CREATE_CATEGORY_MODE: (state, mode) => (state.createCategoryMode = mode),

    UPDATE_CATEGORY_ID: (state, id) => {
        state.categories = state.categories.filter((category) => category.id !== id);
    },

    RESET_COLLECTION_TO_EDIT: (state) => {
        state.collectionObject = {
            mode: 'create',
            name: '',
            id: 0,
            isActive: true,
        };
    },

    SET_CREATE_COLLECTION_MODE: (state, mode) => (state.createCollectionMode = mode),
    SET_COLLECTION_TO_EDIT: (state, id) => {
        state.collectionObject = state.collections.find((collection) => collection.id === id);
        state.collectionObject.mode = 'edit';
    },
    setCollections: (state, collections) => (state.collections = collections),

    setCreateMode: (state, mode) => (state.createMode = mode),
    setFaqs(state, faqs) {
        state.faqs = faqs;
    },
    removeFaq: (state, id) => (state.faqs = state.faqs.filter((faq) => faq.id !== id)),

    editFaq: (state, updFaq) => {
        const index = state.faqs.findIndex((faq) => faq.id === updFaq.id);
        if (index !== -1) {
            state.faqs.splice(index, 1, updFaq);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
