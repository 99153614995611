module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "adminMenu": {
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
          "deletionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletion of comment"])},
          "deletionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this comment?"])},
          "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error deleting comment"])}
        }
      },
      "sv": {
        "adminMenu": {
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radera"])},
          "deletionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radera kommentar"])},
          "deletionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill radera denna kommentar?"])},
          "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fel vid radering av kommentar"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"adminMenu":{"delete":"Delete","deletionTitle":"Deletion of comment","deletionMessage":"Are you sure you want to delete this comment?","deleteError":"Error deleting comment"}},"sv":{"adminMenu":{"delete":"Radera","deletionTitle":"Radera kommentar","deletionMessage":"Är du säker på att du vill radera denna kommentar?","deleteError":"Fel vid radering av kommentar"}}}')
  delete Component.options._Ctor
  
}
