module.exports = {
    params: {
        integrationLinks: {
            active: true,
            links: [
                {
                    name: 'OsTicket',
                    color: '#3299b3',
                    link: 'osTicket',
                    icon: 'mdi-link',
                },
            ],
        },
    },
};
