module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "federatedLogin": {
          "continueWith": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Continue with ", _interpolate(_named("name"))])},
          "loggingIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logging in..."])}
        }
      },
      "sv": {
        "federatedLogin": {
          "continueWith": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fortsätt med ", _interpolate(_named("name"))])},
          "loggingIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loggar in..."])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"federatedLogin":{"continueWith":"Continue with {name}","loggingIn":"Logging in..."}},"sv":{"federatedLogin":{"continueWith":"Fortsätt med {name}","loggingIn":"Loggar in..."}}}')
  delete Component.options._Ctor
  
}
