module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "clientContactInfo": {
          "clientMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client matched against multiple clients in the system"])}
        }
      },
      "sv": {
        "clientContactInfo": {
          "clientMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klient matchad mot flera klienter i systemet"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"clientContactInfo":{"clientMatch":"Client matched against multiple clients in the system"}},"sv":{"clientContactInfo":{"clientMatch":"Klient matchad mot flera klienter i systemet"}}}')
  delete Component.options._Ctor
  
}
