module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "button": {
          "nextCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take next case"])},
          "nextCaseTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take the next case in your logged in queues that use cherry picking"])}
        }
      },
      "sv": {
        "button": {
          "nextCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta nästa ärende"])},
          "nextCaseTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta nästa ärende i dina inloggade köer som använder cherry picking"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"button":{"nextCase":"Take next case","nextCaseTooltip":"Take the next case in your logged in queues that use cherry picking"}},"sv":{"button":{"nextCase":"Ta nästa ärende","nextCaseTooltip":"Ta nästa ärende i dina inloggade köer som använder cherry picking"}}}')
  delete Component.options._Ctor
  
}
