module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "caseComments": {
          "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
          "answerAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer all"])},
          "forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forward"])}
        }
      },
      "sv": {
        "caseComments": {
          "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svara"])},
          "answerAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svara alla"])},
          "forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidarebefordra"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"caseComments":{"answer":"Answer","answerAll":"Answer all","forward":"Forward"}},"sv":{"caseComments":{"answer":"Svara","answerAll":"Svara alla","forward":"Vidarebefordra"}}}')
  delete Component.options._Ctor
  
}
