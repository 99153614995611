module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "caseComments": {
          "showSystemNotif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show system notifications"])},
          "closeCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go out of case"])},
          "detached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been dismissed from the case due to inactivity for more than"])},
          "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min:s"])},
          "waitUntilCaseIsLoaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait until the case is loaded before closing it"])},
          "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write"])},
          "noCommentsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found."])},
          "noCommentsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try changing your search query."])},
          "noTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No topic"])},
          "commentSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment settings"])},
          "subcaseFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related Case"])},
          "subcase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subcase"])},
          "amountOfSubcases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of related cases"])}
        },
        "caseCard": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not load the case, please try to reload the page"])},
          "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The case could not be found"])}
        }
      },
      "sv": {
        "caseComments": {
          "showSystemNotif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa systemnotifikationer"])},
          "closeCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå ut ur ärendet"])},
          "detached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har blivit utskickad från ärendet på grund av inaktivitet i mer än"])},
          "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
          "waitUntilCaseIsLoaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vänta tills ärendet är laddat innan du stänger det"])},
          "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv"])},
          "noCommentsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga resultat hittades."])},
          "noCommentsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försök att ändra din sökfråga."])},
          "noTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget ämne"])},
          "commentSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar inställningar"])},
          "subcaseFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relaterat ärende"])},
          "subcase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underärende"])},
          "amountOfSubcases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal relaterade ärenden"])}
        },
        "caseCard": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde inte ladda ärendet, vänligen försök att ladda om sidan"])},
          "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ärendet kunde inte hittas"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"caseComments":{"showSystemNotif":"Show system notifications","closeCase":"Go out of case","detached":"You have been dismissed from the case due to inactivity for more than","minutes":"min:s","waitUntilCaseIsLoaded":"Wait until the case is loaded before closing it","write":"Write","noCommentsTitle":"No results found.","noCommentsText":"Try changing your search query.","noTopic":"No topic","commentSettings":"Comment settings","subcaseFrom":"Related Case","subcase":"Subcase","amountOfSubcases":"Amount of related cases"},"caseCard":{"error":"Could not load the case, please try to reload the page","notFound":"The case could not be found"}},"sv":{"caseComments":{"showSystemNotif":"Visa systemnotifikationer","closeCase":"Gå ut ur ärendet","detached":"Du har blivit utskickad från ärendet på grund av inaktivitet i mer än","minutes":"min","waitUntilCaseIsLoaded":"Vänta tills ärendet är laddat innan du stänger det","write":"Skriv","noCommentsTitle":"Inga resultat hittades.","noCommentsText":"Försök att ändra din sökfråga.","noTopic":"Inget ämne","commentSettings":"Kommentar inställningar","subcaseFrom":"Relaterat ärende","subcase":"Underärende","amountOfSubcases":"Antal relaterade ärenden"},"caseCard":{"error":"Kunde inte ladda ärendet, vänligen försök att ladda om sidan","notFound":"Ärendet kunde inte hittas"}}}')
  delete Component.options._Ctor
  
}
