<template>
    <section :key="$route.params.caseId">
        <v-menu>
            <template #activator="{ on }">
                <article class="activity-button" v-on="on" @click="open">
                    <v-icon size="16" color="var(--v-gray4-base)">mdi-bell</v-icon>
                </article>
            </template>

            <header class="case-activity-header">
                <component
                    :is="currentPage === pages.ACTIVITY ? 'tertiary-button' : 'standard-button'"
                    :class="{ disabled: currentPage === pages.ACTIVITY }"
                    @click.stop="currentPage = pages.ACTIVITY"
                >
                    <span>{{ $t('activity') }}</span>
                </component>

                <component
                    :is="currentPage === pages.STATISTICS ? 'tertiary-button' : 'standard-button'"
                    :class="{ disabled: currentPage === pages.STATISTICS }"
                    @click.stop="currentPage = pages.STATISTICS"
                >
                    <span>{{ $t('statistics') }}</span>
                </component>
            </header>

            <section v-if="currentPage === pages.STATISTICS" class="case-activity-box">
                <article class="total-time">
                    <section>{{ $t('totalTime') }}</section>
                    <span class="date">{{ formatTime(caseActivityStatistics.totalActivitiesTime) }}</span>
                </article>
                <article
                    v-for="statistic in caseActivityStatistics.activities"
                    :key="statistic.id"
                    class="activity-item"
                >
                    <article class="avatar">
                        <SimpleTooltip :tooltipText="statistic.userName" bottom>
                            <CommonAvatar :size="22" :userId="statistic.userId" />
                        </SimpleTooltip>
                    </article>
                    <article class="time-in-case">{{ $t('timeInCase') }}</article>
                    <article class="date">{{ formatTime(statistic.timeInCase) }}</article>
                </article>
            </section>

            <section v-if="currentPage === pages.ACTIVITY" class="case-activity-box" @scroll="onScroll">
                <article v-for="activity in activities" :key="activity.id" class="activity-item">
                    <article class="avatar">
                        <SimpleTooltip :tooltipText="activity.userName" bottom>
                            <CommonAvatar :size="22" :userId="activity.userId" />
                        </SimpleTooltip>
                    </article>
                    <article class="action">
                        {{ $t(getActivityIconAndTranslation(activity.activityType)).translation }}
                    </article>
                    <article class="date">{{ getDay(activity.created) }}</article>
                </article>
            </section>
        </v-menu>
    </section>
</template>

<script>
    import { debouncer } from '@/utils';

    import CommonAvatar from '@/components/Global/CommonAvatar.vue';
    import SimpleTooltip from '@/components/Global/SimpleTooltip.vue';

    import { mapActions, mapState } from 'vuex';
    import { getDay } from '@/utils/DateFormatter';
    import { formatTime } from '@/utils/date';
    import { getActivityIconAndTranslation } from '@/helpers/activity/activity.helper';

    export default {
        name: 'CaseActivity',

        components: {
            CommonAvatar,
            SimpleTooltip,
        },

        data() {
            return {
                offset: 0,
                limit: 10,
                fetchDebounce: null,
                currentPage: 'ACTIVITY',
                pages: {
                    ACTIVITY: 'ACTIVITY',
                    STATISTICS: 'STATISTICS',
                },
            };
        },

        computed: {
            ...mapState({
                caseActivity: (state) => state.Activity.caseActivity,
                caseActivityStatistics: (state) => state.Activity.caseActivityStatistics,
            }),

            activities() {
                return this.caseActivity.activities;
            },

            hasMore() {
                return this.caseActivity.hasMore;
            },
        },

        watch: {
            $route() {
                this.setCaseActivity({});
            },

            currentPage: {
                handler() {
                    if (this.currentPage === this.pages.ACTIVITY) {
                        this.getCaseActivity({
                            caseId: this.$route.params.caseId,
                            offset: this.offset,
                            limit: this.limit,
                        });
                    } else {
                        this.getCaseActivityStatistics({ caseId: this.$route.params.caseId });
                    }
                },
                immediate: false,
            },
        },

        created() {
            this.fetchCaseActivityDebounce = debouncer(this.fetchCaseActivity, 100);
        },

        beforeDestroy() {
            this.setCaseActivity({});
            this.setCaseActivityStatistics({});
        },

        methods: {
            ...mapActions({
                getCaseActivity: 'Activity/getCaseActivity',
                getCaseActivityStatistics: 'Activity/getCaseActivityStatistics',
                setCaseActivity: 'Activity/setCaseActivity',
                setCaseActivityStatistics: 'Activity/setCaseActivityStatistics',
            }),

            open() {
                this.currentPage = this.pages.ACTIVITY;
                this.fetchCaseActivity();
            },

            fetchCaseActivity() {
                this.getCaseActivity({
                    caseId: this.$route.params.caseId,
                    offset: this.offset,
                    limit: this.limit,
                });
            },

            onScroll(e) {
                if (this.hasMore && e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
                    this.offset += this.limit;
                    this.fetchCaseActivityDebounce();
                }
            },

            getActivityIconAndTranslation,
            getDay,
            formatTime,
        },
    };
</script>

<style scoped>
    .disabled {
        pointer-events: none;
    }

    .case-activity-header {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .case-activity-box {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        max-height: 260px;
        padding: 4px;
    }

    .activity-button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: var(--v-gray3-base);
        color: white;
        width: 28px;
        height: 28px;
        transition: background-color 0.2s ease;
    }

    .activity-button:hover {
        background-color: var(--v-gray3-darken1);
    }

    .activity-button:active {
        background-color: var(--v-gray3-darken2);
    }

    .activity-item {
        padding: 10px;
        display: grid;
        grid-template-columns: 30px 1fr auto;
        gap: 10px;
        height: auto;
    }

    .date {
        font-size: 10px;
        color: var(--v-gray2-base);
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .time-in-case {
        font-size: 12px;
        color: var(--v-gray4-base);
        display: flex;
        align-items: center;
    }

    .action {
        font-size: 12px;
        color: var(--v-gray4-base);
        display: flex;
        align-items: center;
    }

    .avatar {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    div[role='menu'] {
        border-radius: 8px;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #eee;
        background-color: var(--v-gray3-base);
        min-width: 300px !important;
        max-width: 240px !important;
        max-height: 260px;
        overflow: hidden;
    }

    .total-time {
        padding: 10px;
        font-size: 12px;
        color: var(--v-gray4-base);
        display: flex;
        justify-content: space-between;
        min-height: 40px;
    }
</style>

<i18n lang="json">
{
    "en": {
        "activity": "Activity",
        "statistics": "Statistics",
        "timeInCase": "Time in case",
        "totalTime": "Total time"
    },
    "sv": {
        "activity": "Aktivitet",
        "statistics": "Statistik",
        "timeInCase": "Tid i ärende",
        "totalTime": "Total tid"
    }
}
</i18n>
