<template>
    <div>
        <div v-if="isSidebar && sidebarContent">
            <div @mouseleave="handleSidebar('close')" @mouseenter="handleSidebar('open')">
                <v-list dense rounded class="m-auto">
                    <template v-for="(item, i) in sidebar">
                        <RecursiveListItem
                            :id="i"
                            :key="i"
                            :hasPopOuts="hasPopOuts"
                            :selectedItem="selected"
                            :item="item"
                            :isSidebarPinned="isSidebarPinned"
                            @selected="applySelected"
                        />
                    </template>
                    <div v-if="hasExternalLinks">
                        <v-divider class="my-2" />
                        <template v-for="(linkItem, linkIndex) in config.customerParams.sideBarExternal.links">
                            <ListLinkItem :key="'link' + linkIndex" :item="linkItem" />
                        </template>
                    </div>
                </v-list>
            </div>
        </div>
        <div v-else>
            <v-list dense rounded class="m-auto">
                <template v-for="(item, i) in adminList">
                    <RecursiveListItem
                        :id="i"
                        :key="i"
                        :hasPopOuts="hasPopOuts"
                        :selectedItem="selected"
                        :item="item"
                        @selected="applySelected"
                    />
                </template>
            </v-list>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import config from '@/app/config';

    import RecursiveListItem from '@/components/Global/MenuList/RecursiveListItem.vue';
    import ListLinkItem from '@/components/Global/MenuList/ListLinkItem.vue';

    export default {
        components: {
            RecursiveListItem,
            ListLinkItem,
        },
        props: {
            isSidebar: {
                type: Boolean,
                default: false,
            },
            hasPopOuts: {
                type: Boolean,
                default: false,
            },
            isSidebarPinned: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                config,
                rememberActive: [],
                selected: '',
                sidebar: [],
            };
        },
        computed: {
            ...mapState({
                userRoles: (state) => state.Auth.roles,
                loggedInUserId: (state) => state.Auth.userObject.userId,
                storeAdminList: (state) => state.Admin.adminList,
                storeSidebarContent: (state) => state.Admin.sidebarContent,
                sidebarContentDefault: (state) => state.Admin.sidebarContentDefault,
            }),
            hasExternalLinks() {
                return (
                    this.config.customerParams?.sideBarExternal?.active &&
                    this.config.customerParams?.sideBarExternal.links.length
                );
            },
            externalLinks() {
                return this.config.customerParams.sideBarExternal.links;
            },
            sidebarContent() {
                // loop through the sidebar content and check if one of the childrens href matches the current route. If so, set the parent as active
                const [sidebarContent] = this.recursiveCheckPath(
                    structuredClone(this.storeSidebarContent),
                    this.$route.path
                );
                return sidebarContent;
            },
            adminList: {
                set(newVal) {
                    this.$store.dispatch('Admin/setAdminList', newVal);
                },
                get() {
                    return structuredClone(this.storeAdminList);
                },
            },
        },
        watch: {
            $route: {
                immediate: true,
                handler(newVal) {
                    if (!newVal) return;
                    // we remove params since we want 'pure' path
                    const splitPath = newVal.path.split('/');
                    const paramValues = Object.values(newVal.params);
                    const pathWithouthParams = splitPath.filter((item) => !paramValues.includes(item));
                    const reassembledPath = pathWithouthParams.join('/');
                    this.selected = reassembledPath; // identify via the path, as a listItems pathDirection is always unique
                },
            },
            sidebarContentDefault: {
                deep: true,
                immediate: true,
                handler(newVal) {
                    this.sidebar = newVal;
                },
            },
        },
        methods: {
            handleSidebar(action) {
                switch (action) {
                    case 'open': {
                        this.sidebar = structuredClone(this.sidebarContent);
                        break;
                    }
                    case 'close': {
                        this.$store.dispatch('Admin/setSidebarContent', this.sidebar);
                        this.sidebar = structuredClone(this.sidebarContentDefault);
                        break;
                    }
                    default: {
                        break;
                    }
                }
            },
            applySelected(v) {
                this.selected = v;
            },
            recursiveCheckPath(arrayOfObjects, path) {
                for (const item of arrayOfObjects) {
                    if (item.href === path) {
                        return [arrayOfObjects, true];
                    }
                    if (!item.children) {
                        continue;
                    }
                    // not interested in the array of objects
                    const [, childrenMatchingPath] = this.recursiveCheckPath(item.children, path);
                    if (typeof childrenMatchingPath === 'boolean') {
                        item.active = childrenMatchingPath;
                        return [arrayOfObjects, true];
                    }
                }
                return [arrayOfObjects, false];
            },
        },
    };
</script>
<style lang="css" scoped></style>
