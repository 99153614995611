<template>
    <div v-if="iframes.length" class="iframe-buttons-wrapper">
        <template v-for="iframe in iframes">
            <v-tooltip :key="iframe.id" top>
                <template #activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                        <ClientCardActionButton
                            state="iframe"
                            :iframeName="iframe.name"
                            :action="() => selectIframe(iframe.name)"
                            :image="getImage(iframe.name)"
                            :tooltip-label="formatName(iframe.name) + ' Iframe'"
                        />
                    </div>
                </template>
                <span>{{ iframe.name }}</span>
            </v-tooltip>
        </template>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import { getImage } from '@/utils/Integrations';
    import ClientCardActionButton from '@/components/Cases/ClientCard/ClientCardActionButton.vue';

    export default {
        components: {
            ClientCardActionButton,
        },
        data() {
            return {
                iframes: [],
            };
        },
        mounted() {
            this.fetchIframes();
        },
        methods: {
            getImage,
            ...mapActions('Cases', ['sideBarView', 'setIframe', 'getIframes']),
            async fetchIframes() {
                this.iframes = await this.getIframes();
            },
            selectIframe(type) {
                this.setIframe(type);
                this.sideBarView('iframe');
            },
            formatName(name) {
                return name.charAt(0).toUpperCase() + name.toLowerCase().slice(1);
            },
        },
    };
</script>

<style scoped>
    .iframe-buttons-wrapper {
        display: flex;
        gap: 8px;
    }
</style>
