export const registerStates = Object.freeze({
    DISABLED: 0, // SIP is disabled in systemintegrations
    INIT: 1, // User tries to register
    NOT_REGED: 2, // User is not registered
    ERROR: 3, // There was an error
    NO_NUMBER: 4, // User has no number
    DISCONNECTED: 5, // Sockets have been disconnected
    REGISTERING: 6, // User is registering
    ALREADY_ACTIVE: 7, // User is registered in another tab
    BECOME_MAIN: 8, // User is becoming main
    REPLACE_MAIN: 9, // Tab is replacing another tab as main
    READY: 10, // User is registered and ready
    RE_REG: 11, // User is re-registering
});
// These follow the standard from freesitch https://developer.signalwire.com/freeswitch/FreeSWITCH-Explained/Troubleshooting-Debugging/SIP-Protocol-Messages_1048971/
export const hangupReasons = Object.freeze({
    UNDEFINED: 0,
    CALL_MISSED: 1,
    NORMAL_CLEARING: 16,
    USER_BUSY: 486,
    NO_ANSWER: 480,
});

export const callStates = Object.freeze({
    REJECTED: 1, // Call was rejected by the remote end
    CANCELLED: 2, // Call was cancelled by the remote end
    ENDED: 3, // Call has ended
    RINGING: 4, // Call is ringing here
    AWAITING: 5, // Call is ringing on the remote end
    ONGOING: 6, // Call is ongoing
    HOLD: 7, // Call is on hold
    MUTED: 8, // Call is muted
});

export const callDirections = Object.freeze({
    INCOMING: 1,
    OUTGOING: 2,
});
