<template>
    <v-card v-if="recordingList.length > 0" elevation="0" class="ma-0 pa-0" style="width: 100%">
        <v-virtual-scroll
            id="v-scroll"
            :bench="benched"
            :items="recordingList"
            item-height="80"
            :height="getVHeight"
            style="border-radius: 0px 0px 0px 0px; width: 100%"
            :style="{ backgroundColor: 'white' }"
        >
            <template #default="{ item }">
                <v-list-item :key="item.sessionId" link :ripple="false" @mousedown="getSpecificRecording(item)">
                    <v-list-item-action>
                        <v-btn fab small depressed color="primary" style="margin-left: 8px !important; z-index: 11">
                            <v-icon size="18" :color="'white'">mdi-monitor</v-icon>
                        </v-btn>
                    </v-list-item-action>
                    <div
                        style="
                            margin-top: 8px;
                            margin-bottom: 8px;
                            width: 100%;
                            border-radius: 3px;
                            background-color: white;
                        "
                        class="softShadowWhite d-flex flex-row px-5"
                    >
                        <v-list-item-content>
                            <v-list-item-title>
                                <span style="font-size: 14px" :style="{ color: 'var(--v-gray2-base' }">
                                    {{ item.timestamp | formatDate }}
                                </span>
                                <br />
                                <!-- <span style="font-size: 12px" :style="{ color: 'var(--v-gray1-base' }">Datum / Tid</span> -->
                                <span style="font-size: 12px" :style="{ color: 'var(--v-gray1-base' }">
                                    {{ $t('recordings.dateTime') }}
                                </span>
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-content class="text-truncate" style="max-width: 200px; min-width: 150px">
                            <v-list-item-title>
                                <span style="font-size: 14px" :style="{ color: 'var(--v-gray2-base' }">
                                    {{ item.visitorId }}
                                </span>
                                <br />
                                <!-- <span style="font-size: 12px" :style="{ color: 'var(--v-gray1-base' }">Kund ID</span> -->
                                <span style="font-size: 12px" :style="{ color: 'var(--v-gray1-base' }">
                                    {{ $t('recordings.customerId') }}
                                </span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </div>
                </v-list-item>
            </template>
        </v-virtual-scroll>
        <v-dialog v-model="dialog" width="1095" height="1080px">
            <v-card height="750">
                <v-toolbar color="primary" dark class="text-center">
                    <v-spacer />
                    <!-- <h4>Uppspelning - Besök på hemsida {{ objectClickedInfo.timestamp }}</h4> -->
                    <h4>{{ $t('recordings.replay') }} {{ objectClickedInfo.timestamp }}</h4>
                    <v-spacer />
                    <v-btn icon @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-divider />
                <v-card-text>
                    <RrWebPlayer v-if="events.length > 0" :events="events" :skipInactive="false" />
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
    <v-card v-else elevation="0" class="d-flex flex-row ma-0 pa-0 px-6 py-6 justify-center" style="width: 100%">
        <!-- <span :style="{ color: 'var(--v-gray2-base' }">Det finns inga webbinspelningar på denna kund.</span> -->
        <span :style="{ color: 'var(--v-gray2-base' }">{{ $t('recordings.noWebRecordings') }}</span>
    </v-card>
</template>
<script>
    import RrWebPlayer from '@preflight-hq/rrweb-player-vue';
    import { parseISO, format } from 'date-fns';
    import { useEventListener } from '@vueuse/core';

    export default {
        components: {
            RrWebPlayer,
        },
        filters: {
            formatDate(date) {
                return format(parseISO(date), 'yyyy-MM-dd HH:mm');
            },
        },
        props: ['clientCard'],
        data() {
            return {
                recordingList: [],
                headers: [
                    // { text: 'Datum', value: 'timestamp' },
                    // { text: 'Kund ID', value: 'visitorId' }
                    { text: this.$t('global.date'), value: 'timestamp' },
                    { text: this.$t('recordings.customerId'), value: 'visitorId' },
                ],
                events: [],
                dialog: false,
                objectClickedInfo: {},
                benched: 0,
                itemHeight: 80,
                numberOfItems: 6,
                width: document.documentElement.clientWidth,
                height: document.documentElement.clientHeight,
            };
        },
        computed: {
            getVHeight() {
                if (this.recordingList.length === 0) {
                    return '0px';
                }
                if (this.recordingList.length < this.numberOfItems) {
                    return this.recordingList.length * this.itemHeight + 'px';
                }
                return this.numberOfItems * this.itemHeight + 'px';
            },
        },
        created() {
            this.getRecordingList();
        },
        methods: {
            getDimensions() {
                this.width = document.documentElement.clientWidth;
                this.height = document.documentElement.clientHeight;
            },
            mounted() {
                useEventListener(window, 'resize', this.getDimensions);
            },
            hide() {
                this.$store.dispatch('Cases/sideBarView', 'default');
            },
            async getRecordingList() {
                this.recordingList = await this.$store.dispatch(
                    'Recordings/getRecordings',
                    this.clientCard.caseFrom.email
                );
            },
            async getSpecificRecording(objectClicked) {
                this.objectClickedInfo = objectClicked;
                this.dialog = true;
                this.events = [];
                this.events = await this.$store.dispatch('Recordings/getSpecificRecording', objectClicked.sessionId);
            },
        },
    };
</script>
<style scoped lang="scss">
    .list-item {
        opacity: 1;
        cursor: pointer;
        border: 1px solid #efefef;
    }
    .list-item:hover {
        transition: all 0.1s ease;
        border: 1px solid #ccc;
    }
    .list-selected {
        border: 1px solid #ccc !important;
    }
    .container {
        scroll-snap-points-y: repeat(420px);
        scroll-snap-type: y proximity;
    }
    .child {
        scroll-snap-align: start;
    }

    .highlighted {
        background-color: red !important;
    }
    .selectedRow {
        opacity: 1 !important;
        outline: var(--v-primary-base) 2px solid;
        box-shadow:
            0px 4px 4px rgba(0, 0, 0, 0.03),
            0px 8px 8px rgba(0, 0, 0, 0.01) !important;
    }
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
        border-radius: 0px 0px 3px 0px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: white;
        border-radius: 0px 0px 3px 0px;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 0px 0px 0px 0px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    .v-progress-circular {
        display: block;
    }
    .softShadowWhite {
        box-shadow:
            0px 4px 8px rgba(0, 0, 0, 0.07),
            0 0px 16px rgba(0, 0, 0, 0.05) !important;
        background-color: transparent !important;
    }
</style>
<i18n lang="json">
{
    "en": {
        "recordings": {
            "dateTime": "Date / Time",
            "customerId": "Customer ID",
            "replay": "Playback - Visit website",
            "noWebRecordings": "Playback - Visit website"
        }
    },
    "sv": {
        "recordings": {
            "dateTime": "Datum / Tid",
            "customerId": "Kund-ID",
            "replay": "Uppspelning - Besök på hemsida",
            "noWebRecordings": "Uppspelning - Besök på hemsida"
        }
    }
}
</i18n>
