/**
 * Waits for an element to be available in the DOM and resolves with the element.
 * @param {string} selector - The CSS selector for the element to wait for.
 * @param {Element} [scope=document.body] - The optional scope to search for the element. Defaults to document.body.
 * @param {number} [timeout=1000] - The optional timeout in milliseconds. Defaults to 1000ms.
 * @returns {Promise<Element>} A promise that resolves with the element once it is available in the DOM.
 */
export function waitForElement(selector, scope = document.body, timeout = 1000) {
    return new Promise((resolve) => {
        const el = document.querySelector(selector);
        if (el) {
            resolve(el);
            return;
        }

        const observer = new MutationObserver(() => {
            const element = document.querySelector(selector);
            if (!element) return;
            resolve(element);
            observer.disconnect();
        });

        // If you get "parameter 1 is not of type 'Node'" error, see https://stackoverflow.com/a/77855838/492336
        observer.observe(scope, {
            childList: true,
            subtree: true,
        });
        // Timeout before resolving if no element exists
        setTimeout(() => {
            observer.disconnect();
            resolve(null);
        }, timeout);
    });
}
