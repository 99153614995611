module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "wrapupTime": {
          "wrapupTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrapup time"])},
          "resetWrapup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Wrapup"])},
          "extendWrapup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extend Wrapup"])},
          "noExtendedWrapup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No wrapup time available"])},
          "startExtendedWrapup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start extended wrapup time"])}
        }
      },
      "sv": {
        "wrapupTime": {
          "wrapupTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbetningstid"])},
          "resetWrapup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avsluta bearbetning"])},
          "extendWrapup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förläng bearbetningstid"])},
          "noExtendedWrapup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen bearbetningstid tillgänglig"])},
          "startExtendedWrapup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starta extra bearbetningstid"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"wrapupTime":{"wrapupTime":"Wrapup time","resetWrapup":"Reset Wrapup","extendWrapup":"Extend Wrapup","noExtendedWrapup":"No wrapup time available","startExtendedWrapup":"Start extended wrapup time"}},"sv":{"wrapupTime":{"wrapupTime":"Bearbetningstid","resetWrapup":"Avsluta bearbetning","extendWrapup":"Förläng bearbetningstid","noExtendedWrapup":"Ingen bearbetningstid tillgänglig","startExtendedWrapup":"Starta extra bearbetningstid"}}}')
  delete Component.options._Ctor
  
}
