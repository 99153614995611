<template>
    <header class="header-content">
        <Creator />
        <section class="header-item start">
            <round-icon-button
                class="mr-2"
                cy-data="filterButton"
                color="white"
                :hoverEffect="true"
                :active="sideMenuOpen"
                @click="toggleFilters"
            >
                mdi-filter
            </round-icon-button>
            <action-button color="white" :hoverEffect="true" class="mr-2" cy-data="createCase" @click="toggleDialog">
                {{ $t('button.createCase') }}
            </action-button>
            <NextCaseButton v-if="userSettings?.cases?.showNextCaseButton?.active" />
        </section>
        <section class="header-item center">
            <article id="dynamic-statistics">
                <DynamicStatistics v-if="showDynamicStatistics" />
            </article>
        </section>
        <section class="header-item end">
            <ListSearch />
        </section>
    </header>
</template>

<script>
    import { mapActions, mapState, mapGetters } from 'vuex';
    import { states } from '@/enums/states.enums';

    import ListSearch from '@/components/Main/Header/Search/ListSearch.vue';
    import DynamicStatistics from '@/components/DynamicStatistics/DynamicStatistics.vue';
    import Creator from '@/components/Cases/Creator/Creator.vue';
    import NextCaseButton from '@/components/Global/NextCaseButton.vue';

    export default {
        name: 'HeaderContent',

        components: {
            ListSearch,
            DynamicStatistics,
            Creator,
            NextCaseButton,
        },

        data() {
            return {
                states,
            };
        },

        computed: {
            ...mapState({
                showDynamicStatistics: (state) => state.DynamicStatistics.visible,
                userSettings: (state) => state.System.userSettings,
            }),
            ...mapGetters('Header', ['sideMenuOpen']),
        },

        methods: {
            ...mapActions({
                toggleFilters: 'Header/toggleFilters',
                toggleDialog: 'Creator/toggleDialog',
            }),
        },
    };
</script>

<style scoped>
    .header-content {
        flex: 1;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
    }

    .header-item {
        flex: 0;
        padding: 0 16px;
        display: flex;
        align-items: center;
    }

    .start {
        justify-self: start;
    }

    .center {
        justify-self: center;
    }

    .end {
        justify-self: end;
    }

    @media (max-width: 1500px) {
        #dynamic-statistics {
            display: none;
        }
    }
</style>

<i18n lang="json">
{
    "en": {
        "button": {
            "createCase": "Create"
        }
    },
    "sv": {
        "button": {
            "createCase": "Skapa"
        }
    }
}
</i18n>
