export function convertSecondsToDigital(value) {
    let hours = Math.floor(value / 60);
    let minutes = value - hours * 60;

    if (hours.toString().length == 1) hours = '0' + hours;
    if (minutes.toString().length == 1) minutes = '0' + minutes;

    if (minutes == 0) minutes = '00';

    return `${hours}:${minutes}`;
}

export function convertDigitalToSeconds(arr) {
    const from = arr[0];
    const fromHours = Number.parseInt(from.slice(0, -3));
    const fromMinutes = Number.parseInt(from.slice(-2));
    const fromTotal = fromHours * 60 + fromMinutes;
    const to = arr[1];
    const toHours = Number.parseInt(to.slice(0, -3));
    const toMinutes = Number.parseInt(to.slice(-2));
    const toTotal = toHours * 60 + toMinutes;
    return [fromTotal, toTotal];
}
