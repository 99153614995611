import axios from 'axios';

export function getAvailableVoices() {
    return axios.get('/telephony/prompts/voices');
}
export function generateTestPrompt(voiceName, text) {
    return axios.get('/telephony/prompts/generate', {
        params: {
            voiceName,
            prompt: text,
        },
        responseType: 'arraybuffer',
        headers: {
            Accept: 'audio/wav',
        },
    });
}

export default {
    getAvailableVoices,
    generateTestPrompt,
};
