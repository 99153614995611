// State object
const state = {
    loading: false,
};
// Getter functions
const getters = {};
// Actions
const actions = {
    showLoader({ commit }) {
        /* Not Active yet, need to refactor the loaders on the pages before so we got the same loading state on each page */
        commit('SET_LOADING', false);
    },

    hideLoader({ commit }) {
        /* Not Active yet, need to refactor the loaders on the pages before so we got the same loading state on each page */
        commit('SET_LOADING', false);
    },
};

// Mutations
const mutations = {
    SET_LOADING(state, payload) {
        state.loading = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
