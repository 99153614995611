import openinghours from '@/api/access/openinghours';

const state = {
    queues: [],
    accessedUsers: [],
};
const getters = {};
const mutations = {
    SET_ACCESS_QUEUES(state, data) {
        state.queues = data;
    },
    SET_ACCESS_USERS(state, data) {
        state.accessedUsers = data;
    },
};
const actions = {
    setAccessedUsers({ commit }, data) {
        commit('SET_ACCESS_USERS', data);
    },
    async getQueues({ commit }) {
        const { data } = await openinghours.getQueues();
        const { openQueues, c1Queues, ivrs } = data;
        commit('SET_ACCESS_QUEUES', [...openQueues, ...c1Queues, ...ivrs]);
    },
    async getAccessUsers({ commit }) {
        const { data } = await openinghours.getAccessUsers();
        commit('SET_ACCESS_USERS', data);
    },
    async addUserAccess({ dispatch }, payload) {
        const { data } = await openinghours.addUserAccess(payload);
        await dispatch('getAccessUsers');
        return data;
    },

    async removeUserAccess({ dispatch }, userId) {
        const { data } = await openinghours.removeUserAccess(userId);
        await dispatch('getAccessUsers');
        return data;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
