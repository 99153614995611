import axios from 'axios';

function getQueues() {
    return axios.get(`/openinghours/queues`);
}

function getAccessUsers() {
    return axios.get(`/openinghours/access/users`);
}
function getUserAccess(userId) {
    return axios.get(`/openinghours/access/users/${userId}/list`);
}

function addUserAccess(payload) {
    return axios.post(`/openinghours/access/users`, payload);
}

function removeUserAccess(userId) {
    return axios.delete(`/openinghours/access/users/${userId}`);
}

export default {
    getQueues,
    getAccessUsers,
    getUserAccess,
    addUserAccess,
    removeUserAccess,
};
