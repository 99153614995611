module.exports = {
    params: {
        integrationLinks: {
            active: true,
            links: [
                { name: 'ÄGA', color: '#afaf0c', link: 'äga', icon: 'mdi-link' },
                { name: 'MRM', color: '#add8e6', link: 'mrm', icon: 'mdi-link' },
            ],
        },
    },
};
