module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "activeCases": {
          "activeCases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Cases"])},
          "activeDialogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Dialogs"])},
          "activeQueues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Queues"])},
          "activeCasesMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any active cases."])},
          "activeDialogsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any active dialogs."])},
          "activeQueuesMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any active queues."])},
          "activeCasesNoItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active cases"])},
          "activeDialogsNoItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active dialogs"])},
          "activeQueuesNoItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active queues"])}
        }
      },
      "sv": {
        "activeCases": {
          "activeCases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiva ärenden"])},
          "activeDialogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiva dialoger"])},
          "activeQueues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiva köer"])},
          "activeCasesMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har inga aktiva ärenden."])},
          "activeDialogsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har inga aktiva dialoger."])},
          "activeQueuesMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har inga aktiva köer."])},
          "activeCasesNoItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga aktiva ärenden"])},
          "activeDialogsNoItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga aktiva dialoger"])},
          "activeQueuesNoItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga aktiva köer"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"activeCases":{"activeCases":"Active Cases","activeDialogs":"Active Dialogs","activeQueues":"Active Queues","activeCasesMessage":"You don\u0027t have any active cases.","activeDialogsMessage":"You don\u0027t have any active dialogs.","activeQueuesMessage":"You don\u0027t have any active queues.","activeCasesNoItems":"No active cases","activeDialogsNoItems":"No active dialogs","activeQueuesNoItems":"No active queues"}},"sv":{"activeCases":{"activeCases":"Aktiva ärenden","activeDialogs":"Aktiva dialoger","activeQueues":"Aktiva köer","activeCasesMessage":"Du har inga aktiva ärenden.","activeDialogsMessage":"Du har inga aktiva dialoger.","activeQueuesMessage":"Du har inga aktiva köer.","activeCasesNoItems":"Inga aktiva ärenden","activeDialogsNoItems":"Inga aktiva dialoger","activeQueuesNoItems":"Inga aktiva köer"}}}')
  delete Component.options._Ctor
  
}
