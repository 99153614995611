import axios from 'axios';

function getAudiences(data) {
    return axios.get('/campaigns/audiences/', { params: data });
}

function getAudienceItems(type, id, limit, page, search) {
    return axios.get('/campaigns/audiences/items/', { params: { type, id, limit, page, search } });
}

function getAudienceItemsClients(id, limit, page, search) {
    return axios.get('/campaigns/audiences/items/clients', { params: { id, limit, page, search } });
}

function createAudience(data) {
    return axios.post('/campaigns/audiences/', data);
}

function deleteAudience(id) {
    return axios.delete(`/campaigns/audiences/${id}`);
}

function bulkInsertClients(data) {
    return axios.post(`/campaigns/audiences/${data.id}/clients/bulk`, data.file, {
        headers: {
            'Content-Type': 'text/csv',
        },
    });
}

function deleteAudienceItem(audienceId, clientid) {
    return axios.delete(`/campaigns/audiences/${audienceId}/clients/${clientid}`);
}

function addAudienceItem(audienceId, clientId) {
    return axios.post(`/campaigns/audiences/${audienceId}/clients/${clientId}`);
}

export default {
    getAudiences,
    getAudienceItems,
    getAudienceItemsClients,
    createAudience,
    deleteAudience,
    bulkInsertClients,
    deleteAudienceItem,
    addAudienceItem,
};
