module.exports = {
    params: {
        integrationLinks: {
            active: false,
            links: [
                { name: 'ÄGA', color: '#afaf0c', link: 'äga' },
                { name: 'MRM', color: '#add8e6', link: 'mrm' },
            ],
        },
    },
};
