import axios from 'axios';
//= ===================================================//
//       CALLBACKBOOKING TEMPLATES                    //
//= ===================================================//
function addTemplate(data) {
    return axios.post(`/cmot/templates`, data);
}
function updateTemplate({ id, data }) {
    return axios.put(`/cmot/templates/${id}`, data);
}
function deleteTemplate(id) {
    return axios.delete(`/cmot/templates/${id}`);
}
function getTemplateById(id) {
    return axios.get(`/cmot/templates/${id}`);
}
function getTemplateByQueueIdentifier(id) {
    return axios.get(`/cmot/templates/by-queue-identifier/${id}`);
}

function getTemplateFrequencies() {
    return axios.get(`/cmot/templates-frequencies`);
}

function setPeriod(payload) {
    return axios.post(`/cmot/templates/apply-template`, payload);
}

// -----------------------------------------------------------

//= ===================================================//
//                  CALLBACK AGENTS                   //
//= ===================================================//

function addAgent(data) {
    return axios.post(`/cmot/agents`, data);
}

function updateAgent(data, id) {
    return axios.put(`/cmot/agents/${id}`, data);
}

function deleteAgent(id) {
    return axios.delete(`/cmot/agents/${id}`);
}

function getAgentsByQueueId(id) {
    return axios.get(`/cmot/agents/by-queue-identifier/${id}`);
}
// -----------------------------------------------------------

function updateBooking(data, selectedBooking) {
    return axios.put(`/cmot/timetables/${selectedBooking}`, data);
}
function insertBooking(data) {
    return axios.post(`/cmot/timetables`, data);
}

function getClickedBooking(id) {
    return axios.get(`/cmot/timetables/${id}`);
}

function getTimeGenereateType() {
    return axios.get(`/cmot/callbacks/init`);
}

function getPeriod(data) {
    return axios.get(`/cmot/search/timetables?from=${data.startDate}&to=${data.endDate}&qid=${data.id}`);
}

function getAllUsersInGroup(id) {
    return axios.get(`/cmot/groups/${id}/info`);
}

function getAllGroups(userId) {
    return axios.get(`/cmot/users/${userId}/groups`);
}

function deleteRecord(selectedBooking) {
    return axios.delete(`/cmot/timetables/${selectedBooking}`);
}

function deleteAllRecords(selectedBooking) {
    return axios.delete(`/cmot/multiple/timetables/1`, { data: selectedBooking });
}
function deleteAllRecordsWithTemplate(selectedBooking) {
    return axios.delete(`/cmot/multiple/timetables/2`, { data: selectedBooking });
}
function addGeneratedSlotTimes(slotTImes) {
    return axios.post(`/cmot/slots`, slotTImes);
}

function deleteMarkedSlots(selectedSlotId) {
    return axios.post('/cmot/slots/delete', selectedSlotId);
}

function deleteSlot(item) {
    return axios.delete(`/cmot/slots/${item.id}`);
}

function postponeBooking(payload) {
    return axios.put(`/cmot/callback/${payload.callbackId}`, payload);
}

function getPhonenumber(callbackId) {
    return axios.get(`/cmot/phonenumber/${callbackId}`);
}

function generateNewSlots(selectedBooking) {
    return axios.post(`/cmot/booking/slots/${selectedBooking}`);
}

export {
    updateBooking,
    deleteRecord,
    deleteAllRecords,
    deleteAllRecordsWithTemplate,
    insertBooking,
    getPeriod,
    getAllUsersInGroup,
    getAllGroups,
    getClickedBooking,
    getTimeGenereateType,
    addGeneratedSlotTimes,
    deleteMarkedSlots,
    deleteSlot,
    postponeBooking,
    // templates
    addTemplate,
    updateTemplate,
    deleteTemplate,
    getTemplateById,
    getTemplateByQueueIdentifier,
    getTemplateFrequencies,
    // agents
    addAgent,
    updateAgent,
    deleteAgent,
    getAgentsByQueueId,
    setPeriod,
    getPhonenumber,
    generateNewSlots,
};
