module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "caseTravel": {
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event time"])},
          "goToCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to case"])},
          "caseId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case ID:"])},
          "queue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queue:"])},
          "queueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queue name:"])},
          "talkTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call time"])},
          "queueTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queue time"])},
          "calledPeople": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Called people"])}
        }
      },
      "sv": {
        "caseTravel": {
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Händelsetid"])},
          "goToCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till ärende"])},
          "caseId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ärendenummer:"])},
          "queue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kö:"])},
          "queueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Könamn:"])},
          "talkTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalstid"])},
          "queueTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kötid"])},
          "calledPeople": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppringda personer"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"caseTravel":{"time":"Event time","goToCase":"Go to case","caseId":"Case ID:","queue":"Queue:","queueName":"Queue name:","talkTime":"Call time","queueTime":"Queue time","calledPeople":"Called people"}},"sv":{"caseTravel":{"time":"Händelsetid","goToCase":"Gå till ärende","caseId":"Ärendenummer:","queue":"Kö:","queueName":"Könamn:","talkTime":"Samtalstid","queueTime":"Kötid","calledPeople":"Uppringda personer"}}}')
  delete Component.options._Ctor
  
}
