<template>
    <div
        class="d-flex align-center"
        style="position: relative"
        :style="{
            color: textColor,
            'font-weight': fontWeight,
            'font-size': fontSize,
            height: fieldHeight,
            width: fieldWidth,
            'max-width': fieldWidth,
        }"
    >
        <div v-if="editingText" style="position: relative; height: 100%; width: 100%">
            <div
                class="rounded-lg ma-0 pa-0"
                style="position: absolute; height: 100%; width: 100%; opacity: 0.1; z-index: 0"
                :style="{ backgroundColor: 'var(--v-gray4-base)' }"
            ></div>
            <v-text-field
                v-model="text"
                z-index="1"
                :class="textFieldClass"
                class="ma-0 pa-0 d-flex align-center"
                style="height: 100%"
                autofocus
                hide-details
                append-icon="mdi-arrow-u-left-top"
                flat
                solo
                :maxLength="maxLength"
                placeholder="Namn"
                background-color="transparent"
                @keydown.enter="saveText()"
                @blur="saveText()"
                @click:append="resetText"
            />
        </div>
        <div v-if="!editingText" class="d-flex align-center" style="height: 100%; width: 100%">
            <v-tooltip top class="align-center" style="max-width: 90%">
                <template #activator="{ on }">
                    <span class="text-truncate" v-on="on">
                        {{ text }}
                    </span>
                </template>
                {{ text }}
            </v-tooltip>
            <v-btn
                class="ml-3"
                fab
                elevation="0"
                style="background-color: transparent"
                width="32px"
                height="32px"
                @click="editingText = true"
            >
                <v-icon :size="iconSize" :style="{ color: iconColor }">mdi-pencil-outline</v-icon>
            </v-btn>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            existingText: {
                type: String,
                default: '',
            },
            fontWeight: {
                type: String,
                default: '300',
            },
            fontSize: {
                type: String,
                default: '24px',
            },
            fieldHeight: {
                type: String,
                default: '40px',
            },
            textColor: {
                type: String,
                default: '',
            },
            fieldWidth: {
                type: String,
                default: '600px',
            },
            textFieldClass: {
                type: String,
                default: '',
            },
            iconSize: {
                type: String,
                default: '16px',
            },
            maxLength: {
                type: String,
                default: '-1',
            },
            iconColor: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                text: '',
                editingText: false,
            };
        },

        watch: {
            existingText: {
                immediate: true,
                handler() {
                    this.text = this.existingText;
                },
            },
        },

        methods: {
            saveText() {
                if (this.text != this.existingText) {
                    this.$emit('saveText', this.text);
                }
                this.editingText = false;
            },
            resetText() {
                this.text = this.existingText;
                this.editingText = false;
            },
        },
    };
</script>
<style scoped lang="scss">
    :deep(.v-text-field.v-text-field--solo .v-input__control) {
        min-height: 0px !important;
    }
</style>
