<template>
    <main class="d-flex" :class="getPositionClass(position)">
        <v-tooltip bottom>
            <template #activator="{ on, attrs }">
                <div class="text-box" v-bind="attrs" v-on="on">
                    <Transition name="fade">
                        <div
                            :style="{
                                color: item.unreadMessages > 0 ? 'var(--v-gray4-base)' : 'var(--v-gray2-base)',
                                fontWeight: item.unreadMessages > 0 ? '500' : '400',
                            }"
                            class="d-flex flex-column"
                            :class="getPositionClass(position, 'column')"
                        >
                            <div class="text-truncate d-flex flex-row align-center" style="max-width: 100%">
                                <span class="text-truncate">{{ value }}</span>
                            </div>
                            <div
                                v-if="listLayout.value == 'big'"
                                class="text-truncate d-flex"
                                :style="{ color: 'var(--v-gray1-base' }"
                                style="font-weight: 400 !important"
                            >
                                <span
                                    style="font-size: 12px; font-weight: 400 !important"
                                    :style="{ color: 'var(--v-gray1-base' }"
                                >
                                    {{ header }}
                                </span>
                            </div>
                        </div>
                    </Transition>
                </div>
            </template>

            <span v-if="listLayout.value === 'big'">{{ value }}</span>
            <span v-else>{{ header }}: {{ value }}</span>
        </v-tooltip>
    </main>
</template>
<script>
    import { positionTypes } from '@/enums/cases.enums';
    import { getPositionClass } from '@/helpers/cases/cases.helper';

    export default {
        props: {
            value: '',
            header: '',
            item: {},
            position: {
                type: String,
                default: positionTypes.LEFT,
                validator(value) {
                    // Shorthand for validator
                    return Object.values(positionTypes).includes(value);
                },
            },
        },
        data() {
            return {
                background: false,
            };
        },
        computed: {
            listLayout() {
                return this.$store.state.Cases.listLayout;
            },
        },
        methods: {
            getPositionClass,
        },
    };
</script>

<style scoped>
    .text-small {
        font-size: 12px;
    }

    .client-text {
        color: var(--v-gray1-base);
        font-weight: 400 !important;
    }
    .text-box {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>
