import axios from 'axios';

function getCaseActivity(caseId, limit, offset) {
    return axios.get(`/activity/case/${caseId}`, {
        params: {
            limit,
            offset,
        },
    });
}

function getCaseActivityStatistics(caseId) {
    return axios.get(`/activity/case/${caseId}/statistics`);
}

export default {
    getCaseActivity,
    getCaseActivityStatistics,
};
