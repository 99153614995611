export const getTextColorByBackground = (color) => {
    // check the category hex color and check if the text should be white or black depending on colour theory.
    if (!color) {
        return 'white';
    }
    const rgb = hexToRgb(color);
    const red = rgb.r;
    const green = rgb.g;
    const blue = rgb.b;
    const hex = color.slice(1, 7);
    if (red * 0.299 + green * 0.587 + blue * 0.114 > 120) {
        // text on light background
        return 'black';
    }
    // text on dark background
    return 'white';
};

export const hexToRgb = (color) => {
    const result = /^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i.exec(color.slice(0, 7));
    if (!result) return null;
    return {
        r: Number.parseInt(result[1], 16),
        g: Number.parseInt(result[2], 16),
        b: Number.parseInt(result[3], 16),
    };
};

export const generateColor = (str = 'random') => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        color += ('00' + value.toString(16)).slice(-2);
    }
    return color;
};

export const darkenHexColor = (hex, amount) => {
    // Ensure the hex color starts with #
    if (hex.charAt(0) === '#') {
        hex = hex.slice(1);
    }

    // Check if the hex color is valid
    if (hex.length !== 3 && hex.length !== 6) {
        throw new Error('Invalid HEX color format.');
    }

    // Convert the hex color to an RGB array
    let rgb = hex.length === 3 ? hex.match(/./g) : hex.match(/../g);
    rgb = rgb.map((c) => Number.parseInt(c.length === 1 ? c + c : c, 16));

    // Darken the color by the specified amount
    for (let i = 0; i < 3; i++) {
        rgb[i] = Math.max(0, rgb[i] - amount);
    }

    // Convert the RGB array back to a hex color
    const darkerHex = rgb.map((c) => c.toString(16).padStart(2, '0')).join('');

    return '#' + darkerHex;
};

export const isValidHexCode = (hexCode) => {
    const regex = /^#([\dA-Fa-f]{3}){1,2}$/;
    return regex.test(hexCode);
};

export function convertColorToRgb(color) {
    switch (true) {
        case isValidHexCode(color): {
            return hexToRgb(color);
        }
        case isRgbColor(color): {
            return color;
        }
        default: {
            console.log('Invalid color format');
            return 'rgb(0,0,0)';
        }
    }
}

export function isRgbColor(rgb) {
    const regex = /^rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)$/i;
    return regex.test(rgb);
}
export function opacityOfColor({ color, opacity }) {
    const rgb = convertColorToRgb(color);
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
}
