module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "templateView": {
          "noTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No templates found in the language this queue has"])},
          "addGotoTempl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add by going to the template manager."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
        }
      },
      "sv": {
        "templateView": {
          "noTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga mallar hittades i det språk denna kö har"])},
          "addGotoTempl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till genom att gå till mall-hanteringen."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"templateView":{"noTemplates":"No templates found in the language this queue has","addGotoTempl":"Add by going to the template manager.","search":"Search"}},"sv":{"templateView":{"noTemplates":"Inga mallar hittades i det språk denna kö har","addGotoTempl":"Lägg till genom att gå till mall-hanteringen.","search":"Sök"}}}')
  delete Component.options._Ctor
  
}
