<template>
    <section v-if="isAdmin">
        <v-btn
            v-if="options.length > 0"
            ref="menuButton"
            fab
            color="white"
            elevation="0"
            height="30"
            width="30"
            @mouseover="(e) => mouseOver(true, e)"
            @mouseout="(e) => mouseOver(false, e)"
            @click="toggleMenu"
        >
            <v-icon size="18" :color="menuIconColor">mdi-dots-horizontal</v-icon>
        </v-btn>

        <v-menu
            v-if="menu"
            v-model="menu"
            :close-on-content-click="false"
            :activator="$refs.menuButton"
            offset-y
            elevation="0"
        >
            <v-list>
                <div v-for="(option, index) of options" :key="index" class="item" @click="clickButton(option.value)">
                    <v-icon size="16" class="mr-2">{{ option.icon }}</v-icon>
                    <v-list-item-title class="medium-heading">{{ option.name }}</v-list-item-title>
                </div>
            </v-list>
        </v-menu>
    </section>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import { userRoles } from '@/enums/roles.enums';

    const optionsEnums = {
        DELETE: 'delete',
    };

    export default {
        name: 'AdminMenu',

        props: {
            item: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                menu: false,
                hover: false,
                options: [
                    {
                        icon: 'mdi-trash-can-outline',
                        name: this.$t('adminMenu.delete'),
                        value: optionsEnums.DELETE,
                    },
                ],
            };
        },

        computed: {
            ...mapGetters({
                roles: 'Auth/getRoles',
            }),

            isAdmin() {
                return (
                    this.roles.includes(userRoles.GROUP_ADMIN) ||
                    this.roles.includes(userRoles.SUPER_ADMIN) ||
                    this.roles.includes(userRoles.DEVELOPER)
                );
            },

            menuIconColor() {
                return this.hover ? 'var(--v-gray4-base)' : 'var(--v-gray1-base)';
            },
        },

        methods: {
            ...mapActions({
                deleteComment: 'Comments/deleteComment',
            }),

            toggleMenu() {
                this.menu = !this.menu;
            },

            async clickButton(option) {
                this.menu = false;

                if (option === optionsEnums.DELETE) {
                    const { confirmed } = await this.$global.dialogs.showConfirmationDialog({
                        title: this.$t('adminMenu.deletionTitle'),
                        message: this.$t('adminMenu.deletionMessage'),
                        confirmText: this.$t('global.btn.confirm'),
                        declineText: this.$t('global.btn.cancel'),
                    });

                    if (!confirmed) {
                        return;
                    }

                    this.deleteComment(this.item.id);
                }
            },

            mouseOver(value) {
                this.hover = value;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .medium-heading {
        font-size: 12px;
        font-weight: 400;
        color: var(--v-gray4-base);
    }

    .item {
        display: flex;
        align-items: center;
        padding: 0 12px;
        padding-right: 18px;
        cursor: pointer;
        height: 42px;
        transition: background-color 0.1s ease-in-out;
    }

    .item:hover {
        background-color: var(--v-gray3-base);
    }
</style>

<i18n lang="json">
{
    "en": {
        "adminMenu": {
            "delete": "Delete",
            "deletionTitle": "Deletion of comment",
            "deletionMessage": "Are you sure you want to delete this comment?",
            "deleteError": "Error deleting comment"
        }
    },
    "sv": {
        "adminMenu": {
            "delete": "Radera",
            "deletionTitle": "Radera kommentar",
            "deletionMessage": "Är du säker på att du vill radera denna kommentar?",
            "deleteError": "Fel vid radering av kommentar"
        }
    }
}
</i18n>
