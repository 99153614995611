module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "recordings": {
          "dateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date / Time"])},
          "customerId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer ID"])},
          "replay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playback - Visit website"])},
          "noWebRecordings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playback - Visit website"])}
        }
      },
      "sv": {
        "recordings": {
          "dateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum / Tid"])},
          "customerId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kund-ID"])},
          "replay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppspelning - Besök på hemsida"])},
          "noWebRecordings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppspelning - Besök på hemsida"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"recordings":{"dateTime":"Date / Time","customerId":"Customer ID","replay":"Playback - Visit website","noWebRecordings":"Playback - Visit website"}},"sv":{"recordings":{"dateTime":"Datum / Tid","customerId":"Kund-ID","replay":"Uppspelning - Besök på hemsida","noWebRecordings":"Uppspelning - Besök på hemsida"}}}')
  delete Component.options._Ctor
  
}
