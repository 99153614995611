import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex",class:_vm.getPositionClass(_vm.position)},[(_vm.item.attachment)?_c(VBtn,{staticClass:"pa-0 mx-0 nohover",style:({
            height: _vm.listLayout.value == 'big' ? '36px' : '20px',
            width: _vm.listLayout.value == 'big' ? '36px' : '20px',
        }),attrs:{"fab":"","small":"","color":_vm.item.unreadMessages > 0 ? 'gray3' : 'gray5',"elevation":"0"}},[_c(VIcon,{staticClass:"ma-0",attrs:{"size":_vm.listLayout.value == 'big' ? 16 : 16,"color":"gray2"}},[_vm._v("mdi-attachment")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }