<!-- Example Usage:
        <SimpleTooltip tooltipText="This is a tooltip" right>
            <button>Hover me</button>
        </SimpleTooltip>
    -->

<template>
    <div>
        <v-tooltip
            v-if="!disabled && enabled"
            :right="right"
            :left="left"
            :top="top"
            :bottom="bottom"
            :open-delay="openDelay"
            :close-delay="closeDelay"
            :max-width="maxWidth"
            :content-class="white ? 'white-tooltip' : ''"
            :z-index="9999"
        >
            <template #activator="{ on }">
                <div class="text-truncate" v-on="on">
                    <slot></slot>
                </div>
            </template>
            <div v-if="heading" class="text-h6 text-truncate">{{ heading }}</div>
            <div>{{ tooltipText }}</div>
        </v-tooltip>
        <slot v-else></slot>
    </div>
</template>

<script>
    export default {
        props: {
            tooltipText: {
                type: String,
                required: true,
            },
            heading: {
                type: String,
                default: '',
            },
            right: {
                type: Boolean,
                default: false,
            },
            left: {
                type: Boolean,
                default: false,
            },
            top: {
                type: Boolean,
                default: false,
            },
            bottom: {
                type: Boolean,
                default: false,
            },
            openDelay: {
                type: Number,
                default: 300,
            },
            closeDelay: {
                type: Number,
                default: 100,
            },
            maxWidth: {
                type: Number,
                default: 300,
            },
            enabled: {
                type: Boolean,
                default: true,
            },
            white: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
<style scoped>
    .white-tooltip {
        background-color: #ffffff;
        color: var(--v-gray4-base);
        opacity: 1;
        box-shadow:
            rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
            rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
    }
</style>
