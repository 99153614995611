module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "integrationLinks": {
          "notValidSsn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The social security number of this member is not valid."])}
        }
      },
      "sv": {
        "integrationLinks": {
          "notValidSsn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnumret på denna medlem är inte ett giltigt personnummer."])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"integrationLinks":{"notValidSsn":"The social security number of this member is not valid."}},"sv":{"integrationLinks":{"notValidSsn":"Personnumret på denna medlem är inte ett giltigt personnummer."}}}')
  delete Component.options._Ctor
  
}
