module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "listView": {
          "noCasesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are currently no cases to display with the current filters."])},
          "noCasesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please go up to the left to change the filters to see if you can find the case you are looking for."])},
          "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign"])},
          "dateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date / Time"])},
          "forYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For You"])},
          "noOutcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No outcome"])},
          "assignCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to assign the case to yourself and open it?"])},
          "couldNotOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not open case"])},
          "noLiveUpdates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No live updates while searching in the list"])}
        }
      },
      "sv": {
        "listView": {
          "noCasesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det finns för närvarande inga ärenden att visa med de aktuella filtren."])},
          "noCasesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå upp till vänster för att ändra filtren för att se om du kan hitta ärendet du letar efter."])},
          "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilldela"])},
          "dateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum / Tid"])},
          "forYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Till dig"])},
          "noOutcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget utfall"])},
          "assignCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vill du tilldela ärendet till dig och öppna det?"])},
          "couldNotOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde inte öppna ärende"])},
          "noLiveUpdates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga liveuppdateringar vid sökning i listan"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"listView":{"noCasesTitle":"There are currently no cases to display with the current filters.","noCasesText":"Please go up to the left to change the filters to see if you can find the case you are looking for.","assign":"Assign","dateTime":"Date / Time","forYou":"For You","noOutcome":"No outcome","assignCase":"Do you want to assign the case to yourself and open it?","couldNotOpen":"Could not open case","noLiveUpdates":"No live updates while searching in the list"}},"sv":{"listView":{"noCasesTitle":"Det finns för närvarande inga ärenden att visa med de aktuella filtren.","noCasesText":"Gå upp till vänster för att ändra filtren för att se om du kan hitta ärendet du letar efter.","assign":"Tilldela","dateTime":"Datum / Tid","forYou":"Till dig","noOutcome":"Inget utfall","assignCase":"Vill du tilldela ärendet till dig och öppna det?","couldNotOpen":"Kunde inte öppna ärende","noLiveUpdates":"Inga liveuppdateringar vid sökning i listan"}}}')
  delete Component.options._Ctor
  
}
