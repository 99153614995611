import statistics from '@/api/statistics/statistics';
import { format, subDays } from 'date-fns';
import { downloadFile } from '@/helpers/files/files.helper';
import i18n from '../../i18n';

// State object
const state = {
    // compare statistics search fields
    compareViewFrom: format(subDays(new Date(), 7), 'yyyy-MM-dd'),
    compareViewTo: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
    compareViewType: null,
    compareViewGroupId: null,
    dateIntervals: [],
    queues: [],
    jobs: [],
    jobFetchIndex: -1,
};
// Getter functions
const getters = {};
// Actions
const actions = {
    changeStateProp({ commit }, payload) {
        commit('changeStateProp', payload);
    },

    async getActiveCalls(_, payload) {
        try {
            const res = await statistics.getActiveCalls(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getAllStatistics(_, payload) {
        try {
            const res = await statistics.getAllStatistics(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getCMOTStatistics(_, payload) {
        try {
            const res = await statistics.getCMOTStatistics(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getAllDateIntervals({ commit }, payload) {
        try {
            const res = await statistics.getAllDateIntervals(payload);
            commit('SET_DATE_INTERVALS', res.data);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getTimelineStatistics(_, payload) {
        try {
            const res = await statistics.getTimelineStatistics(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async aggregatedActivityStatistics(_, payload) {
        try {
            const res = await statistics.aggregatedActivityStatistics(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getCallPerformanceStatistics(_, payload) {
        try {
            const res = await statistics.getCallPerformanceStatistics(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getBigTableCallPerformanceStatistics(_, payload) {
        try {
            const res = await statistics.getBigTableCallPerformanceStatistics(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async callCDRStatistics(_, payload) {
        try {
            const res = await statistics.callCDRStatistics(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getUserStatisticsByGroup(_, payload) {
        try {
            const res = await statistics.getUserStatisticsByGroup(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getC1QueueServiceStatistics(_, payload) {
        try {
            const res = await statistics.getC1QueueServiceStatistics(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async exportStatistics({ dispatch }, payload) {
        try {
            const res = await statistics.exportStatistics(payload);
            await dispatch('getExportJobs');
            this._vm.$toasted.show(i18n.t('statisticsStore.workSaved'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getExportJobs({ commit }, payload) {
        try {
            const res = await statistics.getExportJobs(payload);
            commit('SET_JOBS', res.data);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async deleteJob({ dispatch }, payload) {
        try {
            const res = await statistics.deleteJob(payload);
            await dispatch('getExportJobs');
            this._vm.$toasted.show(i18n.t('statisticsStore.workDeleted'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getUserGroupCompare(_, payload) {
        try {
            const res = await statistics.getUserGroupCompare(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getClientsCallStatistics(_, payload) {
        try {
            const res = await statistics.getClientsCallStatistics(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getJobData(_, payload) {
        const { data } = await statistics.getJobData(payload);
        return data;
    },
    async getC1QueueCallsStats(_, payload) {
        try {
            const res = await statistics.getC1QueueCallsStats(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getUserC1LogIns(_, payload) {
        try {
            const res = await statistics.getUserC1LogIns(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getC1WorkingTimeStatistics(_, payload) {
        try {
            const res = await statistics.getC1WorkingTimeStatistics(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async getQueuesAdvanced({ commit }, payload) {
        try {
            const res = await statistics.getQueuesAdvanced(payload);
            const queues = res.data.map((queue) => {
                return {
                    ...queue,
                    value: queue.ID,
                };
            });
            commit('SET_QUEUES', queues);
            return queues;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), {
                icon: 'mdi-cancel',
                type: 'error',
            });

            throw error;
        }
    },
    async downloadJobData({ dispatch, commit }, { jobId, index }) {
        commit('SET_JOB_FETCH_INDEX', index);
        try {
            const result = await dispatch('getJobData', jobId);
            if (result.status === 200) {
                downloadFile(result.data.contentBytes, result.data.contentType, result.data.name);
            }
        } catch (error) {
            this._vm.$toasted.show(i18n.t('statisticsStore.couldNotGetStatistics'), {
                icon: 'cancel',
                type: 'error',
                duration: 3000,
            });
        } finally {
            commit('SET_JOB_FETCH_INDEX', -1);
        }
    },
};
// Mutations
const mutations = {
    SET_JOB_FETCH_INDEX(state, payload) {
        state.jobFetchIndex = payload;
    },
    changeStateProp(state, obj) {
        const [[key, value]] = Object.entries(obj);
        if (state[key] == value) return;
        state[key] = value;
    },
    SET_DATE_INTERVALS(state, payload) {
        state.dateIntervals = payload;
    },
    SET_QUEUES(state, payload) {
        state.queues = payload;
    },
    SET_JOBS(state, payload) {
        state.jobs = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
