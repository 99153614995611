<template>
    <section class="comment-actions">
        <v-chip
            v-if="active.includes('answer')"
            :outlined="item.answerPressed ? false : true"
            :disabled="isReadOnly"
            @click="executeAnswerButton(item)"
        >
            <span class="text-truncate chip-action" cy-data="respond">
                {{ $t('caseComments.answer') }}
            </span>
        </v-chip>

        <v-chip
            v-if="active.includes('answer-sms')"
            :outlined="item.answerPressed ? false : true"
            :disabled="isReadOnly"
            @click="executeAnswerSmsButton(item)"
        >
            <span class="text-truncate chip-action" cy-data="respond">
                {{ $t('caseComments.answer') }}
            </span>
        </v-chip>

        <v-chip
            v-if="active.includes('answer-all')"
            :outlined="item.answerAllPressed ? false : true"
            :disabled="isReadOnly"
            @click="executeAnswerAllButton(item)"
        >
            <span class="text-truncate chip-action">
                {{ $t('caseComments.answerAll') }}
            </span>
        </v-chip>
        <v-chip
            v-if="active.includes('forward')"
            outlined
            :disabled="isReadOnly || isForwarding"
            @click="executeForwardButton({ caseId: item.caseId, commentId: item.id })"
        >
            <span class="text-truncate chip-action">
                {{ $t('caseComments.forward') }}
            </span>
        </v-chip>

        <ChatGPT v-if="active.includes('chat-gpt')" :context="'comment'" :commentId="item.id" :disabled="isReadOnly" />
    </section>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import { getTextColorByBackground } from '@/utils/Colors';
    import ChatGPT from '@/components/Global/ChatGPT/ChatGPT.vue';

    export default {
        components: {
            ChatGPT,
        },
        props: {
            item: {
                type: Object,
                default: () => {},
            },
            active: {
                type: Array,
                default: () => [],
            },
        },

        computed: {
            ...mapGetters({
                isReadOnly: 'Cases/isReadOnly',
            }),
            ...mapState({
                repliedComment: (state) => state.Comments.repliedComment,
                isForwarding: (state) => state.Comments.isForwarding,
            }),
        },

        methods: {
            ...mapActions('Forwarder', [
                'executeAnswerButton',
                'executeAnswerSmsButton',
                'executeAnswerAllButton',
                'executeForwardButton',
            ]),

            getTextColorByBackground,
        },
    };
</script>

<style scoped lang="scss">
    .chip-action {
        color: var(--v-gray2-base);
        font-size: 12px;
    }

    .comment-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }
</style>

<i18n lang="json">
{
    "en": {
        "caseComments": {
            "answer": "Answer",
            "answerAll": "Answer all",
            "forward": "Forward"
        }
    },

    "sv": {
        "caseComments": {
            "answer": "Svara",
            "answerAll": "Svara alla",
            "forward": "Vidarebefordra"
        }
    }
}
</i18n>
