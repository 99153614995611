import sms from '@/api/sms/sms';
import i18n from '../../i18n';

// State object
const state = {
    smsCampaigns: [],
    sending: false,
    selectedCampaign: null,
    campaignResults: [],
    campaignJobItemsActive: [],
    campaignJobItemsFailed: [],
};
// Getter functions
const getters = {};

const actions = {
    SOCKET_campaign({ commit }, data) {
        if (data.status) {
            commit('UPDATE_CAMPAIGN_STATUS', { campaignId: data.campaignId, status: data.status });
        }
    },

    async createSMSCampaign({ dispatch, commit, state }, data) {
        try {
            commit('SET_SENDING', true);
            const response = await sms.createSMSCampaign(data);
            commit('SET_SENDING', false);
            dispatch('getSMSCampaigns', { limit: state.smsCampaigns.length + 1, page: 1 });

            return response.data;
        } catch (error) {
            commit('SET_SENDING', false);
            console.error('Error creating SMS campaign:', error);
            return false;
        }
    },

    async getSMSCampaigns({ dispatch, commit }, data) {
        try {
            const { page } = data;
            const response = await sms.getSMSCampaigns(data);

            dispatch('unsubscribe');

            if (page === 1) {
                commit('SET_SMS_CAMPAIGNS', response.data);
            } else {
                commit('SET_SMS_CAMPAIGNS', state.smsCampaigns.concat(response.data));
            }

            dispatch('subscribe');
        } catch (error) {
            console.error('Error getting SMS campaigns:', error);
        }
    },

    async getCampignResults({ state, commit }, { page, limit }) {
        try {
            const campaignId = state.selectedCampaign.campaignId;
            const response = await sms.getCampignResults({ page, limit, campaignId });

            commit('SET_CAMPAIGN_RESULTS', response.data);

            return response.data;
        } catch (error) {
            console.error('Error getting SMS campaign results:', error);
        }
    },

    async getCampaignJobItemsActive({ commit }, { page, limit }) {
        try {
            const campaignId = state.selectedCampaign.campaignId;
            const response = await sms.getCampaignJobItems({
                campaignId,
                status: ['COMPLETED', 'PENDING', 'IN_PROGRESS'],
                page,
                limit,
            });

            if (page === 1) {
                commit('SET_CAMPAIGN_JOB_ITEMS_ACTIVE', response.data);
            } else {
                commit('SET_CAMPAIGN_JOB_ITEMS_ACTIVE', state.campaignJobItemsActive.concat(response.data));
            }

            return response.data;
        } catch (error) {
            console.error('Error getting SMS campaign job items:', error);
        }
    },

    async getCampaignJobItemsFailed({ commit }, { page, limit }) {
        try {
            const campaignId = state.selectedCampaign.campaignId;
            const response = await sms.getCampaignJobItems({ campaignId, status: ['FAILED'], page, limit });

            if (page === 1) {
                commit('SET_CAMPAIGN_JOB_ITEMS_FAILED', response.data);
            } else {
                commit('SET_CAMPAIGN_JOB_ITEMS_FAILED', state.campaignJobItemsFailed.concat(response.data));
            }

            return response.data;
        } catch (error) {
            console.error('Error getting SMS campaign job items:', error);
        }
    },

    setSelectedCampaign({ commit }, campaign) {
        commit('SET_SELECTED_CAMPAIGN', campaign);
    },

    setCampaignResults({ commit }, results) {
        commit('SET_CAMPAIGN_RESULTS', results);
    },

    setCampaignJobItemsActive({ commit }, jobItems) {
        commit('SET_CAMPAIGN_JOB_ITEMS_ACTIVE', jobItems);
    },

    setCampaignJobItemsFailed({ commit }, jobItems) {
        commit('SET_CAMPAIGN_JOB_ITEMS_FAILED', jobItems);
    },

    subscribe({ state }) {
        if (state.smsCampaigns.length === 0) {
            return;
        }

        this._vm.$socket.emit(
            'subscribe',
            state.smsCampaigns.map((campaign) => campaign.campaignId),
        );
    },

    unsubscribe({ state }) {
        if (state.smsCampaigns.length === 0) {
            return;
        }

        this._vm.$socket.emit(
            'unsubscribe',
            state.smsCampaigns.map((campaign) => campaign.campaignId),
        );
    },
};

// Mutations
const mutations = {
    SET_SMS_CAMPAIGNS(state, campaigns) {
        state.smsCampaigns = campaigns;
    },

    SET_SENDING(state, sending) {
        state.sending = sending;
    },

    UPDATE_CAMPAIGN_STATUS(state, { campaignId, status }) {
        const campaign = state.smsCampaigns.find((campaign) => campaign.campaignId === campaignId);

        if (campaign) {
            campaign.status = status;
        }
    },

    SET_SELECTED_CAMPAIGN(state, campaign) {
        state.selectedCampaign = campaign;
    },

    SET_CAMPAIGN_RESULTS(state, results) {
        state.campaignResults = results;
    },

    SET_CAMPAIGN_JOB_ITEMS_ACTIVE(state, jobItems) {
        state.campaignJobItemsActive = jobItems;
    },

    SET_CAMPAIGN_JOB_ITEMS_FAILED(state, jobItems) {
        state.campaignJobItemsFailed = jobItems;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
