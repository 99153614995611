import api from '@/api/smartsearch/smartsearch.js';
import prompts from '@/api/telephony/prompts';

const state = {
    sidebar: {
        conversations: [],

        prompts: [],
        selectedPrompt: null,

        error: null,
    },
    main: {
        selectedConversationId: null,
        selectedConversation: [],

        loading: false,

        error: null,
    },
};

const getters = {};

const actions = {
    /**
     * Opens the Smart Search dialog.
     *
     * This method asynchronously shows a dialog with the Smart Search component.
     * The dialog is displayed in full size.
     *
     * @returns {Promise<void>} A promise that resolves when the dialog is shown.
     */
    async openSmartSearch({ commit }) {
        commit('RESET_ALL_ERRORS');
        await this._vm.$global.dialogs.showDialog({
            component: () => import('@/components/SmartSearch/Components/Main.vue'),
            size: 'full',
        });
    },
    async getPromptsByContext({ commit }) {
        try {
            const { data } = await api.getPromptsByContext('smart_search');
            commit('SET_PROMPTS', data.result);
            const [result] = data.result;
            if (result && !state.sidebar.selectedPrompt) {
                commit('SET_PROMPT', result.id);
            }
            if (!result) {
                commit('SET_ERROR', {
                    text: 'Admin needs to create prompts for AI assistant',
                    context: 'sidebar',
                });
            }
        } catch (error) {
            commit('SET_ERROR', {
                text: 'Error fetching prompts',
                context: 'main',
                error: error?.response?.data || error,
            });
        }
    },

    async createConversation({ dispatch, commit, state }, topic) {
        try {
            if (!state.sidebar.selectedPrompt) {
                commit('SET_ERROR', {
                    text: 'Please select a prompt',
                    context: 'sidebar',
                });
                return;
            }
            const conversation = { topic, promptId: state.sidebar.selectedPrompt };
            const { data } = await api.createConversation(conversation);
            await dispatch('getConversation', data.result);
            await dispatch('getConversations', { page: 1, limit: 10, search: '' });
        } catch (error) {
            commit('SET_ERROR', {
                text: 'Error creating conversation',
                context: 'sidebar',
                error: error?.response?.data || error,
            });
        }
    },
    /**
     * Fetches conversations from the API and commits them to the store.
     *
     * @param {Object} context - The Vuex action context.
     * @param {Function} context.commit - The Vuex commit function.
     * @param {Object} payload - The payload object.
     * @param {number} payload.page - The current page number.
     * @param {number} payload.limit - The number of items per page.
     * @param {string} payload.search - The search query string.
     *
     * @returns {Promise<void>} - A promise that resolves when the conversations are fetched and committed.
     *
     * @throws {Error} - Throws an error if the API request fails.
     */
    async getConversations({ commit, state, dispatch }, { page, limit, search }) {
        try {
            const offset = (page - 1) * limit;
            const { data } = await api.getConversations(offset, limit, search);

            if (page === 1) {
                commit('SET_CONVERSATIONS', data.result);
            } else {
                commit('SET_CONVERSATIONS', state.sidebar.conversations.concat(data.result));
            }
            /* If the result is not empty, set default the first conversation */
            if (data?.result?.length > 0 && offset === 0) {
                commit('SET_CONVERSATION', { conversation: [data.result[0]], item: data.result[0] });
                dispatch('getConversation', data.result[0]);
                commit('SET_PROMPT', data.result[0].promptId);
            }
        } catch (error) {
            commit('SET_ERROR', {
                text: 'Error fetching conversations',
                context: 'sidebar',
                error: error?.response?.data || error,
            });
        }
    },
    async getConversation({ commit }, item) {
        try {
            const { data } = await api.getConversation(item.id);
            commit('SET_CONVERSATION', { conversation: data.result, item });
        } catch (error) {
            commit('SET_ERROR', {
                text: 'Error fetching conversation',
                context: 'main',
                error: error?.response?.data || error,
            });
        }
    },
    /**
     * Saves a conversation item and updates the conversation.
     *
     * @param {Object} context - The Vuex action context.
     * @param {Function} context.commit - Vuex commit function.
     * @param {Object} context.state - Vuex state object.
     * @param {string} text - The text of the conversation item to save.
     * @returns {Promise<void>} - A promise that resolves when the conversation item is saved.
     */
    async saveConversationItem({ commit, state }, text) {
        try {
            commit('TOGGLE_LOADING');
            for await (const chunk of api.saveConversationItem(
                state.main.selectedConversationId,
                text,
                state.sidebar.selectedPrompt,
            )) {
                commit('PUSH_CONVERSATION_ITEM', JSON.parse(chunk)[0]);
            }
            commit('TOGGLE_LOADING');
        } catch (error) {
            commit('SET_ERROR', {
                text: 'Error saving conversation item',
                context: 'main',
                error: error?.response?.data || error,
            });
            commit('TOGGLE_LOADING');
        }
    },
};

const mutations = {
    SET_ERROR(state, error) {
        if (error.context === 'main') state.main.error = error;
        else state.sidebar.error = error;
    },
    SET_CONVERSATIONS(state, conversations) {
        state.sidebar.conversations = conversations;
    },
    SET_CONVERSATION(state, { conversation, item }) {
        state.main.selectedConversation = conversation;
        state.main.selectedConversationId = item.id;
        state.sidebar.selectedPrompt = item.promptId;
    },
    PUSH_CONVERSATION_ITEM(state, item) {
        state.main.selectedConversation.push(item);
    },

    SET_PROMPTS(state, prompts) {
        state.sidebar.prompts = prompts;
    },
    SET_PROMPT(state, promptId) {
        state.sidebar.selectedPrompt = promptId;
    },
    TOGGLE_LOADING(state) {
        state.main.loading = !state.main.loading;
    },
    RESET_ALL_ERRORS(state) {
        state.sidebar.error = null;
        state.main.error = null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
