import axios from 'axios';

function getHistory(q) {
    return axios.get(`/chats/history/${q}`);
}
function getChatStatus(caseId) {
    return axios.get(`/chats/${caseId}/status`);
}

function answerChat(payload) {
    return axios.post(`/chatmanager/answer-case`, payload);
}

export default {
    getHistory,
    getChatStatus,
    answerChat,
};
