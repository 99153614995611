<template>
    <div>
        <v-dialog
            v-model="vmodelDialog"
            :width="dialogWidth"
            style="box-shadow: 20px black; z-index: 999999 !important"
            @click:outside="close"
        >
            <div
                class="d-flex flex-column justify-space-between rounded-lg pa-6"
                style="background-color: white; min-height: 200px"
            >
                <div class="d-flex justify-space-between pb-5">
                    <div>
                        <span
                            v-dompurify-html="title || $t('confirmationDialogue.delete')"
                            style="font-size: 25px; font-weight: 500; color: var(--v-gray4-base)"
                        ></span>
                        <div class="mt-3">
                            <div v-if="!body">
                                <slot name="body"></slot>
                            </div>
                            <div v-else v-dompurify-html="body || $t('confirmationDialogue.delete')"></div>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-start">
                        <v-btn
                            id="confirmCloseBtn"
                            depressed
                            fab
                            width="40px"
                            height="40px"
                            style="background-color: transparent"
                            @click="executeClick('cancel')"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                </div>
                <div class="d-flex flex-row justify-end">
                    <div v-for="(btn, b) in confirmationButtons" :key="b">
                        <v-btn
                            :id="btn.id"
                            depressed
                            class="ml-2"
                            :class="getButtonClass(btn.id, upClicked)"
                            @click="executeClick(btn.value)"
                        >
                            <span :cy-value="btn.value">{{ getTextByValue(btn.value) }}</span>
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-dialog>
        <slot name="activator" :on="open"></slot>
    </div>
</template>
<script>
    import { getText } from '@tiptap/core';

    export default {
        props: {
            title: {
                type: String,
            },
            body: {
                type: String,
            },
            dialogStatus: {
                type: Boolean,
                default: false,
            },
            thirdOption: {
                type: String,
                default: '',
            },
            fourthOption: {
                type: String,
                default: '',
            },
            dialogWidth: {
                type: String,
                default: '750px',
            },
            normalSizeOnThird: {
                type: Boolean,
                default: false,
            },
            normalSizeOnFourth: {
                type: Boolean,
                default: false,
            },
            handleDialog: {
                type: Boolean,
                default: false,
            },
            value: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                dialog: false,
                titleText: '',
                bodyText: '',
                selectedButtonId: 1,
                selectedButton: null,
                oldStyle: {},
                upClicked: false,
            };
        },
        computed: {
            vmodelDialog: {
                get() {
                    return this.handleDialog ? this.dialogStatus : this.dialog;
                },
                set(val) {
                    if (!this.handleDialog) {
                        this.$emit('ConfirmResult', false);
                    }
                },
            },
        },

        watch: {
            dialogStatus: {
                deep: true,
                immediate: true,
                handler(newVal) {
                    this.dialog = newVal;
                },
            },
            dialog(newVal) {
                if (!newVal) {
                    window.removeEventListener('keydown', this.keyDown);
                    this.selectedButtonId = 1;
                    this.upClicked = false;
                } else {
                    window.addEventListener('keydown', this.keyDown);
                    this.setInitialButtons();
                }
                this.$emit('input', newVal);
            },
            selectedButtonId(newVal, oldVal) {
                let maxBtn = 2;
                if (this.fourthOption.length !== 0) maxBtn++;
                if (this.thirdOption.length !== 0) maxBtn++;
                if (!this.upClicked) {
                    if (newVal > maxBtn) this.selectedButtonId = 1;
                    if (newVal < 1) this.selectedButtonId = maxBtn;
                }
            },
            value(newVal) {
                this.dialog = newVal;
            },
        },
        created() {
            this.setConfirmationButtons();
            this.setInitialButtons();
        },
        destroyed() {
            window.removeEventListener('keydown', this.keyDown);
        },
        methods: {
            open() {
                this.dialog = true;
            },
            close() {
                this.dialog = false;
            },
            getTextByValue(value) {
                switch (value) {
                    case 'confirm': {
                        return this.$t('global.yes');
                    }
                    case 'cancel': {
                        return this.$t('global.btn.cancel');
                    }
                    case 'ThirdOption': {
                        return this.thirdOption;
                    }
                    case 'FourthOption': {
                        return this.fourthOption;
                    }
                    default: {
                        return '';
                    }
                }
            },
            getButtonClass(buttonId, upClicked) {
                const id = +buttonId.replace('confirmBtn', '');
                if (upClicked || this.selectedButtonId !== id) {
                    return 'buttonUnclicked';
                }
                return 'buttonClicked';
            },
            setInitialButtons() {
                if (this.confirmationButtons.length > 2) {
                    this.confirmationButtons.splice(0, this.confirmationButtons.length - 2);
                }
                if (this.thirdOption?.length > 0) {
                    const object = {
                        id: 'confirmBtn3',
                        value: 'ThirdOption',
                    };
                    this.confirmationButtons.splice(1, 0, object);
                }
                if (this.fourthOption?.length > 0) {
                    const object = {
                        id: 'confirmBtn4',
                        value: 'FourthOption',
                    };
                    this.confirmationButtons.splice(1, 0, object);
                }
            },
            setConfirmationButtons() {
                this.confirmationButtons = [
                    {
                        id: 'confirmBtn2',
                        value: 'cancel',
                    },
                    {
                        id: 'confirmBtn1',
                        value: 'confirm',
                    },
                ];
            },
            executeClick(type) {
                this.dialog = false;
                switch (type) {
                    case 'cancel': {
                        this.$emit('ConfirmResult', false);
                        break;
                    }
                    case 'confirm': {
                        this.$emit('ConfirmResult', true);
                        break;
                    }
                    case 'FourthOption':
                    case 'ThirdOption': {
                        this.$emit(type, true);
                        break;
                    }
                    default: {
                        break;
                    }
                }
            },
            keyDown(event) {
                if (event.key == 'Escape') {
                    this.executeClick('cancel');
                }
                if (!this.dialog) return;
                if (event.key == 'ArrowUp') {
                    this.upClicked = true;
                    this.selectedButtonId = 0;
                    this.setFocus('confirmCloseBtn');
                }
                if (event.key == 'ArrowDown') {
                    this.selectedButtonId = 1;
                    this.removeUpClicked();
                }
                if (event.key == 'ArrowLeft') {
                    this.selectedButtonId++;
                    this.removeUpClicked();
                }
                if (event.key == 'ArrowRight' || event.key == 'Tab') {
                    this.selectedButtonId--;
                    this.removeUpClicked();
                }
                if (event.key == 'Enter') {
                    // click the selected button
                    if (this.upClicked) {
                        this.executeClick('cancel');
                        return;
                    }
                    switch (this.selectedButtonId) {
                        case 1: {
                            this.executeClick('confirm');
                            break;
                        }
                        case 2: {
                            this.executeClick('cancel');
                            break;
                        }
                        case 3: {
                            this.executeClick('ThirdOption');
                            break;
                        }
                        case 4: {
                            this.executeClick('FourthOption');
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                }
            },
            removeUpClicked() {
                const btn = document.querySelector('#confirmCloseBtn');
                if (btn) {
                    btn.blur();
                }
                this.upClicked = false;
            },
            setFocus(id) {
                const btn = document.getElementById(id);
                if (btn) {
                    btn.focus();
                }
            },
            clickButton(id) {
                const btn = document.getElementById(id);
                if (btn) {
                    btn.click();
                }
            },
        },
    };
</script>
<style scoped>
    .buttonClicked {
        color: white;
        border: none;
    }

    .buttonClicked.theme--light.v-btn.v-btn--has-bg {
        background-color: black;
    }

    .buttonUnclicked {
        color: black;
        border: 2px solid black;
    }

    .buttonUnclicked.theme--light.v-btn.v-btn--has-bg {
        background-color: transparent;
    }
</style>
<i18n lang="json">
{
    "en": {
        "confirmationDialogue": {
            "delete": "Delete"
        }
    },
    "sv": {
        "confirmationDialogue": {
            "delete": "Radera"
        }
    }
}
</i18n>
