module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "createComments": {
          "external": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External"])},
          "internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal"])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
          "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
          "ccAndBcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CC and BCC"])},
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
          "blindCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blind copy"])}
        }
      },
      "sv": {
        "createComments": {
          "external": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extern"])},
          "internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intern"])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Från"])},
          "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottagare"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ämne"])},
          "ccAndBcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CC och BCC"])},
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopia"])},
          "blindCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemlig kopia"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"createComments":{"external":"External","internal":"Internal","from":"From","recipient":"Recipient","subject":"Subject","ccAndBcc":"CC and BCC","copy":"Copy","blindCopy":"Blind copy"}},"sv":{"createComments":{"external":"Extern","internal":"Intern","from":"Från","recipient":"Mottagare","subject":"Ämne","ccAndBcc":"CC och BCC","copy":"Kopia","blindCopy":"Hemlig kopia"}}}')
  delete Component.options._Ctor
  
}
