<template>
    <ExternalTasksModal>
        <template #button="{ handleClick }">
            <IntegrationButton :active="active" :integration-name="integration.name" @click="handleClick" />
        </template>
    </ExternalTasksModal>
</template>
<script>
    import ExternalTasksModal from './ExternalTasks/ExternalTasksModal.vue';
    import IntegrationButton from './IntegrationButton.vue';

    export default {
        components: { ExternalTasksModal, IntegrationButton },
        props: {
            integration: {
                type: Object,
                required: true,
            },
        },
        computed: {
            active() {
                return Boolean(this.integration.active);
            },
        },
    };
</script>
<style scoped lang="scss"></style>
<i18n lang="json">
{
    "en": {},
    "sv": {}
}
</i18n>
