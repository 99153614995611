import video from '@/api/video/video';
// State object
const state = {};
// Getter functions
const getters = {};
// Actions
const actions = {
    async sendSMS(_, payload) {
        try {
            const result = await video.sendSMS(payload);
            return [result, null];
        } catch (error) {
            console.error(error);
            return [null, error];
        }
    },

    async sendEmail(_, payload) {
        try {
            const result = await video.sendEmail(payload);
            return [result, null];
        } catch (error) {
            console.error(error);
            return [null, error];
        }
    },
};
// Mutations
const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
