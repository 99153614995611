import chat from '@/api/chat/chat';
import cases from '../../api/cases/cases';
import i18n from '../../i18n';

const state = {
    showNotification: true,
};
const getters = {};
const mutations = {
    SET_SHOW_NOTIFICATION(state, payload) {
        state.showNotification = payload;
    },
};

const actions = {
    setShowNotification({ commit }, payload) {
        commit('SET_SHOW_NOTIFICATION', payload);
    },
    getHistory(_, payload) {
        return new Promise((resolve, reject) => {
            chat.getHistory(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('chatStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    getChatStatus(_, caseId) {
        return new Promise((resolve, reject) => {
            chat.getChatStatus(caseId)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('chatStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    answerChat(_, payload) {
        return new Promise((resolve, reject) => {
            chat.answerChat(payload)
                .then((res) => {
                    resolve(res.status);
                })
                .catch((error) => {
                    this._vm.$toasted.show('Något gick fel, försök igen: ', error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
