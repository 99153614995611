/* Vue engine */
import Vue from 'vue';
import Router from 'vue-router';

/* Application config */
import cfg from '@/app/config';

// **  Styling to thirdparty apps

// ** Neccessery third party applications
import axios from 'axios';
import VueSocketIO from 'vue-socket.io';
import { io } from 'socket.io-client';

// ** Third party applications that are "nice to have"
import 'vue-dnd-zone/vue-dnd-zone.css';
import './scss/slider/slider-default.css';

// ** Global components end
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

import initSentry from './sentry';

// ** Important for authentication, please do not move.
import AuthHandler from './auth/auth.handler';
import AuthHelper from './auth/auth.helper';
import AxiosInterceptor from './interceptors/axiosClient';
import i18n from './i18n';
import App from './App.vue';

import { global } from './services/global/global.service';

// ** Import global styles
import './css/global.css';

const { shadowDirective } = require('./directives/shadowDom');

// ** Global components

// ** Buttons
const StandardButton = () => import('@/components/Main/Buttons/Standard.vue');
const PrimaryButton = () => import('@/components/Main/Buttons/Primary.vue');
const ActionButton = () => import('@/components/Main/Buttons/Action.vue');
const SecondaryActionButton = () => import('@/components/Main/Buttons/SecondaryAction.vue');
const PrimaryActionButton = () => import('@/components/Main/Buttons/PrimaryAction.vue');
const TertiaryActionButton = () => import('@/components/Main/Buttons/TertiaryAction.vue');
const TertiaryButton = () => import('@/components/Main/Buttons/Tertiary.vue');

const EmailInputSingle = () => import('@/components/Main/Selects/EmailSingle.vue');
const EmailInputMulti = () => import('@/components/Main/Selects/EmailMulti.vue');
const EmailInputSystem = () => import('@/components/Main/Selects/EmailSystem.vue');

const AddressSingle = () => import('@/components/Main/Inputs/AddressSingle.vue');

// ** Round Buttons
const RoundIconButton = () => import('@/components/Main/Buttons/RoundIcon.vue');

// ** Selects
const StandardSelect = () => import('@/components/Main/Selects/Standard.vue');
const MultiSelect = () => import('@/components/Main/Selects/Multi.vue');
const UsersSelect = () => import('@/components/Main/Selects/Users.vue');
const UserSelect = () => import('@/components/Main/Selects/User.vue');
const StatusSelect = () => import('@/components/Main/Selects/Status.vue');
const QueueSelect = () => import('@/components/Main/Selects/Queue.vue');
const QueuesSelect = () => import('@/components/Main/Selects/Queues.vue');
const TemplateSelect = () => import('@/components/Main/Selects/Template.vue');
const SignatureSelect = () => import('@/components/Main/Selects/Signature.vue');
const TimeSelect = () => import('@/components/Main/Selects/Time.vue');
const SlaSelect = () => import('@/components/Main/Selects/SLA.vue');
const ClientSelect = () => import('@/components/Main/Selects/Client.vue');
const MultiUsersSelect = () => import('@/components/Main/Selects/MultiUsers.vue');
const LanguageSelect = () => import('@/components/Main/Selects/Language.vue');
const CountrySelect = () => import('@/components/Main/Selects/Country.vue');
const DynamicSelect = () => import('@/components/Main/Selects/Dynamic.vue');
const BrandSelect = () => import('@/components/Main/Selects/Brand.vue');
const BrandsSelect = () => import('@/components/Main/Selects/Brands.vue');

// ** Inputs
const StandardInput = () => import('@/components/Main/Inputs/Standard.vue');
const SearchInput = () => import('@/components/Main/Inputs/Search.vue');
const SecondaryInput = () => import('@/components/Main/Inputs/Secondary.vue');
const PhoneNumber = () => import('@/components/Main/Inputs/PhoneNumber.vue');
const PhoneNumberPhoneWrapper = () => import('@/components/Main/Inputs/PhoneNumberPhoneWrapper.vue');
const PhoneNumberSmsWrapper = () => import('@/components/Main/Inputs/PhoneNumberSmsWrapper.vue');
const SenderNumberSelect = () => import('@/components/Main/Inputs/NumberSenderSelect.vue');

// ** Text
const TipTap = () => import('@/components/Global/TiptapEditor.vue');

// ** Previews
const SmsPreview = () => import('@/components/Main/Previews/Sms.vue');

// ** Tooltips
const Tooltip = () => import('@/components/Main/Tooltips/BasicTooltip.vue');

const TextArea = () => import('@/components/Main/Inputs/TextArea.vue');

// ** Overlays
const LoaderOverlay = () => import('@/components/Main/Overlay/LoaderOverlay.vue');

// ** Infinite List
const InfiniteList = () => import('@/components/Main/List/InfiniteList.vue');

(async () => {
    // this removes the extremely long error warning when trying to navigate to same adress
    const originalPush = Router.prototype.push;
    Router.prototype.push = function push(location) {
        return originalPush.call(this, location).catch((error) => error);
    };

    /* Axios defaults */
    axios.defaults.baseURL = cfg.customerFirstUrl + 'api/';

    /* Options for Vue Toasted Globally */
    const options = {
        router,
        position: 'bottom-center',
        duration: 5000,
        iconPack: 'mdi',
        theme: 'bubble',
        type: 'success',
        keepOnHover: true,
        singleton: true,
    };

    /** Register imported third party applications */
    import('vue-confetti').then((module) => Vue.use(module.default));
    import('vue-toasted').then((module) => Vue.use(module.default, options));
    import('vuetify-datetime-picker').then((module) => Vue.use(module.default));
    import('vue-audio-visual').then((module) => Vue.use(module.default));
    import('vue-dnd-zone').then((module) => Vue.use(module.default));
    import('vue-slider-component').then((module) => Vue.component('VueSlider', module.default));
    import('vue-notification').then((module) => Vue.use(module.default));
    import('vue-dompurify-html').then((module) =>
        Vue.use(module.default, {
            default: {
                USE_PROFILES: { html: true },
                FORBID_TAGS: ['style'],
                ADD_ATTR: ['target'],
            },
        }),
    );

    Vue.directive('add-target-blank', {
        inserted(el) {
            const links = el.querySelectorAll('a');
            for (const link of links) {
                link.setAttribute('target', '_blank');
            }
        },
        update(el) {
            const links = el.querySelectorAll('a');
            for (const link of links) {
                link.setAttribute('target', '_blank');
            }
        },
    });

    // ** !Important Vuetify **/
    Vue.config.productionTip = false;

    axios.interceptors.request.use(
        (req) => {
            // console.log(`${req.method.toUpperCase()} Request made to ${req.url} with data:`, req.data);
            return req;
        },
        (error) => error,
    );

    // Handles auth interception logic
    AxiosInterceptor(axios);

    if (localStorage.getItem('loggedIn')) {
        try {
            const tokenResponse = await AuthHandler.refreshTokens();
            if (tokenResponse) {
                await AuthHelper.saveTokens(tokenResponse);
            }
        } catch (_) {
            console.error('Error refreshing initial token');
        }
    }

    // Get settings
    try {
        //* Gets configuration for the system
        await store.dispatch('System/getSettings', ['admin_config', 'search_config', 'userdata_config']);
        await store.dispatch('System/setVuetifyColors');
    } catch (error) {
        console.error('Error could not get systemSettings -> Error - >', error, new Date());
    }

    const accessToken = store.state.Auth.accessToken || 0;

    // Socket stuff very last to ensure we have token
    const eventSocket = io(cfg.customerFirstUrl + 'casemanagement/events', {
        transports: ['websocket'],
        query: {
            token: accessToken,
        },
        path: '/api/socket.io',
        autoConnect: false,
        useConnectionNamespace: false,
    });

    // Components

    Vue.component('StandardButton', StandardButton);
    Vue.component('PrimaryButton', PrimaryButton);
    Vue.component('ActionButton', ActionButton);
    Vue.component('RoundIconButton', RoundIconButton);
    Vue.component('StandardSelect', StandardSelect);
    Vue.component('TertiaryActionButton', TertiaryActionButton);
    Vue.component('SecondaryActionButton', SecondaryActionButton);
    Vue.component('PrimaryActionButton', PrimaryActionButton);
    Vue.component('MultiSelect', MultiSelect);
    Vue.component('UsersSelect', UsersSelect);
    Vue.component('TertiaryButton', TertiaryButton);
    Vue.component('StandardInput', StandardInput);
    Vue.component('SearchInput', SearchInput);
    Vue.component('SecondaryInput', SecondaryInput);
    Vue.component('PhoneNumber', PhoneNumber);
    Vue.component('PhoneInput', PhoneNumberPhoneWrapper);
    Vue.component('SmsInput', PhoneNumberSmsWrapper);
    Vue.component('EmailInputSingle', EmailInputSingle);
    Vue.component('EmailInputMulti', EmailInputMulti);
    Vue.component('EmailInputSystem', EmailInputSystem);
    Vue.component('StatusSelect', StatusSelect);
    Vue.component('TimeSelect', TimeSelect);
    Vue.component('SlaSelect', SlaSelect);
    Vue.component('QueueSelect', QueueSelect);
    Vue.component('QueuesSelect', QueuesSelect);
    Vue.component('TemplateSelect', TemplateSelect);
    Vue.component('SignatureSelect', SignatureSelect);
    Vue.component('UserSelect', UserSelect);
    Vue.component('ClientSelect', ClientSelect);
    Vue.component('TipTap', TipTap);
    Vue.component('SmsTipTap', TipTap);
    Vue.component('AddressSingle', AddressSingle);
    Vue.component('SmsPreview', SmsPreview);
    Vue.component('SenderNumberSelect', SenderNumberSelect);
    Vue.component('MultiUsersSelect', MultiUsersSelect);
    Vue.component('BrandSelect', BrandSelect);
    Vue.component('BrandsSelect', BrandsSelect);
    Vue.component('Tooltip', Tooltip);
    Vue.component('TextArea', TextArea);
    Vue.component('LoaderOverlay', LoaderOverlay);
    Vue.component('InfiniteList', InfiniteList);
    Vue.component('LanguageSelect', LanguageSelect);
    Vue.component('CountrySelect', CountrySelect);
    Vue.component('DynamicSelect', DynamicSelect);

    Vue.use(
        new VueSocketIO({
            debug: false,
            connection: eventSocket,
            vuex: {
                store,
                actionPrefix: 'SOCKET_',
                mutationPrefix: 'SOCKET_',
            },
        }),
    );

    initSentry(Vue, router);

    router.beforeEach((_1, _2, next) => {
        // Show loader
        store.dispatch('Loader/showLoader');
        next();
    });

    router.afterEach(() => {
        // Hide loader
        store.dispatch('Loader/hideLoader');
    });

    /**
     * Global object to be used in the application
     *
     * @param {object} global
     * @param {object} global.dialogs
     * @param {function} global.dialogs.showConfirmationDialog
     * @param {function} global.dialogs.showDialog
     */
    Vue.prototype.$global = global;

    new Vue({
        router,
        store,
        vuetify,
        i18n,
        directives: {
            'shadow-dom': shadowDirective,
        },
        mounted() {
            // Assume your app has finished loading once the root component is mounted
            window.dispatchEvent(new Event('c1-loading-end'));
        },
        render: (h) => h(App),
    }).$mount('#app');
})();

window.addEventListener('error', (e) => {
    const regex = /Loading chunk \d+ failed/;
    // prompt user to confirm refresh
    if (regex.test(e.message)) {
        window.location.reload();
    }
});
