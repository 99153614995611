import dataTreeRootNode from '../../router/router.helper';
import router from '../../router';

// This has to be lowercase
const swyxEndpoints = [];

const state = {
    currentStates: [],
};

const actions = {
    async SOCKET_connect({ dispatch, commit }) {
        const initialIntegrationData = await dispatch('Integrations/getActiveStates', null, { root: true });
        commit('SET_CURRENT_STATES', initialIntegrationData);
        dispatch('availableRoutesHandler');
    },
    SOCKET_disconnect({ commit }) {
        commit('SET_CURRENT_STATES', []);
    },
    SOCKET_integrationActiveChange({ commit, dispatch }, integrationData) {
        commit('SET_CURRENT_STATES', integrationData);
        dispatch('availableRoutesHandler');
    },
    isIntegrationActive({ state }, name) {
        const integration = state.currentStates[name];
        return Boolean(integration);
    },
    async availableRoutesHandler({ dispatch }) {
        const isActive = await dispatch('isIntegrationActive', 'SWYX');
        if (isActive) {
            dataTreeRootNode.delete('swyx');
        } else {
            dataTreeRootNode.addChild(swyxEndpoints, 'swyx');
        }
        const currentRoute = window.location.hash.replace('#', '');
        if (dataTreeRootNode.exists(currentRoute.toLowerCase())) {
            router.push('/');
        }

        dispatch('Admin/getSidebarContent', null, { root: true });
    },
};

const getters = {
    isIntegrationActive: (state) => (name) => {
        const integration = state.currentStates[name.toUpperCase()];
        return Boolean(integration);
    },
    isCallingActive: (_, getters) => {
        return getters.isIntegrationActive('SWYX') || getters.isIntegrationActive('FREESWITCH');
    },
    isSmsActive: (_, getters) => {
        return getters.isIntegrationActive('SINCH');
    },
};

const mutations = {
    SET_CURRENT_STATES(state, data) {
        state.currentStates = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
