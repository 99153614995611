import axios from 'axios';

function authorizeIntegration() {
    return axios.get(`/billing/integration`);
}

function checkIfIntegrationIsActive() {
    return axios.get(`/billing/integration/active`);
}

function syncExternals() {
    return axios.post(`/billing/externals/sync`);
}

function getCustomers() {
    return axios.get(`/billing/customers`);
}
function getCustomer(customerId) {
    return axios.get(`/billing/customers/${customerId}`);
}

function updateCustomer(payload) {
    return axios.put(`/billing/customers`, payload);
}
function deleteCustomer(id) {
    return axios.delete(`/billing/customers/${id}`);
}

function getBillableCustomers(query) {
    return axios.get(`billing/billable-customers`, {
        params: {
            ...query,
        },
    });
}
function getBillableCustomer(payload) {
    return axios.get(`billing/billable-customers/${payload.customerId}`, {
        params: {
            ...payload.query,
        },
    });
}

function getInvoices(query) {
    return axios.get(`billing/invoices`, {
        params: {
            ...query,
        },
    });
}

function createInvoice(payload) {
    return axios.post(`billing/invoices`, payload);
}

function addArticlesToCustomer(payload) {
    const { customerId, articles } = payload;
    return axios.post(`billing/customers/${customerId}/articles`, {
        articles,
    });
}
function deleteArticleFromCustomer(payload) {
    const { customerId, articleId } = payload;
    return axios.delete(`billing/customers/${customerId}/articles/${articleId}`);
}

function getArticlesByCustomer(id) {
    return axios.get(`billing/customer/${id}/articles`);
}
function getArticles() {
    return axios.get(`billing/articles`);
}
function createArticle(payload) {
    return axios.post(`/billing/articles`, payload);
}
function deleteArticle(id) {
    return axios.delete(`/billing/articles/${id}`);
}
function updateArticle(article) {
    return axios.put(`/billing/articles/${article.id}`, article);
}

function getBillingPeriods() {
    return axios.get(`/billing/periods`);
}
function createPeriod(payload) {
    return axios.post(`/billing/periods`, payload);
}

function deletePeriod(id) {
    return axios.delete(`/billing/periods/${id}`);
}

function updatePeriod(payload) {
    return axios.put(`/billing/periods/${payload.params.id}`, payload.data);
}

function addPeriodToCustomer(payload) {
    const { customerId, periodId, articleId } = payload;
    return axios.post(`/billing/customers/${customerId}/periods/${periodId}`, {
        articleId,
    });
}
function deleteCustomerPeriod(payload) {
    const { customerId, periodId } = payload;
    return axios.delete(`/billing/customers/${customerId}/periods/${periodId}`);
}

function getPeriodTypes() {
    return axios.get(`/billing/periods/types`);
}

function generateInvoiceBase({ from, to }) {
    return axios.get(`/billing/invoicebase/generate?from=${from}&to=${to}`);
}

function getInvoiceSummary({ from, to, customerId }) {
    return axios.get(`/billing/customers/${customerId}/invoicebase/summary?from=${from}&to=${to}`);
}

export default {
    authorizeIntegration,
    checkIfIntegrationIsActive,
    getInvoiceSummary,
    syncExternals,
    getCustomers,
    getCustomer,
    updateCustomer,
    deleteCustomer,
    getBillableCustomers,
    getBillableCustomer,
    createInvoice,
    getInvoices,
    addArticlesToCustomer,
    getArticlesByCustomer,
    deleteArticleFromCustomer,
    getArticles,
    createArticle,
    updateArticle,
    deleteArticle,
    getBillingPeriods,
    createPeriod,
    deletePeriod,
    updatePeriod,
    addPeriodToCustomer,
    deleteCustomerPeriod,
    getPeriodTypes,
    generateInvoiceBase,
};
