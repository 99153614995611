module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "caseTravel": {
          "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are here"])},
          "timeBetween": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time between events"])}
        }
      },
      "sv": {
        "caseTravel": {
          "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du är här"])},
          "timeBetween": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tid mellan händelser"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"caseTravel":{"position":"You are here","timeBetween":"Time between events"}},"sv":{"caseTravel":{"position":"Du är här","timeBetween":"Tid mellan händelser"}}}')
  delete Component.options._Ctor
  
}
