module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "sv": {
        "toolbar": {
          "newMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nytt meddelande"])},
          "external": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny extern e-post"])},
          "internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nytt internt meddelande"])},
          "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nytt socialt meddelande"])},
          "forwarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidarebefordrar"])}
        }
      },
      "en": {
        "toolbar": {
          "newMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New message"])},
          "external": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New external e-mail"])},
          "internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New internal message"])},
          "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New social message"])},
          "forwarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forwarding"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"sv":{"toolbar":{"newMessage":"Nytt meddelande","external":"Ny extern e-post","internal":"Nytt internt meddelande","social":"Nytt socialt meddelande","forwarding":"Vidarebefordrar"}},"en":{"toolbar":{"newMessage":"New message","external":"New external e-mail","internal":"New internal message","social":"New social message","forwarding":"Forwarding"}}}')
  delete Component.options._Ctor
  
}
