import Vue from 'vue';
import Vuex from 'vuex';
import { vuexStrictMode } from '@/app/config';
import modules from './modules';

// create initial state
const initialState = {};
for (const moduleName in modules) {
    const { state } = modules[moduleName];
    initialState[moduleName] = structuredClone(state);
}

// add a reset action
const actions = {
    resetState({ commit }) {
        commit('RESET_STATE');
    },
};

// add a reset mutation
const mutations = {
    RESET_STATE: (state) => {
        for (const moduleName in initialState) {
            Object.assign(state[moduleName], initialState[moduleName]);
        }
    },
};

const plugins = [];
for (const moduleName in modules) {
    const module = modules[moduleName];
    if (module.plugins) {
        plugins.push(...module.plugins);
    }
}

Vue.use(Vuex);
export default new Vuex.Store({
    modules,
    mutations,
    actions,
    plugins,
    strict: vuexStrictMode,
});
