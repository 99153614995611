<template>
    <SimpleTooltip :tooltipText="busyText" top :disabled="!isBusy">
        <div
            :class="{
                'timeline-item': !isSelectedCase && !isBusy,
                'activity-line-class': isSelectedCase || isBusy,
            }"
            @click="goTo(item.caseId)"
        >
            <div v-if="isSelectedCase" class="selected"></div>
            <div v-else-if="isBusy" class="busy"></div>
            <div class="timeline-internal-type">
                <InternalType :item="item" :small="true" :showParentBadge="true" />
            </div>
            <div class="middle-section">
                <TimelineItemStandard v-if="!parent" :item="item" />
                <TimelineItemParent v-else :item="item" />
            </div>
            <div class="round-icons-section">
                <TimelineItemCard :item="item" />
                <TimelineItemAvatar :item="item" />
            </div>
        </div>
    </SimpleTooltip>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    import { formatDateToText, getMonthByNumber } from '@/utils/DateFormatter';
    import InternalType from '@/components/Cases/List/InternalType.vue';
    import TimelineItemStandard from '@/components/Cases/TimelineItemStandard.vue';
    import TimelineItemParent from '@/components/Cases/TimelineItemParent.vue';
    import TimelineItemCard from '@/components/Cases/TimelineItemCard.vue';
    import TimelineItemAvatar from '@/components/Cases/TimelineItemAvatar.vue';

    export default {
        components: {
            InternalType,
            SimpleTooltip: () => import('@/components/Global/SimpleTooltip.vue'),
            TimelineItemStandard,
            TimelineItemParent,
            TimelineItemCard,
            TimelineItemAvatar,
        },
        props: {
            item: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapState({
                timeline: (state) => state.Cases.timeline,
                timelineItems: (state) => state.Cases.timelineItems,
                caseTimeline: (state) => state.Cases.caseTimeline,
                selectedCaseId: (state) => state.Cases.selectedCaseId,
                busyCaseList: (state) => state.Cases.busyCaseList,
            }),
            userName() {
                if (this.item.userName) {
                    return this.item.userName;
                }
                return this.$t('timeLine.noAgent');
            },
            locale() {
                return this.$i18n.locale;
            },
            isSelectedCase() {
                return this.selectedCaseId === this.item.caseId;
            },
            isBusy() {
                return this.busyCaseList.some((e) => e.id === this.item.caseId);
            },
            busyText() {
                return this.working + ' ' + this.$t('timeLine.workingCase');
            },
            working() {
                const working = this.busyCaseList.find((e) => e.id === this.item.caseId && e.activeUser);
                return working ? working.userName : null;
            },
            parent() {
                return this.item.parent || null;
            },
        },
        methods: {
            ...mapActions({
                goToCase: 'Cases/goToCase',
            }),
            getMonthByNumber,
            formatDateToText,
            goTo(caseId) {
                this.goToCase({ caseId });
            },
        },
    };
</script>

<style scoped>
    .selected {
        background: var(--v-primary-base);
        height: 100%;
        width: 4px;
        left: 0;
        margin-right: 15px;
    }
    .busy {
        background-color: var(--v-color1-base);
        height: 100%;
        width: 4px;
        left: 0;
        margin-right: 15px;
    }
    .activity-line-class {
        padding: 0 15px 0 0;
        height: 58px;
        display: grid;
        align-items: center;
        grid-template-columns: 4px 38px 1fr 68px;
        transition: background-color 0.2s ease-in;
        border-bottom: 1px solid rgb(0, 0, 0, 0.15);
    }
    .activity-line-class:hover {
        background-color: var(--v-gray5-base);
        cursor: pointer;
    }
    .activity-line-class:focus {
        background-color: var(--v-gray5-base);
    }
    .timeline-item {
        padding: 0 15px 0 4px;
        height: 58px;
        display: grid;
        align-items: center;
        grid-template-columns: 38px 1fr 68px;
        transition: background-color 0.2s ease-in;
        border-bottom: 1px solid rgb(0, 0, 0, 0.15);
    }
    .timeline-item:hover {
        background-color: var(--v-gray5-base);
        cursor: pointer;
    }
    .timeline-item:focus {
        background-color: var(--v-gray5-base);
    }
    .timeline-item > div:last-child {
        display: flex;
        flex-direction: row;
    }
    .timeline-internal-type {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 10px;
    }
    .middle-section {
        margin-left: 10px;
    }
    .round-icons-section {
        display: flex;
        gap: 15px;
        padding: 0 2px;
    }
</style>
<i18n lang="json">
{
    "en": {
        "timeLine": {
            "dateTime": "Date / Time",
            "noAgent": "No agent",
            "noContactPerson": "No contact person",
            "noTopic": "No topic",
            "workingCase": "is currently working on this case"
        }
    },
    "sv": {
        "timeLine": {
            "dateTime": "Datum / Tid",
            "noAgent": "Ingen agent",
            "noContactPerson": "Ingen kontaktperson",
            "noTopic": "Inget ämne",
            "workingCase": "jobbar i detta ärende just nu"
        }
    }
}
</i18n>
