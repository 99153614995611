<template>
    <div>
        <slot name="button" :handleClick="openModal"></slot>
        <v-dialog v-model="modal" width="70%">
            <CreateTask
                v-if="creatingTask"
                :client="client"
                :case="selectedCase"
                @task-created="reload"
                @back="cancelCreation"
            />
            <TaskList
                v-else-if="!taskLoading && !selectedTask"
                :key="reloadCount"
                v-model="tasks"
                :client-id="clientId"
                :channel-type="channelType"
                :loading="listLoading"
                @loading="setLoading"
                @selected-task="handleSelectedTask"
                @close="modal = false"
            >
                <template #button>
                    <!-- HIDE THIS FOR NWOW -->
                    <!-- <v-btn color="primary" text @click="creatingTask = true">{{ $t('createTask') }}</v-btn> -->
                </template>
            </TaskList>

            <SelectedTask v-else :task="selectedTask" :loading="taskLoading" @exit="clearSelectedTask" />
        </v-dialog>
    </div>
</template>
<script>
    import { mapActions, mapState } from 'vuex';

    export default {
        components: {
            TaskList: () => import('./TaskList/TasksList.vue'),
            SelectedTask: () => import('./SelectedTask/SelectedTask.vue'),
            CreateTask: () => import('./CreateTask/CreateTask.vue'),
        },
        data() {
            return {
                reloadCount: 0,
                modal: false,
                listLoading: true,
                taskLoading: false,
                tasks: null,
                selectedTask: null,
                creatingTask: false,
            };
        },
        computed: {
            ...mapState('Cases', {
                client: (state) => state.clientNowOnCard?.data,
                selectedCase: (state) => state.selectedCase?.case,
                clientId: (state) => state.clientNowOnCard?.data?.clientId,
                channelType: (state) => state.selectedCase?.case?.internalType,
            }),
        },

        methods: {
            ...mapActions('Integrations', {
                storeGetExternalTask: 'getDeduTask',
            }),
            openModal() {
                this.modal = true;
            },
            async handleSelectedTask(_task) {
                this.setTaskLoading(true);
                const task = await this.storeGetExternalTask(_task.guidId);
                this.setSelectedTask(task);
                this.setTaskLoading(false);
            },
            cancelCreation() {
                this.creatingTask = false;
                this.setLoading(true);
            },
            reload() {
                this.setLoading(true);
                this.setTasks(null);
                this.creatingTask = false;
                this.reloadCount++;
            },
            setTasks(tasks) {
                this.tasks = tasks;
            },
            setSelectedTask(task) {
                this.selectedTask = task;
            },
            clearSelectedTask() {
                this.listLoading = true;
                this.setSelectedTask(null);
            },
            setLoading(loading) {
                this.listLoading = loading;
            },
            setTaskLoading(loading) {
                this.taskLoading = loading;
            },
        },
    };
</script>
<style scoped lang="scss">
    :deep(.v-dialog) {
        border-radius: 20px !important;
    }
</style>
<i18n lang="json">
{
    "en": {
        "externalTasks": "External tasks",

        "externalTasksModal": "External tasks",
        "createTask": "Create task",
        "externalTasksModalTitle": "External tasks",
        "externalTasksModalClose": "Close"
    },
    "sv": {
        "externalTasks": "Externa ärenden",

        "externalTasksModal": "Externa ärenden",
        "createTask": "Skapa ärende",
        "externalTasksModalTitle": "Externa ärenden",
        "externalTasksModalClose": "Stäng"
    }
}
</i18n>
