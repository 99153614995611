export function mapFilterPayload(filter, searchType, fixedFilterItems) {
    const newPayload = [];
    newPayload.page = filter.page;
    newPayload.items = filter.items;
    newPayload.queues = fixedFilterItems.queues.map((q) => q.value).sort();
    newPayload.categories = fixedFilterItems.categories.map((q) => q.value).sort();
    newPayload.channels = fixedFilterItems.channels.map((q) => q.value).sort();

    newPayload.userIds = filter.users.map((q) => q.value).sort();
    newPayload.showUnansweredOnly = filter.showUnansweredOnly;
    newPayload.sortBy = filter.sortField;
    newPayload.direction = filter.sortDirection;
    newPayload.statuses = filter.statuses.map((q) => q.value).sort();
    newPayload.query = filter.searchQuery;

    if (newPayload.query == '') {
        newPayload.searchType = 1;
    } else {
        newPayload.searchType = searchType;
    }

    return newPayload;
}
