<template>
    <v-app>
        <UserRevisionInformationPopup :showProp="informUserAboutNewVersion" />
        <template v-if="viewIsLoaded">
            <DisconnectOverlay />
            <Sidebar class="sidebar" @sidebar-pinned="handlePinSidebar" />
            <SIPManager v-if="showSipPhone" />
            <NormalAlert :key="(reloadContent = 1)" />
            <QuarantineAlert />
            <ChatAlert :key="reloadContent + 3" />
            <CredentialChangeModal :user="user" />
            <SimpleNotification :key="reloadContent + 4" />
            <UserActionModule v-if="showUserActionModule && userSettings" />
            <BuilderBar />
            <ModuleCard v-if="showUserActionModule && userSettings" />
        </template>

        <main class="main-container" :style="mainStyle">
            <SideMenu v-if="sideMenu" />

            <section class="outer-container">
                <StandardHeader v-if="isCases" />
                <section
                    id="scroll-container"
                    class="scroll-container-main"
                    :class="scrollContainerClass"
                    :style="scrollContainerStyle"
                >
                    <router-view />
                    <section v-if="loading" class="router-loader">
                        <v-progress-circular indeterminate color="primary" />
                    </section>
                </section>
            </section>
        </main>
    </v-app>
</template>

<script>
    import { mapGetters, mapState, mapActions } from 'vuex';
    import { useEventListener, useStorage } from '@vueuse/core';

    import config from '@/app/config';
    import UserActionModule from '@/components/Global/UserActionModule/UserActionModule.vue';
    import ModuleCard from '@/components/Global/UserActionModule/Modules/ActiveCommunications/ModuleCard.vue';

    import { userRoles } from '@/enums/roles.enums';
    import Sidebar from '@/views/Global/Sidebar.vue';
    import SideMenu from '@/components/Main/FilterMenu/SideMenu.vue';
    import StandardHeader from '@/components/Main/Header/Header.vue';

    import AuthHandler from '@/auth/auth.handler';
    import AuthHelper from '@/auth/auth.helper';

    import appApi from '@/api/app/app';

    export default {
        name: 'App',

        components: {
            QuarantineAlert: () => import('@/components/Global/QuarantineAlert.vue'),
            NormalAlert: () => import('@/components/CaseAlerts/NormalAlert.vue'),
            ChatAlert: () => import('@/components/CaseAlerts/ChatAlert.vue'),
            CredentialChangeModal: () => import('@/components/Global/CredentialChangeModal.vue'),
            SimpleNotification: () => import('./components/Global/SimpleNotification.vue'),
            BuilderBar: () => import('@/components/Global/BuilderMenu/BuilderBar.vue'),
            UserRevisionInformationPopup: () => import('@/components/Global/UserRevisionInformationPopup.vue'),
            DisconnectOverlay: () => import('@/components/Main/Overlay/DisconnectOverlay.vue'),
            StandardHeader,
            SideMenu,
            Sidebar,
            UserActionModule,
            ModuleCard,
            SIPManager: () => import('./views/Sip/SIPManager.vue'),
        },

        data() {
            return {
                disconnected: false,
                reloadContent: 0,
                renewProcess: false,
                pingTimeout: false,
                informUserAboutNewVersion: false,
                checkVersionTimeout: null,
                storageUser: useStorage('__userObject', null),
                userRoles,
                sidebarPinned: false,
            };
        },

        sockets: {
            connect_error() {
                this.disconnected = true;
            },

            async connect() {
                this.setIsSocketDisconnected(false);

                this.disconnected = false;
                this.pingTimeout = false;
                this.renewProcess = false;
                this.socketHandler(true);
                // Get new token on connect.
                const tokens = await AuthHandler.refreshTokens();

                if (tokens) {
                    await AuthHelper.saveTokens(tokens);
                }
            },

            disconnectMessage() {
                this.renewProcess = true;
                this.socketHandler(true);
            },

            renewState(data) {
                if (data.status === 200) {
                    this.renewProcess = false;
                }
            },

            disconnect(err) {
                this.setIsSocketDisconnected(true);

                this.socketHandler(false);
                this.$socket.disconnect(); // this makes sure the socket instance is disconnected properly because somehow it can be flagged as discconnected but you cant use the connect function to reconnect - Tim Aribssib 2023-03-07
                if (err !== 'ping timeout') {
                    console.warn('Ping timeout, should not be a problem, but here FYI', err);
                    this.disconnected = true;
                } else {
                    this.pingTimeout = true;
                    console.log('Debug -> Temporarary socket disconnect do nothing', err);
                    this.socketHandler(true);
                }
            },

            loggedInUser(data) {
                if (data.UserId !== this.user.userId) return;
                this.$store.dispatch('Auth/setUser', data);
            },
        },

        computed: {
            ...mapGetters({
                isLoggedIn: 'Auth/isLoggedIn',
            }),

            ...mapState({
                accessToken: (state) => state.Auth.accessToken,
                user: (state) => state.Auth.userObject,
                widgetBuilderState: (state) => state.Widget.widgetBuilderState,
                globalWallboardState: (state) => state.Wallboard.globalWallboardState,
                userSettings: (state) => state.System.userSettings,
                appState: (state) => state.App.appState,
                appStates: (state) => state.App.appStates,
                roles: (state) => state.Auth.roles,
                showSipPhone: (state) => state.App.showSipPhone,
                loading: (state) => state.Loader.loading,
            }),

            scrollContainerStyle() {
                return {
                    height: this.isCases ? 'calc(100vh - 64px)' : '100vh',
                };
            },

            showUserActionModule() {
                return !this.widgetBuilderState && !this.globalWallboardState;
            },
            viewIsLoaded() {
                return this.appState === this.appStates.LOADED && this.$route.name !== 'Login';
            },

            isCases() {
                return this.$route.name === 'Cases' || this.$route.name === 'Case';
            },
            isLeaderboard() {
                return this.$route.name === 'IndividualLeaderboard' || this.$route.name === 'GlobalLeaderboard';
            },
            sideMenu() {
                return this.isCases || this.isLeaderboard;
            },

            mainStyle() {
                if (this.$route.name !== 'Login') {
                    if (this.sidebarPinned) {
                        return {
                            paddingLeft: '252px !important',
                        };
                    }
                    return {
                        paddingLeft: '72px !important',
                    };
                }
                return {};
            },

            scrollContainerClass() {
                if (this.$route.name !== 'Login') {
                    return 'scroll-container';
                }
                return '';
            },
        },

        watch: {
            $route(newVal, oldVal) {
                this.clearAllToastMessages();
                try {
                    if (['Case', 'Cases'].includes(oldVal?.name)) {
                        const { caseId } = oldVal.params;
                        if (caseId) {
                            this.$store.dispatch('Cases/caseFree', caseId);
                        }
                    }
                } catch (error) {
                    console.error('Error in case free when routing', error);
                }
            },
            isLoggedIn: {
                handler(to) {
                    if (to) {
                        this.init();
                    }
                },
                immediate: true,
            },

            storageUser(newVal, oldVal) {
                if (!newVal) {
                    // user logged out on another tab so we need to logout here too
                    this.$store.dispatch('Auth/logoutClient');
                }
                if (!oldVal && !document.hasFocus()) {
                    // user logged in on another tab and this tab is not focused so we need to reload
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }
            },

            accessToken(to) {
                if (to) {
                    this.renewToken(to);
                }
            },

            disconnected(isDisconnected) {
                if (!this.isLoggedIn) {
                    return;
                }
                if (isDisconnected) {
                    this.socketHandler(isDisconnected);
                } else if (this.renewProcess === false) {
                    this.reloadContent++;
                }
            },
        },

        created() {
            this.setLanguage();
            document.addEventListener('visibilitychange', this.handleVisibility, false);
            this.createDateNowEvent();

            if (!this.isLoggedIn) return;
            this.getBrandsActive();
        },

        mounted() {
            this.socketHandler(this.isLoggedIn);
            useEventListener(window, 'focus', this.reconnectSocket);
            useEventListener(window, 'visibilitychange', this.reconnectSocket);
            setInterval(() => {
                this.checkVersion();
            }, 20_000);
            useEventListener(window, 'focus', this.checkVersion);
            localStorage.setItem('timesRestarted', 0);
        },

        beforeDestroy() {
            document.removeEventListener('visibilitychange', this.handleVisibility);
        },

        methods: {
            ...mapActions({
                setIsSocketDisconnected: 'App/setIsSocketDisconnected',
                init: 'App/init',
                connectSocket: 'App/connectSocket',
                disconnectSocket: 'App/disconnectSocket',
                clearAllToastMessages: 'App/clearAllToastMessages',
                createDateNowEvent: 'Cases/createDateNowEvent',
                getBrandsActive: 'Brands/getBrandsActive',
            }),

            setLanguage() {
                this.$root.$i18n.locale = this.user.Lang || 'sv';
            },

            async checkVersion() {
                const newRevision = await appApi.getNewVersion();

                clearTimeout(this.checkVersionTimeout);
                this.checkVersionTimeout = setTimeout(() => {
                    this.informUserAboutNewVersion = config.revision.trim() !== newRevision.trim();
                }, 5_000);
            },
            renewToken(accessToken) {
                this.$socket.emit('renewToken', {
                    accessToken,
                    message: 'I have a new token.',
                });

                this.renewProcess = true;
                // if tokens are different, adjust
                this.$socket.io.opts.query = { token: accessToken };
            },

            /**
             * Handles socket events and updates the application state accordingly.
             *
             * @param {Boolean} state - The current application state.
             */
            socketHandler(state) {
                if (state) {
                    this.connectSocket();
                } else {
                    this.disconnectSocket();
                }
            },

            async checkConfig() {
                // If more than 3600 seconds (one hour) have passed since config was fetched from mongodb, fetch config again
                if (this.isLoggedIn === false) return;
                let configDateLastDownload = localStorage.getItem('__configDownloaded');
                if (configDateLastDownload == null) {
                    await this.$store.dispatch('System/getSettings', ['admin_config']);
                    return;
                }
                configDateLastDownload = new Date(configDateLastDownload);
                if (Math.floor((Date.now() - configDateLastDownload) / 1000) > 3600) {
                    await this.$store.dispatch('System/getSettings', ['admin_config']);
                }
                // this.reloadContent = this.reloadContent += 1;
            },

            handleVisibility() {
                if (document.visibilityState == 'hidden') {
                    return;
                }

                // if not logged in, do nothing
                if (this.isLoggedIn === false) return;

                this.checkConfig();
            },

            reconnectSocket() {
                if (this.disconnected || this.pingTimeout) {
                    console.log('You lost connection when unfocused');
                    this.$socket.disconnect(); // this makes sure the socket instance is disconnected properly because somehow it can be flagged as discconnected but you cant use the connect function to reconnect - Tim Aribssib 2023-03-07
                    this.$store.dispatch('App/setSocketReconnecting', true); // used to prevent duplicate xhr requests
                    this.socketHandler(true);
                    // this.reloadContent = this.reloadContent += 1;
                }
            },

            handlePinSidebar(sidebarPinState) {
                this.sidebarPinned = sidebarPinState;
            },
        },
    };
</script>
<style lang="scss">
    $body-font-family: 'Roboto', sans-serif;
    $app-background: var(--v-appleSilver1-base);

    //GLOBAL STYLES

    .container-style {
        background-color: rgb(237, 242, 247);
        padding-left: 0px;
        padding-right: 0px;
        padding: 0px !important;
    }

    .toasted-information {
        background-color: var(--v-info-base) !important;
        color: white !important;
    }

    .scroll-container {
        overflow-y: auto;
        width: 100%;
        padding: 16px;
        background-color: #edf2f7;
    }

    .outer-container {
        width: 100%;
    }

    .main-container {
        padding: 0px !important;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
    }

    .main-style {
        height: 100%;
        padding: 0px !important;
        padding-left: 72px !important;
        background-color: rgb(237, 242, 247);
    }

    .white-text {
        color: white !important;
    }

    .c1-btn-white {
        text-transform: none !important;
        letter-spacing: 0px !important;
        font-weight: 400 !important;
        color: var(--v-primary-base) !important;
        box-shadow:
            0 2px 2px rgba(0, 0, 0, 0.25),
            0 4px 4px rgba(0, 0, 0, 0.01) !important;
        border: 1px solid #eeeeee !important;
    }

    .c1-btn-style {
        text-transform: none !important;
        letter-spacing: 0px !important;
        font-weight: 400 !important;
        min-height: 40px !important;
    }

    .softShadow {
        box-shadow:
            0px 8px 8px rgba(0, 0, 0, 0.022),
            0px 16px 16px rgba(0, 0, 0, 0.034);
    }
    .softShadowHard {
        box-shadow:
            0px 6px 6px rgba(0, 0, 0, 0.052),
            0px 12px 12px rgba(0, 0, 0, 0.064);
    }

    .standardCard {
        box-shadow:
            0px 8px 8px rgba(0, 0, 0, 0.022),
            0px 16px 16px rgba(0, 0, 0, 0.034);
        background-color: #eeeeee;
    }

    .softShadowTop {
        box-shadow:
            0 -8px 8px rgba(0, 0, 0, 0.022),
            0 -16px 16px rgba(0, 0, 0, 0.034) !important;
    }
    .softShadowTopHard {
        box-shadow:
            0 -6px 6px rgba(0, 0, 0, 0.052),
            0 -12px 12px rgba(0, 0, 0, 0.064) !important;
    }
    .softShadowLeft {
        box-shadow:
            -8px 0 8px rgba(0, 0, 0, 0.03),
            -16px 0 16px rgba(0, 0, 0, 0.01) !important;
    }
    .softShadowRight {
        box-shadow:
            8px 0 8px rgba(0, 0, 0, 0.03),
            16px 0 16px rgba(0, 0, 0, 0.01) !important;
    }

    .v-pagination__item {
        box-shadow: none;
        border: 1px #ccc solid;
        background: transparent !important;
        display: none !important;
    }

    .v-pagination__more {
        display: none !important;
    }

    .v-pagination__item--active {
        border: 1px #ccc solid;
        background: red !important;
    }

    .v-pagination__navigation {
        box-shadow: none;
        border: 0px #ccc solid;
        background: white !important;
        margin: 5px !important;
        transition: background-color 0.3s ease;
        min-height: 40px !important;
        min-width: 40px !important;
        box-shadow:
            0 1px 1px rgba(0, 0, 0, 0.02),
            0 2px 2px rgba(0, 0, 0, 0.02),
            0 4px 4px rgba(0, 0, 0, 0.02),
            0 8px 8px rgba(0, 0, 0, 0.02),
            0 16px 16px rgba(0, 0, 0, 0.01),
            0 24px 24px rgba(0, 0, 0, 0.01) !important;
    }

    .v-pagination__navigation:hover {
        background: rgba(180, 180, 180, 0.6) !important;
    }

    .v-pagination__more {
        box-shadow: none;
        border: 1px #ccc solid;
        background: transparent !important;
        text-align: center;
        align-items: center !important;
    }

    .g-button {
        transition: all 0.3s ease;
        background-color: white !important;
    }

    .g-button:hover {
        background: rgba(180, 180, 180, 0.6) !important;
    }

    .g-button::before {
        background-color: #fff !important;
    }

    .g-button::after {
        background: rgba(180, 180, 180, 0.6) !important;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .v-select__slot {
        font-size: 14px !important;
    }

    .v-text-field__slot {
        font-size: 14px !important;
    }

    body,
    html {
        padding: 0;
        margin: 0;
        width: 100%;
        min-height: 100vh;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        background-color: var(--v-gray3-base) !important;
        overflow: hidden;
    }

    .v-main {
        padding: 0 0 0 70px !important;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        -webkit-border-radius: 6px;
        border-radius: 6px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 6px;
        border-radius: 6px;
        background: #a2a2a2;
    }

    ::-webkit-scrollbar-thumb:window-inactive {
        background-color: $app-background !important;
    }

    .c1-btn-style {
        height: 100%;
        min-height: 40px;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        -webkit-border-radius: 6px;
        border-radius: 6px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 6px;
        border-radius: 6px;
        background: #a2a2a2;
    }

    ::-webkit-scrollbar-thumb:window-inactive {
        background-color: $app-background !important;
    }

    /* GLOBAL CHANGES */
    .v-text-field--outlined fieldset {
        color: #ececec !important;
    }

    // Viktor...
    .container {
        max-width: 100% !important;
    }

    .text-h6 {
        font-weight: 400 !important;
    }

    //global css for toasting
    .toasted {
        box-shadow:
            0 1px 1px rgba(0, 0, 0, 0.02),
            0 2px 2px rgba(0, 0, 0, 0.02),
            0 4px 4px rgba(0, 0, 0, 0.02),
            0 8px 8px rgba(0, 0, 0, 0.02),
            0 16px 16px rgba(0, 0, 0, 0.01),
            0 24px 24px rgba(0, 0, 0, 0.01) !important;
        padding-left: 24px !important;
        padding-top: 16px !important;
        padding-bottom: 16px !important;
        border-radius: 6px !important;
        font-size: 14px !important;
        font-weight: 400 !important;
    }

    .success {
        background-color: var(--v-primary-base) !important;
    }

    .toasted-information {
        background-color: var(--v-info-base) !important;
        color: white !important;
    }

    // so calendar events appear on top
    .v-event-timed {
        z-index: 4 !important;
    }

    .pen-cursor-closed {
        cursor:
            url('./assets/OpenhoursIcons/closed-brush.svg') 0 15,
            default !important;
    }

    .pen-cursor-talfil {
        cursor:
            url('./assets/OpenhoursIcons/message-brush.svg') 0 15,
            default !important;
    }
    .pen-cursor-transfer {
        cursor:
            url('./assets/OpenhoursIcons/transfer-brush.svg') 0 15,
            default !important;
    }
    .pen-cursor-open {
        cursor:
            url('./assets/OpenhoursIcons/open-brush.svg') 0 15,
            default !important;
    }

    .btnAnimation {
        display: inline-block;
        transition: all 0.2s ease-in;
        position: relative;
        overflow: hidden;
        z-index: 1;
    }

    .btnAnimation:before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%) scaleY(1) scaleX(1.25);
        top: 100%;
        width: 140%;
        height: 180%;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 50%;
        display: block;
        transition: all 0.3s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
        z-index: -1;
    }

    .btnAnimation:after {
        content: '';
        position: absolute;
        left: 55%;
        transform: translateX(-50%) scaleY(1) scaleX(0.75);
        top: 180%;
        width: 160%;
        height: 190%;
        background-color: var(--v-primary-base);
        border-radius: 50%;
        display: block;
        transition: all 0.3s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
        z-index: -1;
    }

    .btnAnimation:hover:before {
        top: -35%;
        background-color: var(--v-primary-base);
        transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
    }

    .btnAnimation:hover:after {
        top: -45%;
        background-color: var(--v-primary-base);
        transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
    }
    .flex-1 {
        flex: 1 !important;
    }

    .router-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--v-gray3-base);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        color: var(--v-gray2-base);
        z-index: 100;
    }

    .scroll-container-main {
        box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.05);
        background-color: #f8f8f8;
    }

    // Global comment styles
    .outgoing {
        position: relative;
        overflow: hidden;
        border: 2px solid rgb(225, 241, 245) !important;
        background-color: white !important;
    }

    .incoming {
        position: relative;
        overflow: hidden;
        border: 2px solid var(--v-gray3-base) !important;
        background-color: white !important;
    }

    .outgoing-chip {
        background-color: rgb(135, 190, 206) !important;
        padding: 2px 4px !important;
        border-radius: 4px !important;
        margin-bottom: 2px !important;
        color: white;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-size: 0.9rem;
    }

    .incoming-chip {
        background-color: var(--v-gray2-base) !important;
        padding: 2px 4px !important;
        border-radius: 4px !important;
        margin-bottom: 2px !important;
        color: white;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-size: 0.9rem;
    }

    .internal-chip {
        background-color: var(--v-gray1-base) !important;
        padding: 2px 4px !important;
        border-radius: 4px !important;
        margin-bottom: 2px !important;
        color: white;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-size: 0.9rem;
    }

    .failed-chip {
        background-color: rgb(248, 146, 151) !important;
        padding: 2px 4px !important;
        border-radius: 4px !important;
        margin-bottom: 2px !important;
        color: white;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .scheduled-chip {
        background-color: rgb(255, 204, 128) !important;
        padding: 2px 4px !important;
        border-radius: 4px !important;
        margin-bottom: 2px !important;
        color: white;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .comment-from {
        font-size: 1.15rem !important;
    }

    .disabled-overlay {
        pointer-events: none;
        opacity: 0.5;
        user-select: none;
    }

    // Global button styles

    .button-selected {
        background-color: var(--v-buttonSelected-base) !important;
        color: var(--v-gray3-darken);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        display: flex;
    }

    .button-selected:hover {
        background-color: var(--v-buttonSelectedHover-base) !important;
    }

    .button-not-selected {
        background-color: var(--v-buttonNotSelected-base) !important;
        transition: all 0.2s ease-in-out;
        color: var(--v-gray2-base);
        display: flex;
    }

    .button-not-selected:hover {
        background-color: var(--v-buttonNotSelectedHover-base) !important;
        cursor: pointer;
    }

    .button-not-selected > div > i {
        opacity: 0.6 !important;
    }

    .button-disabled {
        pointer-events: none;
        opacity: 0.3 !important;
    }
</style>
