<template>
    <div class="case-container text-truncate">
        <article class="text-truncate item-heading">{{ title }}</article>
        <article class="item-date">{{ formatDateToText(item.dateTime, locale) }}</article>
    </div>
</template>

<script>
    import { formatDateToText } from '@/utils/DateFormatter';

    export default {
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        computed: {
            title() {
                return this.item.topic || this.$t('timelineItemStandard.noTopic');
            },
            originalDate() {
                return this.item.dateTime;
            },
            locale() {
                return this.$i18n.locale;
            },
        },
        methods: {
            formatDateToText,
        },
    };
</script>
<style>
    .case-container {
        padding: 0 8px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
    }
</style>
<i18n lang="json">
{
    "en": {
        "timelineItemStandard": {
            "noTopic": "No topic"
        }
    },
    "sv": {
        "timelineItemStandard": {
            "noTopic": "Inget ämne"
        }
    }
}
</i18n>
