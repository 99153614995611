import categories from '@/api/categories/categories';
import router from '@/router';
import i18n from '../../i18n';

const state = {
    queueCategoryRelations: [],
};

const actions = {
    async getQueueCategoryRelations({ commit }, payload) {
        try {
            const res = await categories.getQueueCategoryRelations(payload);
            commit('SET_QUEUE_CATEGORY_RELATIONS', res.data);
            return res.data;
        } catch (error) {
            console.error('Could not get queue category relations', error);
            return null;
        }
    },

    async updateQueueCategoryRelations(_, payload) {
        try {
            const res = await categories.updateQueueCategoryRelations(payload);
            this._vm.$toasted.show(i18n.t('global.changedSaved'), {
                icon: 'mdi-content-save',
                type: 'success',
            });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(`${i18n.t('categoriesStore.noQueueCategoryRelations')}: ${error}`, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
            return error;
        }
    },

    async getCategories(_, payload) {
        try {
            const res = await categories.getCategories(payload);
            return res.data;
        } catch (error) {
            console.error('Could not get categories', error);
            return null;
        }
    },
};

const mutations = {
    SET_QUEUE_CATEGORY_RELATIONS(state, data) {
        state.queueCategoryRelations = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
