import { isPossiblePhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import internalNumbers from '@/enums/phoneNumber.enums';

// !!used exclusively for freeswithch!!
export const internalNumberRegex = /^\d{3,4}$/;
export const internalAndExternalPhoneNumberRegex = /^\+?\d{1,11}$/; // this checks for a number with 1 to 11 digits and an optional + in front
export const ringtoneParseRegex = /(?:\/|^)([\w-]+)\.\w{8}\.mp3$/; // This regex parses the file name to a readable format
export const internalOrExternalNumberRegex = /^\+?\d{3,12}$/; // This regex matches numbers with 3-12 digits, and allows a + in the beginning

/**
 * Validates a phone number. Can be validated as only an international number, or as an internal or international number.
 *
 * @param {string} number - The phone number to validate.
 * @param {boolean} [validateInternalNumbers=true] - Whether to validate internal numbers.
 * @param {string} [countryCode='SE'] - The fallback country code to use for parsing the phone number.
 * @returns {boolean} - Whether the phone number is valid.
 */
export function validatePhoneNumber(number, validateInternalNumbers = true, countryCode = 'SE') {
    if (typeof number !== 'string') {
        return false;
    }
    try {
        if (validateInternalNumbers && validateInternalNumber(number)) {
            return true;
        }
        return isPossiblePhoneNumber(number, countryCode);
    } catch {
        return false;
    }
}

function validateInternalNumber(number) {
    if (Number.isNaN(Number(number))) {
        return false;
    }
    return number.length >= internalNumberRegex.MIN_LENGTH && number.length <= internalNumbers.MAX_LENGTH;
}

/**
 * Formats a phone number based on the provided country code.
 * If the phone number is not a possible number, the original number is returned.
 * @param {string} number - The phone number to format.
 * @param {string} [countryCode='SE'] - The country code to use for formatting.
 * @returns {string} The formatted phone number.
 */
export function formatPhoneNumber(number, countryCode = 'SE') {
    try {
        const parsedNumber = parsePhoneNumber(number, countryCode);
        if (!parsedNumber.isPossible()) {
            return number;
        }
        return parsedNumber.number;
    } catch {
        return number;
    }
}
