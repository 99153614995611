module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "activeUsers": {
          "watching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" is watching"])},
          "extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" more"])},
          "working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" is working in the case"])},
          "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You"])}
        }
      },
      "sv": {
        "activeUsers": {
          "watching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" tittar på"])},
          "extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" fler"])},
          "working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" arbetar i ärendet"])},
          "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"activeUsers":{"watching":" is watching","extra":" more","working":" is working in the case","you":"You"}},"sv":{"activeUsers":{"watching":" tittar på","extra":" fler","working":" arbetar i ärendet","you":"Du"}}}')
  delete Component.options._Ctor
  
}
