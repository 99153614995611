<template>
    <div class="comments softShadow">
        <header v-if="header" class="medium-heading">
            {{ header }}
        </header>
        <div v-for="(title, index) in titles" :key="`${title}-${index}`" class="comments-grid">
            <span class="comments-grid-header">{{ title }}</span>
            <span class="comments-grid-text">{{ values[index] }}</span>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            description: {
                type: String,
                default: '',
            },
        },
        computed: {
            parsedDescription() {
                // Removes all html, body, head, br and h1-6 tags
                const parseRegex =
                    /<html[^>]*>|<\/html>|<head[^>]*>|<\/head>|<body[^>]*>|<\/body>|<br[^>]*>|<h[1-6][^>]*>|<\/h[1-6]>/g;
                const html = this.description.replaceAll(parseRegex, '');
                // Extracts "<b>Key</b>Value" pairs from the string
                const regex = /<b>(.*?)<\/b>(?!<b>)(.*?)(?=$|<b>)/g;
                const matches = html.matchAll(regex);
                const result = {};

                for (const match of matches) {
                    const key = match[1].trim();
                    const value = match[2].trim();
                    result[key] = value;
                }

                return result;
            },

            titles() {
                return Object.keys(this.parsedDescription);
            },

            values() {
                return Object.values(this.parsedDescription);
            },

            header() {
                // Returns first matching header
                const regex = /<h[1-6]>([^<]+)<\/h[1-6]>/g;
                const match = this.description.matchAll(regex);
                return match.next().value[1];
            },
        },
    };
</script>
<style scoped lang="scss">
    .comments {
        padding: 16px;
        border-radius: 4px;
        width: 100%;
        max-width: 460px;
        gap: 4px;
    }

    .comments-grid {
        display: grid;
        grid-template-columns: 1fr 1.4fr;
        border-bottom: 1px solid var(--v-gray3-base);
        border-radius: 4px;
        padding: 8px 0px;
    }

    .comments-grid-header {
        text-align: left;
        font-size: 12px;
        color: var(--v-gray2-base);
    }

    .comments-grid-text {
        font-size: 12px;
        color: var(--v-gray4-base);
    }

    .medium-heading {
        font-size: 14px;
        font-weight: 500;
        color: var(--v-gray4-base);
        text-align: center;
        margin-bottom: 16px;
    }
</style>
