import i18n from '../i18n';

export const userCreatorStepsTemplate = [
    {
        textKey: 'adminStore.userLogin',
        value: 'login',
        component: 'UserLoginForm',
        onlySwyx: false,
    },
    {
        textKey: 'adminStore.userProfile',
        value: 'profile',
        component: 'UserProfileForm',
        onlySwyx: false,
    },
    {
        textKey: 'adminStore.connections',
        value: 'connection',
        component: 'UserConnectionForm',
        onlySwyx: false,
    },
    {
        textKey: 'adminStore.roles',
        value: 'role',
        component: 'UserRoleForm',
        onlySwyx: false,
    },
    {
        textKey: 'adminStore.groups',
        value: 'group',
        component: 'UserGroupForm',
        onlySwyx: false,
    },
    {
        textKey: 'adminStore.templateUser',
        value: 'template',
        component: 'UserTemplateForm',
        onlySwyx: true,
    },
    {
        textKey: 'global.btn.save',
        value: 'create',
        component: 'UserCreatorSummary',
        onlySwyx: false,
    },
];

export const creatorUserObjectTemplate = {
    displayName: { value: '', textKey: 'adminStore.displayName', show: true },
    userName: { value: '', textKey: 'adminStore.userName', show: true },
    password: { value: '', textKey: 'adminStore.password', show: true },
    image: { value: '', textKey: 'adminStore.image', show: false },
    title: { value: '', textKey: 'adminStore.title', show: true },
    description: { value: '', textKey: 'adminStore.description', show: true },
    email: { value: '', textKey: 'adminStore.email', show: true },
    internalNumber: { value: '', textKey: 'adminStore.internalNumber', isSwyx: true, show: true },
    publicNumber: { value: '', textKey: 'adminStore.publicNumber', isSwyx: true, show: true },
    roles: { value: [], textKey: 'adminStore.roles', show: true },
    groups: { value: [], textKey: 'adminStore.groups', show: true },
    template: { value: {}, textKey: 'adminStore.templateUser', isSwyx: true, show: true },
    swyx: { value: false, textKey: 'adminStore.swyx', isSwyx: true, show: false },
    credentialChange: { value: false, textKey: 'adminStore.forceCredentialChange', show: false },
    hasCredentials: { value: true, textKey: 'adminStore.userHasNoCredentials', show: false },
};
