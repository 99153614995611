import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isAdmin)?_c('section',[(_vm.options.length > 0)?_c(VBtn,{ref:"menuButton",attrs:{"fab":"","color":"white","elevation":"0","height":"30","width":"30"},on:{"mouseover":(e) => _vm.mouseOver(true, e),"mouseout":(e) => _vm.mouseOver(false, e),"click":_vm.toggleMenu}},[_c(VIcon,{attrs:{"size":"18","color":_vm.menuIconColor}},[_vm._v("mdi-dots-horizontal")])],1):_vm._e(),(_vm.menu)?_c(VMenu,{attrs:{"close-on-content-click":false,"activator":_vm.$refs.menuButton,"offset-y":"","elevation":"0"},model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VList,_vm._l((_vm.options),function(option,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.clickButton(option.value)}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"16"}},[_vm._v(_vm._s(option.icon))]),_c(VListItemTitle,{staticClass:"medium-heading"},[_vm._v(_vm._s(option.name))])],1)}),0)],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }