module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "internalType": {
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])},
          "withSubcase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with subcase"])}
        }
      },
      "sv": {
        "internalType": {
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okänd"])},
          "withSubcase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["med underärende"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"internalType":{"unknown":"Unknown","withSubcase":"with subcase"}},"sv":{"internalType":{"unknown":"Okänd","withSubcase":"med underärende"}}}')
  delete Component.options._Ctor
  
}
