import enums from '@/constants/enums/openinghours.enums';

const { OPEN, CLOSED, MESSAGE, TRANSFER } = enums.exceptionTypes;
const utils = require('@/utils/openhours.util');

export function getColorByExceptionType(exceptionType) {
    switch (exceptionType) {
        case OPEN: {
            return '#4caf50';
        }
        case CLOSED: {
            return 'red';
        }
        case MESSAGE: {
            return 'blue';
        }
        case TRANSFER: {
            return 'orange';
        }
        default: {
            return 'black';
        }
    }
}

export function timeValuesIntoDigital(days) {
    return days.map((day) => {
        return formatOpenHours(day);
    });
}

export function formatOpenHours(day) {
    const arrOfDigitalTimes = day.value.map((value) => {
        return utils.convertSecondsToDigital(value);
    });

    return {
        relId: day.RelId || null,
        active: !!day.checked,
        times: [...arrOfDigitalTimes],
    };
}

export default {
    getColorByExceptionType,
    timeValuesIntoDigital,
    formatOpenHours,
};
