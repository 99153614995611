import emails from '@/api/emails/emails';

const actions = {
    async getBlacklistedEmails(_, payload) {
        try {
            const data = await emails.getBlacklistedEmails(payload);
            return [data, null];
        } catch (error) {
            return [null, error];
        }
    },
    async getPage(_, payload) {
        try {
            const data = await emails.getBlacklistedEmailsPage(payload);
            return [data, null];
        } catch (error) {
            return [null, error];
        }
    },
    async addBlacklistedEmail(_, payload) {
        try {
            const data = await emails.addBlacklistedEmail(payload);
            return [data, null];
        } catch (error) {
            return [null, error];
        }
    },
    async updateBlacklistedEmail(_, payload) {
        try {
            const data = await emails.updateBlacklistedEmail(payload);
            return [data, null];
        } catch (error) {
            return [null, error];
        }
    },
    async deleteBlacklistedEmail(_, payload) {
        try {
            const data = await emails.deleteBlacklistedEmail(payload);
            return [data, null];
        } catch (error) {
            return [null, error];
        }
    },
};

export default {
    namespaced: true,
    actions,
};
