<template>
    <div>
        <slot :onClick="traverse"></slot>
    </div>
</template>
<script>
    export default {
        props: {
            href: {
                type: String,
                default: '',
            },
        },

        methods: {
            traverse() {
                // navigate to the login page
                const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
                const destination = this.href;
                if (!isChrome) {
                    window.open('microsoft-edge:' + destination, '_blank', 'width=10,height=10,left=-100,top=-100');
                } else {
                    window.open(destination, '_blank', 'width=1400,height=800,left=100,top=100');
                }
            },
        },
    };
</script>
<style scoped lang="scss"></style>
