import audience from '@/api/audiences/audiences';
import i18n from '../../i18n';
import { audienceType } from '../../enums/campaigns/campaigns.enums';

// State object
const state = {
    audiences: [],
    selectedAudience: null,
    audienceItems: [],
    sending: false,
    deleting: false,
    inserting: false,
};

// Getter functions
const getters = {};

const actions = {
    async bulkInsertClients({ dispatch, commit }, data) {
        try {
            commit('SET_INSERTING', true);
            const response = await audience.bulkInsertClients(data);
            commit('SET_INSERTING', false);

            this._vm.$toasted.show(i18n.t('audiences.bulkInsertClientsSuccess'), {
                icon: 'mdi-check',
                type: 'success',
            });

            dispatch('fetchAudienceItems', { limit: 30, page: 1, search: '' });

            return response.data;
        } catch (error) {
            commit('SET_INSERTING', false);
            console.error('Error bulk inserting clients:', error);
            return false;
        }
    },

    async deleteClientsAudience({ dispatch, commit }, id) {
        try {
            commit('SET_DELETING', true);
            const response = await audience.deleteAudience(id);
            commit('SET_DELETING', false);
            dispatch('getAllAudiences');
            return response.data;
        } catch (error) {
            commit('SET_DELETING', false);
            console.error('Error deleting clients audience:', error);
            return false;
        }
    },

    async createClientsAudience({ dispatch, commit }, data) {
        try {
            commit('SET_SENDING', true);
            const response = await audience.createAudience({ ...data, type: audienceType.CLIENTS });
            commit('SET_SENDING', false);
            dispatch('getAllAudiences');
            return response.data;
        } catch (error) {
            commit('SET_SENDING', false);
            console.error('Error creating clients audience:', error);
            return false;
        }
    },

    async getAllAudiences({ dispatch }) {
        let page = 1;
        let morePages = true;
        /**
         * Fetch all audiences paginated
         * This will be improved to a new route /audiences/all
         * to avoid fetching with multiple requests
         */
        while (morePages) {
            try {
                const response = await dispatch('getAudiences', { page });

                if (response?.data && response.data.length > 0) {
                    page++;
                } else {
                    morePages = false;
                }
            } catch (error) {
                console.error('Error fetching all audiences:', error);
                morePages = false;
            }
        }
    },

    async getAudiences({ commit }, { limit, page }) {
        try {
            const response = await audience.getAudiences({ limit, page });

            if (page === 1) {
                commit('SET_AUDIENCES', response.data);
            } else {
                commit('SET_AUDIENCES', state.audiences.concat(response.data));
            }

            return response;
        } catch (error) {
            console.error('Error getting audiences:', error);
        }
    },

    selectAudience({ commit }, audience) {
        commit('SET_AUDIENCE_ITEMS', []);
        commit('SET_SELECTED_AUDIENCE', audience);
    },

    async fetchAudienceItems({ state, commit }, { limit, page, search }) {
        try {
            const type = state.selectedAudience?.type;
            const id = state.selectedAudience.static ? null : state.selectedAudience.id;

            const response = await audience.getAudienceItems(type, id, limit, page, search);

            if (page === 1) {
                commit('SET_AUDIENCE_ITEMS', response.data);
            } else {
                commit('SET_AUDIENCE_ITEMS', state.audienceItems.concat(response.data));
            }

            return response.data;
        } catch (error) {
            console.error('Error fetching audience items:', error);
        }
    },

    async fetchAudienceItemsClients({ state, commit }, { limit, page, search }) {
        try {
            const id = state.selectedAudience.id;
            const response = await audience.getAudienceItemsClients(id, limit, page, search);

            if (page === 1) {
                commit('SET_AUDIENCE_ITEMS', response.data);
            } else {
                commit('SET_AUDIENCE_ITEMS', state.audienceItems.concat(response.data));
            }

            return response.data;
        } catch (error) {
            console.error('Error fetching audience items clients:', error);
        }
    },

    async deleteAudienceItem({ state, commit }, { id }) {
        try {
            const audienceId = state.selectedAudience.id;
            await audience.deleteAudienceItem(audienceId, id);

            const item = state.audienceItems.find((item) => item.id === id && item.connected);

            if (item) {
                commit('UPDATE_CONNECTED', { id, connected: false });
            } else {
                commit('REMOVE_ITEM', id);
            }
        } catch (error) {
            console.error('Error deleting audience item:', error);
        }
    },

    async addAudienceItem({ state, commit }, { id }) {
        try {
            const audienceId = state.selectedAudience.id;
            await audience.addAudienceItem(audienceId, id);

            const item = state.audienceItems.find((item) => item.id === id && !item.connected);

            if (item) {
                commit('UPDATE_CONNECTED', { id, connected: true });
            }
        } catch (error) {
            console.error('Error adding audience item:', error);
        }
    },
};

// Mutations
const mutations = {
    SET_AUDIENCES(state, audiences) {
        state.audiences = audiences;
    },

    SET_SELECTED_AUDIENCE(state, audience) {
        state.selectedAudience = audience;
    },

    SET_AUDIENCE_ITEMS(state, audienceItems) {
        state.audienceItems = audienceItems;
    },

    SET_SENDING(state, sending) {
        state.sending = sending;
    },

    SET_DELETING(state, deleting) {
        state.deleting = deleting;
    },

    SET_INSERTING(state, inserting) {
        state.inserting = inserting;
    },

    UPDATE_CONNECTED(state, { id, connected }) {
        const item = state.audienceItems.find((item) => item.id === id);
        if (item) {
            item.connected = connected;
        }
    },

    REMOVE_ITEM(state, id) {
        state.audienceItems = state.audienceItems.filter((item) => item.id !== id);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
