module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "search": {
          "searchHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
          "minLetters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search with at least 3 characters"])},
          "searchAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search all"])},
          "searchOnlyFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search only filters"])},
          "searchSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search settings"])},
          "searchOnlyCommentsAndFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search only comments and filters"])}
        }
      },
      "sv": {
        "search": {
          "searchHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök"])},
          "minLetters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök med minst tre tecken"])},
          "searchAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök alla"])},
          "searchOnlyFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök endast filter"])},
          "searchSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sökinställningar"])},
          "searchOnlyCommentsAndFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök endast kommentarer och filter"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"search":{"searchHere":"Search","minLetters":"Search with at least 3 characters","searchAll":"Search all","searchOnlyFilters":"Search only filters","searchSettings":"Search settings","searchOnlyCommentsAndFilters":"Search only comments and filters"}},"sv":{"search":{"searchHere":"Sök","minLetters":"Sök med minst tre tecken","searchAll":"Sök alla","searchOnlyFilters":"Sök endast filter","searchSettings":"Sökinställningar","searchOnlyCommentsAndFilters":"Sök endast kommentarer och filter"}}}')
  delete Component.options._Ctor
  
}
