<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
<template>
    <div v-if="label" class="label">
        <span :style="fontSize">
            {{ label }}
        </span>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { getServiceTime } from '@/utils/Design';
    import { caseStatuses } from '@/enums/cases.enums';

    export default {
        props: {
            item: {
                type: Object,
                default: () => ({}),
            },
            size: {
                type: Number,
                default: 12,
            },
        },

        computed: {
            ...mapState({
                dateNowRealTime: (state) => state.Cases.dateNow,
            }),
            serviceTime() {
                return this.getServiceTime(this.item.sla, this.item.dateTime, 'text', this.dateNowRealTime);
            },
            label() {
                if (this.serviceTime === '') {
                    return '';
                }
                switch (this.item.status) {
                    case caseStatuses.OPEN:
                    case caseStatuses.ONGOING: {
                        return this.$t(
                            this.dateNowRealTime < new Date(this.item.sla).getTime()
                                ? 'sla.preSlaOpen'
                                : 'sla.postSlaOpen',
                            { time: this.serviceTime }
                        );
                    }
                    case caseStatuses.SNOOZED: {
                        return this.$t(`sla.snoozed`, { time: this.serviceTime });
                    }
                    case caseStatuses.IN_QUEUE: {
                        return this.$t('sla.inQueue', {
                            time: this.getServiceTime(
                                this.dateNowRealTime,
                                this.item.dateTime,
                                'text',
                                new Date(this.item.lastIncoming).getTime()
                            ),
                        });
                    }
                    default: {
                        return '';
                    }
                }
            },
            fontSize() {
                return `font-size: ${this.size}px`;
            },
        },

        methods: {
            getServiceTime,
        },
    };
</script>

<style lang="scss" scoped>
    .label {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 0;
        flex: 1;
        max-width: fit-content;
    }
    .label * {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        color: var(--v-gray2-base);
    }
</style>

<i18n lang="json">
{
    "en": {
        "sla": {
            "preSlaOpen": "{time} left",
            "postSlaOpen": "{time} ago",
            "inQueue": "{time} queue time",
            "snoozed": "{time} (snoozed)"
        }
    },
    "sv": {
        "sla": {
            "preSlaOpen": "{time} kvar",
            "postSlaOpen": "{time} sedan",
            "inQueue": "{time} kötid",
            "snoozed": "{time} (bevakad)"
        }
    }
}
</i18n>
