<template>
    <section>
        <section>
            <section id="tiptap" class="dock-shadow" :class="dockShadowClass" :style="dockShadowStyle">
                <!-- Start of container -->
                <Toolbar />
                <!-- Start of tiptap top menu -->
                <section class="create-comments-inner-container">
                    <TipTapHeader />
                    <div v-if="isReadOnly" class="read-only-overlay" @click.stop="handleReadOnlyClick()"></div>
                    <!-- Start of tiptap -->
                    <section class="tiptap-container-outer">
                        <TiptapEditor
                            id="tippytappy1"
                            ref="tiptapEditor"
                            :key="caseToLoad + '_' + tiptapState"
                            :textfieldColor="checkColorOfField()"
                            :autocomplete="autoCompleteToggle"
                            :caseId="caseId"
                            :files="files"
                            :existingContent="content"
                            :template="template"
                            :templateLanguage="currentQueueLanguage"
                            height="100%"
                            :signature="external ? selectedSignature || {} : {}"
                            :showSignature="userSettings.texteditor.showSignature.active"
                            :basic="isSocial || !external"
                            :defaultInlineAttachments="true"
                            :should-auto-focus="!isForwarding"
                            @change="tiptapChange"
                        />
                    </section>

                    <!-- Start of Tiptap Buttons -->
                    <div class="bottom-buttons-row">
                        <section v-if="userSettings" class="bottom-container">
                            <section class="bottom-functions">
                                <v-tooltip top>
                                    <template #activator="{ on, attrs }">
                                        <v-btn
                                            text
                                            icon
                                            :color="autoCompleteToggle ? 'primary' : null"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="toggleAutocompleteInComment"
                                        >
                                            <v-icon size="18">mdi-lightbulb-auto-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    {{ $t('createComments.tglAutocomplete') }}
                                </v-tooltip>

                                <article
                                    v-for="(item, i) in sideViewFunctions"
                                    v-show="item.sideView != 'phoneView'"
                                    :key="i"
                                >
                                    <v-tooltip v-if="item.active" top>
                                        <template #activator="{ on, attrs }">
                                            <v-btn
                                                text
                                                icon
                                                :cy-value="item.sideView"
                                                :color="handleSignatureColor(item)"
                                                v-bind="attrs"
                                                class="mx-2"
                                                v-on="on"
                                                @click="decideAction(item.sideView)"
                                            >
                                                <v-icon size="18">{{ item.icon }}</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ item.text[$i18n.locale] }}</span>
                                    </v-tooltip>
                                </article>
                                <article>
                                    <ChatGPT :context="'cases'" />
                                </article>
                            </section>
                            <!-- Send Buttons -->
                            <section class="send-buttons-container">
                                <v-btn
                                    v-if="showAttachCheckBoxes && external && !isForwarding"
                                    color="primary"
                                    depressed
                                    small
                                    class="send-buttons"
                                    @click="allCommentsChecked ? clearAttachChecked() : checkAllComments()"
                                >
                                    <span class="send-button-text">
                                        {{
                                            allCommentsChecked
                                                ? $t('createComments.unmarkAll')
                                                : $t('createComments.markAll')
                                        }}
                                    </span>
                                </v-btn>

                                <div v-if="external && !isBasic && !isForwarding" class="flex-row d-flex align-center">
                                    <v-tooltip top>
                                        <template #activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                text
                                                icon
                                                small
                                                class="mx-1"
                                                color="primary"
                                                v-on="on"
                                                @click="toggleShowAttachCheckBoxes"
                                            >
                                                <v-icon size="18">
                                                    {{
                                                        showAttachCheckBoxes ? 'mdi-file-tree' : 'mdi-file-tree-outline'
                                                    }}
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>
                                            {{
                                                showAttachCheckBoxes
                                                    ? $t('createComments.dontAttachMailThread')
                                                    : $t('createComments.attachMailThread')
                                            }}
                                        </span>
                                    </v-tooltip>
                                </div>
                                <div v-if="external && !isBasic && !isForwarding" class="flex-row d-flex align-center">
                                    <v-tooltip top>
                                        <template #activator="{ on, attrs }">
                                            <v-card elevation="0" color="transparent" v-bind="attrs" v-on="on">
                                                <v-btn
                                                    color="primary"
                                                    depressed
                                                    small
                                                    class="send-buttons"
                                                    :disabled="isReadOnly"
                                                    @click="executeForwardButton({ caseId })"
                                                >
                                                    <span>
                                                        <v-icon size="18">mdi-share</v-icon>
                                                    </span>
                                                </v-btn>
                                            </v-card>
                                        </template>
                                        <span>{{ $t('createComments.forwardDescription') }}</span>
                                    </v-tooltip>
                                </div>
                                <div class="flex-row d-flex align-center">
                                    <v-tooltip v-if="!isBasic" top>
                                        <template #activator="{ on, attrs }">
                                            <v-card elevation="0" color="transparent" v-bind="attrs" v-on="on">
                                                <v-btn
                                                    :disabled="sendAndCloseDisabled"
                                                    color="primary"
                                                    depressed
                                                    small
                                                    class="send-buttons"
                                                    @click="saveComment({ closeCase: true })"
                                                >
                                                    <div v-if="external" class="inner-send-button-container">
                                                        <v-icon size="18">mdi-send</v-icon>
                                                        {{ $t('createComments.close') }}
                                                    </div>
                                                    <div v-else class="inner-send-button-container">
                                                        <v-icon size="18">mdi-plus</v-icon>
                                                        {{ $t('createComments.close') }}
                                                    </div>
                                                </v-btn>
                                            </v-card>
                                        </template>
                                        <div v-if="!emailsValid && external">
                                            {{ $t('createComments.noExtMail') }}
                                        </div>
                                        <div v-else-if="external">
                                            {{ $t('createComments.sendClose') }}
                                        </div>
                                        <div v-else>{{ $t('createComments.saveClose') }}</div>
                                    </v-tooltip>
                                </div>
                                <div class="flex-row d-flex align-center">
                                    <v-card color="primary" class="d-flex" depressed :loading="loading">
                                        <v-tooltip top>
                                            <template #activator="{ on, attrs }">
                                                <v-btn
                                                    class="send-buttons d-flex align-center px-4"
                                                    :disabled="sendDisabled"
                                                    small
                                                    color="transparent"
                                                    elevation="0"
                                                    v-bind="attrs"
                                                    cy-data="sendComment"
                                                    v-on="on"
                                                    @click="saveComment()"
                                                >
                                                    <div class="d-flex justify-space-between">
                                                        <span v-if="external">
                                                            <v-icon color="white" size="18">mdi-send</v-icon>
                                                        </span>
                                                        <span v-else>
                                                            <v-icon color="white" size="18">mdi-plus</v-icon>
                                                        </span>
                                                    </div>
                                                </v-btn>
                                            </template>
                                            <div v-if="!emailsValid && external && !isBasic">
                                                {{ $t('createComments.noExtMail') }}
                                            </div>
                                            <div v-else-if="external">{{ $t('global.btn.send') }}</div>
                                            <div v-else>{{ $t('global.btn.save') }}</div>
                                        </v-tooltip>

                                        <v-divider color="white" vertical />

                                        <v-menu v-if="!isForwarding" left>
                                            <template #activator="{ on, attrs }">
                                                <v-card
                                                    color="transparent"
                                                    :disabled="((!emailsValid && external) || isReadOnly) && !isBasic"
                                                    small
                                                    elevation="0"
                                                    class="d-flex align-center px-1"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon small color="white">mdi-chevron-down</v-icon>
                                                </v-card>
                                            </template>
                                            <v-card>
                                                <v-list dense>
                                                    <v-list-item
                                                        v-for="(action, index) in sendActions"
                                                        :key="index"
                                                        @click="fireAction(action)"
                                                    >
                                                        <v-list-item-title>
                                                            {{ action.name }}
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-card>
                                        </v-menu>
                                    </v-card>
                                </div>
                            </section>
                        </section>
                    </div>
                </section>
            </section>
        </section>
    </section>
</template>
<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import { replaceTemplateValues } from '@/utils/index';

    import { getTextColorByBackground } from '@/utils/Colors';
    import { sanitizeImageSrc } from '../../directives/shadowDom';
    import { states } from '../../enums/client.enums';

    import ChatGPT from '../Global/ChatGPT/ChatGPT.vue';
    import TipTapHeader from './Comments/TipTapHeader.vue';
    import TiptapEditor from '../Global/TiptapEditor.vue';
    import Toolbar from './Comments/Toolbar.vue';

    export default {
        components: {
            ChatGPT,
            TipTapHeader,
            TiptapEditor,
            Toolbar,
        },
        props: {
            caseId: {
                type: String,
                default: '',
            },
            templateLanguage: {
                type: String,
                default: null,
            },
        },
        data() {
            return {
                dragover: false,
                dragNdrop: null,
                attachConversation: false,
                sideViewFunctions: {},
                template: null,
                loading: false,
                saveCommentState: false,
                sendActions: [{ name: this.$t('createComments.createSubCase'), value: 'create_subcase' }],
                selectedAction: null,
                sending: false,
            };
        },
        computed: {
            ...mapGetters({
                isReadOnly: 'Cases/isReadOnly',
                isBasic: 'Cases/isBasic',
                allCommentsChecked: 'Comments/getAllCommentsChecked',
            }),

            ...mapState({
                selectedSignature: (state) => state.Cases.selectedCaseSignature,
                loggedInUser: (state) => state.Auth.userObject,
                selectedCase: (state) => state.Cases.selectedCase,
                caseToLoad: (state) => state.Cases.caseToLoad,
                tiptapState: (state) => state.Comments.tiptapState,
                tiptapHeight: (state) => state.Comments.tiptapHeight,
                showAttachCheckBoxes: (state) => state.Forwarder.showAttachCheckBoxes,
                attachChecked: (state) => state.Comments.attachChecked,
                userSettings: (state) => state.System.userSettings,
                clientNowOnCard: (state) => state.Cases.clientNowOnCard,
                reversedCommentsOrder: (state) => state.Comments.reversedCommentsOrder,
                userPreferences: (state) => state.Users.userPreferences,

                // Email
                subject: (state) => state.Comments.subject,
                content: (state) => state.Comments.content,
                emailToRecipients: (state) => state.Comments.emailToRecipients,
                emailCcRecipients: (state) => state.Comments.emailCcRecipients,
                emailBccRecipients: (state) => state.Comments.emailBccRecipients,
                emailFrom: (state) => state.Comments.emailFrom,
                isForwarding: (state) => state.Comments.isForwarding,
                repliedComment: (state) => state.Comments.repliedComment,
                // Files
                files: (state) => state.Files.files,

                // Template
                temporaryTemplate: (state) => state.Cases.temporaryTemplate,
                templates: (state) => state.Admin.templates,
                queues: (state) => state.QueueManager.allQueues,

                // Client card state
                clientCardState: (state) => state.Client.clientCardState,
            }),
            ...mapGetters({
                emailsValid: 'Comments/emailsValid',
            }),

            autoCompleteToggle() {
                return this.userPreferences.autocompleteInComment;
            },

            isSocial() {
                return this.selectedCase?.case?.internalType === 'social';
            },

            caseIsClosed() {
                return this.selectedCase?.case?.status === 'Closed';
            },

            currentQueueLanguage() {
                const currentQueueId = this.selectedCase.case.queueId;
                return this.queues.find((q) => q.ID === currentQueueId)?.Language ?? null;
            },

            external: {
                get() {
                    return this.$store.state.Comments.currentExternalState;
                },
                set(val) {
                    this.$store.dispatch('Comments/setCurrentExternalState', val);
                },
            },
            caseCommentsWidth: {
                get() {
                    return this.$store.state.Comments.caseCommentsWidth;
                },
                set(val) {
                    this.$store.commit('Comments/SET_CASE_COMMENTS_WIDTH', val);
                },
            },

            showCcs: {
                get() {
                    return this.$store.state.Comments.showCcs;
                },
                set(val) {
                    this.setShowCCs(val);
                },
            },

            dockShadowStyle() {
                // set the height if its full screen
                return {
                    height: this.tiptapState === 'full' ? '90vh' : 'auto',
                    maxHeight: this.tiptapState === 'full' ? '90vh' : 'auto',
                    minHeight: this.tiptapState === 'open' ? this.tiptapHeight + 'px' : 'auto',
                };
            },

            dockShadowClass() {
                return {
                    'dock-shadow-full': this.tiptapState === 'full',
                };
            },
            sendAndCloseDisabled() {
                return (!this.emailsValid && this.external) || this.isReadOnly || this.caseIsClosed;
            },
            sendDisabled() {
                return (((!this.emailsValid && this.external) || this.isReadOnly) && !this.isBasic) || this.sending;
            },
        },
        watch: {
            external(val) {
                if (val === false) {
                    this.showCcs = false;
                }
                this.checkColorOfField();
            },

            temporaryTemplate(val) {
                this.template = val;
            },

            userSettings: {
                immediate: true,
                handler() {
                    this.sideViewFunctions = this.userSettings.cases.sideViewFunctions;
                },
            },

            attachConversation() {
                this.showAttachCheckboxes = this.attachConversation;
            },

            caseToLoad: {
                handler() {
                    this.retrieveCommentData();
                },
                immediate: true,
            },
        },

        mounted() {
            // Add event listener to detect Ctrl + Enter key combination
            document.addEventListener('keydown', this.handleKeyDown);
        },
        beforeDestroy() {
            // Remove event listener when component is destroyed
            document.removeEventListener('keydown', this.handleKeyDown);
        },
        destroyed() {
            this.sidebarView('default');
        },

        created() {
            if (!this.queues.length) {
                this.getAllQueues();
            }

            this.checkColorOfField(this.external);
        },

        methods: {
            ...mapActions({
                // Comments
                closeTiptap: 'Comments/closeTiptap',
                setCommentContent: 'Comments/setCommentContent',
                setCommentFiles: 'Comments/setCommentFiles',
                setFilesToCase: 'Files/setFilesToCase',
                clearCommentFiles: 'Comments/clearCommentFiles',
                clearAttachChecked: 'Comments/clearAttachChecked',
                checkAllComments: 'Comments/checkAllComments',
                setShowCCs: 'Comments/setShowCCs',

                // Forwarder
                toggleShowAttachCheckBoxes: 'Forwarder/toggleShowAttachCheckBoxes',
                executeForwardButton: 'Forwarder/executeForwardButton',

                // Cases
                handleReadOnlyClick: 'Cases/handleReadOnlyClick',
                sendComment: 'Cases/sendComment',
                sideBarView: 'Cases/sideBarView',
                caseToPdf: 'Cases/caseToPdf',

                // Social
                publishSocialComment: 'Social/publish',

                // QueueManager
                getAllQueues: 'QueueManager/getAllQueues',

                // Client
                toggleClientCard: 'Client/toggleClientCard',

                // Files
                clearAllFiles: 'Files/clearAllFiles',

                // Users
                setUserSettings: 'Users/setUserSettings',
                getUserSettings: 'Users/getUserSettings',
            }),
            tiptapChange(obj) {
                const { content, files } = obj;
                const { caseId } = this;

                this.setCommentContent({ content, caseId });
                this.setFilesToCase({ files, caseId });
            },
            fireAction(action) {
                this.selectedAction = action.value;
                this.saveComment();
            },
            async handleInitExternalToggle() {
                const activeRes = await this.getActiveIntegrations();

                const hasEmailIntegrationActive = activeRes?.GMAIL || activeRes?.OUTLOOK || activeRes?.EXCHANGE;

                if (!hasEmailIntegrationActive) {
                    this.showDisabledRecipient = true;
                    this.disableStateToggle = true;
                    this.external = false;
                } else {
                    this.showDisabledRecipient = false;
                    this.disableStateToggle = false;
                    this.external = true;
                }
            },

            commentIsChecked(commentId) {
                return this.attachChecked.includes(commentId);
            },

            getTextColorByBackground,

            closeCase() {
                this.$router.push('/cases');
            },

            decideAction(type) {
                if (type === 'pdf') {
                    this.openPdf();
                    return;
                }
                this.sidebarView(type);

                // Open the sidebar if its closed
                if (this.clientCardState === states.CLOSED) {
                    this.toggleClientCard();
                }
            },

            retrieveCommentData() {
                const caseStringLocalStorage = this.isForwarding ? '__caseContentForward_' : '__caseContent_';

                const localContent = localStorage.getItem(caseStringLocalStorage + this.caseToLoad);
                if (!localContent) {
                    this.setCommentContent({ content: '', caseId: this.caseToLoad });
                    return;
                }
                let isValidJson = false;
                let parsedContent = {};
                try {
                    parsedContent = JSON.parse(localContent);
                    isValidJson = true;
                } catch {
                    isValidJson = false;
                }
                if (!isValidJson) {
                    this.setCommentContent({ content: '', caseId: this.caseToLoad });
                    return;
                }

                const { content } = parsedContent;
                const { contactParsedName } = this.selectedCase.case;
                if (content) {
                    this.setCommentContent({ content, caseId: this.caseToLoad });
                    return;
                }

                const initialCaseGreetings = this.userSettings.cases.initialCaseGreetings.active;

                if (contactParsedName) {
                    const greeting = initialCaseGreetings
                        ? `${this.$t('createComments.hi')} ${contactParsedName}!`
                        : '';
                    this.setCommentContent({ content: greeting, caseId: this.caseToLoad });
                    return;
                }

                this.setCommentContent({ content: '', caseId: this.caseToLoad });
            },

            sidebarView(type) {
                this.sideBarView(type);
            },

            checkColorOfField() {
                if (this.external) {
                    return '#E1F1F5';
                }
                return '#efefef';
            },

            validateSaveComment() {
                const hasNoContent = !this.content || this.content.trim() === '<br>';
                if (hasNoContent) {
                    this.noContentToast();
                    return false;
                }

                return true;
            },

            checkIfAttachedCommentsHasFiles() {
                if (!this.attachChecked.length) {
                    return false;
                }
                let attachedCommentsHasFiles = false;

                for (const comment of this.selectedCase.comments) {
                    if (!this.attachChecked.includes(comment.id)) continue;

                    if (comment.attachments?.length > 0 || comment.attachment === 1) {
                        attachedCommentsHasFiles = true;
                        break;
                    }
                }
                return attachedCommentsHasFiles;
            },

            async saveSocialComment() {
                this.loading = true;
                const payload = {
                    text: this.content,
                    caseId: this.selectedCase.case.caseId,
                };
                const result = await this.publishSocialComment(payload);

                if (result.status === 201) {
                    this.setCommentContent({ data: '', caseId: this.caseId });
                    this.closeTiptap();
                }

                this.loading = false;
            },

            async saveComment(options = {}) {
                const { closeCase = false } = options;

                const attachWithoutAskingSetting =
                    this.userSettings.cases.attachConversationSettings?.allwaysAttachThreadedFiles?.active;

                // * is the function active or we have selected some threaded files
                let attachThreadedFiles = false;

                const valid = this.validateSaveComment();

                if (!valid) return;

                if (this.isSocial && this.external) {
                    await this.saveSocialComment();
                    return;
                }

                const hasAttachments = this.checkIfAttachedCommentsHasFiles();

                // * we should trigger the popup if we are not forwarding, we have files in the attached thread, we are external, we have threadedFiles enabled and we have the checkbox enabled
                const shouldTriggerThreadAttachModal =
                    !this.isForwarding &&
                    hasAttachments &&
                    this.external &&
                    !attachWithoutAskingSetting &&
                    this.showAttachCheckBoxes;

                if (shouldTriggerThreadAttachModal) {
                    const { confirmed } = await this.$global.dialogs.showConfirmationDialog({
                        title: this.$t('createComments.confirmationTitle'),
                        message: this.$t('createComments.confirmationBody'),
                        confirmText: this.$t('global.yes'),
                        declineText: this.$t('global.no'),
                    });

                    if (!confirmed) {
                        attachThreadedFiles = false;
                    } else {
                        attachThreadedFiles = true;
                    }
                } else {
                    attachThreadedFiles = attachWithoutAskingSetting && hasAttachments;
                }

                let signatureToSend = this.selectedSignature.Content;

                if (this.selectedSignature.Content) {
                    signatureToSend = replaceTemplateValues(sanitizeImageSrc(this.selectedSignature.Content), {
                        client: this.clientNowOnCard?.data || null,
                        agent: this.loggedInUser,
                        sender: this.emailFrom,
                    });
                }
                const content = this.$refs.tiptapEditor.save();

                const payload = {
                    receiver: this.external ? this.emailToRecipients[0] : null,
                    comment: content,
                    caseId: this.selectedCase.case.caseId,
                    from: this.external ? this.emailFrom : this.loggedInUser.userName,
                    outgoing: this.external ? 1 : 0,
                    attachments: this.files.filter((file) => file.caseId === this.caseId),
                    typeOfMessage: this.external ? 'email' : 'internal',
                    type: this.external ? 1 : 0,
                    subject: this.subject,
                    isForwarding: this.isForwarding,
                    to: this.external ? this.emailToRecipients : [],
                    ccTo: this.external ? this.emailCcRecipients : [],
                    bccTo: this.external ? this.emailBccRecipients : [],
                    attachConversation:
                        this.attachChecked.length && this.showAttachCheckBoxes && !this.isForwarding ? 1 : 0,
                    threadedAttaches: attachThreadedFiles ? 1 : 0,
                    userId: this.loggedInUser.userId,
                    signature: this.external ? signatureToSend : '',
                    attachComments: this.showAttachCheckBoxes && !this.isForwarding ? this.attachChecked : [],
                    closeCase,
                    repliedComment: this.repliedComment,
                };

                // * if we are forwarding we want to default create a subcase
                if (this.isForwarding) {
                    payload.action = this.sendActions[0].value;
                    // * else if we have explicitly selected an action we want to use that
                } else if (this.selectedAction) {
                    payload.action = this.selectedAction;
                }
                this.sending = true;
                const res = await this.sendComment(payload);
                this.sending = false;

                // Clear tiptap

                if (res.status === 200) {
                    if (closeCase) {
                        this.closeCaseIfCloseOnSend();
                    }

                    // 1. Update local storage content and subject
                    localStorage.removeItem('__caseContent_' + this.caseToLoad);
                    localStorage.removeItem('__caseContentForward_' + this.caseToLoad);

                    // 2. Close the tiptap
                    this.closeTiptap();
                    this.clearCommentFiles();
                    this.clearAllFiles(this.selectedCase.case.caseId);

                    if (this.userSettings.cases.attachConversationSettings?.attachConversation?.active) {
                        this.checkAllComments();
                    }

                    localStorage.removeItem('__caseHiddenSignatureId_' + this.caseToLoad);

                    const SCROLL_TIMEOUT = 1000;

                    await new Promise((resolve) => setTimeout(resolve, SCROLL_TIMEOUT));
                    this.$nextTick(() => {
                        const element = document.querySelector('#conversation-container');
                        if (!element) return;
                        if (this.reversedCommentsOrder) {
                            if (element) {
                                element.scrollTop = element.scrollHeight;
                                return;
                            }
                            return;
                        }
                        element.scrollTop = 0;
                    });
                }
            },

            closeCaseIfCloseOnSend() {
                if (this.userSettings.cases?.closeOnSendClose?.active) {
                    this.closeCase();
                }
            },

            setAttachConversation() {
                this.attachConversation = !this.attachConversation;
            },
            handleSignatureColor(item) {
                return item.sideView === 'signature' && Object.keys(this.selectedSignature).length !== 0
                    ? 'primary'
                    : null;
            },
            noContentToast() {
                this.$toasted.show(this.$t('createComments.writeSomething'), {
                    icon: 'error',
                    type: 'error',
                    duration: 5000,
                });
            },
            openPdf() {
                this.caseToPdf(this.caseId);
            },
            handleKeyDown(event) {
                const ctrlShiftEnter = event.ctrlKey && event.shiftKey && event.key === 'Enter';
                if (
                    ctrlShiftEnter &&
                    !this.sendAndCloseDisabled &&
                    this.userSettings?.cases.shortCuts.sendAndClose.active
                ) {
                    this.saveComment({ closeCase: true });
                    return;
                }
                const ctrlEnter = event.ctrlKey && event.key === 'Enter';
                if (ctrlEnter && !this.sendDisabled && this.userSettings?.cases.shortCuts.send.active) {
                    this.saveComment({ closeCase: false });
                }
            },
            async toggleAutocompleteInComment() {
                const settings = {
                    ...this.userPreferences,
                    autocompleteInComment: !this.autoCompleteToggle,
                };
                await this.setUserSettings({ userId: this.loggedInUser.userId, settings });
                await this.getUserSettings(this.loggedInUser.userId);
            },
        },
    };
</script>
<style lang="scss" scoped>
    :deep(.mdi-paperclip) {
        font-size: 20px !important;
    }

    #tiptap {
        transition: max-height 0.05s ease;
    }

    .read-only-overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        cursor: default;
        transition: opacity 0.3s ease;
    }

    .inner-send-button-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    .bottom-functions {
        display: flex;
        flex-direction: row;
        gap: 4px;
    }

    .bottom-functions > * {
        display: flex;
        align-items: center;
    }
    .bottom-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .dock-shadow {
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15) !important;
        border-radius: 12px;
        background-color: white;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        overflow: hidden;
        position: relative;
    }

    :deep(
            .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
                .v-input__prepend-inner
        ) {
        margin-top: 0px;
        margin-top: auto;
        margin-bottom: auto;
    }

    .attachment-icon {
        color: 'var(--v-gray2-base';
    }

    .create-comments-inner-container {
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr auto;
        gap: 8px;
    }

    .tiptap-container-outer {
        display: flex;
        flex-direction: column;
        align-content: stretch;
        justify-content: stretch;
        flex: 1;
        position: relative;
    }

    .send-buttons {
        font-weight: 400;
    }

    .send-buttons-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
    }
    .send-button-text {
        font-size: 12px;
    }
</style>
<i18n lang="json">
{
    "en": {
        "createComments": {
            "message1": "The case is in the queue waiting to be picked up by automatic assignment",
            "recpLocked": "The recipient is locked to the case.",
            "attachToBottom": "Attach to the bottom of the page.",
            "dropFiles": "Drop files here",
            "tglAutocomplete": "Toggle autocomplete",
            "attachFiles": "Attach file(s).",
            "attachMailThread": "Attach mail thread.",
            "dontAttachMailThread": "Don't attach mail thread",
            "sendClose": "Send & Close",
            "saveClose": "Save & Close",
            "noExtMail": "Cannot send an external mail where the recipient does not have a valid email address.",
            "fileTooBig": "The file is too big",
            "filesTooBig": "The files are too big",
            "hi": "Hello",
            "writeSomething": "You have to write something",
            "templates": "Templates",
            "mobile": "Mobile",
            "recipient": "Recipient",
            "copy": "Cc",
            "blindCopy": "Bcc",
            "ccAndBcc": "Cc and bcc",
            "expand": "Expand",
            "minimize": "Minimize",
            "internal": "Internal",
            "external": "External",
            "subject": "Subject",
            "lock": "Lock position",
            "unlock": "Edit position",
            "markAll": "Mark all",
            "unmarkAll": "Unmark all",
            "forward": "Forward",
            "answer": "Answer",
            "answerAll": "Answer all",
            "confirmationTitle": "Send attached files",
            "confirmationBody": "Some of the threaded messages have attachments. Do you want to send them as well?",
            "dockWindow": "Undock window",
            "dockBottom": "Dock window to bottom",
            "close": "Close",
            "maximize": "Maximize",
            "forwardDescription": "Forward the conversation to another recipient",
            "createSubCase": "Create subcase"
        }
    },
    "sv": {
        "createComments": {
            "message1": "Ärendet är i kön och väntar på att plockas upp av automatisk tilldelning",
            "recpLocked": "Mottagaren är låst till ärendet.",
            "attachToBottom": "Fäst mot botten av sidan.",
            "dropFiles": "Släpp filer här",
            "tglAutocomplete": "Växla autocomplete",
            "attachFiles": "Bifoga fil(er).",
            "attachMailThread": "Bifoga mailtråden.",
            "dontAttachMailThread": "Bifoga inte mailtråden",
            "sendClose": "Skicka & Stäng",
            "saveClose": "Spara & Stäng",
            "noExtMail": "Kan inte skicka externt mail där mottagaren inte har en giltig e-post.",
            "fileTooBig": "Filen är för stor",
            "filesTooBig": "Filerna är för stora",
            "hi": "Hej",
            "writeSomething": "Du måste skriva något",
            "templates": "Mallar",
            "mobile": "Mobil",
            "recipient": "Mottagare",
            "copy": "Kopia",
            "blindCopy": "Hemlig kopia",
            "ccAndBcc": "Kopia och hemlig kopia",
            "expand": "Expandera",
            "minimize": "Minimera",
            "internal": "Intern",
            "external": "Extern",
            "subject": "Ämne",
            "lock": "Lås position",
            "unlock": "Redigera position",
            "markAll": "Markera alla",
            "unmarkAll": "Avmarkera alla",
            "forward": "Vidarebefordra",
            "answer": "Svara",
            "answerAll": "Svara alla",
            "confirmationTitle": "Skicka bifogade filer",
            "confirmationBody": "Vissa av de trådade meddelandena har bilagor. Vill du skicka dem också?",
            "dockWindow": "Lossa fönster",
            "dockBottom": "Fäst fönster mot botten",
            "close": "Stäng",
            "maximize": "Maximera",
            "forwardDescription": "Vidarebefordra konversationen till en annan mottagare",
            "createSubCase": "Skapa underärende"
        }
    }
}
</i18n>
