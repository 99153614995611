module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "profilePicture": {
          "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign the case to Yourself"])}
        }
      },
      "sv": {
        "profilePicture": {
          "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilldela ärendet till dig själv"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"profilePicture":{"assign":"Assign the case to Yourself"}},"sv":{"profilePicture":{"assign":"Tilldela ärendet till dig själv"}}}')
  delete Component.options._Ctor
  
}
