import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"v-card mx-auto px-3",attrs:{"flat":""}},[(_vm.activeTemplates.length > 0)?_c(VList,[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"noSignature",attrs:{"icon":""},on:{"click":function($event){return _vm.setSelectSignature({})}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-minus-circle-outline")])],1)]}}],null,false,1976066805)},[_c('span',[_vm._v(_vm._s(_vm.$t('SignatureView.noSignature')))])]),_vm._l((_vm.activeTemplates),function(item,i){return _c(VTooltip,{key:item.ID,staticStyle:{"z-index":"20"},attrs:{"right":"","openDelay":"200","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VListItem,_vm._g(_vm._b({staticClass:"listItem",class:_vm.selectedSignature.ID == item.ID ? 'activeListItem' : '',on:{"click":function($event){return _vm.setSelectSignature(item)},"mouseover":function($event){return _vm.setSignatureImages(i)}}},'v-list-item',attrs,false),on),[_c(VListItemTitle,[_vm._v(_vm._s(item.Name))])],1)]}}],null,true)},[_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(item.Content),expression:"item.Content"}],staticClass:"signatureTooltip"})])})],2):_c('div',{staticClass:"ma-5"},[_vm._v(" "+_vm._s(_vm.$t('SignatureView.noSignatures'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('SignatureView.addGotoTempl'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('SignatureView.onlyActivated'))+" "),_c('br'),_c('br'),_c(VDivider),_c('br'),_c(VBtn,{attrs:{"dense":"","color":"primary","elevation":"0"},on:{"click":function($event){return _vm.$router.replace('/Admin/Templates')}}},[_vm._v(" "+_vm._s(_vm.$t('SignatureView.templateManager'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }