<template>
    <div>
        <IntegrationButton :integrationName="integration.name" @click="openModal" />
        <v-dialog v-model="open" max-width="500px">
            <GetMemberButton :key="selectedClientId" :ssn="selectedClientSSN" @close="open = false" />
        </v-dialog>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import IntegrationButton from './IntegrationButton.vue';
    import GetMemberButton from './SverigesAkassor/GetMemberButton.vue';

    export default {
        components: {
            IntegrationButton,
            GetMemberButton,
        },
        props: {
            integration: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                open: false,
            };
        },
        computed: {
            ...mapGetters({
                selectedClientId: 'Client/selectedClientId',
                selectedClientSSN: 'Client/selectedClientSSN',
            }),
        },
        methods: {
            openModal() {
                this.open = true;
            },
        },
    };
</script>
