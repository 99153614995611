module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "bankIdButton": {
          "bankID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BankID"])},
          "verCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for confirmation..."])},
          "verSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer authentication succeeded!"])},
          "ssn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social security number"])},
          "ssnNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social security number not valid"])},
          "errMess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. BankID response"])},
          "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, try again in a few minutes!"])},
          "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])}
        }
      },
      "sv": {
        "bankIdButton": {
          "bankID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BankID"])},
          "verCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väntar på bekräftelse..."])},
          "verSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundautentisering lyckades!"])},
          "ssn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnummer"])},
          "ssnNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnumret är inte giltigt"])},
          "errMess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Något gick fel, BankID svar"])},
          "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Något gick fel, testa igen om några minuter!"])},
          "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifiera"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"bankIdButton":{"bankID":"BankID","verCustomer":"Waiting for confirmation...","verSuccess":"Customer authentication succeeded!","ssn":"Social security number","ssnNotValid":"Social security number not valid","errMess":"Something went wrong. BankID response","somethingWentWrong":"Something went wrong, try again in a few minutes!","verify":"Verify"}},"sv":{"bankIdButton":{"bankID":"BankID","verCustomer":"Väntar på bekräftelse...","verSuccess":"Kundautentisering lyckades!","ssn":"Personnummer","ssnNotValid":"Personnumret är inte giltigt","errMess":"Något gick fel, BankID svar","somethingWentWrong":"Något gick fel, testa igen om några minuter!","verify":"Verifiera"}}}')
  delete Component.options._Ctor
  
}
