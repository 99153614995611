import { format, subDays, parseISO, isThisYear } from 'date-fns';
import { sv, enGB } from 'date-fns/locale';

import i18n from '../i18n';

import { timeConvertions } from '../enums/date.enums';

const { MILLISECONDS_IN_SECOND, SECONDS_IN_MINUTE, MINUTES_IN_HOUR, HOURS_IN_DAY, DAYS_IN_WEEK } = timeConvertions;

export function getDay(v) {
    if (!v) {
        return '';
    }

    const value = format(new Date(v), 'yyyy-MM-dd');
    const time = format(new Date(v), 'HH:mm');

    const today = format(new Date(), 'yyyy-MM-dd');
    const yesterDay = format(subDays(new Date(), 1), 'yyyy-MM-dd');

    switch (value) {
        case today: {
            // return `Idag ${time}`;
            return i18n.t('global.today') + ` ${time}`;
        }

        case yesterDay: {
            // return `Igår ${time}`;
            return i18n.t('global.yesterday') + ` ${time}`;
        }
        default: {
            return `${value} ${time}`;
        }
    }
}

export function convertDateToText(date) {
    const englishDay = format(new Date(date), 'EEEEEE');
    switch (englishDay) {
        case 'Mo': {
            // return [`Mån ${format(new Date(date),'dd/MM')}`, 'Måndag', 'black']
            return [
                i18n.t('global.shortWDays.mon') + ` ${format(new Date(date), 'dd/MM')}`,
                i18n.t('global.weekDays.monday'),
                'black',
            ];
        }
        case 'Tu': {
            // return [`Tis ${format(new Date(date),'dd/MM')}`, 'Tisdag', 'black']
            return [
                i18n.t('global.shortWDays.tue') + ` ${format(new Date(date), 'dd/MM')}`,
                i18n.t('global.weekDays.tuesday'),
                'black',
            ];
        }
        case 'We': {
            // return [`Ons ${format(new Date(date),'dd/MM')}`, 'Onsdag', 'black']
            return [
                i18n.t('global.shortWDays.wed') + ` ${format(new Date(date), 'dd/MM')}`,
                i18n.t('global.weekDays.wednesday'),
                'black',
            ];
        }
        case 'Th': {
            // return [`Tor ${format(new Date(date),'dd/MM')}`, 'Torsdag', 'black']
            return [
                i18n.t('global.shortWDays.thu') + ` ${format(new Date(date), 'dd/MM')}`,
                i18n.t('global.weekDays.thursday'),
                'black',
            ];
        }
        case 'Fr': {
            // return [`Fre ${format(new Date(date),'dd/MM')}`, 'Fredag', 'black']
            return [
                i18n.t('global.shortWDays.fri') + ` ${format(new Date(date), 'dd/MM')}`,
                i18n.t('global.weekDays.friday'),
                'black',
            ];
        }
        case 'Sa': {
            // return [`Lör ${format(new Date(date),'dd/MM')}`, 'Lördag', 'grey']
            return [
                i18n.t('global.shortWDays.sat') + ` ${format(new Date(date), 'dd/MM')}`,
                i18n.t('global.weekDays.saturday'),
                'grey',
            ];
        }
        case 'Su': {
            // return [`Sön ${format(new Date(date),'dd/MM')}`, 'Söndag', 'grey']
            return [
                i18n.t('global.shortWDays.sun') + ` ${format(new Date(date), 'dd/MM')}`,
                i18n.t('global.weekDays.sunday'),
                'grey',
            ];
        }
        default: {
            console.info('invalid date in convertDateToText()');
        }
    }
}

export function getMonthByNumber(monthNumber) {
    // get month by number
    switch (monthNumber) {
        case 1:
        case '01': {
            return i18n.t('global.months.jan');
        }
        case 2:
        case '02': {
            return i18n.t('global.months.feb');
        }
        case 3:
        case '03': {
            return i18n.t('global.months.mar');
        }
        case 4:
        case '04': {
            return i18n.t('global.months.apr');
        }
        case 5:
        case '05': {
            return i18n.t('global.months.may');
        }
        case 6:
        case '06': {
            return i18n.t('global.months.jun');
        }
        case 7:
        case '07': {
            return i18n.t('global.months.jul');
        }
        case 8:
        case '08': {
            return i18n.t('global.months.aug');
        }
        case 9:
        case '09': {
            return i18n.t('global.months.sep');
        }
        case 10:
        case '10': {
            return i18n.t('global.months.oct');
        }
        case 11:
        case '11': {
            return i18n.t('global.months.nov');
        }
        case 12:
        case '12': {
            return i18n.t('global.months.dec');
        }
        default: {
            console.info('invalid month in getMonthByNumber()');
            return 'invalid month';
        }
    }
}

export function formatTimeFromSeconds(seconds) {
    let minutes = Math.floor(seconds / SECONDS_IN_MINUTE);
    let hours = Math.floor(minutes / MINUTES_IN_HOUR);
    let minutesLeft = minutes % MINUTES_IN_HOUR;
    let secondsLeft = Math.floor(seconds % SECONDS_IN_MINUTE);

    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    if (minutesLeft < 10) {
        minutesLeft = '0' + minutesLeft;
    }
    if (secondsLeft < 10) {
        secondsLeft = '0' + secondsLeft;
    }
    let result;
    if (hours > 0) {
        result = hours + ':' + minutesLeft + ':' + secondsLeft;
    } else {
        result = minutes + ':' + secondsLeft;
    }
    return result;
}

export function formatTimeToText(seconds) {
    if (seconds < 0) {
        return 'Invalid input';
    }

    const milliseconds = Math.floor((seconds % 1) * MILLISECONDS_IN_SECOND);
    seconds = Math.floor(seconds);
    let minutes = Math.floor(seconds / SECONDS_IN_MINUTE);
    seconds %= SECONDS_IN_MINUTE;
    let hours = Math.floor(minutes / MINUTES_IN_HOUR);
    minutes %= MINUTES_IN_HOUR;
    let days = Math.floor(hours / HOURS_IN_DAY);
    hours %= HOURS_IN_DAY;
    const weeks = Math.floor(days / DAYS_IN_WEEK);
    days %= DAYS_IN_WEEK;

    const timeUnits = [];

    if (weeks > 0) {
        timeUnits.push(weeks + ' w');
    }
    if (days > 0) {
        timeUnits.push(days + ' d');
    }
    if (hours > 0) {
        timeUnits.push(hours + ' h');
    }
    if (minutes > 0) {
        timeUnits.push(minutes + ' m');
    }
    if (seconds > 0) {
        timeUnits.push(seconds + ' s');
    }
    if (milliseconds > 0) {
        timeUnits.push(milliseconds + ' ms');
    }

    if (timeUnits.length === 0) {
        return '0 s';
    }
    return timeUnits.join(' ');
}

export function formatDateToText(date, locale = 'sv') {
    // use this.$i18n.locale as locale param

    const dateFormat = new Date(date);
    switch (locale) {
        case 'en': {
            locale = enGB;
            break;
        }
        default: {
            locale = sv;
        }
    }
    // If the date is from the current year but not the current month
    if (isThisYear(dateFormat)) {
        return format(dateFormat, 'd MMM HH:mm', { locale });
    }

    // If the date is not from the current year
    return format(dateFormat, 'd MMM yyyy HH:mm', { locale });
}
