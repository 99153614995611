<template>
    <OpenExternal :href="item.href">
        <template #default="{ onClick }">
            <v-list-item class="item" @click="onClick">
                <v-list-item-action class="m-auto">
                    <v-icon>
                        {{ item.icon }}
                    </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title :class="`text-body-3 font-weight-regular `">
                        {{ item.title[$i18n.locale] }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </OpenExternal>
</template>
<script>
    import OpenExternal from '@/components/Global/OpenExternal.vue';

    export default {
        components: {
            OpenExternal,
        },
        props: {
            item: {
                type: Object,
                default: {},
            },
        },
    };
</script>
<style scoped>
    :deep(.v-list-item) {
        justify-content: start !important;
    }
    .item {
        display: flex;
        align-items: center;

        background-color: transparent;
    }
</style>
<i18n lang="json">
{
    "en": {
        "recursiveListItem": {
            "popOut": "Pop out"
        }
    },
    "sv": {
        "recursiveListItem": {
            "popOut": "Poppa ut"
        }
    }
}
</i18n>
