import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{ref:"toolbar-container",staticClass:"editor-area-inner-container",style:(_vm.editorAreaStyle)},[_c('div',{staticClass:"file-input"},[_c(VFileInput,{ref:"fileInput",attrs:{"hide-input":"","truncate-length":"15","multiple":"","append-icon":"mdi-paperclip"},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1),(_vm.editor && _vm.showFontStyles)?_c('section',{staticClass:"editor-area-list"},[_vm._l((_vm.filteredItems.slice(0, _vm.buttonsToShow)),function(item,key){return _c('article',{key:key},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltip){return [(item.title == 'Link')?_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":300,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(menu){return [_c('span',_vm._g({},menu.on),[_c(VBtn,_vm._g(_vm._b({style:({
                                        color:
                                            _vm.selectedText.text &&
                                            _vm.selectedText.text.length &&
                                            _vm.content.includes(_vm.selectedText.text)
                                                ? 'var(--v-color4-base)'
                                                : 'var(--v-gray1-base)',
                                    }),attrs:{"dark":"","icon":""}},'v-btn',tooltip.attrs,false),tooltip.on),[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(_vm._s(item.icon))])],1)],1)]}}],null,true),model:{value:(_vm.menuState),callback:function ($$v) {_vm.menuState=$$v},expression:"menuState"}},[_c(VCard,[_c(VTextField,{staticClass:"pa-1",attrs:{"label":"URL","filled":"","flat":"","autofocus":"","dense":"","hide-details":"","append-icon":"mdi-chevron-right"},on:{"click:append":function($event){return _vm.setLink(_vm.url)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.setLink()}},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1)],1):(item.title == 'Size' || item.title == 'FontFamily')?_c(VMenu,{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(menu){return [_c('span',_vm._g({},menu.on),[_c(VBtn,_vm._g(_vm._b({style:({
                                        color: menu.value ? 'var(--v-gray4-base)' : 'var(--v-gray1-base)',
                                    }),attrs:{"icon":""}},'v-btn',tooltip.attrs,false),tooltip.on),[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(_vm._s(item.icon))])],1)],1)]}}],null,true)},[_c(VList,{staticClass:"tiptapBtnList"},_vm._l((item.options),function(option,index){return _c(VListItem,{key:index,staticClass:"tiptap-button",on:{"click":function($event){return item.action(option)}}},[_vm._v(" "+_vm._s(option == null ? 'Default' : option)+" ")])}),1)],1):(item.title == 'Color')?_c(VMenu,{attrs:{"offset-y":"","top":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(menu){return [_c('span',_vm._g({},menu.on),[_c(VBtn,_vm._g(_vm._b({style:({
                                        color: menu.value ? 'var(--v-gray4-base)' : 'var(--v-gray1-base)',
                                    }),attrs:{"icon":""}},'v-btn',tooltip.attrs,false),tooltip.on),[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(_vm._s(item.icon))])],1)],1)]}}],null,true)},[_c(VColorPicker,{attrs:{"hide-canvas":"","show-swatches":"","swatches":item.options},model:{value:(_vm.colorPickerValue),callback:function ($$v) {_vm.colorPickerValue=$$v},expression:"colorPickerValue"}})],1):(item.title === 'Table options')?_c(VMenu,{attrs:{"offset-y":"","nudge-bottom":"20px","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(menu){return [_c('span',_vm._g({},menu.on),[_c(VBtn,_vm._g(_vm._b({style:(_vm.getButtonColor(item)),attrs:{"icon":""}},'v-btn',tooltip.attrs,false),tooltip.on),[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(_vm._s(item.icon))])],1)],1)]}}],null,true)},[_c(VCard,{staticClass:"d-flex pa-2"},_vm._l((item.options),function(option,index){return _c(VTooltip,{key:index,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":option.action}},on),[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(_vm._s(option.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTranslatedValue(option.title)))])])}),1)],1):_c(VBtn,_vm._g(_vm._b({style:(item.isActive
                                ? item.isActive('strike') == true
                                    ? 'color:' + 'var(--v-gray4-base)'
                                    : 'color:' + 'var(--v-gray1-base)'
                                : 'color:' + 'var(--v-gray4-base)'),attrs:{"icon":""},on:{"click":item.action}},'v-btn',tooltip.attrs,false),tooltip.on),[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTranslatedValue(item.title)))])])],1)}),(_vm.showDots)?_c('article',[_c(VMenu,{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":"","color":"var(--v-gray1-base)"}},on),[_c(VIcon,{attrs:{"size":"20"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,842290063)},[_c(VList,{staticClass:"editor-area-list-hidden"},_vm._l((_vm.hiddenItems),function(item,key){return _c('article',{key:key},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltip){return [(item.title == 'Image')?_c(VMenu,{attrs:{"close-on-content-click":false,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(menu){return [_c('span',_vm._g({},menu.on),[_c(VBtn,_vm._g(_vm._b({style:({
                                                    color: menu.value
                                                        ? 'var(--v-color4-base)'
                                                        : 'var(--v-gray1-base)',
                                                }),attrs:{"icon":""}},'v-btn',tooltip.attrs,false),tooltip.on),[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(_vm._s(item.icon))])],1)],1)]}}],null,true),model:{value:(_vm.toggleUrlInput),callback:function ($$v) {_vm.toggleUrlInput=$$v},expression:"toggleUrlInput"}},[_c(VCard,[_c(VRow,{staticClass:"imageInput",attrs:{"no-gutters":""},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return item.action(_vm.image.url, _vm.image.width)}}},[_c(VCol,[_c(VTextField,{attrs:{"label":"Image URL","autofocus":"","hide-details":""},model:{value:(_vm.image.url),callback:function ($$v) {_vm.$set(_vm.image, "url", $$v)},expression:"image.url"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VTextField,{staticClass:"ma-1",attrs:{"label":"Width","suffix":"px","placeholder":"Auto","hide-details":""},model:{value:(_vm.image.width),callback:function ($$v) {_vm.$set(_vm.image, "width", $$v)},expression:"image.width"}})],1),_c(VCol,{attrs:{"cols":"1","align-self":"center"}},[_c(VIcon,{attrs:{"size":"20"},on:{"click":function($event){return item.action(_vm.image.url, _vm.image.width)}}},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],1):(item.title == 'Link')?_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":300,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(menu){return [_c('span',_vm._g({},menu.on),[_c(VBtn,_vm._g(_vm._b({style:({
                                                    color:
                                                        _vm.selectedText.text &&
                                                        _vm.selectedText.text.length &&
                                                        _vm.content.includes(_vm.selectedText.text)
                                                            ? 'var(--v-color4-base)'
                                                            : 'var(--v-gray1-base)',
                                                }),attrs:{"dark":"","icon":""}},'v-btn',tooltip.attrs,false),tooltip.on),[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(_vm._s(item.icon))])],1)],1)]}}],null,true),model:{value:(_vm.menuState),callback:function ($$v) {_vm.menuState=$$v},expression:"menuState"}},[_c(VCard,[_c(VTextField,{staticClass:"pa-1",attrs:{"label":"URL","filled":"","flat":"","autofocus":"","dense":"","hide-details":"","append-icon":"mdi-chevron-right"},on:{"click:append":function($event){return _vm.setLink(_vm.url)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.setLink()}},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1)],1):(item.title == 'Size' || item.title == 'FontFamily')?_c(VMenu,{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(menu){return [_c('span',_vm._g({},menu.on),[_c(VBtn,_vm._g(_vm._b({style:({
                                                    color: menu.value
                                                        ? 'var(--v-gray4-base)'
                                                        : 'var(--v-gray1-base)',
                                                }),attrs:{"icon":""}},'v-btn',tooltip.attrs,false),tooltip.on),[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(_vm._s(item.icon))])],1)],1)]}}],null,true)},[_c(VList,{staticClass:"tiptapBtnList"},_vm._l((item.options),function(option,index){return _c(VListItem,{key:index,staticClass:"tiptap-button",on:{"click":function($event){return item.action(option)}}},[_vm._v(" "+_vm._s(option == null ? 'Default' : option)+" ")])}),1)],1):(item.title == 'Color')?_c(VMenu,{attrs:{"offset-y":"","top":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(menu){return [_c('span',_vm._g({},menu.on),[_c(VBtn,_vm._g(_vm._b({style:({
                                                    color: menu.value
                                                        ? 'var(--v-gray4-base)'
                                                        : 'var(--v-gray1-base)',
                                                }),attrs:{"icon":""}},'v-btn',tooltip.attrs,false),tooltip.on),[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(_vm._s(item.icon))])],1)],1)]}}],null,true)},[_c(VColorPicker,{attrs:{"hide-canvas":"","show-swatches":"","swatches":item.options},model:{value:(_vm.colorPickerValue),callback:function ($$v) {_vm.colorPickerValue=$$v},expression:"colorPickerValue"}})],1):(item.title === 'Table options')?_c(VMenu,{attrs:{"offset-y":"","offset-x":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(menu){return [_c('span',_vm._g({},menu.on),[_c(VBtn,_vm._g(_vm._b({style:(item.isActive
                                                        ? 'color:' + 'var(--v-gray1-base)'
                                                        : 'color:' + 'var(--v-gray4-base)'),attrs:{"icon":""}},'v-btn',tooltip.attrs,false),tooltip.on),[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(_vm._s(item.icon))])],1)],1)]}}],null,true)},[_c(VCard,{staticClass:"d-flex pa-2"},_vm._l((item.options),function(option,index){return _c(VTooltip,{key:index,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":option.action}},on),[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(_vm._s(option.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTranslatedValue(option.title)))])])}),1)],1):_c(VBtn,_vm._g(_vm._b({style:(item.isActive
                                            ? item.isActive('strike') == true
                                                ? 'color:' + 'var(--v-gray4-base)'
                                                : 'color:' + 'var(--v-gray1-base)'
                                            : 'color:' + 'var(--v-gray4-base)'),attrs:{"icon":""},on:{"click":item.action}},'v-btn',tooltip.attrs,false),tooltip.on),[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTranslatedValue(item.title)))])])],1)}),0)],1)],1):_vm._e()],2):_vm._e(),_c('section',{staticClass:"toggle-tiptap-toolbar"},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.showFontStyles = !_vm.showFontStyles}}},[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.showFontStyles ? 'mdi-minus' : 'mdi-menu'))])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }