module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "actionMenu": {
          "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])}
        }
      },
      "sv": {
        "actionMenu": {
          "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dölj"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"actionMenu":{"hide":"Hide","show":"Show"}},"sv":{"actionMenu":{"hide":"Dölj","show":"Visa"}}}')
  delete Component.options._Ctor
  
}
