import i18n from '@/i18n';

export function getActivityIconAndTranslation(activityType) {
    switch (activityType) {
        case 'JOIN': {
            return {
                icon: 'account-multiple-plus',
                translation: i18n.t('activity.join'),
            };
        }
        case 'LEAVE': {
            return {
                icon: 'account-multiple-minus',
                translation: i18n.t('activity.leave'),
            };
        }
        case 'UPDATE_CASE_DATA': {
            return {
                icon: 'pencil',
                translation: i18n.t('activity.updateCaseData'),
            };
        }
        case 'CREATE_EMAIL': {
            return {
                icon: 'email',
                translation: i18n.t('activity.createEmail'),
            };
        }
        case 'CREATE_EMAIL_FORWARD': {
            return {
                icon: 'email',
                translation: i18n.t('activity.createEmailForward'),
            };
        }
        case 'CREATE_CALL': {
            return {
                icon: 'phone',
                translation: i18n.t('activity.createCall'),
            };
        }
        case 'CREATE_SMS': {
            return {
                icon: 'message-text',
                translation: i18n.t('activity.createSms'),
            };
        }
        case 'DELETE_COMMENT': {
            return {
                icon: 'comment-remove',
                translation: i18n.t('activity.deleteComment'),
            };
        }
        case 'POST_INTERNAL_COMMENT': {
            return {
                icon: 'comment-plus',
                translation: i18n.t('activity.createInternalComment'),
            };
        }
        case 'CREATE_VIDEO_MEETING': {
            return {
                icon: 'video',
                translation: i18n.t('activity.createVideoMeeting'),
            };
        }
        case 'UPDATE_CASE_STATUS': {
            return {
                icon: 'file-document-edit',
                translation: i18n.t('activity.updateCaseStatus'),
            };
        }
        case 'UPDATE_CASE_CLOSE_CASE': {
            return {
                icon: 'file-document-edit',
                translation: i18n.t('activity.updateCloseCase'),
            };
        }
        case 'UPDATE_CASE_REOPEN_CASE': {
            return {
                icon: 'file-document-edit',
                translation: i18n.t('activity.updateReopenCase'),
            };
        }
        case 'UPDATE_CASE_TO_DELETED': {
            return {
                icon: 'file-document-edit',
                translation: i18n.t('activity.updateToDeleted'),
            };
        }
        case 'UPDATE_CASE_QUEUE': {
            return {
                icon: 'file-document-edit',
                translation: i18n.t('activity.updateCaseQueue'),
            };
        }
        case 'UPDATE_CASE_ASSIGN': {
            return {
                icon: 'file-document-edit',
                translation: i18n.t('activity.updateCaseAssign'),
            };
        }
        case 'UPDATE_CASE_REMOVE_ASSIGN': {
            return {
                icon: 'file-document-edit',
                translation: i18n.t('activity.updateCaseRemoveAssign'),
            };
        }
        case 'UPDATE_CASE_SLA': {
            return {
                icon: 'file-document-edit',
                translation: i18n.t('activity.updateCaseSla'),
            };
        }
        case 'UPDATE_CASE_CATEGORY': {
            return {
                icon: 'file-document-edit',
                translation: i18n.t('activity.updateCaseCategory'),
            };
        }
        case 'UPDATE_CASE_CATEGORY_REMOVE': {
            return {
                icon: 'file-document-edit',
                translation: i18n.t('activity.updateCaseCategoryRemove'),
            };
        }
        default: {
            return {
                icon: 'file-document-edit',
                translation: i18n.t('activity.unknown'),
            };
        }
    }
}
